import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useTextInputStyle = makeStyles((theme: Theme) => ({
  inputBox: {
    marginBottom: theme.spacing(3.75)
  },
  inputBoxLabel: {
    marginBottom: theme.spacing(0.5)
  },
}));
