import { invokeApig } from '../libs/awsLib';

class DowntimeApi {
  static getAllDowntimeReasons() {
    const apiUrl = '/downtimeReasons';
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl });
  }

  static getDowntimeReasons(siteId) {
    const apiUrl = '/downtimeReasons/getReasons';
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, queryParams: { siteId } });
  }

  static getDowntimeReason(siteId, id) {
    const apiUrl = `/downtimeReasons/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, queryParams: { siteId } });
  }

  static saveDowntimeReason(downtimeReason) {
    const apiUrl = '/downtimeReasons';
    if (downtimeReason.id === '') delete downtimeReason.id;
    // VCCM-1691 optional description
    if (downtimeReason.description === '') delete downtimeReason.description;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, method: 'POST', body: downtimeReason });
  }

  static updateDowntimeReason(downtimeReason) {
    const apiUrl = `/downtimeReasons/${downtimeReason.id}`;
    // VCCM-1691 optional description, remove from stored record (by sending null) if empty string
    if (downtimeReason.description === '') downtimeReason.description = null;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, method: 'PUT', queryParams: { siteId: downtimeReason.siteId }, body: downtimeReason });
  }

  static deleteDowntimeReason(siteId, id) {
    const apiUrl = `/downtimeReasons/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }

  static getAllDowntimeEvents() {
    const apiUrl = '/downtimeEvents';
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl });
  }

  static getDowntimeEventsBy(siteId, lineId, startTime, endTime, interval) {
    const apiUrl = '/downtimeEvents/getEventsBy';
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams: { siteId, lineId, startDateTime: startTime, endDateTime: endTime, interval, version: 2 } });
  }

  static getDowntimeEventsByLineList(siteId, lineIds, startTime, endTime, interval) {
    const apiUrl = '/downtimeEvents/getEventsBy';
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams: { siteId, lineIds, startDateTime: startTime, endDateTime: endTime, interval, version: 2 } });
  }

  static getDowntimeEvent(siteId, id) {
    const apiUrl = `/downtimeEvents/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams: { siteId } });
  }

  static saveDowntimeEvent(siteId, updates, originalDowntimeEvent) {
    const apiUrl = `/downtimeEvents/${originalDowntimeEvent.lineId}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, method: 'PUT', body: updates, queryParams: { startDt: originalDowntimeEvent.startDt, siteId } });
  }

  static deleteDowntimeEvent(siteId, id) {
    const apiUrl = `/downtimeEvents/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }
}

export default DowntimeApi;
