import React, { ReactElement, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, withRouter } from "react-router-dom";

// styles
import useStyles from "./Layout.css";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { NotFound } from "../NotFound/NotFound";
import { SitePage } from "../../pages/sites/SitePage/SitePage";
import LinePages from "../../pages/linepage/LinesPage";
import HomePage from "../../pages/homepage/HomePage";
import LineSetupPage from "../../pages/linesetup/LineSetupPage/LineSetupPage";
import ProductSetupPage from "../../pages/productsetup/ProductSetupPage/ProductSetupPage";
import { SchedulerPage } from "../../pages/schedulerpage/SchedulerPage/SchedulerPage";
import { useAppSelectedSite, useAppGlobalState, useConfigState, useAppUserState } from "../../context/AppContext/AppContext";
import DisplayModeSettingsSetupPage from "../../pages/displaymode/DisplayModeSettingsSetupPage/DisplayModeSettingsSetupPage";
import InspectionSettingsPage from "../../pages/inspections/InspectionSettingsPage/InspectionSettingsPage";
import InspectionSetupPage from "../../pages/inspections/InspectionSetupPage/InspectionSetupPage/InspectionSetupPage";
import DeviceRoleSetupPage from "../../pages/devicerole/DeviceRoleSetupPage/DeviceRoleSetupPage";
import { ReportsDashPage } from "../../pages/reports/ReportsDashPage/ReportsDashPage";
import { AdminPage } from "../../pages/admin/AdminPage/AdminPage";
import { OperationsPage } from "../../pages/admin/OperationsPage/OperationsPage";
import { UserSetupMainPage } from "../../pages/users/UserSetupMainPage/UserSetupMainPage";
import { DowntimeSetupMainPage } from "../../pages/downtime/DowntimeSetupMainPage/DowntimeSetupMainPage";
import SiteForm from "../../pages/sites/SiteForm/SiteForm";
import { Footer } from "../Footer/Footer";
import { DownloadPage } from "../../pages/download/DownloadPage/DownloadPage";
import { getLinkAccessInfo } from "../../RouteAccess";
import { CheckAuthorisedRoute } from "../CheckAuthorisedRoute/CheckAuthorisedRoute";
import AsyncComponent from "../Common/AsyncComponent";
import { DowntimeCategoryManagementPage } from "../../pages/downtime/category/DowntimeCategoryManagementPage/DowntimeCategoryManagementPage";
import { DowntimeSubCategoryManagementPage } from "../../pages/downtime/subcategory/DowntimeSubCategoryManagementPage/DowntimeSubCategoryManagementPage";
import { useConfigActions } from "../../actions/configActions";

import { useSiteActions } from "../../actions/siteActions";
import { TranslationManagementPage } from "../../pages/translationpage/TranslationManagementPage/TranslationManagementPage";
import { getCurrentUser } from "../../libs/awsLib";
import { useUserActions } from "../../actions/userActions";

const AsyncShiftManagementSetupPage = AsyncComponent({ loader: () => import('../../pages/schedulerpage/shifts/ShiftManagementPage/ShiftManagementPage') });
const AsyncLineManagementPage = AsyncComponent({ loader: () => import('../../pages/linesetup/LineManagementPage/LineManagementPage') });
const AsyncProductManagementPage = AsyncComponent({ loader: () => import('../../pages/productsetup/ProductManagementPage/ProductManagementPage') });
const AsyncInspectionSetupManagementPage = AsyncComponent({ loader: () => import('../../pages/inspections/InspectionSetupPage/InspectionSetupManagementPage/InspectionSetupManagementPage') });
const AsyncAboutPage = AsyncComponent({ loader: () => import('../AboutPage/AboutPage') });

const AsyncLineDetailPage = AsyncComponent({ loader: () => import('../../pages/linepage/LineDetailPage/LineDetailPage') });
const AsyncLinesDash = AsyncComponent({ loader: () => import('../../pages/dashboard/LinesDashPage/LinesDashPage') });
const AsyncReportNotificationPage = AsyncComponent({ loader: () => import('../../pages/schedulerpage/ReportNotificationPage/ReportNotificationPage') });

const Layout = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();

  const siteActions = useSiteActions();

  const { selectedSiteId, sites, isAdmin, initialised, defaultPageDisplayRequired } = useAppGlobalState();
  const { cognitoUserLoaded } = useAppUserState();
  const selectedSite = useAppSelectedSite();
  const configState = useConfigState();
  const configActions = useConfigActions();
  const userActions = useUserActions();

  const [isRedirected, setIsRedirected] = useState<boolean>(false);

  const site = sites.find(el => el.id === selectedSiteId);
  const accessInfo = getLinkAccessInfo(site, isAdmin, selectedSiteId);

  useEffect(() => {
    if (initialised && defaultPageDisplayRequired) {
      history.push('/home');
      userActions.setPageRequired(false);
    }
  }, [initialised]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (history.location.pathname.indexOf('/dashboard/lines') > -1) {
      configActions.setDashboardMode(true);
    } else if (configState.dashboardMode) {
      configActions.setDashboardMode(false);
    }
  }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const access = {
    accessInfo,
    params: {
      siteId: selectedSiteId
    }
  };

  useEffect(() => {
    if (initialised && !selectedSite && sites && sites.length > 0 && !isRedirected) {
      (async () => {
        const currrentUser = await getCurrentUser();
        if (currrentUser) {
          siteActions.setSite(sites[0].id, currrentUser);
        }
      })();
      history.push('/home');
      setIsRedirected(true);
    }
  }, [sites, initialised, selectedSite, history, siteActions, isRedirected]);

  return <>
    {
      !configState.dashboardMode && <Header />
    }

    {
      selectedSite ? <div className={classes.root}>
        {
          !configState.dashboardMode && <Sidebar />
        }
        {cognitoUserLoaded && <div className={configState.dashboardMode ? classes.displayModeContainer : classes.contentContainer}>
          {
            <div className={configState.dashboardMode ? classes.displayModeContent : classes.content}>
              <Switch>
                <Route exact path="/" render={() => <Redirect to={'/home'} />} />
                <Route exact path="/site" render={() => <Redirect to={`/site/${selectedSiteId}/lines`} />} />
                <Route exact path="/about" component={AsyncAboutPage} />

                <CheckAuthorisedRoute {...access} path="/setup/translations" component={TranslationManagementPage} />
                <CheckAuthorisedRoute {...access} path="/setup/site/:siteId/:setupMode" component={SiteForm} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/download" component={DownloadPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/lines/:lineMode" component={LinePages} forceToSites={true} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/lines" component={LinePages} forceToSites={true} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/dashboard/lines" component={AsyncLinesDash} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/line/:lineId/:actionMode" component={AsyncLineManagementPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/line/:id" component={AsyncLineDetailPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/lines" component={LineSetupPage} />
                <CheckAuthorisedRoute {...access} path="/home" component={HomePage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/product/:productId/:actionMode" component={AsyncProductManagementPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/products" component={ProductSetupPage} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/scheduledReport/:userId/:title/:action" component={AsyncReportNotificationPage} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/displayMode" component={DisplayModeSettingsSetupPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/deviceroles" component={DeviceRoleSetupPage} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/inspection/:inspectionId/:actionMode" component={AsyncInspectionSetupManagementPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/inspections" component={InspectionSetupPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/compliance" component={InspectionSettingsPage} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/downtime/:setupParam/:action" component={DowntimeCategoryManagementPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/subcategory/:setupParam/:action" component={DowntimeSubCategoryManagementPage} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/shifts/:shiftPage" component={SchedulerPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/shifts" component={SchedulerPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/shift/:id/:actionMode/:typeId/:fromSchedule" component={AsyncShiftManagementSetupPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/setup/shift/:id/:actionMode/:typeId" component={AsyncShiftManagementSetupPage} />

                <Route path="/site/:siteId/setup/downtimes" component={DowntimeSetupMainPage} />
                <Route path="/site/:siteId/setup/subcategory" component={DowntimeSetupMainPage} />

                <Route path="/site/:siteId/setup/user/:setupParam/:action" component={UserSetupMainPage} />
                <Route path="/site/:siteId/setup/role/:setupParam/:action" component={UserSetupMainPage} />
                <Route path="/site/:siteId/setup/users" component={UserSetupMainPage} />
                <Route path="/site/:siteId/setup/roles" component={UserSetupMainPage} />

                <CheckAuthorisedRoute {...access} path="/site/:siteId/report/:tab" component={ReportsDashPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/report" component={ReportsDashPage} />
                <CheckAuthorisedRoute {...access} path="/site/:siteId/admin" component={AdminPage} />
                <CheckAuthorisedRoute {...access} path="/sites/operations" component={OperationsPage} />
                <CheckAuthorisedRoute {...access} path="/sites" component={SitePage} />

                <Route component={NotFound} />
              </Switch>
            </div>
          }
          {
            !configState.dashboardMode && <div className={classes.footer}>
              <Footer />
            </div>
          }
        </div>
        }
      </div> : null
    }

  </>

}

Layout.displayName = 'Layout';

export default withRouter(Layout);
