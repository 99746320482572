import React, { useCallback, useState } from 'react';
import { validation } from 'vccm-common';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import classNames from 'classnames';
import { ProgressIndicator } from '../ProgressIndicator/ProgressIndicator';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  List,
  ListItem,
  TextField
} from '@material-ui/core';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { green, red } from '@material-ui/core/colors';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNewPasswordWithCodeDialogStyle } from './NewPasswordWithCodeDialog.css';
import { containsLowercase, containsNumber, containsUppercase, isMinLength } from '../../utilities/validations';

const GreenTheme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const RedTheme = createMuiTheme({
  palette: {
    primary: red,
  },
});

const getIcon = passed => (
  <ThemeProvider theme={passed ? GreenTheme : RedTheme}>
    {
      passed ? <CheckIcon color="primary" className="valid" /> : <CloseIcon color="primary" className="invalid" />
    }
  </ThemeProvider>
);

interface NewPasswordWithCodeDialogProps {
  loading: boolean;
  open: boolean;
  onClose: (validationCode: string, password: string) => void;
  maxWidth?: DialogProps['maxWidth'];
}

const NewPasswordWithCodeDialog = ({
  loading,
  open,
  maxWidth,
  onClose }: NewPasswordWithCodeDialogProps) => {
  const classes = useNewPasswordWithCodeDialogStyle();

  const intl = useIntl();

  const [errors, setErrors] = useState<any>({});
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [validationCode, setValidationCode] = useState('');

  const validateForm = useCallback(() => {
    // use the user schema to validate password rules
    const results = validation.schemas.user.validate({
      password,
      confirmPassword,
    }, { abortEarly: false });
    const errs = results.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, '_') : '';
      return agg;
    }, {});
    setErrors(errs);

    return !(errs.confirmPassword || errs.password);
  }, [password, confirmPassword]);

  const handleCancel = () => {
    onClose('', '');
  };

  const handleOk = (password) => {
    onClose(validationCode, password);
  };

  const checks = {
    passwordMatches: password && confirmPassword && password === confirmPassword,
    isMinLength: isMinLength(password, 8),
    containsNumber: containsNumber(password),
    containsUppercase: containsUppercase(password),
    containsLowercase: containsLowercase(password)
  };

  const allChecksPass = Object.keys(checks).reduce((acc, el) => acc && checks[el], true);

  return <>
    <Dialog disableBackdropClick
      classes={{ paper: classes.root }}
      fullWidth={true}
      maxWidth={maxWidth || 'sm'}
      disableEscapeKeyDown
      aria-labelledby="new-password-with-code" open={open}>
      <DialogTitle id="password-dialog-title" >
        <div className={classes.title}>
          <div>
            <PermIdentityIcon className={classes.titleIcon} />
          </div>
          <div>
            {intl.formatMessage({ id: 'detail_Password' })}
          </div>
        </div>
        <IconButton aria-label="close" className={classNames(classes.closeButton, 'password-close-btn')} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl className={classes.formControl}>
          <TextField
            autoComplete="off"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="validationCode"
            label={intl.formatMessage({ id: 'detail_VerificationCode' })}
            onChange={(event) => setValidationCode(event.target.value)}
            id="validationCode"
            disabled={loading}
            value={validationCode}
            error={errors && !!errors.validationCode}
            helperText={errors && errors.validationCode && intl.formatMessage({ id: errors.validationCode })}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            autoComplete="off"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={intl.formatMessage({ id: 'detail_Password' })}
            onChange={(event) => setPassword(event.target.value)}
            type="password"
            id="password"
            disabled={loading}
            value={password}
            error={errors && !!errors.password}
            helperText={errors && errors.password && intl.formatMessage({ id: errors.password })}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name='confirmPassword'
            label={intl.formatMessage({ id: 'detail_Confirm_Password' })}
            onChange={(event) => setConfirmPassword(event.target.value)}
            type="password"
            id="confirmPassword"
            disabled={loading}
            value={confirmPassword}
            error={errors && !!errors.confirmPassword}
            helperText={errors && errors.confirmPassword && intl.formatMessage({ id: errors.confirmPassword })}
          />
        </FormControl>
        <div>
          <div>{intl.formatMessage({ id: 'detail_PasswordRequirements' })}</div>
          <List>
            <ListItem>
              <div className={classes.passwordCheck}>
                {getIcon(checks.passwordMatches)}
                {intl.formatMessage({ id: 'detail_PasswordsMustMatch' })}
              </div>
            </ListItem>
            <ListItem>
              <div className={classes.passwordCheck}>
                {getIcon(checks.isMinLength)}
                {intl.formatMessage({ id: 'detail_PasswordIsMinLength' })}
              </div>
            </ListItem>
            <ListItem>
              <div className={classes.passwordCheck}>
                {getIcon(checks.containsNumber)}
                {intl.formatMessage({ id: 'detail_PasswordContainsNumber' })}
              </div>
            </ListItem>
            <ListItem>
              <div className={classes.passwordCheck}>
                {getIcon(checks.containsUppercase)}
                {intl.formatMessage({ id: 'detail_PasswordContainsUppercase' })}
              </div>
            </ListItem>
            <ListItem>
              <div className={classes.passwordCheck}>
                {getIcon(containsLowercase(password))}
                {intl.formatMessage({ id: 'detail_PasswordContainsLowercase' })}
              </div>
            </ListItem>
          </List>
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button autoFocus
          onClick={() => {
            if (validateForm()) {
              handleOk(password);
            }
          }}
          className="password-ok-btn"
          variant="contained"
          disabled={loading || !allChecksPass}
          color="primary">
          <FormattedMessage id="detail_Ok" />
        </Button>
      </DialogActions>
    </Dialog>
    {
      loading && <ProgressIndicator full={true} />
    }
  </>
}

export { NewPasswordWithCodeDialog };
