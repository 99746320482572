import React, { ReactElement } from 'react';
import { usePageTabsStyle } from './PageTabs.css';
import classNames from 'classnames';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

const gridBreakWithDisplay: any = {
  tab: { lg: 9, md: 9, sm: 12, xs: 12 },
  display: { lg: 3, md: 3, sm: 12, xs: 12 },
};

const gridBreak: any = {
  tab: { lg: 12, md: 12, sm: 12, xs: 12 },
};

function PageTabs({ children, displayButton }: { children: ReactElement; displayButton?: string | ReactElement }) {
  const classes = usePageTabsStyle();
  const theme = useTheme();

  const mediumSize = useMediaQuery(theme.breakpoints.up('md'));

  const activeBreak = displayButton ? gridBreakWithDisplay : gridBreak;

  return (
    <Grid container className={classNames(classes.appPageTabTitle, classes.root, { [classes.extra]: mediumSize })}>
      <Grid item className={classes.appPageTabTitleHeading} {...activeBreak.tab}>
        {children}
      </Grid>
      {displayButton && <Grid item className={classes.displayButton} {...activeBreak.display}>{displayButton}</Grid>}
    </Grid>
  );
}

export default PageTabs;
