import React, { useEffect, useState } from 'react';
import toastr from 'toastr';

import ReportNotificationListTable from '../ReportNotificationListTable/ReportNotificationListTable';
import { getCurrentUser } from '../../../libs/awsLib';
import notifyApi from '../../../api/prodNotificationApi';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { ReportNotificationDelete } from '../ReportNotificationDelete/ReportNotificationDelete';

interface IReportNotificationSetupPageProps {
  siteId: string;
}

const ReportNotificationSetupPage = ({ siteId }: IReportNotificationSetupPageProps) => {
  const [scheduledReports, setScheduledReports] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState<string>();
  const [user, setUser] = useState<any>({});

  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [toBeDeletedSchedule, setToBeDeletedSchedule] = useState<any>({ title: '' });

  const loadData = async () => {
    try {
      const [currentUser, reportSchedules] = await Promise.all([getCurrentUser(), notifyApi.listReportSchedules()]);
      setScheduledReports(reportSchedules);
      setUser(currentUser);
      setLoading(false);
    } catch (e) {
      setError(e.toString());
      setLoading(false);
    }
  };

  const onDeleteSchedule = (schedule) => {
    setDeleteConfirmVisible(true);
    setToBeDeletedSchedule(schedule)
  }

  const onScheduleDeleted = () => {
    setDeleteConfirmVisible(false);
    loadData();
  }

  useEffect(() => {
    let ignore = false;
    if (ignore) return undefined;
    (loadData)();
    // return the cleanup function
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (reload) {
      setLoading(true);
      loadData();
    }
  }, [reload]);

  useEffect(() => {
    if (error) {
      toastr.error(error);
      setError('');
    }
  }, [error]);

  return !loading ? <>
    <ReportNotificationListTable
      scheduledReports={scheduledReports}
      userId={user && user.username}
      onChange={setReload}
      onDeleteSchedule={onDeleteSchedule}
      siteId={siteId}
    />
    <ReportNotificationDelete
      schedule={toBeDeletedSchedule}
      isDeleteConfirmVisible={isDeleteConfirmVisible}
      setDeleteConfirmVisible={setDeleteConfirmVisible}
      onDeleted={onScheduleDeleted} />
  </> :
    <ProgressIndicator />
}

export default ReportNotificationSetupPage;
