import React, { useCallback, useEffect, useState } from 'react';
import { claims } from 'vccm-common';
import InspectionSetupListRow from '../InspectionSetupListRow/InspectionSetupListRow';
import { TableHeadText } from '../../../../components/TableHeadText/TableHeadText';
import StyledGrid from '../../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledContentCard from '../../../../components/Basic/StyledContentCard/StyledContentCard';
import Utils from '../../../../utilities/utils';
import { EmptyItem } from '../../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../../constants/global';
import { useInspectionSetupListTableStyles } from './InspectionSetupListTable.css';
import Can from '../../../../components/Common/Can';
import { InspectionSetupBar } from '../InspectionSetupBar/InspectionSetupBar';
import { useAppGlobalState, useAppLineState, useAppProductState } from '../../../../context/AppContext/AppContext';
import { getRoles } from '../../../../services/UserService/UserService';
import UserHelper from '../../../../helpers/UserHelper';
import { useIntl } from 'react-intl';
import { useConfigActions } from '../../../../actions/configActions';
import { DEFAULT_COLOR } from '../../../../constants/colors';
import { Brightness1 as Brightness1Icon } from "@material-ui/icons";
import { useIsSmallSelector } from '../../../../context/LayoutContext/LayoutContext';

const gridBreak = {
  name: { lg: 2, md: 4, sm: 4, xs: 12 },
  description: { lg: 1, md: 3, sm: 4, xs: 12 },
  frequency: { lg: 1, md: 3, sm: 4, xs: 12 },
  active: { lg: 1, md: 2, sm: 4, xs: 12 },
  role: { lg: 1, md: 3, sm: 4, xs: 12 },
  lines: { lg: 2, md: 3, sm: 4, xs: 12 },
  products: { lg: 2, md: 3, sm: 4, xs: 12 },
  actionButtons: { lg: 2, md: 3, sm: 12, xs: 12 }
};

interface IInspectionSetupListState {
  page: number;
  sortedInspectionSetups: Array<any>;
  pageCount: number;
  pagedInspectionSetups: Array<any>;
}
interface InspectionSetupListTableProps {
  inspections: any;
  site: any;
  refreshSiteData: () => void;
  onDeleteInspectionSetup: (inspection: any) => void;
}

const InspectionSetupListTable = ({
  inspections,
  site,
  refreshSiteData,
  onDeleteInspectionSetup }: InspectionSetupListTableProps) => {
  const classes = useInspectionSetupListTableStyles();
  const [, setshowInspectionSetupAssignModal] = useState(false);
  const [roles, setRoles] = useState<Array<any>>([]);
  const [formattedLines, setFormattedLines] = useState<Array<any>>([]);
  const [formattedProducts, setFormattedProducts] = useState<Array<any>>([]);

  const { selectedSiteId, appFilters } = useAppGlobalState();

  const lines = useAppLineState();
  const products = useAppProductState();
  const intl = useIntl();

  const smallMode = useIsSmallSelector();
  const configActions = useConfigActions();

  const [filteredInspectionSetups, setFilteredInspectionSetups] = useState<Array<any>>([]);

  const [inspectionListState, setInspectionSetupListState] = useState<IInspectionSetupListState>({
    page: 1,
    sortedInspectionSetups: [],
    pageCount: 0,
    pagedInspectionSetups: []
  });

  const {
    page,
    sortedInspectionSetups,
    pageCount,
    pagedInspectionSetups
  } = inspectionListState;

  const showAssignModal = () => {
    setshowInspectionSetupAssignModal(true);
  }

  function makeLinesListForDisplay(lineTitles) {
    // TODO: make this wrap on each title
    let output = '';
    for (const key in lineTitles) {
      output += `${lineTitles[key]}; `;
    }
    return output;
  }

  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const insightUser = claims.moduleLicensed(site, claims.licensedModuleInfo.intelligence);

  const isDev = Utils.isDev();

  useEffect(() => {
    (async () => {
      try {
        const fetchedRoles = await getRoles(selectedSiteId);
        const updatedRoles = UserHelper.translateRoles(fetchedRoles.map(el => {
          return el.key ? { ...el, key: el.key.replace('.', '_') } : el
        }), intl);

        const rolesForLookup = (!updatedRoles ? [] : updatedRoles.filter(r => r.title).map(r => ({ value: r.id, text: r.title })));

        setRoles(rolesForLookup);
      } catch (e) {
        alert(e);
      }
    })();
  }, [selectedSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormattedLines(lines.map(i => ({
      value: i.id,
      text: i.title,
      icon: <Brightness1Icon style={{ color: i.display ? i.display.color : DEFAULT_COLOR }} />
    })).sort(Utils.sortByText));
  }, [lines]);

  useEffect(() => {
    setFormattedProducts(products.map(i => ({
      value: i.id,
      text: i.title
    })).sort(Utils.sortByText));
  }, [products]);

  useEffect(() => {
    setInspectionSetupListState(s => {
      const sorted = inspections ? inspections.sort((a, b) => a.title.localeCompare(b.title)) : [];
      return {
        ...s,
        sortedInspectionSetups: sorted
      }
    })
  }, [inspections, site]);

  useEffect(() => {
    const searchTerm = appFilters.inspectionSetupFilter.searchTerm;
    const products = appFilters.inspectionSetupFilter.products;
    const lines = appFilters.inspectionSetupFilter.lines;

    const searchTermLowercase = searchTerm.toLowerCase();
    let initialFilteredInspectionSetups = searchTerm
      ? sortedInspectionSetups.filter(inspection => inspection.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedInspectionSetups;

    initialFilteredInspectionSetups = products && products.length > 0
      ? initialFilteredInspectionSetups.filter(inspection => !!products.find(el => inspection.productIds && inspection.productIds.indexOf(el) > -1))
      : initialFilteredInspectionSetups;

    initialFilteredInspectionSetups = lines && lines.length > 0
      ? initialFilteredInspectionSetups.filter(inspection => !!lines.find(el => inspection.lineIds && inspection.lineIds.indexOf(el) > -1))
      : initialFilteredInspectionSetups;

    setFilteredInspectionSetups(initialFilteredInspectionSetups);

  }, [appFilters, sortedInspectionSetups]);


  useEffect(() => {
    setInspectionSetupListState(s => {
      const count = Math.ceil(((filteredInspectionSetups && site && filteredInspectionSetups.length) || 0) / PAGE_SIZE);
      const paged = filteredInspectionSetups.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedInspectionSetups: paged
      }
    })
  }, [filteredInspectionSetups, site]);

  const onPageChange = useCallback(p => {
    setInspectionSetupListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredInspectionSetups.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setInspectionSetupListState(s => ({
      ...s,
      pagedInspectionSetups: paged,
    }));
  }, [page, filteredInspectionSetups]);

  return (<>
    <Can do="edit" on="inspection">
      <InspectionSetupBar
        siteId={site.id}
        isSmall={smallMode}
        products={formattedProducts}
        lines={formattedLines}
        filter={appFilters.inspectionSetupFilter}
        setFilter={configActions.setBarFilter}
        refreshSiteData={refreshSiteData} />
    </Can>
    <StyledContentCard>
      {
        inspections && site && inspections.length > 0 ? <StyledGrid>
          {
            (appFilters.inspectionSetupFilter.searchTerm.length === 0 &&
              appFilters.inspectionSetupFilter.products.length === 0 &&
              appFilters.inspectionSetupFilter.lines.length === 0) || pagedInspectionSetups.length > 0 ? <StyledGridHeadRow>
              <StyledGridHeadCell {...gridBreak.name}>
                <TableHeadText
                  textId="detail_Name"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.description} >
                <TableHeadText
                  textId="detail_Description"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.frequency} >
                <TableHeadText
                  textId="detail_Frequency"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.active} >
                <TableHeadText
                  textId="detail_Active"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.role} >
                <TableHeadText
                  textId="detail_Role"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.lines} >
                <TableHeadText
                  textId="detail_Lines"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.products} >
                <TableHeadText
                  textId="detail_Products"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.actionButtons} >
              </StyledGridHeadCell>
            </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
          }
          {
            pagedInspectionSetups.map(inspection => (
              <InspectionSetupListRow
                key={inspection.id}
                inspection={inspection}
                siteId={site.id}
                gridBreak={gridBreak}
                onAssign={showAssignModal}
                isDev={isDev}
                insightUser={insightUser}
                roles={roles}
                formattedLines={formattedLines}
                formattedProducts={formattedProducts}
                makeLinesListForDisplay={makeLinesListForDisplay}
                onDeleteInspectionSetup={onDeleteInspectionSetup}
              />
            ))
          }
        </StyledGrid>
          : <EmptyItem textId="detail_noInspectionSetups" />
      }
      {
        filteredInspectionSetups && site && filteredInspectionSetups.length > PAGE_SIZE ? <div className={classes.pageContainer}>
          <Paging
            page={page}
            onChange={onPageChange}
            count={pageCount}
            total={filteredInspectionSetups.length} /></div> : null
      }
    </StyledContentCard>
  </>)
}

export default InspectionSetupListTable;
