import { makeStyles } from "@material-ui/core";

export const useProductImportDialogStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.625)
  },
  content: {
    margin: 0,
    padding: 0
  },
  title: {
    wordBreak: 'normal'
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  selectBtns: {
    justifySelf: 'flex-start'
  },
  saveCloseBtns: {
    justifyContent: 'flex-end'
  },
  createProductBtn: {
    marginRight: theme.spacing(1.125)
  }
}));
