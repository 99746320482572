import { makeStyles } from "@material-ui/core";
import { mySecondaryColorDimmed } from "../../../constants/colors";

export const useReportNotificationListRowStyles = makeStyles((theme) => ({
  scheduleOptionsContainer: {
    display: 'flex',
  },
  scheduleOptions: {
    borderRadius: '0.6rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e8e8e8',
    marginRight: '5px',
    minWidth: '40px',
    fontSize: '14px'
  },
  scheduleOptionInner: {
    margin: '5px',
    textAlign: 'center'
  },
  reportAccordion: {
    position: 'unset',
    borderRadius: '10px',
    border: `1px solid ${mySecondaryColorDimmed}`,
    '&:nth-last-child(n + 2)': {
      marginBottom: '5px'
    }
  },
  reportOptionsContainer: {
    display: 'flex',
  },
  reportOptions: {
    borderRadius: '0.6rem',
    justifyContent: 'center',
    backgroundColor: '#e8e8e8',
    alignItems: 'center',
    marginRight: '5px',
    fontSize: '14px'
  },
  reportOptionInner: {
    margin: '5px 10px !important',
    textAlign: 'center'
  },
  summary: {
    minHeight: 'unset'
  },
  summaryContent: {
    margin: '6px 0'
  },
  summaryIcon: {
    padding: '0 12px'
  }
}));
