import {
  Chart, ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeriesDefaults,
  ChartLegend, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartSeriesItemTooltip,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { sharedConfig } from './ChartSharedConfig';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useIntl } from 'react-intl';


function LegendToolTip(props: any) {
  return (
    <div>
      <strong>{props.tooltip && props.tooltip.data.value}</strong>
      <strong>
        {' '}
        {props.tooltip && props.tooltip.length}
      </strong>
    </div>
  );
}

const ChartDowntimeDonut = (props) => {
  const intl = useIntl();
  const [tooltip, setTooltip] = React.useState(
    {
      display: false,
      data: {
        key: '',
        value: '',
        color: '',
      },
    } as any
  );
  const [hiddenDonutSection, setHiddenDonutSection] = React.useState<Array<boolean>>([]);

  useEffect(() => {
    setHiddenDonutSection(props.data.map(() => true))
  }, [props])

  const showLegendToolTip = (event) => {
    const { display } = tooltip
    const target = event.series.data[event.pointIndex];
    if (!display && Object.keys(target.subSlice).length !== 0) {
      setTooltip({
        display: true,
        data: {
          key: '',
          value: (<div>{intl.formatMessage({ id: 'detail_Subcategories' })}{target.subSlice.map(s => { return (<div key={s.sliceKey}>{s.sliceKey}</div>) })}</div>),
          color: target.color
        },
      })
    }
  }

  const hideLegendToolTip = () => {
    setTooltip({
      display: false,
      data: {
        key: '',
        value: '',
        color: '',
      },
    })
  }

  const onClickEvent = (event) => {
    const visibilityLst: Array<boolean> = [];
    for (const i in event.series.data) {
      if (Number(i) === event.pointIndex) {
        visibilityLst.push(!hiddenDonutSection[i])
        event.series.data[event.pointIndex].visible = !hiddenDonutSection[i]
      }
      else visibilityLst.push(hiddenDonutSection[i])
    }
    setHiddenDonutSection(visibilityLst)
  }

  function chartConfig(cProps): any {
    return {
      title: {
        text: cProps.title,
        visible: cProps.showTitle,
        color: 'Black',
      },
      legend: {
        position: 'bottom',
      },
      chartArea: {
      },

      transitions: false,
      seriesDefaults: {
        type: 'donut',
        holeSize: 60,
        labels: {
          visible: true,
          background: 'transparent',
          position: 'outsideEnd',
          format: '{0:n0}',
        },
      },
      series: [
        {
          name: 'Count',
          data: cProps.data,
          field: cProps.downtimeElapsed ? 'duration' : 'count',
          categoryField: 'sliceKey',
          format: '{0:n0}',
          color: 'green',
        }],
      panes: [
        {
          clip: false,
        }],
      valueAxis: {
        line: {
          visible: false,
        },
      },
      categoryAxis: {
        majorGridLines: {
          visible: false,
        },
        line: {
          visible: false,
        },
      },
      tooltip: {
        visible: true,
      },

    };
  }

  const renderDonutTooltip = (event) => {
    if (event.point) {
      const { subSlice } = event.point.dataItem
      if (subSlice && Object.keys(subSlice).length !== 0) return (<div>{intl.formatMessage({ id: 'detail_Subcategories' })}{subSlice && subSlice.map(slice => {
        return (<div key={slice.sliceKey}>{slice.sliceKey}: {slice.duration}</div>)
      })}</div>)
      else return (<div>{event.point.value}</div>)
    }
  }
  const config = chartConfig(props);
  const onLegendItemLeave = {
    onLegendItemLeave: () => {
      hideLegendToolTip();
    }
  }
  const mainContent =
    <Chart
      renderAs={props.printing ? 'canvas' : undefined}
      {...sharedConfig}
      onLegendItemHover={e => {
        showLegendToolTip(e)
      }}
      {...onLegendItemLeave}
      onLegendItemClick={e => {
        onClickEvent(e)
      }}
    >
      <ChartTooltip render={renderDonutTooltip} />
      <ChartLegend {...config.legend} />
      <ChartSeriesDefaults {...config.seriesDefaults} />
      <ChartTitle {...config.title} />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem {...config.categoryAxis} />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem {...config.valueAxis} />
      </ChartValueAxis>
      <ChartSeries>
        {config.series.map((item, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ChartSeriesItem type="donut" {...item} key={`series-${i}`} colorField="color">
              <ChartSeriesItemTooltip format="{0:n0}" />
            </ChartSeriesItem>
          )
        })}
      </ChartSeries>

    </Chart>

  const filterElements = element => {
    element.title = 'event downtime'; // this title is required to trigger tooltip
    return true;
  }

  return <div>
    {tooltip.display ? (
      <Tooltip
        position="bottom"
        filter={filterElements}
        anchorElement="target"
        content={props => (
          <LegendToolTip
            tooltip={tooltip}
            title={props.title}
          />
        )}
      > {mainContent}
      </Tooltip>) : mainContent
    }
  </div>;
};

ChartDowntimeDonut.propTypes = {
  downtimeElapsed: PropTypes.bool,
  printing: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.object
};

export default ChartDowntimeDonut;
