import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesDefaults,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts'; import 'hammerjs';

import PropTypes from 'prop-types';
import React from 'react';
import { sharedConfig } from './ChartSharedConfig';

function chartConfig(props): any {
  const { lines } = props;
  const data = lines.map(line => ({
    ...line,
    targetDif: line.runRate ? line.oeeHour ? line.count - (line.runRate * line.oeeHour.length) : 0 : 0
  }));

  return {
    categoryAxis: {
      labels: {
        rotation: 'auto',
      },

      majorGridLines: {
        visible: false,
      },
      line: {
        visible: false,
      },
    },
    legend: {
      position: 'bottom',
      visible: false,
      color: 'White',
    },
    panes: [
      {
        clip: false,
      }],
    series: [
      {
        name: 'Count',
        data,
        field: 'targetDif',
        categoryField: 'title',
        color: '#0192c7',
      }],
    title: {
      text: props.title,
      visible: props.showTitle,
      color: 'Black',
    },


    transitions: false,

    valueAxis: {
      line: {
        visible: false,
      },
    },

    tooltip: {
      visible: true,
      render: ({ point }) => `${point.value.toFixed()} ${point.value > 0 ? 'ahead' : 'behind'}`,

    }
  };
}

const ChartPerformanceBarByLine = (props) => {

  const config = chartConfig(props);

  return (
    <React.Fragment>
      {props.children}
      <Chart {...sharedConfig}
        onSeriesClick={e => props.history.push(`/site/${props.siteId}/line/${e.dataItem.id}`)}
      >
        <ChartTooltip {...config.tooltip} />
        <ChartLegend {...config.legend} />
        <ChartSeriesDefaults {...config.seriesDefaults} />
        <ChartTitle {...config.title} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem {...config.categoryAxis} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem {...config.valueAxis} />
        </ChartValueAxis>
        <ChartSeries>
          {config.series.map((item, i) => (
            <ChartSeriesItem {...item} key={`series-${i}`} />
          ))}
        </ChartSeries>
      </Chart>
    </React.Fragment>
  );
};

ChartPerformanceBarByLine.propTypes = {
  children: PropTypes.element,
  history: PropTypes.object,
  lines: PropTypes.array,
  showTitle: PropTypes.bool,
  siteId: PropTypes.string,
  title: PropTypes.string,
  interval: PropTypes.string,
  min: PropTypes.number,
  screenHeight: PropTypes.number,
  screenWidth: PropTypes.number,
};

ChartPerformanceBarByLine.defaultProps = {
  lines: [],
  showTitle: false,
  children: <div />,
};

export default ChartPerformanceBarByLine;
