import CirrusApi from '../api/prodCirrusApi';
import UserApi from '../api/prodUserApi';
import { Logger } from '../utilities/Logger/Logger';

export async function authenticate(siteId) {
  try {
    const token = await UserApi.getCirrusToken(siteId);
    const response = await CirrusApi.authenticate(token);
    if (response.ok === true) {
      return token;
    } else {
      Logger.of('App.Actions.cirrusActions2.authenticate').info(`Template Design Authenticate Error: Token not authenticated [${token}]`);
    }
  } catch (e) {
    Logger.of('App.Actions.cirrusActions2.authenticate').error(`Template Design Authenticate Error: ${e}`);
  }
}

export async function getPrintersWithJobs() {
  const response = await CirrusApi.getAvailablePrinters();
  if (response.ok === true) {
    const {PrinterList} = await response.json();
    return Object.values(PrinterList);
  } else {
    Logger.of('App.Actions.cirrusActions2.getPrintersWithJobs').warn(`Template Design getTemplates Error: Status Code ${response.status}`);
  }
}

export async function getJobFile(jobId) {
  try {
    const response = await CirrusApi.getJobFile(jobId);
    if (response.ok === true) {
      return await response.blob();
    } else {
      Logger.of('App.Actions.cirrusActions2.getJobFile').warn(`Template Design getJobFile Error: Status Code ${response.status}`);
    }
  } catch (e) {
    Logger.of('App.Actions.cirrusActions2.getJobFile').error(`Template Design getJobFile Error: ${e}`);
  }
}

export async function getTemplates(printerName) {
  try {
    const utf8Array = convertToUTF8Array(printerName);
    const hex = buf2hex(utf8Array);
    const response = await CirrusApi.getAllJobs(hex);
    if (response.ok === true) {
      return await response.json();
    } else {
      Logger.of('App.Actions.cirrusActions2.getTemplates').warn(`Template Design getTemplates Error: Status Code ${response.status}`);
    }
  } catch (e) {
    Logger.of('App.Actions.cirrusActions2.getTemplates').error(`Template Design getTemplates Error: ${e}`);
  }
}

function buf2hex(buffer) {
  return Array.prototype.map.call(new Uint8Array(buffer), (x) => `00${x.toString(16)}`.slice(-2)).join('');
}

function convertToUTF8Array(str) {
  const utf8: Array<any> = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) {
      utf8.push(charcode);
    } else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    }
    // surrogate pair
    else {
      i++;
      charcode = ((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff);
      utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    }
  }
  return utf8;
}
