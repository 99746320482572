import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import BarStyledGrid from '../../../components/Basic/BarStyledGrid/BarStyledGrid';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';
import { useHistory } from 'react-router';
import { useRoleManagementBarStyles } from './RoleManagementBar.css';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../components/SearchInput/SearchInput';

const gridBreak: any = {
  create: { lg: 6, md: 6, sm: 6, xs: 6 },
  filter: { lg: 6, md: 6, sm: 6, xs: 6 },
};
interface IRoleManagementBarProps {
  siteId: string;
  filter: { searchTerm: string };
  setFilter: Dispatch<SetStateAction<{ searchTerm: string }>>;
}

const RoleManagementBar = ({
  siteId,
  filter,
  setFilter }: IRoleManagementBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useRoleManagementBarStyles();

  const addRole = () => {
    history.push(`/site/${siteId}/setup/role/0/add`);
  }

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter(s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);


  return <BarStyledContentCard>
    <BarStyledGrid>
      <StyledGridCell {...gridBreak.create}>
        <StyledActionButton
          color="primary"
          startIcon={<AddIcon />}
          disabled={false}
          onClick={addRole}
          label={intl.formatMessage({ id: 'detail_Create_Role' })}
        />
      </StyledGridCell>
      <StyledGridCell {...gridBreak.filter} className={classes.filterContainer}>
        <SearchInput
          searchTerm={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onSubmitSearch={(term) => {
            setSearchTerm(term);
          }}
        />
      </StyledGridCell>
    </BarStyledGrid>
  </BarStyledContentCard>
};

export default RoleManagementBar;
