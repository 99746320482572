import { makeStyles } from "@material-ui/core";

export const useReportManagerListTableStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchContainer: {
    padding: theme.spacing(0.125)
  },
  title: {
    margin: theme.spacing(2.5)
  },
  pageContainer: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  }
}));
