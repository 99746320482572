import React, { ReactElement, useEffect, useMemo } from "react";
import { IHistory } from '../../interfaces/IHistory';
import { ISetBoolean } from '../../interfaces/AppGlobal/ISetBoolean';
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import { IAppGlobalDispatch } from "../../interfaces/AppGlobal/IAppGlobalDispatch";
import { IStorage } from "../../interfaces/Storage/IStorage";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SCREEN_SIZE_CHANGED,
  SET_SITES,
  RESTORE_USER_FROM_SESSION_SUCCESS,
  INITALISE_GLOBAL_STATE,
  SET_HOME_SELECTION_MODE,
  SET_DEFAULT_PAGE_REQUIRED,
  SET_HEADER_TITLE,
  RESET_FILTER
} from "../../actions/actionTypes";

import { signOutUser } from "../../libs/awsLib";
import { rootReducer, IRootState } from "../../reducers/rootReducer/rootReducer";
import { ISiteState } from "../../reducers/siteReducer/siteReducer";
import { IUserState } from "../../reducers/userReducer/userReducer";
import { IConfigState } from "../../reducers/configReducer/configReducer";
import { IAppGlobalState } from "../../interfaces/AppGlobal/IAppGlobalState";
import { ILanguageState } from "../../reducers/languageReducer/languageReducer";
import { useIntl } from "react-intl";

const initialRootState = rootReducer({}, { type: INITALISE_GLOBAL_STATE, payload: null });
const AppGlobalStateContext = React.createContext<IRootState>(initialRootState);
const AppGlobalDispatchContext = React.createContext((action: IAppGlobalAction) => { });

function AppGlobalProvider({ children }: { children: ReactElement }) {
  const [state, dispatch] = React.useReducer(rootReducer, initialRootState);
  const intl = useIntl();
  useEffect(() => {
    if (state.userState.isAuthenticated) {
      Axios.interceptors.request.use((config: AxiosRequestConfig) => {
        return config;
      }, (error: AxiosError) => {
        return Promise.reject(error);
      });

      Axios.interceptors.response.use((response: AxiosResponse) => {
        return response;
      }, (error: AxiosError) => {
        if (error.response && error.response.data && error.response.data.errorId) {
          const message = intl.formatMessage({ id: `detail_${error.response.data.errorId}` });
          return Promise.reject({ message, status: error.response.status });
        } else if (error.response && error.response.data && error.response.data.message) {
          return Promise.reject({ message: error.response.data.message, status: error.response.status });
        } else if (error.response && error.response.data && error.response.data.error) {
          return Promise.reject({ message: error.response.data.error, status: error.response.status });
        } else if (error.response && error.response.data && typeof error.response.data === 'string') {
          return Promise.reject({ message: error.response.data, status: error.response.status });
        } else {
          return Promise.reject(error);
        }
      });

    } else {
      dispatch({ type: SET_SITES, payload: [] });
    }
  }, [state.userState.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const onResize: any = React.useCallback((wn: any, event: any) => {
    dispatch({
      type: SCREEN_SIZE_CHANGED,
      payload: {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight - 120
      }
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize, false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppGlobalStateContext.Provider value={state}>
      <AppGlobalDispatchContext.Provider value={dispatch}>
        {children}
      </AppGlobalDispatchContext.Provider>
    </AppGlobalStateContext.Provider>
  );
}

function useAppGlobalState(): IAppGlobalState {
  try {
    const context: IRootState = React.useContext(AppGlobalStateContext);
    return {
      ...context.configState,
      ...context.siteState,
      ...context.userState
    };
  } catch (err) {
    throw new Error("useAppGlobalState must be used within a functional component");
  }
}

function useAppSiteState(): ISiteState {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.siteState;
  } catch (err) {
    throw new Error("useAppSiteState must be used within a functional component");
  }
}


function useAppSelectedSite(): any {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.siteState.getSelectedSite();
  } catch (err) {
    throw new Error("useAppSiteState must be used within a functional component");
  }
}

function useAppUserState(): IUserState {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.userState;
  } catch (err) {
    throw new Error("useAppUserState must be used within a functional component");
  }
}

function useAppConfigState(): IConfigState {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.configState;
  } catch (err) {
    throw new Error("useAppConfigState must be used within a functional component");
  }
}

function useAppConfigSelector(): any {
  try {
    const intl = useIntl();
    const context = React.useContext(AppGlobalStateContext);
    return useMemo(() => ({
      inspectionFreqTypes: context.configState.inspectionFreqTypes.map(i => ({
        value: i.value.toString(),
        text: intl.formatMessage({ id: i.transId })
      })),
      inspectionStepTypes: context.configState.inspectionStepTypes.map(i => ({
        value: i.value.toString(),
        text: intl.formatMessage({ id: i.transId })
      })),
      inspectionRefCompareTypes: context.configState.inspectionRefCompareTypes.map(i => ({ value: i.value.toString(), text: i.text }))
    }), [context.configState, intl]);
  } catch (err) {
    throw new Error("useAppConfigSelector must be used within a functional component");
  }
}

function useAppLanguageState(): ILanguageState {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.languageState;
  } catch (err) {
    throw new Error("useAppLanguageState must be used within a functional component");
  }
}

function useAppLineState(): Array<any> {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.lineState;
  } catch (err) {
    throw new Error("useAppLineState must be used within a functional component");
  }
}

function useAppShiftState(): Array<any> {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.shiftState;
  } catch (err) {
    throw new Error("useAppShiftState must be used within a functional component");
  }
}

function useAppProductState(): Array<any> {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.productState;
  } catch (err) {
    throw new Error("useAppProductState must be used within a functional component")
  }
}

function useConfigState(): IConfigState {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.configState;
  } catch (err) {
    throw new Error("useConfigState must be used within a functional component")
  }
}

function useIsLoading(): boolean {
  try {
    const context = React.useContext(AppGlobalStateContext);
    return context.configState.isLoading;
  } catch (err) {
    throw new Error("useConfigState must be used within a functional component")
  }
}

function useAppGlobalDispatch(): IAppGlobalDispatch {
  try {
    const context = React.useContext(AppGlobalDispatchContext);
    return context;
  } catch (err) {
    throw new Error("useAppGlobalDispatch must be used within a functional component");
  }
}


function useSelectedSite() {
  try {
    const context = React.useContext(AppGlobalStateContext);
    const sites = context.siteState.sites;
    if (sites.length === 0) {
      return null;
    }

    const selectedSites = sites.filter(s => s.id === context.siteState.selectedSiteId);
    if (selectedSites) {
      return selectedSites[0];
    }

    const selectedSitesBySelection = sites.filter(s => s.selected === true);
    if (selectedSitesBySelection) {
      return selectedSitesBySelection[0];
    }
    return null;
  } catch (err) {
    throw new Error("useAppGlobalDispatch must be used within a functional component");
  }
}

async function loginUser(dispatch: IAppGlobalDispatch, userSessionStorage: IStorage, userProfile: any, history: IHistory, setIsLoading: ISetBoolean, setError: ISetBoolean) {
  dispatch({ type: LOGIN_SUCCESS, payload: userProfile });
  setError(false);
  setIsLoading(true);

  if (userProfile) {
    userSessionStorage.setItem("cognito_user", JSON.stringify(userProfile));
    setError(false);
    setIsLoading(false);
  } else {
    userSessionStorage.removeItem("cognito_user");
    dispatch({ type: LOGIN_FAILURE, payload: null });
    setError(true);
    setIsLoading(false);
  }
}

async function restoreUserFromSession(dispatch: IAppGlobalDispatch, userProfile: any) {
  dispatch({ type: RESTORE_USER_FROM_SESSION_SUCCESS, payload: userProfile });
}

function useLogOut() {
  return async function (dispatch: IAppGlobalDispatch, history: IHistory) {
    await signOutUser();
    dispatch({ type: SET_DEFAULT_PAGE_REQUIRED, payload: true });
    dispatch({ type: SET_HOME_SELECTION_MODE, payload: 0 });
    dispatch({ type: LOGOUT_SUCCESS, payload: null });
    dispatch({ type: SET_HEADER_TITLE, payload: { title: '', icon: null, linkParams: { ignoreItSelf: true } } });
    dispatch({ type: RESET_FILTER, payload: null });
    history.push("/login");
  }
}

export {
  AppGlobalProvider,
  useAppGlobalState,
  useAppSiteState,
  useAppConfigState,
  useAppLanguageState,
  useAppUserState,
  useAppLineState,
  useAppShiftState,
  useAppProductState,
  useConfigState,
  useAppConfigSelector,
  useAppGlobalDispatch,
  loginUser,
  useLogOut,
  restoreUserFromSession,
  useSelectedSite,
  useAppSelectedSite,
  useIsLoading
};
