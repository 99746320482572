import React from 'react';
import { Box, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useFormHeadTextStyles } from './FormHeadText.css';

export const FormHeadText = ({ textId, fontSize, subtextId, textAlign }: { textId: string; subtextId?: string; fontSize?: string; textAlign?: string }) => {
  const classes = useFormHeadTextStyles();
  return <><Typography component="div">
    <Box className={classes.textContainer} fontWeight="fontWeightBold" m={1} fontSize={fontSize || 'h4.fontSize'} textAlign={textAlign || 'center'}>
      <FormattedMessage id={textId} />
    </Box>
  </Typography>
    { subtextId && <FormattedMessage id={subtextId} />}
  </>
}
