import { makeStyles } from "@material-ui/core";

export const useLinePagesStyles = makeStyles((theme) => ({
  tabIcon: {
    marginRight: theme.spacing(1.25),
    verticalAlign: 'middle',
    marginTop: theme.spacing(0.625),
  },
  displayModeLink: {
    textDecoration: 'none'
  },
  displayModeBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(1.875),
  }
}));
