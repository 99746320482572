
import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useInfoTooltipStyles } from './InfoTooltip.css';

const InfoTooltip = ({ textId, placement, extraClass }: { textId: string; placement?: any; extraClass?: string }) => {
  const classes = useInfoTooltipStyles();
  const intl = useIntl()
  return <Tooltip title={intl.formatMessage({ id: textId })} placement={placement || 'top-start'}>
    <IconButton aria-label={intl.formatMessage({ id: textId })} className={extraClass ? classNames(classes.infoBtn, extraClass) : classes.infoBtn}>
      <InfoIcon />
    </IconButton>
  </Tooltip>
}

export { InfoTooltip };
