import { invokeApig } from '../libs/awsLib';


class ProductApi {
  static getAllProducts() {
    const apiUrl = '/products';
    return invokeApig({ gatewayName: 'PRODUCTS', path: apiUrl });
  }

  static getProducts(siteId, identifierRequired = false) {
    const apiUrl = '/products/getProducts';
    return invokeApig({ gatewayName: 'PRODUCTS', path: apiUrl, queryParams: ({ siteId, ...(identifierRequired && { identifierRequired: true }) }) });
  }

  static getProductsForTimeInterval(siteId, startDt, endDt, lineId, shiftId) {
    // todo = this this to match /activeProductsForTimeInterval
    const apiUrl = `/activeProductsForTimeFrame/${siteId}`;

    const queryParams = {
      startDt,
      endDt,
    };

    if (lineId && lineId !== '' && lineId !== 'all') {
      queryParams['lineId'] = lineId;
    }
    if (shiftId && shiftId !== '' && shiftId !== 'all') {
      queryParams['shiftId'] = shiftId;
    }
    return invokeApig({
      gatewayName: 'PRODUCTS', path: apiUrl, method: 'GET', queryParams,
    });
  }

  static saveProduct(product) {
    const apiUrl = '/products';
    if (product.id === '') delete product.id; // eslint-disable-line no-param-reassign
    if (product.imageUrl === '') delete product.imageUrl; // eslint-disable-line no-param-reassign
    if (product.imageBase64 === '') delete product.imageBase64; // eslint-disable-line no-param-reassign
    if (product.lineIds === '') delete product.lineIds; // eslint-disable-line no-param-reassign
    if (product.quantity === '') delete product.quantity; // eslint-disable-line no-param-reassign
    if (product.description === '') delete product.description; // eslint-disable-line no-param-reassign
    return invokeApig({ gatewayName: 'PRODUCTS', path: apiUrl, method: 'POST', body: product });
  }

  static updateProduct(siteId, product) {
    const apiUrl = `/products/${product.id}`;
    // VCCM-1691 optional description, remove from stored record (by sending null) if empty string
    if (product.description === '') product.description = null;
    return invokeApig({
      gatewayName: 'PRODUCTS',
      path: apiUrl,
      method: 'PUT',
      queryParams: { siteId },
      body: product,
    });
  }

  static deleteProduct(siteId, id) {
    const apiUrl = `/products/${id}`;
    return invokeApig({ gatewayName: 'PRODUCTS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }

  static getProduct(siteId, id) {
    const apiUrl = `/products/${id}`;
    return invokeApig({ gatewayName: 'PRODUCTS', path: apiUrl, method: 'GET', queryParams: { siteId } });
  }
}

export default ProductApi;
