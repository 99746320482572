import { makeStyles } from "@material-ui/core";

export const useDashboardStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
  row: {
    display: 'flex',
    justifyContent: 'stretch',
    margin: theme.spacing(0, 1)
  },

  contentSmall: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  contentLarge: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%'
  }
}));
