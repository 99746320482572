import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { mySecondaryColor } from '../../../constants/colors';

const StyledTab = withStyles((theme) => ({
  root: {
    flexDirection: 'row',
    fontSize: theme.spacing(1.875),
    alignItems: 'center',
    justifyContent: 'left',
    borderBottomWidth: '3px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
  },
  selected : {
    borderBottomWidth: '3px',
    borderBottomStyle: 'solid',
    borderBottomColor: `${mySecondaryColor}`,
    transition: theme.transitions.create("border-bottom-color"),
    duration: theme.transitions.duration.complex,
    easing: theme.transitions.easing.easeInOut
  }

}))(Tab);

export default StyledTab;
