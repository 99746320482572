import React from 'react';
import classNames from 'classnames';
import { Box, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useTableHeadTextStyles } from './TableHeadText.css';

interface TableHeadTextProps {
  textId: string;
  fontSize?: string;
  textAlign?: string;
  extraClass?: string;
}

export const TableHeadText = ({ textId, fontSize, textAlign, extraClass }: TableHeadTextProps) => {
  const classes = useTableHeadTextStyles();
  return <Typography component="div">
    <Box className={extraClass ? classNames(classes.textContainer, extraClass) : classes.textContainer} fontWeight="fontWeightBold" m={1} fontSize={fontSize || 'h4.fontSize'} textAlign={textAlign || 'center'}>
      <FormattedMessage id={textId} />
    </Box>
  </Typography>
}
