import React from 'react';
import SitePrinterListRow from '../SitePrinterListRow/SitePrinterListRow';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';

const set1Breaks = {
  serial: { lg: 3, md: 3, sm: 6, xs: 12 },
  name: { lg: 3, md: 3, sm: 6, xs: 12 },
  model: { lg: 3, md: 3, sm: 6, xs: 12 },
  status: { lg: 3, md: 3, sm: 6, xs: 12 },
};

interface ISitePrinterListTableProps {
  sitePrinters: Array<any>;
}

const SitePrinterListTable = ({ sitePrinters }: ISitePrinterListTableProps) => {
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const gridBreak: any = set1Breaks;
  return sitePrinters && sitePrinters.length > 0 ? <StyledGrid>
    <StyledGridHeadRow>
      <StyledGridHeadCell {...gridBreak.serial}>
        <TableHeadText
          textId="detail_PrinterSerial"
          fontSize={tableHeadFontSize}
          textAlign={tableHeadAlign}
        />
      </StyledGridHeadCell>
      <StyledGridHeadCell {...gridBreak.name}>
        <TableHeadText
          textId="detail_PrinterName"
          fontSize={tableHeadFontSize}
          textAlign={tableHeadAlign}
        />
      </StyledGridHeadCell>
      <StyledGridHeadCell {...gridBreak.model}>
        <TableHeadText
          textId="detail_Printer_Model"
          fontSize={tableHeadFontSize}
          textAlign={tableHeadAlign}
        />
      </StyledGridHeadCell>
      <StyledGridHeadCell {...gridBreak.status}>
        <TableHeadText
          textId="detail_PrinterStatus"
          fontSize={tableHeadFontSize}
          textAlign={tableHeadAlign}
        />
      </StyledGridHeadCell>
    </StyledGridHeadRow>
    {
      sitePrinters ? sitePrinters.map(printer => (
        <SitePrinterListRow key={printer.printerId}
          printer={printer}
          gridBreak={gridBreak}
        />
      )) : null
    }
  </StyledGrid> : null;
}

export default SitePrinterListTable;
