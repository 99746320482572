import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledTabs = withStyles((theme) => ({
  flexContainer: {
    flexWrap: 'wrap',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    top: '0px',
    '& > span': {
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
}))(Tabs);

export default StyledTabs;
