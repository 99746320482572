import React from 'react';

import {
  Link
} from '@material-ui/core';
import { useFooterStyle } from './Footer.css';
import { CopyrightButton } from '../CopyrightButton/CopyrightButton';
import { useIntl } from 'react-intl';

const Footer = () => {
  const classes = useFooterStyle();
  const intl = useIntl();
  return <div className={classes.root}>
    <div className={classes.footerLinkContainer}>
      <div className={classes.footerLink}>
        <Link
          component="a"
          variant="body2"
          target="_blank"
          href="https://www.videojet.com"
        >
          <CopyrightButton />
        </Link>
      </div>

      <div className={classes.footerLink}>
        <Link
          component="a"
          variant="body2"
          target="_blank"
          href="https://www.videojet.com/us/homepage/general/privacy-policy.html"
        >
          {intl.formatMessage({ id: 'detail_PrivacyPolicy' })}
        </Link>
      </div>
      <div className={classes.footerLink}>
        <Link
          component="a"
          variant="body2"
          target="_blank"
          href="https://www.videojet.com/content/dam/pdf/NA%20-%20English/privacy-policy/Videojet-online-terms-of-use-us.pdf"
        >
          {intl.formatMessage({ id: 'detail_TermsofUse' })}
        </Link>
      </div>
    </div>

  </div>
}

export { Footer };
