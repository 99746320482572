import { makeStyles } from "@material-ui/core";

export const useProductBarStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    alignSelf: 'center'
  },
  csvUploadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    alignSelf: 'center'
  }
}));
