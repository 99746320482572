import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const spacer = 1;
const transitionBase = 'all .2s ease-in-out';

export const usePageTabsStyle = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  appPageTabTitle: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, .5)',
    transition: transitionBase,
    position: 'relative',
    padding: theme.spacing(0, 1.125)
  },
  appPageTabTitleHeading: {
    paddingRight: `${spacer}rem`,
  },
  displayButton: {
    justifySelf: 'flex-end'
  },
  extra: {
    paddingRight: theme.spacing(5)
  }
}));
