import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { useLineActions } from '../../../actions/lineActions';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

interface ILineDeleteProps {
  isDeleteConfirmVisible: boolean;
  line: any;
  setDeleteConfirmVisible: Dispatch<SetStateAction<boolean>>;
  onDeleted: () => void;
}

const LineDelete = ({
  isDeleteConfirmVisible,
  setDeleteConfirmVisible,
  line,
  onDeleted }: ILineDeleteProps) => {

  const intl = useIntl();
  const { selectedSiteId } = useAppGlobalState();

  const lineActions = useLineActions();
  const [saving, setSaving] = useState(false);

  const onCloseDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {

        setSaving(true);

        lineActions.deleteLine(line.id, selectedSiteId)
          .then(() => {
            toastr.success(intl.formatMessage({ id: 'detail_Line_Deleted' }));
            onDeleted();
          })
          .catch((error) => {
            toastr.error(error);
          })
          .finally(() => {
            setSaving(false);
          });

        return false;

      } catch (e) {
        toastr.error(e.toString());
      }
    }
    setDeleteConfirmVisible(false);
  }

  return <DeleteDialog
    saving={saving}
    title={<FormattedMessage id="detail_PleaseConfirm" />}
    open={isDeleteConfirmVisible}
    onClose={onCloseDeleteDialog}
    extraContent={<div>{line.title} {line.description ? `-${line.description}` : ''}</div>}
  />;

};

export { LineDelete };
