import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const LandingButton = withStyles((theme) => ({
  root: {
    fontSize: theme.spacing(2),
    textTransform: 'capitalize',
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    flexBasis: 0,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: theme.spacing(14),
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(8),
    },
  },
}))(Button);
