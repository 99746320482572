import React from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useSearchableMultipleSelectorStyle } from "./SearchableMultipleSelector.css";
import { Checkbox, Chip } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SearchableMultipleSelectorProps {
  id: string;
  placeholder: string;
  onChange: (event) => void;
  onReset: (event) => void;
  value: Array<any>;
  options: Array<any>;
  fieldName?: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  errors?: any;
}

function SearchableMultipleSelector({
  id,
  label,
  onChange,
  onReset,
  value,
  isDisabled,
  options }: SearchableMultipleSelectorProps) {
  const classes = useSearchableMultipleSelectorStyle();

  const removeAll = () => {
    onReset('');
  };

  const handleDelete = (item) => {
    onChange(value.filter(el => el.value !== item.value));
  }

  return <Autocomplete
    multiple
    id={id}
    value={value}
    disabled={isDisabled}
    options={options}
    fullWidth={true}
    disableCloseOnSelect
    getOptionLabel={(option) => option.text}
    renderOption={(option, { selected }) => {
      return <>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {
          option.icon && <div className={classes.icon}>{option.icon}</div>
        }
        {option.text}
      </>
    }}
    getOptionSelected={(option) => {
      // e.g value selected with enter, right from the input
      return value.find(elm => elm.value === option.value);
    }}

    onChange={(event, options, reason) => {
      if (options) {
        onChange(options)
      }
    }}
    onReset={removeAll}
    renderInput={(params) => {
      const myParams = {
        ...params, InputProps: {
          ...params.InputProps, startAdornment: value.map(el => <Chip
            key={el.value}
            className={classes.chip}
            size="small"
            icon={el.icon ? el.icon : null}
            label={el.text}
            color="secondary"
            onDelete={() => { }}
            deleteIcon={
              <div className={el.icon ? classes.clipCloseSimple : classes.clipClose}
                onMouseDown={(event) => {
                  if (!(value && value.length === 0)) {
                    event.stopPropagation()
                    handleDelete(el)
                  }
                }}
              >
                <CancelIcon className={classes.closeIcon}/>
              </div>
            }
          />)
        }
      }
      return <TextField {...myParams} variant="outlined" label={label} />
    }}
  />
}

SearchableMultipleSelector.displayName = 'SearchableMultipleSelector';

export { SearchableMultipleSelector };
