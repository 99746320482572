import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  MoveToInbox as MoveToInboxIcon
} from '@material-ui/icons';
import { useTranslationManagementBarStyles } from './TranslationManagementBar.css';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';
import { Card, FormControl } from '@material-ui/core';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { StyledDropDown } from '../../../components/StyledDropdown/StyledDropdown';
import { TranslationImportDialog } from '../TranslationImportDialog/TranslationImportDialog';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';
import ExportToFileDialog from '../ExportToFileDialog/ExportToFileDialog';
import { useAppLanguageState } from '../../../context/AppContext/AppContext';
import { useLanguageActions } from '../../../actions/languageActions';
import { CsvImportSelector } from '../CsvImportSelector/CsvImporrtSelector';

interface TranslationManagementBarProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
  languages: Array<{ value: string; text: string }>;
  refreshData: (language: string, data: Array<any>) => void;
  onCloseExportToCsvDialog: (languages: Array<string>, fileName: string, emptySelected?: boolean) => void;
  onCloseExportToJsonDialog: (languages: Array<string>, fileName: string, emptySelected?: boolean) => void;
}

const TranslationManagementBar = ({
  setLoading,
  languages,
  refreshData,
  onCloseExportToCsvDialog,
  onCloseExportToJsonDialog
}: TranslationManagementBarProps) => {
  const intl = useIntl();
  const classes = useTranslationManagementBarStyles();
  const { filter } = useAppLanguageState();
  const languageActions = useLanguageActions();

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  const [searchingKeyOnly, setSearchingKeyOnly] = useState<boolean>(filter.searchingKeyOnly);
  const [blankValueOnly, setBlankValueOnly] = useState<boolean>(filter.blankValueOnly);
  const [selectedLanguages, setSelectedLanguages] = useState<Array<string>>(filter.selectedLanguages);

  const [fileInfo, setFileInfo] = React.useState<any>({});
  const [importedItems, setImportedItems] = useState<Array<any>>([]);
  const [exportToCsvVisible, setExportToCsvVisible] = useState<boolean>(false);
  const [exportToJsonVisible, setExportToJsonVisible] = useState<boolean>(false);

  const debouncedSearchingKeyOnly = useDebounce(searchingKeyOnly, DEBOUNCED_SEARCH_DELAY);
  const debouncedBlankValueOnly = useDebounce(blankValueOnly, DEBOUNCED_SEARCH_DELAY);
  const debouncedSelectedLanguages = useDebounce(selectedLanguages, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    languageActions.updateLanguageFilter(s => ({
      ...s,
      searchTerm,
      searchingKeyOnly,
      blankValueOnly,
      selectedLanguages
    }));
  }, [debouncedSearchTerm, debouncedSearchingKeyOnly, debouncedBlankValueOnly, debouncedSelectedLanguages]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCsvFileImported = (fileInfo, newlyImportedItems) => {
    setFileInfo(fileInfo);
    setImportedItems(newlyImportedItems);
    setLoading(false);
  }

  const handleImportClose = (lang, insertedItems) => {
    setImportedItems([]);
    setFileInfo({});
    if (insertedItems.length > 0) {
      refreshData(lang, insertedItems);
    }
  }

  const performCloseExportToCsv = (languages: Array<string>, fileName: string, emptySelected?: boolean) => {
    setExportToCsvVisible(false);
    onCloseExportToCsvDialog(languages, fileName, emptySelected);
  }

  const performCloseExportToJson = (languages: Array<string>, fileName: string, emptySelected?: boolean) => {
    setExportToJsonVisible(false);
    onCloseExportToJsonDialog(languages, fileName, emptySelected);
  }

  return (<Card className={classes.root}>
    <div className={classes.barContainer}>
      <div className={classes.barItem}>
        <FormControl variant="outlined" className={classes.formControl}>
          <StyledDropDown
            labelId="selectedLanguages"
            name="selectedLanguages"
            multiple={true}
            ignoreAll={true}
            enableRemoveButton={true}
            label={intl.formatMessage({ id: "detail_SelectedLanguages" })}
            placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
            options={languages}
            value={selectedLanguages || []}
            onChange={(value) => {
              setSelectedLanguages(value);
            }}
          />
        </FormControl>
      </div>

      <div className={classes.barItem}>
        <div className={classes.barItemTitle}>
          {intl.formatMessage({ id: 'detail_SearchKeyOnly' })}
        </div>
        <div className={classes.barItemControl}>
          <StyledSwitch checked={searchingKeyOnly}
            onChange={(e, checked) => setSearchingKeyOnly(checked)}
            name="isSearchingKeyOnly" />
        </div>
      </div>

      <div className={classes.barItem}>
        <div className={classes.barItemTitle}>
          {intl.formatMessage({ id: 'detail_BlankValueOnly' })}
        </div>
        <div className={classes.barItemControl}>
          <StyledSwitch checked={blankValueOnly}
            onChange={(e, checked) => setBlankValueOnly(checked)}
            name="bankValueOnly" />
        </div>
      </div>

      <div className={classes.barItem}>
        <SearchInput
          searchTerm={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onSubmitSearch={(term) => {
            setSearchTerm(term);
          }}
        />
      </div>
      <div className={classes.barItem} >
        <CsvImportSelector
          csvUploadId="__csvUploadId_main"
          disabled={false}
          buttonIcon={<CloudUploadIcon />}
          buttonTextId="detail_ImportCSV"
          setLoading={setLoading}
          onCsvFileImported={onCsvFileImported}
        />
      </div>
      <div className={classes.barItem}>
        <StyledActionButton
          color="primmary"
          startIcon={<CloudDownloadIcon />}
          onClick={() => setExportToCsvVisible(true)}
          label={intl.formatMessage({ id: 'detail_ExportToCsv' })}
        />
      </div>
      <div className={classes.barItem}>
        <StyledActionButton
          color="primmary"
          startIcon={<MoveToInboxIcon />}
          onClick={() => setExportToJsonVisible(true)}
          label={intl.formatMessage({ id: 'detail_ExportToJson' })}
        />
      </div>
    </div>
    {
      importedItems && importedItems.length > 0 && <TranslationImportDialog
        initialFileInfo={fileInfo}
        open={importedItems && importedItems.length > 0}
        initiallyImportedItems={importedItems}
        onClose={handleImportClose}
        allLanguages={languages}
        setLoading={setLoading}/>
    }

    <ExportToFileDialog
      title={intl.formatMessage({ id: 'detail_ExportToCsv' })}
      showEmptyChoice={true}
      open={exportToCsvVisible}
      onClose={performCloseExportToCsv}
      allLanguages={languages} />

    <ExportToFileDialog
      title={intl.formatMessage({ id: 'detail_ExportToJson' })}
      showEmptyChoice={false}
      open={exportToJsonVisible}
      onClose={performCloseExportToJson}
      allLanguages={languages} />
  </Card>
  )
};

export default TranslationManagementBar;
