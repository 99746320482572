import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import StyledGridCell from '../../../../components/Basic/StyledGridCell/StyledGridCell';
import BarStyledContentCard from '../../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import BarStyledGrid from '../../../../components/Basic/BarStyledGrid/BarStyledGrid';
import { StyledActionButton } from '../../../../components/Basic/StyledActionButton/StyledActionButton';
import { useHistory } from 'react-router';
import Can from '../../../../components/Common/Can';
import { useShiftBarStyles } from './Shift.css';
import { DEBOUNCED_SEARCH_DELAY } from '../../../../constants/global';
import { useDebounce } from '../../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';

const gridBreak: any = {
  create: { lg: 6, md: 6, sm: 7, xs: 7 },
  filter: { lg: 6, md: 6, sm: 5, xs: 5 },
};

interface IShiftBarProps {
  siteId: string;
  isShift: boolean;
  filterName: string;
  filter: { searchTerm: string };
  setFilter: (string, any) => void;
}

const ShiftBar = ({
  siteId,
  isShift,
  filterName,
  filter,
  setFilter }: IShiftBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useShiftBarStyles();

  const addShift = () => {
    history.push(`/site/${siteId}/setup/shift/0/add/${isShift ? 1 : 3}`);
  }

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter(filterName, s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter, filterName]);

  return <BarStyledContentCard>
    <BarStyledGrid>
      <Can do="edit" on="schedule">
        <StyledGridCell {...gridBreak.create}>
          <div className={classes.buttonContainer}>
            {
              isShift && <StyledActionButton
                color="primary"
                startIcon={<AddIcon />}
                onClick={addShift}
                label={intl.formatMessage({ id: 'detail_Add_a_Shift' })}
              />
            }

            {
              !isShift && <StyledActionButton
                color="primary"
                startIcon={<AddIcon />}
                onClick={addShift}
                label={intl.formatMessage({ id: 'detail_Add_a_PlannedDowntime' })}
              />
            }
          </div>
        </StyledGridCell>
      </Can>
      <StyledGridCell {...gridBreak.filter} className={classes.filterContainer}>
        <SearchInput
          searchTerm={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onSubmitSearch={(term) => {
            setSearchTerm(term);
          }}
        />
      </StyledGridCell>
    </BarStyledGrid>

  </BarStyledContentCard>
};

export { ShiftBar };
