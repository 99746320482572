import { invokeApig } from '../libs/awsLib';

class NotificationApi {
  static listReportSchedules(params: any = {}) {
    const { siteId, uid } = params;
    const apiUrl = '/schedule';
    return invokeApig({
      gatewayName: 'NOTIFICATION',
      method: 'GET',
      path: apiUrl,
      queryParams: ({
        ...(siteId && ({ siteId })),
        ...(uid && ({ uid })),
      }),
    });
  }

  static listReportSchedulesByDist(siteId, uid) {
    const apiUrl = '/scheduleListByDist';
    return invokeApig({ gatewayName: 'NOTIFICATION', method: 'GET', path: apiUrl, queryParams: { siteId, uid } });
  }

  static listSiteReports(siteId) {
    const apiUrl = `/reports/site/${siteId}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'GET' });
  }

  static listUserReports(siteId, userId) {
    const apiUrl = `/reports/user/${siteId}/${userId}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'GET' });
  }

  static removeGlobalReport(siteId, title) {
    const apiUrl = `/reports/site/${siteId}/${encodeURIComponent(title)}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'DELETE' });
  }

  static removeUserReport(siteId, userId, title) {
    const apiUrl = `/reports/user/${siteId}/${userId}/${encodeURIComponent(title)}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'DELETE' });
  }

  /**
   * Save or update a global report
   *
   * @param {Object} userReport - report definition to save
   * @param {boolean} overwrite - force an update
   * @returns {Promise<*>} the new saved report or error
   */
  static saveGlobalUserReport(userReport, overwrite = false) {
    const { id, siteId } = userReport;
    const apiUrl = `/reports/site/${siteId}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: (id || overwrite) ? 'PUT' : 'POST', body: userReport });
  }

  static saveUserReport(userReport, overwrite = false) {
    const { id } = userReport;
    const apiUrl = '/reports/user';
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: (id || overwrite) ? 'PUT' : 'POST', body: userReport });
  }

  static scheduleCreate(reportNotification) {
    const apiUrl = '/schedule';
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'POST', body: reportNotification });
  }

  static scheduleRemove(reportNotification) {
    const apiUrl = `/schedule/${reportNotification.siteId}/${reportNotification.userId}/${reportNotification.title}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'DELETE', body: reportNotification });
  }

  static scheduleUpdate(reportNotification) {
    const apiUrl = '/schedule';
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'PUT', body: reportNotification });
  }

  static scheduleGet(siteId, userId, title) {
    const apiUrl = `/schedule/${siteId}/${userId}/${encodeURIComponent(title)}`;
    return invokeApig({ gatewayName: 'NOTIFICATION', path: apiUrl, method: 'GET' });
  }
}

export default NotificationApi;
