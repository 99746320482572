import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import BarStyledGrid from '../../../components/Basic/BarStyledGrid/BarStyledGrid';
import { useHistory } from 'react-router';
import Can from '../../../components/Common/Can';
import { useReportNotificationSetupBarStyles } from './ReportNotificationSetupBar.css';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';

const gridBreak: any = {
  create: { lg: 6, md: 6, sm: 7, xs: 7 },
  filter: { lg: 6, md: 6, sm: 5, xs: 5},
};

interface IReportNotificationSetupBarProps {
  siteId: string;
  userId: string;
  filter: { searchTerm: string };
  setFilter: (string, any) => void;
}

const ReportNotificationSetupBar = ({
  siteId,
  userId,
  filter,
  setFilter }: IReportNotificationSetupBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useReportNotificationSetupBarStyles();

  const addReportNotificationSetup = () => {
    history.push(`/site/${siteId}/scheduledReport/${userId}/0/add`);
  }

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('reportSchedulerFilter', s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);

  return (<BarStyledContentCard>
    <BarStyledGrid>
      <Can do="edit" on="line">
        <StyledGridCell {...gridBreak.create}>
          <div className={classes.buttonContainer}>
            <StyledActionButton
              color="primary"
              startIcon={<AddIcon />}
              onClick={addReportNotificationSetup}
              label={intl.formatMessage({ id: 'detail_Create_a_ScheduledReport' })}
            />
          </div>
        </StyledGridCell>
      </Can>
      <StyledGridCell {...gridBreak.filter} className={classes.filterContainer}>
        <SearchInput
          searchTerm={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onSubmitSearch={(term) => {
            setSearchTerm(term);
          }}
        />
      </StyledGridCell>
    </BarStyledGrid>
  </BarStyledContentCard>
  )
};

export { ReportNotificationSetupBar };
