import momentTimeZone from 'moment-timezone';
import { extendMoment } from 'moment-range';

// Pond
import { TimeRange, TimeRangeEvent, TimeSeries } from 'pondjs';
import { array, bool, func, object, string, number } from 'prop-types';
import React from 'react';

// Imports from the charts library
import { ChartContainer, ChartRow, Charts, EventChart } from 'react-timeseries-charts';
import { Tooltip } from '@progress/kendo-react-tooltip';
import TooltipContentTemplate from './TooltipContentTemplate/TooltipContentTemplate';
import DateHelper from '../../helpers/DateHelper/DateHelper';
import SchedulerHelper from '../../helpers/SchedulerHelper/SchedulerHelper';
import SiteHelper from '../../helpers/SiteHelper/SiteHelper';
import Utils from '../../utilities/utils';
import ChartHelper from './ChartHelper'
import { useIntl } from 'react-intl';
import { Logger } from '../../utilities/Logger/Logger';
import ResizableChart from '../ResizableChart/ResizableChart';
const moment = extendMoment(momentTimeZone as any);

const ChartTimeStateBar: any = (props) => {
  const [lineId] = React.useState(props.lineId);
  const [selectedEventId] = React.useState(props.selectedEventId);
  const [tooltip, setTooltip] = React.useState(
    {
      display: false,
      data: {
        key: '',
        value: '',
      },
    }
  );

  const getTickCount = (events) => {
    let chartWidth;
    const stateChart = document.getElementById('time-in-state-chart');
    const stateChartModal = document.getElementById('time-in-state-chart-modal');
    if (stateChartModal) {
      chartWidth = stateChartModal.clientWidth;
    } else if (stateChart) {
      chartWidth = stateChart.clientWidth;
    }

    // ideally would show a tick for each hour of range. ticks are generated in 2, 5 or 10s
    const range = moment().range(events[0].startTime, events[events.length - 1].endTime);
    const hours = Array.from(range.by('hour', { excludeEnd: false }));

    if (hours.length < 5) {
      return 5;
    }
    if (chartWidth / hours.length < 40) {
      return Math.round(hours.length / 3);
    }

    return hours.length;
  }


  const getDetailFromEvent = (event) => {
    const eventParsed = JSON.parse(event);
    const [eventStart, eventEnd] = eventParsed.timerange;
    const diff = momentTimeZone(eventEnd).diff(eventStart);
    const durationText = DateHelper.humanizeDuration(momentTimeZone.duration(diff));
    const eventDetail = {
      type: eventParsed.data.type,
      reasonId: eventParsed.data.reasonId,
      reasonNote: eventParsed.data.reasonNote,
      secondReasonId: eventParsed.data.secondReasonId,
      subReasonNote: eventParsed.data.subReasonNote,
      typeId: eventParsed.data.typeId,
      id: eventParsed.data.id,
      shiftId: eventParsed.data.shiftId,
      status: eventParsed.data.status,
      startDT: eventStart,
      endDT: eventEnd,
      length: durationText,
      lineId: lineId,
    };
    return {
      diff,
      eventDetail,
    };
  };

  const getLabel = (event) => {
    const { diff } = getDetailFromEvent(event);
    return `${momentTimeZone.duration(diff)
      .asMinutes()
      .toFixed(0)}`;
  };

  const getTimeTick = (time) => {
    let mins = time.getMinutes()
      .toString();
    if (mins.length === 1) {
      mins += '0';
    }
    return `${time.getHours()
      .toString()}:${mins}`;
  };

  // Turn data into TimeSeries
  const formatIntoTimeSeries = (outageEvents) => {
    if (outageEvents === undefined || outageEvents.length === 0) return undefined;
    const events = outageEvents.map(
      ({ startTime, endTime, ...data }) => {
        const startTimeTransformed = Utils.getLocalSiteDateObject(new Date(startTime), props.timezone);
        const endTimeTransformed = Utils.getLocalSiteDateObject(new Date(endTime), props.timezone);
        return new TimeRangeEvent(
          new TimeRange(startTimeTransformed, endTimeTransformed), data,
        );
      },
    );

    return new TimeSeries({
      name: 'TimeInState',
      events,
    });
  };

  const filterElements = element => {
    const hasTooltip = !!(element.className && element.className.baseVal === 'eventchart-marker');
    if (hasTooltip) {
      element.title = 'event chart'; // this title is required to trigger tooltip
    }
    return hasTooltip;
  }

  const eventStyleFunc = (event, eventState) => {
    const { eventDetail } = getDetailFromEvent(event);
    // default
    let retObj: any = {
      fill: '#2185d0',
      opacity: 1,
    };

    switch (eventDetail.type) {
      case 'NoShiftScheduled':
        retObj.fill = '#f4f4f4';
        break;
      case 'downtime':
        if (eventDetail.status === 'Disconnected') { // this printer disconnected
          retObj.fill = '#4f4c4f';
        } else if (checkIfEventIsPlannedDowntime(eventDetail)) {
          retObj.fill = '#f5d636';
        } else {
          retObj.fill = '#ff5132';
        }
        break;
      case 'micro':
        if (eventDetail.status === 'Disconnected') { // this printer disconnected
          retObj.fill = '#4f4c4f';
        } else {
          retObj.fill = '#f28d6b';
        }
        break;
      case 'schedule':
        if (eventDetail.typeId < 2) { // not autostandby
          if (new Date(eventDetail.startDT) >= Utils.getLocalSiteDateObject(nowDt, props.timezone)) {
            // if a future event
            retObj.fill = '#2185d0';
          } else {
            retObj.fill = eventDetail.status === 'Disconnected' ? '#4f4c4f' : '#3cba22';
          }
        } else {
          retObj.fill = '#f5d636';
        }
        break;
      case 'scheduled downtime':
        retObj.fill = '#f5d636';
        break;
      case 'slowCycle':
        retObj.fill = '#94f533';
        break;
      default:
        if (eventDetail.type.substring(0, 9) === 'slowCycle') {
          retObj.fill = '#94f533';
        }
        break;
    }

    switch (eventState) {
      case 'normal':
        break;

      case 'hover':
        retObj.opacity = 0.7;
        break;

      case 'selected':
        retObj = {
          fill: '#ba1fa5',
          opacity: 0.5,
          border: '10px solid green',
        };
        break;

      default:
        break;
      // pass
    }
    if (selectedEventId !== undefined && eventDetail.id === selectedEventId) {
      retObj.fill = '#ba1fa5';
      retObj.border = '2px solid black';
      retObj.opacity = 0.2;
    }
    return retObj;
  }

  const checkIfEventIsPlannedDowntime = (eventDetail) => {
    const category = SiteHelper.getCategoryById(props.downtimeCategories, (eventDetail.reasonId));
    return category && category.typeId < 2;
  }


  const handleClick = (event) => {
    const { eventDetail } = getDetailFromEvent(event);
    return props.eventSelected && props.eventSelected(eventDetail);
  }


  const hideToolTip = () => {
    setTooltip({
      display: false,
      data: {
        key: '',
        value: '',
      },
    })
  }

  const showToolTip = (event, props) => {
    const { eventDetail } = getDetailFromEvent(event);

    function getTrans(key) {
      return key && intl.formatMessage({
        id: `detail_${key.charAt(0)
          .toUpperCase()}${key.substring(1)}`
      });
    }

    let typeText = `${(getTrans(eventDetail.type).toUpperCase())} ${eventDetail.reasonId
      ? (eventDetail.reasonId === '1' || eventDetail.reasonId === 1)
        ? `${getTrans(SiteHelper.getCategoryNameById(props.downtimeCategories, (eventDetail.reasonId)))}`.toUpperCase()
        : `${SiteHelper.getCategoryNameById(props.downtimeCategories, (eventDetail.reasonId))}`.toUpperCase()
      : ''} `;

    const nowPoint = data.find(p => p.type === 'now');
    const nowDt = new Date(nowPoint && nowPoint.startTime);
    const siteNow = Utils.getLocalSiteDateObject(nowDt, timezone);
    const startDt = new Date(eventDetail.startDT);
    const activeEventType = eventDetail.typeId === 1 && startDt.valueOf() >= siteNow.valueOf() ? 5 : eventDetail.typeId;

    let category = activeEventType
      ? (`${getTrans(SchedulerHelper.getEventTypeById(activeEventType))}`).toUpperCase()
      : '';

    if (eventDetail.type === 'NoShiftScheduled') {
      category = '';
    }

    if (eventDetail.status === 'Disconnected') {
      category = '';
      typeText = intl.formatMessage({ id: 'detail_PrinterStatus_Disconnected' }).toUpperCase();
    }

    if (eventDetail.type.substring(0, 9) === 'slowCycle') {
      category = '';
      typeText = intl.formatMessage({ id: 'detail_SlowCycle' }).toUpperCase();
    }

    const newVar = {
      display: true,
      length: eventDetail.length,
      data: {
        key: typeText,
        value: category,
      },
      note: eventDetail.reasonNote,
      pos: {
        x: 400,
        y: 75,
      },
    };
    setTooltip(newVar);
  }

  const intl = useIntl();

  const { timeLine, slowCycles, mergedTimeLine, style, timezone } = props;

  const data = mergedTimeLine || ChartHelper.mergeTimeLine(timeLine, slowCycles);
  const series: any = (data && timezone) ? formatIntoTimeSeries(data) : {};

  if (!series) {
    return intl.formatMessage({ id: 'detail_No_data' });
  }

  const nowPoint = data.find(p => p.type === 'now');
  const nowDt = new Date(nowPoint && nowPoint.startTime);
  Logger.of('App.ChartTimeStateBar').trace('Now is ', nowDt);

  const mainContent =
    <ResizableChart>
      <ChartContainer
        trackerPosition={Utils.getLocalSiteDateObject(nowDt, timezone)}
        timeRange={series.timerange()}
        enablePanZoom={false}
        format={getTimeTick}
        timeAxisTickCount={getTickCount(data)}
        minDuration={1.8e+6} // in ms - lowest possible interval is 30 minutes
        timeAxisStyle={
          {
            values:
              props.large
                ?
                {
                  'font-size': '1.8rem',
                  'font-style': 'oblique'
                }
                : {}
          }
        }
        {...(props.timeAxisAngledLabels
          ? {
            timeAxisAngledLabels: true,
            timeAxisHeight: 45
          }
          : {})}
      >
        <ChartRow
          height={props.large ? 80 : 40}
          trackerShowTime={true}>
          <Charts>
            <EventChart
              label={e => getLabel(e)}
              onMouseOver={!props.large ? (e => showToolTip(e, props)) : undefined}
              onMouseLeave={!props.large ? hideToolTip : undefined}
              onMouseOut={!props.large ? hideToolTip : undefined}
              onSelectionChange={handleClick}
              series={series}
              size={props.large ? 90 : 45}
              hoverMarkerWidth={0}
              style={eventStyleFunc}
              data-key="Id"
              data-value="12345"
              textOffsetY={0}
              textOffsetX={-7}
            />
          </Charts>
        </ChartRow>
      </ChartContainer>
    </ResizableChart>;

  return <div
    style={style}>
    {!props.large ?
      <Tooltip
        position="bottom"
        filter={filterElements}
        anchorElement="target"
        content={props => <TooltipContentTemplate
          tooltip={tooltip}
          title={props.title}
        />}
      >
        {mainContent}
      </Tooltip> : mainContent
    }
  </div>;
}

ChartTimeStateBar.propTypes = {
  allowComponentUpdates: bool,
  downtimeCategories: array,
  eventSelected: func,
  lineId: string,
  selectedEventId: string,
  slowCycles: array,
  style: object,
  timeAxisAngledLabels: bool,
  timeLine: array,
  mergedTimeLine: array,
  timezone: string.isRequired,
  large: bool,
  title: string,
  currentSideBarState: bool,
  timeStateBarWidth: number
};

ChartTimeStateBar.defaultProps = {
  slowCycles: [],
  timeLine: [],
  allowComponentUpdates: true,
  large: false,
  timeStateBarWidth: 0
};

export default ChartTimeStateBar;
