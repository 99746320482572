import { claims } from 'vccm-common';
import ability from "./ability";
import UserHelper from "./helpers/UserHelper";
import Utils from "./utilities/utils";

export interface IAccessItem {
  hidden?: boolean;
  hasAccess: boolean;
  link: string;
  targetLink?: string;
}

const canAccess = (isAdmin: boolean, action: string, subject: string) => {
  return isAdmin || !!UserHelper.Can(action, subject);
}

export const routeAccess = (site: any, isAdmin: boolean, selectedSiteId: string): Array<IAccessItem> => {
  const canAccessDownload = isAdmin || !!ability.rules.find(r => (r.subject === 'printerJob') && (r.actions === 'send' || r.actions === 'download'));
  const isSubscriptionStatusNotTrial = site && site.subscriptionStatus !== 'trial';

  const canAccessVrsWithField = (field) => !!UserHelper.CanWithField('vrs', 'authModule', field);
  const isInspectionModuleLicensed = claims.moduleLicensed(site, claims.licensedModuleInfo.inspect);

  const hasModuleMissing = (module) => {
    return site && (!site.modules || (site.modules && site.modules.values && Array.from(site.modules.values).indexOf(module) === -1));
  }

  return [
    {
      hidden: !isInspectionModuleLicensed,
      hasAccess: canAccess(isAdmin, 'view', 'inspection'),
      link: `/site/${selectedSiteId}/setup/inspections`
    },
    {
      hidden: !isInspectionModuleLicensed,
      hasAccess: canAccess(isAdmin, 'view', 'inspection'),
      link: `/site/${selectedSiteId}/inspections`
    },
    {
      hidden: !isInspectionModuleLicensed,
      hasAccess: canAccess(isAdmin, 'view', 'inspection'),
      link: `/site/${selectedSiteId}/setup/compliance`
    },
    {
      hidden: !isInspectionModuleLicensed,
      hasAccess: canAccess(isAdmin, 'view', 'inspection'),
      link: `/site/${selectedSiteId}/inspection/report`
    },
    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: canAccess(isAdmin, 'edit', 'printerJob'),
      link: `/site/${selectedSiteId}/setup/execution`
    },
    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: canAccess(isAdmin, 'edit', 'printerJob'),
      link: `/site/${selectedSiteId}/execute/configure`
    },
    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: canAccess(isAdmin, 'execute', 'printerJob'),
      link: `/site/${selectedSiteId}/execute/order`
    },
    {
      hasAccess: isAdmin || !hasModuleMissing('Intelligence'),
      link: `/site/${selectedSiteId}/lines/overview`
    },
    {
      hasAccess: isAdmin || isSubscriptionStatusNotTrial,
      link: `/site/${selectedSiteId}/lines`
    },
    {
      hasAccess: isAdmin || (!Utils.isGuid(selectedSiteId || '') && canAccess(isAdmin, 'view', 'report')),
      link: `/site/${selectedSiteId}/report`
    },
    {
      hasAccess: isAdmin,
      link: '/sites/operations'
    },
    {
      hasAccess: canAccess(isAdmin, 'view', 'sites'),
      link: '/sites'
    },
    {
      hasAccess: canAccessVrsWithField('Administrator') || isAdmin || (isSubscriptionStatusNotTrial && canAccess(isAdmin, 'view', 'siteDef')),
      link: '/setup/site',
      targetLink: '/sites'
    },
    {
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'line') || canAccess(isAdmin, 'edit', 'line'))),
      link: `/site/${selectedSiteId}/setup/lines`
    },
    {
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'product') || canAccess(isAdmin, 'edit', 'product'))),
      link: `/site/${selectedSiteId}/setup/products`
    },
    {
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'schedule') || canAccess(isAdmin, 'edit', 'schedule'))),
      link: `/site/${selectedSiteId}/shifts`
    },
    {
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'downtimeReason') || canAccess(isAdmin, 'edit', 'downtimeReason'))),
      link: `/site/${selectedSiteId}/setup/downtimes`
    },
    {
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && canAccess(isAdmin, 'create', 'user')),
      link: `/site/${selectedSiteId}/setup/displayMode`
    },

    {
      hidden: !Utils.isOrderExEnabled(),
      hasAccess: isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'deviceRole') || canAccess(isAdmin, 'edit', 'deviceRole'))),
      link: `/site/${selectedSiteId}/setup/deviceroles`
    },
    {
      hasAccess: canAccessVrsWithField('Administrator') || isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'user') || canAccess(isAdmin, 'create', 'user'))),
      link: `/site/${selectedSiteId}/setup/users`
    },

    {
      hasAccess: canAccessVrsWithField('Administrator') || isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'user') || canAccess(isAdmin, 'create', 'user'))),
      link: `/site/${selectedSiteId}/setup/user`
    },

    {
      hasAccess: canAccessVrsWithField('Administrator') || isAdmin || (isSubscriptionStatusNotTrial && (canAccess(isAdmin, 'view', 'user') || canAccess(isAdmin, 'create', 'user'))),
      link: `/site/${selectedSiteId}/setup/role`
    },

    {
      hasAccess: canAccessDownload,
      link: `/site/${selectedSiteId}/Download`
    }
  ]
};

export const getLinkAccessInfo = (site: any, isAdmin: boolean, selectedSiteId: string) => {
  const accessList = routeAccess(site, isAdmin, selectedSiteId);
  return (link: string) => {
    const targetLink = link.toLowerCase();
    const access = accessList.find(el => targetLink.indexOf(el.link.toLowerCase()) > -1);
    if (access) {
      return {
        disabled: !access.hasAccess,
        link,
        hidden: access.hidden,
        targetLink: access.targetLink
      }
    }

    return {
      link
    }
  }
}
