import React from "react";
import { SimpleMessage } from "../SimpleMessage/SimpleMessage";
import Header from '../Header/Header';

function Error() {
  return (
    <div>
      <Header />
      <SimpleMessage title="404 - Not found" subtitle="The page you are looking for cannot be found." />
    </div>
  );
}

Error.displayName = 'Error';

export { Error };
