import React from 'react';
import { useCopyrightButtonStyle } from './CopyrightButton.css';

const CopyrightButton = () => {
  const classes = useCopyrightButtonStyle();
  return (
    <div className={classes.root}>
      VideojetConnect © {(new Date()).getFullYear()} Videojet
    </div>
  )
};

export { CopyrightButton }
