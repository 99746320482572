import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { useNotificationActions } from '../../../actions/notificationActions';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';

interface IReportNotificationDeleteProps {
  isDeleteConfirmVisible: boolean;
  schedule: any;
  setDeleteConfirmVisible: Dispatch<SetStateAction<boolean>>;
  onDeleted: () => void;
}

const ReportNotificationDelete = ({
  isDeleteConfirmVisible,
  setDeleteConfirmVisible,
  schedule,
  onDeleted }: IReportNotificationDeleteProps) => {

  const intl = useIntl();
  const notificationActions = useNotificationActions();
  const [saving, setSaving] = useState(false);

  const onCloseDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);
        notificationActions.deleteSchedule(schedule)
          .then(() => {
            toastr.success(intl.formatMessage({ id: 'detail_Deleted' }));
            onDeleted();
          })
          .catch((error) => {
            toastr.error(error);
          })
          .finally(() => {
            setSaving(false);
          });

        return false;

      } catch (e) {
        toastr.error(e.toString());
      }
    }
    setDeleteConfirmVisible(false);
  }

  return <DeleteDialog
    saving={saving}
    title={<FormattedMessage id="detail_PleaseConfirm" />}
    open={isDeleteConfirmVisible}
    onClose={onCloseDeleteDialog}
    extraContent={<div>{schedule.title} {schedule.description ? `-${schedule.description}` : ''}</div>}
  />;

};

export { ReportNotificationDelete };
