import React, { useEffect, useState } from 'react';
import { Home as HomeIcon } from '@material-ui/icons';
import { useHomePageStyles } from './HomePage.css';
import classNames from 'classnames';
import StyledContentCard from '../../components/Basic/StyledContentCard/StyledContentCard';
import { useIntl } from 'react-intl';
import { useSetTitleAction } from '../../actions/useActions/useSetTitleAction/useSetTitleAction';
import UserHelper from '../../helpers/UserHelper';
import { useAppGlobalState } from '../../context/AppContext/AppContext';
import { claims } from 'vccm-common';
import UsersApi from '../../api/prodUserApi';
import { useHistory } from 'react-router';
import { useConfigActions } from '../../actions/configActions';
import * as config from '../../config';
import { Divider, Grid } from '@material-ui/core';
import { LandingButton } from '../../components/Basic/LandingButton/LandingButton';
import vccmImage from "../../vj-videojet-connect.jpeg";
import { Logger } from '../../utilities/Logger/Logger';
import { InfoDialog } from '../../components/InfoDialog/InfoDialog';
import {
  VideojetConnectDesign,
  VideojetConnectExchange,
  VideojetConnectInsight,
  VideojetConnectInspect,
  VideojetConnectRemoteService
} from '../../constants/global';

const gridBreaks: any = {
  set1: { lg: 6, md: 6, sm: 6, xs: 12 },
  set2: { lg: 6, md: 6, sm: 6, xs: 12 },
};

const HomePage = () => {
  const classes = useHomePageStyles();
  const configActions = useConfigActions();
  const intl = useIntl();
  const setTitle = useSetTitleAction();
  const history = useHistory();
  const [showUserRestrictionDialog, setShowUserRestrictionDialog] = useState(false);

  const { sites, homeSelectionMode, selectedSiteId, isAdmin } = useAppGlobalState();

  useEffect(() => {
    setTitle('detail_Home', <HomeIcon />, {
      ignoreItself: true
    });
  }, [setTitle]);

  useEffect(() => {
    if (homeSelectionMode === 2) {
      configActions.setHomeSelectionMode(1);
    }
  }, [homeSelectionMode, configActions]);

  const site = sites ? sites.find(el => el.id === selectedSiteId) : null;

  const showVrsAccess = async () => {
    const canAccessVrs = !!UserHelper.Can('vrs', 'authModule');
    const siteHasAuthModule = claims.siteHasForeignAuthModule(site, claims.siteForeignModuleAuthMap.vrs);
    // ReactGA.event({
    //   category: 'VRS',
    //   action: 'VRS Launched',
    // });
    const endpoint = config.default.apiGateway.VRS_URL;
    if (canAccessVrs) {
      const { token } = await UsersApi.getVrsLoginToken(selectedSiteId);
      window.open(`${endpoint}${endpoint && endpoint[endpoint.length - 1] === '/' ? '' : '/'}MigratedUserLogin?Token=${token}`, '_blank');
    } else if (!siteHasAuthModule) {
      window.open(endpoint, '_blank');
    } else {
      setShowUserRestrictionDialog(true);
    }
  }

  const gotoIntelligence = (event) => {
    event.preventDefault();
    if (UserHelper.CanAccessModule('Intelligence')) {
      configActions.setHomeSelectionMode(1);
      history.push(`/site/${selectedSiteId}/lines/overview`);
    } else {
      setShowUserRestrictionDialog(true);
    }
  }

  const gotoInspect = (event) => {
    event.preventDefault();
    if (UserHelper.CanAccessModule('Inspect')) {
      configActions.setHomeSelectionMode(1);
      history.push(`/site/${selectedSiteId}/inspect`);
    } else {
      setShowUserRestrictionDialog(true);
    }
  }

  const showExchange = async () => {
    // ReactGA.event({
    //   category: 'VRS',
    //   action: 'VRS Launched',
    // });
    const endpoint = config.default.apiGateway.API_APP_URL;
    window.open(endpoint, '_blank');
  }

  const gotoApi = (event) => {
    event.preventDefault();
    if (UserHelper.CanAccessModule('api')) {
      configActions.setHomeSelectionMode(1);
      showExchange();
    } else {
      setShowUserRestrictionDialog(true);
    }

  }

  const showCirrus = async () => {
    const canAccessCirrus = UserHelper.Can('edit', 'design');
    // ReactGA.event({
    //   category: 'Design',
    //   action: 'Design Launched',
    // })
    if (canAccessCirrus || isAdmin) {
      const token = await UsersApi.getCirrusToken(selectedSiteId)
      const cirrusUrl = config.default.apiGateway.CIRRUS_URL
      Logger.of('SideBar.render').info(`Launching Cirrus url:${cirrusUrl}#/templatemanager?debug=true&tkn=${token}`);
      const chrome = navigator.userAgent.indexOf('Chrome') > -1
      if (chrome) {
        window.open(`${cirrusUrl}#/templatemanager?debug=true&tkn=${token}`, '_blank')
      } else {
        window.location.replace(`${cirrusUrl}#/templatemanager?debug=true&tkn=${token}`)
      }
    } else {
      setShowUserRestrictionDialog(true);
    }
  }

  const hasModuleMissing = (module) => {
    return site && (!site.modules || (site.modules && site.modules.values && Array.from(site.modules.values).indexOf(module) === -1));
  }

  return <>
    <StyledContentCard classes={{ root: classes.cardRoot }}>
      <Grid container className={classes.root}>
        <Grid container className={classes.textRow}>
          <Grid item className={classNames(classes.headText, classes.cell)}>
            {intl.formatMessage({ id: 'detail_HomePageTitle' })}
          </Grid>
        </Grid>
        <Grid container className={classes.textRow}>
          <Grid item className={classes.dividerContainer}>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
        </Grid>
        <Grid container className={classes.textRow}>
          <Grid item className={classNames(classes.text, classes.cell)}>
            {intl.formatMessage({ id: 'detail_HomePageText' })}
          </Grid>
        </Grid>
        <Grid container className={classes.textRow}>
          <Grid item className={classNames(classes.subheadText, classes.cell)}>
            {intl.formatMessage({ id: 'detail_HomePageSubText' })}
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item {...gridBreaks.set1} className={classes.cell}>
            <LandingButton
              variant="contained"
              disabled={hasModuleMissing("vrs")}
              color="primary"
              onClick={showVrsAccess} >
              <div>
                <div>{VideojetConnectRemoteService}</div>
                <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetRemoteServiceDescription' })}</div>
              </div>
            </LandingButton>
          </Grid>
          <Grid item {...gridBreaks.set2} className={classes.cell}>
            <LandingButton
              variant="contained"
              disabled={hasModuleMissing("api")}
              color="primary"
              onClick={gotoApi}>
              <div>
                <div>{VideojetConnectExchange}</div>
                <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetExchangeDescription' })}</div>
              </div>
            </LandingButton>
          </Grid>
        </Grid>
        {
          !hasModuleMissing("Inspect") && <><Grid container className={classes.row}>
            <Grid item {...gridBreaks.set1} className={classes.cell}>
              <LandingButton
                variant="contained"
                disabled={hasModuleMissing("Intelligence")}
                color="primary"
                onClick={gotoIntelligence}>
                <div>
                  <div>{VideojetConnectInsight}</div>
                  <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetInsightDescription' })}</div>
                </div>
              </LandingButton>
            </Grid>
            <Grid item {...gridBreaks.set2} className={classes.cell}>
              <LandingButton
                variant="contained"
                color="primary"
                onClick={gotoInspect}>
                <div>
                  <div>{VideojetConnectInspect}</div>
                  <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetInspectDescription' })}</div>
                </div>
              </LandingButton>
            </Grid>
          </Grid>
            <Grid container className={classes.row}>
              <Grid item {...gridBreaks.set1} className={classes.cell}>
                <LandingButton
                  variant="contained"
                  disabled={hasModuleMissing("Design")}
                  color="primary"
                  onClick={showCirrus}>
                  <div>
                    <div>{VideojetConnectDesign}</div>
                    <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetDesignDescription' })}</div>
                  </div>
                </LandingButton>
              </Grid>
            </Grid>
          </>
        }
        {hasModuleMissing("Inspect") && <Grid container className={classes.row}>
          <Grid item {...gridBreaks.set1} className={classes.cell}>
            <LandingButton
              variant="contained"
              disabled={hasModuleMissing("Intelligence")}
              color="primary"
              onClick={gotoIntelligence}>
              <div>
                <div>{VideojetConnectInsight}</div>
                <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetInsightDescription' })}</div>
              </div>
            </LandingButton>
          </Grid>
          <Grid item {...gridBreaks.set2} className={classes.cell}>
            <LandingButton
              variant="contained"
              disabled={hasModuleMissing("Design")}
              color="primary"
              onClick={showCirrus}>
              <div>
                <div>{VideojetConnectDesign}</div>
                <div className={classes.homeButtonSubText}>{intl.formatMessage({ id: 'detail_VideojetDesignDescription' })}</div>
              </div>
            </LandingButton>
          </Grid>
        </Grid>
        }
         <Grid container className={classes.textRow}>
          <Grid item className={classNames(classes.subheadTextAccess, classes.cell)}>
            {intl.formatMessage({ id: 'detail_HomePageSubTextAccess' })}
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item {...gridBreaks.set1} className={classes.cell}>
            <img className={classes.vccmImage}
              src={vccmImage}
              alt="videojet connect" />
          </Grid>
        </Grid>
      </Grid>

    </StyledContentCard>
    {
      showUserRestrictionDialog && <InfoDialog
        title={intl.formatMessage({ id: 'detail_AccessRestriction' })}
        open={showUserRestrictionDialog}
        onClose={() => setShowUserRestrictionDialog(false)}
        content={<div className={classes.infoContainer}>
          <div>{intl.formatMessage({ id: 'detail_NotConfiguredForAccess' })}</div>
        </div>}
      />
    }
  </>
}

export default HomePage;
