import toastr from 'toastr';
import Moment from 'moment-timezone';
import { differenceInMinutes, isAfter } from 'date-fns';
import { Logger } from '../../utilities/Logger/Logger';

import _ from 'lodash';

export const ACTIVITIES = {
  visualVerify: 0,
  compareToReference: 1,
  addImage: 2,
  captureValue: 3,
};
export const statusTypes = {
  approved: 'Approved',
  expired: 'Expired',
  failed: 'Failed',
  missed: 'Missed',
  onDemand: 'On Demand',
  open: 'Open',
  passed: 'Passed',
  pending: 'Pending',
  skipped: 'Skipped',
};


export const colorDef = {
  bold: 'black',
  critical: 'red',
  error: 'orange',
  ok: 'green',
  warning: 'yellow',
};

export const statusMeta = {
  [statusTypes.approved]: { id: 'detail_Approved' },
  [statusTypes.failed]: { color: colorDef.critical, id: 'detail_Failed' },
  [statusTypes.missed]: { color: colorDef.error, id: 'detail_Missed' },
  [statusTypes.onDemand]: { color: colorDef.ok, id: 'detail_OnDemand', excludeReportFilter: false },
  [statusTypes.open]: { id: 'detail_Open' },
  [statusTypes.passed]: { color: colorDef.ok, id: 'detail_Passed' },
  [statusTypes.pending]: { id: 'detail_Pending', excludeReportFilter: false },
  [statusTypes.skipped]: { color: colorDef.error, id: 'detail_Skipped', excludeReportFilter: false },

};

export const timeFrames = [
  {
    translationKey: 'detail_Yesterday',
    value: 'yesterday',
  },
  {
    translationKey: 'detail_Today',
    value: 'today',
  },
  {
    translationKey: 'detail_Tomorrow',
    value: 'tomorrow',
  },
  {
    translationKey: 'detail_RestOfWeek',
    value: 'week',
  },
  {
    translationKey: 'detail_Custom',
    value: 'custom',
  },
];

export const defaultMinutesDue = 30;

export const waitingApprovalStatuses = [statusTypes.passed.toLowerCase(), statusTypes.failed.toLowerCase(), statusTypes.skipped.toLowerCase()];
class AssessmentHelper {

  static sortData(propName, direction, data) {
    const sortedData = _.sortBy(data, [propName]);
    return (direction === 'descending') ? sortedData.reverse() : sortedData;
  }

  static defaultMinutesDue = 30;

  static createAssessmentFromEvent(page, event) {
    const { assessmentActions } = page.props;
    const { params } = page.props.match;
    const assessment = {
      activities: event.inspectionItems,
      id: event.id.includes('_') ? event.id : `${event.id}_${event.lineId}`,
      inspectionId: event.inspectionId,
      siteId: params.siteId,
      startDt: event.startTime,
      title: event.title,
      description: event.description,
      shiftId: event.shiftId,
      roleId: event.roleId,
      minutesDue: event.minutesDue,
    };

    return assessmentActions.createAssessment(assessment);
  }

  static defaultAssessment(params) {
    const { id, siteId, startDt } = params;

    return {
      id,
      inspectionId: id.slice(0, 36),
      siteId,
      startDt,
    };
  }

  static deleteAssessment(page) {
    page.setState({ saving: true });
    return page.props.assessmentActions.deleteAssessment(page.state.assessment)
      .then(() => {
        toastr.success('Assessment deleted');
        page.setState({ saving: false });
      })
      .catch((error) => {
        toastr.error(error);
        page.setState({ saving: false });
      });
  }

  /**
   * Explode assessments
   *
   * @param {Object} inspections - list response
   * @returns {Array} synthetic and real records
   */
  static explodeAssessments(inspections, currentShifts: Array<any> = []) {
    const knownKeys = {};
    const results: any = (inspections.known || []).map((i) => {
      const [inspectionId, lineId] = i.id.split('_');
      const window = i.minutesDue || defaultMinutesDue;
      // side effect of the map!
      if (knownKeys[i.id] == null) knownKeys[i.id] = new Set();
      knownKeys[i.id].add(i.startDt);
      return ({
        ...i,
        startTime: i.startDt,
        status: this.getStatus(i),
        inspectionId,
        lineId,
        dueDate: new Date((new Date(i.startDt)).getTime() + (window * 60000)).toISOString(),
        canOpen: true,
        nextAssessment: i.status == null,
      });
    });


    (inspections.scheduled || []).forEach((inspection) => {
      results.push(...Object.entries(inspection.instances)
        .filter(([lineId]) => (inspection.lineIds && inspection.lineIds.length > 0
          ? inspection.lineIds.includes(lineId)
          : true))
        .flatMap(([lineId, lineInfo]) => {
          const { productId, shiftInstances } = lineInfo as any;
          const window = inspection.minutesDue || 60;
          const lineInstances = Object.entries(shiftInstances)
            .flatMap(([shiftId, dts]) => {
              let dateTimes;
              const freqType = Number(inspection.freqType);
              if ((dts as any).length === 0 && freqType === 4) {
                if (currentShifts.some(s => s.recurrenceId === shiftId)) {
                  const now = Moment.utc().toISOString();
                  dateTimes = [now];
                } else dateTimes = [];
              } else {
                dateTimes = dts;
              }
              const canOpen = false;
              // console.log('dateTimes to consider', dateTimes);
              // console.log(knownKeys[`${inspection.id}_${lineId}`]);
              return dateTimes
                // we don't want synthetic records to clash with known, so filter
                .filter((dt) => {
                  const knownDts = knownKeys[`${inspection.id}_${lineId}`];
                  return (knownDts && !knownDts.has(dt)) !== false;
                })
                .map(dt => ({
                  ...inspection,
                  lineId,
                  shiftId,
                  canOpen,
                  productId,
                  startTime: dt,
                  instances: undefined,
                  inspectionId: inspection.id,
                  dueDate: new Date((new Date(dt)).getTime() + (window * 60000)).toISOString(),
                  freqTypeNumeric: freqType,
                  createdAt: undefined,

                }));
            });


          if (lineInstances.length) {
            return lineInstances.map((li) => {
              const diff = Moment.duration(
                Moment.utc(li.startTime).diff(Moment.utc(), 'm'),
                'm',
              );
              li.canOpen = diff.asMinutes() <= 1;
              return li;
            });
          }

          return lineInstances;
        }));
    });

    return results
      .sort(((a, b) => a.startTime - b.startTime));
  }

  static getSummary(assessments) {
    const retVal = { approved: 0, failed: 0, missed: 0, open: 0, onDemand: 0, passed: 0, pending: 0, skipped: 0, total: assessments && assessments.length, waitingApproval: 0 };
    _.forEach(assessments, (assessment) => {
      const { freqTypeNumeric, overallStatus } = assessment;
      const status = (assessment && assessment.status) || (freqTypeNumeric === 4 ? statusTypes.onDemand : statusTypes.pending);
      if (status === statusTypes.pending) retVal.pending++;
      if (status === statusTypes.open) retVal.open++;
      if ((status === statusTypes.passed) || (overallStatus === statusTypes.passed)) retVal.passed++;
      if ((status === statusTypes.failed) || (overallStatus === statusTypes.failed)) retVal.failed++;
      if ((status === statusTypes.missed) || (overallStatus === statusTypes.missed)) retVal.missed++;
      if ((status === statusTypes.skipped) || (overallStatus === statusTypes.skipped)) retVal.skipped++;
      if ((status === statusTypes.approved) || (overallStatus === statusTypes.approved)) retVal.approved++;
      if (status === statusTypes.onDemand) retVal.onDemand++;
      if (AssessmentHelper.waitingApproval(status)) retVal.waitingApproval++;
    });

    return retVal;
  }

  /**
   *
   * @param assessment - {object} - inspection item
   * @returns {{failed: number, passed: number}}
   */
  static getItemSummary(assessment) {
    const retVal = {
      failed: 0,
      passed: 0,
    };
    const data = assessment.inspectionItems || assessment.activities;
    // console.log(data);
    _.forEach(data, (inspection) => {
      const { passed, required } = inspection;
      if (passed) retVal.passed++;
      if (!passed && required) retVal.failed++;
    });
    return retVal;
  }

  /**
   *
   * @param status
   * @param canOpen
   * @returns {boolean}
   */
  static getDisabledState(status, canOpen) {
    if (!canOpen) return false;
    if (status === statusTypes.missed) {
      return true;
    }
    return true;
  }

  static getStatus(i) {
    return AssessmentHelper.capitalizeStatus(i.status);
  }

  static capitalizeStatus(status) {
    const aStatus = status && status.charAt(0)
      .toUpperCase() + status.slice(1);
    if (aStatus === statusTypes.expired) return statusTypes.missed;
    return aStatus;
  }

  /**
   * Filter assessments
   *
   * @param {Array} assessments - assessments to filter
   * @param {Object} filter - filter to apply to assessments
   * @returns {Array} - compare result
   */
  static useFilter(assessments, filter) {
    let retArray = assessments || [];
    if (filter && filter.shift) retArray = retArray.filter(i => i.shiftId === filter.shift);

    if (filter && filter.status) {
      if (filter.status === 'waitingApproval') {
        retArray = retArray.filter(i => AssessmentHelper.waitingApproval(i.status));
      } else if (filter.status !== 'all') {
        if (filter.status === statusTypes.pending) {
          retArray = retArray.filter(i => (!i.createdAt || i.freqTypeNumeric === 4) && i.status !== statusTypes.missed);
        } else if (filter.status === statusTypes.onDemand) {
          retArray = retArray.filter(i => (i.freqTypeNumeric === 4 && i.status !== statusTypes.missed));
        } else {
          retArray = retArray.filter(i => (i.status && i.status.toLowerCase() === filter.status.toLowerCase()) || (i.overallStatus === filter.status));
        }
      }
    }

    if (filter && filter.line && assessments) {
      retArray = retArray.filter(i => i.lineId === filter.line);
    }

    if (filter && filter.title && assessments) {
      retArray = retArray.filter(i => i.title.includes(filter.title));
    }

    if (filter && filter.product && assessments) {
      retArray = retArray.filter(i => i.productId && i.productId.includes(filter.product));
    }
    return retArray;
  }

  static waitingApproval(aStatus) {
    return waitingApprovalStatuses.includes(aStatus && aStatus.toLowerCase());
  }

  static activitiesFromResponse(response) {
    return ((response && response.activities) || []).map((rawActivity) => {
      const activity = Object.assign(AssessmentHelper.emptyInspectionItem(), rawActivity);
      AssessmentHelper.setActivityIsValid(activity);
      return activity;
    });
  }

  static loadAssessment(page) {
    const { params } = page.props.match;

    page.setState({ loading: true });
    page.props.assessmentActions.loadAssessment(params.id, params.siteId, params.startDt)
      .then((response) => {
        const activities = AssessmentHelper.activitiesFromResponse(response);
        page.setState({
          assessment: response,
          activities,
          loading: false,
        });
      });
  }

  static loadInspection(page) {
    const { params } = page.props.match;

    page.setState({ loading: true });
    page.props.inspectionActions.loadInspection(params.inspectionId, params.siteId)
      .then((response) => {
        const assessment = Object.assign({}, page.state.assessment, {
          activities: response.inspectionItems,
        });
        const activities = AssessmentHelper.parseInspectionItems(response.inspectionItems);
        page.setState({
          assessment,
          activities,
          loading: false,
        });
      });
  }

  static emptyInspectionItem() {
    return {
      comment: '',
      image: '',
      passed: false,
      value: '',
    };
  }

  static onSearchFilterChange = (filter, context) => {
    const { lines, linesFormatted, products, productsFormatted } = context.props;
    Logger.of('App.AssessmentsReportPage.onSearchFilterChange')
      .info('new filter change', filter);

    // generate search component of Url
    let search = `?timeFrameId=${filter.timeFrameId}`
      + `&status=${filter.status}`;

    if (filter.title) {
      search += `&title=${encodeURIComponent(filter.title)}`;
    }

    if (filter.lineIds && (lines || linesFormatted)) {
      search += `&lineIds=${(lines && lines.map(m => m.id)) || (linesFormatted && linesFormatted.map(m => m.value))}`;
    }
    if (filter.productIds && (lines || linesFormatted)) {
      search += `&lineIds=${(products && products.map(m => m.id)) || (productsFormatted && productsFormatted.map(m => m.value))}`;
    }
    if (filter.timeFrameId === 'customDateRange') {
      search += `&startDt=${filter.startDt.toISOString()}&endDt=${filter.endDt.toISOString()}`;
    }
    context.setState({
      filter: {
        ...filter,
        isDirty: true,
        url: `${context.props.history.location.pathname}${search}`,
      },
    }, () => {
      context.props.history.push({ search });
    });
  };

  static parseInspectionItems(inspectionItems) {
    return inspectionItems.map((item) => {
      item.type = Number(item.type);
      return Object.assign(AssessmentHelper.emptyInspectionItem(), item);
    });
  }

  static saveAssessment(page) {
    page.setState({ saving: true });
    return page.props.assessmentActions.saveAssessment(page.state.assessment)
      .then(() => {
        toastr.success('Assessment saved');
        page.setState({ saving: false });
      })
      .catch((error) => {
        toastr.error(error);
        page.setState({ saving: false });
      });
  }

  static setActivityIsValid(activity) {
    if (!activity.required) {
      activity.isValid = true;
      return;
    }

    switch (activity.type) {
      case ACTIVITIES.visualVerify:
        activity.isValid = true;
        break;
      case ACTIVITIES.compareToReference:
        activity.isValid = activity.value && activity.value.length > 0;
        break;
      case ACTIVITIES.addImage:
        activity.isValid = activity.imageFilename && activity.imageFilename.length > 0;
        break;
      case ACTIVITIES.captureValue:
        activity.isValid = activity.value && activity.value.length > 0;
        break;
      default:
        activity.isValid = true;
        break;
    }

    if (activity.isValid) {
      activity.validationFailed = false;
    }
  }

  static async signAssessment(page, user) {
    page.state.assessment.signedBy = user.username;
    page.state.assessment.signedDt = new Date().toISOString();
  }

  static async uploadFile(page, event) {
    const { params } = page.props.match;

    if (event.target && event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const { fileName, url } = await page.props.inspectionActions.getFileUploadUrl(params.siteId, file.type);
      await page.props.inspectionActions.uploadFile(url, file);
      return fileName;
    }
    return undefined;
  }

  static async uploadFileGetUrl(props, event) {
    const { params } = props.match;

    if (event.target && event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const { fileName, url } = await props.getFileUploadUrl(params.siteId, file.type);
      const { url: urlFileName } = await props.getFileUrl(params.siteId, fileName);
      await props.uploadFile(url, file);
      return {
        urlFileName,
        fileName,
      };
    }
    return undefined;
  }

  static skipAssessment(page, msg, userId = null) {
    page.state.assessment.skippedBy = userId;
    page.state.assessment.skipReason = msg;
  }

  static approveAssessment(page, userId) {
    page.state.assessment.approvedBy = userId;
  }
}

export default AssessmentHelper;

export function getColor(aStatus, diffDue, canOpen, startDt) {
  try {
    switch (aStatus) {
      case statusTypes.pending:
      case statusTypes.open:
        if (diffDue > 0) {
          return canOpen && isAfter(new Date(), startDt) ? colorDef.ok : undefined;
        }
        return colorDef.warning;
      case statusTypes.onDemand: return canOpen && diffDue > 0 ? statusMeta[aStatus] && statusMeta[aStatus].color : undefined;
      default:
        return isAfter(new Date(), startDt) ? statusMeta[aStatus] && statusMeta[aStatus].color : undefined;
    }
  } catch (e) {
    console.log(e.toString());
  }
  return undefined;
}

export function statusGlyph(aStatus) {
  switch (aStatus) {
    case statusTypes.pending:
    case statusTypes.open:
      return 'clock';
    case statusTypes.failed:
      return 'ban';
    case statusTypes.expired:
    case statusTypes.missed:
      return 'question';
    case statusTypes.skipped:
      return 'step forward';
    case statusTypes.onDemand:
      return 'play';
    case statusTypes.passed:
      return 'check';
    default:
      return false;
  }
}

export function computeStatus({ status, freqTypeNumeric, diffDue, dueDate, freqType }) {
  const freq = freqTypeNumeric || Number(freqType);
  diffDue = diffDue || differenceInMinutes(new Date(dueDate), new Date());
  if (status == null) {
    if (freq === 4) {
      return {
        status: statusTypes.onDemand,
        diffDue,
      };
    }

    return {
      status: diffDue > 0 ? statusTypes.pending : statusTypes.missed,
      diffDue,
    };
  }
  return {
    status,
    diffDue: diffDue || differenceInMinutes(new Date(dueDate), new Date()),
  };
}
