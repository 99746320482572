import { useMemo } from 'react';
import RoleApi from '../api/prodRoleApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import {
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  LIST_ABILITIES_SUCCESS,
  LOAD_ROLES_SUCCESS,
  UPDATE_USER_SUCCESS
} from './actionTypes';

function list(siteId) {
  return dispatch => {
    return RoleApi.list(siteId).then((roles) => {
      dispatch(loadRolesSuccess(roles));
      return roles;
    }).catch((error) => {

      console.log('roleActions.loadUsers error', error);
      throw error;
    });
  }
}

export function loadRolesSuccess(roles) {
  return { type: LOAD_ROLES_SUCCESS, roles };
}

function listAbilities() {
  return dispatch => {
    return RoleApi.getAbilities().then(abilities => {
      dispatch(listAbilitiesSuccess(abilities));
      return abilities;
    }).catch((error) => {
      //
      console.log('roleActions.listAbilities error', error);
      throw error;
    });
  }
}

function listAbilitiesSuccess(payload) {
  return { type: LIST_ABILITIES_SUCCESS, payload };
}


function createRole(role) {
  return dispatch => {
    console.log('roleActions.create creating =>', role);
    return RoleApi.create(role).then((user) => {
      dispatch(createRoleSuccess(user));
      console.log('roleActions.create success =>', user);
      return user;
    }).catch((error) => {

      console.log('roleActions.create error', error);
      throw error;
    });
  }
}

function createRoleSuccess(role) {
  return { type: CREATE_ROLE_SUCCESS, role };
}

function updateRole(role) {
  return dispatch => {
    console.log('roleActions.update updating =>', role);
    delete role.assignedUsers;
    return RoleApi.update(role).then((role) => {
      dispatch(updateRoleSuccess(role));
      console.log('roleActions.update success =>', role);
      return role;
    }).catch((error) => {

      console.log('roleActions.update error', error);
      throw error;
    });
  }
}

function updateRoleSuccess(user) {
  return { type: UPDATE_USER_SUCCESS, user };
}

function deleteRole(id, siteId) {
  return (dispatch) => RoleApi.delete(id, siteId).then(
    dispatch(deleteRoleSuccess(id)),
  ).catch((error) => {
    console.log('userActions.deleteUser error', error);
    throw error;
  });
}

function deleteRoleSuccess(id) {
  return { type: DELETE_ROLE_SUCCESS, id };
}

export const useRoleActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    list: (siteId) => list(siteId)(dispatch),
    listAbilities: () => listAbilities()(dispatch),
    createRole: (role) => createRole(role)(dispatch),
    updateRole: (role) => updateRole(role)(dispatch),
    deleteRole: (role, siteId) => deleteRole(role, siteId)(dispatch)
  }), [dispatch]);
};
