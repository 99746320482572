import { useMemo } from 'react';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import { LanguageData } from '../interfaces/languageData';
import { TranslationFilter } from '../interfaces/TranslationFilter';
import * as types from './actionTypes';

export function updateLanguageData(language: string, importedData: Array<LanguageData>) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_TRANSLATION_DATA, payload: { language, importedData } });
  }
}

export function updateLanguageFilter(filterLambda: (filterState: TranslationFilter) => TranslationFilter) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_TRANSLATION_FILTER, payload: filterLambda });
  }
}

export const useLanguageActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    updateLanguageData: (language: string, importedData: Array<LanguageData>) => updateLanguageData(language, importedData)(dispatch),
    updateLanguageFilter: (filterLambda: (filterState: TranslationFilter) => TranslationFilter) => updateLanguageFilter(filterLambda)(dispatch)
  }), [dispatch]);
};

