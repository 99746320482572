import { invokeApig } from '../libs/awsLib';

class ShiftApi {
  static getAllShifts() {
    const apiUrl = '/schedules';
    return invokeApig({ gatewayName: 'SCHEDULES', path: apiUrl });
  }

  static getShifts(siteId) {
    const apiUrl = '/schedules/getShifts';
    return invokeApig({ gatewayName: 'SCHEDULES', path: apiUrl, queryParams: { siteId } });
  }

  static getShiftsAtMinute(siteId, isotime) {
    const apiUrl = '/schedules/shiftsAtMinute';
    return invokeApig({ gatewayName: 'SCHEDULES', path: apiUrl, queryParams: { siteId, isotime } });
  }

  static saveShiftEvent(shift) {
    const apiUrl = '/schedules';
    // remove extra properties
    if (shift.id === '') delete shift.id;
    // VCCM-1691 optional description
    if (shift.description === '') delete shift.description;
    console.log('saving new schedule', shift);
    return invokeApig({ gatewayName: 'SCHEDULES', path: apiUrl, method: 'POST', body: shift });
  }

  static updateEvent(siteId, shift) {
    const apiUrl = `/schedules/${shift.id}`;
    console.log(`updating schedule ${shift.id} =>`, shift);
    // VCCM-1691 optional description, remove from stored record (by sending null) if empty string
    if (shift.description === '') shift.description = null;
    if (shift.recurrenceRule === '') shift.recurrenceRule = null;
    if (shift.recurrenceException === '') shift.recurrenceException = null;
    return invokeApig({ gatewayName: 'SCHEDULES', path: apiUrl, method: 'PUT', queryParams: { siteId }, body: shift });
  }

  static deleteShiftEvent(siteId, id) {
    const apiUrl = `/schedules/${id}`;
    return invokeApig({ gatewayName: 'SCHEDULES', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }
}

export default ShiftApi;
