import toastr from 'toastr';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppShiftState, useAppSiteState } from '../../../context/AppContext/AppContext';
import { useShiftHistoryReportPageStyles } from './ShiftHistoryReportPage.css'
import { CardContent, Typography } from '@material-ui/core';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Logger } from '../../../utilities/Logger/Logger';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useDowntimeActions } from '../../../actions/downtimeActions';
import {
  defaultReportFilter,
  getCommonFilterFromQueryString,
  performUpdateReportFilterData,
  formatShiftEvent,
  getShiftsOccurringInRange

} from '../../../utilities/ReportUtility/ReportUtility';
import SchedulerHelper from '../../../helpers/SchedulerHelper/SchedulerHelper';
import DateHelper from '../../../helpers/DateHelper/DateHelper';
import AsyncComponent from '../../../components/Common/AsyncComponent';
import { DayLineMapLoader } from './DayLineMapLoader/DayLineMapLoader';
import RetroactiveDowntimeModal from '../../../modals/RetroactiveDowntimeModal/RetroactiveDowntimeModal';
import CountdownModal from '../../../modals/CountdownModal/CountdownModal';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import { Paging } from '../../../components/Paging/Paging';
import { REPORT_PAGE_SIZE } from '../../../constants/global';
import { ReportTypes } from '../../../enums/ReportTypes';

interface IShiftReportListState {
  page: number;
  pageCount: number;
  pageSize: number;
  pagedReports: Array<any>;
}

interface IShiftHistoryReportPage {
  match: any;
}

const AsyncShiftSummaryWidget = AsyncComponent({ loader: () => import('./ShiftSummaryWidget/ShiftSummaryWidget') });
const AsyncShiftHistoryLineWidget = AsyncComponent({ loader: () => import('./ShiftHistoryLineWidget/ShiftHistoryLineWidget') });

const ShiftHistoryReportPage = ({ match }: IShiftHistoryReportPage) => {
  const classes = useShiftHistoryReportPageStyles();
  const shifts = useAppShiftState();
  const downtimeActions = useDowntimeActions();

  const intl = useIntl();
  const { sites } = useAppSiteState();
  const siteId = match.params.siteId;
  const history = useHistory();
  const site = sites.find(el => el.id === siteId);

  const initialiseFilter = (): any => {
    if (history.location.search) {
      // if there are query params in the route when it's loaded, set the filters to match before fetching data
      return getCommonFilterFromQueryString({
        startTimeId: '',
        endTimeId: '',
      }, history.location.search, site.tz, true)
    } else {
      return {
        ...defaultReportFilter,
        lineId: '',
        lineIds: '',
        reportShift: [],
        reportShifts: [],
        selectableProducts: [],
        reportProduct: '',
        reportLineDisplay: 1
      }
    }
  }

  const [filter, setFilter] = useState<any>(initialiseFilter());
  const [isShiftsOccurringInRangeInitialised, setIsShiftsOccurringInRangeInitialised] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [refreshDataIndex, setRefreshDataIndex] = useState<number>(0);

  const [filterIndex, setFilterIndex] = useState(0);
  const [activeShifts, setActiveShifts] = useState<Array<any> | null>(null);
  const shiftsFormatted = useMemo(() => activeShifts ? activeShifts.map(shift => ({
    value: shift.id,
    text: shift.title,
  })) : [], [activeShifts]);

  const [reportState, setReportState] = useState<any>({
    showCountdownModal: false,
    showRetroactiveDowntimeModal: false,
    showProductAssignModal: false,
    selectedDowntime: {},
    selectedEvent: null,
    selectedLineId: '',
    selectedShift: {},
    modalDTTimeInState: '',
    eventToEdit: null,
    occurrencesInRange: null,
    lineDateGroupings: [],
    noShiftOccurrences: false,
    shiftsWithOccurrencesInRange: [],
    dayLineMap: {},
    reloadData: false
  });

  const [timeRange, setTimeRange] = useState<any>(null);
  const [isDataInitialized, setIsDataInitialized] = useState(false);

  const [shiftReportListState, setShiftReportListState] = useState<IShiftReportListState>({
    page: 1,
    pageSize: 1,
    pageCount: 0,
    pagedReports: []
  });


  const {
    page,
    pageCount,
    pageSize,
    pagedReports
  } = shiftReportListState;

  const { noShiftOccurrences, lineDateGroupings } = reportState;

  const getAndSetShiftsOccurringInRange = () => {
    const [shiftsWithOccurrencesInRange, shiftOccurrences, nextTimeRange] = getShiftsOccurringInRange(shifts, filter, site.tz, true);

    setReportState((state) => ({
      ...state,
      occurrencesInRange: shiftOccurrences,
      shiftsWithOccurrencesInRange
    }));

    setTimeRange(nextTimeRange);
    setIsShiftsOccurringInRangeInitialised(true);
    setIsFilterApplied(true);
    setRefreshDataIndex(refreshDataIndex + 1);
  }

  const updateRetroactiveDowntime = (selectedDowntimeReason, downtimeReasonNote, eventToUpdate, secondReasonId, subDowntimeReasonNote) => {
    downtimeActions.saveDowntimeEvent(site.id, {
      reasonId: selectedDowntimeReason.id,
      reasonNote: downtimeReasonNote,
      secondReasonId: secondReasonId,
      subReasonNote: subDowntimeReasonNote
    }, eventToUpdate)
      .then(async () => {
        toastr.success(intl.formatMessage({ id: 'detail_DowntimeUpdated' }));
        handleClose();
      })
      .catch((error) => {
        toastr.error(error.message);
        Logger.of('App.LinePerformanceListTable.updateRetroactiveDowntime')
          .info('saveDowntimeEvent error', error);
        handleClose();
      });
  }

  const handleClose = (canceled?: boolean) => {
    const waitTime = (reportState && reportState.eventToEdit && reportState.eventToEdit.endTime - reportState.eventToEdit.start) / 3600000;
    setReportState(state => ({
      ...state,
      showRetroactiveDowntimeModal: false,
      showProductAssignModal: false,
      showCountdownModal: !canceled,
      selectedDowntime: {},
      selectedEvent: null,
      selectedLineId: '',
      selectedShift: {},
      waitTime: Math.min(30, Math.max(5, waitTime * 5)) * 1000
    }));
  };

  const performShowingRetroactiveDowntimeModal = (timeInStateEvent) => {
    // must have a line ID
    if (!timeInStateEvent && !timeInStateEvent.lineId) {
      return;
    }

    // must be a downtime or scheduled break
    if (timeInStateEvent.type !== 'downtime' && timeInStateEvent.typeId !== 3) {
      return;
    }

    // don't allow change of a disconnected printer.
    if (timeInStateEvent.status && timeInStateEvent.status === 'Disconnected') {
      return;
    }

    const { dayLineMap } = reportState;
    const selectedShift = Object.assign({}, {
      lineId: timeInStateEvent.lineId,
      instance: formatShiftEvent(SchedulerHelper.getShiftById(shifts, timeInStateEvent.shiftId)),
    });

    Logger.of('App.ShiftHistoryReportPage.showRetroactiveDowntimeModal')
      .info('here it is', {
        showRetroactiveDowntimeModal: true,
        selectedLineId: timeInStateEvent.lineId,
        selectedShift,
        eventToEdit: timeInStateEvent,
      });
    if (selectedShift.instance) {
      const timeInState = dayLineMap[timeInStateEvent.dayText]
        && dayLineMap[timeInStateEvent.dayText].lineData[timeInStateEvent.lineId]
        && {
        timeLine: [...dayLineMap[timeInStateEvent.dayText].lineData[timeInStateEvent.lineId].filteredTimeline],
        slowCycles: [...dayLineMap[timeInStateEvent.dayText].lineData[timeInStateEvent.lineId].slowCycles],
      };
      setReportState(state => ({
        ...state,
        selectedShift,
        selectedLineId: timeInStateEvent.lineId,
        showRetroactiveDowntimeModal: true,
        modalDTTimeInState: timeInState,
        eventToEdit: timeInStateEvent,
      }));
    }
  }

  const routeReportQueryParams = useCallback(() => {
    let dates = '';
    if (filter.timeFrameId === 'customDateRange') {
      dates = `&startDt=${filter.startDt.toISOString()}&endDt=${filter.endDt.toISOString()}`;
    }

    history.push({
      search: `${`?timeFrameId=${filter.timeFrameId}`
        + `&reportShifts=${filter.reportShifts && filter.reportShifts.length > 0 ? filter.reportShifts : 'all'}`}${dates}`,
    });

    Logger.of('App.ShiftHistoryReportPage.updateProductFilterData')
      .info('Updated selectable products to =>', filter.selectableProducts);
  }, [filter, history]);

  const updateReportFilterData = (data) => {
    const { name, value } = data;
    setFilter((state) => performUpdateReportFilterData(state, name, value, site.tz, 'ShiftHistoryReportPage'));

    if (name !== 'reportShifts') {
      setFilterIndex(s => s + 1);
    }
  }

  const hideCountdownModal = (reason) => {
    setReportState(state => ({
      ...state,
      showCountdownModal: false,
      reloadData: true,
    }));
  }

  useEffect(() => {
    const [shiftsWithOccurrencesInRange] = getShiftsOccurringInRange(shifts, filter, site.tz)
    setActiveShifts(shiftsWithOccurrencesInRange);
  }, [filterIndex, shifts, site.tz]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    routeReportQueryParams();
  }, [filter, routeReportQueryParams]);

  useEffect(() => {
    if (!reportState.reloadData) {
      return;
    }
    performloadShiftReportData();
    setReportState(state => ({ ...state, reloadData: false }))
  }, [reportState.reloadData])

  const subHeader = intl.formatMessage({ id: 'detail_Shift_History_Report' });

  const performloadShiftReportData = () => {
    setIsDataInitialized(false);
    setIsFilterApplied(true);
    setIsShiftsOccurringInRangeInitialised(false);
  }

  const applyFilter = () => {
    setIsFilterApplied(false);
    setTimeout(() => {
      performloadShiftReportData();
    }, 100);

  }

  useEffect(() => {
    if (!isShiftsOccurringInRangeInitialised) {
      getAndSetShiftsOccurringInRange();
    }
  }, [isShiftsOccurringInRangeInitialised]); // eslint-disable-line react-hooks/exhaustive-deps

  const dayLineMapUpdated = (value) => {
    setReportState(state => ({
      ...state,
      noShiftOccurrences: value.noShiftOccurrences,
      lineDateGroupings: value.lineDateGroupings,
      totalData: value.totalData,
      dayLineMap: value.dayLineMap
    }))
    setIsDataInitialized(true);
  };

  const calculatedTotalSize = useMemo(() => {
    if (lineDateGroupings.length > 0) {
      return lineDateGroupings.reduce((acc, el) => el.data.length + acc, 0);
    }
    return 0;
  }, [lineDateGroupings]);

  const calculatedPageSize = useMemo(() => {
    let size = 1;
    if (lineDateGroupings.length > 0) {
      const total = lineDateGroupings.reduce((acc, el) => el.data.length + acc, 0);
      size = total > 0 ? Math.ceil(REPORT_PAGE_SIZE * lineDateGroupings.length / total) : 1;
    }
    return size;
  }, [lineDateGroupings]);

  const pageDetails = useMemo(() => {
    const before = page === 1 ? [] : lineDateGroupings.slice(0, (page - 1) * calculatedPageSize);
    const paged = lineDateGroupings.slice((page - 1) * calculatedPageSize, page * calculatedPageSize)
    const start = before.length > 0 ? before.reduce((acc, el) => el.data.length + acc, 0) : 1;
    const end = start + paged.reduce((acc, el) => el.data.length + acc, 0)
    return `${start}-${end} of ${calculatedTotalSize}`;
  }, [lineDateGroupings, page, calculatedPageSize, calculatedTotalSize]);

  useEffect(() => {
    setShiftReportListState(s => {
      const count = Math.ceil(((lineDateGroupings && lineDateGroupings.length) || 0) / calculatedPageSize);
      return {
        ...s,
        page: 1,
        pageSize: calculatedPageSize,
        pageCount: count,
      }
    })
  }, [lineDateGroupings, calculatedPageSize]);

  const onPageChange = useCallback(p => {
    setShiftReportListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = lineDateGroupings.slice((page - 1) * calculatedPageSize, page * calculatedPageSize);
    setShiftReportListState(s => ({
      ...s,
      pageSize: calculatedPageSize,
      pagedReports: paged
    }));
  }, [page, lineDateGroupings, calculatedPageSize]);

  const renderCharts = () => {
    if (!isFilterApplied || !isShiftsOccurringInRangeInitialised) {
      return <div></div>
    } else if (!isDataInitialized && isShiftsOccurringInRangeInitialised && timeRange) {
      return <DayLineMapLoader timeRange={timeRange}
        initialReportState={reportState}
        site={site}
        refreshDataIndex={refreshDataIndex}
        filter={filter}
        dayLineMapUpdated={dayLineMapUpdated} />
    }
    else if (noShiftOccurrences || lineDateGroupings.length === 0
      || lineDateGroupings.map(group => group.data).flat().length === 0
    ) {
      return (
        <div className={classes.noShiftFound}>
          <Typography variant="h6">{intl.formatMessage({ id: 'detail_No_shifts' })}</Typography>
        </div>
      );
    }
    else {
      return <div className={classes.widgetContainer}>
        <AsyncShiftSummaryWidget
          isTotalSummary
          oeeHours={reportState.totalData.oeeHours}
          downtimeHours={reportState.totalData.downtime}
          microstops={reportState.totalData.microstops}
        />
        {
          pagedReports.map(dataToDisplay => (
            <AsyncShiftHistoryLineWidget
              dateGroup={dataToDisplay}
              collapsible={timeRange.diff('days') > 1}
              site={site}
              key={`day_${dataToDisplay.dayNumber}`}
              eventSelected={performShowingRetroactiveDowntimeModal}
            />
          ))
        }
      </div>
    }
  }

  return <>
    {
      (site && reportState.selectedShift && reportState.showRetroactiveDowntimeModal) && (
        <RetroactiveDowntimeModal
          cancel={() => handleClose(true)}
          selectedShift={reportState.selectedShift.instance}
          lineId={reportState.selectedShift.lineId}
          open={reportState.showRetroactiveDowntimeModal}
          save={updateRetroactiveDowntime}
          selectedEvent={reportState.eventToEdit}
          site={site}
          timeInState={reportState.modalDTTimeInState}
        />
      )
    }
    {
      reportState.showCountdownModal && <CountdownModal open={true}
        titleTranslationId={"detail_ApplyingChanges"}
        bodyTranslationId={"detail_Updating"}
        totalDuration={reportState.waitTime || 5000}
        onComplete={hideCountdownModal}
      />
    }
    { sites.length > 0 && <>
      <BarStyledContentCard>
        <CardContent className={classes.content}>
          <ReportHeader
            enableShiftFilter
            enableLineFilter={false}
            enableLineDisplay={false}
            timeFrames={DateHelper.getFormattedTimeFrames(intl, ((timeFrames) => timeFrames.slice(0, 3)))}
            reportType={ReportTypes.Shift_History}
            filter={filter}
            saved={filter.saved}
            shifts={shiftsFormatted}
            title={filter.title || ''}
            siteId={siteId}
            updateReportFilterData={updateReportFilterData}
            getNewData={applyFilter}
            subHeader={subHeader}
            isLoading={false}
            enableTimeInterval={false}
          />
        </CardContent>
      </BarStyledContentCard>
      <StyledContentCard>
        <CardContent className={classes.content}>
          {refreshDataIndex && renderCharts()}
          {
            isFilterApplied && isShiftsOccurringInRangeInitialised && isDataInitialized
              && refreshDataIndex && lineDateGroupings.length > pageSize
              ? <div className={classes.pageContainer}>
                <Paging
                  page={page}
                  onChange={onPageChange}
                  count={pageCount}
                  pageSize={pageSize}
                  total={lineDateGroupings.length}
                  pageDetails={pageDetails} /></div> : null
          }
        </CardContent>
      </StyledContentCard>
    </>
    }
  </>
};

export default ShiftHistoryReportPage;
