import React, { useEffect, useState } from 'react';
import { useReportsDashPageStyles } from './ReportsDashPage.css';

import { useIntl } from 'react-intl';
import {
  LibraryBooks as LibraryBooksIcon,
  Tune as TuneIcon,
  WatchLater as WatchLaterIcon,
  Build as BuildIcon,
  CardGiftcard as CardGiftcardIcon,
  CalendarToday as CalendarTodayIcon,
} from "@material-ui/icons";
import PageTabs from '../../../components/PageTabs/PageTabs';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import OeeReportPage from '../OeeReportPage/OeeReportPage';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import DowntimeReportPage from '../DowntimeReportPage/DowntimeReportPage';
import ShiftHistoryReportPage from '../ShiftHistoryReportPage/ShiftHistoryReportPage';
import ProductReportPage from '../ProductReportPage/ProductReportPage';
import ReportManagementPage from '../ReportManagementPage/ReportManagementPage';
import StyledTab from '../../../components/Basic/StyledTab/StyledTab';
import StyledTabs from '../../../components/Basic/StyledTabs/StyledTabs';

const tabIndexName = ['oee', 'downtime', 'shift-history', 'product', 'management'];
const tabIds = ['detail_OEE', 'detail_Downtime', 'detail_Shift_History', 'detail_Product', 'detail_Management']

const ReportsDashPage = ({ match }: any) => {
  const intl = useIntl();
  const history = useHistory();

  const { selectedSiteId } = useAppGlobalState();

  const findTabIndex = () => {
    const activeTab = (match.params.tab || 'oee').toLowerCase();
    const activeTabIndex = tabIndexName.indexOf(activeTab);
    if (activeTabIndex > -1) {
      return activeTabIndex;
    }

    return 0;
  }

  const currentTabIndex = findTabIndex();

  const [activeTabIndex, setActiveTabIndex] = useState(currentTabIndex);
  const classes = useReportsDashPageStyles();
  const setTitle = useSetTitleAction();

  const tabClick = (e, index) => {
    setActiveTabIndex(index);
    const tabName = tabIndexName[activeTabIndex];
    history.push(`/site/${match.params.siteId}/report/${tabName}`);
  };

  useEffect(() => {
    const tabName = tabIndexName[activeTabIndex];
    const queryStr = history.location.search ? history.location.search : '';
    history.push(`/site/${match.params.siteId}/report/${tabName}${queryStr}`);
    setTitle('detail_Reports', <LibraryBooksIcon />, {
      ignoreItself: true,
      parents: [{
        textOnly: true,
        id: 'INSIGHT',
      },
      {
        id: 'detail_Reports',
      },
      {
        id: tabIds[activeTabIndex],
      }]
    });
  }, [setTitle, activeTabIndex, match.params.siteId, history]);

  useEffect(() => {
    setActiveTabIndex(currentTabIndex);
  }, [currentTabIndex]);

  return <><PageTabs>
    <StyledTabs
      value={activeTabIndex}
      onChange={tabClick}
      indicatorColor="secondary"
      textColor="primary"
    >
      <StyledTab icon={<CalendarTodayIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: tabIds[0] })} />
      <StyledTab icon={<WatchLaterIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: tabIds[1] })} />
      <StyledTab icon={<BuildIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: tabIds[2] })} />
      <StyledTab icon={<CardGiftcardIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: tabIds[3] })} />
      <StyledTab icon={<TuneIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: tabIds[4] })} />
    </StyledTabs>
  </PageTabs>
    <Switch>
      <Route exact path="/site/:siteId/report" render={() => <Redirect to={`/site/${selectedSiteId}/report/oee`} />} />
      <Route path="/site/:siteId/report/oee" component={OeeReportPage} />
      <Route path="/site/:siteId/report/downtime" component={DowntimeReportPage} />
      <Route path="/site/:siteId/report/shift-history" component={ShiftHistoryReportPage} />
      <Route path="/site/:siteId/report/product" component={ProductReportPage} />
      <Route path="/site/:siteId/report/management" component={ReportManagementPage} />
    </Switch>
  </>
}

export { ReportsDashPage }
