import React from 'react';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { standardBoxShadow } from '../../../constants/colors';

const StyledContentCard = withStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(2.5, 0),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow,
    padding: theme.spacing(1.5, 1.875),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 0.125),
    },
  }
}))((props) => <Card {...props} />);

export default StyledContentCard;
