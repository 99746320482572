import React from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import { useLinearProgressWithLabelStyles } from './LinearProgressWithLabel.css';
import classNames from 'classnames';
interface LinearProgressWithLabelProps {
  label: string;
  progressValue: number;
  maxValue: number;
  hideProgressLabel?: boolean;
  extraClass?: string;
}

const LinearProgressWithLabel = ({
  label,
  progressValue,
  maxValue,
  hideProgressLabel,
  extraClass }: LinearProgressWithLabelProps) => {
  const classes = useLinearProgressWithLabelStyles();
  return (
    <div>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1} className={classes.bar}>
          <LinearProgress variant="determinate" classes={{ root: extraClass ? classNames(classes.root, extraClass) : classes.root }} value={Math.round(100.0 * progressValue / maxValue)} />
        </Box>
      </Box>
      {
        label && <div className={classes.label}>{label} {!hideProgressLabel && `(${progressValue}/${maxValue})`}</div>
      }
    </div>
  );
}

export { LinearProgressWithLabel };

