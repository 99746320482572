import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import ReportSaveModal from '../ReportSaveModal/ReportSaveModal';
import notifyApi from '../../../api/prodNotificationApi';
import { ReportTypeGlobal, ReportTypeLocal } from '../../../constants/global';
import { useHistory } from 'react-router';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { useReportSubscribeWidgetStyle } from './ReportSubscribeWidget.css';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';

const ReportSubscribeWidget = ({
  currentUsername,
  reportType,
  siteId,
  timeFrame,
  saved,
  title,
  dirty }) => {
  const classes = useReportSubscribeWidgetStyle();
  const intl = useIntl();
  const history = useHistory();
  const url = window.location.href;

  const [isModalVisible, setModalVisible] = useState(false);
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);

  function copyToClipboard() {
    // create hidden element
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toastr.success(intl.formatMessage({ id: 'detail_UrlCopied' }));
  }

  const onCloseDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        if (saved === ReportTypeLocal) {
          await notifyApi.removeUserReport(siteId, currentUsername, title);
        }
        else {
          await notifyApi.removeGlobalReport(siteId, title);
        }
        toastr.success(`${intl.formatMessage({ id: 'detail_Deleted' })} ${title}`);
        // force querystring to be reloaded by navigation away and back
        history.push(`/site/${siteId}/report/management`);
        history.push(`/site/${siteId}/report/${reportType.toLowerCase()}`);
      } catch (e) {
        toastr.error(e.toString());
      }
      setDeleteConfirmVisible(false);
    } else {
      setDeleteConfirmVisible(false);
    }
  }

  const can = true;

  return (
    <>
      <ReportSaveModal
        cancel={() => setModalVisible(false)}
        open={isModalVisible}
        onSave={({ isGlobalReport, success, schedule, report }) => {
          if (success) {
            setModalVisible(false);
            if (schedule) {
              history.push(`/site/${siteId}/scheduledReport/${currentUsername}/0/add?${isGlobalReport ? 'globalReports' : 'localReports'}=${report.title}`);
            } else history.push(`/site/${siteId}/report/management`);
          }
        }}
        reportType={reportType}
        timeFrame={timeFrame}
        title={title}
        siteId={siteId}
        username={currentUsername}
      />
      <DeleteDialog
        title={<FormattedMessage id="detail_Warning" />}
        open={isDeleteConfirmVisible}
        onClose={onCloseDeleteDialog}
      />
      <div className={classes.actionButtons}>
        {url && <StyledActionButton
          tooltip={url}
          color="default"
          startIcon={<FileCopyIcon />}
          onClick={copyToClipboard}
          label={intl.formatMessage({ id: 'detail_Copy' })}
        />
        }
        <StyledActionButton
          color="default"
          disabled={saved && (!dirty || (saved === ReportTypeGlobal ? !can : false))}
          startIcon={<SaveIcon />}
          onClick={() => setModalVisible(true)}
          label={intl.formatMessage({ id: 'detail_Save' })}
        />
        <StyledActionButton
          color="default"
          tooltip={intl.formatMessage({ id: 'detail_Delete' })}
          disabled={(!can && saved === ReportTypeGlobal) || saved == null}
          startIcon={<DeleteIcon />}
          onClick={() => setDeleteConfirmVisible(true)}
          label={intl.formatMessage({ id: 'detail_Delete' })}
        />
      </div>
    </>
  );
};

ReportSubscribeWidget.propTypes = {
  currentUsername: string,
  dirty: bool,
  saved: string,
  reportType: string,
  timeFrame: string,
  title: string,
  siteId: string
};

export default ReportSubscribeWidget;
