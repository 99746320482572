import React, { useEffect, useState } from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import classNames from 'classnames';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { green } from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';
import { pdfjs, Page, Document } from 'react-pdf';
import useDimensions from 'react-use-dimensions';

import termsPdf from '../../static/VideojetConnect SaaS Terms and Conditions 080413.pdf';

import { useTermsDialogStyle } from './TermsDialog.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface TermsDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps['maxWidth'];
}

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const TermsDialog = ({
  open,
  maxWidth,
  onClose }: TermsDialogProps) => {
  const classes = useTermsDialogStyle();

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [termsModalRef, { width }] = useDimensions();
  const [canAccept, setCanAccept] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  useEffect(() => {
    if (canAccept) {
      return;
    }
    setCanAccept(pageNumber === numPages);
  }, [canAccept, pageNumber, numPages]);

  const onDocumentLoadSuccess = ({ numPages: num }) => { setNumPages(num); };

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'lg'}
    disableEscapeKeyDown
    aria-labelledby="terms" open={open}>
    <DialogTitle id="terms-dialog-title" >
      <div className={classes.title}>
        <div>
          <HelpOutlineIcon className={classes.titleIcon} />
        </div>
        <div>
          <div className={classes.titleText}>TERMS and CONDITIONS</div>
          <div>{`By registering you agree to Videojet's TERMS and CONDITIONS ${pageNumber} / ${numPages}`}</div>
        </div>
      </div>
      <IconButton aria-label="close" className={classNames(classes.closeButton, 'terms-close-btn')} onClick={handleCancel}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers>
      <div ref={termsModalRef} className={classes.documentContainer}>
        <Document
          file={termsPdf}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} width={width} />
        </Document>
      </div>
    </DialogContent>
    <DialogActions className={classes.buttonContainer}>
      <div className={classes.backBtnContainer} >
        <Button
          color="primary"
          variant="contained"
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
          startIcon={<NavigateBeforeIcon />}
          className={'previous-btn'}
        >
          <FormattedMessage id="detail_Back" />
        </Button>
      </div>
      <div className={classes.nextBtnContainer} >
        <Button
          color="primary"
          variant="contained"
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
          endIcon={<NavigateNextIcon />}
          className={classNames(classes.rightButton, 'next-btn')}
        >
          <FormattedMessage id="detail_Next" />
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const link = document.createElement('a');
            link.download = '.pdf';
            link.href = termsPdf;
            link.click();
            setCanAccept(true);
          }}
          startIcon={<GetAppIcon />}
          className={classNames(classes.rightButton, 'download-btn')}
        >
          <FormattedMessage id="detail_Download" />
        </Button>
        <ThemeProvider theme={theme}>
          <Button autoFocus
            onClick={handleOk}
            className="terms-ok-btn"
            variant="contained"
            disabled={!canAccept}
            color="primary">
            <FormattedMessage id="detail_Accept" />
          </Button>
        </ThemeProvider>
      </div>
    </DialogActions>
  </Dialog>;
}

export { TermsDialog };
