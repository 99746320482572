import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import Moment from 'moment-timezone';
import _ from 'lodash';
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography
} from '@material-ui/core';

import Utils from '../../utilities/utils';
import SiteHelper from '../../helpers/SiteHelper/SiteHelper';
import SchedulerHelper from '../../helpers/SchedulerHelper/SchedulerHelper';
import LineApi from '../../api/prodLineApi';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Logger } from '../../utilities/Logger/Logger';
import { ProgressIndicator } from '../../components/ProgressIndicator/ProgressIndicator';
import { useRetroactiveDowntimeModalStyles } from './RetroactiveDowntimeModal.css';
import { StyledDialogContent } from '../../components/StyledDialogContent/StyledDialogContent';
import { StyledDialogActions } from '../../components/StyledDialogActions/StyledDialogActions';
import { StyledDialogTitle } from '../../components/StyledDialogTitle/StyledDialogTitle';

function getDowntimeCategoriesForDisplay(downtimeCategories, intl) {
  return downtimeCategories && downtimeCategories
    .map(d => ({
      value: d.id,
      text: d.translationKey ? intl.formatMessage({ id: d.translationKey.replace(/\./g, '_') }) : d.title.replace(/\./g, '_')
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
}

const RetroactiveDowntimeModal = (props) => {
  const classes = useRetroactiveDowntimeModalStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDowntimeReason, setSelectedDowntimeReason] = useState<any>();
  const [timeInState, setTimeInState] = useState(props.timeInState);
  const [downtimeReasonNote, setDowntimeReasonNote] = useState('');
  const [subDowntimeReasonNote, setSubDowntimeReasonNote] = useState('');
  const [secondReasonId, setSecondReasonId] = useState(props.selectedEvent.secondReasonId)

  const intl = useIntl();
  const { cancel, lineId, open, selectedShift, site, title, start, end } = props;
  const eventToEdit = props.selectedEvent;

  const updateEditEvent = (event) => {
    setIsLoading(true);
    const value = event.target.value
    setTimeout(() => {
      const selectedDowntimeReasonId = value;
      const downtime = site.downtimeCategories.find(dc => dc.id === selectedDowntimeReasonId);
      setSelectedDowntimeReason(downtime);
      setSecondReasonId('');
      setIsLoading(false);
    });
  };

  const updateEditSubcategoryEvent = (event) => {
    setIsLoading(true);
    const value = event.target.value
    setTimeout(() => {
      setSecondReasonId(value);
      setIsLoading(false);
    });
  };

  const updateEventNote = (event) => {
    setDowntimeReasonNote(event.target.value);
  }

  const updateEventSubdowntimeNote = (event) => {
    setSubDowntimeReasonNote(event.target.value);
  }

  const handleSave = () => {
    setIsSaving(true);
    setTimeout(() => {
      let eventToUpdate = {};
      _.forEach(timeInState.timeLine, (timeInStateEvent) => {
        if (eventToEdit.startDT && timeInStateEvent.startTime === Utils.getFinalTimeForServer(eventToEdit.startDT, site.tz)) {
          eventToUpdate = Object.assign({}, timeInStateEvent, {
            lineId,
          });
        }
      });
      props.save(selectedDowntimeReason, downtimeReasonNote, eventToUpdate, secondReasonId, subDowntimeReasonNote);
    });
  }
  useEffect(() => {
    if (!timeInState) {
      // get data but don't add it to the store
      LineApi.getLineTimeInState(site.id, [lineId], Moment(selectedShift.start)
        .toISOString(), site.slowCycleThreshold)
        .then(timeInStateDataResponse => {
          setTimeInState(timeInStateDataResponse);
          setIsLoading(false);
        })
        .catch(error => Logger.of('App.LinePerformanceListTable.showRetroactiveDowntimeModal')
          .info('getLineTimeInState error', error));
    }
  }, [lineId, selectedShift.start, site.id, site.slowCycleThreshold, timeInState]);

  const selectedChildReasons = selectedDowntimeReason && selectedDowntimeReason.childIds ? selectedDowntimeReason.childIds.map(id => id) : [];

  const secondaryReasons = site.downtimeCategories.filter(category => selectedChildReasons.includes(category.id) && category.isSubcategory);

  return <Dialog open={open} >
    <StyledDialogTitle>
      <FormattedMessage id="detail_ChangeDowntime" />
    </StyledDialogTitle>

    <StyledDialogContent>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            {selectedShift
              && <div className={classes.shiftContainer}>
                <div className={classes.shift}><FormattedMessage id='detail_Shift' /></div>
                <div className={classes.shiftTitle}>{`${selectedShift.title ? selectedShift.title : title}`}</div>
                <div className={classes.shiftDate}>{`${Moment.tz(selectedShift.start ? selectedShift.start : start, site.tz).format('L HH:mm')} - ${Moment.tz(selectedShift.end ? selectedShift.end : end, site.tz).format('L HH:mm')}`}</div>
              </div>
            }
          </Grid>
        </Grid>

        <Grid container className={classes.divider}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        {eventToEdit.reasonId ? (
          <Grid container className={classes.marginBottom}>
            <Grid item xs={5} className={classes.label}>
              <Typography variant="h6"><FormattedMessage id={'detail_DowntimeCategoryReason'} /></Typography>
            </Grid>
            <Grid item xs={7}>
              {eventToEdit.status === 'Disconnected'
                ? <FormattedMessage id={'detail_PrinterStatus_Disconnected'} />
                : SiteHelper.getCategoryNameById(site.downtimeCategories, eventToEdit.reasonId)}{SchedulerHelper.getEventTypeById(eventToEdit.typeId)
            }
            </Grid>
          </Grid>
        ) : ''}

        {eventToEdit.reasonNote && eventToEdit.reasonNote !== '' ? (
          <Grid container className={classes.marginBottom}>
            <Grid item xs={5} className={classes.label}>
              <Typography variant="h6"><FormattedMessage id={'detail_DowntimeCategoryNotes'} /></Typography>
            </Grid>
            <Grid item xs={7}>
              {eventToEdit.status === 'Disconnected'
                ? <FormattedMessage id={'detail_PrinterStatus_Disconnected'} />
                : eventToEdit.reasonNote
              }
            </Grid>
          </Grid>
        ) : '' }

        {eventToEdit.secondReasonId ? (
          <Grid container className={classes.marginBottom}>
            <Grid item xs={5} className={classes.label}>
              <Typography variant="h6"><FormattedMessage id={'detail_DowntimeSubcategoryReason'} /></Typography>
            </Grid>
            <Grid item xs={7}>
              {eventToEdit.status === 'Disconnected'
                ? <FormattedMessage id={'detail_PrinterStatus_Disconnected'} />
                : SiteHelper.getCategoryNameById(site.downtimeCategories, eventToEdit.secondReasonId)}{SchedulerHelper.getEventTypeById(eventToEdit.typeId)
              }
            </Grid>
          </Grid>
        ) : '' }

        {eventToEdit.secondReasonId && eventToEdit.subReasonNote && eventToEdit.subReasonNote !== '' ? (
          <Grid container className={classes.marginBottom}>
            <Grid item xs={5} className={classes.label}>
              <Typography variant="h6"><FormattedMessage id={'detail_DowntimeSubcategoryNotes'} /></Typography>
            </Grid>
            <Grid item xs={7}>
              {eventToEdit.status === 'Disconnected'
                ? <FormattedMessage id={'detail_PrinterStatus_Disconnected'} />
                : eventToEdit.subReasonNote
              }
            </Grid>
          </Grid>
        ) : '' }

        <Grid container className={classes.marginBottom}>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id={'detail_DowntimeStart'} /></Typography>
          </Grid>
          <Grid item xs={7}>
            {Moment(eventToEdit.startDT).format('L HH:mm A')}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id={'detail_Length'} /></Typography>
          </Grid>
          <Grid item xs={7}>
            {eventToEdit.length}
          </Grid>
        </Grid>

        <Grid container className={classes.divider}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container className={classes.row}>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id="detail_SelectANewDowntimeCategoryReason" />&nbsp; <span className={classes.required}>*</span></Typography>
          </Grid>
          <Grid item xs={7} className={classes.controlItem}>
            <FormControl variant="outlined" className={classes.control}>
              <Select name="selectedDowntimeReason"
                disabled={isSaving}
                placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
                value={(selectedDowntimeReason && selectedDowntimeReason.id) || ''}
                onChange={updateEditEvent}
              >
                {getDowntimeCategoriesForDisplay(site.downtimeCategories.filter(cat => !cat.isSubcategory), intl).map(el =>
                  <MenuItem key={el.value} value={el.value}>{el.text}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {secondaryReasons.length !== 0 && <Grid container className={classes.row}>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id="detail_SelectANewDowntimeSubcategoryReason" /></Typography>
          </Grid>
          <Grid item xs={7} className={classes.controlItem}>
            <FormControl variant="outlined" className={classes.control}>
              <Select name="secondaryReasons"
                disabled={isSaving}
                placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
                value={secondReasonId || ''}
                onChange={updateEditSubcategoryEvent}
              >
                {getDowntimeCategoriesForDisplay(secondaryReasons, intl).map(el =>
                  <MenuItem key={el.value} value={el.value}>{el.text}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>}
        <Grid container className={classes.row}>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id="detail_EnterADowntimeCategoryNote" /></Typography>
          </Grid>
          <Grid item xs={7} className={classes.controlItem}>
            <TextareaAutosize
              className={classes.textArea}
              onChange={updateEventNote}
              name={'downtimeNote'}
              maxLength={255}
              rowsMin={4}
              disabled={isSaving}
              value={downtimeReasonNote}
            />
          </Grid>
        </Grid>
        {secondaryReasons.length !== 0 && secondReasonId !== '' && <Grid container className={classes.row}>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id="detail_EnterADowntimeSubcategoryNote" /></Typography>
          </Grid>
          <Grid item xs={7} className={classes.controlItem}>
            <TextareaAutosize
              className={classes.textArea}
              onChange={updateEventSubdowntimeNote}
              name={'subDowntimeNote'}
              maxLength={255}
              rowsMin={4}
              disabled={isSaving}
              value={subDowntimeReasonNote} />
          </Grid>
        </Grid>
        }
      </Grid>
      {isLoading && <ProgressIndicator />}
    </StyledDialogContent>
    <StyledDialogActions>
      <Button color="primary" onClick={cancel}>
        <FormattedMessage id={'detail_Cancel'} />
      </Button>

      <Button
        onClick={handleSave}
        variant="contained"
        color="primary"
        disabled={!eventToEdit || !selectedDowntimeReason || isSaving}
        size="large"
      >
        {!isSaving && <FormattedMessage id={'detail_Save'} />}
        {isSaving && <FormattedMessage id={'detail_Saving'} />}
      </Button>
    </StyledDialogActions>
  </Dialog>;
};

RetroactiveDowntimeModal.defaultProps = {};

RetroactiveDowntimeModal.propTypes = {
  cancel: func.isRequired,
  lineId: string,
  open: bool.isRequired,
  save: func,
  site: object.isRequired,
  selectedEvent: object,
  selectedShift: object.isRequired,
  timeInState: object,
  title: string,
  start: string,
  end: string,
};

export default injectIntl(RetroactiveDowntimeModal);
