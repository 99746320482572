import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  Grid,
  Typography
} from '@material-ui/core';
import moment from "moment";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useIntl } from 'react-intl';
import { validation } from 'vccm-common';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import DateHelper from '../../../helpers/DateHelper/DateHelper';
import { useReportHeaderStyles } from './ReportHeader.css';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { getCurrentUser } from '../../../libs/awsLib';
import ReportSubscribeWidget from '../ReportSubscribeWidget/ReportSubscribeWidget';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';
import { StyledDropDown } from '../../../components/StyledDropdown/StyledDropdown';
import { ReportTypes } from '../../../enums/ReportTypes';

interface ReportHeaderProps {
  reportType: string;
  enableLineDisplay?: boolean;
  enableLineFilter?: boolean;
  enableProductFilter?: boolean;
  enableShiftFilter?: boolean;
  enableTimeInterval?: boolean;
  filter: any;
  getNewData: () => void;
  isLoading: boolean;
  saved: string;
  shifts: Array<any>;
  lines?: Array<any>;
  products?: Array<any>;
  singleSelectionFilters?: boolean;
  updateReportFilterData: ({ name, value }: { name: string; value: any }) => void;
  subHeader: string;
  timeFrames?: any;
  title: string;
  siteId: string;
}

const ReportHeader = ({
  reportType,
  enableLineDisplay,
  enableLineFilter,
  enableProductFilter,
  enableShiftFilter,
  enableTimeInterval,
  filter,
  getNewData,
  isLoading,
  saved,
  shifts,
  lines,
  products,
  singleSelectionFilters,
  updateReportFilterData,
  subHeader,
  timeFrames,
  title,
  siteId
}: ReportHeaderProps) => {
  const intl = useIntl();
  const classes = useReportHeaderStyles();

  const [userName, setUsername] = useState('');

  const stepOptions = useMemo(() => [
    {
      value: 'true',
      text: intl.formatMessage({ id: 'detail_Time_Interval' })
    },
    {
      value: 'false',
      text: intl.formatMessage({ id: 'detail_Occurrences' })
    }
  ], [intl]);

  useEffect(() => {
    (async () => {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        setUsername(currentUser['username']);
      }
    })();
  }, []);

  const linesFormatted = lines && Array.isArray(lines) ? lines.map(line => ({
    value: line.id,
    text: line.title,
  })) : [];

  const productsFormatted = products && Array.isArray(products) ? products.filter(p => p)
    .map(p => ({
      value: p.id,
      text: p.title,
    })) : [];

  const lineDisplayOption = [{
    value: 1,
    translationKey: 'detail_Summary',
  }, {
    value: 2,
    translationKey: 'detail_Detail',
  }];

  const updateReportFilterDataWithSelect = (name: string) => (value) => {
    updateReportFilterData({ name, value });
  }
  const updateReportFilterDataWithDate = (name: string) => (event) => updateReportFilterData({ name, value: event });
  const updateReportFilterDataWithSelectStep = (name: string) => (value) => {
    updateReportFilterData({ name, value: value === 'true' });
  }
  const getStartDateValue = () => {
    const startDateStr = (filter.startDt && validation.validateDateString(filter.startDt) && filter.startDt.format('mm/dd/yyyy')) || (filter.startDt)
    return startDateStr ? moment(startDateStr, 'MM/DD/YYYY').toDate() : null;
  }

  const getEndDateValue = () => {
    const endDateStr = (filter.endDt && validation.validateDateString(filter.endDt) && filter.endDt.format('mm/dd/yyyy')) || (filter.endDt)
    return endDateStr ? moment(endDateStr, 'MM/DD/YYYY').toDate() : null;
  }

  return <>
    <div className={classes.title}>
      <Typography variant="h5">{title || subHeader}</Typography>
    </div>
    <Grid container spacing={2}>
      <Grid container>
        <Grid item className={classes.cell}>
          <FormControl variant="outlined" className={classes.formControl}>
            <StyledDropDown
              labelId="timeframe-id-label"
              multiple={false}
              name="timeFrameId"
              label={intl.formatMessage({ id: 'detail_TimeFrame' })}
              placeholder={intl.formatMessage({ id: 'detail_TimeFrame' })}
              options={(timeFrames || DateHelper.getFormattedTimeFrames(intl))}
              value={filter.timeFrameId}
              onChange={updateReportFilterDataWithSelect('timeFrameId')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.cell}>
          <FormControl variant="outlined" className={classes.formControl}>
            <KeyboardDatePicker
              tabIndex={1}
              autoOk
              inputVariant="outlined"
              label={intl.formatMessage({ id: 'detail_Start' })}
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              name="startDt"
              disabled={filter.timeFrameId !== 'customDateRange'}
              value={getStartDateValue()}
              className={classes.datePicker}
              InputAdornmentProps={{ position: "start" }}
              onChange={filter.timeFrameId !== 'customDateRange' ? () => { } : updateReportFilterDataWithDate('startDt')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.cell}>
          <FormControl variant="outlined" className={classes.formControl}>
            <KeyboardDatePicker
              tabIndex={2}
              autoOk
              variant="inline"
              inputVariant="outlined"
              label={intl.formatMessage({ id: 'detail_End' })}
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              name="endDate"
              disabled={filter.timeFrameId !== 'customDateRange'}
              value={getEndDateValue()}
              InputAdornmentProps={{ position: "start" }}
              className={classes.datePicker}
              onChange={filter.timeFrameId !== 'customDateRange' ? () => { } : updateReportFilterDataWithDate('endDt')}
            />
          </FormControl>
        </Grid>

        {
          enableTimeInterval && <Grid item className={classes.cell}>
            <FormControl variant="outlined" className={classes.formControl}>
              <StyledDropDown
                labelId="timeinterval-id-label"
                multiple={false}
                name="timeIntervalId"
                label={intl.formatMessage({ id: 'detail_Time_Interval' })}
                placeholder={intl.formatMessage({ id: 'detail_Time_Interval' })}
                options={DateHelper.getFormattedTimeIntervals(intl)}
                value={filter.timeIntervalId}
                onChange={updateReportFilterDataWithSelect('timeIntervalId')}
              />
            </FormControl>
          </Grid>
        }
        {
          reportType === ReportTypes.Downtime && <Grid item className={classes.cell}><FormControl variant="outlined" className={classes.formControl}>
            <StyledDropDown
              labelId="downtimeElapsed"
              multiple={false}
              name="downtimeElapsed"
              label={intl.formatMessage({ id: 'detail_Step' })}
              placeholder={intl.formatMessage({ id: 'detail_Step' })}
              options={stepOptions}
              value={filter.downtimeElapsed ? 'true' : 'false'}
              onChange={updateReportFilterDataWithSelectStep('downtimeElapsed')}
            />
          </FormControl>
          </Grid>
        }


        {!(enableLineFilter === false) && (
          <Grid item className={classes.cell}>
            <FormControl variant="outlined" className={classes.formControl}>
              <StyledDropDown
                labelId="line-label"
                name="lineIds"
                multiple={!singleSelectionFilters}
                enableRemoveButton={true}
                label={intl.formatMessage({ id: singleSelectionFilters ? 'detail_Line' : 'detail_Lines' })}
                placeholder={intl.formatMessage({ id: 'detail_All_Ellipsis' })}
                options={linesFormatted}
                value={filter.lineIds}
                onChange={updateReportFilterDataWithSelect('lineIds')}
              />
            </FormControl>
          </Grid>
        )}
        {enableShiftFilter
          && (
            <Grid item className={classes.cell}>
              <FormControl variant="outlined" className={classes.formControl}>
                <StyledDropDown
                  labelId="shift-label"
                  name="reportShifts"
                  multiple={!singleSelectionFilters}
                  enableRemoveButton={true}
                  label={intl.formatMessage({ id: singleSelectionFilters ? 'detail_Shift' : 'detail_Shifts' })}
                  placeholder={intl.formatMessage({ id: 'detail_All_Ellipsis' })}
                  options={shifts}
                  value={filter.reportShifts}
                  onChange={updateReportFilterDataWithSelect('reportShifts')}
                />
              </FormControl>
            </Grid>
          )}
        {enableProductFilter
          && (
            <Grid item className={classes.cell}>
              <FormControl variant="outlined" className={classes.formControl}>
                <StyledDropDown
                  labelId="product-label"
                  name="reportProduct"
                  multiple={false}
                  enableRemoveButton={true}
                  label={intl.formatMessage({ id: 'detail_Product' })}
                  placeholder={intl.formatMessage({ id: 'detail_All_Ellipsis' })}
                  options={productsFormatted}
                  value={filter.reportProduct}
                  onChange={updateReportFilterDataWithSelect('reportProduct')}
                />
              </FormControl>
            </Grid>
          )}
        {!(enableLineDisplay === false) && (
          <Grid item className={classes.cell}>
            <FormControl variant="outlined" className={classes.formControl}>
              <StyledDropDown
                labelId="line-label"
                multiple={false}
                name="reportLineDisplay"
                label={intl.formatMessage({ id: 'detail_Line_Display' })}
                placeholder={intl.formatMessage({ id: 'detail_All_Ellipsis' })}
                options={DateHelper.formatData(lineDisplayOption, intl)}
                value={filter.reportLineDisplay}
                onChange={updateReportFilterDataWithSelect('reportLineDisplay')}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item className={classes.cell} lg={6} md={12} sm={12} xs={12}>
          <div className={classes.actionButtons}>
            <div className={classes.applyContainer}>
              <StyledActionButton
                color="primary"
                startIcon={<PlayArrowIcon />}
                disabled={!filter.isDirty || isLoading}
                onClick={() => {
                  filter.canSave = true;
                  return getNewData();
                }}
                label={intl.formatMessage({ id: 'detail_Apply' })}
              />
            </div>
            <ReportSubscribeWidget
              currentUsername={userName}
              dirty={!filter.isDirty ? filter.canSave : filter.isDirty}
              reportType={reportType}
              timeFrame={filter.timeFrameId}
              siteId={siteId}
              title={title}
              saved={saved}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
    {
      isLoading && <ProgressIndicator />
    }
  </>
};

export default ReportHeader;
