import RoleApi from "../../api/prodRoleApi";
import UsersApi from "../../api/prodUserApi";

async function loadUsers(companies) {
  let paginationToken = null;
  const allUsers: Array<any> = [];
  do {
    try {
      const response = await UsersApi.getAllUsers(1, paginationToken, companies);
      paginationToken = response.paginationToken; //eslint-disable-line prefer-destructuring
      if (Array.isArray(response)) {
        allUsers.push(...response);
      }
    } catch (error) {
      console.log('userActions.allUsers error', error);
      throw error;
    }
  } while (paginationToken);
  return allUsers;
}

async function getRoles(sideId = ''): Promise<Array<any>> {
  try {
    const roles = await RoleApi.list(sideId);
    return roles.map(el => ({ ...el, key: el.key ? el.key.replace('detail.', 'detail_') : el.key }));
  } catch (error) {
    console.log('roleActions.getRoles error', error);
    throw error;
  }
}

async function getUserRoles(siteId: string, userId: string): Promise<any> {
  try {
    const userRoles = await RoleApi.getUserRoles(siteId, userId);
    return userRoles[siteId];
  } catch (error) {
    console.log('roleActions.getUserRoles error', error);
    throw error;
  }
}

export { loadUsers, getRoles, getUserRoles };
