import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from '../../../utilities/Logger/Logger';
import { useTooltipContentTemplateStyle } from './TooltipContentTemplate.css';

function TooltipContentTemplate(props) {
  const classes = useTooltipContentTemplateStyle();
  Logger.of('App.TooltipContentTemplate')
    .info('TooltipContentTemplate props', props);
  return (
    <div className={classes.root}>
      {props.tooltip && props.tooltip.data.key}
      <strong>{props.tooltip && props.tooltip.data.value}</strong>
      <strong>
        {' '}
        {props.tooltip && props.tooltip.length}
      </strong>
      <br />
      <div style={{ maxWidth: "80ch", overflow: "break-word" }}>
        {props.tooltip.note}
      </div>
    </div>
  );
}
TooltipContentTemplate.propTypes = {
  tooltip: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    note: PropTypes.string,
    length: PropTypes.string,
    display:  PropTypes.bool,
    pos: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    })
  }),
  title: PropTypes.string
};
export default TooltipContentTemplate;
