import toastr from 'toastr';
import React, { useCallback, useEffect, useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { cognitoFields, validation } from 'vccm-common';
import Axios, { AxiosRequestConfig } from 'axios';
import { sign } from 'jsonwebtoken';
import config from '../../config';
import countries from '../../utilities/countries';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { InfoDialog } from '../InfoDialog/InfoDialog';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  FormControl,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps
} from '@material-ui/core';

import { NewPasswordDialog } from '../NewPasswordDialog/NewPasswordDialog';
import { useSignUpStyle } from './Signup.css';
import { SimpleDropdown } from '../Basic/SimpleDropdown/SimpleDropdown';

const countriesArray = Object.entries(countries['en-US'].detail)
  .map(([k, v]) => {
    const lowerKey = k.toLowerCase();
    return ({
      flag: lowerKey,
      key: lowerKey,
      label: lowerKey,
      text: v,
      value: lowerKey,
    });
  });

const endpoint = `${config.apiGateway.USERS_URL}/users/vrsSignup`;

interface SignupProps {
  open: boolean;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps['maxWidth'];
}

const Signup = ({
  open,
  maxWidth,
  onClose }: SignupProps) => {
  const intl = useIntl();
  const classes = useSignUpStyle();
  const { userType } = useParams<{ userType: string }>();

  const [companyName, setCompanyName] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [requestError, setRequestError] = useState<string>('');
  const [first, setFirst] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [last, setLast] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [, setSignedUpUser] = useState<any>();
  const [siteId, setSiteId] = useState<string>();
  const [results, setResults] = useState<string>('');
  const [status, setStatus] = useState<any>('');
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const validateSignup = useCallback(() => {
    let errs: any = {};
    const user = {
      company: companyName,
      country,
      email,
      firstName: first,
      lastName: last,
    };
    const userValidate = validation.schemas.user.validate(user, { abortEarly: false });

    const vrsErrors = userType === 'vrs' ? {
      ...(!(siteId && siteId.length) ? { siteId: 'validation.user.siteId' } : {}),
      ...(!(companyName && companyName.length) ? { companyId: 'validation.user.companyId' } : {}),
    } : {};

    const vrsValid = userType === 'vrs' ? Object.keys(vrsErrors).length === 0 : true;

    if (userValidate.error == null && vrsValid) {
      setErrors('');
      return true;
    }

    errs = userValidate.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, '_') : '';
      return agg;
    }, {});

    if (userType === 'vrs') {
      delete errs.password;
      delete errs.confirmPassword;
    }

    setErrors({ ...errs, ...vrsErrors });
    return Object.keys(user)
      .every(key => errs[key] == null) && vrsValid;
  }, [setErrors, companyName, country, email, first, last, userType, siteId]);

  const handleOnChange = useCallback((name, value) => {
    switch (name) {
      case 'companyName':
        setCompanyName(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'first':
        setFirst(value);
        break;
      case 'last':
        setLast(value);
        break;
      case 'siteId':
        setSiteId(value);
        break;
      default:
        break;
    }
  }, [setCompanyName, setCountry, setEmail, setFirst, setLast, setSiteId]);

  useEffect(() => {
    if (showErrors) {
      validateSignup();
    }

  }, [showErrors, first, last, email, country, companyName, siteId, validateSignup])

  const handleResponse = useCallback(async (resp) => {
    if (resp == null) {
      setRequestError(intl.formatMessage({ id: 'detail_UnknownError' }));
    } else if (resp.status === 200) {
      setResults(intl.formatMessage({ id: 'detail_PleaseCheckEmailThenSignIn' }));
    } else if (resp.status === 202) {
      setResults(resp.data && resp.data.message
        ? intl.formatMessage({ id: resp.data.message })
        : JSON.stringify(resp));
    } else {
      setResults(JSON.stringify(resp));
    }
    setStatus(resp && resp.status);
  }, [intl]);

  const signup = useCallback((username, pwd, attributeList) => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });

    return new Promise((resolve, reject) => userPool.signUp(username, pwd, attributeList || [], [], (err, result) => {
      if (!result || err) {
        reject(err);
        return;
      }
      resolve(result.user);
    }));
  }, []);

  // toast on error
  useEffect(() => {
    if (requestError) {
      toastr.error(requestError);
      setRequestError('');
    }
  }, [requestError]);


  const onCloseNewPasswordDialog = async (password) => {
    if (password) {
      setIsLoading(true);
      try {
        const attributeList = [
          {
            Name: 'given_name',
            Value: first,
          },
          {
            Name: 'family_name',
            Value: last,
          },
          {
            Name: cognitoFields.COMPANY_NAME,
            Value: companyName,
          },
          {
            Name: cognitoFields.COUNTRY,
            Value: country,
          },
        ];
        const newUser = await signup(email.toLowerCase(), password, attributeList);
        setSignedUpUser(newUser);
        setShowPassword(false);
        setStatus('success');
        setResults(intl.formatMessage({ id: 'detail_AccountCreated' }));
        setShowInfo(true);
      } catch (e) {
        setStatus('error');
        setResults(e.message);
        setIsLoading(false);
        setShowPassword(false);
        setShowInfo(true);
      }
    } else {
      setShowPassword(false);
    }
  };

  const handleSubmitForm = async () => {
    setShowErrors(true);
    if (validateSignup()) {
      if (userType === 'vrs') {
        setIsLoading(true);
        setResults('');
        const options: AxiosRequestConfig = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sign({ siteId },
              companyName)
          }
        };
        try {
          const response = await Axios.post(endpoint, {
            email,
            first,
            last,
          }, options);

          handleResponse({...response});
          setShowInfo(true);
        } catch (e) {
          setRequestError(`${intl.formatMessage({ id: 'detail_NotAuthorizedSiteIdCompanyId' })}\n${siteId}, ${companyName}`);
        }
        setIsLoading(false);
      } else {
        setShowPassword(true);
      }
    }
  }

  const closeInfoDialog = () => {
    setShowInfo(false);
    setShowPassword(false);
    handleOk();
  }

  return <>
    {
      !showPassword && !showInfo && <Dialog disableBackdropClick
        classes={{ paper: classes.root }}
        fullWidth={true}
        maxWidth={maxWidth || 'sm'}
        disableEscapeKeyDown
        aria-labelledby="sign-up" open={open}>
        <DialogTitle id="terms-dialog-title" >
          <div className={classes.title}>
            <div>
              <PersonAddIcon className={classes.titleIcon} />
            </div>
            <div>
              <div className={classes.titleText}>{intl.formatMessage({ id: 'detail_Create_Account' })}</div>
              <div>{userType === 'vrs'
                ? intl.formatMessage({ id: 'detail_Remote_Service' })
                : intl.formatMessage({ id: 'detail_Design' })}</div>

            </div>
          </div>
          <IconButton aria-label="close" className={classNames(classes.closeButton, 'terms-close-btn')} onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="first"
              label={intl.formatMessage({ id: 'detail_First_Name' })}
              onChange={(event) => handleOnChange('first', event.target.value)}
              id="first"
              value={first}
              error={errors && !!errors.firstName}
              helperText={errors && errors.firstName && intl.formatMessage({ id: errors.firstName })}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="last"
              label={intl.formatMessage({ id: 'detail_Last_Name' })}
              onChange={(event) => handleOnChange('last', event.target.value)}
              id="last"
              value={last}
              error={errors && !!errors.lastName}
              helperText={errors && errors.lastName && intl.formatMessage({ id: errors.lastName })}
            />
          </FormControl>

          {
            userType === 'vrs' ? <>
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="companyName"
                  label={intl.formatMessage({ id: 'detail_Company_Id' })}
                  onChange={(event) => handleOnChange('companyName', event.target.value)}
                  id="companyName"
                  value={companyName}
                  error={errors && !!errors.companyId}
                  helperText={errors && errors.companyId && intl.formatMessage({ id: errors.companyId })}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="siteId"
                  label={intl.formatMessage({ id: 'detail_Site_Id' })}
                  onChange={(event) => handleOnChange('last', event.target.value)}
                  id="siteId"
                  value={siteId}
                  error={errors && !!errors.siteId}
                  helperText={errors && errors.siteId && intl.formatMessage({ id: errors.siteId })}
                />
              </FormControl>
            </> : <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="companyName"
                  label={intl.formatMessage({ id: 'detail_Company_Name' })}
                  onChange={(event) => handleOnChange('companyName', event.target.value)}
                  id="companyName"
                  value={companyName}
                  error={errors && !!errors.company}
                  helperText={errors && errors.company && intl.formatMessage({ id: errors.company })}
                />
              </FormControl>
          }
          <FormControl variant="outlined" required className={classes.formControlInner}>
            <SimpleDropdown
              id="country"
              name="country"
              required={true}
              label={intl.formatMessage({ id: 'detail_Country' })}
              canRemoveAll={false}
              placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
              onChange={(value) => handleOnChange('country', value)}
              options={countriesArray}
              value={country}
              error={errors && !!errors.country}
              helperText={errors && errors.country && intl.formatMessage({ id: errors.country })}
            />

          </FormControl>

          <FormControl required className={classes.formControl}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label={intl.formatMessage({ id: 'detail_Email' })}
              onChange={(event) => handleOnChange('email', event.target.value)}
              id="email"
              value={email}
              error={errors && !!errors.email}
              helperText={errors && errors.email && intl.formatMessage({ id: errors.email })}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button autoFocus
            onClick={handleSubmitForm}
            className="terms-ok-btn"
            variant="contained"
            color="primary">
            {userType === 'vrs'
              ? intl.formatMessage({ id: 'detail_Submit' })
              : intl.formatMessage({ id: 'detail_EnterNewPassword' })}
          </Button>
        </DialogActions>
      </Dialog>
    }
    {
      showPassword && !showInfo && <NewPasswordDialog
        loading={isLoading}
        open={showPassword}
        onClose={onCloseNewPasswordDialog}
      />
    }
    {
      showInfo && <InfoDialog
        title={intl.formatMessage({ id: status === 'success' ? 'detail_VideojetConnectUserCreated' : 'detail_SignupError' })}
        open={showInfo}
        onClose={closeInfoDialog}
        content={<div className={classes.infoContainer}>
          <div className={classes.successIcon}>{status === 'success' && <CheckIcon fontSize="large"/>}</div>
          <div className={classes.errorIcon}>{status ==='error' && <ErrorIcon fontSize="large"/>}</div>
          <div>{results}</div>
        </div>}
      />
    }
  </>
};

export default Signup;
