import { makeStyles } from "@material-ui/core";
import { myGrayShade4 } from "../../../constants/colors";

export const useSimpleDropdownStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.75, 2.875),
    minHeight: theme.spacing(4.5)
  },
  dimmedLabel: {
    color: myGrayShade4
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(1.125)
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  itemText: {
    whiteSpace: "pre-wrap"
  },
  errorText: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  }
}));
