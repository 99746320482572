// Third party libraries
import moment from 'moment-timezone';
import * as localeMoment from 'moment';
import { v4 as uuid } from 'uuid';
import qstr from 'query-string';
import util from "util";
import { Logger } from './Logger/Logger';
import * as ENUMS from '../enums/Enums';

import _ from 'lodash';

const scheduleTypes: Array<any> = [
  { text: 'Shift', value: 1, color: '#56ca85' },
  { text: 'Maintenance', value: 2, color: '#f8a398' },
  { text: 'Break', value: 3, color: '#cac33b' },
];

const rRuleUntilFormat = 'YYYYMMDD[T]HHmm00[Z]';

function pad(n: number): string {
  return n < 10 ? `0${n}` : `${n}`;
}

export function cloneObj(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function yyyymmddToddmmyyyy(yyyymmdd: string): string {
  const date = new Date(yyyymmdd);
  const dd = pad(date.getDate());
  const mm = pad(date.getMonth() + 1);
  const yyyy = date.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

export function addToCurrentYear(extraYear: number): number {
  const now = new Date();
  return now.getFullYear() + extraYear;
}

export function normaliseName(name: string): string {
  return name ? name.toLowerCase().replace(/[\s/,.?()*]/g, '_') : name;
}

export function produceNormalizedName(name: string, truncationLimit: number): string {
  if (name) {
    return normaliseName(name.length <= truncationLimit && truncationLimit >= 3 ? name : `${name.substring(0, truncationLimit - 3)}...`);
  }

  return name;
}

export function convertId(id: string): string {
  return id.replace(/\//g, '_').replace(/#/g, '.');
}

export const smallDelay = (delayAmount: number): Promise<boolean> => new Promise(resolve => setTimeout(() => {
  resolve(true);
}, delayAmount));

export const singleTick = () => new Promise(resolve => setTimeout(() => {
  resolve(true);
}));

export function standardiseWithZero(value: number): string | number {
  return value < 10 ? `0${value}` : value;
}

export function formattedDateTimeStringFromMomentObject(momentDate, includeTimePart = true): string {
  const timePart = `${standardiseWithZero(momentDate.hours())}:${standardiseWithZero(momentDate.minutes())}`;
  return includeTimePart ? `${yyyymmddToddmmyyyy(momentDate.format('YYYY-MM-DD'))} ${timePart}` : yyyymmddToddmmyyyy(momentDate.format('YYYY-MM-DD'));
}

export function formattedLocalDateTimeStringFromZuluDateString(utcDate: string, includeTimePart = true): string {
  const now = moment(utcDate);
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return includeTimePart ? `${yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'))} ${timePart}` : yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'));
}

export function formattedUTCDateTimeStringFromZuluDateString(utcDate: string, includeTimePart = true): string {
  const now = moment.utc(utcDate);
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return includeTimePart ? `${yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'))} ${timePart}` : yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'));
}

export function currentDateTime(): string {
  const now = moment();
  const timePart = `${standardiseWithZero(now.hours())}:${standardiseWithZero(now.minutes())}`;
  return `${yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'))} ${timePart}`;
}

export function currentDate(): string {
  const now = moment();
  return yyyymmddToddmmyyyy(now.format('YYYY-MM-DD'));
}


export function buildUrl(valueArray: Array<any>): string {
  let path = '';
  for (const value of valueArray) { // eslint-disable-line no-restricted-syntax
    if (value || value === 0) {
      path = `${path}/${value}`;
    } else {
      return path;
    }
  }

  return path;
}

export function produceTooltip(pre: string, values: Array<string>, post: string): string {
  return values.reduce((acc, el, index) => {
    let seperator = ', ';
    if (index === values.length - 2) {
      seperator = ' or ';
    } else if (index === values.length - 1) {
      seperator = post;
    }
    return acc + el + seperator;
  }, pre);
}

export function equalsIgnoreCase(value1: null | undefined | string, value2: string): boolean {
  if (value1 === value2) {
    return true;
  }

  if (!value1 || !value2) {
    return false;
  }
  return value1.toLowerCase() === value2.toLowerCase();
}

export function isNotNullOrUndefined(obj: any): boolean {
  return obj !== null && obj !== undefined;
}

/**
 * Modifies an object to remove properties that are empty
 *
 * @param {object} obj -  to be modified
 * @param {string|[string]=} propertyNames - optional list of properties to update, otherwise all
 * @returns {object} updated object
 */

export const prepToSend = (obj, propertyNames) => {
  const pNames = (!propertyNames ? Object.keys(obj) : Array.isArray(propertyNames) ? propertyNames : [propertyNames]);
  for (const name of pNames) {
    if (obj[name] != null && obj[name].length === 0) { obj[name] = null; }
  }
  return obj;
};

class Utils {
  static flattenMessages(nestedMessages, prefix = '') {
    Logger.of('Utils.flattenMessages').info(`prefix is ${prefix}, nestedMessages=>`, nestedMessages);
    if (!nestedMessages) {
      return {};
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, this.flattenMessages(value, prefixedKey));
      }
      return messages;
    }, {});
  }

  static extractErrors(error, mixedMode = false) {
    if (error == null) return error;

    let errStr = '';
    try {
      if (error.statusCode && error.statusCode !== 200) {
        errStr = `Code: ${error.statusCode} : `;
      }
      if (error.message) {
        try {
          const err = JSON.parse(error.message);
          if (err.message) {
            errStr += `Message: ${err.message}`;
          } else {
            errStr += `Message: ${error.message}`;
          }
        } catch (e) {
          if (mixedMode) {
            errStr += error.message;
          } else {
            throw (e);
          }
        }
      }
    } catch (e) {
      return `Error building error string: ${util.inspect(e)}`;
    }
    return errStr;
  }

  static isDev() {
    return window.location.host.includes('localhost') || window.location.host.includes('vccm.dev')
  }

  static isOrderExEnabled() {
    return false;
  }

  static getStage() {
    if (window.location.host.toLowerCase().includes('localhost') || window.location.host.includes('vccm.dev')) return "dev"
    if (window.location.host.toLowerCase().includes('vccm.qa')) return "qa"
    if (window.location.host.toLowerCase().includes('vccm.stage')) return "stage"
    if (window.location.host.toLowerCase().includes('videojetcloud')) return "production"
    if (window.location.host.toLowerCase().includes('vccm.prodtest')) return "prodtest"
  }
  /**
   * adds a event to each event in timeInStateData for each event in oeeData that oee is less then the threshold
   *
   * @function
   * @param {Array} timeInStateData - timeInStateEvents
   * @param {Array} processMinuteData - data from shiftTargets endpoint
   * @param {number} threshold - value multiplied by the run rate to determine the slow cycle rate
   */
  static addSlowCycleToTimeInStateEvents(timeInStateData, processMinuteData, threshold = 1) {
    const mergedData: Array<any> = [];
    if (!processMinuteData) return timeInStateData;
    // let expectedPiecesPerMinutes = (processMinuteData.rates.runRate / 60) * threshold;
    // sort OeeData by eventStartDT
    _.forEach(timeInStateData, (timeInStateEvent) => {
      if (timeInStateEvent.type === 'schedule' && timeInStateEvent.typeId
        === scheduleTypes.find(i => i.text === 'Shift').value) {
        // add an event from the time of the first timeInStateData event until the first oeeDataEvent
        // console.log('got an production schedule event =>', timeInStateEvent);
        const newEvents = Utils.addSlowCycleToTimeInStateEvent(timeInStateEvent, processMinuteData);
        // console.log('Here are your events =>', newEvents);
        _.forEach(newEvents, (event) => {
          mergedData.push(event);
        });
      } else {
        mergedData.push(timeInStateEvent);
      }
    });

    // console.log('addSlowCycleToTimeInStateEvents begin', timeInStateData);
    // console.log('addSlowCycleToTimeInStateEvents end', mergedData);
    return mergedData;
  }

  /**
   * adds a event to each event in timeInStateData for each event in oeeData that oee is less then the threshold
   *
   * @function
   * @param {Array} timeInStateData - timeInStateEvents
   * @param {Array} processMinuteData - data from shiftTargets endpoint
   */
  static addProcessMinuteDataToTimeInStateEvents(timeInStateData, processMinuteData) {
    const mergedData: Array<any> = [];
    if (!processMinuteData) return timeInStateData;
    // let expectedPiecesPerMinutes = (processMinuteData.rates.runRate / 60) * threshold;
    // sort OeeData by eventStartDT
    _.forEach(timeInStateData, (timeInStateEvent) => {
      if (timeInStateEvent.type === 'schedule' && timeInStateEvent.typeId
        === scheduleTypes.find(i => i.text === 'Shift').value) {
        // add an event from the time of the first timeInStateData event until the first oeeDataEvent
        // console.log('got an production schedule event =>', timeInStateEvent);
        const newEvents = Utils.addProcessMinuteDataToTimeInStateEvent(timeInStateEvent, processMinuteData);
        // console.log('Here are your events =>', newEvents);
        _.forEach(newEvents, (event) => {
          mergedData.push(event);
        });
      } else {
        mergedData.push(timeInStateEvent);
      }
    });

    // console.log('addSlowCycleToTimeInStateEvents begin', timeInStateData);
    // console.log('addSlowCycleToTimeInStateEvents end', mergedData);
    return mergedData;
  }

  /**
   * adds a slice event of type microStop for each event less than the given time-span to the passed events
   *
   * @function
   * @param {Array} timeInStateEvent - starting event before slow cycles
   * @param {Array} processMinuteData - data to get counts from
   * @param {number} expectedPiecesPerMinutes - minimal count/minute to not get categorized as a slow cycle
   * @param {boolean} combineSlices - do you want the concurrent events of the same type merged.. TODO show slow cycle percent. for now combine slices
   */
  static addSlowCycleToTimeInStateEvent(timeInStateEvent, processMinuteData, combineSlices = true) {
    // console.log('got an production schedule event =>', timeInStateEvent);
    // look for events in oeeData that are in this range
    // let oeeDataInRange.find(item => item.)
    let eventToAdd;
    let firstEvent = true;
    const returnData: Array<any> = [];
    let lastEvent;
    let lastOeeEvent;
    const beforeTime = moment(timeInStateEvent.startTime);
    const afterTime = moment(timeInStateEvent.endTime);
    // let containsOeeEvent = false;
    if (processMinuteData) {
      // for performance we will slice off events already processed this will only work if the events are in order
      // _.forEach(oeeData.actual.slice(currentOeeDataIndex), function(oeeEvent) {
      _.forEach(processMinuteData, (slowCycle) => {
        const time = moment(slowCycle.dt);
        // look for events in oeeData that are in this range
        if (time.isBetween(beforeTime, afterTime)) {
          if (lastOeeEvent) {
            const lastEventTime = moment(lastOeeEvent.dt);
            eventToAdd = {
              startTime: lastEventTime.toISOString(),
              change: timeInStateEvent.change,
              id: timeInStateEvent.id,
              type: 'slowCycle',
              typeId: 1,
              endTime: time.toISOString(),
            };

            // Add First Event Padding
            if (firstEvent) {
              // the first event
              firstEvent = false;
              const firstEventPadding = {
                startTime: beforeTime.toISOString(),
                change: timeInStateEvent.change,
                id: timeInStateEvent.id,
                type: 'schedule',
                typeId: 1,
                endTime: eventToAdd.startTime, // afterTime.toISOString(),
              };
              returnData.push(firstEventPadding);
            }

            // Combine this and last events if of the same type
            if (combineSlices && lastEvent !== undefined) {
              if (lastEvent.type === eventToAdd.type) {
                // the same
                eventToAdd.startTime = lastEvent ? lastEvent.startTime : eventToAdd.startTime;
                returnData.pop();
              }
            }

            returnData.push(eventToAdd);
          }

          lastOeeEvent = slowCycle;
          lastEvent = eventToAdd;
        }
      });

      // look to add last event padding
      if (lastEvent !== undefined && eventToAdd) {
        if (timeInStateEvent.endTime > eventToAdd.endTime) {
          const lastEventPadding = {
            startTime: eventToAdd.endTime,
            change: timeInStateEvent.change,
            id: timeInStateEvent.id,
            type: 'schedule',
            typeId: 1,
            endTime: timeInStateEvent.endTime,
          };
          returnData.push(lastEventPadding);
        }
      }
    } else {
      returnData.push(timeInStateEvent);
    }

    return returnData.length > 0 ? returnData : [timeInStateEvent];
  }

  static addProcessMinuteDataToTimeInStateEvent(timeInStateEvent, processMinuteData, combineSlices = false) {
    let eventToAdd;
    let firstEvent = true;
    const returnData: Array<any> = [];
    let lastEvent;
    let lastOeeEvent;
    const beforeTime = moment(timeInStateEvent.startTime);
    const afterTime = moment(timeInStateEvent.endTime);
    if (processMinuteData) {
      // for performance we will slice off events already processed this will only work if the events are in order
      _.forEach(processMinuteData, (slowCycle) => {
        const time = moment(slowCycle.dt);
        // look for events in oeeData that are in this range AND that is NOT a microstop
        if (time.isBetween(beforeTime, afterTime) && slowCycle.tcp > 0) {
          if (lastOeeEvent) {
            const lastEventTime = moment(lastOeeEvent.dt);
            eventToAdd = {
              startTime: lastEventTime.toISOString(),
              change: timeInStateEvent.change,
              id: timeInStateEvent.id,
              // type: slowCycle.tcp > 0 ? `slowCycle ${(slowCycle.tcp * 100).toFixed(0)}%` : 'microStop',
              type: `slowCycle ${(slowCycle.tcp * 100).toFixed(0)}%`,
              typeId: 1,
              endTime: lastEventTime.add(1, 'minutes').toISOString(),
            };

            // Add First Event Padding
            if (firstEvent) {
              // the first event
              firstEvent = false;
              const firstEventPadding = {
                startTime: beforeTime.toISOString(),
                change: timeInStateEvent.change,
                id: timeInStateEvent.id,
                type: 'schedule',
                typeId: 1,
                endTime: eventToAdd.startTime,
              };
              returnData.push(firstEventPadding);
            } else {
              // Add Padding of default event
              // eslint-disable-next-line
              if (lastEvent.endTime < eventToAdd.startTime) {
                // put the original event back in place
                const originalEventPadding = {
                  startTime: lastEvent.endTime,
                  change: timeInStateEvent.change,
                  id: timeInStateEvent.id,
                  type: 'schedule',
                  typeId: 1,
                  endTime: eventToAdd.startTime,
                };
                returnData.push(originalEventPadding);
              }
            }

            // Combine this and last events if of the same type
            if (combineSlices && lastEvent !== undefined) {
              if (lastEvent.type === eventToAdd.type) {
                // the same
                eventToAdd.startTime = lastEvent ? lastEvent.startTime : eventToAdd.startTime;
                returnData.pop();
              }
            }

            returnData.push(eventToAdd);
          }

          lastOeeEvent = slowCycle;
          lastEvent = eventToAdd;
        }
      });

      // look to add last event padding
      if (lastEvent !== undefined && eventToAdd) {
        if (timeInStateEvent.endTime > eventToAdd.endTime) {
          const lastEventPadding = {
            startTime: eventToAdd.endTime,
            change: timeInStateEvent.change,
            id: timeInStateEvent.id,
            type: 'schedule',
            typeId: 1,
            endTime: timeInStateEvent.endTime,
          };
          returnData.push(lastEventPadding);
        }
      }
    } // else {
    returnData.push(timeInStateEvent);
    // }

    return returnData.length > 0 ? returnData.sort(Utils.sortByStartTime) : [timeInStateEvent];
  }

  // function for dynamic sorting
  static compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) { //eslint-disable-line no-prototype-builtins
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  static sortObjectsArray(objectsArray, sortKey) {
    // Quick Sort:
    let retVal;
    if (objectsArray.length > 1) {
      const pivotIndex = Math.floor((objectsArray.length - 1) / 2); // middle index
      const pivotItem = objectsArray[pivotIndex]; // value in the middle index
      const less: Array<any> = [];


      const more: Array<any> = [];

      objectsArray.splice(pivotIndex, 1); // remove the item in the pivot position
      objectsArray.forEach((value, index, array) => {
        (value[sortKey] && value[sortKey].toUpperCase()) <= (pivotItem[sortKey] && pivotItem[sortKey].toUpperCase()) // compare the 'sortKey' proiperty
          ? less.push(value)
          : more.push(value);
      });

      retVal = this.sortObjectsArray(less, sortKey)
        .concat([pivotItem], this.sortObjectsArray(more, sortKey));
    } else {
      retVal = objectsArray;
    }
    return retVal;
  }

  static getColorFromString(colorName) {
    let hash = 0;
    for (let i = 0; i < colorName.length; i++) {
      hash = colorName.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += (`00${value.toString(16)}`).substr(-2);
    }
    return color;
  }

  getScheduleStart(scheduleItem) {
    return moment(
      scheduleItem.instanceInfo
        ? scheduleItem.instanceInfo.startTime
        : scheduleItem.startTime,
    ).utc();
  }

  getScheduleEnd(scheduleItem) {
    return moment(
      scheduleItem.instanceInfo
        ? scheduleItem.instanceInfo.endTime
        : scheduleItem.endTime,
    ).utc();
  }

  getInstanceStart(scheduleItem) {
    return moment(
      scheduleItem.instances
        ? scheduleItem.instances[0].startTime
        : scheduleItem.startTime,
    ).utc();
  }

  getInstanceEnd(scheduleItem) {
    return moment(
      scheduleItem.instances
        ? scheduleItem.instances[0].endTime
        : scheduleItem.endTime,
    ).utc();
  }

  explodeDowntimeToTimeLine(downtimes, addMs) {
    return downtimes.map((dt) => {
      let startDt;
      if (addMs) {
        startDt = moment.utc(dt.startDt).add(1, 'ms');
      } else {
        startDt = moment.utc(dt.startDt);
      }
      const minItem = {
        startTime: startDt,
        startDt: dt.startDt,
        endTime: moment.utc(dt.endDt),
        id: dt.id || uuid({ msecs: startDt.valueOf() }),
        type: dt.type || 'downtime',
        reasonId: dt.reasonId,
        status: dt.status,
        shiftId: dt.shiftId,
      };
      return minItem;
    });
  }

  explodeEventToTimeLine(scheduleItem) {
    // abusing object deconstructing in an anonymous function
    // to pick out only the typeId and id from the scheduleItem
    const minItem: any = (({ typeId, id }) => ({ typeId, id }))(scheduleItem);
    // in this case, the shift start is set to the time autostandby ends
    minItem.startTime = this.getScheduleStart(scheduleItem);
    minItem.endTime = this.getScheduleEnd(scheduleItem);
    minItem.type = 'schedule';
    let timeLine = [minItem];
    if (scheduleItem.instanceInfo && scheduleItem.instanceInfo.concurrentEvents) {
      timeLine = timeLine.concat(
        ...scheduleItem.instanceInfo.concurrentEvents.map(ce => this.explodeEventToTimeLine(ce)),
      );
    }
    // console.log('minimized %j => %j', scheduleItem, timeLine);
    return timeLine;
  }

  explodeShiftHistoryEventsToTimeLine(scheduleItems) {
    let timeLine: Array<any> = [];
    scheduleItems.forEach((scheduleItem) => {
      const minItem: any = (({ typeId, id }) => ({ typeId, id }))(scheduleItem);
      minItem.startTime = this.getInstanceStart(scheduleItem);
      minItem.endTime = this.getInstanceEnd(scheduleItem);
      minItem.type = 'schedule';

      timeLine.push(minItem);
      if (scheduleItem.instanceInfo && scheduleItem.instanceInfo.concurrentEvents) {
        timeLine = timeLine.concat(
          ...scheduleItem.instanceInfo.concurrentEvents.map(ce => this.explodeEventToTimeLine(ce)),
        );
      }
    });
    Logger.of('Utils.explodeShiftHistoryEventsToTimeLine').info('schedule item for timeline %j', scheduleItems);
    Logger.of('Utils.explodeShiftHistoryEventsToTimeLine').info('shift history timeline %j', timeLine);
    return timeLine;
  }

  /**
   * Take a url string and return it's individual path components
   *
   * @param urlString
   * @returns {{hostname: string, protocol: string, search: string, port: string, origin: string, host: string, params: *, hash: string, pathname: string}}
   */
  static parseUrl(urlString) {
    const a = document.createElement('a');
    a.setAttribute('href', urlString);
    const { host, hostname, pathname, port, protocol, search, hash } = a;
    const origin = `${protocol}//${hostname}${port.length ? `:${port}` : ''}`;
    const params = qstr.parse(search);
    return { origin, host, hostname, params, pathname, port, protocol, search, hash };
  }

  /**
   * Take a time line of events and create a collection of segments defining every state change
   *
   * @param timeLine - ordered collection of schedule and downtime events
   * @param now
   * @returns {*}
   */
  segmentTimeLine(timeLine, now) {
    Logger.of('Utils.segmentTimeLine').info('segmenting the timeline %j', timeLine);
    if (!timeLine || timeLine.length < 1) return timeLine;

    // flatten timeline
    const points: any = [].concat(
      ...timeLine.map((e) => {
        const copiedFields = {
          id: e.id,
          type: e.type,
          typeId: e.typeId,
          reasonId: e.reasonId,
          status: e.status,
          shiftId: e.shiftId,
          startDt: e.startDt || null,
        };
        return [
          Object.assign({
            startTime: moment.utc(e.startTime),
            change: 'start',
          }, copiedFields),
          Object.assign({
            startTime: moment.utc((e.endTime || now.clone())),
            change: 'end',
          }, copiedFields),
        ];
      }).concat([
        { startTime: now.clone(), change: 'start', type: 'now' },
        { startTime: (now.clone().add(1, 'ms')), change: 'end', type: 'now' },
      ]),
    ).sort(this.timeSorter);

    // segment
    // from event start to end subdivide flattened timeline up-to now
    // explode to a new timeline
    Logger.of('Utils.segmentTimeLine').info('Building segments...');
    const segments: Array<any> = [];
    const ongoingEventStack: Array<any> = [];
    while (points.length) {
      let thisPoint: any;
      try {
        thisPoint = points.shift();
        if (segments.length) {
          // previous segment's end time defaults to real end of event
          // setting previous end to match this start
          segments[segments.length - 1].endTime = thisPoint.startTime;
        }
        if (thisPoint.change === 'start') {
          segments.push(Object.assign({}, thisPoint));
          // console.log('Starting event: thisPoint<%s> = %j', thisPoint.id, thisPoint);
          ongoingEventStack.unshift(thisPoint); // push onto stack of events ongoing
        } else if (thisPoint.change === 'end') {
          if (ongoingEventStack.length) {
            // need to remove ending event from stack
            // ongoing events might end in different order than they are added
            const endingEventIdx = ongoingEventStack.findIndex(e => e.id === thisPoint.id);
            // let endingEvents;
            if (endingEventIdx >= 0) {
              // endingEvents = ongoingEventStack.splice(endingEventIdx, 1);
              // removed items returned, array modified in place
              // console.log('Ending event %j', endingEvents);
            } else throw new Error("Received unpaired end state (couldn't find start)");

            // continue any concurrent superseded events
            if (ongoingEventStack.length && points.length
              && points[0].startTime !== thisPoint.startTime) {
              const continuingEvent = Object.assign({}, ongoingEventStack[0]);
              continuingEvent.startTime = thisPoint.startTime;
              segments.push(continuingEvent);
              // console.log('Continuing event<%s> on new segment %j', continuingEvent.id, continuingEvent);
            }
            // else No break between this 'end' and next start, so just wait for next iteration
          } else throw new Error('Received unpaired end state (nothing started)');
        }
      } catch (e) {
        Logger.of('Utils.segmentTimeLine').error('Error "%s" on point %j', e.message, thisPoint);
      }
    }

    Logger.of('Utils.segmentTimeLine').info(segments);
    return segments;
  }

  static getItemById(items, id) {
    if (!items || !items.length || !id) return null;

    const item = items.find(item => item.id === id);
    if (item) return item;
    return null;
  }

  static splitCurrentTimeSeriesEvent(outageEvents, endTime) {
    const newEvents: Array<any> = [];
    // split time event into start till Now and not till end
    outageEvents.forEach(
      (item) => {
        const now = new Date();
        // ⌛
        if (item.typeId === 1 && moment(item.startTime).toDate() < now
          && moment(item.endTime).toDate() > now) {
          // Split this event
          /*            console.log('sorting this data', item); */
          newEvents.push(Object.assign({}, item, { endTime: endTime || now.toISOString() }));
          newEvents.push(Object.assign({}, item, { startTime: now.toISOString() }));
        } else {
          newEvents.push(item);
        }
      },
    );
    /*    console.log('splitCurrentTimeSeriesEvent new events', newEvents); */
    return newEvents;
  }

  /**
   * Sort objects by startTime 8am->9am
   *
   * @param {object} a - compare object A
   * @param {object} b - compare object B
   * @returns {number} - compare result
   */
  static sortByStartTime(a, b) {
    return moment(a.startTime).toDate().valueOf() - moment(b.startTime).toDate().valueOf();
  }

  /**
   * Sorting function for Array.sort(), which expects a positive or negative value
   *
   * @param a - left element
   * @param b - right element
   * @returns {number}
   */
  timeSorter(a, b) {
    // +1 if a after b, -1 if a before b, 0 if equal
    return +(a.startTime > b.startTime) || -(a.startTime < b.startTime);
  }

  static sortByName(a, b) {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  /**
   * Sort objects by title a->a
   *
   * @param {object} a - compare object A
   * @param {object} b - compare object B
   * @returns {number} - compare result
   */
  static sortByTitle(a, b) {
    const x = a.title.toLowerCase();
    const y = b.title.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  /**
   * Sort objects by title a->a
   *
   * @param {object} a - compare object A
   * @param {object} b - compare object B
   * @returns {number} - compare result
   */
  static sortByText(a, b) {
    const x = a.text.toLowerCase();
    const y = b.text.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  static isGuid(stringToTest) {
    if (stringToTest[0] === '{') {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }

  static OnlyLinesInShift(line, schedulerFormattedShifts) {
    if (!schedulerFormattedShifts || !line || !line.printerId) return 1;
    let returnValue = 0;
    _.forEach(schedulerFormattedShifts, (shift) => {
      if (moment(shift.start) < moment() && moment() < moment(shift.end) && !returnValue) {
        returnValue = (shift.lineIds && shift.lineIds.find(l => l === line.id)) ? 1 : 0;
      }
    });
    return returnValue;
  }

  //timezone, site, to local functions


  /**
   *  Returns a Date Object in the local tz site time (client local timezone, time value of site's time)
   *
   * @param {string|Date|Moment} time - the time (utc or client local ) to convert to a site local time
   * @param {string} timezone - the site tz
   * @returns {Date} - local tz site time Date
   * @example
   * // '2020-09-01T00:00:00.0000' client local (America/New_York in this example) would be fine too
   * // utc => 2020-08-31T21:00:00-07:00 => to client local => 2020-08-31T21:00:00-04:00
   * getLocalSiteDateObject('2020-09-01T04:00:00.000Z', 'America/Los_Angeles');
   */
  static getLocalSiteDateObject = (time, timezone) => {
    // parse the time, convert to site tz, then back to local tz and offset, but keeping the same time
    return moment(time).tz(timezone).local(true).toDate();
  };



  /**
   * Parse a date string, preventing and ignoring errors
   *
   * @param {string} endDate - date string
   * @param {any} format - possible input date format
   * @returns {*} valid date string or undefined
   */
  static parseDateStr(endDate, format = undefined) {
    try {
      if (endDate) {
        const dt = moment.utc(endDate, format).local();
        if (dt.isValid()) {
          return dt.format('MM/DD/YYYY');
        }
      }
    } catch (e) {
      // do nothing
      console.log(e);
    }
    return undefined;
  }

  // Some RRule related functions

  /**
   * Pull end-date info from an recurrenceRule
   *
   * @param {string} recurrenceRule - the rule string
   * @param {string} returnType - what we lookin for
   * @param {object} ends - obj to receive some flags
   * @returns {((string|number))} date string
   */
  static getENDDates(recurrenceRule, returnType, ends) {
    let returnString: any = 0;
    if (!recurrenceRule) return null;
    if (returnType === 'UNTIL=') returnString = null;
    const array = recurrenceRule.split(';');
    // eslint-disable-next-line
    array.filter(element => element.includes(returnType)).forEach((element) => {
      const [, value] = element.split('=');
      // eslint-disable-next-line
      switch (returnType) {
        case 'COUNT=':
          ends.never = false;
          ends.after = true;
          ends.on = false;
          returnString = parseInt(value, 10);
          // eslint-disable-next-line
          if (isNaN(returnString)) returnString = 0;
          break;
        case 'UNTIL=':
          ends.never = false;
          ends.after = false;
          ends.on = true;
          ends.endDate = value.indexOf('Invalid') === -1 ? moment.utc(value, rRuleUntilFormat) : '';
          returnString = value.indexOf('Invalid') === -1 ? this.parseDateStr(value): '';
          break;
      }
    });
    return returnString;
  }

  static makeDatesFREQForDisplay(recurrenceRule) {
    if (recurrenceRule === undefined || recurrenceRule === null) return 0;
    let returnString = '';
    if (recurrenceRule === '') returnString = 'ONE TIME';
    if (recurrenceRule.includes('FREQ=')) {
      [, returnString] = recurrenceRule.split(';').filter(r => r.includes('FREQ='))[0].split('=');
    }
    switch (returnString.toUpperCase()) {
      case 'DAILY': return '1';
      case 'WEEKLY': return '2';
      case 'MONTHLY': return '3';
      case 'YEARLY': return '4';
      default:
    }
    return '0';
  }

  static getFREQRepeat(repeatId, count, endDate, intl) {
    if (count && count > 0) {
      return `${count.toString()} ${intl.formatMessage({ id: 'scheduler_recurrenceEditorEndOccurrence' })}`;
    }
    if (endDate) {
      return `${intl.formatMessage({ id: 'detail_Ends' })} ${endDate}`;
    }
    return intl.formatMessage({ id: 'scheduler_recurrenceEditorEndNever' });
  }

  // turn rrule abbreviations into day of week numbers
  static dayMap = { 'SU': 0, 'MO': 1, 'TU': 2, 'WE': 3, 'TH': 4, 'FR': 5, 'SA': 6 };
  static makeDatesBYDAYForDisplay(recurrenceRule, intl) {
    let byDay = '';
    if (!recurrenceRule) return intl ? intl.formatMessage({ id: 'detail_OneTime' }) : 'ONE TIME';
    if (recurrenceRule.toLowerCase().includes('never')) return intl ? intl.formatMessage({ id: 'scheduler_recurrenceEditorFrequenciesNever' }) : 'NEVER';
    // extract the byDay
    if (recurrenceRule.includes('BYDAY=')) {
      [, byDay] = recurrenceRule.split(';').filter(r => r.includes('BYDAY='))[0].split('=');
    }


    if (recurrenceRule.includes('FREQ=')) {
      const [, recurFreq] = recurrenceRule.split(';')
        .filter(r => r.includes('FREQ='))[0].split('=');
      // all days (like DAILY)
      if (recurFreq === 'DAILY' && byDay.length < 1) byDay = 'SU,MO,TU,WE,TH,FR,SA';
    }


    if (!intl)
      return byDay;

    // create a locale aware moment
    //set the locale to match the user's
    localeMoment.locale(intl.locale)
    // split the rrule string, convert to locale of user, format to two day abbreviation, and back to ', ' separated string
    return byDay && byDay
      .split(',')
      .map(d => localeMoment.weekdaysShort(this.dayMap[d]))
      .join(', ');
  }
  static makeDayForDisplay(dayAbbreviation, intl) {
    // create a locale aware moment
    //set the locale to match the user's
    localeMoment.locale(intl.locale)
    return localeMoment.weekdaysShort(this.dayMap[dayAbbreviation]);
  }

  static makeDatesFREQForDisplayRow(recurrenceRule, intl) {
    let returnString = '';
    if (!recurrenceRule) return intl.formatMessage({ id: 'detail_OneTime' });
    if (recurrenceRule.toLowerCase()
      .includes('never')) return intl.formatMessage({ id: 'scheduler_recurrenceEditorEndNever' });
    if (recurrenceRule.includes('FREQ=')) {
      [, returnString] = recurrenceRule.split(';')
        .filter(r => r.includes('FREQ='))[0].split('=');
    }
    switch (returnString.toUpperCase()) {
      case 'DAILY':
        return intl.formatMessage({ id: 'scheduler_recurrenceEditorFrequenciesDaily' });
      case 'WEEKLY':
        return intl.formatMessage({ id: 'scheduler_recurrenceEditorFrequenciesWeekly' });
      case 'MONTHLY':
        return intl.formatMessage({ id: 'scheduler_recurrenceEditorFrequenciesMonthly' });
      case 'YEARLY':
        return intl.formatMessage({ id: 'scheduler_recurrenceEditorFrequenciesYearly' });
      default:
        return returnString;
    }
  }


  static getDAYS(byDay) {
    const array: Array<string> = [];
    'SU,MO,TU,WE,TH,FR,SA'.split(',').forEach((day) => { if (byDay[day] === true) array.push(day); });
    return array.join(',');
  }

  static getFREQ(repeatId) {
    switch (repeatId) {
      case '1': return 'DAILY';
      case '2': return 'WEEKLY';
      case '3': return 'MONTHLY';
      case '4': return 'YEARLY';
      default:
    }
    return 'ONE TIME';
  }

  /**
   * converts a client local time to site time to ISO string, like from a time picker
   *
   * @param {string|Date|Moment} time - the utc or client local time that represents a site time
   * @param {string} timezone - the site tz to convert
   * @returns {string} ISO utc time, corrected for site tz
   * @example
   * // '2020-09-01T00:00:00.0000' client local (America/New_York in this example)
   * // would be fine too => 2020-09-01T00:00:00-07:00 => 2020-09-01T07:00:00.000Z
   * Utils.getFinalTimeForServer(new Date('2020-09-01T04:00:00.000Z'), 'America/Los_Angeles');
   */

  static getFinalTimeForServer(time, timezone) {
    return moment(time).tz(timezone, true).utc().toISOString()
  }

  static getISOTimeForReport(time, timezone, intervalValue) {
    if (intervalValue === ENUMS.INTERVALS.HOUR) {
        return Utils.getFinalTimeForServer(time, timezone);
    }
    // for non hour we use date as it is without any UTC or timezone calculation
    // because we want only the date part as appeared in the submission form
    return moment(time).toISOString();
  }

  static getFinalTimeForServerChkUtc(time, timezone) {
    if (time.toString().endsWith('Z')) {
      return time;
    }
    return Utils.getFinalTimeForServer(time, timezone);
  }

  static getRecurrenceRule(FREQ, state) {
    const { count, byDay, endDate, ends } = state;
    if (FREQ !== '') FREQ = `FREQ=${FREQ};`;
    let COUNT = count;
    if (COUNT !== 0) {
      COUNT = `COUNT=${COUNT};`;
    } else {
      COUNT = '';
    }
    let BYDAY = this.getDAYS(byDay);
    if (BYDAY.length !== 0) {
      BYDAY = `BYDAY=${BYDAY};`;
    } else {
      BYDAY = '';
    }
    let UNTIL = this.getUNTIL(endDate);
    if (UNTIL != null && ends && ends.on === true) {
      UNTIL = `UNTIL=${UNTIL};`;
    } else {
      UNTIL = '';
    }
    let WKST = 'WKST=SU';
    if (COUNT === '' && UNTIL === '' && FREQ === '') WKST = '';
    return { FREQ, COUNT, BYDAY, UNTIL, WKST };
  }

  static getUNTIL(endDate) {
    // yyyy-MM-ddTHH:mm:sszzz 20180705T035959Z

    if (endDate != null || endDate !== undefined) {
      return moment.utc(endDate).format(rRuleUntilFormat);
    }
    return null;
  }

  static getEndDatePropFromObject(obj, name) {
    return obj && obj.recurrenceRule && Utils.getENDDates(obj.recurrenceRule, name, {});
  }

  static timeToFormat(time, zone) {
    return moment.utc(time)
      .tz(zone)
      .format('hh:mm A');
  }
}

export default Utils;
