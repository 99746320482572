import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';

import RoleListRow from '../RoleListRow/RoleListRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import RoleManagementBar from '../RoleManagementBar/RoleManagementBar';
import Can from '../../../components/Common/Can';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import { PAGE_SIZE } from '../../../constants/global';
import { Paging } from '../../../components/Paging/Paging';
import { useRoleListTableStyles } from './RoleListTable.css';

const set1Breaks = {
  name: { lg: 4, md: 4, sm: 6, xs: 12 },
  description: { lg: 4, md: 4, sm: 6, xs: 12 },
  actionButtons: { lg: 4, md: 4, sm: 12, xs: 12 }
};

interface IRoleListState {
  page: number;
  sortedRoles: Array<any>;
  pageCount: number;
  pagedRoles: Array<any>;
}

interface IRoleListTableProps {
  roles: Array<any>;
  siteId: string;
  setRoleRefreshIndex: Dispatch<SetStateAction<number>>;
}

const RoleListTable = ({
  roles,
  siteId,
  setRoleRefreshIndex }: IRoleListTableProps) => {
  const classes = useRoleListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';

  const [filter, setFilter] = useState<{ searchTerm: string }>({
    searchTerm: ''
  });

  const [filteredRoles, setFilteredRoles] = useState<Array<any>>([]);

  const gridBreak: any = set1Breaks;

  const [roleListState, setRoleListState] = useState<IRoleListState>({
    page: 1,
    sortedRoles: [],
    pageCount: 0,
    pagedRoles: []
  });

  const {
    page,
    sortedRoles,
    pageCount,
    pagedRoles
  } = roleListState;

  useEffect(() => {
    setRoleListState(s => {
      const sorted = roles.filter(r => r.siteId === siteId).sort((a, b) => a.title.localeCompare(b.title))
        .sort((a, b) => a.readonly && !b.readonly ? 1 : !a.readonly && b.readonly ? -1 : 0);
      return {
        ...s,
        sortedRoles: sorted,
      }
    })
  }, [roles, siteId]);

  useEffect(() => {
    const searchTerm = filter.searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredRoles = searchTerm
      ? sortedRoles.filter(role => role.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedRoles;

    setFilteredRoles(initialFilteredRoles);

  }, [filter, sortedRoles]);

  useEffect(() => {
    setRoleListState(s => {
      const count = Math.ceil(((filteredRoles && filteredRoles.length) || 0) / PAGE_SIZE);
      const paged = filteredRoles.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedRoles: paged
      }
    })
  }, [filteredRoles]);

  const onPageChange = useCallback(p => {
    setRoleListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredRoles.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setRoleListState(s => ({
      ...s,
      pagedRoles: paged
    }));
  }, [page, filteredRoles]);

  return (<>
    <Can do="edit" on="role">
      <RoleManagementBar
        siteId={siteId}
        filter={filter}
        setFilter={setFilter}
      />
    </Can>
    <StyledContentCard>
      {
        sortedRoles && sortedRoles.length > 0 ? <StyledGrid>
          {
            filter.searchTerm.length === 0 || pagedRoles.length > 0 ? <StyledGridHeadRow>
              <StyledGridHeadCell {...gridBreak.name}>
                <TableHeadText
                  textId="detail_Name"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.description}>
                <TableHeadText
                  textId="detail_Description"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.actionButtons}>
              </StyledGridHeadCell>
            </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
          }
          {
            pagedRoles.map((role) =>
              <RoleListRow
                key={role.id}
                role={role}
                siteId={siteId}
                gridBreak={gridBreak}
                setRoleRefreshIndex={setRoleRefreshIndex} />
            )
          }
        </StyledGrid>
          : <EmptyItem textId="detail_noRoles" />
      }
      {
        filteredRoles && filteredRoles.length > PAGE_SIZE ? <div className={classes.pageContainer}>
          <Paging
            page={page}
            onChange={onPageChange}
            count={pageCount}
            total={filteredRoles.length}/></div> : null
      }
    </StyledContentCard>
  </>
  );
}

export default RoleListTable;
