import Moment from 'moment/moment';

import Utils from '../../utilities/utils';
import initialState from "../initialState";
import {
  CREATE_LINE_SUCCESS,
  CREATE_QA_COUNT_SUCCESS,
  LOAD_LINES_OVERVIEW_SUCCESS,
  LOAD_LINES_SUCCESS,
  LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS,
  LOAD_LINE_OEE_DAY_SUCCESS,
  LOAD_LINE_OEE_HOUR_SUCCESS,
  LOAD_LINE_OEE_SHIFT_SUCCESS,
  LOAD_LINE_OEE_SUCCESS,
  LOAD_LINE_SHIFT_TARGETS_SUCCESS,
  LOAD_LINE_SLOW_CYCLES_SUCCESS,
  LOAD_LINE_THROUGHPUT_BY_DAY_SUCCESS,
  LOAD_LINE_TIME_IN_STATE_SUCCESS,
  LOAD_LINE_UTILIZATION_SUCCESS,
  RESET_SITE_DATA,
  SET_LINE,
  UPDATE_LINE_SUCCESS
} from '../../actions/actionTypes';
import LineHelper from '../../helpers/LineHelper/LineHelper';
import { getAvailability, getOee, getPerformance, getQuality } from '../../utilities/oeeCalculations';
import { Logger } from '../../utilities/Logger/Logger';

export function lineReducer(state = initialState.lines, action) {
  const DateTimeParts = {
    YEAR: 0,
    MONTH: 1,
    DAY: 2,
    HOUR: 3,
    MINUTE: 4,
    SECOND: 5,
  };


  const formatString = (value: string, ...rest) => {
    let a = value;
    rest.forEach((item, index) => {
      a = a.replace(`{${index}}`, item);
    });
    return a;
  }

  switch (action.type) {
    case RESET_SITE_DATA:
      return initialState.lines;

    case CREATE_LINE_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.line),
      ];

    case UPDATE_LINE_SUCCESS:
      return [
        ...state.filter(line => line.id !== action.line.id),
        Object.assign(state.find(l => l.id === action.line.id) || {}, action.line, { assignedProductId: action.line.assignedProductId || undefined }),
      ];

    case CREATE_QA_COUNT_SUCCESS:
      console.log('CREATE_QA_COUNT_SUCCESS', action);
      return state;

    case LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS:
      Logger.of('App.lineReducer').info('LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS', action, state);
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (action.payload.lineIds && action.payload.lineIds.includes(line.id)) {
            return Object.assign({}, line, {
              downtimeSummary: Object.assign({}, action.payload.response),
            });
          }
          return line;
        }),
      });

    case LOAD_LINES_SUCCESS:
      return Object.assign([], ...action.data.lines, {
        ...action.data.lines.map(line => (line.display && line.display.color ? line : Object.assign({}, line, { display: { color: Utils.getColorFromString(line.title.split('').reverse().join('')) } }))),
      }).sort(Utils.sortByTitle);

    case LOAD_LINE_OEE_DAY_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (line.id === action.oee.lineId) {
            // we are on the correct line
            // copy to not change the immutable object
            const events = Object.assign([], action.oee.data.lineOeeDay);
            // shape the data
            const formattedEvents = events.map(shift => ({
              count: shift.count,
              date: shift.date,
              timeStamp: Moment(shift.date).toDate(),
              oee: shift.oee,
            }));
            // Sort by timeStamp
            return Object.assign({}, line, {
              oeeDay: formattedEvents.sort((a, b) => a.timeStamp - b.timeStamp),
            });
          }
          return line;
        }),
      });

    case LOAD_LINE_OEE_HOUR_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (line.id === action.oee.lineId) {
            // we are on the correct line
            // copy to not change the immutable object
            const events = Object.assign([], action.oee.data.data);
            // shape the data
            const formattedEvents = events.map((data) => {
              const dateTimeParts = data.dateHour.split('-');
              return ({
                count: data.count || 0,
                dateHour: data.dateHour,
                // change date from "2018-02-21-15" to "2018-02-21T15:00:00"
                timeStamp: Moment(formatString('{0}-{1}-{2}T{3}:00:00Z', dateTimeParts[DateTimeParts.YEAR],
                  dateTimeParts[DateTimeParts.MONTH], dateTimeParts[DateTimeParts.DAY],
                  dateTimeParts[DateTimeParts.HOUR])).toDate(),
                oee: data.oee || 0,
                totalCounter: 0,
              });
            });

            // Sort by timeStamp
            const sortedFormattedEvents = formattedEvents.sort((a, b) => a.timeStamp - b.timeStamp);

            // Count hours and Total count
            let runningHoursCount = 0;
            let totalCount = 0;
            let shiftEffTotal = 0;
            let shiftOeeTotal = 0;
            sortedFormattedEvents.forEach((event) => {
              runningHoursCount = event.count > 0 ? runningHoursCount + 1 : 0;
              shiftEffTotal += event.oee;
              shiftOeeTotal += event.oee;
              totalCount += event.count;
            });

            return Object.assign({}, line, {
              oeeHour: sortedFormattedEvents,
              // Store the last Hour
              lastHour: sortedFormattedEvents.length ? sortedFormattedEvents.slice(-1)[0].count : 0,
              count: totalCount,

              // first test for count
              /* lastHour: sortedFormattedEvents.length>0? sortedFormattedEvents.slice(-1)[0].count.toLocaleString():0,
               count: totalCount.toLocaleString(), */
              statusText: runningHoursCount ? `Running ${runningHoursCount} hours` : 'Stopped',
              status: runningHoursCount ? 'good' : 'bad',
              lastUpdate: sortedFormattedEvents.length ? sortedFormattedEvents.slice(-1)[0].timeStamp : '',
              shiftEff: sortedFormattedEvents.length ? shiftEffTotal / sortedFormattedEvents.length : 0,
              shiftOee: sortedFormattedEvents.length ? shiftOeeTotal * 100 / sortedFormattedEvents.length : 0,
              shiftCount: totalCount,
            });
          }
          return line;
        }),
      });

    case LOAD_LINE_OEE_SHIFT_SUCCESS:
      // console.log('line oee shift data %s', JSON.stringify(action.oee.data));
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (line.id === action.oee.lineId) {
            // we are on the correct line
            // copy to not change the immutable object
            // events.map(e => e.slices.find(s => s.sliceKey === '148fce21-8331-4dab-9d1b-31db343681b3'))
            const [key] = action.oee.data.responseType.split('_').slice(-1);
            const events = Object.assign([], action.oee.data.data.map((e) => {
              const shiftSlices = e.slices && e.slices.filter(s => s.sliceKey.includes(key));
              if (shiftSlices && shiftSlices.length) {
                return ({
                  ...e,
                  slices: shiftSlices,
                  ...(e.slices || []).find(s => s.sliceKey === key),
                });
              }
              return null;
            }).filter(e => e)); // ?
            // shape the data
            // todo: look at Chris's response type to know where to put the data
            const formattedEvents = events.map((data) => {
              const dateTimeParts = data.dateHour ? data.dateHour.split('-') : data.date.split('-');
              return ({
                count: data.count || 0,
                plannedMinutes: data.plannedMinutes || 0,
                plannedStoppedMinutes: data.plannedStoppedMinutes || 0,
                unplannedStoppedMinutes: data.unplannedStoppedMinutes || 0,
                runningMinutes: data.plannedMinutes + data.unplannedStoppedMinutes,
                reportedMinutes: data.reportedMinutes || 0,
                currentTargetCount: data.currentTargetCount || 0,
                badPieceCount: data.badPieceCount || 0,
                dateHour: data.dateHour,
                // change date from "2018-02-21-15" to "2018-02-21T15:00:00"
                timeStamp: Moment(formatString('{0}-{1}-{2}T{3}:00:00Z', dateTimeParts[DateTimeParts.YEAR],
                  dateTimeParts[DateTimeParts.MONTH], dateTimeParts[DateTimeParts.DAY],
                  dateTimeParts[DateTimeParts.HOUR])).toDate(),
                // These are already calculated and don't need recalculated
                oee: data.oee, // oeeCalculations.getOee(data) || 0,
                performance: data.performance, // oeeCalculations.getPerformance(data) || 0,
                availability: data.availability, // oeeCalculations.getAvailability(data) || 0,
                quality: data.quality, // oeeCalculations.getQuality(data) || 1,
                totalCounter: 0,
              });
            });

            // Sort by timeStamp
            const sortedFormattedEvents = formattedEvents.sort((a, b) => a.timeStamp - b.timeStamp);
            const eventCount = sortedFormattedEvents.length;

            // Recalculating OEE for whole shift - todo: do this in the backend
            const total = sortedFormattedEvents.reduce(
              (sums, event) => {
                Object.keys(sums).forEach((key) => {
                  if (event.hasOwnProperty(key) // eslint-disable-line no-prototype-builtins
                    && key !== 'ingestVersion') sums[key] += event[key];
                });
                sums.runningHours += event.count > 0 ? 1 : 0;
                sums.ingestVersion = sums.ingestVersion || event.ingestVersion;
                return sums;
              },
              {
                count: 0,
                runningHours: 0,
                runningMinutes: 0,
                reportedMinutes: 0,
                plannedMinutes: 0,
                currentTargetCount: 0,
                badPieceCount: 0,
                plannedStoppedMinutes: 0,
                unplannedStoppedMinutes: 0,
              },
            );
            const shiftPerformance = getPerformance(total);
            const shiftAvailability = getAvailability(total);
            const shiftQuality = getQuality(total);
            const shiftOee = getOee(total);
            return Object.assign({}, line, {
              oeeHour: sortedFormattedEvents,
              currentHour: (eventCount > 1) ? sortedFormattedEvents.slice(-1)[0].count : total.count,
              lastHour: (eventCount > 1) ? sortedFormattedEvents.slice(-2)[0].count : 0,
              count: total.count,
              // first test for count
              /* lastHour: sortedFormattedEvents.length>0? sortedFormattedEvents.slice(-1)[0].count.toLocaleString():0,
               count: totalCount.toLocaleString(), */
              statusText: total.runningHours ? `Running ${total.runningHours} hours` : 'Stopped',
              status: total.runningHours ? 'good' : 'bad',
              lastUpdate: eventCount > 1 ? sortedFormattedEvents.slice(-1)[0].timeStamp : '',
              shiftPerformance: shiftPerformance * 100,
              shiftAvailability: shiftAvailability * 100,
              shiftQuality: shiftQuality * 100,
              shiftEff: shiftOee,
              shiftOee: shiftOee * 100,
              shiftCount: total.count,
            });
          }
          return line;
        }),
      });

    case LOAD_LINE_OEE_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (line.id === action.oee.lineId) {
            const newLine = Object.assign({}, line, {
              oee: action.oee.data,
            });
            newLine.status = LineHelper.getStatus(newLine).state;
            newLine.statusText = LineHelper.getStatus(newLine).description;
            return newLine;
          }

          return line;
        }),
      });

    case LOAD_LINES_OVERVIEW_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          // Logger.of('App.lineReducer').info('LOAD_LINES_OVERVIEW_SUCCESS', action);
          // add data for each line if it exists.
          const data = action.payload.response[line.id];
          if (data) {
            const thisLine = Object.assign({}, line);
            //Logger.of('App.lineReducer').info('LOAD_LINES_OVERVIEW_SUCCESS Got data for a line', data);

            // shape the hour data...
            const formattedHourEvents = data.oeeHours.map((item) => {
              const dateTimeParts = item.dateHour.split('-');
              return ({
                count: item.count || 0,
                plannedMinutes: item.plannedMinutes || 0,
                unplannedStoppedMinutes: item.unplannedStoppedMinutes || 0,
                runningMinutes: item.plannedMinutes + item.unplannedStoppedMinutes,
                reportedMinutes: item.reportedMinutes || 0,
                currentTargetCount: item.currentTargetCount || 0,
                badPieceCount: item.badPieceCount || 0,
                dateHour: item.dateHour,
                // change date from "2018-02-21-15" to "2018-02-21T15:00:00"
                timeStamp: Moment(formatString('{0}-{1}-{2}T{3}:00:00Z', dateTimeParts[DateTimeParts.YEAR],
                  dateTimeParts[DateTimeParts.MONTH], dateTimeParts[DateTimeParts.DAY],
                  dateTimeParts[DateTimeParts.HOUR])).toDate(),
                oee: item.oee || 0,
                performance: item.performance || 0,
                availability: item.availability || 0,
                quality: item.quality || 1,
                totalCounter: 0,
                ingestVersion: item.ingestVersion,
                scheduledRunMinutes: item.scheduledRunMinutes

              });
            });

            // Sort by timeStamp
            const sortedFormattedHourEvents = formattedHourEvents.sort((a, b) => a.timeStamp - b.timeStamp);
            const eventCount = sortedFormattedHourEvents.length;

            // get running minutes
            // todo: might not need total
            const total = sortedFormattedHourEvents.reduce(
              (sums, event) => {
                Object.keys(sums).forEach((key) => {
                  if (event.hasOwnProperty(key) // eslint-disable-line no-prototype-builtins
                    && key !== 'ingestVersion') sums[key] += event[key];
                });
                sums.runningHours += event.count > 0 ? 1 : 0;
                sums.ingestVersion = sums.ingestVersion || event.ingestVersion;
                return sums;
              },
              {
                count: 0,
                runningHours: 0,
                runningMinutes: 0,
                reportedMinutes: 0,
                plannedMinutes: 0,
                currentTargetCount: 0,
                badPieceCount: 0,
              },
            );

            // Shape the Line data

            // todo: possible grab the assign product
            thisLine.assignedProductRun = data.product && data.product.run;
            thisLine.assignedProductId = data.product && data.product.id;

            // todo: rethink the next line.
            thisLine.count = total.count;
            // thisLine.count = data.oee.count;

            thisLine.currentHour = (eventCount > 1) ? sortedFormattedHourEvents.slice(-1)[0].count : total.count;
            thisLine.lastHour = (eventCount > 1) ? sortedFormattedHourEvents.slice(-2)[0].count : 0;
            thisLine.lastUpdate = eventCount > 1 ? sortedFormattedHourEvents.slice(-1)[0].timeStamp : '';
            thisLine.oeeHour = sortedFormattedHourEvents;
            // thisLine.oeeHour = data.oeeHours.map(h => Object.assign({}, h, {timeStamp: `${h.dateHour}:00:00.000Z`}));

            thisLine.printerId = data.printerId;
            thisLine.shiftAvailability = data.oee.availability * 100;
            thisLine.shiftCount = data.oee.count;
            thisLine.shiftEff = data.oee.oee;
            thisLine.shiftOee = data.oee.oee * 100;
            thisLine.shiftPerformance = data.oee.performance * 100;
            thisLine.shiftQuality = data.oee.quality * 100;

            // still using the shiftTargets endpoint to load shiftTargets graph data on LineDetailPage
            // shiftOverview is called by both LineDetailPage and LinesPage
            if (!thisLine.shiftTargets) {
              thisLine.shiftTargets = {};
            }

            thisLine.shiftTargets.produced = data.production && data.production.actual;
            thisLine.shiftTargets.planned = data.production && data.production.planned;
            thisLine.shiftTargets.remaining = data.product && data.product.currentRemaining;
            thisLine.shiftTargets.currentDt = data.product && data.product.currentDt;
            thisLine.shiftTargets.rates = {
              assignedProductRun: data.product && data.product.run,
              assignedProductId: data.product && data.product.id,
              instant: (data.production && data.production.currentRates && data.production.currentRates.instant),
              bestRate: (data.production && data.production.currentRates && data.production.currentRates.bestRate),
              runRate: (data.production && data.production.currentRates && data.production.currentRates.runRate),
            };

            thisLine.targetCount = data.production && data.production.planned;

            return thisLine;
          }

          return line;
        }),
      });

    case LOAD_LINE_SHIFT_TARGETS_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          let endOfShiftRemaining = 0;
          let completionTime = null;
          let endOfTargetTime: any = null;
          if (line.id === action.lineShiftTargets.lineId && action.lineShiftTargets.data.target) {
            const actualEvents = action.lineShiftTargets.data.actual.map(data => (Object.assign({}, data, {
              dt: Moment(data.dt).toDate(),
              remaining: data.remaining,
              timeMarker: data.timeMarker ? data.timeMarker : {},
            })));

            const targetEvents = action.lineShiftTargets.data.target.map(data => (Object.assign({}, data, {
              dt: Moment(data.dt).toDate(),
              remaining: data.remaining,
            })));

            const runRateEvents = action.lineShiftTargets.data.runRate.map((data) => {
              if (data.labels && data.labels.includes('End of Shift')) {
                /*          console.log('Saw end of shift',data); */
                endOfShiftRemaining = data.remaining;
              }
              if (data.labels && data.labels.includes('End of Target')) {
                /*       console.log('Saw end of target',data); */
                endOfTargetTime = Moment(data.dt).toDate();
              }
              completionTime = data.dt;

              return (Object.assign({}, data, {
                dt: Moment(data.dt).toDate(),
                remaining: data.remaining,
                now: data.now ? data.now : {},
              }));
            });
            const bestRateEvents = action.lineShiftTargets.data.bestRate.map(data => (Object.assign({}, data, {
              dt: Moment(data.dt).toDate(),
              remaining: data.remaining,
            })));

            let estimatedRemainingTarget = 0;
            if (action.lineShiftTargets.data.targetProduction && action.lineShiftTargets.data.targetProduction > 0) {
              estimatedRemainingTarget = endOfShiftRemaining - targetEvents[targetEvents.length - 1].remaining;
            }

            const rates = (action.lineShiftTargets.data.shiftDetails && action.lineShiftTargets.data.shiftDetails.rates)
              || action.lineShiftTargets.data.rates;

            return Object.assign({}, line, {
              shiftTargets: Object.assign({}, action.lineShiftTargets.data, {
                actual: actualEvents,
                bestRate: bestRateEvents,
                completionTime,
                endOfTargetTime,
                estimatedRemainingTarget,
                rates,
                runRate: runRateEvents,
                target: targetEvents,
                targetCount: action.lineShiftTargets.data.targetCount,
              }),
            });
          }
          return line;
        }),
      });

    case LOAD_LINE_SLOW_CYCLES_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (line.id === action.payload.lineId && action.payload.data) {
            const newLine = Object.assign({}, line, {
              slowCycles: Object.assign({}, action.payload.data),
            });
            /*            newLine.status = LineHelper.getStatus(newLine).state;
            newLine.statusText = LineHelper.getStatus(newLine).description; */
            return newLine;
          }
          return line;
        }),
      });

    case LOAD_LINE_THROUGHPUT_BY_DAY_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (line.id === action.data.lineId) {
            return Object.assign({}, line, {
              throughputByDay: action.data,
            });
          }
          return line;
        }),
      });

    case LOAD_LINE_TIME_IN_STATE_SUCCESS:
      return Object.assign([], ...state, {
        ...state.map((line) => {
          if (action.payload.response
            && ((action.payload.multiline && action.payload.response[line.id])
              || line.id === action.payload.lineId)) {
            const timeInState = (action.payload.multiline) ? action.payload.response[line.id] : action.payload.response;
            const { timeLine, slowCycles } = timeInState;

            // reverse sort and put nulls at the end

            const ts = timeLine
              .slice(0)
              .sort((a, b) => (a.endTime === null ? 0 : 1) - (b.endTime === null ? 0 : 1)
                || -(a.endTime > b.endTime) || +(a.endTime < b.endTime));
            const nowIndex = ts.findIndex(tls => tls.type === 'now');
            const nowSegmentStartTime = nowIndex >= 0 ? ts[nowIndex].startTime : null;
            const timeLineSegmentNow = nowIndex <= (ts.length - 2) ? ts[nowIndex + 1] : undefined;

            const r = Object.assign({}, line, {
              timeInState: {
                timeLine,
                nowSegmentStartTime,
                currentSegment: timeLineSegmentNow,
                slowCycles,
              },
            });
            r.status = LineHelper.getStatus(r);
            /* console.log('LOAD_LINE_TIME_IN_STATE_SUCCESS final state', r); */
            return r;
          }

          return line;
        }),
      });

    case LOAD_LINE_UTILIZATION_SUCCESS:
      return Object.assign([], ...state, {

        ...state.map((line) => {
          if (line.id === action.lineUtilization.lineId) {
            return Object.assign({}, line, {
              efficiency: action.lineUtilization.data,
            });
          }
          return line;
        }),
      });

    case SET_LINE:
      return Object.assign([], ...state, {
        ...state.map(line => (line.id === action.id ? Object.assign({}, line, {
          selected: true,
        }) : Object.assign({}, line, {
          selected: false,
        }))),
      });

    default:
      return state;
  }
}
