import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSiteManagementBarStyles } from './SiteManagementBar.css';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';
import { Card } from '@material-ui/core';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { FilterInterface } from '../interfaces/FilterInterface';

interface SiteManagementBarProps {
  filter: FilterInterface;
  setFilter: (string, any) => void;
}

const SiteManagementBar = ({
  filter,
  setFilter
}: SiteManagementBarProps) => {
  const intl = useIntl();
  const { isAdmin } = useAppGlobalState();
  const classes = useSiteManagementBarStyles();

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('siteFilter', s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);

  const [showDesignUsers, setShowDesignUsers] = useState<boolean>(filter.isShowingDesignUsers);
  const debouncedDesignUsers = useDebounce(showDesignUsers, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('siteFilter', s => ({ ...s, isShowingDesignUsers: debouncedDesignUsers as boolean }));
  }, [debouncedDesignUsers, setFilter]);

  return (<Card className={classes.root}>
    <div className={classes.barContainer}>
      {
        isAdmin && <div className={classes.barItem}>
          <div className={classes.barItemTitle}>
            {intl.formatMessage({ id: 'detail_ShowDesign' })}
          </div>
          <div className={classes.barItemControl}>
            <StyledSwitch checked={showDesignUsers}
              onChange={(e, checked) => setShowDesignUsers(checked)}
              name="IsShowingDesignUsers" />
          </div>
        </div>
      }
      <div className={classes.barItem}>
        <SearchInput
          searchTerm={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onSubmitSearch={(term) => {
            setSearchTerm(term);
          }}
        />
      </div>
    </div>
  </Card>
  )
};

export default SiteManagementBar;
