import { FormControl } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGlobalStyles } from "../../constants/globalstyles";
import { useDowntimeThresholdStyle } from "./DowntimeThreshold.css";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { downtimeThresholdLimits } from 'vccm-common/src/validation';
import { InfoTooltip } from "../Basic/InfoTooltip/InfoTooltip";

interface DowntimeThresholdProps {
  onChange: (event) => void;
  isDisabled?: boolean;
  value: number;
  errors: any;
}

function DowntimeThreshold({ onChange,
  value,
  isDisabled,
  errors }: DowntimeThresholdProps) {
  const classes = useDowntimeThresholdStyle();
  const intl = useIntl();
  const globalClasses = useGlobalStyles();
  return <div className={classes.inputBox}>
    <div>
      <FormattedMessage id='detail_DowntimeThreshold' />
      <InfoTooltip textId='detail_DowntimeThresholdTip' />
    </div>

    <FormControl variant="outlined" className={globalClasses.formControl}>
      <div>
        <div className={classes.textLabel}>{intl.formatMessage({ id: 'detail_Minutes' })}</div>
        <NumericTextBox
          name="downtimeThreshold"
          onChange={onChange}
          value={value}
          defaultValue={value || downtimeThresholdLimits.default}
          format="n0"
          disabled={isDisabled}
          min={downtimeThresholdLimits.min}
          max={downtimeThresholdLimits.max}
          validationMessage={errors.downtimeThreshold && intl.formatMessage({ id: 'validation.site.downtimeThreshold' })}
          valid={errors.downtimeThreshold == null}
        />
      </div>
    </FormControl>
  </div>
}

DowntimeThreshold.displayName = 'DowntimeThreshold';

export { DowntimeThreshold };
