import React, { useRef, useState } from "react";
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import classNames from 'classnames';
import {
  Inbox as InboxIcon,
  ExpandLess as ExpandLessIcon,
  NavigateNext as NavigateNextIcon
} from "@material-ui/icons";
import classnames from "classnames";

// styles
import useStyles from "./SidebarLink.css";

// components
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

export interface ISidebarLinkProps {
  link?: string;
  icon?: any;
  label?: string;
  languagePreLabel?: string;
  languageKey?: string;
  children?: any;
  disabled?: boolean;
  internalCall?: () => void;
  isSidebarOpened: boolean;
  nested?: any;
  type?: string;
  setActiveLink: any;
  status?: number;
  hidden?: boolean;
  capitalise?: boolean;
  single?: boolean;
  module?: string;
  site: any;
  activeLinkIndicators?: Array<string>;
  nonActiveLinkIndicators?: Array<string>;
  hideIfModuleNotEnabled?: boolean;
}

export default function SidebarLink({
  link,
  icon,
  label,
  languagePreLabel,
  languageKey,
  hidden,
  children,
  disabled,
  isSidebarOpened,
  nested,
  type,
  setActiveLink,
  capitalise,
  internalCall,
  single,
  module,
  site,
  activeLinkIndicators,
  nonActiveLinkIndicators,
  hideIfModuleNotEnabled
}: ISidebarLinkProps) {

  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();

  const hasModuleMissing = (module) => {
    return site && (!site.modules || (site.modules && site.modules.values && Array.from(site.modules.values).indexOf(module) === -1));
  }

  const isAnyChildrenActive = () => {
    if (children) {

      return !!children.find(el => {
        const nonActive = !el.nonActiveLinkIndicators || !!el.nonActiveLinkIndicators.find(elm => location.pathname.indexOf(elm) === -1);
        return nonActive && !!el.activeLinkIndicators && !!el.activeLinkIndicators.find(elm => location.pathname.indexOf(elm) > -1)
      });
    }

    return false;
  }

  // local
  const [isOpen, setIsOpen] = useState(isAnyChildrenActive);
  const LinkElementRef = useRef(null);
  const nonActive = !nonActiveLinkIndicators || !!nonActiveLinkIndicators.find(el => location.pathname.indexOf(el) === -1);
  const isLinkActive = nonActive && !!activeLinkIndicators && !!activeLinkIndicators.find(el => location.pathname.indexOf(el) > -1)
  const titleText = type === "titleText";

  const goToAbsoluteLink = (absLink) => {
    // ReactGA.event({
    //   category: 'WFX',
    //   action: 'Workflow Portal Launched',
    // });
    window.open(absLink, '_blank');
  }

  const CustomLink: any = React.useMemo( // eslint-disable-line react/display-name
    () => {
      const isAbsolute = link && (link.indexOf('http://') > -1 || link.indexOf('https://') > -1);
      if (link && !isAbsolute) {
        return React.forwardRef((linkProps, ref) => ( // eslint-disable-line react/display-name
          <Link ref={LinkElementRef} to="#" {...linkProps} onClick={() => setActiveLink(link)} />
        ))
      }

      return 'li';
    }, [link, setActiveLink],
  );

  const linkLabel = languageKey ? `${languagePreLabel ? languagePreLabel + ': ' : ''}${intl.formatMessage({ id: languageKey }) || label}` : (label || '');

  if (module && hasModuleMissing(module) && hideIfModuleNotEnabled) {
    return null;
  }

  if (!hidden && type === "title" && !children) {
    return (
      <div
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
          [classes.linkTextDisabled]: module && hasModuleMissing(module),
        })}
      >
        {capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}
      </div>
    );
  }

  if (hidden || (!titleText && module && hasModuleMissing(module))) {
    return null;
  }

  if (type === "divider") {
    return <Divider className={single ? classes.dividerSingle : classes.divider} />;
  }

  const checkInternalCall = () => {
    const isAbsolute = link && (link.indexOf('http://') > -1 || link.indexOf('https://') > -1);
    if (isAbsolute) {
      return () => goToAbsoluteLink(link)
    }

    if (internalCall) {
      return internalCall;
    }

    return null;
  }


  if (!children)
    return (
      <ListItem
        button
        disabled={disabled || !!(module && hasModuleMissing(module))}
        className={classes.link}
        component={CustomLink}
        onClick={checkInternalCall()}
        classes={{
          root: classnames({
            [classes.linkActive]: isLinkActive,
            [classes.linkNested]: nested,
            [classes.clickableTitle]: titleText
          }),
        }}
        disableRipple
      >
        {
          !titleText && <Tooltip title={isSidebarOpened ? '' : linkLabel} placement="right">
            <IconButton>
              <ListItemIcon
                className={classnames(classes.linkIcon, {
                  [classes.linkIconActive]: isLinkActive,
                })}
              >
                {icon}
              </ListItemIcon>
            </IconButton>
          </Tooltip>
        }

        <ListItemText
          classes={{
            primary: classnames(classes.linkText, titleText ? classes.linkSectionTitleText : classes.largeText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened
            }),
          }}
          primary={capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        disabled={(type !== "title" && !!children.find(el => el.disabled)) || !!(module && hasModuleMissing(module))}
        onClick={toggleCollapse}
        className={classnames(classes.link, {
          [classes.collapsableSectionTitle]: type === "title",
        })}
        disableRipple
      >

        {type !== "title" ? <Tooltip title={isSidebarOpened ? '' : linkLabel} placement="right"><IconButton>
          <ListItemIcon
            className={classes.linkIcon}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
        </IconButton></Tooltip> : null
        }

        <ListItemText
          classes={{
            primary: classnames(classes.linkText,
              titleText ? classes.linkSectionTitleText : classes.largeText, {
              [classes.collapsableSectionTitleText]: type === "title",
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened
            }),
          }}
          primary={capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}
        />
        <div className={classNames(classes.collapseIcon, { [classes.collapseInSmall]: !isSidebarOpened })}>
          {isOpen ? <ExpandLessIcon /> : <NavigateNextIcon />}
        </div>
      </ListItem>
      {children && (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          className={classNames({
            [classes.nestedList]: isSidebarOpened,
            [classes.nestedListSmall]: !isSidebarOpened
          })}
        >
          <List component="div" disablePadding>
            {children.map(childrenLink => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                disabled={childrenLink.disabled}
                isSidebarOpened={isSidebarOpened}
                setActiveLink={setActiveLink}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  function toggleCollapse(e) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }
}
