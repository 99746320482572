import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Can from '../Common/Can';
import * as config from '../../config';

// styles
import useStyles from './Sidebar.css';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext/LayoutContext';
import { useAppGlobalState } from '../../context/AppContext/AppContext';
import UserHelper from '../../helpers/UserHelper';
import UsersApi from '../../api/prodUserApi';
import { Logger } from '../../utilities/Logger/Logger';
import { getStructure } from '../../structure';

function Sidebar() {
  const classes = useStyles();
  const theme: any = useTheme();
  const history = useHistory();

  const { sites, selectedSiteId, isAdmin } = useAppGlobalState();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const gotoLink = (link) => {
    history.push(link);
  }

  const onClickLink = (link) => {
    if (isSmallScreen()) {
      toggleSidebar(layoutDispatch);
      setTimeout(() => {
        gotoLink(link);
      });
    } else {
      gotoLink(link);
    }
  }

  const site = sites ? sites.find(el => el.id === selectedSiteId) : null;

  const showVrsAccess = async () => {
    const canAccessVrs = !!UserHelper.Can('vrs', 'authModule');
    // ReactGA.event({
    //   category: 'VRS',
    //   action: 'VRS Launched',
    // });
    const endpoint = config.default.apiGateway.VRS_URL;
    if (canAccessVrs) {
      const { token } = await UsersApi.getVrsLoginToken(selectedSiteId);
      window.open(`${endpoint}${endpoint && endpoint[endpoint.length - 1] === '/' ? '' : '/'}MigratedUserLogin?Token=${token}`, '_blank');
    } else {
      window.open(endpoint, '_blank');
    }
  }

  const showCirrus = async () => {
    const canAccessCirrus = UserHelper.Can('edit', 'design');
    // ReactGA.event({
    //   category: 'Design',
    //   action: 'Design Launched',
    // })
    if (canAccessCirrus || isAdmin) {
      const token = await UsersApi.getCirrusToken(selectedSiteId)
      const cirrusUrl = config.default.apiGateway.CIRRUS_URL
      Logger.of('SideBar.render').info(`Launching Cirrus url:${cirrusUrl}#/templatemanager?debug=true&tkn=${token}`);
      const chrome = navigator.userAgent.indexOf('Chrome') > -1
      if (chrome) {
        window.open(`${cirrusUrl}#/templatemanager?debug=true&tkn=${token}`, '_blank')
      } else {
        window.location.replace(`${cirrusUrl}#/templatemanager?debug=true&tkn=${token}`)
      }
    }
  };

  const showExchange = async () => {
    // ReactGA.event({
    //   category: 'VRS',
    //   action: 'VRS Launched',
    // });
    const endpoint = config.default.apiGateway.API_APP_URL;
    window.open(endpoint, '_blank');
  }

  const internalCallArray = {
    menuAccessVrs: showVrsAccess,
    menuShowCirrus: showCirrus,
    menuExchange: showExchange
  };

  return (
    <>
      <Drawer
        variant={isPermanent ? 'permanent' : 'temporary'}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isSidebarOpened,
            [classes.drawerClose]: !isSidebarOpened,

          }, classes.listContainer),
        }}
        open={isSidebarOpened}
      >
        <div className={classes.toolbar} />
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <ArrowBackIcon className={classes.mobileBackButtonIcon} />
          </IconButton>
        </div>
        <List>
          {getStructure(site, internalCallArray, isAdmin, selectedSiteId).map(link => {
            const ActionLink = <SidebarLink
              key={link.id}
              isSidebarOpened={isSidebarOpened}
              setActiveLink={onClickLink}
              disabled={link.disabled}
              site={site}
              {...link}
            />;

            if (link.can) {
              return <Can do={link.can.do} on={link.can.on} key={link.id}>
                {can => can ? ActionLink : null}
              </Can>
            }

            if (link.requireAdmin) {
              return isAdmin ? ActionLink : null;
            }
            return ActionLink;
          })}
        </List>
      </Drawer>
    </>
  );

  function isSmallScreen() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    return windowWidth < breakpointWidth;
  }

  function handleWindowWidthChange() {
    const isScreenWidthSmall = isSmallScreen();
    if (isScreenWidthSmall && isPermanent) {
      setPermanent(false);
    } else if (!isScreenWidthSmall && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
