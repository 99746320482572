// Greys
export const myBlack = '#000';
export const myBlackAlpha0 = 'rgba(0, 0, 0, 0)';
export const myBlackAlpha2 = 'rgba(0, 0, 0, .2)';
export const myBlackAlpha12 = 'rgba(0, 0, 0, .12)';
export const myBlackAlpha14 = 'rgba(0, 0, 0, .14)';
export const myBlackAlpha87 = 'rgba(0, 0, 0, .87)';

export const myVeryDarkGrey = '#2a2a2a';
export const myDarkGrey = '#666';
export const myGrey = '#b9b9b9';
export const myGrayShade1 = '#959595';
export const myGrayShade2 = '#292b2c';
export const myGrayShade2Alpha10 = 'rgba(42, 42, 42, .1)';
export const myTurquoiseShade4 = '#4cced7';
export const myGrayShade3 = '#636c72';
export const myGrayShade4 = '#8c8c8c';
export const myLightGrey = '#f2f2f2';
export const myWhite = '#fff';
export const myWhiteShade0 = '#f8f8f8';
export const myWhiteShade1 = '#e6e6e6';
export const myWhiteShade2 = '#c0c0c0';
export const myWhiteShade3 = '#eeeeee';
export const myWhiteShade4 = '#d7d7d7';

export const myGreyBorder = '#e6e7f1';

export const myPrimaryColor = '#577790';
export const myCorporatePrimaryColor = '#00a4de';
export const mySecondaryColor = '#333333';
export const widgetColor = '#577790';

export const myPrimarySelectedColor = '#1f748e';
export const myPrimaryColorLighter = '#8fa9bc'
export const myPrimaryCorporateColorLighter = '#56b7d5';

export const mySecondarySelectedColor = '#404040';
export const mySecondaryColorDimmed = 'rgba(46, 46, 46, .60)';
export const mySecondaryColorLighter = '#424242'
export const myWhiteColorDimmed = 'rgba(255, 255, 255, .70)';
export const myWhiteColorDimmedFurther = 'rgba(255, 255, 255, .40)';

export const myGreyDimmed = 'rgba(185, 185, 185, .40)';
export const myGreyDimmed2 = 'rgba(245, 255, 255, .99)';
export const myDisabledColor = 'rgba(245, 255, 255, .65)';

export const myDarkBackground = '#1c2022';
export const myVeryDarkTurquoise = '#0e686d';
export const mydarkTurquoise = '#13939b';
export const myTurquoise = '#19bec8';
export const myTurquoiseShade1 = '#00bec9';
export const myTurquoiseShade1Alpha50 = 'rgba(0, 190, 201, .5)';
export const myTurquoiseShade2 = '#008e96';
export const myTurquoiseShade3 = '#00848c';
export const actionBackgroundColor = '#efeff6';

export const myCyan = '#5ce3eb';
export const myLightCyan = '#b7f2f6';
export const myVeryLightCyan = '#e4fafc';
export const myGhostWhite = '#f8f9ff';


// Extra colours
export const myGreyExtra = '#999';
export const myLiteGreyExtra = '#aaa';
export const myStgWarning = '#a90d61';

// Errors (not really)
export const myBlue = '#07abd6';
export const myGreen = '#7dd607';
export const myGreenShade1 = '#d0eecb';
export const myRed = '#f33434';
export const myRedShade1 = '#e45448';
export const myRedShade1Alpha50 = 'rgba(228, 84, 72, .5)';
export const myRedShade2 = '#d92e20';
export const myRedShade3 = '#d02c1f';
export const myYellow = '#ffc714';
export const myOrange = '#ffa514';
export const myLightPink = '#ffefee';
export const myGray = '#333334';
export const myDarkWhite = '#eaeaea';

export const standardBoxShadow = `0 .18rem .3rem rgba(166,166,185,.5), 0 .326rem 3rem rgba(122,123,151,.3)`;

export const requiredColor = '#ff0000';
export const imageBackgroundColor = '#e0e0e0';

export const disabledBackgroundColor = '#ebebe4';
export const disabledColor = '#c6c6c6';

export const DEFAULT_COLOR = '#632FA7';
export const DEFAULT_DOWNTIME_COLOR = '#632FA7';
export const DEFAULT_LINE_COLOR = '#632FA7';
export const DISPLAY_COLORS = {
  red: '#ff0000',
  orange: '#fe9a76',
  yellow: '#ffd700',
  olive: '#32cd32',
  green: '#21ba45',
  teal: '#008080',
  blue: '#0e6eb8',
  violet: '#ee82ee',
  purple: '#b413ec',
  pink: '#ff1493',
  brown: '#a52a2a',
  grey: '#a0a0a0',
  black: '#000000'
};

