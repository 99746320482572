import { makeStyles } from "@material-ui/core";

export const useSimpleLinearProgressStyles = makeStyles((theme) => ({
  root: {
    width: '320px',
    position: 'absolute',
    bottom: theme.spacing(0.625),
  },
  linearProgress:  {
    width: '100%',
    position: 'absolute',
    height: theme.spacing(2),
  }
}));
