import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLinePerformanceOverviewStyles } from './LinePerformanceOverview.css';
import classNames from "classnames";
import { SMALL_NUMBER_OF_ROWS } from '../../../../constants/global';

function getDisplayPercent(line, propName) {
  return line && line[propName]
    ? `${Math.round(line[propName])} %`
    : '—';
}

const LinePerformanceOverview = (props) => {
  const classes = useLinePerformanceOverviewStyles();
  const linesOrderedByOee = [...props.lines].sort((a, b) => {
    let aShiftOee; let bShiftOee;
    !isNaN(a.shiftOee) ? aShiftOee = a.shiftOee : aShiftOee = 0;
    !isNaN(b.shiftOee) ? bShiftOee = b.shiftOee : bShiftOee = 0;
    return aShiftOee - bShiftOee;
  });

  const rowClassName = linesOrderedByOee.length < SMALL_NUMBER_OF_ROWS ? classes.bigRow: classes.smallRow;

  return (
    <>
      <div className={classes.row}>
        <div><FormattedMessage id="detail_Line" /></div>
        <div><FormattedMessage id="detail_OEE" /></div>
        <div><FormattedMessage id="detail_Availability" /></div>
        <div><FormattedMessage id="detail_Performance" /></div>
        <div><FormattedMessage id="detail_Quality" /></div>
      </div>
      {
        linesOrderedByOee
          .map((line, index) => (
            <div className={classNames(classes.row, rowClassName)} key={line.id}>
              <div className={classes.head}>{line.title}</div>
              <div>
                {line.shiftOee ? Math.round(line.shiftOee) : 0}
                  &nbsp;%
                </div>
              <div>
                {getDisplayPercent(line, 'shiftAvailability')}
              </div>
              <div >
                {getDisplayPercent(line, 'shiftPerformance')}
              </div>
              <div>
                {getDisplayPercent(line, 'shiftQuality')}
              </div>
            </div>
          ))
      }

    </>
  );
};

LinePerformanceOverview.propTypes = {
  lines: PropTypes.array,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  interval: PropTypes.string,
  min: PropTypes.number,
};

LinePerformanceOverview.defaultProps = {
  lines: [],
};

export default LinePerformanceOverview;
