
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { wholeRow } from '../../../constants/global';
import StyledGridCell from '../StyledGridCell/StyledGridCell';
import { useEmptyItemStyles } from './EmptyItem.css';

const EmptyItem = ({ textId }: { textId: string }) => {
  const classes = useEmptyItemStyles();
  return <Grid container>
    <StyledGridCell {...wholeRow}>
      <div className={classes.noItemYet} style={{ textAlign: 'center' }}>
        <b><FormattedMessage id={textId} /></b>
      </div>
    </StyledGridCell>
  </Grid>
}

export { EmptyItem };
