
import NotifyApi from '../api/prodNotificationApi';
import {
  DELETE_SCHEDULE_SUCCESS,

} from './actionTypes';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import { useMemo } from 'react';


export function deleteSchedule(schedule) {
  return (dispatch) => {
    return NotifyApi.scheduleRemove(schedule).then(
      dispatch(deleteScheduleSuccess(schedule.id)),
    ).catch((error) => {
      throw error;
    });
  }
}

export function deleteScheduleSuccess(id) {
  return { type: DELETE_SCHEDULE_SUCCESS, id };
}

export const useNotificationActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    deleteSchedule: (id) => deleteSchedule(id)(dispatch)
  }), [dispatch]);
};
