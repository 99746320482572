import React, { useEffect, useState } from 'react';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

import { loadUsers } from '../../../services/UserService/UserService';
import { Route, Switch } from 'react-router';
import { NotFound } from '../../../components/NotFound/NotFound';
import { UserSetupPage } from '../UserSetupPage/UserSetupPage';
import UserManagementPage from '../UserManagementPage/UserManagementPage';
import RoleManagementPage from '../../role/RoleManagementPage/RoleManagementPage';
import { CheckAuthorisedRoute } from '../../../components/CheckAuthorisedRoute/CheckAuthorisedRoute';
import { getLinkAccessInfo } from '../../../RouteAccess';

const UserSetupMainPage = ({ match }: any) => {
  const { sites, isAdmin, appFilters } = useAppGlobalState();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<any>([]);
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

  const siteId = match.params.siteId;
  const setupParam = match.params.setupParam;
  const action = match.params.action || 'none';
  const setupMode = match.params.setupMode;
  const site = sites.find(el => el.id === siteId);
  const accessInfo = getLinkAccessInfo(site, isAdmin, siteId);
  const access = {
    accessInfo,
    params: {
      siteId,
      setupMode,
      action,
      setupParam
    }
  };


  useEffect(() => {
    (async () => {
      if (users.length && !refreshRequired) {
        // we already have the users
        return;
      }

      try {
        if (sites.length > 0) {
          const companies = [site && site.companyId ];
          setIsLoading(true);
          const fetchedUsers = await loadUsers(appFilters.userFilter.showAllUsers ? null : companies);
          setUsers(fetchedUsers);
          setIsLoading(false);
          setRefreshRequired(false);
        }
      } catch (e) {
        alert(e);
      }
    })();
  }, [sites, refreshRequired, appFilters.userFilter.showAllUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  return !isLoading ? <>
    <Switch>
      <CheckAuthorisedRoute {...access} path={`/site/${siteId}/setup/user/${setupParam}/${action}`}>
        <UserManagementPage users={users} match={match} setRefreshRequired={setRefreshRequired} />
      </CheckAuthorisedRoute>
      <CheckAuthorisedRoute {...access} path={`/site/${siteId}/setup/role/${setupParam}/${action}`}>
        <RoleManagementPage match={match} setRefreshRequired={setRefreshRequired} />
      </CheckAuthorisedRoute>
      <CheckAuthorisedRoute {...access} path={`/site/${siteId}/setup/users`}>
        <UserSetupPage users={users} match={match} refreshRequired={refreshRequired} setRefreshRequired={setRefreshRequired} />
      </CheckAuthorisedRoute>
      <CheckAuthorisedRoute {...access} path={`/site/${siteId}/setup/roles`}>
        <UserSetupPage users={users} match={match} refreshRequired={refreshRequired} setRefreshRequired={setRefreshRequired} />
      </CheckAuthorisedRoute>
      <Route component={NotFound} />
    </Switch>
  </> :
    <ProgressIndicator />
}

export { UserSetupMainPage }
