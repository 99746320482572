import { useMemo } from 'react';
import configApi from '../api/mockConfigApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import * as types from './actionTypes';

function loadConfigSuccess(config) {
  return { type: types.LOAD_CONFIG_SUCCESS, config };
}

export function loadConfig() {
  return (dispatch) => {
    return configApi.getConfig().then((config) => {
      dispatch(loadConfigSuccess(config));
    }).catch((error) => {
      throw (error);
    });
  }
}

function setHomeSelectionMode(payload) {
  return (dispatch) => {
    dispatch({ type: types.SET_HOME_SELECTION_MODE, payload });
  }
}

export function setDashboardTitle(title, dashboardMode = false) {
  return (dispatch) => {
    dispatch({ type: types.SET_TITLE, title });
    dispatch({ type: types.SET_DASHBOARD_MODE, dashboardMode });
  }
}

export function setDashboardMode(dashboardMode) {
  return (dispatch) => {
    dispatch({ type: types.SET_DASHBOARD_MODE, payload: dashboardMode });
  }
}

function setIsLoading(isLoading) {
  return (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: isLoading });
  }
}

export function getActionDescription(actionMode) {
  let title = '';

  switch (actionMode) {
    case types.ACTION_ADD:
      title = 'Create';
      break;
    case types.ACTION_EDIT:
      title = 'Edit';
      break;
    case types.ACTION_DELETE:
      title = 'Delete';
      break;
    case types.ACTION_CLONE:
      title = 'Clone';
      break;
    case types.ACTION_PREVIEW:
      title = 'Preview';
      break;
    default:
  }
  return title;
}

export function setBarFilter(name, setFilter) {
  return function (dispatch) {
    dispatch({ type: types.SET_FILTER, payload: { name, setFilter } })
  }
}

export function resetBarFilter() {
  return function (dispatch) {
    dispatch({ type: types.RESET_FILTER, payload: null })
  }
}

export const useConfigActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    loadConfig: () => loadConfig()(dispatch),
    setDashboardTitle: (title, dashboardMode) => setDashboardTitle(title, dashboardMode)(dispatch),
    setDashboardMode: (dashboardMode) => setDashboardMode(dashboardMode)(dispatch),
    setIsLoading: (isLoading) => setIsLoading(isLoading)(dispatch),
    setHomeSelectionMode: (selection) => setHomeSelectionMode(selection)(dispatch),
    setBarFilter: (name, filter) => setBarFilter(name, filter)(dispatch),
    resetBarFilter: () => resetBarFilter()(dispatch)
  }), [dispatch]);
};

