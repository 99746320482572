import tinycolor from 'tinycolor2';
import { myWhiteShade3, myWhite, myPrimarySelectedColor, myPrimaryColor, mySecondaryColor } from '../constants/colors';

const warning = '#FFC260';
const success = '#867a24';
const info = '#58bec7';

const lightenRate = 7.5;
const darkenRate = 15;

export default {
  palette: {
    primary: {
      main: myPrimaryColor,
      light: tinycolor(myPrimaryColor)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(myPrimaryColor)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: mySecondaryColor,
      light: tinycolor(mySecondaryColor)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(mySecondaryColor)
        .darken(darkenRate)
        .toHexString(),
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
    },
    background: {
      default: '#F6F7FF',
      light: '#F3F5FF',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  overrides: {
    MuiInputBase: {
      formControl: {
        width: '100%'
      }
    },
    MuiButton: {
      containedPrimary: {
        color: myWhite
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    },
    MuiToolbar: {
      root: {
        alignItems: 'unset',
        maxHeight: '84px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '10px',
        backgroundColor: mySecondaryColor,
        borderRadius: '8px'
      }
    },
    MuiTab: {
      wrapper: {
        display: 'inline'
      },
      labelIcon: {
        minHeight: '36px'
      }
    },
    MuiMenuItem: {
      root: {
        padding: '5px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '10px'
      }
    },
    MuiPaginationItem: {
      page: {
        '&$selected': {
          backgroundColor: myPrimaryColor,
          color: myWhite,
          '&:hover': {
            backgroundColor: myPrimarySelectedColor,
          }
        },
      },
    },
    MuiListItem: {
      root: {
        paddingTop: '2px',
        paddingBottom: '2px',
        '&$selected': {
          backgroundColor: `${myWhite}`,
          '&:hover': {
            backgroundColor: myWhiteShade3,
          }
        },
        '&:hover': {
          backgroundColor: myWhiteShade3,
        }
      },
      button: {
        '&:hover': {
          backgroundColor: myWhiteShade3,
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      },
    },
  },
};
