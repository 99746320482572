
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppGlobalState, useSelectedSite } from '../../../context/AppContext/AppContext';
import { useUserSetupPageStyles } from './UserSetupPage.css';

import { useIntl } from 'react-intl';
import {
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from "@material-ui/icons";
import PageTabs from '../../../components/PageTabs/PageTabs';
import UserListTable from '../UserListTable/UserListTable';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import RoleListTable from '../../role/RoleListTable/RoleListTable';
import { getRoles } from '../../../services/UserService/UserService';
import UserHelper from '../../../helpers/UserHelper';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useHistory } from 'react-router';
import StyledTab from '../../../components/Basic/StyledTab/StyledTab';
import StyledTabs from '../../../components/Basic/StyledTabs/StyledTabs';
import Can from '../../../components/Common/Can';

interface IUserSetupPageProps {
  match: any;
  users: Array<any>;
  refreshRequired: boolean;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const UserSetupPage = ({ users, match, refreshRequired, setRefreshRequired }: IUserSetupPageProps) => {
  const intl = useIntl();
  const history = useHistory();

  const classes = useUserSetupPageStyles();
  const setTitle = useSetTitleAction();

  const selectedSiteId = match.params.siteId;
  const { sites, isAdmin } = useAppGlobalState();
  const selectedSite = useSelectedSite();
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState<any>([]);
  const [userAllowedRoles, setUserAllowedRoles] = useState<any>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(history.location.pathname.indexOf('setup/roles') > -1 ? 1 : 0);
  const siteId: string = match.params.siteId;

  const [roleRefreshIndex, setRoleRefreshIndex] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        if (sites.length > 0) {
          const fetchedRoles = await getRoles(siteId);
          const updatedRoles = UserHelper.translateRoles(fetchedRoles.map(el => {
            return el.key ? { ...el, key: el.key.replace('.', '_') } : el
          }), intl);
          setRoles(updatedRoles);
          setIsLoading(false);
        }
      } catch (e) {
        alert(e);
      }
    })();
  }, [sites, roleRefreshIndex, refreshRequired]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeTabIndex === 0) {
      setTitle('detail_Users', <SupervisorAccountIcon />, {
        ignoreItself: true,
        parents: [{
          id: 'detail_Configuration',
        },
        {
          id: 'detail_Users',
        },
        {
          id: 'detail_ManageUsers',
        }]
      });
      history.replace(`/site/${siteId}/setup/users`);
    } else {
      setTitle('detail_Users', <SupervisorAccountIcon />, {
        ignoreItself: true,
        parents: [{
          id: 'detail_Configuration',
        },
        {
          id: 'detail_Users',
        },
        {
          id: 'detail_ManageRoles',
        }]
      });
      history.replace(`/site/${siteId}/setup/roles`);
    }
  }, [setTitle, activeTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (selectedSite) {
      const modules = selectedSite && selectedSite.modules && selectedSite.modules.values && selectedSite.modules.values;
      const visibleRoles = (isAdmin) ? [...roles, ...UserHelper.addApiOrVrsRolesForAdmin(roles, selectedSite)] : roles;
      setUserAllowedRoles(visibleRoles
        .filter(el => el.authModule !== 'vrs' || (modules && modules.indexOf('vrs') > -1))
        .filter(el => el.authModule !== 'api' || (modules && modules.indexOf('api') > -1)));
    }
  }, [roles, selectedSite, isAdmin]);

  return !isLoading ? <>
    <Can do="view" on="role" passThrough>
      {can => <PageTabs>
        <StyledTabs
          value={activeTabIndex}
          onChange={(e, index) => setActiveTabIndex(index)}
          indicatorColor="secondary"
          textColor="primary"
        >
          <StyledTab icon={<CalendarTodayIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_ManageUsers' })} />
          {
            can && <StyledTab icon={<AssignmentIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_ManageRoles' })} />
          }
        </StyledTabs>
      </PageTabs>
      }
    </Can>
    {
      activeTabIndex === 0 && <UserListTable
        users={users}
        roles={userAllowedRoles}
        siteId={selectedSiteId}
        setRefreshRequired={setRefreshRequired}
      />
    }
    {
      activeTabIndex === 1 && <Can do="view" on="role">
        <RoleListTable
          roles={roles}
          siteId={selectedSiteId}
          setRoleRefreshIndex={setRoleRefreshIndex}
        /></Can>
    }
  </> :
    <ProgressIndicator />
}

export { UserSetupPage }
