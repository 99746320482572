export function containsUppercase(text) {
  const containsLetterRegex = new RegExp('([A-Z])');
  return containsLetterRegex.test(text);
}

export function containsLowercase(text) {
  const containsLetterRegex = new RegExp('([a-z])');
  return containsLetterRegex.test(text);
}
export function containsNumber(text) {
  const containsNumberRegex = new RegExp('([0-9])');
  return containsNumberRegex.test(text);
}

export function containsSymbol(text) {
  const containsSymbolRegex = new RegExp('(?=.*[!@$%^&*()<>{}|+=])');
  return containsSymbolRegex.test(text);
}
export function isMinLength(text, minLength = 0) {
  const isMinLengthRegex = new RegExp(`(?=.{${minLength},})`);
  return isMinLengthRegex.test(text);
}
