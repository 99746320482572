import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment-timezone';
import {
  Dashboard as DashboardIcon,
  HomeWork as HomeWorkIcon
} from '@material-ui/icons';
import toastr from 'toastr';
import { validation } from 'vccm-common';
import Can from '../../../components/Common/Can';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useSiteFormStyles } from '../SiteForm/SiteForm.css';
import { extractErrors } from '../../../libs/getDynoErrors';
import { getActionDescription } from '../../../actions/configActions';

import { getCurrentUser } from '../../../libs/awsLib';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
} from '@material-ui/core';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useHistory } from 'react-router';
import { TextInput } from '../../../components/TextInput/TextInput';
import SiteApi from '../../../api/prodSiteApi';
import { FormHeadText } from '../../../components/FormHeadText/FormHeadText';
import { SlowCyclePercentage } from '../../../components/SlowCyclePercentage/SlowCyclePercentage';
import { DowntimeCheckbox } from '../../../components/DowntimeCheckbox/DowntimeCheckbox';
import { NotificationsDelay } from '../../../components/NotificationsDelay/NotificationsDelay';
import { DowntimeThreshold } from '../../../components/DowntimeThreshold/DowntimeThreshold';
import { useSiteActions } from '../../../actions/siteActions';
import { useUserActions } from '../../../actions/userActions';
import { API_MODULE_NAME } from '../../../constants/global';
import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog';
import UserHelper from '../../../helpers/UserHelper';
import { NotAuthorised } from '../../../components/NotAuthorised/NotAuthorised';
import { SearchableTimezoneSelector } from '../../../components/SearchableTimezoneSelector/SearchableTimezoneSelector';

const getEmptySilverSite = () => {
  return {
    notificationsEnabled: false,
    notificationsDelay: 10,
    printers: [],
    subscriptionStatus: 'silver'
  };
}

const getCurrentSite = (sites, siteId) => {
  const site = sites.find(el => el.id === siteId);
  return site ? { ...site } : getEmptySilverSite();
};
interface ISiteForm {
  match: any;
}

const tableHeadAlign = 'left';


const SiteForm = ({ match }: ISiteForm) => {
  const classes = useSiteFormStyles();
  const history = useHistory();
  const intl = useIntl();
  const { sites, isAdmin, selectedSiteId } = useAppGlobalState();
  const siteActions = useSiteActions();
  const userActions = useUserActions();
  const setTitle = useSetTitleAction();
  const siteId: string = match.params.siteId;
  const setupMode: string = match.params.setupMode || match.params.setupMode.toLowerCase();

  const [errors, setErrors] = useState<any>({});
  const [zoneNames, setZoneNames] = useState<Array<any>>([]);
  const [saving, setSaving] = useState(false);
  const [siteDataReady, setSiteDataReady] = useState(false);
  const [currentSite, setCurrentSite] = useState<any>(getCurrentSite(sites, siteId));
  const [orgCurrentSite, setOrgCurrentSite] = useState<any>(getCurrentSite(sites, siteId));
  const [dirty, setDirty] = useState(false);
  const [apiKeyGenerationDialog, setApiKeyGenerationDialog] = useState(false);
  const [currentSetupMode] = useState<string>(setupMode);

  useEffect(() => {
    if (sites && sites.length === 1 && sites[0].id === selectedSiteId && siteId !== '0') {
      siteActions.loadSite(siteId).then(site => {
        setCurrentSite(site);
        setOrgCurrentSite(site);
        setSiteDataReady(true);
      }).catch(err => {
        console.log(err);
        toastr.error(err.message);
      });
    } else {
      setCurrentSite(getCurrentSite(sites, siteId));
      setOrgCurrentSite(getCurrentSite(sites, siteId));
      setSiteDataReady(true);
    }
  }, [siteId, sites, selectedSiteId, siteActions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (siteDataReady) {
      setDirty(JSON.stringify(currentSite) !== JSON.stringify(orgCurrentSite));
    }
  }, [siteDataReady]); // eslint-disable-line react-hooks/exhaustive-deps


  // if state we care changes, reevaluate dirty
  useEffect(() => {
    if (siteDataReady && currentSite && orgCurrentSite) {
      setDirty(JSON.stringify(currentSite) !== JSON.stringify(orgCurrentSite));
    }
  }, [currentSite, orgCurrentSite, siteDataReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // we need reset current and original site when we switch to 'add' from 'edit'
    if (siteDataReady && currentSetupMode === 'edit' && setupMode === 'add') {
      setCurrentSite(getEmptySilverSite());
      setOrgCurrentSite(getEmptySilverSite());
    }
  }, [siteDataReady, setupMode, currentSetupMode]);

  useEffect(() => {
    SiteApi.getSiteTimeZones().then((timeZones) => {
      if (timeZones) {
        setZoneNames(timeZones.zoneNames);
      } else {
        console.log('siteApi other than error timeZones=>', timeZones);
      }
    })
      .catch((error) => { console.log('siteApi error', error); });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasCreatedAt = () => !!currentSite.createdAt;

  const updateData = (data) => {
    const { name, value } = data;
    setCurrentSite((state) => ({ ...state, [name]: value }));
  }

  useEffect(() => {
    if (setupMode === 'edit') {
      setTitle('detail_Sites', <DashboardIcon />,
        {
          ignoreItself: true,
          parents: [{
            id: 'detail_Configuration',
          }, {
            id: 'detail_Sites',
            link: '/sites',
            icon: <HomeWorkIcon />
          },
          {
            id: 'detail_Edit_a_Site'
          }]
        });
    } else {
      setTitle('detail_Create_a_Site', <DashboardIcon />,
        {
          parents: [{
            id: 'detail_Administrator',
          }]
        });
    }

  }, [setTitle, setupMode]);

  const closeForm = () => {
    history.push('/sites');
  }

  const cancelForm = () => {
    setCurrentSite({ ...orgCurrentSite });
  }

  const onCloseApiKeyConfirmDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);
        const updated = await siteActions.generateApiKey({ ...currentSite });
        console.log('updated', updated);
        if (updated) {
          setCurrentSite(updated);
          toastr.success(intl.formatMessage(({ id: 'detail_ApiKeyGenerated' })));
        }
      } catch (e) {
        toastr.error(e);
      } finally {
        setSaving(false);
      }
    }
    setApiKeyGenerationDialog(false);
  }

  const updateDataWithSelect = (name: string) => (value) => {
    updateData({ name, value });
  }

  const updateDataWithText = (name: string) => (event) => {
    updateData({ name, value: event.target.value });
  }

  const toggleNotifications = () => {
    setCurrentSite((state) => ({ ...state, notificationsEnabled: !state.notificationsEnabled }));
  };

  const kendoOnChange = (e) => {
    if (e.target.name.toUpperCase() === 'NOTIFICATIONDELAY') {
      setCurrentSite((state) => ({ ...state, notificationsDelay: e.target.value }));
    }
    if (e.target.name.toUpperCase() === 'DOWNTIMETHRESHOLD') {
      setCurrentSite((state) => ({ ...state, downtimeThreshold: e.target.value }));
    }
    if (e.target.name.toUpperCase() === 'SLOWCYCLETHRESHOLD') {
      // change to percentage
      const newValue = e.target.value <= 1 ? e.target.value : e.target.value / 100;
      setCurrentSite((state) => ({ ...state, slowCycleThreshold: newValue }));
    }
    if (e.target.name === 'tz') {
      setCurrentSite((state) => ({ ...state, tz: e.target.value }));
    }
  }

  const siteFormIsValid = () => {
    let formIsValid = true;
    let errors = {};
    const siteToValidate = ({ ...currentSite, printers: undefined });
    delete siteToValidate.bestRate;
    delete siteToValidate.runRate;
    delete siteToValidate.printers;
    delete siteToValidate.selected;
    delete siteToValidate.siteAdmins;
    delete siteToValidate.downtimeCategories;
    if (siteToValidate.address === '') {
      delete siteToValidate.address;
    }
    if (siteToValidate.description === '') {
      delete siteToValidate.description;
    }
    if (siteToValidate.subscriptionStatus === null) {
      delete siteToValidate.subscriptionStatus;
    }

    const results = validation.schemas.site.validate(siteToValidate, { abortEarly: false });


    if (!results.error) {
      setErrors({});
      return true;
    }

    errors = results.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, '_') : '';
      formIsValid = false;
      return agg;
    }, {});

    setErrors(errors);
    return formIsValid;
  }

  const redirect = () => {
    setSaving(false);
    history.push('/sites');
  }

  const saveSite = async (event) => {
    event.preventDefault();
    const locale = navigator.language.split(/[-_]/)[0];
    if (currentSite.modules && !Array.isArray(currentSite.modules) && currentSite.modules.values) {
      currentSite.modules = Array.from(currentSite.modules.values);
    }

    if (!siteFormIsValid()) {
      return;
    }
    setSaving(true);
    /* console.log('Creating site', this.state.site); */
    const editing = !!currentSite.createdAt;

    const siteCopy = {
      ...currentSite,
      modules: currentSite.modules && Array.isArray(currentSite.modules) ? {
        values: currentSite.modules
      } : currentSite.modules
    };

    console.log('siteManagement save site =>', currentSite);
    siteActions.saveSite(siteCopy, setupMode === 'edit').then(async (site) => {
      if (site !== undefined) {
        setCurrentSite({ ...site });
        setOrgCurrentSite({ ...site });
        toastr.success(`${currentSite.title} ${intl.formatMessage({ id: editing ? 'detail_Updated' : 'detail_Saved' })}`);
        /* console.log('site created', site, editing); */
        if (site.contactEmail) {
          const userToSave = {
            email: site.contactEmail.toLowerCase(),
            siteId: site.id,
            firstName: '',
            lastName: '',
            companyId: siteCopy.companyId,
            companyName: siteCopy.title,
            locale
          };
          console.log('User to save', userToSave);
          let userError = '';
          try {
            await userActions.saveUser(userToSave);
            console.log('User created', userToSave);
          } catch (error) {
            if (error.toString().indexOf('Username Exists') > -1 || error.status === 409) {
              // no need to alert the user
              //toastr.success("User already exists. Please assign in User Setup");
              // TODO assign existing user instead? Would need to look up the user by email
            } else {
              userError = error;
            }
          }

          if (!userError) {
            siteActions.loadSite(site.id, true).then(() => {
              console.log('setting site ID Sites', site.id);
              const currentUser = getCurrentUser();
              siteActions.setSite(site.id, currentUser);
              redirect();
            });
          } else {
            console.log('user save error', userToSave);
            // toastr.error(userError);
            setSaving(false);
          }
        }
      } else {
        console.log('site save error 2', site);
        toastr.error('Site not saved');
        setSaving(false);
      }
    }).catch((error) => {
      console.log('site save error');
      console.log('%j', error);
      const errStr = extractErrors(error);
      toastr.error(errStr || error);
      setSaving(false);
    });
  }

  const getTitleId = () => {
    const actionMode = setupMode.toUpperCase();
    if (actionMode === 'EDIT') {
      return 'detail_Edit_a_Site';
    }

    if (actionMode === 'ADD') {
      return 'detail_Create_a_Site';
    }

    return `${getActionDescription(actionMode)} a Site`;
  }

  const siteHasApiModule = () => {
    let modules: Array<string> = [];
    if (currentSite.modules && Array.isArray(currentSite.modules)) {
      modules = currentSite.modules;
    } else {
      modules = currentSite.modules && currentSite.modules.values ? currentSite.modules.values : []
      if (!Array.isArray(modules)) {
        modules = Array.from(modules);
      }
    }

    return modules.indexOf(API_MODULE_NAME) > -1;
  }

  const hasSiteHasAnyActiveApiKey = currentSite && currentSite.activeApiKeys && currentSite.activeApiKeys.length > 0;

  const canCreateSite = isAdmin || !!UserHelper.Can('edit', 'siteDef') || !!UserHelper.Can('edit', 'siteConfig');
  return (setupMode.toUpperCase() !== 'ADD' || canCreateSite) ? <>
    {siteDataReady && currentSite && <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.headerContainer}>
          <FormHeadText
            textId={getTitleId()}
            fontSize='h3.fontSize'
            textAlign={tableHeadAlign}
          />
        </div>
        <TextInput
          fieldName="id"
          label={intl.formatMessage({ id: "detail_Site_Id" })}
          placeholder="12345678"
          value={currentSite.id || ''}
          onChange={updateDataWithText('id')}
          isDisabled={hasCreatedAt()}
          isRequired={true}
          errors={errors}
        />

        <TextInput
          fieldName="companyId"
          label={intl.formatMessage({ id: "detail_Company_Id" })}
          value={currentSite.companyId || ''}
          onChange={updateDataWithText('companyId')}
          isDisabled={hasCreatedAt()}
          placeholder="12345678"
          isRequired={true}
          errors={errors}
        />

        <Can do="edit" on="siteDef" passThrough>
          {can => (
            <TextInput
              isDisabled={!can}
              fieldName="title"
              label={intl.formatMessage({ id: "detail_Site_Name" })}
              placeholder="Ex. Site Name"
              isRequired={true}
              value={currentSite.title || ''}
              onChange={updateDataWithText('title')}
              errors={errors}
            />
          )}
        </Can>

        <Can do="edit" on="siteDef" passThrough>
          {can => (
            <TextInput
              isDisabled={!can}
              fieldName="description"
              label={intl.formatMessage({ id: "detail_Description" })}
              placeholder="Ex. Bottle Packaging"
              value={currentSite.description || ''}
              onChange={updateDataWithText('description')}
              errors={errors}
            />
          )}
        </Can>

        <Can do="edit" on="siteDef" passThrough>
          {can => (
            <TextInput
              isDisabled={!can}
              fieldName="address"
              label={intl.formatMessage({ id: "detail_Address" })}
              placeholder="123 E. Main, Duluth, GA 30096"
              value={currentSite.address || ''}
              onChange={updateDataWithText('address')}
              errors={errors}
            />
          )}
        </Can>

        <Can do="edit" on="siteDef" passThrough>
          {can => zoneNames.length > 0 ? (
            <SearchableTimezoneSelector
              isDisabled={!can}
              fieldName="tz"
              isRequired={true}
              label={intl.formatMessage({ id: "detail_TimeZone" })}
              placeholder="Select..."
              value={currentSite.tz || ''}
              options={zoneNames.map(p => ({
                key: p,
                value: p,
                text: p,
                label: moment.tz(p).zoneName(),
                description: moment.tz(p).format('Z z'),
              }))}
              onChange={updateDataWithSelect('tz')}
              errors={errors}
            />
          ) : null}
        </Can>

        <div className={classes.headerContainer}>
          <FormHeadText
            textId="detail_Setup_Site_Admin"
            fontSize='h4.fontSize'
            textAlign={tableHeadAlign}
          />
        </div>

        <Can do="edit" on="siteDef" passThrough>
          {can => (
            <TextInput
              isDisabled={!can}
              isRequired={true}
              fieldName="contactEmail"
              label={intl.formatMessage({ id: "detail_Site_Admin_Email" })}
              placeholder="admin@domain.com"
              value={currentSite.contactEmail || ''}
              onChange={updateDataWithText('contactEmail')}
              errors={errors}
            />
          )}
        </Can>

        <div className={classes.headerContainer}>
          <FormHeadText
            textId="detail_Rates"
            fontSize='h4.fontSize'
            textAlign={tableHeadAlign}
            subtextId="detail_Create_default_targets_for_your_site_to_measure_your_sites_efficiency"
          />
        </div>

        <Can do="edit" on="siteConfig" passThrough>
          {can => (<SlowCyclePercentage
            value={currentSite.slowCycleThreshold}
            onChange={can && kendoOnChange}
            isDisabled={!can}
          />
          )}
        </Can>

        <div className={classes.headerContainer}>
          <FormHeadText
            textId="detail_Alerts"
            fontSize='h4.fontSize'
            textAlign={tableHeadAlign}
          />
        </div>

        <Can do="edit" on="siteConfig" passThrough>
          {can => (<DowntimeCheckbox
            value={currentSite.notificationsEnabled}
            onChange={toggleNotifications}
            isDisabled={!can}
          />
          )}
        </Can>

        <Can do="edit" on="siteConfig" passThrough>
          {can => (<NotificationsDelay
            value={currentSite.notificationsDelay}
            onChange={can && kendoOnChange}
            isDisabled={!can}
            errors={errors}
          />
          )}
        </Can>

        <div className={classes.headerContainer}>
          <FormHeadText
            textId="detail_Downtime_Setup"
            fontSize='h4.fontSize'
            textAlign={tableHeadAlign}
          />
        </div>

        <Can do="edit" on="siteConfig" passThrough>
          {can => (<DowntimeThreshold
            value={currentSite.downtimeThreshold}
            onChange={can && kendoOnChange}
            isDisabled={!can}
            errors={errors}
          />
          )}
        </Can>

        {
          siteHasApiModule() && setupMode === 'edit' &&
          <Can do="edit" on="siteConfig" passThrough>
            {userHasSiteAdminRole => <Can do="full-access" on={`api-${siteId}`} passThrough>
              {
                userHasApiAccessRole => <>
                  {
                    (userHasSiteAdminRole || (userHasApiAccessRole && hasSiteHasAnyActiveApiKey))
                    && <div className={classes.headerContainer}>
                      <FormHeadText
                        textId="detail_ApiKey"
                        fontSize='h4.fontSize'
                        textAlign={tableHeadAlign}
                      />
                    </div>
                  }
                  {
                    (userHasSiteAdminRole || userHasApiAccessRole)
                    && hasSiteHasAnyActiveApiKey
                    && <TextInput
                      isDisabled={true}
                      fieldName="ApiKeyValue"
                      label={intl.formatMessage({ id: "detail_CurrentApiKey" })}
                      placeholder=""
                      isRequired={true}
                      value={currentSite.activeApiKeys[0].keyValue}
                      errors={errors}
                    />
                  }
                  {
                    userHasSiteAdminRole && <FormControl className={classes.formControl}>
                      <Button
                        variant="contained"
                        disabled={saving}
                        onClick={() => setApiKeyGenerationDialog(true)}>
                        <FormattedMessage id={hasSiteHasAnyActiveApiKey ? 'detail_RegenerateApiKey' : 'detail_GenerateApiKey'} />
                      </Button>
                    </FormControl>
                  }
                </>
              }
            </Can>
            }
          </Can>
        }
      </CardContent>
      <CardActions className={classes.actionButtons}>
        <Button
          variant="contained"
          disabled={saving || !dirty}
          onClick={cancelForm}>
          <FormattedMessage id="detail_Cancel" />
        </Button>

        <Can do="edit" on="siteDef" passThrough>
          {can => <Button
            variant="contained"
            color="primary"
            disabled={saving || !dirty || !can}
            onClick={saveSite}
          >
            <FormattedMessage id="detail_Save" />
          </Button>
          }
        </Can>

        <div className={classes.grow} />
        <Button
          variant="contained"
          onClick={closeForm}>
          <FormattedMessage id="detail_Close" />
        </Button>
      </CardActions>

    </Card>}
    <ConfirmDialog
      saving={saving}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={apiKeyGenerationDialog}
      onClose={onCloseApiKeyConfirmDialog}
      content={<FormattedMessage id={hasSiteHasAnyActiveApiKey ? 'detail_RegenerateApiKeyConfirm' : 'detail_GenerateApiKeyConfirm'} />}
    />
  </> : <NotAuthorised />
};

export default SiteForm;
