import React, { useEffect } from "react";
import MomentUtils from '@date-io/moment';
import Cookies from 'universal-cookie';
import { cognitoFields } from 'vccm-common';
import { signOutUser } from './libs/awsLib';
import { Route, Switch, Redirect } from "react-router-dom";
import { authUser, getCurrentUser, userRefreshSession } from './libs/awsLib';
import AsyncComponent from "./components/Common/AsyncComponent";

// components
import Layout from "./components/Layout/Layout";

// pages
import { Error } from "./components/Error/Error";
import Login from "./components/Login/Login";

// context
import { restoreUserFromSession, useAppGlobalDispatch, useAppUserState, useAppSiteState } from "./context/AppContext/AppContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Logger } from "./utilities/Logger/Logger";
import UserHelper from "./helpers/UserHelper";
import { APP_INITIALISED } from "./actions/actionTypes";
import { useSiteActions } from "./actions/siteActions";
import { useUserActions } from "./actions/userActions";

const AsyncSignUp = AsyncComponent({ loader: () => import('./components/Signup/Signup') });
const AsyncVrsMigrationNewUserPage = AsyncComponent({ loader: () => import('./components/VrsMigrationNewUserPage/VrsMigrationNewUserPage') });

export default function App({ match }: any) {
  const { isAuthenticated } = useAppUserState();
  const { selectedSiteId } = useAppSiteState();
  const dispatch = useAppGlobalDispatch();

  const siteActions = useSiteActions();
  const userActions = useUserActions();

  useEffect(() => {
    (async () => {
      try {
        if (await authUser()) {
          if (window.location.pathname.includes('/welcome/vrs/user')) {
            Logger.of('App').trace('trying to migrate user');
            await signOutUser();
          }
          const currentUser = await getCurrentUser();
          if (currentUser) {
            await userRefreshSession(currentUser);
            const paramSiteId = match && match.params && match.params.siteId;
            const userProfile = {
              email: UserHelper.getEmailAddressBySession(currentUser),
              selectedSiteId: paramSiteId || (new Cookies().get('LastSite')) || ''
            };

            restoreUserFromSession(dispatch, userProfile);
            Logger.of('App.Initialise').trace('Current user is =>', currentUser);
          }
        }
        userActions.setCognitoUserLoaded(true);

      } catch (e) {
        Logger.of('App.Initialise').warn('error', e);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isAdmin = (currentUser: any) => {
    let retVal = false;
    try {
      retVal = (currentUser && currentUser.signInUserSession && currentUser.signInUserSession.idToken && currentUser.signInUserSession.idToken.payload && currentUser.signInUserSession.idToken.payload[cognitoFields.COMPANY_ID_LIST].includes('*'));
    }
    catch (e) {
      Logger.of('App.isAdmin').warn('error', e);
    }
    return !!retVal;
  };

  const createSite = (currentUser) => {
    Logger.of('App.createSite').info('Creating site');
    siteActions.createSite({
      id: currentUser.username,
      title: 'My Site',
      companyId: currentUser.username,
      subscriptionStatus: 'trial',
    })
      .then(async (site) => {
        if (site) {
          Logger.of('App.createSite').info('Site created:', site);
          Logger.of('App.createSite').info('Refreshing user session:', currentUser);
          const refreshUser = await userRefreshSession(currentUser);
          await siteActions.loadSites();
          Logger.of('App.createSite').info('current:', { currentUser, refreshUser });
          await siteActions.setSite(site.id, currentUser);
          // ReactGA.ga('set', 'userId', `${currentUser.username}`);
          // ReactGA.ga((tracker) => {
          //   Logger.of('App.createSite').info('trackerId', tracker.get('clientId'));
          //   Logger.of('App.createSite').info('userId', tracker.get('userId'));
          // });

          dispatch({
            type: APP_INITIALISED, payload: {
              selectedSiteId: site.id,
              currentUser,
              isAdmin: isAdmin(currentUser)
            }
          });
        } else {
          Logger.of('App.createSite').warn('Could not create site');
        }
      })
      .catch(async (error) => {
        if (error && error.message && error.message.includes('Already exists')) {
          await siteActions.loadSites();
          // we use self signed user id as newly created site
          const siteId = currentUser.username;
          await siteActions.setSite(siteId, currentUser);
          // ReactGA.ga('set', 'userId', `${currentUser.username}`);
          // ReactGA.ga((tracker) => {
          //   Logger.of('App.createSite ').info(' already exists', tracker.get('userId'));
          // });

          dispatch({
            type: APP_INITIALISED, payload: {
              selectedSiteId: siteId,
              currentUser,
              isAdmin: isAdmin(currentUser)
            }
          });
        } else {
          Logger.of('App.createSite').warn('Could not create site', error);
        }
      });
  }

  const loadSites = async () => {
    const currentUser: any = await getCurrentUser();
    let userSite = null;
    let sites: Array<any> = [];
    try {
      if (selectedSiteId) {
        userSite = await siteActions.loadSingleSite(selectedSiteId);
        if (userSite) {
          sites = [userSite];
        }
      }
    } catch (err) {
      console.log('User does not have a site', err)
    }

    if(sites.length === 0) {
      sites = await siteActions.loadSites();
    //  console.log('sites=', JSON.stringify(sites.find(el => el.id === selectedSiteId),null, 2));
    }

    if (sites !== undefined && sites.length > 0) {
      // try to get last site from cache

      const siteId = selectedSiteId ? selectedSiteId : sites.length > 0 ? sites[0].id : '';

      if (currentUser && siteId) {
        Logger.of('App.componentDidMount').trace('Current user is =>', currentUser);
        await siteActions.setSite(siteId, currentUser);
      }

      dispatch({
        type: APP_INITIALISED, payload: {
          selectedSiteId: siteId,
          currentUser,
          isAdmin: isAdmin(currentUser)
        }
      });
    } else {
      // create a site since we don't have any.
      // This means we must be a self sign-up user
      await createSite(currentUser);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadSites();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />}
          />
          <PrivateRoute path="/about" component={Layout} isAuthenticated={isAuthenticated} />

          <Route path="/signup/:userType" component={AsyncSignUp} />
          <Route path="/welcome/vrs/user" component={AsyncVrsMigrationNewUserPage} />

          <PrivateRoute path="/home" component={Layout} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/sites" component={Layout} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/site" component={Layout} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/setup" component={Layout} isAuthenticated={isAuthenticated} />

          <PublicRoute path="/login" component={Login} isAuthenticated={isAuthenticated} />
          <Route component={Error} />
        </Switch>
      </>
    </MuiPickersUtilsProvider>
  );
}

interface IRoute {
  isAuthenticated: boolean;
  component: any;
  path: string;
}

function PrivateRoute({ component, isAuthenticated, ...rest }: Partial<IRoute>) {
  const { cognitoUserLoaded } = useAppUserState();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated
          ? React.createElement(component, props)
          : cognitoUserLoaded && <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
      }
    />
  );
}

function PublicRoute({ isAuthenticated, component, ...rest }: Partial<IRoute>) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated
          ? <Redirect
            to={{
              pathname: "/",
            }}
          />
          : React.createElement(component, props)
      }
    />
  );
}
