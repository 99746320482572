import React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    }
  },
  breakWithSmall: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    }
  },
  breakWithMedium: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    }
  }
}));

const StyledActionContainer = ({ breaks, ...props }: any) => {
  const classes = useStyles();
  return <div className={classNames(classes.root, {
    [classes.breakWithSmall]: breaks && breaks.sm,
    [classes.breakWithMedium]: breaks && breaks.md
  })} {...props} />
}

export default StyledActionContainer;
