import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import UserListRow from '../UserListRow/UserListRow';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import UserManagementBar from '../UserManagementBar/UserManagementBar';
import ability from '../../../ability';
import UserHelper from '../../../helpers/UserHelper';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../components/Paging/Paging';
import { NUMBER_OF_TOTAL_MODULES, PAGE_SIZE } from '../../../constants/global';
import { useUserListTableStyles } from './UserListTable.css';
import { useConfigActions } from '../../../actions/configActions';
interface IUserListState {
  page: number;
  sortedUsers: Array<any>;
  pageCount: number;
  pagedUsers: Array<any>;
}

interface IUserListTableProps {
  roles: any;
  siteId: string;
  users: any;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const set1Breaks = {
  email: { lg: 3, md: 4, sm: 12, xs: 12 },
  enabled: { lg: 1, md: 2, sm: 12, xs: 12 },
  status: { lg: 2, md: 6, sm: 12, xs: 12 },
  roles: { lg: 4, md: 8, sm: 12, xs: 12 },
  actionButtons: { lg: 2, md: 4, sm: 12, xs: 12 }
};

const UserListTable = ({
  roles,
  users,
  siteId,
  setRefreshRequired
}: IUserListTableProps) => {
  const classes = useUserListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const { sites, isAdmin, selectedSiteId, appFilters } = useAppGlobalState();
  const configActions = useConfigActions();
  const gridBreak: any = set1Breaks;
  const [allowedAuthModules, setAllowedAuthModules] = useState<Array<any>>([]);
  const [selectedAuthModules, setSelectedAuthModules] = useState<Array<any>>([]);
  const [activeRoles, setActiveRoles] = useState<Array<any>>([]);

  const site = sites.find(el => el.id === selectedSiteId);
  const canCreate = ability.rules.find(r => (r.actions === 'vrs' && r.subject === 'authModule' && r.fields && r.fields.includes('Administrator')) || (r.actions === 'create' && r.subject === 'user')) || isAdmin || false;
  const canView = canCreate || ability.rules.find(r => (r.actions === 'vrs' && r.subject === 'authModule' && r.fields && r.fields.includes('Administrator')) || (r.actions === 'view' && r.subject === 'user')) || isAdmin || false;
  const authModuleFilteredUsers = canView && users && site && selectedAuthModules && UserHelper.UsersInAuthModules(users, selectedAuthModules, selectedSiteId);

  const [filteredUsers, setFilteredUsers] = useState<Array<any>>([]);

  const [userListState, setUserListState] = useState<IUserListState>({
    page: 1,
    sortedUsers: [],
    pageCount: 0,
    pagedUsers: []
  });

  const {
    page,
    sortedUsers,
    pageCount,
    pagedUsers
  } = userListState;

  useEffect(() => {
    const modules = site && site.modules && site.modules.values && site.modules.values;
    const allowedAuthMods = UserHelper.getAllowedAuthModules(isAdmin)
      .filter(el => el.value !== 'vrs' || (modules && modules.indexOf('vrs') > -1))
      .filter(el => el.value !== 'api' || (modules && modules.indexOf('api') > -1));

    setAllowedAuthModules(allowedAuthMods);

    if (appFilters.userFilter.siteId && site.id === appFilters.userFilter.siteId) {
      setSelectedAuthModules([...appFilters.userFilter.filteredAuthModules]);
    } else {
      setSelectedAuthModules(allowedAuthMods.map(a => a.value));
      configActions.setBarFilter('userFilter', (filter) => ({ ...filter, siteId: site.id }));
    }

  }, [isAdmin, site]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (appFilters.userFilter.filteredAuthModules.length === 0 && (!appFilters.userFilter.siteId || site.id !== appFilters.userFilter.siteId)) {
      configActions.setBarFilter('userFilter', (filter) => ({ ...filter, filteredAuthModules: allowedAuthModules.map(el => el.value) }));
    }
  }, [allowedAuthModules, configActions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredAuthModules = appFilters.userFilter.filteredAuthModules;
    setActiveRoles(filteredAuthModules.length === 0 ? roles : roles.filter(el => el.authModule !== 'vrs' || (filteredAuthModules.indexOf('vrs') > -1))
      .filter(el => el.authModule !== 'api' || (filteredAuthModules.indexOf('api') > -1))
      .filter(el => el.authModule || (filteredAuthModules.indexOf('vccm') > -1)));
  }, [roles, appFilters.userFilter.filteredAuthModules]);

  useEffect(() => {
    const sorted = users.map(el => ({ ...el, email: UserHelper.getEmailAddress(el) })).sort((a, b) => a.email.localeCompare(b.email));
    setUserListState(s => {
      return {
        ...s,
        sortedUsers: sorted
      }
    })

  }, [users]);

  useEffect(() => {
    const searchTerm = appFilters.userFilter.searchTerm;
    const filteredAuthModules = appFilters.userFilter.filteredAuthModules;

    let initialFilteredUsers = searchTerm
      ? sortedUsers.filter(u => u.email.indexOf(searchTerm.toLowerCase()) > -1)
      : sortedUsers;


    if (filteredAuthModules.length > 0 && filteredAuthModules.length < NUMBER_OF_TOTAL_MODULES) {
      initialFilteredUsers = initialFilteredUsers.filter(u => !!u.authRoles.find(role =>
        filteredAuthModules.find(elm => elm === role.authModule
          && role[siteId] && ((elm === 'vccm' && role[siteId].length > 0)
            || (elm !== 'vccm' && !!role[siteId].find(elmx => elmx.indexOf(`${elm}-`) > -1))))));
    }

    setFilteredUsers(initialFilteredUsers);

  }, [appFilters, sortedUsers, selectedAuthModules, siteId]);

  useEffect(() => {
    setUserListState(s => {
      const count = Math.ceil(((filteredUsers && site && filteredUsers.length) || 0) / PAGE_SIZE);
      const paged = filteredUsers.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedUsers: paged
      }
    })
  }, [filteredUsers, site]);

  const onPageChange = useCallback(p => {
    setUserListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredUsers.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setUserListState(s => ({
      ...s,
      pagedUsers: paged
    }));
  }, [page, filteredUsers]);

  return (<>
    <UserManagementBar
      siteId={siteId}
      allowedAuthModules={allowedAuthModules}
      filter={appFilters.userFilter}
      setFilter={configActions.setBarFilter}
      setRefreshRequired={setRefreshRequired}
    />
    {canView && users && site && selectedAuthModules && authModuleFilteredUsers &&
      <StyledContentCard>
        {
          users && users.length > 0 ? <StyledGrid>
            {
              pagedUsers.length > 0 ? <StyledGridHeadRow>
                <StyledGridHeadCell {...gridBreak.email}>
                  <TableHeadText
                    textId="detail_Email"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell {...gridBreak.enabled}>
                  <TableHeadText
                    textId="detail_Enabled"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>

                <StyledGridHeadCell {...gridBreak.status}>
                  <TableHeadText
                    textId="detail_User_Status"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>

                <StyledGridHeadCell {...gridBreak.roles}>
                  <TableHeadText
                    textId="detail_User_Roles"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>

                <StyledGridHeadCell {...gridBreak.actionButtons}>
                </StyledGridHeadCell>
              </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
            }
            <>
              {
                pagedUsers.map(user => (
                  <UserListRow
                    key={user.Username}
                    selectedAuthModules={selectedAuthModules}
                    ignoreVccmDisplay={appFilters.userFilter.filteredAuthModules.length > 0 && appFilters.userFilter.filteredAuthModules.indexOf('vccm') === -1}
                    setRefreshRequired={setRefreshRequired}
                    user={user}
                    roles={activeRoles}
                    site={sites.find(el => el.id === siteId)}
                    gridBreak={gridBreak}
                  />
                ))
              }
            </>
          </StyledGrid>
            : <EmptyItem textId="detail_noUsers" />
        }
        {
          filteredUsers && site && filteredUsers.length > PAGE_SIZE ? <div className={classes.pageContainer}>
            <Paging
              page={page}
              onChange={onPageChange}
              count={pageCount}
              total={filteredUsers.length} /></div> : null
        }
      </StyledContentCard>
    }
  </>
  );
}

export default UserListTable;
