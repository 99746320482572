

import React, { useEffect, useState } from 'react';
import { LINE_STATE_BAR_REFRESH_DELAY } from '../../constants/global';
import { useResizableChartStyle } from './ResizableChart.css';
interface IResizableProps {
  children?: any;
}

const ResizableChart = ({ children }: IResizableProps) => {
  const [container, setContainer] = useState<any>(null);
  const classes = useResizableChartStyle();

  const [myWidth, setMyWidth] = useState<number>(0);

  useEffect(() => {
    const myWidthCheckInterval = setInterval(() => {
      if (container && myWidth !== container.offsetWidth) {
        setMyWidth(container.offsetWidth);
      }
    }, LINE_STATE_BAR_REFRESH_DELAY);

    return () => {
      clearInterval(myWidthCheckInterval);
    }

  }, [myWidth, container]);

  const child = React.Children.only(children);
  const childElement = container
    ? React.cloneElement(child, { width: myWidth })
    : null;

  return (
    <div ref={c => {
      setContainer(c);
    }} className={classes.root}>
      {childElement}
    </div>
  );
}

export default ResizableChart;
