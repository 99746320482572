import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import classNames from "classnames";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  Info as InfoIcon,
  ExitToApp as ExitToAppIcon,
  Help as HelpIcon
} from "@material-ui/icons";

import * as config from '../../config';

import List from '@material-ui/core/List';


import { ReactComponent as CompanyLogo } from './videojet-logo.svg';

// styles
import useStyles from "./Header.css";

// context
import { useAppGlobalDispatch, useLogOut, useAppSiteState, useAppConfigState, useAppUserState } from "../../context/AppContext/AppContext";
import { useHistory } from 'react-router';
import { toggleSidebar, useLayoutDispatch } from "../../context/LayoutContext/LayoutContext";
import AddressLabel from "../AddressLabel/AddressLabel";
import PageTitle from "../PageTitle/PageTitle";
import { useIntl } from "react-intl";
import { ProfileMenuItem } from "../ProfileMenuItem/ProfileMenuItem";
import { SimpleLinearProgress } from "../SimpleLinearProgress/SimpleLinearProgress";
import { useConfigActions } from "../../actions/configActions";

function Header() {
  const configActions = useConfigActions();
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();

  const { sites, selectedSiteId } = useAppSiteState();
  const { headerTitleInfo, isLoading } = useAppConfigState();
  const { isAuthenticated, userProfile } = useAppUserState();

  // global
  const layoutDispatch = useLayoutDispatch();

  const userDispatch = useAppGlobalDispatch();
  const logOut = useLogOut();

  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const extraSmallSize = useMediaQuery(theme.breakpoints.down('xs'));

  // local
  const [profileMenu, setProfileMenu] = useState(null);

  const getSelectedSite = () => {
    if (sites && sites.length > 0 && selectedSiteId) {
      return sites.find(el => el.id === selectedSiteId);
    }

    if (sites && sites.length > 0 && !selectedSiteId) {
      return sites[0];
    }

    return null;
  }

  const isHomePage = () => history.location.pathname.toLowerCase().indexOf('/home') > -1;

  const gotoHome = (event) => {
    event.preventDefault();
    if (!isHomePage()) {
      configActions.setHomeSelectionMode(2);
      history.push('/home');
    }
  }

  const selectedSite = getSelectedSite();

  return <><AppBar className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <div className={classes.logoContainerParent}>
        <div className={classes.logoContainer}>
          <CompanyLogo
            onClick={gotoHome}
            className={classNames(classes.logo, { [classes.link]: !isHomePage() })} />
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classes.headerMenuButton}
          >
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          </IconButton>
        </div>
        {isLoading && <SimpleLinearProgress />}
      </div>
      {
        headerTitleInfo && !extraSmallSize && <PageTitle {...headerTitleInfo} smallSize={smallSize} titleOnly={smallSize} />
      }
      <div className={classes.grow} />
      {
        isAuthenticated && selectedSite && <div className={classes.addressContainer}>
          <div className={classes.siteTitle}>
            {selectedSite.title}
          </div>
          <div className={classes.addressLines}>
            {<AddressLabel address={selectedSite.address} />}
            <i>
              {selectedSite.tz}
            </i>
          </div>
        </div>
      }
      {
        isAuthenticated && <div className={classes.profileBtnContainer}><IconButton
          aria-haspopup="true"
          color="inherit"
          className={classNames(classes.headerMenuButton, 'user-btn')}
          aria-controls="profile-menu"
          onClick={(e: any) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerAccountIcon }} />
        </IconButton></div>
      }
      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <div className={classes.profileMenuItem}>
          <div className={classes.profileMenuLink}>
            {userProfile && userProfile.email}
          </div>
        </div>
        <List component="nav" aria-label="profile menu">
          <ProfileMenuItem
            icon={<ExitToAppIcon />}
            linkLabel={intl.formatMessage({ id: 'detail_Logout' })}
            onClickMenuItem={() => logOut(userDispatch, history)}
            extraClass="logout-btn"
          />
          <ProfileMenuItem
            icon={<HelpIcon />}
            linkLabel={intl.formatMessage({ id: 'detail_Help' })}
            onClickMenuItem={() => {
              const endpoint = config.default.apiGateway.VCCM_HELP_URL;
              window.open(endpoint, '_blank');
            }}
            extraClass="help-btn"
          />
          <ProfileMenuItem
            icon={<InfoIcon />}
            linkLabel={intl.formatMessage({ id: 'detail_About' })}
            onClickMenuItem={() => history.replace('/about')}
            extraClass="about-btn"
          />
        </List>
      </Menu>
    </Toolbar>
  </AppBar>
    {smallSize && <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar} classes={{ root: classes.toolBarBreadcrumb }}> {
        headerTitleInfo && <PageTitle {...headerTitleInfo} smallSize={smallSize} titleOnly={false} />
      }</Toolbar>
    </AppBar>
    }
  </>
}

Header.displayName = 'Header';

export default Header;
