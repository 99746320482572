export const categoriesMapping = {
  'view-schedule': 'Setup',
  'edit-schedule': 'Setup',
  'view-product': 'Setup',
  'edit-product': 'Setup',
  'view-line': 'Setup',
  'edit-line': 'Setup',
  'view-downtimeReason': 'Setup',
  'edit-downtimeReason': 'Setup',
  'view-deviceRole': 'Setup',
  'edit-deviceRole': 'Setup',
  'view-role': 'User Management',
  'edit-role': 'User Management',
  'create-user': 'User Management',
  'delete-user': 'User Management',
  'assign-user-role': 'User Management',
  'assign-line-product': 'Operation',
  'assign-downtime-reason': 'Operation',
  'assign-line-printer': 'Operation',
  'apply-qa': 'Operation',
  'view-siteDef': 'Site Management',
  'edit-siteDef': 'Site Management',
  'edit-siteConfig': 'Site Management',
  'edit-design': 'Design',
  'send-printerJob': 'Design',
  'download-printerJob': 'Design',
  'view-inspection': 'Setup',
  'edit-inspection': 'Setup',
  'perform-inspection': 'Operation',
  'view-report': 'Report',
  'edit-report': 'Report',
  'edit-global-report': 'Report',
  'view-assessment': 'Operation',
  'edit-assessment': 'Operation',
  'approve-assessment': 'Operation',
  'edit-printerJob': 'Order Execution',
  'execute-printerJob': 'Order Execution',
  'full-access': 'Full Access',
};
