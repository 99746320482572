import { invokeApig } from '../libs/awsLib';
import { Logger } from '../utilities/Logger/Logger';

class SiteApi {
  static createSite(site) {
    const apiUrl = '/sites/create-design-only';
    return invokeApig({ gatewayName: 'SITES', path: apiUrl, method: 'POST', body: site });
  }

  static getAllSites() {
    const apiUrl = '/sites';
    return invokeApig({ gatewayName: 'SITES', path: apiUrl });
  }

  static getSite(siteId) {
    return invokeApig(this.getSiteRequest(siteId));
  }

  static migrateSites(syncType: string) {
    const apiUrl = `/sites/replicate/${syncType}`;
    return invokeApig({ gatewayName: 'SITES', method: 'POST', path: apiUrl });
  }

  static getSiteRequest(siteId, apiUrl = '/sites') {
    return {
      method: 'GET',
      gatewayName: 'SITES',
      path: `${apiUrl}/${siteId}`,
      // queryParams: { siteId },
    };
  }

  static getDowntimeReasons(siteId) {
    const apiUrl = '/downtimeReasons/getReasons';
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, queryParams: { siteId } });
  }

  /**
   * Gets downtime summary data
   *
   * @function
   *
   * @param {Date} endDt - end date for requested events.
   * @param {string} interval - hours,days,shift_hours.
   * @param {Array} lineIds
   * @param {Array} shiftIds
   * @param {number} siteId
   * @param {Date} startDt - start date for requested events
   * @param includeMicrostops
   * @param includePrinterDisconnected
   */
  static getSiteDowntimeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, includeMicrostops, includePrinterDisconnected) {
    /* console.log('here is your ids',lineIds); */
    const apiUrl = '/downtimeEvents/getEventsBy';
    const queryParams = {
      interval,
      siteId,
      startDt,
      endDt,
      lineIds,
      shiftIds,
      version: 2,
      microstops: includeMicrostops === true,
      printerDisconnected: includePrinterDisconnected === true,
    };
    /* console.log('Downtime queryParams', queryParams); */
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams });
  }

  static getSitePrinters(siteId) {
    const apiUrl = '/sites/getSitePrinters';
    const version = 3;
    return invokeApig({ gatewayName: 'SITES', path: apiUrl, queryParams: { version, siteId } });

  }


  static getSiteTimeZones() {
    const apiUrl = '/timezones';
    return invokeApig({ gatewayName: 'SITES', path: apiUrl });
  }

  /**
   * Gets oee data
   *
   * @function
   *
   *   - end date for the events.
   *   - hour,day,week.
   * @param params
   */
  static getSiteOeeSummary(params) {
    const { lineIds } = params;
    const apiUrl = `/oee/${lineIds && lineIds[0]}`;
    return invokeApig({
      gatewayName: 'OEE',
      path: apiUrl,
      queryParams: {
        ...params,
        detailed: true,
      },
    });
  }

  static getShiftReport(siteId, startDt) {
    const apiUrl = `/shiftReport/${siteId}`;
    return invokeApig({ gatewayName: 'OEE', path: apiUrl, queryParams: { startDt } });
  }

  static loadJob(request) {
    const apiUrl = '/sites/jobRequest';
    return invokeApig({ gatewayName: 'SITES', path: apiUrl, method: 'POST', body: request });
  }

  static saveSite(site) {
    const apiUrl = '/sites';
    return invokeApig({
      gatewayName: 'SITES',
      path: apiUrl,
      method: 'POST',
      body: {
        address: site.address || undefined,
        addressState: site.addressState || undefined,
        companyId: site.companyId,
        contactEmail: site.contactEmail,
        contactName: site.contactName,
        createdAt: site.createdAt,
        ...(site.dashConfig ? { dashConfig: site.dashConfig } : {}),
        description: site.description,
        notificationsDelay: site.notificationsDelay,
        notificationsEnabled: site.notificationsEnabled,
        slowCycleThreshold: site.slowCycleThreshold,
        smallStopCategory: site.smallStopCategory,
        subscriptionStatus: site.subscriptionStatus,
        id: site.id,
        title: site.title,
        updatedAt: site.updatedAt,
        tz: site.tz,
        complianceSettings: site.complianceSettings,
        downtimeThreshold: site.downtimeThreshold,
      }
    });
  }

  static updateSite(site) {
    const apiUrl = `/sites/${site.id}`;
    // VCCM-1691 optional description, remove from stored record (by sending null) if empty string
    if (site.description === '') {
      site.description = null;
    }



    return invokeApig({
      gatewayName: 'SITES',
      path: apiUrl,
      method: 'PUT',
      body: {
        address: site.address || undefined,
        addressState: site.addressState || undefined,
        companyId: site.companyId,
        contactEmail: site.contactEmail,
        contactName: site.contactName,
        createdAt: site.createdAt,
        ...(site.dashConfig ? { dashConfig: site.dashConfig } : {}),
        description: site.description,
        // modules: site.modules,
        usagePlanType: site.usagePlanType || undefined,
        ...(site.modules && site.modules.values && site.modules.values.length > 0 ? { modules: site.modules.values } : { modules: null }),
        notificationsDelay: site.notificationsDelay,
        notificationsEnabled: site.notificationsEnabled,
        slowCycleThreshold: site.slowCycleThreshold,
        smallStopCategory: site.smallStopCategory,
        title: site.title,
        updatedAt: site.updatedAt,
        tz: site.tz,
        complianceSettings: site.complianceSettings,
        downtimeThreshold: site.downtimeThreshold
      }
    });
  }

  static generateApiKey(site) {
    const apiUrl = `/sites/${site.id}/apikey`;
    if (site.description === '') {
      site.description = null;
    }
    return invokeApig({
      gatewayName: 'SITES',
      path: apiUrl,
      method: 'POST',
      body: {
        address: site.address || undefined,
        addressState: site.addressState || undefined,
        companyId: site.companyId,
        contactEmail: site.contactEmail,
        contactName: site.contactName,
        createdAt: site.createdAt,
        ...(site.dashConfig ? { dashConfig: site.dashConfig } : {}),
        description: site.description,
        // modules: site.modules,
        ...(site.modules && site.modules.values ? { modules: site.modules.values } : []),
        notificationsDelay: site.notificationsDelay,
        notificationsEnabled: site.notificationsEnabled,
        slowCycleThreshold: site.slowCycleThreshold,
        smallStopCategory: site.smallStopCategory,
        title: site.title,
        updatedAt: site.updatedAt,
        tz: site.tz,
        complianceSettings: site.complianceSettings,
        downtimeThreshold: site.downtimeThreshold,
      }
    });
  }

  static deleteSite(id) {
    const apiUrl = `/sites/${id}`;
    return invokeApig({ gatewayName: 'SITES', path: apiUrl, method: 'DELETE' });
  }

  static getJob(id, request) {
    Logger.of('App.prodSiteApi.getJob').info('getting job', { id, request });
    const apiUrl = `/sites/jobRequest/${id}`;
    return invokeApig({ gatewayName: 'SITES', path: apiUrl, method: 'GET', queryParams: request });
  }
}

export default SiteApi;
