import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import toastr from 'toastr';
import { Logger } from '../../../utilities/Logger/Logger';
import { useAppGlobalDispatch, useAppGlobalState } from '../../../context/AppContext/AppContext';
import ProductListTable from '../ProductListTable/ProductListTable';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { CardGiftcard as CardGiftcardIcon } from "@material-ui/icons";
import { loadLines } from '../../../services/LineService/LineService';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useProductActions } from '../../../actions/productActions';
import { ProductDelete } from '../ProductDelete/ProductDelete';
import Utils from '../../../utilities/utils';

const addLinesToProducts = (lines: Array<any>, products) => {
  const localProducts = [...products];
  for (const keyLocalProducts in localProducts) {
    const lineTitles: Array<any> = [];

    lines.forEach((line) => {
      if (line.assignedProductId === localProducts[keyLocalProducts].id) {
        lineTitles.push(line.title);
      }
    });
    localProducts[keyLocalProducts] = { ...localProducts[keyLocalProducts], lineTitles };
  }
  return localProducts;
};

const ProductSetupPage = (props) => {
  const dispatch = useAppGlobalDispatch();
  const { sites } = useAppGlobalState();
  const productActions = useProductActions();

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Array<any>>([]);
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [toBeDeletedProduct, setToBeDeletedProduct] = useState<any>({ title: '' });

  const setTitle = useSetTitleAction();
  const refreshSiteData = async () => {
    try {
      const siteId: string = props.match.params.siteId;
      const currentSite = sites.find(el => el.id === siteId);
      if (currentSite && currentSite.printers) {
        setIsLoading(() => true);
        const [lines, currentProducts] = await Promise.all(
          [
            loadLines(siteId, sites.find(el => el.id === siteId).slowCycleThreshold, dispatch),
            productActions.loadProducts(siteId)
          ]).catch(err => {
            toastr.error(err.message ? err.message : err)
          }) as any;

        setProducts(addLinesToProducts(lines, currentProducts.sort(Utils.sortByTitle)));
        setIsLoading(() => false);
      }
    } catch (error) {
      Logger.of('App.ProductSetupPage.refreshSiteData').info('Error Refreshing site data', error);
    }
  }

  useEffect(() => {
    if (sites.length > 0) {
      refreshSiteData();
    }
  }, [sites]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_Products', <CardGiftcardIcon />, {
      parents: [{
        id: 'detail_Configuration',
      }]
    });
  }, [setTitle]);

  const onDeleteProduct = (line) => {
    setDeleteConfirmVisible(true);
    setToBeDeletedProduct(line)
  }

  const onProductDeleted = () => {
    setDeleteConfirmVisible(false);
    refreshSiteData();
  }

  const siteId: string = props.match.params.siteId;
  const currentSite = sites.find(el => el.id === siteId);

  return (
    !isLoading ?
      <>
        <ProductListTable
          refreshSiteData={refreshSiteData}
          site={currentSite}
          products={products}
          onDeleteProduct={onDeleteProduct}
        />
        <ProductDelete
          product={toBeDeletedProduct}
          isDeleteConfirmVisible={isDeleteConfirmVisible}
          setDeleteConfirmVisible={setDeleteConfirmVisible}
          onDeleted={onProductDeleted} />

      </> :
      <ProgressIndicator />
  );
}

ProductSetupPage.propTypes = {
  history: object,
  match: object,
};

export default ProductSetupPage;
