import React, { useEffect, useState } from 'react';
import * as ReactGA from 'react-ga';
import Moment from 'moment/moment';
import * as FileSaver from 'file-saver'
import { FormattedMessage, useIntl } from 'react-intl';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import { authenticate, getPrintersWithJobs, getTemplates, getJobFile } from '../../../actions/cirrusActions2'
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useDisplayModeSettingsSetupPageStyles } from './DownloadPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl
} from '@material-ui/core';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';
import { Logger } from '../../../utilities/Logger/Logger';
import { useSiteActions } from '../../../actions/siteActions';
import { StyledDropDown } from '../../../components/StyledDropdown/StyledDropdown';
import Can from '../../../components/Common/Can';

interface IDisplayModeSettingsSetupPage {
  match: any;
}

const DownloadPageFlashMessage = () => {
  const intl = useIntl();
  return (
    <div>
      <p>
        {intl.formatMessage({ id: 'detail_locationJob' })}
      </p>
      <p>
        {intl.formatMessage({ id: 'detail_transferJob' })}
      </p>
      <p>
        {intl.formatMessage({ id: 'detail_Printer_does_not_recognise_the_JobDesign_manual' })}
      </p>
    </div>
  )
};

const DownloadPage = ({ match }: IDisplayModeSettingsSetupPage) => {
  const classes = useDisplayModeSettingsSetupPageStyles();

  const intl = useIntl();
  const { sites } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const siteActions = useSiteActions();

  const siteId: string = match.params.siteId;
  const [isLoading, setIsLoading] = useState(true);
  const [downloadToFlash, setDownloadToFlash] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [isChecking, setIsChecking] = useState(false);

  const [allPrinters, setAllPrinters] = useState<Array<any>>([]);
  const [jobs, setJobs] = useState<Array<any>>([]);

  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedSitePrinter, setSelectedSitePrinter] = useState('');

  const [status, setStatus] = useState('');

  const buildStatusString = (status, msg) => {
    let translatedStatus = status;
    switch (status) {
      case 'QUEUED':
        translatedStatus = intl.formatMessage({ id: 'detail_JobDownloadQueued' });
        break;
      case 'IN_PROGRESS':
        translatedStatus = intl.formatMessage({ id: 'detail_JobDownloadInProgress' });
        break;
      case 'FAILED':
        translatedStatus = intl.formatMessage({ id: 'detail_JobDownloadFailed' });
        break;
      case 'SUCCEEDED':
        translatedStatus = intl.formatMessage({ id: 'detail_JobDownloadComplete' });
        break;
      case 'TIMED_OUT':
        translatedStatus = intl.formatMessage({ id: 'detail_JobDownloadTimedOut' });
        break;
      default:
        Logger.of('App.DownloadPage.buildStatusString').warn('Unknown status', status);
        break;
    }
    const statusString = msg ? `${translatedStatus}: ${msg}` : `${translatedStatus}`;
    const finalStatusString = `${intl.formatMessage({ id: 'detail_Status' })} [${Moment().format('HH:mm:ss')}] : ${statusString}`;
    return finalStatusString;
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      if (isLoading || !siteId || !jobId || isChecking) {
        return;
      }

      try {
        setIsChecking(true);
        Logger.of('App.DownloadPage.checkStatus').info('getting job execution for ', jobId);
        siteActions.getJob(jobId, { siteId, printerId: selectedSitePrinter })
          .then((success) => {
            if (success) {
              const msg = (success.statusDetails && success.statusDetails.detailsMap && success.statusDetails.detailsMap.progress_message) || '';
              const statusString = buildStatusString(success.status, msg);
              setStatus(statusString)
              Logger.of('App.DownloadPage.checkStatus').info('status string ', statusString);
              if (success.status !== 'QUEUED' && success.status !== 'IN_PROGRESS') {
                Logger.of('App.DownloadPage.checkStatus').info('terminal state');
                setJobId(null);
              }
            }
          })
          .catch((err) => Logger.of('App.DownloadPage.checkStatus').warn('get job error', err));
      } catch (e) {
        Logger.of('App.DownloadPage.checkStatus').warn('error', e);
      }
      setIsChecking(false);
    }, 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });


  // Get a list of printers with jobs
  useEffect(() => {
    (async () => {
      const printerArray: Array<any> = [];
      try {
        setIsLoading(true);
        if (siteId && siteId.length > 0) {
          const token = await authenticate(siteId);
          if (token && token.length > 0) {
            const printersWithJobs: any = await getPrintersWithJobs();
            printersWithJobs.map((printers) => printers.map((printer) => printerArray.push({
              key: printer.Id,
              value: printer.Name,
              text: printer.Name,
            })));
          }
        }
      } catch (error) {
        Logger.of('DownloadPage.LoadPrinters').error('Load Printers failed', error);
      } finally {
        setIsLoading(false);
        setAllPrinters(printerArray);
      }
    })();
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  //Get the jobs for the selected printer
  useEffect(() => {
    (async () => {
      const jobsArray: Array<any> = [];
      try {
        if (siteId && siteId.length && selectedPrinter && selectedPrinter.length) {
          const token = await authenticate(siteId);
          if (token && token.length > 0) {
            const templates = await getTemplates(selectedPrinter);
            templates.map((job) => jobsArray.push({
              key: job.Id,
              value: job.Id,
              text: job.DisplayedTitle,
            }));
          }
        }
      } catch (error) {
        Logger.of('DownloadPage.LoadJobs').error('Load Jobs failed', error);
      } finally {
        setJobs(jobsArray);
      }
    })();
  }, [siteId, selectedPrinter]); // eslint-disable-line react-hooks/exhaustive-deps


  const downloadButtonDisabled = (can) => {
    if (!can) {
      return true;
    }

    const site = sites.find(el => el.id === siteId);
    const hasSubscription = site && site.subscriptionStatus !== 'trial';

    if (downloadToFlash || !hasSubscription) {
      if (!selectedPrinter || !selectedJob)
        return true;
    } else {
      if (!selectedPrinter || !selectedJob || !selectedSitePrinter) {
        return true;
      }
    }
    return false;
  }

  const getSitePrintersOptions = () => {
    const site = sites.find(el => el.id === siteId);
    if (site && site.printers) {
      return site.printers.map((sitePrinter) => {
        return {
          key: sitePrinter.serial,
          value: sitePrinter.serial,
          text: sitePrinter.friendlyName,
        }
      })
    }
    return [];
  }

  const handleDownload = (commandType) => {
    (async () => {
      try {
        const token = await authenticate(siteId);
        if (token && token.length > 0) {
          const success = await siteActions.loadJob({ siteId, printerId: selectedSitePrinter, token, templateId: selectedJob, type: commandType });
          if (success && success.jobId) {
            setJobId(success.jobId);
            // test call status method
            console.log('setState ', success.jobId);
            ReactGA.event({
              category: 'Job',
              action: 'Printer Download Request',
              label: selectedSitePrinter,
            });

          }
        }
      } catch (error) {
        Logger.of('DownloadPage.handleDownload').error('Load Job failed', error);
        if (error.message.indexOf('Res Locator Error') > 0) {
          setStatus(intl.formatMessage({ id: 'detail_ResLocatorError' }));
        } else {
          setStatus(error.message);
        }
      }
    })();
  };

  const handleDownloadToFlash = () => {
    (async () => {
      try {
        const token = await authenticate(siteId);
        if (token && token.length > 0) {
          const data = await getJobFile(selectedJob);
          if (data) {
            const friendlyName = jobs.find(x => x.value === selectedJob).text;
            const blob = new File([data], `${friendlyName || 'Job'}.zjf`, { type: 'application/vj.zjf' });
            FileSaver.saveAs(blob);
            ReactGA.event({
              category: 'Job',
              action: 'Flash Download',
              label: friendlyName,
            });
          }
        }
      } catch (error) {
        Logger.of('DownloadPage.handleDownloadToFlash').error('Load Job failed', error);
      }
    })();
  };

  useEffect(() => {
    setTitle('detail_Download', <CloudDownloadIcon />, {
      parents: [{
        textOnly: true,
        id: 'DESIGN',
      }]
    });
  }, [setTitle]);

  const updatePrinterModel = (value) => {
    setSelectedPrinter(value.toString());
    setSelectedJob('');
  }

  const site = sites.find(el => el.id === siteId);
  const hasSubscription = site && site.subscriptionStatus !== 'trial';

  return !isLoading && sites.length > 0 ? <>
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <FormControl variant="outlined"  className={classes.formControl}>
          <StyledDropDown
            labelId="printer-model"
            multiple={false}
            name="printerModel"
            label={intl.formatMessage({ id: 'detail_Printer_Model' })}
            placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
            options={allPrinters}
            value={selectedPrinter}
            onChange={updatePrinterModel}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <StyledDropDown
            labelId="jobName"
            multiple={false}
            name="jobName"
            label={intl.formatMessage({ id: 'detail_Job' })}
            placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
            options={allPrinters}
            value={selectedJob}
            onChange={(value) => { setSelectedJob(value.toString()); }}
          />
        </FormControl>
        {
          hasSubscription && <FormControl variant="outlined" className={classes.formControl}>
            <StyledDropDown
              labelId="sitePrinters"
              multiple={false}
              name="sitePrinters"
              label={intl.formatMessage({ id: 'detail_Printer' })}
              placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
              options={getSitePrintersOptions()}
              value={selectedSitePrinter}
              onChange={(value) => { setSelectedSitePrinter(value.toString()); }}
            />
          </FormControl>
        }
        {
          hasSubscription && <FormControl className={classes.formControl}>
            <div>
              <FormattedMessage id="detail_Download_to_flash" />
            </div>
            <div className={classes.controlContainer}>
              <StyledSwitch checked={downloadToFlash}
                onChange={(e, checked) => setDownloadToFlash(checked)}
                name="checkedAutoRotate" />
            </div>
          </FormControl>
        }
        {(downloadToFlash || !hasSubscription) && (
          <DownloadPageFlashMessage />
        )}
      </CardContent>
      <CardActions className={classes.actionButtons}>
        {
          downloadToFlash || !hasSubscription ? (
            <div style={{ margin: '10px 0px' }}>
              <Can do="download" on="printerJob" passThrough>
                {(can) => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={downloadButtonDisabled(can)}
                    onClick={() => handleDownloadToFlash()}
                    style={{ margin: '10px 0px' }}>
                    <FormattedMessage id="detail_Download_to_flash" />
                  </Button>
                )}
              </Can>
            </div>
          ) : (
              <div style={{ margin: '10px 0px' }}>
                <Can do="send" on="printerJob" passThrough>
                  {(can) => (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={downloadButtonDisabled(can)}
                      onClick={() => handleDownload('Download')}
                      style={{ margin: '10px 0px' }}>
                      <FormattedMessage id="detail_Download" />
                    </Button>
                  )}
                </Can>
              </div>
            )
        }
        {status && (
          <div style={{ margin: '10px 0px' }}>
            <label style={{ minWidth: '250px' }}>
              {status}
            </label>
          </div>
        )}
      </CardActions>
    </Card>
  </> :
    <div className={classes.progress}>
      <ProgressIndicator />
    </div>
};

export { DownloadPage };
