import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TableHeadText } from '../../../../components/TableHeadText/TableHeadText';

import StyledGrid from '../../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import DowntimeSubCategoryListRow from '../DowntimeSubCategoryListRow/DowntimeSubCategoryListRow';
import StyledContentCard from '../../../../components/Basic/StyledContentCard/StyledContentCard';
import { DowntimeSubCategoryBar } from '../DowntimeSubCategoryBar /DowntimeSubCategoryBar';
import Can from '../../../../components/Common/Can';
import { EmptyItem } from '../../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../../constants/global';
import { useDowntimeSubCategoryListTableStyles } from './DowntimeSubCategoryListTable.css';
import { useConfigActions } from '../../../../actions/configActions';
import { useAppGlobalState } from '../../../../context/AppContext/AppContext';

const set1Breaks = {
  name: { lg: 4, md: 4, sm: 6, xs: 12 },
  description: { lg: 4, md: 4, sm: 6, xs: 12 },
  actionButtons: { lg: 4, md: 4, sm: 12, xs: 12 }
};

interface IDowntimeSubCategoryListState {
  page: number;
  sortedDowntimeSubCategories: Array<any>;
  pageCount: number;
  pagedDowntimeSubCategories: Array<any>;
}
interface IDowntimeCategoryListTableProps {
  downtimeCategories: Array<any>;
  siteId: string;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const DowntimeSubCategoryListTable = ({
  downtimeCategories,
  siteId,
  setRefreshRequired }: IDowntimeCategoryListTableProps) => {
  const classes = useDowntimeSubCategoryListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';

  const gridBreak: any = set1Breaks;

  const configActions = useConfigActions();
  const { appFilters } = useAppGlobalState();

  const [filteredDowntimeSubCategories, setFilteredDowntimeSubCategories] = useState<Array<any>>([]);

  const [downtimeSubCategoryListState, setDowntimeSubCategoryListState] = useState<IDowntimeSubCategoryListState>({
    page: 1,
    sortedDowntimeSubCategories: [],
    pageCount: 0,
    pagedDowntimeSubCategories: []
  });

  const {
    page,
    sortedDowntimeSubCategories,
    pageCount,
    pagedDowntimeSubCategories
  } = downtimeSubCategoryListState;

  useEffect(() => {
    setDowntimeSubCategoryListState(s => {
      const sorted = downtimeCategories.filter(el => el.isSubcategory).sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        page: 1,
        sortedDowntimeSubCategories: sorted
      }
    })
  }, [downtimeCategories]);

  useEffect(() => {
    const searchTerm = appFilters.subcategoryFilter.searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredCategories = searchTerm
      ? sortedDowntimeSubCategories.filter(category => category.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedDowntimeSubCategories;

    setFilteredDowntimeSubCategories(initialFilteredCategories);

  }, [appFilters, sortedDowntimeSubCategories]);

  useEffect(() => {
    setDowntimeSubCategoryListState(s => {
      const count = Math.ceil(((filteredDowntimeSubCategories && filteredDowntimeSubCategories.length) || 0) / PAGE_SIZE);
      const paged = filteredDowntimeSubCategories.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedDowntimeSubCategories: paged
      }
    })
  }, [filteredDowntimeSubCategories]);

  const onPageChange = useCallback(p => {
    setDowntimeSubCategoryListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredDowntimeSubCategories.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setDowntimeSubCategoryListState(s => ({
      ...s,
      pagedDowntimeSubCategories: paged
    }));
  }, [page, filteredDowntimeSubCategories]);

  return (
    <>
      <Can do="edit" on="downtimeReason">
        <DowntimeSubCategoryBar
          siteId={siteId}
          filter={appFilters.subcategoryFilter}
          setFilter={configActions.setBarFilter}
        />
      </Can>
      <StyledContentCard>
        {
          downtimeCategories && downtimeCategories.filter(el => el.isSubcategory).length > 0 ? <StyledGrid>
            {
              appFilters.subcategoryFilter.searchTerm.length === 0 || pagedDowntimeSubCategories.length > 0 ? <StyledGridHeadRow>
                <StyledGridHeadCell {...gridBreak.name}>
                  <TableHeadText
                    textId="detail_Name"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell {...gridBreak.description}>
                  <TableHeadText
                    textId="detail_Description"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell item {...gridBreak.actionButtons}>
                </StyledGridHeadCell>
              </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
            }
            <>
              {
                pagedDowntimeSubCategories.map(category =>
                (
                  <DowntimeSubCategoryListRow
                    key={category.id}
                    siteId={siteId}
                    category={category}
                    gridBreak={gridBreak}
                    setRefreshRequired={setRefreshRequired}
                  />
                )
                )
              }
            </>
          </StyledGrid> : <EmptyItem textId="detail_noDowntimeSubCategory" />
        }
        {
          filteredDowntimeSubCategories && filteredDowntimeSubCategories.length > PAGE_SIZE ? <div className={classes.pageContainer}>
            <Paging
              page={page}
              onChange={onPageChange}
              count={pageCount}
              total={filteredDowntimeSubCategories.length} /></div> : null
        }
      </StyledContentCard>
    </>
  );
}

export default DowntimeSubCategoryListTable;
