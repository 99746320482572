import React from 'react';

import { useIntl } from 'react-intl';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useReportDownloadStyles } from './ReportDownload.css';

import {
  CloudDownload as CloudDownloadIcon,
  PictureAsPdf as PictureAsPdfIcon
} from '@material-ui/icons';

import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';

interface ReportDownloadProps {
  downloadToCsv: () => void;
  exportToPdf: () => void;
  isDownloading: boolean;
  isLoading: boolean;
}

const ReportDownload = ({
  downloadToCsv,
  exportToPdf,
  isDownloading,
  isLoading,
}: ReportDownloadProps) => {
  const intl = useIntl();
  const classes = useReportDownloadStyles();

  return <>
    <div className={classes.actionButtons}>
      {
        downloadToCsv && (
          <StyledActionButton
            color="primary"
            startIcon={<CloudDownloadIcon />}
            disabled={isLoading}
            onClick={downloadToCsv}
            label={intl.formatMessage({ id: 'detail_Download' })}
          />
        )}
      {
        exportToPdf && (
          <StyledActionButton
            color="primary"
            startIcon={<PictureAsPdfIcon />}
            disabled={isLoading}
            onClick={exportToPdf}
            label={intl.formatMessage({ id: 'detail_DownloadPdf' })}
          />
        )}
    </div>
    {
      (isLoading || isDownloading) && <ProgressIndicator />
    }
  </>
};

export { ReportDownload };
