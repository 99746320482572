import React from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useInfoDialogStyle } from './InfoDialog.css';

interface InfoDialogProps {
  title: any;
  open: boolean;
  onClose: () => void;
  content: any;
  maxWidth?: DialogProps['maxWidth'];
}

const InfoDialog = ({
  title,
  open,
  maxWidth,
  onClose,
  content }: InfoDialogProps) => {
  const classes = useInfoDialogStyle();

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'sm'}
    disableEscapeKeyDown
    aria-labelledby="info" open={open}>
    <DialogTitle id="info-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent dividers>
      {content}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}
        color="default"
        className="info-close-btn"
        variant="contained">
        <FormattedMessage id="detail_Close" />
      </Button>
    </DialogActions>
  </Dialog>;
}

export { InfoDialog };
