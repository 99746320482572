import React from "react";

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// styles
import useStyles from "./ProfileMenuItem.css";

export interface IProfileMenuItemProps {
  icon: any;
  linkLabel: string;
  onClickMenuItem: () => void;
  extraClass: string;
}

const ProfileMenuItem = ({
  icon,
  linkLabel,
  onClickMenuItem,
  extraClass
}: IProfileMenuItemProps) => {

  const classes = useStyles();

  return <ListItem button
    className={classes.link}
    onClick={onClickMenuItem}>
    <ListItemIcon className={classes.linkIcon}>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={linkLabel}
      classes={{
        primary: classes.linkText,
      }}
      className={extraClass} />
  </ListItem>

}

export { ProfileMenuItem };
