import { makeStyles } from "@material-ui/core";
import { myRed, mySecondaryColor } from "../../../constants/colors";

export const useTranslationManagementPageStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
   // maxHeight: 440,
  },
  cell: {
    borderBottom: `0.125px solid ${mySecondaryColor}`,
    '&:nth-last-child(n + 2)': {
      borderRight: `0.125px solid ${mySecondaryColor}`,
    },
    margin: theme.spacing(0.125)
  },
  warning: {
    color: myRed
  },
  paginationRoot: {
    display: 'flex'
  },
  paginationToolbar: {
    display: 'flex',
    alignItems: 'center'
  }
}));
