import { makeStyles } from "@material-ui/core";

export const useCountdownModalStyles = makeStyles((theme) => ({
  modalBody: {
    padding: theme.spacing(1, 3, '!important')
  },
  progressContainer: {
    padding: theme.spacing(0.625, 1.5)
  },
  divider: {
    margin: theme.spacing(0.75, 0)
  }
}));
