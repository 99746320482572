import { makeStyles } from "@material-ui/core";
import { widgetColor, myWhite } from "../../../../constants/colors";

export const useLineProductOverviewStyle =  makeStyles((theme) => ({
  widget: {
    backgroundColor: widgetColor,
    color: myWhite,
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    fontSize: theme.spacing(3.5),
    justifyContent: 'space-between',
    borderRadius: '10px',
    height: '100%'
  },
  title: {
    fontSize: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxHeight: theme.spacing(2),
    },
  },
  value: {
    fontSize: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      maxHeight: theme.spacing(3),
    },
    fontWeight: 600
  }
}));
