import { makeStyles } from "@material-ui/core";

export const useInspectionSetupBarStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    alignSelf: 'center',
    margin: theme.spacing(1)
  },
  csvUploadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center'
  },
  barContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    alignSelf: 'center',
    flexWrap: 'wrap'
  },
  filterContainerSmall: {
    display: 'flex',
    justifyContent: 'flex-start',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    alignSelf: 'center',
    flexWrap: 'wrap'
  },
  barItem: {
    margin: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControl: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.spacing(60),
    minWidth: theme.spacing(30),
    flexFlow: 'row',
    alignItems: 'center'
  },
  search: {
    padding: theme.spacing(1, 0.625)
  }
}));
