import { makeStyles } from "@material-ui/core";
import { standardBoxShadow } from "../../../constants/colors";

export const useSiteFormStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25, 1.875),
    margin: theme.spacing(1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: theme.spacing(2.5),
    maxWidth: theme.spacing(100),
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row',
    maxWidth: theme.spacing(80),
    alignItems: 'center',
    '&:nth-last-child(n + 2)': {
      marginBottom: theme.spacing(1.875)
    }
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(3.75)
  },
  actionButtons: {
    justifyContent: 'flex-start',
    maxWidth: theme.spacing(70)
  },
  grow: {
    flexGrow: 1,
  },
  headerContainer: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
}));
