import { makeStyles } from "@material-ui/core";
import { myGrayShade4 } from "../../constants/colors";

export const useStyledDropdownStyles = makeStyles((theme) => ({
  dimmedLabel: {
    color: myGrayShade4
  },
  checkbox: {
    padding: theme.spacing(0.25, 0.5)
  }
}));
