import React, { useEffect, useState } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import { validation } from 'vccm-common';
import toastr from 'toastr';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useDisplayModeSettingsSetupPageStyles } from '../DisplayModeSettingsSetupPage/DisplayModeSettingsSetupPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  Button,
  CardActions,
  CardContent,
  FormControl
} from '@material-ui/core';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';
import { InfoTooltip } from '../../../components/Basic/InfoTooltip/InfoTooltip';
import { useSiteActions } from '../../../actions/siteActions';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';

interface IDisplayModeSettingsSetupPage {
  match: any;
}

const DisplayModeSettingsSetupPage = ({ match }: IDisplayModeSettingsSetupPage) => {
  const classes = useDisplayModeSettingsSetupPageStyles();

  const intl = useIntl();
  const { sites } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const siteActions = useSiteActions();

  const siteId: string = match.params.siteId;
  const [delay, setDelay] = useState<number>();
  const [autoRotate, setAutoRotate] = useState<boolean>();
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [configSite, setConfigSite] = useState<any>(sites.find(el => el.id === siteId) || { dashConfig: null });
  const [dirty, setDirty] = useState(false);

  function initForm(site) {
    setConfigSite(site);
    setAutoRotate(site.dashConfig && site.dashConfig.cycleLines);
    setDelay(site.dashConfig && site.dashConfig.delay);
  }

  //if our site changes, re-init form
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const mySite = await siteActions.loadSite(siteId, true);
        if (mySite) {
          initForm(mySite);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // if state we care changes, reevaluate dirty
  useEffect(() => {
    if (configSite) {
      setDirty(!configSite.dashConfig ||
        configSite.dashConfig.cycleLines !== autoRotate
        || configSite.dashConfig.delay !== delay
      );
    }
  }, [configSite.dashConfig, autoRotate, delay]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_DisplayMode', <DashboardIcon />, {
      parents: [{
        id: 'detail_Configuration',
      }]
    });
  }, [setTitle]);

  return !isLoading && sites.length > 0 ? <>
    <StyledContentCard>
      <CardContent className={classes.content}>
        <FormControl className={classes.formControl}>
          <div>
            <FormattedMessage id="detail_AutoRotate" />
            <InfoTooltip textId='detail_AutoRotate' />
          </div>
          <div className={classes.controlContainer}>
            <StyledSwitch checked={autoRotate}
              onChange={(e, checked) => setAutoRotate(checked)}
              name="checkedAutoRotate" />
          </div>
        </FormControl>
        <div className={classes.rotate}>
          <div>
            <FormattedMessage id='detail_RotateSpeed' />
            <InfoTooltip textId='detail_RotateSpeed' />
          </div>
          <div className={classes.controlContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <div>
                <div className={classes.textLabel}>{intl.formatMessage({ id: "detail_Seconds" })}</div>
                <NumericTextBox
                  name="RotateSpeed"
                  defaultValue={validation.dashConfigLimits.default}
                  onChange={(e) => setDelay(Number(e.target.value))}
                  value={delay}
                  format="n0"
                  disabled={autoRotate !== true}
                  min={validation.dashConfigLimits.min}
                  max={validation.dashConfigLimits.max}
                />
              </div>
            </FormControl>
          </div>
        </div>
      </CardContent>
      <CardActions className={classes.actionButtons}>
        <Button
          variant="contained"
          disabled={saving || !dirty}
          onClick={() => initForm(configSite)}>
          <FormattedMessage id="detail_Cancel" />
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={saving || !dirty}
          onClick={async () => {
            try {
              const updated = await siteActions.saveSite({ ...configSite, dashConfig: { cycleLines: autoRotate, delay } }, true);
              if (updated) {
                initForm(updated);
                toastr.success(intl.formatMessage(({ id: 'detail_DisplayModeSettingsSaved' })));
              }
            } catch (e) {
              toastr.error(e);
            } finally {
              setSaving(false);
            }
          }}
        >
          <FormattedMessage id="detail_Save" />
        </Button>
      </CardActions>
    </StyledContentCard>
  </> : <ProgressIndicator full={true} />
};

export default DisplayModeSettingsSetupPage;
