import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CalendarToday as CalendarTodayIcon,
  Person as PersonIcon
} from '@material-ui/icons';
import toastr from 'toastr';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useUserManagementPageStyles } from './UserManagementPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { ChipSelector } from '../../../components/Basic/ChipSelector/ChipSelector';
import UserHelper from '../../../helpers/UserHelper';
import { getRoles, getUserRoles } from '../../../services/UserService/UserService';
import { Logger } from '../../../utilities/Logger/Logger';
import { ACTION_EDIT } from '../../../actions/actionTypes';
import { StyledDropDown } from '../../../components/StyledDropdown/StyledDropdown';
import { validation } from 'vccm-common';
import { useHistory } from 'react-router';
import { useUserActions } from '../../../actions/userActions';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import ability from '../../../ability';
interface IUserManagementPage {
  match: any;
  users: Array<any>;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const UserManagementPage = ({ users, match, setRefreshRequired }: IUserManagementPage) => {
  const classes = useUserManagementPageStyles();
  const history = useHistory();

  const userActions = useUserActions();

  const intl = useIntl();
  const { sites, isAdmin } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const siteId: string = match.params.siteId;
  const userName: string = match.params.setupParam;

  const user = users.find(el => el.Username === userName);
  const site = sites.find(el => el.id === siteId);

  const getUserEmail = () => {
    return user ? UserHelper.getEmailAddress(user) : '';
  }

  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any>([]);

  const [selectedInitialRoles, setSelectedInitialRoles] = React.useState<any>({});
  const [email, setEmail] = React.useState<string>(getUserEmail());

  const [selectedRoles, setSelectedRoles] = React.useState<any>({});
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const isEditMode = useMemo(() => {
    return match.params.action && match.params.action.toUpperCase() === ACTION_EDIT;
  }, [match.params.action]);

  useEffect(() => {
    (async () => {
      try {
        if (sites.length > 0) {
          setIsLoading(true);
          const fetchedRoles = await getRoles(siteId);
          if (fetchedRoles) {
            Logger.of('App.UserManagementPage').info('Role api roles', roles);
            if (isEditMode) {
              const userRoles = await getUserRoles(siteId, userName);
              Logger.of('App.UserManagementPage').info('Role api user roles', userRoles);
              setSelectedRoles(userRoles || {});
              setSelectedInitialRoles(userRoles ? JSON.parse(JSON.stringify(userRoles)) : {});
            }

            setRoles(fetchedRoles.filter(r => r.siteId === siteId));
            setIsLoading(false);
          } else {
            Logger.of('App.UserManagementPage').info('Role api returned no roles', siteId);
          }
        }
      } catch (e) {
        alert(e);
      }
    })();
  }, [sites]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_Users', <CalendarTodayIcon />, {
      ignoreItself: true,
      parents: [{
        id: 'detail_Configuration',
      },
      {
        id: 'detail_Users',
        link: `/site/${siteId}/setup/users`,
        icon: <PersonIcon />
      },
      {
        id: 'detail_ManageUsers',
      },
      {
        id: isEditMode ? 'detail_Edit_a_User' : 'detail_Create_a_User',
      }]
    })
  }, [setTitle, siteId, isEditMode]);

  const userAllowedRoles = !isAdmin ? roles : [...roles, ...UserHelper.addApiOrVrsRolesForAdmin(roles, site)];

  const allRolesWithAuthModules = userAllowedRoles && UserHelper.translateRoles(userAllowedRoles.map(authModule => authModule.hasOwnProperty('authModule') //eslint-disable-line no-prototype-builtins
    ? authModule : { ...authModule, authModule: "vccm" }), intl);

  const allAugmentedRolesWithAuthModules = [...allRolesWithAuthModules];
  const modules = site.modules && site.modules.values && site.modules.values;
  const allowedAuthModules = UserHelper.getAllowedAuthModules(isAdmin).map(a => a.value.toLowerCase());
  const onlyUnique = (value, index, self) => self.indexOf(value) === index;
  const uniqueModules = userAllowedRoles ? userAllowedRoles.filter(authModule => authModule.hasOwnProperty('authModule')) //eslint-disable-line no-prototype-builtins
    .map(m => m.authModule)
    .filter(onlyUnique)
    .filter(el => el !== 'vrs' || (modules && modules.indexOf('vrs') > -1))
    .filter(el => el !== 'api' || (modules && modules.indexOf('api') > -1))
    .concat(['vccm']) : [];

  const updateSelectedRole = (value, name) => {
    Logger.of('App.UserManagementPage.updateSelectedRole').info('name', name, 'value', value);
    const authName = name.replace("Select", "");
    const authValue = value === '' ? [] : Array.isArray(value) ? value : [value];

    setSelectedRoles((state) => {
      const newState = { ...state };
      if (authValue && authValue.length === 0) {
        newState[authName] = [];
      } else {
        newState[authName] = authValue;
      }
      return newState;
    });
  }

  const cancelForm = () => {
    setSelectedRoles(selectedInitialRoles ? JSON.parse(JSON.stringify(selectedInitialRoles)) : {});
    setEmail(getUserEmail());
  };

  const updateEmail = (event) => {
    setEmail(event.target.value);
  }

  useEffect(() => {
    if (!isLoading && sites.length > 0) {
      setDirty(email !== getUserEmail() || JSON.stringify(selectedInitialRoles) !== JSON.stringify(selectedRoles));
    }
  }, [email, selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps

  const userFormIsValid = () => {
    let errors = {};

    Logger.of('App.UserManagementPage.userFormIsValid').info('state.roles', roles);
    Logger.of('App.UserManagementPage.userFormIsValid').info('state.selectedRoles', selectedRoles);

    const userObj = {
      email,
      siteRoles: { [siteId]: selectedRoles }
    };

    Logger.of('App.UserManagementPage.userFormIsValid').info('user', userObj);

    const results = validation.schemas.basicUserInfo.validate(userObj, { abortEarly: false });

    if (results.error == null) {
      setErrors(errors);
      return (userObj);
    }

    errors = results.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, '_') : '';
      return agg;
    }, {});

    Logger.of('App.UserManagementPage.userFormIsValid').error('errors', errors);

    setErrors(errors);
    return false;
  }

  const closeForm = () => {
    history.push(
      `/site/${siteId}/setup/users`,
    );
  }
  const redirect = () => {
    setSaving(false);
    toastr.success(intl.formatMessage({ id: 'detail_UserSaved' }));
    closeForm();
  }

  const saveThisUser = (event) => {
    event.preventDefault();
    const site = sites.find(el => el.id === siteId);
    const validatedUser = userFormIsValid();
    Logger.of('App.UserManagementPage.saveUser').info('validatedUser', validatedUser, 'site', site);
    if (!validatedUser || !site) {
      return;
    }

    setSaving(true);

    if (isEditMode) {
      const newRoles = selectedRoles;
      userActions.assignUser(userName, site.id, user.siteRoleId || 1, newRoles)
        .then(() => {
          setRefreshRequired(true);
          redirect();
        })
        .catch((error) => {
          setSaving(false);
          if (error && error.message) {
            try {
              const err = JSON.parse(error.message);
              toastr.error(err.Message || error);
              setErrors({ [err.prop]: err.key });
            } catch {
              toastr.error(error.message);
            }
          } else {
            toastr.error(error);
          }
        });
    } else {
      const locale = navigator.language.split(/[-_]/)[0];
      const userObj = {
        email: validatedUser.email.toLowerCase(),
        siteId: site.id,
        firstName: 'firstname',
        lastName: 'lastname',
        companyId: site.companyId || 'The Company',
        companyName: site.title || 'My Site',
        locale,
        siteRoles: validatedUser.siteRoles || false, // VCCM-1347 pass roles on create of user
      };

      Logger.of('App.UserManagementPage.saveUser').info('saving user', user);
      userActions.saveUser(userObj)
        .then(() => {
          setRefreshRequired(true);
          redirect();
        })
        .catch((error) => {
          setSaving(false);
          try {
            const err = JSON.parse(error.message);
            toastr.error(err.message || error);
            Logger.of('App.UserManagementPage.saveUser').error('saving user error', (err.message || error));
            if (err.prop) {
              setErrors({ [err.prop]: err.key });

            }
          } catch {
            toastr.error(error.message);
          }
        });
    }
  }

  const displayOnlyItems = roles && selectedRoles
    && roles.filter(role => {
      return uniqueModules.forEach(mod => {
        return selectedRoles[mod] && selectedRoles[mod].indexOf(role.id) > -1
      });
    });

  const canAssignRole = ability.rules.find(r => (r.actions === 'vrs' && r.subject === 'authModule' && r.fields && r.fields.includes('Administrator'))
    || (r.actions === 'assign' && r.subject === 'user-role'))
    || false;

  return <>
    {!isLoading && sites.length > 0 && <StyledContentCard>
      <CardContent className={classes.content}>
        <div className={classes.title}>
          <Typography variant="h5">{intl.formatMessage({ id: isEditMode ? 'detail_Edit_a_User' : 'detail_Create_a_User' })}</Typography>
        </div>
        <FormControl className={classes.formControl}>
          <TextField
            name="userEmail"
            variant="outlined"
            label={intl.formatMessage({ id: "detail_Email" })}
            value={email}
            onChange={updateEmail}
            disabled={isEditMode}
            fullWidth={true}
            error={!!errors.email}
            helperText={errors.email && intl.formatMessage({ id: 'detail_Emailmustbevalid' })}
          />
        </FormControl>
        <>
          <div className={classes.title}>
            <Typography variant="h5">{intl.formatMessage({ id: 'detail_Modules' })}</Typography>
          </div>
          {canAssignRole ? uniqueModules.map(authModule => allowedAuthModules.includes(authModule) &&
            <FormControl variant="outlined" className={classes.formControl} key={authModule}>
              {
                authModule !== 'vrs' && authModule !== 'api' ? <ChipSelector
                  id={'roleContent'}
                  name={`${authModule}Select`}
                  can={true}
                  label={authModule}
                  onChange={(value) => updateSelectedRole(value, authModule)}
                  placeholder={intl.formatMessage({ id: 'detail_No_Access' })}
                  options={allAugmentedRolesWithAuthModules.filter(r => r.authModule === authModule).map(el => ({
                    value: el.id,
                    text: el.title
                  }))}
                  value={(selectedRoles && selectedRoles[authModule]) || []} />
                  : <StyledDropDown
                    multiple={false}
                    name={`${authModule}Select`}
                    label={authModule}
                    enableRemoveButton={true}
                    onChange={(value) => updateSelectedRole(value, authModule)}
                    placeholder={intl.formatMessage({ id: 'detail_No_Access' })}
                    options={allAugmentedRolesWithAuthModules.filter(r => r.authModule === authModule).map(el => ({
                      value: el.id,
                      text: el.title
                    }))}
                    value={(selectedRoles && selectedRoles[authModule] && selectedRoles[authModule].length > 0) ? selectedRoles[authModule][0] : ''} />
              }
            </FormControl>
          ) : displayOnlyItems ? displayOnlyItems.map(role =>
            <div
              id={`lbl${role.title}`}
              key={role}>{role.title}</div>) : null
          }
          {
            errors.roles && <div className={classes.error}>{errors.roles}</div>
          }
          {
            Object.keys(errors).find(el => el === siteId) && <div className={classes.error}>{errors[siteId]}</div>
          }
        </>
      </CardContent>
      <CardActions className={classes.actionButtons}>
        <Button
          variant="contained"
          disabled={saving || !dirty}
          onClick={cancelForm}>
          <FormattedMessage id="detail_Cancel" />
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={saving || !dirty}
          onClick={async (event) => {
            try {
              saveThisUser(event);
            } catch (e) {
              toastr.error(e);
            }
          }}
        >
          <FormattedMessage id="detail_Save" />
        </Button>
        <div className={classes.grow} />
        <Button
          variant="contained"
          onClick={closeForm}>
          <FormattedMessage id="detail_Close" />
        </Button>
      </CardActions>
    </StyledContentCard>
    }
    {
      (isLoading || saving) && <ProgressIndicator />
    }
  </>

};

export default UserManagementPage;
