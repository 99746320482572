import React from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDeleteDialogStyle } from './DeleteDialog.css';

interface IDeleteDialogProps {
  title: any;
  open: boolean;
  onClose: (value: boolean) => void;
  content?: any;
  extraContent?: any;
  saving?: boolean;
  maxWidth?: DialogProps['maxWidth'];
}

const DeleteDialog = ({ title, open, maxWidth, onClose, content, extraContent, saving }: IDeleteDialogProps) => {
  const classes = useDeleteDialogStyle();
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'xs'}
    disableEscapeKeyDown
    aria-labelledby="delete" open={open}>
    <DialogTitle id="delete-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent dividers>
      {content || <><FormattedMessage id="detail_Are_you_sure_you_want_to_delete" />{'?'}</>}
      {extraContent}
    </DialogContent>
    <DialogActions>
      <Button autoFocus
        onClick={handleOk}
        className="delete-ok-btn"
        variant="contained"
        disabled={saving}
        color="primary">
        <FormattedMessage id="detail_Yes" />
      </Button>
      <Button onClick={handleCancel}
        color="default"
        className="delete-cancel-btn"
        disabled={saving}
        variant="contained">
        <FormattedMessage id="detail_No" />
      </Button>
    </DialogActions>
  </Dialog>;
}

export { DeleteDialog };
