import { makeStyles } from "@material-ui/core";

export const useUserSetupPageStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap'
  },
  lineProgress: {
    height: '100vh'
  },
  tabIcon: {
    marginRight: theme.spacing(1.25),
    verticalAlign: 'middle',
    marginTop: theme.spacing(0.625),
  }
}));
