import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import {
  useAppGlobalState,
  useAppLineState,
  useAppSelectedSite,
  useAppShiftState
} from '../../../context/AppContext/AppContext';
import { useSchedulerPageStyles } from './SchedulerPage.css';
import Utils from '../../../utilities/utils';
import qstr from 'query-string';
import { useHistory, useParams } from 'react-router';

import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  AgendaView,
  SchedulerSlot,
  SchedulerEditItem,
  SchedulerEditSlot
} from '@progress/kendo-react-scheduler';
import { useIntl } from 'react-intl';
import {
  LibraryBooks as LibraryBooksIcon,
  Build as BuildIcon,
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon,
  HourglassEmpty as HourglassEmptyIcon
} from "@material-ui/icons";
import PageTabs from '../../../components/PageTabs/PageTabs';
import { scheduleTypes } from '../../../helpers/SchedulerHelper/SchedulerHelper';
import ShiftSetupPage from '../shifts/ShiftSetupPage/ShiftSetupPage';
import ReportNotificationSetupPage from '../ReportNotificationSetupPage/ReportNotificationSetupPage';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { ShiftDelete } from '../shifts/ShiftDelete/ShiftDelete';
import { useShiftActions } from '../../../actions/shiftActions';
import { useLineActions } from '../../../actions/lineActions';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import StyledTab from '../../../components/Basic/StyledTab/StyledTab';
import StyledTabs from '../../../components/Basic/StyledTabs/StyledTabs';


const tabIds = ['detail_Scheduler', 'detail_Shifts', 'detail_PlannedDowntimes', 'detail_ReportScheduler'];

const CustomEditItem = (props: any) => {
  const history = useHistory();
  const { selectedSiteId } = useAppGlobalState();

  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [toBeDeletedShift, setToBeDeletedShift] = useState<any>({ title: '' });

  const setDeleteParams = (args) => {
    setDeleteConfirmVisible(true);
    setToBeDeletedShift(args.shift || { title: '' })
  }

  return <>
    {
      !isDeleteConfirmVisible && <SchedulerEditItem {...props}
        onRemoveClick={(event) => {
          // redirect to our delete
          setDeleteParams({
            shift: event.target.props.dataItem
          });
        }}
        onFormItemChange={(args) => {
          // save the item to be edited. We will use on redirect
          if (args.value) {
            //  setState({ eventEdited: args.value }, () => redirect());
            redirect({
              type: 'edit',
              history,
              siteId: selectedSiteId,
              eventEdited: args.value
            });
          }
        }}
      // DE-630 we don't support the editing of an occurrence, controlling the state of the modal seems to work to bypass it too
      // showOccurrenceDialog={false}

      />
    }
    <ShiftDelete
      shift={toBeDeletedShift}
      isDeleteConfirmVisible={isDeleteConfirmVisible}
      setDeleteConfirmVisible={setDeleteConfirmVisible} />
  </>
}

const CustomSlot = (props: any) => {
  const history = useHistory();
  const { selectedSiteId } = useAppGlobalState();
  const { eventEdited } = props;
  const doubleClickHandler = React.useCallback(
    (event) => {
      const p = event?.target?.props;
      redirect({
        type: 'add',
        history, siteId: selectedSiteId,
        eventEdited,
        startTime: p?.start?.toISOString(),
        endTime: p?.end?.toISOString()
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <SchedulerSlot
      {...props}
      onDoubleClick={doubleClickHandler}
    />
  )
}

const CustomEditSlot = (props) => {
  const history = useHistory();
  const { selectedSiteId } = useAppGlobalState();
  const doubleClickHandler = React.useCallback(
    (event) => {
      const p = event?.target?.props;
      redirect({
        type: 'add',
        history,
        siteId: selectedSiteId,
        startTime: p?.start?.toISOString(),
        endTime: p?.end?.toISOString()
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <SchedulerEditSlot
      {...props}
      onDoubleClick={doubleClickHandler}
    />
  )
}

const redirect = (args: any = null) => {
  const { type, siteId, eventEdited, history, ...rest } = args ?? {} as any;
  if (type === 'add') {
    history.push(`/site/${siteId}/setup/shift/0/add/1/1?${qstr.stringify(rest)}`);
  } else if (type === 'delete') {
    history.push(`/site/${siteId}/setup/shift/${eventEdited.id}/delete/${eventEdited.typeId}/1`);
  }
  else if (eventEdited) {
    history.push(`/site/${siteId}/setup/shift/${eventEdited.id}/edit/${eventEdited.typeId}/1`);
  }
}

const SchedulerPage = (props: any) => {
  const intl = useIntl();
  const history = useHistory();
  const setTitle = useSetTitleAction();
  const classes = useSchedulerPageStyles();

  const { shiftPage } = useParams<{ shiftPage: string }>();

  const { selectedSiteId } = useAppGlobalState();
  const selectedSite = useAppSelectedSite();
  const [activeTabIndex, setActiveTabIndex] = useState(shiftPage ? Number.parseInt(shiftPage) : 0);

  const shiftActions = useShiftActions();
  const lineActions = useLineActions();
  const currentShifts = useAppShiftState();
  const currentLines = useAppLineState();

  const [schedulerEvents, setSchedulerEvents] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultShifts, setResultShifts] = useState<Array<any>>([]);
  const [resultDowntimes, setResultDowntimes] = useState<Array<any>>([]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([shiftActions.loadShifts(selectedSiteId),
    lineActions.loadLines(selectedSiteId)])
      .catch((error) => {
        toastr.error(error);
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const shifts = [...currentShifts];

    const schedulerFormattedShifts = shifts.map(shift => ({
      id: shift.id,
      companyId: shift.companyId,
      createdAt: shift.createdAt,
      siteId: shift.siteId,
      title: shift.title,
      description: shift.description,
      lineIds: shift.lineIds ? Array.from(shift.lineIds) : [],
      typeId: shift.typeId,
      start: Utils.getLocalSiteDateObject(shift.startTime, selectedSite.tz),
      end: Utils.getLocalSiteDateObject(shift.endTime, selectedSite.tz),
      dayLightCorrectionDifference: shift.dayLightCorrectionDifference,
      //recurrenceId: shift.recurrenceId,
      recurrenceRule: shift.recurrenceRule,
      recurrenceExceptions: shift.recurrenceException,
      isAllDay: shift.isAllDay,
      isDeleted: shift.isDeleted,
    }));
    setSchedulerEvents(schedulerFormattedShifts);
  }, [currentShifts, currentLines, selectedSite]);

  useEffect(() => {
    setTitle('detail_Shifts', <BuildIcon />, {
      ignoreItself: true,
      parents: [{
        id: 'detail_Configuration',
      }, {
        id: 'detail_Shifts',
      },
      {
        id: tabIds[activeTabIndex],
      }]
    });
  }, [setTitle, activeTabIndex]);

  useEffect(() => {
    history.replace(`/site/${selectedSiteId}/shifts/${activeTabIndex}`);
  }, [activeTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const can = true;
  const resources = [{
    colorField: 'color', // {string} The field name from the data that contains the resource color.
    data: scheduleTypes.map(st => ({ ...st, text: intl.formatMessage({ id: `detail_${st.text}` }) })),// {any[]} The resource data.
    multiple: false,    // Specifies if the events have multiple resource values.
    field: 'typeId',    // {string} The field name of the event that contains the resource value.
    name: intl.formatMessage({ id: 'detail_Type' }),       // {string} The resource name. If not set, the value of the field option is used.
    textField: 'text',  // {string} The field name from the data that contains the resource text.
    valueField: 'value'// {string} The field name from the data that contains the resource value.
  }, {
    colorField: 'color',
    data: currentLines,
    multiple: true,
    field: 'lineIds',
    name: intl.formatMessage({ id: 'detail_Lines' }),
    textField: 'title',
    valueField: 'id',
  }];

  useEffect(() => {
    if (currentShifts) {
      setResultShifts(currentShifts.filter(shift => shift.typeId === 1));
      setResultDowntimes(currentShifts.filter(shift => shift.typeId !== 1));
    }
  }, [currentShifts]); // eslint-disable-line react-hooks/exhaustive-deps

  const nowInSite = Utils.getLocalSiteDateObject(new Date(), selectedSite.tz);

  return !isLoading ? <>
    <PageTabs>
      <StyledTabs
        value={activeTabIndex}
        onChange={(e, index) => setActiveTabIndex(index)}
        indicatorColor="secondary"
        textColor="primary"
      >
        <StyledTab icon={<CalendarTodayIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_Scheduler' })} />
        <StyledTab icon={<AssignmentIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_Shifts' })} />
        <StyledTab icon={<HourglassEmptyIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_PlannedDowntimes' })} />
        <StyledTab icon={<LibraryBooksIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_ReportScheduler' })} />
      </StyledTabs>
    </PageTabs>

    {
      activeTabIndex === 0 && <Scheduler
        data={schedulerEvents}
        defaultDate={nowInSite}
        editable={{
          add: can,
          remove: can,
          drag: false,
          resize: can,
          edit: can
        } as any}
        editItem={CustomEditItem}
        editSlot={CustomEditSlot}
        defaultView={'week'}
        resources={resources}
        slot={CustomSlot}
        modelFields={{
          id: 'id',
          companyId: 'companyId',
          createdAt: 'createdAt',
          lineIds: 'lineIds',
          title: 'title',
          recurrenceId: 'recurrenceId',
          recurrenceExceptions: 'recurrenceException',
          recurrenceRule: 'recurrenceRule',
          siteId: 'siteId',
          typeId: 'typeId',
        } as any}
      >
        <DayView endTime={"23:59"} showWorkHours={false} startTime={"00:00"} />
        <WeekView endTime={"23:59"} showWorkHours={false} startTime={"00:00"} />
        <MonthView />
        <AgendaView />
      </Scheduler>
    }
    {
      activeTabIndex === 1 && <ShiftSetupPage
        shifts={resultShifts}
        lines={currentLines}
        siteId={selectedSiteId}
        isShift={true}
        timezone={selectedSite.tz}
      />
    }
    {
      activeTabIndex === 2 && <ShiftSetupPage
        shifts={resultDowntimes}
        lines={currentLines}
        siteId={selectedSiteId}
        isShift={false}
        timezone={selectedSite.tz}
      />
    }
    {
      activeTabIndex === 3 && <ReportNotificationSetupPage
        siteId={selectedSiteId} />
    }
  </>
    :
    <ProgressIndicator />
}

export { SchedulerPage }
