import { invokeApig } from '../libs/awsLib';

const apiUrl = '/roles';

class RoleApi {

  static list(siteId = '') {
    return invokeApig({ gatewayName: 'ROLES', path: apiUrl, queryParams: (siteId ? { 'siteId': siteId } : {}) });
  }

  static create(role) {
    return invokeApig({ gatewayName: 'ROLES', method: 'POST', path: apiUrl, body: role });
  }

  static get(roleId, siteId) {
    return invokeApig({ gatewayName: 'ROLES', path: `${apiUrl}/${roleId}`, queryParams: { 'siteId': siteId } });
  }

  static getAbilities() {
    return invokeApig({ gatewayName: 'ROLES', path: `${apiUrl}/abilities` });
  }

  static getUserRoles(siteId, userId) {
    return invokeApig({ gatewayName: 'ROLES', path: `${apiUrl}/user/${userId}`, queryParams: (siteId ? { 'siteId': siteId } : {}) });
  }

  static update(role) {
    return invokeApig({ gatewayName: 'ROLES', method: 'PUT', path: `${apiUrl}/${(role && role.id)}`, body: role });
  }

  static delete(role, siteId) {
    return invokeApig({ gatewayName: 'ROLES', method: 'DELETE', path: `${apiUrl}/${(role && role.id)}`, queryParams: { 'siteId': siteId } });
  }

}

export default RoleApi;
