import React from 'react';
import {
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import classNames from 'classnames';

import { Clear } from '@material-ui/icons';
import { useSimpleDropdownStyles } from './SimpleDropdown.css';
import StyledSelect from '../StyledSelector/StyledSelector';
import { useIntl } from 'react-intl';
import { useGlobalStyles } from '../../../constants/globalstyles';

interface SimpleDropdownProps {
  id?: string;
  disabled?: boolean;
  required?: boolean;
  labelId?: string;
  label: string;
  name: string;
  placeholder: string;
  options: Array<any>;
  value: any;
  onChange: any;
  canRemoveAll: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
}

const SimpleDropdown = ({
  id,
  disabled,
  required,
  labelId,
  label,
  name,
  placeholder,
  options,
  value,
  onChange,
  canRemoveAll,
  error,
  helperText,
  className }: SimpleDropdownProps) => {
  const globalStyle = useGlobalStyles();
  const classes = useSimpleDropdownStyles();
  const intl = useIntl();
  const hasAnyValue = (): boolean => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    if (String(value).length > 0) {
      return true;
    }

    return false;
  };

  const removeAll = () => {
    onChange('');
  };

  const noOptionAvailableText = options && options.length === 0 ? ` (${intl.formatMessage({ id: 'detail_NoItemExitsToSelect' })})` : '';

  const iconComponent: any = !noOptionAvailableText && canRemoveAll ?
    { IconComponent: () => <IconButton onClick={removeAll}><Clear /></IconButton> }  /* eslint-disable-line react/display-name */
    : {};

  return <>
    <InputLabel className={classNames({
      [classes.dimmedLabel]: !required && !error && !hasAnyValue(),
      [globalStyle.required]: required && error && !hasAnyValue()
    })} id={labelId}>{`${label}${noOptionAvailableText}`}</InputLabel>
    <StyledSelect
      id={id}
      required={required}
      multiple={false}
      name={name}
      value={value}
      label={label || noOptionAvailableText ? `${label}${noOptionAvailableText}` : undefined}
      onChange={(event) => onChange(event.target.value)}
      {...iconComponent}
      placeholder={placeholder}
      disabled={(options && options.length === 0) || disabled}
      classes={{ root: className ? className : classes.root }}
      error={error}
    >
      {
        options && options.map((el) => {
          return <MenuItem value={el.value} key={el.value}>
            {
              el.icon ? <div className={classes.valueContainer}>
                <div className={classes.icon}>{el.icon}</div>
                <ListItemText primary={el.text} classes={{ root: classes.itemText }} />
              </div> : <ListItemText primary={el.text} classes={{ root: classes.itemText }} />
            }

          </MenuItem>
        })
      }
    </StyledSelect>
    {helperText && <div className={classNames(classes.errorText, globalStyle.required)}>{helperText}</div>}
  </>
}

export { SimpleDropdown }
