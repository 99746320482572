import React from 'react';
import util from 'util';
import ReportManagerListTable from '../ReportManagerListTable/ReportManagerListTable';

// TODO - move usage back to UserHelper when we can sync Dev to QA
function getUserEmailFromList(users, userId) {
  let email = '';
  const found = users && users.find(u => u.Username === userId);
  if (found) {
    email = found.Attributes.find(att => att.Name === 'email').Value;
  }
  return email;
}

function getAssocScheduleDistributedBy(schedules, item) {
  if (Array.isArray(schedules) && item) {
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].siteReports && schedules[i].siteReports.includes(item.title)) {
        return schedules[i].distributedBy;
      }
    }
  }
  return false;
}

function setDistributedBy(context, users, reports, schedules) {
  console.debug(`App.ReportManager.setDistributedBy start users:${users && users.length} reports:${reports && reports.length} schedules:${schedules && schedules.length}`);
  if (context === 'global-report' && (users && users.length) && (reports && reports.length) && (schedules && schedules.length)) {
    console.debug('App.ReportManager.setDistributedBy', util.inspect({ users, reports, schedules }, false, 4));
    reports.forEach((r) => {
      const distributedBy = getAssocScheduleDistributedBy(schedules, r);
      r.distributedBy = distributedBy;
      r.distributedBySort = getUserEmailFromList(users, distributedBy);
    });
  }
}
interface ReportManagerProps {
  editAction: string;
  editContext: string;
  reports: Array<any>;
  schedules: any;
  header: any;
  onChange: any;
  site: any;
  users: Array<any>;
  userId: string;
  isLocal: boolean;
}

const ReportManager = ({ reports,
  schedules,
  header,
  onChange,
  editAction,
  editContext,
  userId,
  users,
  isLocal }: ReportManagerProps) => {

  const displayedReports = reports;

  setDistributedBy(editContext, users, displayedReports, schedules);

  return displayedReports && displayedReports.length > 0 ? <ReportManagerListTable
    header={header}
    editContext={editContext}
    editAction={editAction}
    displayedReports={displayedReports}
    schedules={schedules}
    userId={userId}
    isLocal={isLocal}
    onReportChange={(newReports: any) => onChange(newReports || reports)}
  /> : null
};

// noinspection JSUnusedGlobalSymbols
export default ReportManager;
