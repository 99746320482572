import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { useLineProductOverviewStyle } from './LineProductOverview.css';
import { Card, Grid } from '@material-ui/core';
import { getOeeHoursWithinShifts } from '../../../../utilities/oeeCalculations';

const LineProductOverview = ({ currentShifts, lines }) => {
  const intl = useIntl();
  const classes = useLineProductOverviewStyle();
  const oeeHoursToDisplay = getOeeHoursWithinShifts(currentShifts, lines);

  const sum = a => a.reduce((x, y) => x + y, 0);
  const totalAmount = Math.round(sum(oeeHoursToDisplay.map(x => Number(x.count ? x.count : 0))));
  return (
    <Card className={classes.widget}>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          {intl.formatMessage({ id: 'detail_Product_Count' })}
        </Grid>
        <Grid item xs={4}>
          {totalAmount.toLocaleString('en')}
        </Grid>
      </Grid>
    </Card>
  );
};

LineProductOverview.propTypes = {
  currentShifts: PropTypes.array,
  lines: PropTypes.array,
};

LineProductOverview.defaultProps = {
  currentShifts: [],
  lines: [],
};

export default LineProductOverview;
