import { makeStyles } from "@material-ui/core";
import { myBlack } from "../../../../constants/colors";

export const useDayLineMapLoaderStyles = makeStyles((theme) => ({
  noShiftFound: {
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: myBlack,
    opacity: .15,
    zIndex: 999999
  }
}));
