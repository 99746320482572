import React from 'react';

import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';
import { useIntl } from 'react-intl';
import { wholeRow } from '../../../constants/global';

interface IProductImportListRow {
  processed: boolean;
  product: any;
  isDev: boolean;
  insightUser: boolean;
  gridBreak: any;
  setSelected: (id, value: boolean) => void;
}

const ProductImportListRow = ({ product,
  isDev,
  insightUser,
  gridBreak,
  setSelected,
  processed }: IProductImportListRow) => {
  const intl = useIntl();

  const getProductStyle = () => {
    if (product.insertStatus !== undefined) {
      return product.insertStatus === true
        ? { backgroundColor: 'Honeydew' }
        : { backgroundColor: 'MistyRose' };
    }

    return {};
  }

  const productStyle = getProductStyle();

  return <>
    <StyledGridRow style={productStyle}>
      <StyledGridCell {...gridBreak.selected} >
        <StyledSwitch checked={product.selected}
          disabled={processed}
          onChange={(e, checked) => setSelected(product['Product Title'], checked)}
          name="selected" />
      </StyledGridCell>
      <StyledGridCell {...gridBreak.title} >
        {product['Product Title']}
      </StyledGridCell>
      <StyledGridCell {...gridBreak.description} >
        {product['Product Description']}
      </StyledGridCell>
      {
        isDev && <StyledGridCell {...gridBreak.productId} >
          <div>
            {product['Product Id']}
          </div>
        </StyledGridCell>
      }
      {insightUser && <>
        <StyledGridCell {...gridBreak.runRate} >
          {product['Run Rate units/hour']}
        </StyledGridCell>
        <StyledGridCell {...gridBreak.bestRate} >
          {product['Best Rate units/hour']}
        </StyledGridCell>

        <StyledGridCell {...gridBreak.multiplier} >
          {product.Multiplier}
        </StyledGridCell>
      </>
      }
    </StyledGridRow>
    {product.insertError
      && (
        <StyledGridRow style={productStyle}>
          <StyledGridCell {...wholeRow} >
            {intl.formatMessage({ id: 'detail_Error_Unable_to_complete_operation' })}
          </StyledGridCell>
        </StyledGridRow>
      )
    }
  </>
};

export default ProductImportListRow;
