import { makeStyles } from "@material-ui/core";
import { DISPLAY_COLORS } from "../../../../constants/colors";

export const useLineStatusWidgetStyles = makeStyles(() => ({
  statusColor: {
    flexGrow: 1,
  },
  status: {
    fontSize: '64px',
    marginRight: '10px',
    textAlign: 'center'
  },
  afterMarker: {
    color: 'transparent'
  },
  plannedMarker: {
    color: DISPLAY_COLORS.yellow
  },
  unplannedMarker: {
    color: DISPLAY_COLORS.orange
  },
  runningMarker: {
    color: DISPLAY_COLORS.green
  },
  maintenanceMarker: {
    color: DISPLAY_COLORS.yellow
  },
  breakMarker: {
    color: DISPLAY_COLORS.yellow
  },
  stoppedMarker: {
    color: DISPLAY_COLORS.red
  }
}));
