import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Typography
} from '@material-ui/core';

import { SupervisedUserCircle as SupervisedUserCircleIcon } from '@material-ui/icons';

import { getCurrentUser } from '../../../libs/awsLib';
import { licensedModuleInfo } from '../../../constants/global';
import { USAGE_PLAN_TYPES } from '../../../enums/Enums';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useSiteActions } from '../../../actions/siteActions';

import { useAdminPageStyles } from './AdminPage.css'

import { SimpleDropdown } from '../../../components/Basic/SimpleDropdown/SimpleDropdown';
import { ChipSelector } from '../../../components/Basic/ChipSelector/ChipSelector';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';

const AdminPage = () => {
  const classes = useAdminPageStyles();
  const siteActions = useSiteActions();
  const { siteId } = useParams<{ siteId: string }>();

  const intl = useIntl();
  const { sites } = useAppGlobalState();
  const setTitle = useSetTitleAction();

  const site = sites.find(el => el.id === siteId)

  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [siteUsagePlanType, setSiteUsagePlanType] = useState<USAGE_PLAN_TYPES>(site.usagePlanType || USAGE_PLAN_TYPES.defaultUsage);
  const [siteOriginalUsagePlanType, setSiteOriginalUsagePlanType] = useState<USAGE_PLAN_TYPES>(site.usagePlanType || USAGE_PLAN_TYPES.defaultUsage);

  const usagePlanTypeOptions = Object.keys(USAGE_PLAN_TYPES).map(key => ({ value: key, text: intl.formatMessage({ id: `detail_${key}` }) }));

  const initialiseConfigSite = () => {
    if (site && site.modules) {
      return site;
    }

    if (site && !site.modules) {
      return { ...site, modules: { values: [] } };
    }

    return { modules: { values: [] } };
  }

  const [configSite, setConfigSite] = useState<any>(initialiseConfigSite());
  const [dirty, setDirty] = useState(false);

  const initialOptionModule = () => {
    const options = Object.keys(licensedModuleInfo).map(key => {
      const licensedModule = licensedModuleInfo[key];
      return {
        text: licensedModule.name,
        value: key
      }
    });

    return options;
  }

  const initialSelectedOptionModule = (site) => {
    const optionValue = site.modules ? site.modules.values : [];
    const selectedValues = Object.keys(licensedModuleInfo).filter(key => {
      const licensedModule = licensedModuleInfo[key];
      return optionValue && optionValue.indexOf(licensedModule.name) > -1;
    });

    return selectedValues;
  }

  const [siteModules] = useState<Array<any>>(initialOptionModule());
  const [selectedSiteModules, setSelectedSiteModules] = useState<Array<any>>(initialSelectedOptionModule(configSite));

  function initForm(site) {
    setSelectedSiteModules(initialSelectedOptionModule(site));
    setSiteUsagePlanType(site.usagePlanType || USAGE_PLAN_TYPES.defaultUsage);
  }

  function onModulesChanged(value) {
    setSelectedSiteModules(value);
  }

  //if our site changes, re-init form
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        initForm(site);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // if state we care changes, reevaluate dirty
  useEffect(() => {
    if (configSite) {
      const selectedNames = selectedSiteModules.map(el => licensedModuleInfo[el].name);
      const moduleValues = configSite.modules.values || [];
      const isDifferent = moduleValues.length !== selectedNames.length
        || moduleValues.filter(el => selectedNames.indexOf(el) === -1).length > 0
        || selectedNames.filter(el => moduleValues.indexOf(el) === -1).length > 0
        || siteUsagePlanType !== siteOriginalUsagePlanType
      setDirty(isDifferent);
    }
  }, [configSite.modules, selectedSiteModules, siteUsagePlanType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_AdminSiteConfiguration', <SupervisedUserCircleIcon />, {
      parents: [{
        id: 'detail_Administrator',
      }]
    });
  }, [setTitle]);

  const saveSite = async () => {
    try {
      const selectedNames = selectedSiteModules.map(el => licensedModuleInfo[el].name);
      const siteWithModules = {
        ...configSite,
        usagePlanType: siteUsagePlanType,
        modules: {
          ...configSite.modules,
          values: selectedNames
        }
      };
      setSaving(true);
      const updated = await siteActions.saveSite(siteWithModules, true);
      if (updated) {
        const newSite = {
          ...configSite,
          usagePlanType: siteUsagePlanType,
          modules: { ...configSite.modules, values: updated.modules }
        };
        setConfigSite(newSite);
        setSiteOriginalUsagePlanType(newSite.usagePlanType || USAGE_PLAN_TYPES.defaultUsage);
        initForm(newSite);
        toastr.success(intl.formatMessage(({ id: 'detail_SiteModulesSaved' })));
        siteActions.loadSite(siteId, true).then(() => {
          const currentUser = getCurrentUser();
          siteActions.setSite(siteId, currentUser);
        });
      }
    } catch (e) {
      toastr.error(e);
    } finally {
      setSaving(false);
    }
  }

  return !isLoading && sites.length > 0 ? <>
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.title}>
          <Typography variant="h5">{intl.formatMessage({ id: 'detail_AdminPageTitle' })}</Typography>
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <ChipSelector
            labelId="sideModules"
            name="sideModules"
            label={intl.formatMessage({ id: 'detail_SiteModules' })}
            placeholder={intl.formatMessage({ id: 'detail_SiteModules' })}
            options={siteModules}
            value={selectedSiteModules}
            onChange={onModulesChanged}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <SimpleDropdown
            id={'usagePlanType'}
            name="usagePlanType"
            canRemoveAll={false}
            label={intl.formatMessage({ id: 'detail_ApiUsagePlan' })}
            onChange={setSiteUsagePlanType}
            placeholder={intl.formatMessage({ id: 'detail_ApiUsagePlan' })}
            options={usagePlanTypeOptions}
            value={siteUsagePlanType} />
        </FormControl>
      </CardContent>
      <CardActions className={classes.actionButtons}>
        <Button
          variant="contained"
          disabled={saving || !dirty}
          onClick={() => {
            setSelectedSiteModules(initialSelectedOptionModule(configSite));
            setSiteUsagePlanType(siteOriginalUsagePlanType);
          }}>
          <FormattedMessage id="detail_Cancel" />
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={saving || !dirty}
          onClick={saveSite}
        >
          <FormattedMessage id={saving ? 'detail_Saving' : 'detail_Save'} />
        </Button>
      </CardActions>
    </Card>
  </> :
    <div className={classes.progress}>
      <ProgressIndicator />
    </div>
};

export { AdminPage };
