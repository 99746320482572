import { makeStyles } from "@material-ui/core";
import { mySecondaryColorDimmed } from "../../../../constants/colors";

export const useLinePerformanceListTableStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  container: {
    width: '100%',
    padding: theme.spacing(1, 2)
  },
  titleContainer: {
    paddingBottom: theme.spacing(1)
  },
  chartContainer: {
    padding: theme.spacing(1, 2),
    borderLeft: `1px solid ${mySecondaryColorDimmed}`,
    borderRight: `1px solid ${mySecondaryColorDimmed}`
  },
  separatorContainer: {
    minHeight: theme.spacing(2.5),
    borderLeft: `1px solid ${mySecondaryColorDimmed}`,
    borderRight: `1px solid ${mySecondaryColorDimmed}`,
    borderBottom: `1px solid ${mySecondaryColorDimmed}`,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    marginBottom: theme.spacing(1.25)
  },
  noLineRunning: {
    padding: theme.spacing(4)
  },
  lineName: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2)
    },
  }
}));
