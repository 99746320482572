import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getCurrentUser } from '../../../libs/awsLib';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import UserRolesListTable from '../UserRolesListTable/UserRolesListTable';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';
import { useUserActions } from '../../../actions/userActions';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useUserListRowStyles } from './UserListRow.css';
import ability from '../../../ability';

interface IUserListRow {
  user: any;
  roles: any;
  site: any;
  gridBreak: any;
  ignoreVccmDisplay: boolean;
  selectedAuthModules: Array<any>;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const UserListRow = ({
  user,
  roles,
  site,
  selectedAuthModules,
  setRefreshRequired,
  gridBreak,
  ignoreVccmDisplay }: IUserListRow) => {
  const intl = useIntl();
  const classes = useUserListRowStyles();
  const userActions = useUserActions();

  const [userRolesToDisplay, setUserRolesToDisplay] = useState<Array<any>>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    let rolesToDisplay: Array<any> = [];
    if (user.authRoles && roles) {
      rolesToDisplay = user.authRoles.map(ar => ({
        authModule: ar.authModule,
        siteRoles: roles.filter(role => (ar[site.id] || []).indexOf(role.id) > -1).map(role => role.title)
      })).filter(el => selectedAuthModules.length === 0 || selectedAuthModules.indexOf(el.authModule) > -1);
    }

    // check for site admin roles
    if (!ignoreVccmDisplay && site.siteAdmins && site.siteAdmins.values.find(id => id === user.Username)) {
      let vccmAuthModule = rolesToDisplay.find(m => m.authModule === 'vccm') || ({ authModule: 'vccm' });
      if (!vccmAuthModule) {
        vccmAuthModule = { authModule: 'vccm', siteRoles: [] };
        rolesToDisplay.push(vccmAuthModule);
      }
      if (!vccmAuthModule.siteRoles) {
        vccmAuthModule.siteRoles = [];
      }
      vccmAuthModule.siteRoles.push(intl.formatMessage({ id: 'detail_SiteAdmin' }));
    }
    setUserRolesToDisplay(rolesToDisplay);
  }, [user.authRoles, roles, site.siteAdmins]); // eslint-disable-line react-hooks/exhaustive-deps

  const canDelete = ability.rules.find(r => (r.actions === 'vrs' && r.subject === 'authModule' && r.fields && r.fields.includes('Administrator')) || (r.actions === 'delete' && r.subject === 'user')) || false;

  const [isUserDeleteConfirmVisible, setUserDeleteConfirmVisible] = useState(false);

  const onCloseUserDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);
        userActions.deleteUser(site.id, user.Username)
          .then(() => {
            toastr.success(intl.formatMessage({ id: 'detail_UserDeleted' }));
            setSaving(false);
            setRefreshRequired(true);
          })
          .catch((error) => {
            toastr.error(error);
            setSaving(false);
          });
      } catch (e) {
        toastr.error(e.toString());
      }

    }
    setUserDeleteConfirmVisible(false);
  }

  const currentUser = getCurrentUser();
  const currentUserName = currentUser ? currentUser['username'] : '';

  return (<>
    <StyledGridRow>
      <StyledGridCell {...gridBreak.email}>
        <div><strong>{user.email}</strong></div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.enabled}>
        <div>{user.Enabled ? intl.formatMessage({ id: 'detail_True' }) : intl.formatMessage({ id: 'detail_False' })}</div>
      </StyledGridCell>

      <StyledGridCell {...gridBreak.status}>
        <div className={classes.limitedText}>{intl.formatMessage({
          id: `detail_${user.UserStatus.charAt(0)
            .toUpperCase() + user.UserStatus.slice(1).toLowerCase()}`
        })}</div>
      </StyledGridCell>

      <StyledGridCell {...gridBreak.roles}>
        <UserRolesListTable userRolesToDisplay={userRolesToDisplay} />
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons}>
        <StyledActionContainer breaks={{ sm: true }}>
          <Link to={`/site/${site.id}/setup/user/${user.Username}/edit`}
          >
            <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
              <ActionButton>
                <EditIcon />
              </ActionButton>
            </Tooltip>
          </Link>
          {
            canDelete && (user.Username !== currentUserName ? <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
              <ActionButton onClick={() => setUserDeleteConfirmVisible(true)}>
                <DeleteIcon />
              </ActionButton>
              </Tooltip> : <ActionButton disabled={true}
                onClick={() => setUserDeleteConfirmVisible(true)}>
              <DeleteIcon />
            </ActionButton>)
          }
        </StyledActionContainer>
      </StyledGridCell>
    </StyledGridRow>
    <DeleteDialog
      saving={saving}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={isUserDeleteConfirmVisible}
      onClose={onCloseUserDeleteDialog}
      extraContent={<div>Email: {user.email}</div>}
    />
    { saving && <ProgressIndicator />}

  </>
  )
};

export default UserListRow;
