import React, { useCallback, useEffect, useState } from 'react';
import ReportNotificationListRow from '../ReportNotificationListRow/ReportNotificationListRow';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import { Paging } from '../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../constants/global';
import { useReportNotificationListTableStyles } from './ReportNotificationListTable.css';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import Can from '../../../components/Common/Can';
import { ReportNotificationSetupBar } from '../ReportNotificationSetupBar/ReportNotificationSetupBar';
import { useConfigActions } from '../../../actions/configActions';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

const gridBreak = {
  active: { lg: 1, md: 2, sm: 12, xs: 12 },
  title: { lg: 2, md: 3, sm: 12, xs: 12 },
  reports: { lg: 4, md: 7, sm: 12, xs: 12 },
  schedule: { lg: 4, md: 6, sm: 12, xs: 12 },
  actionButtons: { lg: 1, md: 6, sm: 12, xs: 12 }
};

interface IReportListState {
  page: number;
  sortedReports: Array<any>;
  pageCount: number;
  pagedReports: Array<any>;
}

interface IReportNotificationListTableProps {
  scheduledReports: any;
  userId: string;
  onChange: any;
  siteId: string;
  onDeleteSchedule: (schedule: any) => void;
}

const ReportNotificationListTable = ({
  scheduledReports,
  onDeleteSchedule,
  siteId,
  userId }: IReportNotificationListTableProps) => {
  const classes = useReportNotificationListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';

  const configActions = useConfigActions();
  const { appFilters } = useAppGlobalState();

  const [filteredScheduledReports, setFilteredScheduledReports] = useState<Array<any>>([]);

  const [reportListState, setReportListState] = useState<IReportListState>({
    page: 1,
    sortedReports: [],
    pageCount: 0,
    pagedReports: []
  });

  const {
    page,
    sortedReports,
    pageCount,
    pagedReports
  } = reportListState;

  useEffect(() => {
    console.log('initialised')
    setReportListState(s => {
      const sorted = scheduledReports.sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        sortedReports: sorted
      }
    })
  }, [scheduledReports]);

  useEffect(() => {
    const searchTerm = appFilters.reportSchedulerFilter.searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredReport = searchTerm
      ? sortedReports.filter(report => report.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedReports;

    setFilteredScheduledReports(initialFilteredReport);

  }, [appFilters, sortedReports]);

  useEffect(() => {
    setReportListState(s => {
      const count = Math.ceil(((filteredScheduledReports && filteredScheduledReports.length) || 0) / PAGE_SIZE);
      const paged = filteredScheduledReports.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedReports: paged
      }
    })
  }, [filteredScheduledReports]);

  const onPageChange = useCallback(p => {
    setReportListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredScheduledReports.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setReportListState(s => ({
      ...s,
      pagedReports: paged
    }));
  }, [page, filteredScheduledReports]);

  return <>
    <Can do="view" on="report">
      <ReportNotificationSetupBar
        siteId={siteId}
        userId={userId}
        filter={appFilters.reportSchedulerFilter}
        setFilter={configActions.setBarFilter}
      />
    </Can>
    <Can do="view" on="report" passThrough>
      {can => can && <StyledContentCard>
        {scheduledReports.length > 0 ? <StyledGrid>
          {
            appFilters.reportSchedulerFilter.searchTerm.length === 0 || pagedReports.length > 0 ? <StyledGridHeadRow>
              <StyledGridHeadCell {...gridBreak.active}>
                <TableHeadText
                  textId="detail_Active"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.title}>
                <TableHeadText
                  textId="detail_Title"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>

              <StyledGridHeadCell {...gridBreak.reports}>
                <TableHeadText
                  textId="detail_Reports"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>

              <StyledGridHeadCell {...gridBreak.schedule}>
                <TableHeadText
                  textId="detail_Schedule"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>

              <StyledGridHeadCell {...gridBreak.actionButtons}>
              </StyledGridHeadCell>
            </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
          }
          <>
            {
              pagedReports ? pagedReports.map(sr => (
                <ReportNotificationListRow
                  key={sr.createdAt}
                  sr={sr}
                  localReports={sr.localReports}
                  siteReports={sr.siteReports}
                  title={sr.title}
                  siteId={sr.siteId}
                  userId={sr.userId}
                  gridBreak={gridBreak}
                  onDeleteSchedule={onDeleteSchedule}
                />
              )) : null
            }
          </>
          {
            filteredScheduledReports && filteredScheduledReports.length > PAGE_SIZE ? <div className={classes.pageContainer}>
              <Paging
                page={page}
                onChange={onPageChange}
                count={pageCount}
                total={filteredScheduledReports.length} /></div> : null
          }
        </StyledGrid> : <EmptyItem textId="detail_noReports" />
        }
      </StyledContentCard>
      }
    </Can>
  </>
}

export default ReportNotificationListTable;
