import {
  Chart, ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeriesDefaults,
  ChartLegend, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartSeriesItemTooltip,
} from '@progress/kendo-react-charts';

import 'hammerjs';
import { IntlService } from '@progress/kendo-react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import * as ENUMS from '../../enums/Enums';
import oeeThresholds from '../../utilities/oeeThresholds';
import { sharedConfig } from './ChartSharedConfig';
import Moment from 'moment/moment';
import { injectIntl } from 'react-intl';


const ChartOee = (props) => {

  function chartConfig(props): any {
    // start with a default configuration for the chart
    const config = defaultConfig(props);

    // update the chart configuration based on the interval
    switch (props.interval.value) {
      case ENUMS.INTERVALS.HOUR:
        // config.categoryAxis.labels.content: e => `${Number(e.value)`
        config.categoryAxis.labels.content = (e) => {
          const { dataItem, value } = e;
          const is = new IntlService(props.locale);
          return is.format(((dataItem && dataItem.siteTimeStamp) || value).getHours() === 0 ? '{0:MM-dd HH:mm}' : '{0:HH:mm}', (dataItem && dataItem.siteTimeStamp) || value);
        };
        break;
      case ENUMS.INTERVALS.DAY:
        config.categoryAxis.labels.format = '{0:MMMM dd}';
        config.categoryAxis.labels.rotation = -45;
        break;
      case ENUMS.INTERVALS.WEEK:
        config.categoryAxis.labels.content = (e) => {
          const dataItemWeek = Moment(e.value).week();
          const chartStartWeek = Moment(props.start).week();
          const chartEndWeek = Moment(props.end).week();

          const dataItemYear = Moment(e.value).year();
          const chartStartYear = Moment(props.start).year();
          const chartEndYear = Moment(props.end).year();
          let startLabel = Moment(e.value).startOf('week').format('MMM DD');
          let endLabel = Moment(e.value).endOf('week').format('MMM DD');

          if ((chartStartWeek === dataItemWeek && chartStartYear === dataItemYear) ||
            // year changeover
            (chartStartWeek === 1 && dataItemWeek === 1 && dataItemYear === chartStartYear - 1)) {
            startLabel = Moment(props.start).format('MMM DD');
          }
          if ((chartEndWeek === dataItemWeek && chartEndYear === dataItemYear) ||
            // year changeover
            (chartStartWeek === 1 && dataItemWeek === 1 && dataItemYear === chartStartYear - 1)) {
            endLabel = Moment(props.end).format('MMM DD');
          }

          return startLabel + ' to ' + endLabel;
        };
        break;
      case ENUMS.INTERVALS.MONTH:
        config.categoryAxis.labels.content = (e) => {
          const dataItemMonthNumber = Moment(e.value).month();
          const chartStartMonthNumber = Moment(props.start).month();
          const chartEndMonthNumber = Moment(props.end).month();

          const dataItemYear = Moment(e.value).year();
          const chartStartYear = Moment(props.start).year();
          const chartEndYear = Moment(props.end).year();

          const inStartMonth = chartStartMonthNumber === dataItemMonthNumber && chartStartYear === dataItemYear;
          const inEndMonth = chartEndMonthNumber === dataItemMonthNumber && chartEndYear === dataItemYear;

          if (inStartMonth && inEndMonth) {
            return Moment(props.start).format('MMM DD') + ' to ' + Moment(props.end).format('MMM DD');
          } else if (inStartMonth) {
            return Moment(props.start).format('MMM DD') + ' to ' + Moment(props.start).endOf('month').format('MMM DD');
          } else if (inEndMonth) {
            return Moment(props.end).format('MMM') + ' 01 to ' + Moment(props.end).format('MMM DD');
          } else {
            return Moment(e.value).format('MMM');
          }
        };
        break;
      default:
    }
    return config;
  }

  function colorsByRange(event) {
    if (event.value > oeeThresholds.thresholds.max.value) {
      return oeeThresholds.thresholds.max.hex;
    }
    if (event.value < oeeThresholds.thresholds.min.value) {
      return oeeThresholds.thresholds.min.hex;
    }
    return oeeThresholds.thresholds.med.hex;
  }

  function defaultConfig(props): any {
    return {
      title: {
        text: props.title,
        visible: props.showTitle,
        margin: {
          bottom: 18,
        },
        color: 'Black',
      },
      legend: {
        position: 'bottom',
        visible: true,
      },
      seriesDefaults: {
        type: 'column',
        stack: props.stacked === undefined ? true : props.stacked,
        color: props.summary ? '#0192c7' : undefined,
        labels: {
          visible: props.showBarLabels,
          background: 'transparent',
          format: '{0:p0}',
        },
      },
      series: (props.customSeries
        || [
          getOeeTypeSeries(props),
          props.dynamicBarColor && { name: oeeThresholds.thresholds.max.n, color: oeeThresholds.thresholds.max.hex },
          props.dynamicBarColor && { name: oeeThresholds.thresholds.med.n, color: oeeThresholds.thresholds.med.hex },
          props.dynamicBarColor && { name: oeeThresholds.thresholds.min.n, color: oeeThresholds.thresholds.min.hex },
        ]
      ),
      transitions: false,
      valueAxis: {
        min: 0,
        max: 1.1,
        labels: {
          visible: true,
          format: '{0:p0}',
        },
        plotBands: [
          {
            from: oeeThresholds.thresholds.max.value,
            to: oeeThresholds.thresholds.max.value + 0.01,
            color: oeeThresholds.thresholds.max.hex,
            opacity: 1,
          }, {
            from: oeeThresholds.thresholds.med.value,
            to: oeeThresholds.thresholds.med.value + 0.01,
            color: oeeThresholds.thresholds.med.hex,
            opacity: 1,
          }, {
            from: oeeThresholds.thresholds.min.value,
            to: oeeThresholds.thresholds.min.value + 0.01,
            color: oeeThresholds.thresholds.min.hex,
            opacity: 1,
          }],
      },
      categoryAxis: {
        baseUnit: props.interval.chartBaseUnit,
        labels: {
          rotation: 'auto',
        },
        majorGridLines: {
          visible: false,
        },
        line: {
          visible: false,
        },
      },

      tooltip: {
        visible: true,
        format: '{0:n2}',
      },
    };
  }

  function getOeeTypeSeries(props) {
    return {
      name: oeeThresholds.thresholds.min.n,
      field: 'oee',
      visibleInLegend: false,
      categoryField: 'siteTimeStamp',
      data: props.data,
      opacity: props.dynamicBarColor ? 0.7 : null,
      color: props.dynamicBarColor ? e => colorsByRange(e) : (props.summary ? '#0192c7' : undefined),
    };
  }

  const config = chartConfig(props);
  return (
    <React.Fragment>
      {props.children}
      <Chart renderAs={props.printing ? 'canvas' : undefined} {...sharedConfig}>
        <ChartTooltip />
        <ChartLegend {...config.legend} />
        <ChartSeriesDefaults {...config.seriesDefaults} />
        <ChartTitle {...config.title} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem {...config.categoryAxis} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem {...config.valueAxis} />
        </ChartValueAxis>
        <ChartSeries>
          {config.series.map((item, i) => (
            <ChartSeriesItem {...item} key={`series-${i}`}>
              <ChartSeriesItemTooltip format="{0:p0}" />
            </ChartSeriesItem>
          ))}
        </ChartSeries>
      </Chart>
    </React.Fragment>
  );
};

ChartOee.propTypes = {
  children: PropTypes.element,
  customSeries: PropTypes.array,
  data: PropTypes.array,
  dynamicBarColor: PropTypes.bool,
  end: PropTypes.instanceOf(Date),
  interval: PropTypes.object,
  printing: PropTypes.bool,
  showBarLabels: PropTypes.bool,
  showTitle: PropTypes.bool,
  stacked: PropTypes.bool,
  start: PropTypes.instanceOf(Date),
  summary: PropTypes.bool,
  title: PropTypes.string,
  locale: PropTypes.string,
};

ChartOee.defaultProps = {
  data: [],
  interval: '',
  showTitle: true,
};

export default injectIntl(ChartOee);
