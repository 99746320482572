import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import BarStyledGrid from '../../../components/Basic/BarStyledGrid/BarStyledGrid';
import { useHistory } from 'react-router';
import Can from '../../../components/Common/Can';
import { useLineBarStyles } from './LineBar.css';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../components/SearchInput/SearchInput';

const gridBreak: any = {
  create: { lg: 6, md: 6, sm: 6, xs: 6 },
  filter: { lg: 6, md: 6, sm: 6, xs: 6 },
};

interface ILineBarProps {
  siteId: string;
  filter: { searchTerm: string };
  setFilter: (string, any) => void;
}

const LineBar = ({
  siteId,
  filter,
  setFilter }: ILineBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useLineBarStyles();

  const addLine = () => {
    history.push(`/site/${siteId}/setup/line/0/add`);
  }

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('lineFilter', s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);

  return <BarStyledContentCard>
    <BarStyledGrid>
      <Can do="edit" on="line">
        <StyledGridCell {...gridBreak.create}>
          <div className={classes.buttonContainer}>
            <StyledActionButton
              color="primary"
              startIcon={<AddIcon />}
              onClick={addLine}
              label={intl.formatMessage({ id: 'detail_Create_a_Line' })}
            />
          </div>
        </StyledGridCell>
      </Can>
      <StyledGridCell {...gridBreak.filter} className={classes.filterContainer}>
        <SearchInput
          searchTerm={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onSubmitSearch={(term) => {
            setSearchTerm(term);
          }}
        />
      </StyledGridCell>
    </BarStyledGrid>

  </BarStyledContentCard>
};

export { LineBar };
