import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { v4 as uuid } from 'uuid';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useRoleManagementPageStyles } from './RoleManagementPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import UserHelper from '../../../helpers/UserHelper';
import { getRoles } from '../../../services/UserService/UserService';
import { Logger } from '../../../utilities/Logger/Logger';
import { ACTION_EDIT } from '../../../actions/actionTypes';
import { useHistory } from 'react-router';
import {
  Assignment as AssignmentIcon,
  Person as PersonIcon
} from '@material-ui/icons';
import Utils, { cloneObj, prepToSend } from '../../../utilities/utils';
import { getAbilityDescription } from './AbilityDescription';
import { useRoleActions } from '../../../actions/roleActions';
import { categoriesMapping } from './categoriesMapping';
import RoleAbilityListTable from '../RoleAbilityListTable/RoleAbilityListTable';
import { MINIMUM_ROLE_TITLE_LENGTH, MINIMUM_ROLE_DESCRIPTION_LENGTH } from '../../../constants/global';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
interface IRoleManagementPage {
  match: any;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const RoleManagementPage = ({ match }: IRoleManagementPage) => {
  const classes = useRoleManagementPageStyles();
  const history = useHistory();

  const intl = useIntl();
  const { sites } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const roleActions = useRoleActions();
  const siteId: string = match.params.siteId;
  const roleId: string = match.params.setupParam;

  const [errors, setErrors] = useState<any>({});
  const [thisRole, setThisRole] = useState<any>({});
  const [abilities, setAbilities] = useState<Array<any>>([]);
  const [initialAbilities, setInitialAbilities] = useState<Array<any>>([]);
  const [formattedCategorizedAbilities, setFormattedCategorizedAbilities] = useState<{ [key: string]: any }>({});

  const getRole = (fetchedRoles) => {
    if (roleId !== '0') {
      return UserHelper.getRoleById(fetchedRoles, roleId);
    }
    return { id: uuid(), description: '', siteId, title: '' };
  }

  const [saving, setSaving] = useState(false);
  const [initialised, setInitialised] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [roleTitle, setRoleTitle] = useState<string>('');
  const [roleDescription, setRoleDescription] = useState<string>('');
  const [showError, setShowError] = useState(false);

  const isEditMode = useMemo(() => {
    return match.params.action && match.params.action.toUpperCase() === ACTION_EDIT;
  }, [match.params.action]);

  useEffect(() => {
    getRoles().then((fetchedRoles) => {
      if (fetchedRoles) {
        const role = getRole(fetchedRoles);
        roleActions.listAbilities().then((abilities) => {
          if (abilities) {
            Logger.of('App.RoleManagementPage').info('Here is the ability list =>', abilities);
            const formattedAbilities = abilities.map(ability => ({
              id: ability,
              title: ability,
              description: intl.formatMessage({ id: getAbilityDescription(ability) }),
              active: role && role.claimIds && role.claimIds && role.claimIds.includes(ability),
            }));

            // VCCM-2558 Remove Order Execution roles from the Role Setup page
            const filteredAbilities = Utils.isOrderExEnabled() ? formattedAbilities : formattedAbilities.filter(a => a.id !== 'execute-printerJob' && a.id !== 'edit-printerJob');

            Logger.of('App.RoleManagementPage').info('Here is the formatted ability list =>', formattedAbilities);
            setAbilities(filteredAbilities);
            setInitialAbilities(cloneObj(filteredAbilities));
            setIsLoading(false);
          } else {
            Logger.of('App.RoleManagementPage').info('RoleApi other than error list=>', abilities);
          }
        }).catch((error) => {
          Logger.of('App.RoleManagementPage').warn('RoleApi abilities error', error);
          setIsLoading(false);
        });
        setThisRole(role);
        setRoleTitle(role.title);
        setRoleDescription(role.description);
        setInitialised(true);
      } else {
        Logger.of('App.RoleManagementPage').info('RoleApi other than error list=>', fetchedRoles);
      }
    }).catch((error) => {
      Logger.of('App.RoleManagementPage').warn('RoleApi error', error);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_Users', <AssignmentIcon />, {
      ignoreItself: true,
      parents: [{
        id: 'detail_Configuration',
      },
      {
        id: 'detail_Users',
        link: `/site/${siteId}/setup/roles`,
        icon: <PersonIcon />
      },
      {
        id: 'detail_ManageRoles',
      },
      {
        id: isEditMode ? 'detail_Edit_role' : 'detail_Create_Role',
      }]
    })
  }, [setTitle, siteId, isEditMode]);

  const cancelForm = () => {
    setAbilities(cloneObj(initialAbilities));
    setRoleTitle(thisRole ? thisRole.title : '');
    setRoleDescription(thisRole ? thisRole.description : '');
    setShowError(false);
    setErrors({});
  };

  const updateRoleTitle = (event) => {
    setRoleTitle(event.target.value);
  }

  const updateRoleDescription = (event) => {
    setRoleDescription(event.target.value);
  }

  const closeForm = () => {
    history.push(
      `/site/${siteId}/setup/roles`,
    );
  }
  const redirect = () => {
    setSaving(false);
    toastr.success(intl.formatMessage({ id: 'detail_RoleSaved' }));
    closeForm();
  }

  const updateThisRole = (event) => {
    const field = event.target.name;
    const roleObj = { ...thisRole };

    if (field.includes('-')) {
      setAbilities(
        abilities.map(
          ability => (ability.id === field ? { ...ability, active: !ability.active } : ability),
        )
      );
      return;
    }
    roleObj[field] = event.target.value;
    return setThisRole(roleObj);
  }

  const roleFormIsValid = useCallback(() => {
    let roleIsValid = true;
    const errors: any = {};

    if (!roleTitle || roleTitle.length < MINIMUM_ROLE_TITLE_LENGTH) {
      errors.title = intl.formatMessage({ id: 'detail_NameLength' });
      roleIsValid = false;
    }

    if (!roleDescription || roleDescription.length < MINIMUM_ROLE_DESCRIPTION_LENGTH) {
      errors.description = intl.formatMessage({ id: 'detail_DescriptionLength' });
      roleIsValid = false;
    }
    setErrors(errors);
    return roleIsValid;
  }, [roleTitle, roleDescription, intl]);

  useEffect(() => {
    if (showError && Object.keys(errors).length > 0) {
      roleFormIsValid();
    }
  }, [roleTitle, roleDescription]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const formattedAbilities = {};
    if (abilities.length > 0) {
      abilities.forEach((ability) => {
        if (categoriesMapping[ability.id]) {
          if (formattedAbilities[categoriesMapping[ability.id]] === undefined) {
            formattedAbilities[categoriesMapping[ability.id]] = [];
          }
          formattedAbilities[categoriesMapping[ability.id]].push(ability);
        }
      });
    }

    setFormattedCategorizedAbilities(formattedAbilities)
  }, [abilities]);

  useEffect(() => {
    if (!isLoading && initialised) {
      const isAbilityDifferent = JSON.stringify(initialAbilities) !== JSON.stringify(abilities);
      setDirty(isAbilityDifferent || roleTitle !== thisRole.title || roleDescription !== thisRole.description);
    }
  }, [abilities, roleTitle, roleDescription]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAnyPermissionSelected = () => {
    for (const key of Object.keys(formattedCategorizedAbilities)) {
      const permissionGroup = formattedCategorizedAbilities[key];
      const hasAnyActiveInTheGroup = permissionGroup.find(el => el.active);
      if (hasAnyActiveInTheGroup) {
        return true;
      }
    }

    return false;
  }

  const saveThisRole = (event) => {
    event.preventDefault();
    const roleToSave = {
      ...thisRole,
      title: roleTitle,
      description: roleDescription,
      claimIds: abilities.filter(a => a.active === true)
        .map(ability => ability.id)
    };

    const site = sites.find(el => el.id === siteId);

    if (!roleFormIsValid() || !site || !hasAnyPermissionSelected()) {
      setShowError(true);
      return;
    }

    prepToSend(roleToSave, ['description', 'claimIds']);

    setSaving(true);
    if (isEditMode) {
      roleActions.updateRole(roleToSave).then(() => {
        redirect();
      }).catch((error) => {
        toastr.error(error);
        setSaving(false);
      });
    } else {
      roleActions.createRole(roleToSave).then(() => {
        redirect();
      }).catch((error) => {
        toastr.error(error.message);
        setSaving(false);
      });
    }
  }

  return <>
    {!isLoading && sites.length > 0 && <StyledContentCard>
      <CardContent className={classes.content}>
        <div className={classes.mainTitle}>
          <Typography variant="h2">{intl.formatMessage({ id: isEditMode ? 'detail_Edit_role' : 'detail_Create_Role' })}</Typography>
        </div>
        <FormControl className={classes.formControl}>
          <TextField
            name="roleTitle"
            variant="outlined"
            label={intl.formatMessage({ id: "detail_RoleName" })}
            value={roleTitle || ''}
            onChange={updateRoleTitle}
            fullWidth={true}
            error={!!errors.title}
            helperText={errors.title}
            required={true}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            name="roleDescription"
            variant="outlined"
            label={intl.formatMessage({ id: "detail_Description" })}
            value={roleDescription || ''}
            onChange={updateRoleDescription}
            fullWidth={true}
            error={!!errors.description}
            helperText={errors.description}
            required={true}
          />
        </FormControl>
        {
          formattedCategorizedAbilities && Object.keys(formattedCategorizedAbilities)
            .map(key => <RoleAbilityListTable
              key={key}
              categoryName={key}
              abilityCategory={formattedCategorizedAbilities[key]}
              onChange={updateThisRole}
            />)
        }
        {
          showError && !hasAnyPermissionSelected() && <div className={classes.error}>
            {intl.formatMessage({ id: 'detail_RoleNeedPermission' })}
          </div>
        }
      </CardContent>
      <CardActions className={classes.actionButtons}>
        <Button
          variant="contained"
          disabled={saving || !dirty}
          onClick={cancelForm}>
          <FormattedMessage id="detail_Cancel" />
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={saving || !dirty}
          onClick={async (event) => {
            try {
              saveThisRole(event);
            } catch (e) {
              toastr.error(e);
            }
          }}
        >
          <FormattedMessage id="detail_Save" />
        </Button>
        <div className={classes.grow} />
        <Button
          variant="contained"
          onClick={closeForm}>
          <FormattedMessage id="detail_Close" />
        </Button>
      </CardActions>
    </StyledContentCard>
    }
    { (isLoading || saving) && <ProgressIndicator />}
  </>

};

export default RoleManagementPage;
