
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { mySecondaryColorDimmed, myWhiteShade0 } from '../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    padding: theme.spacing(0.625, 1.25),
    margin: theme.spacing(0.625, 1.25, '!important'),
    border: theme.spacing(.125, 'solid', mySecondaryColorDimmed),
    borderRadius: theme.spacing(1.5)
  },
  readonly: {
    backgroundColor: myWhiteShade0
  }
}));

const StyledGridRow = ({ readonly, ...rest }: any) => {
  const classes = useStyles();
  return <Grid container spacing={1} className={classNames(classes.root, { [classes.readonly]: readonly })} {...rest} />
}

export default StyledGridRow;

