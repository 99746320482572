import React, { useCallback, useEffect, useState } from 'react';
import ShiftListRow from '../ShiftListRow/ShiftListRow';
import { TableHeadText } from '../../../../components/TableHeadText/TableHeadText';
import Utils from '../../../../utilities/utils';
import StyledGrid from '../../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import { EmptyItem } from '../../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../../constants/global';
import { useShiftListTableStyles } from './ShiftListTable.css';
import { ShiftBar } from '../ShiftBar/ShiftBar';
import Can from '../../../../components/Common/Can';
import StyledContentCard from '../../../../components/Basic/StyledContentCard/StyledContentCard';
import { useConfigActions } from '../../../../actions/configActions';
import { useAppGlobalState } from '../../../../context/AppContext/AppContext';

const setBreaks = {
  shifts: { lg: 2, md: 4, sm: 12, xs: 12 },
  repeats: { lg: 2, md: 4, sm: 12, xs: 12 },
  time: { lg: 2, md: 4, sm: 12, xs: 12 },
  ends: { lg: 1, md: 4, sm: 12, xs: 12 },
  lines: { lg: 3, md: 4, sm: 12, xs: 12 },
  actionButtons: { lg: 2, md: 4, sm: 12, xs: 12 }
};

const setBreaksWithoutActionButtons = {
  shifts: { lg: 2, md: 4, sm: 12, xs: 12 },
  repeats: { lg: 2, md: 4, sm: 12, xs: 12 },
  time: { lg: 2, md: 4, sm: 12, xs: 12 },
  ends: { lg: 2, md: 6, sm: 12, xs: 12 },
  lines: { lg: 4, md: 6, sm: 12, xs: 12 }
};
interface IShiftListState {
  page: number;
  sortedShifts: Array<any>;
  pageCount: number;
  pagedShifts: Array<any>;
}
interface IShiftListTableProps {
  shifts: Array<any>;
  lines: Array<any>;
  timezone: string;
  siteId: string;
  isShift: boolean;
  actionButtonHidden: boolean;
  onDeleteShift?: (shift: any) => void;
}

const ShiftListTable = ({ shifts,
  lines,
  timezone,
  siteId,
  isShift,
  actionButtonHidden,
  onDeleteShift }: IShiftListTableProps) => {
  const classes = useShiftListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const filterName = isShift ? 'shiftFilter': 'plannedDowntimeFilter';

  const gridBreak: any = actionButtonHidden ? setBreaksWithoutActionButtons : setBreaks;

  const configActions = useConfigActions();
  const { appFilters } = useAppGlobalState();

  const [filteredShifts, setFilteredShifts] = useState<Array<any>>([]);

  const [shiftListState, setShiftListState] = useState<IShiftListState>({
    page: 1,
    sortedShifts: [],
    pageCount: 0,
    pagedShifts: []
  });

  const {
    page,
    sortedShifts,
    pageCount,
    pagedShifts
  } = shiftListState;

  useEffect(() => {
    setShiftListState(s => {
      const sorted = shifts.sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        page: 1,
        sortedShifts: sorted
      }
    })
  }, [shifts]);

  useEffect(() => {
    const searchTerm = appFilters[filterName].searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredShifts = searchTerm
      ? sortedShifts.filter(shift => shift.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedShifts;

    setFilteredShifts(initialFilteredShifts);

  }, [appFilters, sortedShifts, filterName]);

  useEffect(() => {
    setShiftListState(s => {
      const count = Math.ceil(((filteredShifts && filteredShifts.length) || 0) / PAGE_SIZE);
      const paged = filteredShifts.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedShifts: paged
      }
    })
  }, [filteredShifts]);

  const onPageChange = useCallback(p => {
    setShiftListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredShifts.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setShiftListState(s => ({
      ...s,
      pagedShifts: paged
    }));
  }, [page, filteredShifts]);

  return <>
    <Can do="edit" on="schedule">
      <ShiftBar
        siteId={siteId}
        isShift={isShift}
        filterName={filterName}
        filter={appFilters[filterName]}
        setFilter={configActions.setBarFilter} />
    </Can>
    <StyledContentCard>
      {timezone && shifts && shifts.filter(el => (isShift && el.typeId === 1)
        || (!isShift && el.typeId !== 1)).length > 0 ? <StyledGrid>
        {
          appFilters[filterName].searchTerm.length === 0 || pagedShifts.length > 0 ? <StyledGridHeadRow>
            <StyledGridHeadCell {...gridBreak.shifts} >
              <TableHeadText
                textId={isShift ? `detail_Shifts` : `detail_PlannedDowntimes`}
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>
            <StyledGridHeadCell {...gridBreak.repeats} >
              <TableHeadText
                textId="detail_Repeats"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>

            <StyledGridHeadCell {...gridBreak.time} >
              <TableHeadText
                textId="detail_Time"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>

            <StyledGridHeadCell {...gridBreak.ends} >
              <TableHeadText
                textId="detail_Ends"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>

            <StyledGridHeadCell {...gridBreak.lines} >
              <TableHeadText
                textId="detail_Lines"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>

            {
              !actionButtonHidden && <StyledGridHeadCell {...gridBreak.actionButtons} >
              </StyledGridHeadCell>
            }
          </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
        }
        <>
          {
            pagedShifts.map(shift => (
              <ShiftListRow
                key={shift.uid || shift.id}
                isPreview={false}
                shift={shift}
                endDate={Utils.getEndDatePropFromObject(shift, 'UNTIL=')}
                count={Utils.getEndDatePropFromObject(shift, 'COUNT=')}
                shiftType={shift.typeId}
                lines={lines}
                timezone={timezone}
                gridBreak={gridBreak}
                actionButtonHidden={actionButtonHidden}
                onDeleteShift={onDeleteShift}
              />
            ))
          }
        </>
        {
          filteredShifts && filteredShifts.length > PAGE_SIZE ? <div className={classes.pageContainer}>
            <Paging
              page={page}
              onChange={onPageChange}
              count={pageCount}
              total={filteredShifts.length} /></div> : null
        }
      </StyledGrid>
        : <EmptyItem textId={isShift ? `detail_noShifts` : `detail_noPlannedDowntimes`} />
      }
    </StyledContentCard>
  </>
}

export default ShiftListTable;
