import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl
} from '@material-ui/core';
import {
  InsertDriveFile as InsertDriveFileIcon
} from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTranslationImportDialogStyle } from './TranslationImportDialog.css';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import TranslationImportListRow from '../TranslationImportListRow/TranslationImportListRow';
import { StyledDropDown } from '../../../components/StyledDropdown/StyledDropdown';
import { useAppLanguageState } from '../../../context/AppContext/AppContext';
import { CsvImportSelector } from '../CsvImportSelector/CsvImporrtSelector';

const decideLanguage = (info) => {
  if (info && info.name) {
    const lanFile = info.name.replace('.csv', '');
    let lan = null;
    if (lanFile) {
      const lanFileParts = lanFile.split('-');
      if (lanFileParts.length > 1) {
        lan = lanFileParts[lanFileParts.length - 1];
      }
    }

    return lan;
  }

  return null;
}

const set1Breaks = {
  selected: { lg: 2, md: 2, sm: 12, xs: 12 },
  key: { lg: 5, md: 5, sm: 12, xs: 12 },
  value: { lg: 5, md: 5, sm: 12, xs: 12 },
};

const tableHeadFontSize = 'h6.fontSize';
const tableHeadAlign = 'left';

interface ITranslationImportDialogProps {
  setLoading: any;
  initialFileInfo: any;
  open: boolean;
  initiallyImportedItems: Array<any>;
  onClose: (lan: string, value: Array<any>) => void;
  maxWidth?: DialogProps['maxWidth'];
  allLanguages: Array<any>;
}

const TranslationImportDialog = ({
  setLoading,
  initialFileInfo,
  open,
  maxWidth,
  onClose,
  initiallyImportedItems,
  allLanguages }: ITranslationImportDialogProps) => {
  const intl = useIntl();
  const classes = useTranslationImportDialogStyle();

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const handleClose = () => {
    onClose(selectedLanguage, []);
  };
  const { translationItems, translationDataObj } = useAppLanguageState();
  const [importedPercent, setImportedPercent] = useState<number>(0.0);
  const [fileInfo, setFileInfo] = useState<any>(initialFileInfo);
  const [importedItems, setImportedItems] = useState<Array<any>>(initiallyImportedItems);
  const [activeItems, setActiveItems] = useState<Array<any>>(initiallyImportedItems);

  const title = useMemo(() => {
    return fileInfo ? fileInfo.name : '';
  }, [fileInfo]);

  const processImportedItems = useCallback((items, language) => {
    const newItems = items.filter(el => {
      const item = translationDataObj[el['Key']];
      if (item) {
        const imported = el['Value'] && el['Value'].trim();
        const existing = item[language] && item[language].trim();
        return imported && imported !== existing;
      }
      return false;
    }).map(el => ({ ...el, selected: true }));

    setActiveItems(newItems);
    setImportedPercent(translationItems.length > 0 ? Math.round(newItems.length * 10000.0 / translationItems.length) / 100.0 : 0.0);
  }, [translationItems, translationDataObj, setActiveItems, setImportedPercent]);

  const changeLanguage = useCallback(value => {
    setSelectedLanguage(value);
    if (fileInfo && fileInfo.name) {
      processImportedItems(importedItems, value);
    }
  }, [fileInfo, setSelectedLanguage, importedItems, processImportedItems]);


  useEffect(() => {
    const lan = decideLanguage(fileInfo);
    if (lan && allLanguages.find(el => el.value === lan)) {
     changeLanguage(lan);
    }
  }, [fileInfo, allLanguages, changeLanguage]);

  const createItems = () => {
    const selectedItems = importedItems.map(item => {
      const key = item['Key'];
      const value = item['Value'];

      const itemToAdd: any = {
        key,
        value
      };
      return itemToAdd;
    })

    onClose(selectedLanguage, selectedItems);
  }

  const gridBreak: any = set1Breaks;

  const extraTitleProps = {
    fontSize: tableHeadFontSize,
    textAlign: tableHeadAlign,
    extraClass: classes.title
  };

  const onCsvFileImported = (info, items) => {
    const lan = decideLanguage(info);
    setFileInfo(info);
    setImportedItems(items);
    if (lan || selectedLanguage) {
      processImportedItems(items, lan || selectedLanguage);
    } else {
      setActiveItems(items);
    }
    setLoading(false);
  }

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'lg'}
    disableEscapeKeyDown
    aria-labelledby="upload-item" open={open}>
    <DialogTitle id="upload-item-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent className={classes.content} dividers>
      <div className={classes.language}>
        <FormControl variant="outlined" className={classes.control}>
          <StyledDropDown
            labelId="selectedLanguage"
            multiple={false}
            name="selectedLanguage"
            label={intl.formatMessage({ id: 'detail_SelectLanguage' })}
            placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
            options={allLanguages}
            value={selectedLanguage}
            onChange={changeLanguage}
          />
        </FormControl>
      </div>

      <StyledGrid>
        <StyledGridHeadRow>
          <StyledGridHeadCell {...gridBreak.key}>
            <TableHeadText
              textId="detail_Key"
              {...extraTitleProps}
            />
          </StyledGridHeadCell>
          <StyledGridHeadCell {...gridBreak.value} className={classes.value}>
            <TableHeadText
              textId="detail_Value"
              {...extraTitleProps}
            /> <span className={classes.percent}>({importedPercent}%)</span>
          </StyledGridHeadCell>
        </StyledGridHeadRow>
        {
          (activeItems)
            ? activeItems.map(item => (
              <TranslationImportListRow key={item.id || item['Key']}
                item={item}
                gridBreak={gridBreak}
              />
            )) : null
        }
      </StyledGrid>
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <CsvImportSelector
        csvUploadId="__csvUploadId_dialog"
        disabled={false}
        buttonIcon={<InsertDriveFileIcon />}
        buttonTextId="detail_ImportCSV"
        setLoading={setLoading}
        onCsvFileImported={onCsvFileImported}
      />
      <Button
        onClick={createItems}
        className={classNames(classes.createProductBtn, 'create-item-btn')}
        variant="contained"
        disabled={!selectedLanguage || !importedItems || importedItems.length === 0}
        color="primary">
        <FormattedMessage id="detail_ImportItem" />
      </Button>
      <Button onClick={handleClose}
        color="default"
        className="cancel-import-item-btn"
        variant="contained">
        <FormattedMessage id="detail_Cancel" />
      </Button>
    </DialogActions>
  </Dialog>;
}

export { TranslationImportDialog };
