import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useSearchInputStyle = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.125, 0.25),
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  textInput: {
    padding: theme.spacing(0, 0.625)
  },
  inputBox: {
    padding: theme.spacing(1.25, 1)
  }
}));
