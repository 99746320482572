import { useMemo } from 'react';
import InspectionApi from '../api/prodInspectionApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import {
  CREATE_INSPECTION_SUCCESS,
  DELETE_INSPECTION_SUCCESS,
  LOAD_INSPECTIONS_SUCCESS,
  LOAD_INSPECTION_SUCCESS,
  UPDATE_INSPECTION_SUCCESS
} from './actionTypes';

export function loadInspectionSuccess(inspection) {
  return { type: LOAD_INSPECTION_SUCCESS, inspection };
}

function loadInspection(id: string, siteId: string) {
  return (dispatch) => {
    return InspectionApi.getInspection(id, siteId).then((inspection) => {
      dispatch(loadInspectionSuccess(inspection));
      return inspection;
    }).catch((error) => {
      console.log('inspectionActions.loadInspection error', error);
      throw error;
    });
  };
}

export function loadInspectionsSuccess(inspections) {
  return { type: LOAD_INSPECTIONS_SUCCESS, inspections };
}

function loadInspections(siteId: string) {
  return (dispatch): Promise<Array<any>> => {
    return InspectionApi.getInspections(siteId).then((inspections) => {
      dispatch(loadInspectionsSuccess(inspections));
      return inspections;
    }).catch((error) => {

      console.log('inspectionActions.loadInspections error', error);
    });
  };
}

export function createInspectionSuccess(inspection) {
  return { type: CREATE_INSPECTION_SUCCESS, inspection };
}

export function updateInspectionSuccess(inspection) {
  return { type: UPDATE_INSPECTION_SUCCESS, inspection };
}

function saveInspection(input: any) {
  return function (dispatch) {

    const edited = !!input.id;
    if (!edited) {
      return InspectionApi.saveInspection(input).then((data) => {
        dispatch(createInspectionSuccess(data));
      }).catch((error) => {

        console.log('inspectionActions.saveInspection error', error);
        throw error;
      });
    }
    return InspectionApi.updateInspection(input).then((data) => {
      dispatch(updateInspectionSuccess(data));
    }).catch((error) => {

      console.log('inspectionActions.updateInspection error', error);
      throw error;
    });
  };
}

export function deleteInspectionSuccess(id) {
  return { type: DELETE_INSPECTION_SUCCESS, id };
}

function deleteInspection(siteId: string, id: string) {
  return function (dispatch) {
    return InspectionApi.deleteInspection(siteId, id).then(
      dispatch(deleteInspectionSuccess(id)),
    ).catch((error) => {

      console.log('inspectionActions.deleteInspection error', error);
      throw (error);
    });
  };
}

function getFileUploadUrl(siteId: string, fileType: string) {
  return (dispatch) => {
    return InspectionApi.getFileUploadUrl(siteId, fileType).then((response) => {
      return response;
    }).catch((error) => {

      console.log('inspectionActions.getFileUploadUrl error', error);
      throw (error);
    });
  };
}

function getFileUrl(siteId: string, fileName: string) {
  return (dispatch) => {
    return InspectionApi.getFileUrl(siteId, fileName).then((response) => {
      return response;
    }).catch((error) => {

      console.log('inspectionActions.getFileUrl error', error);
      throw (error);
    });
  };
}

function uploadFile(signedUrl: string, file: any) {
  return (dispatch) => {
    return InspectionApi.uploadFile(signedUrl, file).then((response) => {
      return response;
    }).catch((error) => {
      console.log('inspectionActions.uploadFile error', error);
      throw (error);
    });
  };
}

export const useInspectionActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    loadInspection: (id: string, siteId: string) => loadInspection(id, siteId)(dispatch),
    loadInspections: (siteId: string) => loadInspections(siteId)(dispatch),
    saveInspection: (input: any) => saveInspection(input)(dispatch),
    deleteInspection: (siteId: string, id: string) => deleteInspection(siteId, id)(dispatch),
    getFileUploadUrl: (siteId: string, fileType: string) => getFileUploadUrl(siteId, fileType)(dispatch),
    getFileUrl: (siteId: string, fileName: string) => getFileUrl(siteId, fileName)(dispatch),
    uploadFile: (signedUrl: string, file: any) => uploadFile(signedUrl, file)(dispatch),
  }), [dispatch]);
};
