import {
  Chart, ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeriesDefaults,
  ChartLegend, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartSeriesItemTooltip,
} from '@progress/kendo-react-charts';
import 'hammerjs';

import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment/moment';
import * as ENUMS from '../../enums/Enums';
import { sharedConfig } from './ChartSharedConfig';

const ChartDowntime = (props) => {
  /**
   * Gets the chart configuration
   *
   * @function
   * @param {Object} props - component properties
   * @returns {Object} ChartConfig
   *
   */
  function chartConfig(props: any) {
    const config = defaultConfig(props);
    if (props.forceLabelRotation)
      config.categoryAxis.labels.rotation = -45;
    switch (props.interval.value) {
      case ENUMS.INTERVALS.HOUR:
        break;
      case ENUMS.INTERVALS.DAY:
        config.categoryAxis.labels.format = '{0:MMMM dd}';
        config.categoryAxis.labels.rotation = -45;
        break;
      case ENUMS.INTERVALS.WEEK:
        config.categoryAxis.labels.content = (e) => {
          const dataItemWeek = Moment(e.value).week();
          const chartStartWeek = Moment(props.start).week();
          const chartEndWeek = Moment(props.end).week();

          const dataItemYear = Moment(e.value).year();
          const chartStartYear = Moment(props.start).year();
          const chartEndYear = Moment(props.end).year();

          let startLabel = Moment(e.value).startOf('week').format('MMM DD');
          let endLabel = Moment(e.value).endOf('week').format('MMM DD');
          if ((chartStartWeek === dataItemWeek && chartStartYear === dataItemYear)
            // year changeover
            || (chartStartWeek === 1 && dataItemWeek === 1 && dataItemYear === chartStartYear - 1)) {
            startLabel = Moment(props.start).format('MMM DD');
          }
          if ((chartEndWeek === dataItemWeek && chartEndYear === dataItemYear)
            ||
            // year changeover
            (chartEndWeek === 1 && dataItemWeek === 1 && dataItemYear === chartEndYear - 1)) {
            endLabel = Moment(props.end).format('MMM DD');
          }

          if (props.requestedOneDay)
            return `${startLabel}`;
          else
            return `${startLabel} to ${endLabel}`;
        };
        break;
      case ENUMS.INTERVALS.MONTH:
        config.categoryAxis.labels.content = (e) => {
          const dataItemMonthNumber = Moment(e.value).month();
          const chartStartMonthNumber = Moment(props.start).month();
          const chartEndMonthNumber = Moment(props.end).month();

          const dataItemYear = Moment(e.value).year();
          const chartStartYear = Moment(props.start).year();
          const chartEndYear = Moment(props.end).year();

          const inStartMonth = chartStartMonthNumber === dataItemMonthNumber && chartStartYear === dataItemYear;
          const inEndMonth = chartEndMonthNumber === dataItemMonthNumber && chartEndYear === dataItemYear;

          if (inStartMonth && inEndMonth) {
            return `${Moment(props.start).format('MMM DD')} to ${Moment(props.end).format('MMM DD')}`;
          } if (inStartMonth) {
            return `${Moment(props.start).format('MMM DD')} to ${Moment(props.start).endOf('month').format('MMM DD')}`;
          } if (inEndMonth) {
            return `${Moment(props.end).format('MMM')} 01 to ${Moment(props.end).format('MMM DD')}`;
          }
          return Moment(e.value).format('MMM');
        };
        break;
      default:
    }
    return config;
  }

  /**
   * Gets the default chart configuration
   *
   * @function
   * @param {Object} cProps - component properties
   * @returns {Object} ChartConfig
   *
   */
  function defaultConfig(cProps): any {
    return {
      title: {
        text: cProps.title,
        visible: cProps.showTitle,
        color: 'Black',
      },
      legend: {
        position: 'bottom',
        visible: true,

      },
      seriesDefaults: {
        type: 'column',
        stack: false,
        color: cProps.summary ? '#0192c7' : undefined,
        labels: {
          visible: true,
          format: '{0:n0}',
          background: 'transparent',
        },

      },
      series: cProps.customSeries,
      transitions: false,
      valueAxis: {
        line: {
          visible: true,
        },
        labels: {
          visible: true,
          format: cProps.downtimeElapsed ? '{0:n0} min' : '{0}',
        },
      },
      categoryAxis: {
        baseUnit: cProps.interval.chartBaseUnit,
        labels: {
          rotation: 'auto',
        },
        majorGridLines: {
          visible: false,
        },
        line: {
          visible: false,
        },
      },
      tooltip: {
        visible: true,
        format: '{0:n0}',
      },
    };
  }

  const config = chartConfig(props);
  return (
    <div className="chart-down-time">
      {props.children}
      <Chart renderAs={props.printing ? 'canvas' : undefined} {...sharedConfig}>
        <ChartTooltip />
        <ChartLegend {...config.legend} />
        <ChartSeriesDefaults {...config.seriesDefaults} />
        <ChartTitle {...config.title} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem {...config.categoryAxis} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem {...config.valueAxis} />
        </ChartValueAxis>
        <ChartSeries>
          {config.series.map((item, i) => (
            <ChartSeriesItem {...item} key={`series-${i}`}>
              <ChartSeriesItemTooltip format="{0:n0}" />
            </ChartSeriesItem>
          ))}
        </ChartSeries>
      </Chart>
    </div>
  );
};

ChartDowntime.propTypes = {
  children: PropTypes.element,
  customSeries: PropTypes.array,
  downtimeElapsed: PropTypes.bool,
  interval: PropTypes.object,
  printing: PropTypes.bool,
  showTitle: PropTypes.bool,
  summary: PropTypes.bool,
  title: PropTypes.string,
  forceLabelRotation: PropTypes.bool,
  requestedOneDay: PropTypes.bool,
  start: PropTypes.object,
  end: PropTypes.object
};

ChartDowntime.defaultProps = {
  customSeries: [],
  interval: '',
  showTitle: true,
  title: 'Downtime Chart',
};

export default ChartDowntime;
