import { FormControl } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

import { useGlobalStyles } from "../../constants/globalstyles";
import { useSlowCyclePercentageStyle } from "./SlowCyclePercentage.css";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { InfoTooltip } from "../Basic/InfoTooltip/InfoTooltip";
interface SlowCyclePercentageProps {
  onChange: (event) => void;
  isDisabled?: boolean;
  value: number;
}

function SlowCyclePercentage({ onChange,
  value,
  isDisabled }: SlowCyclePercentageProps) {
  const classes = useSlowCyclePercentageStyle();
  const globalClasses = useGlobalStyles();
  return <div className={classes.inputBox}>
    <div>
      <FormattedMessage id='detail_SlowCyclePercentage' />
      <InfoTooltip textId='detail_RatesThreshold'/>
    </div>

    <FormControl variant="outlined" className={globalClasses.formControl}>
      <NumericTextBox
        name="slowCycleThreshold"
        onChange={onChange}
        value={value}
        format="p"
        min={0}
        max={100}
        step={0.01}
        disabled={isDisabled}
      />
    </FormControl>
  </div>
}

SlowCyclePercentage.displayName = 'SlowCyclePercentage';

export { SlowCyclePercentage };
