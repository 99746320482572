import React from 'react';
import {
  Checkbox,
  Chip,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import classNames from 'classnames';

import {
  Clear as ClearIcon,
  Cancel as CancelIcon
} from '@material-ui/icons'

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import { useChipSelectorStyles } from './ChipSelector.css';
import StyledSelect from '../StyledSelector/StyledSelector';
import { useIntl } from 'react-intl';

const ChipSelector = ({ labelId,
  label,
  name,
  placeholder,
  options,
  value,
  onChange,
  rootClass = null,
  selectClass = null,
  outlinedClass = null }: any) => {
  const classes = useChipSelectorStyles();
  const intl = useIntl();

  const hasAnyValue = (): boolean => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    if (String(value).length > 0) {
      return true;
    }

    return false;
  };

  const removeAll = () => {
    onChange([]);
  };

  const noOptionAvailableText = options && options.length === 0 ? ` (${intl.formatMessage({ id: 'detail_NoItemExitsToSelect' })})` : '';

  const iconComponent: any = !noOptionAvailableText ?
    { IconComponent: () => <IconButton onClick={removeAll}><ClearIcon /></IconButton> }  /* eslint-disable-line react/display-name */
    : {};

  const handleDelete = (item) => {
    onChange(value.filter(el => el !== item.value));
  }

  const labelProps: any = label ? { label } : {};

  return <>
    { (label || noOptionAvailableText) && <InputLabel className={classNames({ [classes.dimmedLabel]: !hasAnyValue() })} id={labelId}>{`${label}${noOptionAvailableText}`}</InputLabel>}
    <StyledSelect
      multiple={true}
      name={name}
      value={value}
      disabled={options && options.length === 0}
      onChange={(event) => onChange(event.target.value)}
      {...labelProps}
      classes={{
        root: rootClass ? rootClass : classes.root,
        select: selectClass ? selectClass : classes.select,
        outlined: outlinedClass ? outlinedClass : classes.outlined,
      }}
      {...iconComponent}
      placeholder={placeholder}
      renderValue={(selected: any) => {
        return <div className={classes.chipContainer}>
          {options && options.length > 0 ? options.filter((el) =>
            (selected as Array<any>).indexOf(String(el.value)) > -1)
            .map((el) => <Chip
              key={el.value}
              className={el.icon ? classes.chipSimple : classes.chip}
              size="small"
              icon={el.icon ? el.icon : <SupervisedUserCircleIcon />}
              label={el.text}
              color="secondary"
              onDelete={() => { }}
              deleteIcon={
                <div className={el.icon ? classes.clipCloseSimple : classes.clipClose}
                  onMouseDown={(event) => {
                    if (!(options && options.length === 0)) {
                      event.stopPropagation()
                      handleDelete(el)
                    }
                  }}
                >
                  <CancelIcon />
                </div>
              }
            />)
            : noOptionAvailableText
          }
        </div>;
      }
      }
    >
      {
        options && options.map((el) => {
          return <MenuItem value={el.value} key={el.value}>
            {
              el.icon ? <div className={classes.valueContainer}>
                <Checkbox checked={value.indexOf(el.value) > -1} />
                <div className={classes.icon}>{el.icon}</div>
                <ListItemText primary={el.text} />
              </div> : <>
                <Checkbox checked={value.indexOf(el.value) > -1} />
                <ListItemText primary={el.text} />
              </>
            }
          </MenuItem>
        })
      }
    </StyledSelect>
  </>
}

export { ChipSelector }
