import { authModules } from 'vccm-common';
import { invokeApig } from '../libs/awsLib';

class UsersApi {
  static getAllUsers(version: any = undefined, paginationToken: any = undefined, companies: any = undefined) {
    return invokeApig({ gatewayName: 'USERS', path: '/users/', queryParams: ({ version, ...(paginationToken && { paginationToken }), ...(companies && { groups: companies }) }) });
  }

  static saveUser(user) {
    console.log('saving User', user);
    return invokeApig({ gatewayName: 'USERS', path: '/users', method: 'POST', body: user });
  }

  static assignUser(userId, siteId, roleLabel, siteRoles) {
    return invokeApig({ gatewayName: 'USERS', method: 'PUT', path: `/users/${userId}/site-assign/${siteId}`, body: { siteRoles } });
  }

  static deleteUser(siteId, id) {
    const apiUrl = `/users/${id}`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }

  static getCirrusToken(siteId) {
    const apiUrl = `/users/tdtoken/${siteId}`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'GET' });
  }

  static getVrsLoginToken(siteId) {
    const apiUrl = '/users/foreignAuthToken';
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'GET', queryParams: { siteId, authModule: authModules.VRS_AUTH_MODULE } });
  }

  static  requestVrsAccess(siteId, videojetAdmin) {
    return invokeApig({ gatewayName: 'USERS', path: '/users/requestVrsAccess', method: 'PUT', body: { siteId, videojetAdmin } });
  }

  static async getVrsAccessRequestType(siteId) {
    return invokeApig({ gatewayName: 'USERS', path: `/users/vrsAccessType/${siteId}` });
  }
}

export default UsersApi;
