import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toastr from 'toastr';
import downloadJs from 'downloadjs';

import { useAppLineState, useAppProductState, useAppShiftState, useAppSiteState } from '../../../context/AppContext/AppContext';
import { useProductReportPageStyles } from './ProductReportPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { CardContent } from '@material-ui/core';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Logger } from '../../../utilities/Logger/Logger';
import ReportCsvUtils from '../../../utilities/ReportCsvUtils';
import { useIntl } from 'react-intl';
import Utils from '../../../utilities/utils';
import * as ENUMS from '../../../enums/Enums';
import { useHistory } from 'react-router';
import { defaultReportFilter, getCommonFilterFromQueryString, getLocalTimeStamp, getNoContentMessage, getShiftsOccurringInRange, getSiteTimeStamp, getTimeoutMessage, performUpdateReportFilterData } from '../../../utilities/ReportUtility/ReportUtility';
import ProductApi from '../../../api/prodProductApi';
import { useSiteActions } from '../../../actions/siteActions';
import { PDFExport } from '@progress/kendo-react-pdf';
import ChartProductBar from '../../../components/Chart/ChartProductBar';
import LineHelper from '../../../helpers/LineHelper/LineHelper';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { ReportDownload } from '../ReportDownload/ReportDownload';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import { LinearProgressWithLabel } from '../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import { ReportTypes } from '../../../enums/ReportTypes';

interface IProductReportPage {
  match: any;
}

const ProductReportPage = ({ match }: IProductReportPage) => {
  const classes = useProductReportPageStyles();

  const lines = useAppLineState();
  const shifts = useAppShiftState();
  const allProducts = useAppProductState();
  const siteActions = useSiteActions();

  const intl = useIntl();
  const { sites, selectedSiteId } = useAppSiteState();
  const site = sites.find(el => el.id === selectedSiteId);
  const history = useHistory();

  const initialiseFilter = () => {
    if (history.location.search) {
      // if there are query params in the route when it's loaded, set the filters to match before fetching data
      return getCommonFilterFromQueryString({
        startTimeId: '',
        endTimeId: '',
        reportProduct: '',
        lineIds: '',
        reportShifts: ''
      }, history.location.search, site.tz, false)
    } else {
      return {
        ...defaultReportFilter,
        lineIds: '',
        reportShifts: '',
        selectableProducts: [],
        reportProduct: '',
        reportLineDisplay: 1
      }
    }
  }

  const [filter, setFilter] = useState<any>(initialiseFilter());
  const [isFilterInitialisedFirstTime, setIsFilterInitialisedFirstTime] = useState(true);
  const [isSelectableProductsInitialised, setIsSelectableProductsInitialised] = useState(false);
  const [isLineIdsManuallyUpdated, setLineIdsManuallyUpdated] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [downloadProgressIndex, setDownloadProgressIndex] = useState(0);
  const [downloadProgressCount, setDownloadProgressCount] = useState(1);

  const [filterIndex, setFilterIndex] = useState(0);
  const [activeShifts, setActiveShifts] = useState<Array<any> | null>(null);
  const shiftsFormatted = useMemo(() => activeShifts ? activeShifts.map(shift => ({
    value: shift.id,
    text: shift.title,
  })) : [], [activeShifts]);

  const [pdfExportComponent, setPdfExportComponent] = useState<any>();

  const getNewData = async () => {
    const lineIds = filter.lineIds && filter.lineIds.length > 0 && filter.lineIds.indexOf('all') === -1
      ? filter.lineIds
      : (lines || []).map(l => l.id);

    setIsLoading(true);
    Logger.of('App.ProductReportPage.getNewData').info('isInitialized =>', isInitialized);
    const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
    Logger.of('App.ProductReportPage.getNewData').info('current filter', filter);
    if (interval && lineIds.length > 0 && lineIds[0].length > 0) {
      await siteActions.loadSiteProductSummary(
        Utils.getISOTimeForReport(filter.endDt, site.tz, interval.value),
        interval.api,
        lineIds,
        filter.reportShift || '',
        match.params.siteId,
        Utils.getISOTimeForReport(filter.startDt, site.tz, interval.value),
        interval.text,
        filter.reportProduct,
        site.tz,
      );
      setFilter((state) => ({ ...state, isDirty: false }));
      if (!isInitialized) {
        setIsInitialized(true);
      }
    }

    setIsLoading(false);
  }

  const downloadToCsv = async () => {
    setIsDownloading(true);
    setDownloadProgressIndex(0);
    setDownloadProgressCount(1);
    Logger.of('App.ProductReportPage.downloadToCsv').info('start', Date.now());
    const { filename, output } = await ReportCsvUtils.selectProductReportData(filter, site, lines, shifts, allProducts, [], setDownloadProgressIndex, setDownloadProgressCount);
    if (Array.isArray(output) && output.length > 0) {
      Logger.of('App.ProductReportPage.downloadToCsv').info(`data selected (${output.length})`, Date.now());
      const csv = await ReportCsvUtils.toCSV(output);
      Logger.of('App.ProductReportPage.downloadToCsv').info('toCSV', Date.now());
      downloadJs(csv, filename, 'text/csv');
      Logger.of('App.ProductReportPage.downloadToCsv').info('downloadJs', Date.now());
    } else {
      toastr.info('No data returned...');
    }
    setIsDownloading(false);
    Logger.of('App.ProductReportPage.downloadToCsv').info('end', Date.now());
  };

  const initialiseSelectableProducts = async () => { // this function is also updating product filter data
    Logger.of('App.ProductReportPage.updateProductFilterData').info('Updating selectable products from =>', filter.selectableProducts);

    const lineIds = filter.lineIds && filter.lineIds.length > 0 && filter.lineIds.indexOf('all') === -1
      ? [filter.lineIds]
      : (lines || []).map(l => l.id);

    const results: any = await Promise.all(
      lineIds.map(async id => ProductApi.getProductsForTimeInterval(
        match.params.siteId,
        filter.startDt.toISOString(),
        filter.endDt.toISOString(),
        id,
        filter.reportShift,
      )),
    );

    const pIds: any = [...Array.from(new Set(results.flatMap(p => p).map(p => p.id)))];
    const allReturnProducts = results.flatMap(p => p).reduce((agg, p) => {
      agg[p.id] = p;
      return agg;
    }, {});
    const products = pIds.map(id => allReturnProducts[id]);
    const activeSelectableProducts = (products.length === 0) ? allProducts : products;

    setFilter(state => {
      const reportProduct = state.reportProduct !== '' && !products.find(product => product.id === state.reportProduct) ? '' : state.reportProduct;
      return { ...state, selectableProducts: activeSelectableProducts || [], reportProduct }
    });

    setIsSelectableProductsInitialised(true);
  };

  const routeReportQueryParams = useCallback(() => {
    let dates = '';
    if (filter.timeFrameId === 'customDateRange') {
      dates = `&startDt=${filter.startDt.toISOString()}&endDt=${filter.endDt.toISOString()}`;
    }

    history.push({
      search: `${`?timeFrameId=${filter.timeFrameId}&timeIntervalId=${filter.timeIntervalId}`
        + `&reportLineDisplay=${filter.reportLineDisplay}&lineIds=${filter.lineIds && filter.lineIds.length > 0 ? filter.lineIds : 'all'}`
        + `&reportProduct=${filter.reportProduct ? filter.reportProduct : 'all'}&reportShifts=${filter.reportShifts && filter.reportShifts.length > 0 ? filter.reportShifts : 'all'}`}${dates}`,
    });

    Logger.of('App.ProductReportPage.updateProductFilterData')
      .info('Updated selectable products to =>', filter.selectableProducts);
  }, [filter, history]);

  const updateReportFilterData = (data) => {
    const { name, value } = data;
    if (name === 'lineIds') {
      setLineIdsManuallyUpdated(true);
    }
    setFilter((state) => performUpdateReportFilterData(state, name, value, site.tz, 'ProductReportPage'));

    if (name !== 'reportShifts') {
      setFilterIndex(s => s + 1);
    }
  }

  useEffect(() => {
    const [shiftsWithOccurrencesInRange] = getShiftsOccurringInRange(shifts, filter, site.tz)
    setActiveShifts(shiftsWithOccurrencesInRange);
  }, [filterIndex, shifts, site.tz]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    routeReportQueryParams();
  }, [filter, routeReportQueryParams]);

  const exportPDGWithComponent = async () => {
    setExporting(true);
  }

  useEffect(() => {
    if (exporting) {
      pdfExportComponent.save();
      setExporting(false);
    }
  }, [exporting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSelectableProductsInitialised) {
      if (history.location.search && isFilterInitialisedFirstTime) {
        getNewData();
      }
      setIsFilterInitialisedFirstTime(false);
    } else {
      initialiseSelectableProducts();
    }
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLineIdsManuallyUpdated) {
      initialiseSelectableProducts();
      setLineIdsManuallyUpdated(false);
    }
  }, [isLineIdsManuallyUpdated]) // eslint-disable-line react-hooks/exhaustive-deps

  const subHeader = intl.formatMessage({ id: 'detail_Product_Report' });

  const sliceData = (data, filter) => {
    if (filter.timeIntervalId !== 3 && filter.timeIntervalId !== 4) {
      let items = data;
      // todo fix the - need to be filtered by selected line
      const byShift = filter && (!Array.isArray(filter.reportShifts) && filter.reportShifts !== null && filter.reportShifts !== '' && filter.reportShifts !== 'all'); // prod rpt
      const byProd = filter && filter.reportProduct != null && filter.reportProduct !== ''; // prod rpt
      const byShifts = filter && (filter.reportShifts && filter.reportShifts.length > 0); // oee rpt

      if (byShift || byProd || byShifts) {
        let type;
        let productShiftKeyList;
        if (byShift && byProd) {
          type = 'intersection';
          // filter by correct product and shift intersection
          const productShiftKey = [filter.reportShifts, filter.reportProduct].sort()
            .join('_');
          productShiftKeyList = [productShiftKey];
        } else if (byShift) {
          type = 'schedule';
          productShiftKeyList = [filter.reportShifts];
        } else if (byProd) {
          type = 'product';
          productShiftKeyList = [filter.reportProduct];
        } else if (byShifts) {
          type = 'schedule';
          productShiftKeyList = [filter.reportShifts];
        }

        items = data.flatMap(d => d
          && d.slices
          && d.slices.filter(s => s.type === type
            && productShiftKeyList.includes(s.sliceKey))
            .map(s => s && ({
              ...s,
              lineId: d.lineId,
              dateHour: d.dateHour,
              date: d.date,
            })));
      }
      return items.filter(i => i);
    }
    return data;
  }

  const seriesDetails = (data) => {
    const seriesFields = ['count', 'currentTargetCount'];
    const detailsByLine = {};
    const series: Array<any> = [];
    let hasDetails = false;
    const reportData = data || [];
    // use the detail to instead of the aggregate
    reportData.map(lineData => lineData.details)
      .reduce((agg, details) => {
        if (details) {
          let filteredDetails = details || [];
          // filter by shift slices
          if (
            (filter.reportShift && filter.reportShift !== '')
            || (filter.reportProduct && filter.reportProduct !== '')
          ) {
            filteredDetails = sliceData(details, filter);
          }

          filteredDetails.forEach((d) => {
            hasDetails = true;

            seriesFields.forEach((val) => {
              if (d[val] > 0) {
                (agg[d.lineId] = agg[d.lineId] || [])
                  .push({
                    timeStamp: getLocalTimeStamp(filter, site.tz, d),
                    date: d.date,
                    siteTimeStamp: getSiteTimeStamp(filter, site.tz, d),
                    lineId: d.lineId,
                    currentTargetCount: d.currentTargetCount || d.target,
                    count: d.count,
                    [val]: d[val],
                  });
              }
            });
          });
        }
        return agg;
      }, detailsByLine);

    if (!hasDetails) return series;
    // create series for each line, by data point, data is an array of oee like thingies
    for (const key in detailsByLine) {
      if (!Object.prototype.hasOwnProperty.call(detailsByLine, key)) continue;
      series.push({
        name: seriesFields.length > 0
          ? `${LineHelper.getLineById(lines, key).title}`
          : LineHelper.getLineById(lines, key).title,
        color: LineHelper.getLineById(lines, key).display.color,
        data: detailsByLine[key],
      });
    }

    return series;
  }

  const getChartData = (data) => {
    let series: Array<any> = [];
    let custom = false;
    const newSeries = [];
    const stacked = false;
    const separateSeriesByThreshold = true;

    let reportData;
    switch (filter.reportLineDisplay) {
      case 1: // line summary
        if (
          (filter.reportShift && filter.reportShift !== '')
          || (filter.reportProduct && filter.reportProduct !== '')
        ) {
          reportData = sliceData(data, filter)
            .map(event => ({
              ...event,
              timeStamp: getLocalTimeStamp(filter, site.tz, event),
              siteTimeStamp: Utils.getLocalSiteDateObject(getLocalTimeStamp(filter, site.tz, event), site.tz),
              target: event.currentTargetCount || event.target,
            }));
        } else {
          reportData = data;
        }

        break;
      case 2: // line details
        series = seriesDetails(data);
        custom = true;
        break;
      default:
        break;
    }
    Logger.of('App.ProductReportPage.getChartData')
      .info('Here is the reportData', reportData);
    return {
      series,
      custom,
      reportData,
      newSeries,
      stacked,
      separateSeriesByThreshold,
      summary: filter.reportLineDisplay === 1,
    };
  }

  const getProductChart = (data, interval) => {
    const title = filter.reportOEEDisplay === 1 ? `${intl.formatMessage({ id: 'detail_Product' })} 2` : intl.formatMessage({ id: 'detail_Product' });
    const { series, custom, reportData, newSeries, stacked, separateSeriesByThreshold, summary } = getChartData(data);
    return (
      <div>
        {
          separateSeriesByThreshold
            ? (
              <ChartProductBar
                data={custom ? [] : reportData}
                interval={interval}
                start={filter.startDt}
                end={filter.endDt}
                customSeries={custom ? series : undefined}
                stacked={stacked}
                dynamicBarColor={false}
                title={title}
                showBarLabels={false}
                summary={summary}
                printing={exporting}
              />
            )
            : (
              <ChartProductBar
                data={custom ? [] : reportData}
                interval={interval}
                start={filter.startDt}
                end={filter.endDt}
                customSeries={custom ? newSeries : undefined}
                stacked={stacked}
                dynamicBarColor={false}
                title={title}
                showBarLabels={false}
                summary={summary}
                printing={exporting}
              />
            )
        }
      </div>
    );
  }
  const chart = (
    <div>
      {(() => {
        let dataPropName = 'data';
        const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
        switch (filter.timeIntervalId) {
          case ENUMS.INTERVALS.HOUR:
            dataPropName = 'oeeHour';
            break;
          case ENUMS.INTERVALS.DAY:
            dataPropName = 'oeeDay';
            break;
          case ENUMS.INTERVALS.WEEK:
            dataPropName = 'oeeWeek';
            break;
          case ENUMS.INTERVALS.MONTH:
            dataPropName = 'oeeMonth';
            break;
          default:
            break;
        }

        if (site && site[dataPropName] && site[dataPropName].error) {
          return getTimeoutMessage(intl);
        }
        const data = (site && site[dataPropName] && site[dataPropName].data) || [];
        const productChart = getProductChart(filter.isDirty ? [] : data, interval);

        return data.length > 0
          ? productChart
          : filter.isDirty ? productChart : getNoContentMessage(intl);
      })()}
    </div>
  );

  return <>
    { sites.length > 0 && <>
      <BarStyledContentCard>
        <CardContent className={classes.content}>
          <ReportHeader
            enableShiftFilter
            enableProductFilter
            filter={filter}
            getNewData={() => getNewData()}
            lines={lines}
            products={filter.selectableProducts}
            isLoading={isLoading}
            reportType={ReportTypes.Product}
            saved={filter.saved}
            shifts={shiftsFormatted}
            siteId={selectedSiteId}
            title={filter.title || ''}
            singleSelectionFilters={true}
            updateReportFilterData={updateReportFilterData}
            subHeader={subHeader}
            enableTimeInterval={true}
          />
        </CardContent>
      </BarStyledContentCard>
      <StyledContentCard>
        <CardContent className={classes.content}>
          {
            isDownloading && <>
              <LinearProgressWithLabel
                label={intl.formatMessage({ id: 'detail_CsvData' })}
                progressValue={downloadProgressIndex}
                maxValue={downloadProgressCount} />
              <div className={classes.overlay}></div>
            </>
          }
          <ReportDownload
            downloadToCsv={() => downloadToCsv()}
            exportToPdf={() => exportPDGWithComponent()}
            isDownloading={false}
            isLoading={isLoading}
          />
          <div className={classes.pdfContainer}>
            <PDFExport
              ref={component => {
                setPdfExportComponent(component);
              }}
              margin="5mm"
              paperSize="A4"
              landscape
              scale={0.8}
            >
              {exporting ? (
                <div>
                  <div>
                    <div>{filter.title || subHeader}</div>
                    <div>{(filter.title && filter.title !== subHeader) ? subHeader : undefined}</div>
                  </div>
                  {chart}
                </div>
              )
                : chart}

            </PDFExport>
          </div>
        </CardContent>
      </StyledContentCard>
    </>
    }
    {
      isLoading && <ProgressIndicator />
    }
  </>
};

export default ProductReportPage;
