import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const VideojetConnectRemoteService = "VideojetConnect™ Remote Service";
export const VideojetConnectExchange = "VideojetConnect™ Exchange";
export const VideojetConnectInsight = "VideojetConnect™ Insight";
export const VideojetConnectInspect = "VideojetConnect™ Inspect";
export const VideojetConnectDesign = "VideojetConnect™ Design";

export const DEFAULT_TIME_ZONE = 'UTC';
export const SHORT_AUTO_CLOSE_TIME = 5000; // 2 secs.
export const MULTIPLE_SELECTOR_TRUNCATION_LENGTH = 85;
export const MINIMUM_ROLE_TITLE_LENGTH = 3;
export const MINIMUM_ROLE_DESCRIPTION_LENGTH = 3;
export const hideWorkingInProgress = true;
export const DEBOUNCED_SEARCH_DELAY = 500;
export const SMALL_NUMBER_OF_ROWS = 4;

export const ReportTypeLocal = 'local';
export const ReportTypeGlobal = 'global';

export const API_MODULE_NAME = 'api';

export const NUMBER_OF_TOTAL_MODULES = 3;

export const licensedModuleInfo = {
  design: { name: 'Design' },
  inspect: { name: 'Inspect' },
  intelligence: { name: 'Intelligence' },
  orderexe: { name: 'Order Execution' },
  beta: { name: 'Beta' },
  vrs: { name: 'vrs' },
  api: { name: API_MODULE_NAME },
};

export const wholeRow: any = { lg: 12, md: 12, sm: 12, xs: 12 };

export const LINE_IN_ONCE = 7;
export const LINE_FETCH_DELAY = 100; // ms
export const LINE_STATE_BAR_REFRESH_DELAY = 500 // ms

export const wide4kLayoutBreakPoint = 3000;
export const vert4kLayoutBreakPoint = 2000;

export const RedTheme = createMuiTheme({
  palette: {
    primary: red,
  },
});

export const PAGE_SIZE = 50;
export const REPORT_PAGE_SIZE = 20;
export const REPORT_DOWNLOAD_PAGE_SIZE = 20;
export const REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE = 2000;
export const REPORT_DOWNLOAD_PROCESSING_PAGE_SIZE = 2000;
