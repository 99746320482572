import React from 'react';
import {
  Checkbox,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import classNames from 'classnames';
import { useStyledDropdownStyles } from './StyledDropdown.css';
import { truncateString } from '../../utilities/stringutils';
import { MULTIPLE_SELECTOR_TRUNCATION_LENGTH } from '../../constants/global';
import { Clear } from '@material-ui/icons';
import { useIntl } from 'react-intl';

export const StyledDropDown = ({
  labelId,
  label,
  name,
  multiple,
  enableRemoveButton,
  placeholder,
  options,
  value,
  onChange }: any) => {
  const classes = useStyledDropdownStyles();
  const intl = useIntl();
  const hasAnyValue = (): boolean => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    if (String(value).length > 0) {
      return true;
    }

    return false;
  };

  const removeAll = () => {
    onValueChange({ target: { value: multiple ? [] : '' } });
  };

  const onValueChange = (event) => {
    const value = event.target.value;
    if (multiple && value.length > 0 && value.indexOf('all') > -1) {
      const allIndex = value.indexOf('all');
      const activeValue = value.filter((val, index) => index !== allIndex);
      onChange(activeValue);
    } else {
      onChange(value);
    }
  }

  const getValue = () => {
    if (multiple && value.length === 0) {
      return ['all']
    }

    if (multiple && value.length > 0 && value.indexOf('all') > -1) {
      const allIndex = value.indexOf('all');
      const activeValue = value.filter((val, index) => index !== allIndex);
      return activeValue;
    }

    return value ? value : 'all';
  }

  const noOptionAvailableText = options && options.length === 0 ? `${intl.formatMessage({ id: 'detail_NoItemExitsToSelect' })}` : '';

  const iconComponent: any = !noOptionAvailableText && (multiple || enableRemoveButton) ?
    { IconComponent: () => <IconButton onClick={removeAll}><Clear /></IconButton> }  /* eslint-disable-line react/display-name */
    : {};

  return <>
    <InputLabel
      className={classNames({ [classes.dimmedLabel]: !hasAnyValue() })}
      id={labelId}>
      {label}
    </InputLabel>
    <Select
      labelId={labelId}
      multiple={multiple}
      name={name}
      value={getValue()}
      onChange={onValueChange}
      disabled={(options && options.length === 0)}
      displayEmpty={enableRemoveButton}
      {...iconComponent}
      label={label}
      renderValue={(selected: any) => {
        if (noOptionAvailableText) {
          return noOptionAvailableText;
        }
        if (multiple && selected && selected.length > 0 && selected[0] === 'all') {
          return placeholder
        }
        if (multiple) {
          return truncateString(options.filter((el) =>
            (selected as Array<unknown>).indexOf(String(el.value)) > -1)
            .map((el) => el.text).join(', ') || '', MULTIPLE_SELECTOR_TRUNCATION_LENGTH);
        }

        const selectedOption = options.find((el) => String(el.value) === String(selected));
        return selectedOption && selectedOption.text ? selectedOption.text : placeholder;
      }}
    >
      {
        [{ value: 'all', text: placeholder }, ...options].map((el) => {
          return <MenuItem value={el.value} key={el.value} style={{ display: el.value === 'all' ? 'none' : '' }}>
            {multiple && <Checkbox checked={getValue().indexOf(el.value) > -1} classes={{ root: classes.checkbox }} />}
            <ListItemText primary={el.text} />
          </MenuItem>
        })
      }
    </Select>
  </>
}
