import { withStyles } from '@material-ui/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export { StyledDialogActions }
