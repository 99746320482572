import { makeStyles } from "@material-ui/core";
import { myRed } from "../../../constants/colors";

export const useUserManagementPageStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: theme.spacing(2.5),
  },
  formControl: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.spacing(70),
    flexFlow: 'row',
    alignItems: 'center',
    '&:nth-last-child(n + 2)': {
      marginBottom: theme.spacing(1.875)
    }
  },
  controlContainer: {
    marginLeft: theme.spacing(3.75)
  },
  actionButtons: {
    justifyContent: 'flex-start',
    maxWidth: theme.spacing(70)
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2.5)
  },
  error: {
    color: myRed
  }
}));
