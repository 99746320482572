import { makeStyles } from "@material-ui/core";
import { myRed, mySecondaryColor, myWhite } from "../../constants/colors";

import loginImage from "../../loginImages/my_login_background.png";

export const useLoginStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${loginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: myWhite,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: theme.spacing(30)
    },
    padding: theme.spacing(0.625)
  },
  logo: {
    minWidth: theme.spacing(75),
    [theme.breakpoints.down('md')]: {
      minWidth: theme.spacing(62.5),
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(50),
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(25),
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%'
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    maxWidth: theme.spacing(20)
  },
  email: {
    maxWidth: theme.spacing(70)
  },
  password: {
    maxWidth: theme.spacing(70)
  },
  errorMessage: {
    textAlign: "center",
    color: myRed
  },
  loginBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  newAccountDivider: {
    marginBottom: theme.spacing(2)
  },
  newAccountHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  newAccountIcon: {
    color: mySecondaryColor,
    marginRight: theme.spacing(0.5)
  }
}));
