export default {
  thresholds: {
    max: {
      value: 0.8,
      hex: '#3aba23',
      colorName: 'green',
      n: '> 80%',
    },
    med: {
      value: 0.7,
      hex: '#fcff26',
      colorName: 'yellow',
      n: '80% - 60%'
    },
    min: {
      value: 0.6,
      hex: '#f5a623',
      colorName: 'orange',
      n: '< 60%',
    },
  },
}
