import { makeStyles } from "@material-ui/styles";
import { myDisabledColor, myCorporatePrimaryColor, mySecondarySelectedColor, myWhite, myWhiteColorDimmed } from "../../../../constants/colors";

export default makeStyles((theme: any)  => ({
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: mySecondarySelectedColor,
    },
  },
  linkActive: {
    backgroundColor: mySecondarySelectedColor,
  },
  linkNested: {
    "&:hover": {
      backgroundColor: mySecondarySelectedColor,
    },
  },
  linkIcon: {
    color: myWhiteColorDimmed,
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    color: myCorporatePrimaryColor,
  },
  linkText: {
    padding: 0,
    color: myWhiteColorDimmed,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: theme.spacing(1.875),
    fontWeight: 600
  },
  clickableTitle: {
    paddingLeft: theme.spacing(1, '!important'),
  },
  largeText: {
    fontSize: theme.spacing(1.875),
  },
  collapseIcon: {
    color: myWhiteColorDimmed,
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  collapseInSmall: {
    marginLeft: '-30px'
  },
  linkTextActive: {
    color: myCorporatePrimaryColor,
  },
  linkTextDisabled: {
    color: myDisabledColor,
    opacity: 0.5
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: 0,
  },
  nestedListSmall: {
    paddingLeft: theme.spacing(2) + 10,
  },
  linkSectionTitleText: {
    fontSize: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: `${myWhite} !important`,
  },
  sectionTitle: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.125),
    fontSize: theme.spacing(2),
    color: myWhite,
  },
  collapsableSectionTitle: {
    marginTop: theme.spacing(1.875),
    paddingLeft: theme.spacing(1.125)
  },
  collapsableSectionTitleText: {
    fontSize: theme.spacing(2),
    color: myWhite,
  },
  divider: {
    marginTop: theme.spacing(2),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
  dividerSingle: {
    height: 1,
    backgroundColor: "#D8D8D880",
  }
}));
