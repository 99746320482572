import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@material-ui/core';

import { Extension as ExtensionIcon } from '@material-ui/icons';

import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useSiteActions } from '../../../actions/siteActions';

import { useOperationsPageStyles } from './OperationsPage.css'
import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog';
import { MigrateSitesResultDialog } from '../MigrateSitesResultDialog/MigrateSitesResultDialog';
import { getCurrentUser } from '../../../libs/awsLib';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

const OperationsPage = () => {
  const classes = useOperationsPageStyles();
  const siteActions = useSiteActions();

  const intl = useIntl();
  const setTitle = useSetTitleAction();
  const { selectedSiteId } = useAppGlobalState();

  const [migrating, setMigrating] = useState(false);
  const [migrationConfirmDialogType, setMigrationConfirmDialogType] = useState('');
  const [resultItems, setResultItems] = useState<Array<any>>([]);

  useEffect(() => {
    setTitle('detail_Operations', <ExtensionIcon />, {
      parents: [{
        id: 'detail_Administrator',
      }]
    });
  }, [setTitle]);

  const callMigrateSite = async (syncType) => {
    setMigrationConfirmDialogType(syncType);
  }

  const onCloseMigrationConfirmDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        const syncType = migrationConfirmDialogType;
        setMigrating(true);
        setMigrationConfirmDialogType('');
        const result = await siteActions.migrateSites(syncType);
        if (result && result.processedPlants && result.processedPlants.length > 0) {
          const processedItems = result.processedPlants.map(el => ({
            siteId: el.PlantID,
            title: el.action.newlyMigratedSite.title,
            companyId: el.CompanyID,
            actionType: el.action.type,
            siteUpdated: el.action.noUpdateNecessary === undefined ? '' : el.action.noUpdateNecessary ? 'NO' : 'YES'
          }));
          setResultItems(processedItems);
        }
      } catch (e) {
        toastr.error(e);
      } finally {
        setMigrating(false);
      }
    }
    setMigrationConfirmDialogType('');
  }

  const onCloseMigrationResultDialog = () => {
    setResultItems([]);
    siteActions.loadSites().then(() => {
      const currentUser = getCurrentUser();
      siteActions.setSite(selectedSiteId, currentUser);
    });
  }

  return <>
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.title}>
          <Typography variant="h5">{intl.formatMessage({ id: 'detail_MigrateSites' })}</Typography>
        </div>
        <div className={classes.migrateBtn}>
          <Button
            variant="contained"
            disabled={migrating}
            onClick={() => {
              callMigrateSite('FULL');
            }}>
            <FormattedMessage id="detail_MigrateSite_Full" />
          </Button>
        </div>
        <div className={classes.migrateBtn}>
          <Button
            variant="contained"
            disabled={migrating}
            onClick={() => {
              callMigrateSite('INCREMENTAL');
            }}>
            <FormattedMessage id="detail_MigrateSite_Incremental" />
          </Button>
        </div>
        {migrating && <div>
          <Typography variant="h6">{intl.formatMessage({ id: 'detail_MigratingSites' })}</Typography>
        </div>
        }
      </CardContent>
      <CardActions className={classes.actionButtons}>
      </CardActions>
    </Card>
    <ConfirmDialog
      saving={migrating}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={!!migrationConfirmDialogType}
      onClose={onCloseMigrationConfirmDialog}
      content={<><FormattedMessage id={'detail_MigrateSitesConfirm'} /><span>{migrationConfirmDialogType ? ` (${migrationConfirmDialogType})` : ''}</span></>}
    />
    {
      resultItems.length > 0 && <MigrateSitesResultDialog
        items={resultItems}
        open={resultItems.length > 0}
        onClose={onCloseMigrationResultDialog} />
    }
  </>
};

export { OperationsPage };
