import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import toastr from 'toastr';
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Divider, Fade } from '@material-ui/core';

import { loginUser, useAppGlobalDispatch } from '../../context/AppContext/AppContext';
import { useSessionStorage } from '../../context/SessionStorageContext/SessionStorageContext';
import { Logger } from '../../utilities/Logger/Logger';

import config from '../../config';
import { useLoginStyles } from './Login.css';
import logo from "../../loginImages/my_login_logo.svg";
import { TermsDialog } from '../TermsDialog/TermsDialog';
import { NewPasswordDialog } from '../NewPasswordDialog/NewPasswordDialog';
import { PasswordResetDialog } from '../PasswordResetDialog/PasswordResetDialog';
import Signup from '../Signup/Signup';

const loginBreaks: any = {
  image: { lg: 8, md: 7, sm: 12, xs: 12 },
  login: { lg: 4, md: 5, sm: 12, xs: 12 },
};

interface IAuthDetails {
  Username?: string;
  Password?: string;
  getUsername: any;
  getPassword: any;
  getValidationData: any;
}
function Login() {
  const intl = useIntl();
  const history = useHistory();
  const classes = useLoginStyles();

  const userSessionStorage = useSessionStorage();
  const userDispatch = useAppGlobalDispatch();
  const [error, setError] = useState(false);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [authenticationDetails, setAuthenticationDetails] = useState<IAuthDetails>();

  const [cognitoError, setCognitoError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  const [createDesign, setCreateDesign] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);

  // local
  const [isLoading, setIsLoading] = useState(false);

  const setUser = (userProfile: any) => {
    loginUser(userDispatch,
      userSessionStorage,
      userProfile,
      history,
      setIsLoading,
      setError);
  }

  useEffect(() => {
    if (newPasswordRequired) {
      setShowTermsModal(true);
      setAccepted(false);
    }
  }, [newPasswordRequired]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!createDesign && accepted) {
      setShowTermsModal(false);
      setShowNewPasswordModal(newPasswordRequired);
    }
  }, [createDesign, accepted, newPasswordRequired]);

  useEffect(() => {
    if (createDesign) {
      if (!accepted) {
        setShowTermsModal(true);
      } else {
        // history.push('/signup/design');
        setShowCreateAccount(true);
      }
    }
  }, [createDesign, accepted, history])

  const login = async (userEmail: string, userPassword: string) => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });
    const user = new CognitoUser({ Username: userEmail, Pool: userPool });
    const authDetails = new AuthenticationDetails({
      Username: userEmail,
      Password: userPassword,
    });
    Logger.of('Login.login').info(`for ${userEmail}`);

    return new Promise((resolve, reject) => user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        Logger.of('Login.login').info('logged in successful', result);
        resolve(true);
      },
      newPasswordRequired: () => {
        setNewPasswordRequired(true);
        setAuthenticationDetails(authDetails);
        setIsLoading(false);
        setError(false);
        setCognitoError('');
      },
      onFailure: (err) => {
        Logger.of('Login.login').info('got an error =>', err);
        reject(err);
      },
    }));
  };

  // wrapped because it is an effect dependency
  const handleNewPasswordSubmit = useCallback(async () => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });
    const user = new CognitoUser({ Username: loginValue, Pool: userPool });
    Logger.of('Login.handleNewPasswordSubmit').info('userPool =>', userPool, authenticationDetails);
    if (!authenticationDetails) {
      return Promise.reject(false);
    }

    return new Promise((resolve, reject) => user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        Logger.of('Login.handleNewPasswordSubmit').info('logged in successful', result);
        resolve(true);
      },
      newPasswordRequired: () => {
        Logger.of('Login.handleNewPasswordSubmit').info('new password required 3');

        user.completeNewPasswordChallenge(newPassword, null, {
          onSuccess: (result) => {
            Logger.of('Login.handleNewPasswordSubmit').info('success', result);
            setShowNewPasswordModal(false);
            setError(false);
            setPasswordValue('');
            setIsLoading(false);
          },
          onFailure: (error) => {
            setCognitoError(error.message);
            setIsLoading(false);
            Logger.of('Login.handleNewPasswordSubmit').info('error', error.message);
            toastr.error(error.message, 'New Password', { timeOut: 10000 });
            reject(error);
          },
        });
      },
      onFailure: (error) => {
        Logger.of('Login.handleNewPasswordSubmit').info('got an error =>', error);
        setCognitoError(error.message);
        setIsLoading(false);
        toastr.error(error(error.message, 'Password Reset', { timeOut: 10000 }));
        throw (error);
      },
    }));
  }, [authenticationDetails, loginValue, newPassword]);

  // the password component will set a newpassword to submit
  useEffect(() => {
    if (newPassword !== '') {
      handleNewPasswordSubmit().then(() => Logger.of('Login.useEffect newPassword').info('submitted'));
    }
  }, [handleNewPasswordSubmit, newPassword]);

  const loginWithUserDetails = async (email: string, password: string) => {
    setIsLoading(true);
    await login(email.toLowerCase(), password)
      .then(async () => {
        setError(false);
        setIsLoading(false);
        setPasswordValue(password);

        const userProfile = {
          email,
          selectedSiteId: new Cookies().get('LastSite')
        };

        setUser(userProfile);

      })
      .catch((error) => {
        setCognitoError(error.message);
        setIsLoading(false);
      });
  }

  const onCloseTermsDialog = (hasUserAccepted) => {
    setShowTermsModal(false);
    setAccepted(hasUserAccepted);
    if (!hasUserAccepted) {
      setNewPasswordRequired(false);
      setCreateDesign(false);
    }
  }

  const onCloseCreateAccountDialog = () => {
    setShowCreateAccount(false);
    setCreateDesign(false);
  }

  const onCloseNewPasswordDialog = (newPassword) => {
    if (newPassword) {
      setNewPassword(newPassword);
      setIsLoading(true);
    } else {
      setNewPasswordRequired(false);
      setShowNewPasswordModal(false);
    }
  }

  const onClosePasswordResetDialog = () => {
    setShowPasswordResetModal(false);
  }

  return <>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item {...loginBreaks.image} xs={false} className={classes.image}>
        <img id="logo" className={classes.logo} src={logo} alt="logo" />
      </Grid>
      <Grid item {...loginBreaks.login} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {isLoading &&
            <CircularProgress size={26} />
          }
          <Typography component="h1" variant="h5">
            {intl.formatMessage({ id: 'detail_WelcomeVideojetConnect' })}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: 'detail_Email' })}
              name="email"
              autoComplete="email"
              autoFocus
              value={loginValue}
              disabled={isLoading}
              className={classNames(classes.email, 'email-txt')}
              onChange={(event) => { const value = event.target.value; setLoginValue(() => value) }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.formatMessage({ id: 'detail_Password' })}
              type="password"
              id="password"
              autoComplete="current-password"
              value={passwordValue}
              className={classNames(classes.password, 'password-txt')}
              disabled={isLoading}
              onChange={(event) => { const value = event.target.value; setPasswordValue(() => value) }}
            />
            <div className={classes.loginBtn}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  loginValue.length === 0 || passwordValue.length === 0 || isLoading
                }
                className={classNames(classes.submit, 'signin-btn')}
                onClick={() => loginWithUserDetails(loginValue,
                  passwordValue).catch((err) => {
                    setError(() => err);
                  })}
              >{intl.formatMessage({ id: 'detail_Login' })}
              </Button>
            </div>
            <Divider className={classes.newAccountDivider} />
            <div className={classes.newAccountHeader}>
              <PersonAddIcon className={classes.newAccountIcon} />
              <Typography>
                {intl.formatMessage({ id: 'detail_Do_you_have_an_account' })}
              </Typography>
            </div>

            <div className={classes.loginBtn}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classNames(classes.submit, 'signin-btn')}
                // DE-903 bypasses this modal, until we have migrated VRS sites
                // onClick={() => setShowCreateAccount(true)}
                onClick={() => setCreateDesign(true)}
              >{intl.formatMessage({ id: 'detail_Create_Account' })}
              </Button>
            </div>
            <div className={classes.loginBtn}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classNames(classes.submit, 'reset-btn')}
                onClick={() => setShowPasswordResetModal(true)}
              >{intl.formatMessage({ id: 'detail_Password_Reset' })}
              </Button>
            </div>
            <Grid container>
              <Grid item xs>
                <Fade in={error || !!cognitoError}>
                  <Typography className={classes.errorMessage}>
                    {intl.formatMessage({ id: 'detail_LoginError' })}
                  </Typography>
                </Fade>
              </Grid>
            </Grid>
            <div id="warning-txt" className="warning">{error}</div>
          </form>
        </div>
      </Grid>
    </Grid>
    <TermsDialog
      open={showTermsModal}
      onClose={onCloseTermsDialog}
    />
    <Signup
      open={showCreateAccount}
      onClose={onCloseCreateAccountDialog}
    />
    <NewPasswordDialog
      loading={isLoading}
      open={showNewPasswordModal}
      onClose={onCloseNewPasswordDialog}
    />
    <PasswordResetDialog
      open={showPasswordResetModal}
      onClose={onClosePasswordResetDialog}
    />
  </>
}

Login.displayName = 'Login';

export default withRouter(Login);
