import React, { useCallback } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { usePagingStyle } from './Paging.css';
import { PAGE_SIZE } from '../../constants/global';

import {
  useTheme,
  useMediaQuery
} from '@material-ui/core';
export interface IPagingProps {
  page: number;
  count: number;
  total: number;
  pageSize?: number;
  pageDetails?: string;
  onChange: (page: number) => void;
}

const SMALL_PAGE_NUMBER = 4;

const Paging = ({ page, count, onChange, total, pageSize =  PAGE_SIZE, pageDetails = ''}: IPagingProps) => {
  const classes = usePagingStyle();
  const onPageChange = useCallback((_, p) => {
    onChange(p);
  }, [onChange]);

  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('xs')) && count > SMALL_PAGE_NUMBER;

  return <div className={smallSize? classes.rootSmall : classes.root}>
    <Pagination
      count={count}
      page={page}
      onChange={onPageChange}
      variant="outlined"
      shape="rounded"
      showFirstButton={true}
      showLastButton={true}/>
    <span className={classes.pageDetail}>{ pageDetails ? pageDetails : `${(page - 1) * pageSize + 1}-${page * pageSize > total ? total : page * pageSize} of ${total}`}</span>
  </div>;
}

export { Paging };
