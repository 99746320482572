
export const INTERVALS = Object.freeze({
  HOUR: 1,
  DAY: 2,
  WEEK: 3,
  MONTH: 4,
  QUARTER: 5,
  YEAR: 6,
  instances: {
    1: { translationKey: 'detail_Hour', text: 'Hour', value: 1, api: 'h', downtimeRptApi: 'h', chartBaseUnit: 'date' },
    2: { translationKey: 'detail_Day', text: 'Day', value: 2, api: 'd', downtimeRptApi: 'd', chartBaseUnit: 'date' },
    3: { translationKey: 'detail_Week', text: 'Week', value: 3, api: 'd', downtimeRptApi: 'w', chartBaseUnit: 'weeks' },
    4: { translationKey: 'detail_Month', text: 'Month', value: 4, api: 'd', downtimeRptApi: 'm', chartBaseUnit: 'months' },
    5: { translationKey: 'detail_Quarter', text: 'Quarter', value: 5, api: 'Q' },
    6: { translationKey: 'detail_Year', text: 'Year', value: 6, api: 'y' },
  }

});

export const DATE_TIME_PARTS = Object.freeze({
  YEAR: 1,
  MONTH: 2,
  DAY: 3,
  HOUR: 4,
  MINUTE: 5,
  SECOND: 6
});

export const DOWNTIME_TYPE_IDS = Object.freeze({
  PLANNED: '1',
  UNPLANNED: '2',
});


export enum USAGE_PLAN_TYPES {
  lowUsage = 'lowUsage',
  defaultUsage = 'defaultUsage',
  highUsage = 'highUsage'
}
