import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import notifyApi from '../../../api/prodNotificationApi';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';
import { Logger } from '../../../utilities/Logger/Logger';
import { useReportSaveModalStyles } from './ReportSaveModal.css';

const ReportSaveModal = (props) => {
  const intl = useIntl();
  const classes = useReportSaveModalStyles();
  const { cancel, open, reportType, siteId, timeFrame, title: savedTitle, username } = props;
  const [isGlobalReport, setGlobalReport] = useState(false);
  const [title, setTitle] = useState(savedTitle);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [canOverwrite, setOverwriteReport] = useState(false);

  useEffect(() => setTitle(savedTitle), [savedTitle]);

  const handleSave = (schedule) => {
    if (isGlobalReport) {
      notifyApi
        .saveGlobalUserReport({ siteId, timeFrame, title, type: reportType, url: window.location.href }, canOverwrite)
        .then((result) => {
          setAlreadyExists(false);
          // eslint-disable-next-line no-unused-expressions
          props.onSave && props.onSave({ isGlobalReport, report: result, success: true, schedule });
          toastr.success(intl.formatMessage({ id: 'detail_ReportSaved' }));
          Logger.of('App.AssessmentsReportPage.onSaveReportRecipients').info('Saved report', { result });
        })
        .catch((err) => {
          if (!err.message.includes('Already exists')) {
            toastr.error(err);
          } else {
            setAlreadyExists(true);
          }
          props.onSave && props.onSave(false);
          Logger.of('App.AssessmentsReportPage.onSaveReportRecipients').error('Failed to save report', err);
        });
    } else {
      notifyApi
        .saveUserReport({ siteId, timeFrame, title, type: reportType, url: window.location.href, userId: username }, canOverwrite)
        .then((result) => {
          setAlreadyExists(false);
          // pass to parent component if interested
          props.onSave && props.onSave({ isGlobalReport, report: result, success: true, schedule });
          toastr.success(intl.formatMessage({ id: 'detail_ReportSaved' }));
          Logger.of('App.AssessmentsReportPage.onSaveReportRecipients').info('Saved report', { result });
        })
        .catch((err) => {
          if (!err.message.includes('Already exists')) {
            toastr.error(err);
          } else {
            setAlreadyExists(true);
          }
          props.onSave && props.onSave(false);
          Logger.of('App.AssessmentsReportPage.onSaveReportRecipients').error('Failed to save report', err);
        });
    }
  };

  const can = true;

  return <Dialog open={open}
    aria-labelledby="save-report-title"
    classes={{ paper: classes.root }}>
    <DialogTitle id="form-dialog-title">
      <FormattedMessage id="detail_SaveThisReport" />
    </DialogTitle>
    <DialogContent dividers>
      <div className={classes.controlContainer}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            autoFocus
            autoComplete="off"
            variant="outlined"
            margin="normal"
            required
            id="name"
            label={intl.formatMessage({ id: 'detail_Name' })}
            type="text"
            onChange={e => setTitle(e.target.value)}
            value={title}
            fullWidth
          />
        </FormControl>
      </div>
      <div className={classes.controlContainer}>
        <label><FormattedMessage id="detail_GlobalReport" /></label>
        <StyledSwitch
          checked={isGlobalReport}
          disabled={!can}
          onChange={(e, checked) => setGlobalReport(checked)}
          name="isglobalReport" />
      </div>
      {
        alreadyExists && <div className={classes.controlContainer}>
          <label><FormattedMessage id="detail_Overwrite" /></label>
          <StyledSwitch
            checked={canOverwrite}
            disabled={!can}
            onChange={(e, checked) => setOverwriteReport(checked)}
            name="canOverride" />
        </div>
      }

    </DialogContent>
    <DialogActions>
      <Button onClick={cancel}
        color="default"
        variant="contained"
      >
        <FormattedMessage id="detail_Cancel" />
      </Button>
      <Button
        disabled={!title.length}
        onClick={() => handleSave(false)}
        color="primary"
        variant="contained">
        <FormattedMessage id="detail_Save" />
      </Button>
      <Button onClick={() => handleSave(true)}
        disabled={!title.length}
        color="primary"
        variant="contained"
      >
        <FormattedMessage id="detail_SaveAndSchedule" />
      </Button>
    </DialogActions>
  </Dialog>;
};

ReportSaveModal.defaultProps = {};

ReportSaveModal.propTypes = {
  cancel: func.isRequired,
  open: bool,
  onSave: func,
  reportType: string,
  siteId: string.isRequired,
  timeFrame: string,
  title: string,
  username: string,
};

export default ReportSaveModal;
