import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { useSimpleLinearProgressStyles } from './SimpleLinearProgress.css';


const SimpleLinearProgress = () => {
  const classes = useSimpleLinearProgressStyles();
  return <div className={classes.root}>
  <div className={classes.linearProgress}>
    <LinearProgress />
  </div></div>
}

export { SimpleLinearProgress };

