import React from 'react';
import { Redirect, Route } from "react-router";
import { useSiteActions } from '../../actions/siteActions';
import { useAppGlobalState } from '../../context/AppContext/AppContext';
import { getCurrentUser } from '../../libs/awsLib';
import { NotAuthorised } from "../NotAuthorised/NotAuthorised";
import { ProgressIndicator } from '../ProgressIndicator/ProgressIndicator';

interface CheckAuthorisedRouteProps {
  path: string;
  component?: any;
  accessInfo?: (path: string) => any;
  params: {
    [key: string]: string;
  };
  children?: any;
  forceToSites?: boolean;
}

const CheckAuthorisedRoute = ({ path, component, accessInfo, params, children, forceToSites }: CheckAuthorisedRouteProps) => {

  const accessPath = params ? Object.keys(params).reduce((acc, key) => {
    return acc.replace(`:${key}`, params[key])
  }, path) : path;

  const { sites, siteInitialised } = useAppGlobalState();
  const siteActions = useSiteActions();

  const access = accessInfo ? accessInfo(accessPath) : null;
  const isVisible = access ? access.hidden === undefined || !access.hidden : true;
  const authorised = access ? access.disabled === undefined || !access.disabled : true;

  if (forceToSites && isVisible && !authorised) {
    if (sites && sites.length > 0) {
      (async () => {
        const currrentUser = await getCurrentUser();
        if (currrentUser) {
          siteActions.setSite(sites[0].id, currrentUser);
        }
      })();
    }
    return <Redirect to={'/sites'} />
  }

  if (component) {
    return siteInitialised ? <Route path={path} component={isVisible && authorised ? component : NotAuthorised} />
      : <ProgressIndicator full={true} />
  }

  return siteInitialised ? <Route path={path}>
    {isVisible && authorised ? children : NotAuthorised}
  </Route> : <ProgressIndicator full={true} />;
}

export { CheckAuthorisedRoute }
