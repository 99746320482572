import { makeStyles } from "@material-ui/core";

export const useReportSaveModalStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(0.75)
  },
  formControl: {
    display: 'flex',
    width: '100%',
    flexFlow: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1.875)
  },
}));
