import React from 'react';
import { useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';

interface IDeviceRoleListRow {
  deviceRole: any;
  siteId: string;
  gridBreak: any;
}

const DeviceRoleListRow = ({ deviceRole,
  gridBreak }: IDeviceRoleListRow) => {
  const intl = useIntl();

  return (
    <StyledGridRow>
      <StyledGridCell {...gridBreak.title}>
        {deviceRole.title}
      </StyledGridCell>

      <StyledGridCell{...gridBreak.actionButtons}>
        <StyledActionContainer>
          <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
            <ActionButton>
              <EditIcon />
            </ActionButton>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
            <ActionButton>
              <DeleteIcon />
            </ActionButton>
          </Tooltip>
        </StyledActionContainer>
      </StyledGridCell>
    </StyledGridRow>
  )
};

export default DeviceRoleListRow;
