import { makeStyles } from "@material-ui/core";
import {
  myBlack,
  myGrayShade4,
  myWhiteShade4,
  myGreyDimmed,
  mySecondaryColor,
  mySecondaryColorDimmed,
  myWhite,
  myPrimaryColor
} from "../../../constants/colors";

export const useChipSelectorStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1.25, 0.625),
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.625, 0.825),
    backgroundColor: myPrimaryColor
  },
  chipSimple: {
    color: myBlack,
    backgroundColor: myGreyDimmed,
    margin: theme.spacing(0.625, 0.825)
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(0.625)
  },
  clipClose: {
    color: myWhite,
    marginTop: theme.spacing(-0.625),
    marginRight: theme.spacing(0.9),
    '&:hover': {
      color: myWhiteShade4,
    }
  },
  clipCloseSimple: {
    color: mySecondaryColor,
    marginTop: theme.spacing(-0.625),
    marginRight: theme.spacing(0.8),
    '&:hover': {
      color: mySecondaryColorDimmed,
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  dimmedLabel: {
    color: myGrayShade4
  },
  select: {
  },
  outlined: {
  }
}));
