import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import { FormattedMessage, useIntl } from 'react-intl';

import { StyledDialogContent } from '../../../components/StyledDialogContent/StyledDialogContent';
import { StyledDialogActions } from '../../../components/StyledDialogActions/StyledDialogActions';

import { useExportToFileDialogStyles } from './ExportToFileDialog.css';
import { StyledDropDown } from '../../../components/StyledDropdown/StyledDropdown';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';

interface ExportToFileDialogProps {
  title;
  showEmptyChoice: boolean;
  open: boolean;
  onClose: (languages: Array<string>, fileName: string, emptySelected?: boolean) => void;
  allLanguages: Array<any>;
}

const ExportToFileDialog = ({
  title,
  showEmptyChoice,
  open,
  onClose,
  allLanguages }: ExportToFileDialogProps) => {
  const intl = useIntl();
  const classes = useExportToFileDialogStyles();
  const [selectedLanguages, setSelectedLanguages] = useState<Array<string>>([]);
  const [fileName, setFileName] = useState<string>('');
  const [blankValueOnly, setBlankValueOnly] = useState<boolean>(false);

  const onExportContinue = () => {
    onClose(selectedLanguages, fileName, blankValueOnly);
  }

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={'sm'}
    disableEscapeKeyDown
    aria-labelledby="confirm" open={open}>
    <DialogTitle>
      {title}
    </DialogTitle>

    <StyledDialogContent dividers>
      <Grid container>
        {showEmptyChoice && <Grid container>
          <Grid item xs={5} className={classes.label}>
            <Typography variant="h6"><FormattedMessage id="detail_BlankValueOnly" /></Typography>
          </Grid>
          <Grid item xs={7} className={classes.controlItem}>
            <FormControl variant="outlined" className={classes.control}>
              <StyledSwitch checked={blankValueOnly}
                onChange={(e, checked) => setBlankValueOnly(checked)}
                name="bankValueOnly" />
            </FormControl>
          </Grid>
        </Grid>
        }

        <Grid container>
          <Grid item xs={12} className={classes.controlItem}>
            <FormControl variant="outlined" className={classes.control}>
              <StyledDropDown
                labelId="selectedLanguages"
                multiple={true}
                name="selectedLanguages"
                label={intl.formatMessage({ id: 'detail_SelectLanguage' })}
                placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
                options={allLanguages}
                value={selectedLanguages}
                onChange={value => setSelectedLanguages(value)}
              />
            </FormControl>
          </Grid>

          <Grid container>
            <Grid item xs={12} className={classes.controlItem}>
              <FormControl variant="outlined" className={classes.control}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="fileName"
                  label={intl.formatMessage({ id: "detail_FileName" })}
                  value={fileName}
                  onChange={(event) => setFileName(event.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledDialogContent>
    <StyledDialogActions>
      <Button
        color="primary"
        onClick={() => onClose([], '')}
      >
        <FormattedMessage id={'detail_Cancel'}/>
      </Button>
      <Button
        onClick={onExportContinue}
        variant="contained"
        color="primary"
        size="large"
        disabled={selectedLanguages.length === 0 || fileName.length === 0}
      >
        <FormattedMessage id={'detail_Continue'} />
      </Button>
    </StyledDialogActions>
  </Dialog>;
};

export default ExportToFileDialog;
