import { makeStyles } from "@material-ui/core";
import { myBlack } from "../../../constants/colors";

export const useProductReportPageStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'column',
    '&:nth-last-child(1)': {
      paddingBottom: theme.spacing(2, '!important')
    },
    [theme.breakpoints.down('sm')]: {
      '&:nth-last-child(1)': {
        paddingBottom: theme.spacing(1, '!important')
      },
    }
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    '&:nth-last-child(n + 2)': {
      marginBottom: theme.spacing(1.875)
    }
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(3.75)
  },
  actionButtons: {
    justifyContent: 'flex-start'
  },
  pdfContainer: {
    marginTop: theme.spacing(2)
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: myBlack,
    opacity: .15,
    zIndex: 999999
  }
}));
