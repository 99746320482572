import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import toastr from 'toastr';
import { Logger } from '../../../../utilities/Logger/Logger';
import { useAppGlobalState } from '../../../../context/AppContext/AppContext';
import InspectionSetupTable from '../InspectionSetupListTable/InspectionSetupListTable';
import { ProgressIndicator } from '../../../../components/ProgressIndicator/ProgressIndicator';
import { ViewComfy as ViewComfyIcon } from "@material-ui/icons";
import { useSetTitleAction } from '../../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useInspectionActions } from '../../../../actions/inspectionActions';
import { InspectionSetupDelete } from '../InspectionSetupDelete/InspectionSetupDelete';


const InspectionSetupPage = (props) => {
  const { sites } = useAppGlobalState();
  const inspectionActions = useInspectionActions();

  const [isLoading, setIsLoading] = useState(true);
  const [inspections, setInspections] = useState<Array<any>>([]);
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [toBeDeletedInspection, setToBeDeletedProduct] = useState<any>({ title: '' });

  const setTitle = useSetTitleAction();
  const refreshSiteData = async () => {
    try {
      const siteId: string = props.match.params.siteId;
      const currentSite = sites.find(el => el.id === siteId);
      if (currentSite && currentSite.printers) {
        setIsLoading(() => true);
        const currentInspections = await inspectionActions.loadInspections(siteId);
        setInspections(currentInspections);
        setIsLoading(() => false);
      }
    } catch (error) {
      toastr.error(error.message ? error.message : error);
      Logger.of('App.Inspection.refreshSiteData').info('Error Refreshing site data', error);
    }
  }

  useEffect(() => {
    if (sites.length > 0) {
      refreshSiteData();
    }
  }, [sites]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_InspectionSetup', <ViewComfyIcon />, {
      parents: [{
        id: 'detail_Configuration',
      }]
    });
  }, [setTitle]);

  const onDeleteInspectionSetup = (line) => {
    setDeleteConfirmVisible(true);
    setToBeDeletedProduct(line)
  }

  const onInspectionSetupDeleted = () => {
    setDeleteConfirmVisible(false);
    refreshSiteData();
  }

  const siteId: string = props.match.params.siteId;
  const currentSite = sites.find(el => el.id === siteId);

  return (
    !isLoading ?
      <>
        <InspectionSetupTable
          refreshSiteData={refreshSiteData}
          site={currentSite}
          inspections={inspections}
          onDeleteInspectionSetup={onDeleteInspectionSetup}
        />
        <InspectionSetupDelete
          inspection={toBeDeletedInspection}
          isDeleteConfirmVisible={isDeleteConfirmVisible}
          setDeleteConfirmVisible={setDeleteConfirmVisible}
          onDeleted={onInspectionSetupDeleted} />

      </> :
      <ProgressIndicator />
  );
}

InspectionSetupPage.propTypes = {
  history: object,
  match: object,
};

export default InspectionSetupPage;
