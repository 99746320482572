import React, { useEffect, useState } from 'react';

import DeviceRoleListTable from '../DeviceRoleListTable/DeviceRoleListTable';
import { Devices as DevicesIcon } from '@material-ui/icons';
import { useDeviceRoleSetupPageStyles } from './DeviceRoleSetupPage.css';
import { useIntl } from 'react-intl';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useDeviceRoleActions } from '../../../actions/deviceRoleActions';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';

const DeviceRoleSetupPage = ({ match }: any) => {
  const intl = useIntl();
  const classes = useDeviceRoleSetupPageStyles();

  const { sites } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const deviceRoleActions = useDeviceRoleActions();
  const siteId: string = match.params.siteId;

  const [isLoading, setIsLoading] = useState(false);
  const [deviceRoles, setDeviceRoles] = useState<Array<any>>([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const roles = await deviceRoleActions.loadDeviceRoles(siteId);
        setDeviceRoles(roles);
        setIsLoading(false);
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    })();
  }, [intl, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_NewDeviceRoleName', <DevicesIcon />);
  }, [setTitle]);

  return (
    !isLoading && sites.length > 0 ? <>
      <StyledContentCard>
        <DeviceRoleListTable
          deviceRoles={deviceRoles}
          siteId={siteId}
        />
      </StyledContentCard>
    </> :
      <div className={classes.progress}>
        <ProgressIndicator />
      </div>
  );
}

export default DeviceRoleSetupPage;
