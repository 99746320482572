import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { useLineSummaryStyle } from './LineSummary.css';
import Widget from '../../../../components/Widget/Widget';
import { Grid } from '@material-ui/core';
import LineProductOverview from '../LineProductOverview/LineProductOverview';
import LineOEEOverview from '../LinePerformanceOverview/LineOEEOverview/LineOEEOverview';

const LineSummary = ({ currentShifts, currentLines }) => {
  const intl = useIntl();
  const classes = useLineSummaryStyle();

  return (
    <Widget
      className={classes.widget}
      title={intl.formatMessage({ id: 'detail_Summary' })}
    >
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <LineOEEOverview
            lines={currentLines}
            currentShifts={currentShifts}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <LineProductOverview
            lines={currentLines}
            currentShifts={currentShifts}
          />
        </Grid>
      </Grid>
    </Widget>
  );
};

LineSummary.propTypes = {
  currentShifts: PropTypes.array,
  currentLines: PropTypes.array,
};

LineSummary.defaultProps = {
  currentShifts: [],
  currentLines: [],
};

export default LineSummary;
