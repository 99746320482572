import { Switch } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { myPrimaryColor, myPrimaryColorLighter } from '../../constants/colors';

export const StyledSwitch = withStyles({
  switchBase: {
    color: myPrimaryColorLighter,
    '&$checked': {
      color: myPrimaryColor,
    },
    '&$checked + $track': {
      backgroundColor: myPrimaryColor,
    },
  },
  checked: {},
  track: {},
})(Switch);
