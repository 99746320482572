import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { usePageTitleStyle } from './PageTitle.css';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { HeaderTitleInfo } from '../../interfaces/HeaderTitleInfo';

interface PageTitleProps extends HeaderTitleInfo {
  smallSize: boolean;
  titleOnly: boolean;
}

function PageTitle({ titleId,
  title,
  icon,
  linkParams,
  smallSize,
  titleOnly }: PageTitleProps) {
  const classes = usePageTitleStyle();
  const intl = useIntl();
  const [breadCrumbData, setBreadCrumbData] = useState<any>(null);

  useEffect(() => {
    if (titleId) {
      setBreadCrumbData(linkParams || {});
    }
  }, [titleId, linkParams]);

  return (
    <>
      <div className={classes.appPageTitle}>
        {
          (!smallSize || titleOnly) && <div className={classes.appPageTitleHeading}>
            <div className={classes.itemParent}>
              <div className={classes.mainIcon}> {icon}</div>
              <span className={classes.appPageTitleHeadingText}>
                {title}
              </span>
            </div>
          </div>
        }
        {!titleOnly && <div className={classes.appPageTitleHeading}>
          {
            breadCrumbData ? <>
              {
                breadCrumbData.parents &&
                breadCrumbData.parents.map((el, index) => <div key={`${el.id}-${index}`} className={classes.itemParent}>
                  {index !== 0 && <div className={classes.separator}><ArrowForwardIosIcon fontSize="small" /></div>}
                  <div className={classes.breadcrumbItem}>
                    {el.link ? <Link to={el.link}>
                      <span className={classes.link}>{el.textOnly ? el.id : intl.formatMessage({ id: el.id })}</span>
                    </Link> : <span className={classes.item}>{el.textOnly ? el.id : intl.formatMessage({ id: el.id })}</span>
                    }
                  </div>

                </div>)
              }
              {
                !breadCrumbData.ignoreItself && <div className={classes.itemParent}>
                  <div className={classes.separator}><ArrowForwardIosIcon fontSize="small" /></div><div className={classes.breadcrumbItem}>
                    <span className={classes.item}>{title}</span>
                  </div>
                </div>
              }
            </> : null
          }
        </div>
        }
      </div>
    </>
  );
}

export default PageTitle;
