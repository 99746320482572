import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledGrid = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1.25),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0.125),
    },
  }
}))((props) => <Grid container spacing={1} {...props} />);

export default StyledGrid;
