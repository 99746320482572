import { FormControl, TextField } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { useGlobalStyles } from "../../constants/globalstyles";
import { useTextInputStyle } from "./TextInput.css";

interface TextInputProps {
  fieldName: string;
  label: string;
  placeholder: string;
  onChange?: (event) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  value: string | number;
  errors: any;
  isNumberBox?: boolean;
  isPercent?: boolean;
}

function TextInput({ fieldName,
  label,
  onChange,
  value,
  isDisabled,
  isRequired,
  placeholder,
  errors,
  isNumberBox }: TextInputProps) {
  const classes = useTextInputStyle();
  const intl = useIntl();
  const globalClasses = useGlobalStyles();

  return <div className={classes.inputBox}>
    <div className={classes.inputBoxLabel}>
      <span>{label}</span>
      {
        isRequired && <>&nbsp; <span className={globalClasses.required}>*</span></>
      }
    </div>

    <FormControl variant="outlined" className={globalClasses.formControl}>
      <TextField
        autoComplete="off"
        type={isNumberBox ? "number" : "text"}
        name={fieldName}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        fullWidth={true}
        error={!!errors[fieldName]}
        helperText={errors[fieldName] && intl.formatMessage({ id: errors[fieldName] })}
      />
    </FormControl>
  </div>
}

TextInput.displayName = 'TextInput';

export { TextInput };
