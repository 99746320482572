
import {
  SCREEN_SIZE_CHANGED,
  SET_DASHBOARD_MODE,
  SET_HEADER_TITLE,
  SET_LOADING,
  SET_HOME_SELECTION_MODE,
  SET_FILTER,
  RESET_FILTER
} from "../../actions/actionTypes";
import { IAppFilter } from "../../interfaces/AppGlobal/IAppFilter";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import { HeaderTitleInfo } from "../../interfaces/HeaderTitleInfo";
import initalState from "../initialState";

export interface IConfigState {
  screenWidth: number;
  screenHeight: number;
  dashboardMode?: boolean;
  headerTitleInfo?: HeaderTitleInfo | null;
  downtimeTypes: Array<{ id: string; name: string }>;
  shiftRepeats: Array<{ value: number; text: string; transId: string }>;
  printerRoles: Array<{ id: number; text: string; transId: string }>;
  isLoading: boolean;
  homeSelectionMode: number;
  appFilters: IAppFilter;
  inspectionFreqTypes: Array<any>;
  inspectionStepTypes: Array<any>;
  inspectionRefCompareTypes: Array<any>;
}
export const initialConfigState: IConfigState = {
  screenWidth: 0,
  screenHeight: 0,
  dashboardMode: false,
  downtimeTypes: [
    { id: '1', name: 'Planned' },
    { id: '2', name: 'Unplanned' },
  ],
  ...initalState.config,
  isLoading: false,
  homeSelectionMode: 0,
  appFilters: {
    siteFilter: {
      searchTerm: '',
      isShowingDesignUsers: false
    },
    lineFilter: {
      searchTerm: ''
    },
    productFilter: {
      searchTerm: ''
    },
    shiftFilter: {
      searchTerm: ''
    },
    plannedDowntimeFilter: {
      searchTerm: ''
    },
    categoryFilter: {
      searchTerm: '',
    },
    subcategoryFilter: {
      searchTerm: '',
    },
    localReportFilter: {
      searchTerm: '',
    },
    globalReportFilter: {
      searchTerm: '',
    },
    reportSchedulerFilter: {
      searchTerm: '',
    },
    inspectionSetupFilter: {
      searchTerm: '',
      lines: [],
      products: []
    },
    userFilter: {
      searchTerm: '',
      filteredAuthModules: [],
      siteId: '',
      showAllUsers: false
    }
  },
};

export function configReducer(state: IConfigState = initialConfigState, action: IAppGlobalAction): IConfigState {
  switch (action.type) {
    case SCREEN_SIZE_CHANGED:
      return {
        ...state,
        screenWidth: action.payload.screenWidth,
        screenHeight: action.payload.screenHeight
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case SET_HEADER_TITLE:
      return {
        ...state,
        headerTitleInfo: action.payload ? { ...action.payload } : null
      };

    case SET_HOME_SELECTION_MODE:
      return {
        ...state,
        homeSelectionMode: action.payload
      };

    case SET_DASHBOARD_MODE:
      return {
        ...state,
        dashboardMode: action.payload
      };

    case SET_FILTER:
      return {
        ...state,
        appFilters: { ...state.appFilters, [action.payload.name]: action.payload.setFilter(state.appFilters[action.payload.name]) }
      };
    case RESET_FILTER:
      return {
        ...state,
        appFilters: {...initialConfigState.appFilters}
      };
    default: {
      return state;
    }
  }
}
