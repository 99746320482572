import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { Tooltip } from '@material-ui/core';
import ViewIcon from '@material-ui/icons/Pageview';
import DeleteIcon from '@material-ui/icons/Delete';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';
import moment from 'moment-timezone';
import { parse, stringify } from 'query-string';
import { reportHelpers } from 'vccm-common';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useReportManagerListRowStyles } from './ReportManagerListRow.css';
import DateHelper from '../../../helpers/DateHelper/DateHelper';
import { timeFrames as complianceTimeFrames } from '../../../helpers/AssessmentHelper/AssessmentHelper';
import notifyApi from '../../../api/prodNotificationApi';
import Utils from '../../../utilities/utils';
import { ReportTypeGlobal, ReportTypeLocal } from '../../../constants/global';
import Can from '../../../components/Common/Can';

interface IReportManagerListRow {
  report: any;
  site: any;
  gridBreak: any;
  editContext: string;
  editAction: string;
  schedules: Array<any>;
  userId: string;
  onReportChange: (newReports: any) => void;
}

const ReportManagerListRow = ({
  report,
  site,
  editContext,
  editAction,
  onReportChange,
  schedules,
  userId,
  gridBreak }: IReportManagerListRow) => {
  const intl = useIntl();
  const classes = useReportManagerListRowStyles();

  const [saving, setSaving] = useState(false);
  const intelDateHelper = new DateHelper(intl);
  const complianceDateHelper = new DateHelper(intl, complianceTimeFrames);

  const [confirmItem, setConfirmItem] = useState<{ userId: string; siteId: string; title: string }>();
  const [schedulesForRewrite, setSchedulesForRewrite] = useState<Array<any>>([]);

  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [confirmSchedules, setConfirmSchedules] = useState<Array<any>>([]);

  const formatPath = (report, appendQueryStringParams = {}) => {
    const now = moment.tz(site.tz);
    const url = reportHelpers.fillUrlTemplate(report.urlTemplate, report.timeFrame, now, site.tz);
    const objUrl = Utils.parseUrl(url);
    const queryStringParams = parse(objUrl.search);
    const combinedSearch = stringify(({ ...queryStringParams, ...appendQueryStringParams }));
    return `${objUrl.pathname}?${combinedSearch}`;
  };

  function getAssocScheduleTitles(isLocal, schedules, item) {
    const assoc: Array<any> = [];
    if (Array.isArray(schedules) && schedules.length && item) {
      if (isLocal) {
        schedules.forEach((s) => {
          if (s.localReports && s.localReports.includes(item.title)) {
            assoc.push(s.title);
          }
        });
      } else {
        schedules.forEach((s) => {
          if (s.siteReports && s.siteReports.includes(item.title)) {
            assoc.push(s.title);
          }
        });
      }
      return assoc;
    }
    return [];
  }
  function getAssocSchedulesForRewrite(isLocal, schedules, item) {
    if (Array.isArray(schedules) && item) {
      if (isLocal) {
        return schedules.filter(s => s.localReports && s.localReports.includes(item.title))
          .map(s => ({ ...s, localReports: s.localReports.filter(lr => lr !== item.title) }));
      }
      // else
      return schedules.filter(s => s.siteReports && s.siteReports.includes(item.title))
        .map(s => ({ ...s, siteReports: s.siteReports.filter(lr => lr !== item.title) }));
    }
    // else
    return false;
  }

  const onCloseDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        const promises: Array<Promise<any>> = [];
        if (confirmItem) {
          if (confirmItem.userId) {
            promises.push(notifyApi.removeUserReport(confirmItem.siteId, confirmItem.userId, confirmItem.title));
          } else {
            promises.push(notifyApi.removeGlobalReport(confirmItem.siteId, confirmItem.title));
          }

          schedulesForRewrite.forEach(s => promises.push(notifyApi.scheduleUpdate(s)));
          setSaving(true);
          await Promise.all(promises);
          setSaving(false);
          toastr.success(`${intl.formatMessage({ id: 'detail_Deleted' })} ${confirmItem.title}`);
        }

        onReportChange(null);
      } catch (e) {
        toastr.error(e.toString());
      }
      setDeleteConfirmVisible(false);
    }
    setDeleteConfirmVisible(false);
  }

  const url = formatPath(report, { title: report.title, saved: editContext === 'report' ? ReportTypeLocal : ReportTypeGlobal, canSave: false });
  const canDelete = (!report.distributedBy || (userId && userId === report.distributedBy));

  const deleteItem = (report) => {
    setDeleteConfirmVisible(true);
    setConfirmItem(report);
    setConfirmSchedules(getAssocScheduleTitles(editContext === 'report', schedules, report));
    setSchedulesForRewrite(getAssocSchedulesForRewrite(editContext === 'report', schedules, report) || []);
  }
  return (<>
    <StyledGridRow>
      <StyledGridCell {...gridBreak.title}>
        <div><strong>{report.title}</strong></div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.type}>
        <div>{intl.formatMessage({ id: `detail_${report.type}` })}</div>
      </StyledGridCell>

      <StyledGridCell {...gridBreak.timeFrame}>
        <div>
          {report.type === 'Compliance'
            ? complianceDateHelper.getTimeFrameTextByValue(report.timeFrame)
            : intelDateHelper.getTimeFrameTextByValue(report.timeFrame)
          }
        </div>
      </StyledGridCell>

      <StyledGridCell {...gridBreak.distributedBy}>
        {report.distributedBySort}
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons}>
        <StyledActionContainer breaks={{ sm: true }}>
          <Tooltip title={intl.formatMessage({ id: 'detail_View' })}>
            <ActionButton onClick={() => onReportChange({
              type: 'redirect',
              to: url,
            })} >
              <ViewIcon />
            </ActionButton>
          </Tooltip>
          <Can do={editAction} on={editContext} passThrough>
            {
              edit => (
                canDelete ? <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
                  <ActionButton onClick={() => {
                    if (edit) {
                      deleteItem(report);
                    }
                  }}>
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip> : <ActionButton disabled={true}>
                  <DeleteIcon />
                </ActionButton>
              )
            }
          </Can>
        </StyledActionContainer>
      </StyledGridCell>
    </StyledGridRow>
    <DeleteDialog
      saving={saving}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={isDeleteConfirmVisible}
      onClose={onCloseDeleteDialog}
      maxWidth="sm"
      content={<><FormattedMessage id="detail_Are_you_sure_you_want_to_delete" />&nbsp;<strong>&quot;{report.title}&quot;</strong>{'?'}</>}
      extraContent={(confirmSchedules && confirmSchedules.length)
        ? (
          <div className={classes.scheduleContainer}>
            <FormattedMessage id="detail_Attached_Schedules" />
            <ul>
              {
                confirmSchedules.map((schedule, index) => (
                  <li key={schedule + index}>{schedule}</li>
                ))
              }
            </ul>
          </div>
        ) : (
          <div />
        )
      }
    />
    { saving && <ProgressIndicator />}

  </>
  )
};

export default ReportManagerListRow;
