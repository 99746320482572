import React, { useEffect, useState } from 'react';
import { LinearProgressWithLabel } from '../../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import { useCountdownProgressStyles } from './CountdownProgress.css';

interface CountdownProgressProps {
  restartIndex: number;
  onComplete: () => void;
  totalDuration: number;
}

const CountdownProgress = ({
  restartIndex,
  totalDuration,
  onComplete }: CountdownProgressProps) => {
  const classes = useCountdownProgressStyles();

  const [durationStartTime, setDurationStartTime] = useState<number>(Date.now());
  const [, setCount] = useState<number>(0);

  useEffect(() => {
    setDurationStartTime(Date.now());
    setCount(0);
  }, [restartIndex, totalDuration]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (restartIndex > 0) {
        if (Date.now() >= durationStartTime + totalDuration) {
          clearInterval(timer);
          onComplete();
        } else {
          setCount(s => s + 1);
        }
      }
    }, 250);

    return () => {
      clearInterval(timer);
    };
  }, [restartIndex]); // eslint-disable-line react-hooks/exhaustive-deps


  return <div className={classes.root}>
    <LinearProgressWithLabel
      label=""
      progressValue={durationStartTime + totalDuration - Date.now()}
      maxValue={totalDuration}
      hideProgressLabel={true} />
  </div>
}

export default CountdownProgress;
