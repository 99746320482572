import { IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import SearchIcon from '@material-ui/icons/Search';
import { useSearchInputStyle } from "./SearchInput.css";

interface SearchInputProps {
  searchTerm: string;
  onChange: (event) => void;
  rootClass?: string;
  onSubmitSearch: (text: string) => void;
}

function SearchInput({
  searchTerm,
  onChange,
  rootClass,
  onSubmitSearch }: SearchInputProps) {
  const classes = useSearchInputStyle();
  const intl = useIntl();

  return <div className={classes.root}>
    <OutlinedInput
      placeholder={intl.formatMessage({ id: 'detail_Search' })}
      value={searchTerm}
      onChange={onChange}
      classes={{ root: rootClass ? rootClass : classes.textInput, input: classes.inputBox }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton type="submit"
            className={classes.iconButton}
            aria-label="search"
            onClick={() => onSubmitSearch(searchTerm)}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  </div>
}

SearchInput.displayName = 'SearchInput';

export { SearchInput };
