import {
  UPDATE_TRANSLATION_DATA,
  UPDATE_TRANSLATION_FILTER
} from "../../actions/actionTypes";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import { TranslationFilter } from "../../interfaces/TranslationFilter";

import { messagesCategory } from '../../utilities/loadTranslations';

const languageKeys = Object.keys(messagesCategory);
const enData: any = messagesCategory.en;
const translationDataObj = Object.keys(enData).reduce((acc: any, key) => {
  return { ...acc, [key]: { en: enData[key] } }
}, {})

languageKeys.forEach(lanKey => {
  const lanData = messagesCategory[lanKey];
  Object.keys(lanData).filter(key => enData[key]).forEach(key => {
    if (translationDataObj[key]) {
      translationDataObj[key] = { ...translationDataObj[key], [lanKey]: lanData[key] };
    } else {
      translationDataObj[key] = { [lanKey]: lanData[key] };
    }
  })
});

const translationItems: Array<any> = Object.keys(translationDataObj).map(key => {
  const itemData = translationDataObj[key];
  return languageKeys.reduce((acc: any, lanKey) => ({
    ...acc,
    all: `${acc.all} ${itemData[lanKey]}`,
    [lanKey]: itemData[lanKey] || ''
  }), { key, all: key });
});

export interface ILanguageState {
  translationItems: any;
  languageKeys: Array<string>;
  translationDataObj: any;
  filter: TranslationFilter;
}
export const initialLanguageState: ILanguageState = {
  translationItems,
  languageKeys,
  translationDataObj,
  filter: {
    searchTerm: '',
    searchingKeyOnly: false,
    blankValueOnly: false,
    selectedLanguages: [...languageKeys]
  }
};

export function languageReducer(state: ILanguageState = initialLanguageState, action: IAppGlobalAction): ILanguageState {
  switch (action.type) {
    case UPDATE_TRANSLATION_DATA: {
      const { language, importedData } = action.payload;
      const { languageKeys, translationDataObj } = state;
      const importedDataObj = importedData.reduce((acc, el) => ({ ...acc, [el.key]: el.value }), {});
      const newTranslationItems: Array<any> = Object.keys(translationDataObj).map(key => {
        const itemData = translationDataObj[key];
        return languageKeys.reduce((acc: any, lanKey) => {
          itemData[lanKey] = lanKey === language && importedDataObj[key] ? importedDataObj[key] : itemData[lanKey];
          const data = itemData[lanKey];
          return {
            ...acc,
            all: `${acc.all} ${data || ''}`,
            [lanKey]: data || ''
          }
        }, { key, all: key });
      });

      return {
        ...state,
        translationItems: newTranslationItems,
        translationDataObj: {...translationDataObj}
      };
    }

    case UPDATE_TRANSLATION_FILTER: {
      const filter = action.payload(state.filter);
      return {
        ...state,
        filter,
      };
    }

    default: {
      return state;
    }
  }
}
