import { makeStyles } from "@material-ui/core";

export const useCountdownProgressStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0.625),
    borderRadius: theme.spacing(1.5),
    width: '100%',
    minHeight:'5px'
  }
}));
