import React from 'react';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';

import DeviceRoleListRow from '../DeviceRoleListRow/DeviceRoleListRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';

const set1Breaks = {
  title: { lg: 6, md: 6, sm: 6, xs: 12 },
  actionButtons: { lg: 6, md: 6, sm: 6, xs: 12 }
};

interface IDeviceRoleListTableProps {
  deviceRoles: Array<any>;
  siteId: string;
}

const DeviceRoleListTable = ({
  deviceRoles,
  siteId
}: IDeviceRoleListTableProps) => {
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';

  const gridBreak: any = set1Breaks;

  return (
    <StyledGrid>
      <StyledGridHeadRow>
        <StyledGridHeadCell {...gridBreak.title}>
          <TableHeadText
            textId="detail_DeviceRoleName"
            fontSize={tableHeadFontSize}
            textAlign={tableHeadAlign}
          />
        </StyledGridHeadCell>
        <StyledGridHeadCell {...gridBreak.actionButtons}>
        </StyledGridHeadCell>
      </StyledGridHeadRow>
      <>
        {
          deviceRoles ? deviceRoles.map(deviceRole => (
            <DeviceRoleListRow
              key={deviceRole.id}
              siteId={siteId}
              deviceRole={deviceRole}
              gridBreak={gridBreak}
            />
          )) : null
        }
      </>
    </StyledGrid>
  );
}

export default DeviceRoleListTable;
