import React from 'react';
import momentTimeZone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import qstr from 'query-string';
import { ShiftChecker } from 'vccm-common';
import { validation, reportHelpers } from 'vccm-common';

import * as ENUMS from '../../enums/Enums';
import { Logger } from "../Logger/Logger";
import Utils from '../utils';

const momentExtended = extendMoment(momentTimeZone as any);

export const formatShiftEvent = (shift) => {
  return {
    shiftId: shift.id,
    companyId: shift.companyId,
    siteId: shift.siteId,
    title: shift.title,
    description: shift.description,
    lineIds: shift.lineIds,
    typeId: shift.typeId,
    start: (shift.instanceInfo) ? shift.instanceInfo.startTime.toDate() : shift.startTimeDaylightSavingsCorrection,
    end: (shift.instanceInfo) ? shift.instanceInfo.endTime.toDate() : shift.endTimeDaylightSavingsCorrection,
    recurrenceRule: shift.recurrenceRule,
    recurrenceException: shift.recurrenceException,
    isAllDay: shift.isAllDay,
    isDeleted: shift.isDeleted,
  };
}

export const getShiftsOccurringInRange = (shifts, filter, siteTz, filterByReportShift = false) => {
  const nextTimeRange = momentExtended.range(
    transformToSiteRelativeMoment(filter.startDt, siteTz),
    transformToSiteRelativeMoment(filter.endDt, siteTz));
  const reportShifts = filterByReportShift && shifts && filter && filter.reportShifts && filter.reportShifts.length
    ? shifts.filter(shift => filter.reportShifts.includes(shift.id))
    : shifts;
  const checker = new ShiftChecker(reportShifts);

  const t = checker.shiftsOverlap(nextTimeRange.start, nextTimeRange.end);
  const shiftOccurrences = t.map(s => formatShiftEvent(s));
  Logger.of('App.getShiftsOccurringInRange').info('found shift occurrences', shiftOccurrences);

  // shift typeId === 1 is a shift that has a schedule (not a break).
  const shiftsWithOccurrencesInRange = shifts.filter((shift) => {
    if ((shift.typeId === 1) && shiftOccurrences.find(occurrence => occurrence.shiftId === shift.id)) {
      return shift;
    }
    return undefined;
  });

  return [shiftsWithOccurrencesInRange, shiftOccurrences, nextTimeRange];
}

const transformToLocalRelativeDate = (aMoment) => {
  if (!momentTimeZone.isMoment(aMoment)) throw new Error('transformToLocalRelativeDate expected a Moment');
  return new Date(aMoment.year(), aMoment.month(), aMoment.date(), aMoment.hour(), aMoment.minute(), aMoment.second(), aMoment.milliseconds())
}

const transformToLocalRelativeDatePlusOneDay = (aMoment) => {
  if (!momentTimeZone.isMoment(aMoment)) throw new Error('transformToLocalRelativeDate expected a Moment');
  return new Date(aMoment.year(), aMoment.month(), aMoment.date() + 1, aMoment.hour(), aMoment.minute(), aMoment.second(), aMoment.milliseconds())
}

const getCustomStartDateFromQueryString = (customTimeFrame, relativeNow = undefined, tz = undefined) => {
  const { startDt } = reportHelpers.getTimeFrameDateRange(tz, relativeNow, customTimeFrame);
  return transformToLocalRelativeDatePlusOneDay(startDt); // Date object in local time zone for injestion by Semantic DateInput
}

const getCustomEndDateFromQueryString = (customTimeFrame, relativeNow = undefined, tz = undefined) => {
  const { endDt } = reportHelpers.getTimeFrameDateRange(tz, relativeNow, customTimeFrame);
  return transformToLocalRelativeDate(endDt); // Date object in local time zone for injestion by Semantic DateInput
}

const getNonCustomStartDate = (customTimeFrame, relativeNow = undefined, tz = undefined) => {
  const { startDt } = reportHelpers.getTimeFrameDateRange(tz, relativeNow, customTimeFrame);
  return transformToLocalRelativeDate(startDt); // Date object in local time zone for injestion by Semantic DateInput
}

const getNonCustomEndDate = (customTimeFrame, relativeNow = undefined, tz = undefined) => {
  const { endDt } = reportHelpers.getTimeFrameDateRange(tz, relativeNow, customTimeFrame);
  return transformToLocalRelativeDate(endDt); // Date object in local time zone for injestion by Semantic DateInput
}

const allOrArray = (obj, all = []) => (obj && !Array.isArray(obj)
  ? obj.toLowerCase() !== 'all' ? obj.split(',') : all
  : obj);

const allOrString = (obj, all = '') => (obj && !Array.isArray(obj)
  ? obj.toLowerCase() !== 'all' ? obj : all
  : obj);

export const getCommonFilterFromQueryString = (filter: any, search: string, siteTz, useArray = false): any => {
  const params = qstr.parse(search,
    {
      arrayFormat: 'comma',
      parseBooleans: true,
      parseNumbers: true
    });

  if (params.timeIntervalId) {
    params.timeIntervalId = Number(params.timeIntervalId);
  }


  if (params.reportLineDisplay) {
    params.reportLineDisplay = Number(params.reportLineDisplay);
  }

  if (params.reportOEEDisplay) {
    params.reportOEEDisplay = Number(params.reportOEEDisplay);
  }

  const startDt = (params.timeFrameId === 'customDateRange' ? getCustomStartDateFromQueryString : getNonCustomStartDate)(params.timeFrameId, params.startDt as any, siteTz);
  const endDt = (params.timeFrameId === 'customDateRange' ? getCustomEndDateFromQueryString : getNonCustomEndDate)(params.timeFrameId, params.endDt as any, siteTz);

  return {
    ...filter,
    ...params,
    lineIds: useArray ? allOrArray(params.lineIds) : allOrString(params.lineIds),
    reportShifts: useArray ? allOrArray(params.reportShifts) : allOrString(params.reportShifts),
    startDt,
    endDt,
    downtimeElapsed: !!params.downtimeElapsed,
    isDirty: true
  };
}

export const transformToSiteRelativeMoment = (aDate: any, siteTz: any): any => {
  if (!(aDate instanceof Date)) {
    throw new Error('transformToSiteRelativeMoment expected a Date');
  }

  const zonedDate = momentTimeZone.tz({
    year: aDate.getFullYear(),
    month: aDate.getMonth(),
    date: aDate.getDate(),
    hour: aDate.getHours(),
    minute: aDate.getMinutes(),
    second: aDate.getSeconds(),
    millisecond: aDate.getMilliseconds(),
  }, siteTz);

  return zonedDate;
}

export const defaultReportFilter = {
  endDt: momentTimeZone()
    .endOf('day')
    .toDate(),
  endTimeId: '',
  isDirty: true,
  startDt: momentTimeZone()
    .startOf('day')
    .toDate(),
  startTimeId: '',
  timeIntervalId: 1,
  timeFrameId: 'customDateRange',
};

export const performUpdateReportFilterData = (filter, field, value, tz, mName: string) => {
  let dateValidation = false;

  Logger.of(`${mName}.updateReportFilterData`).info(`ReportCommon => start:${filter.startDt} end:${filter.endDt}`);

  const updatedItem = { ...filter };
  switch (field) {
    case 'timeFrameId':
      updatedItem[field] = value;
      if (value !== 'customDateRange') { // set start and end date based on the value. the date fields will not be disabled on the form
        updatedItem.startDt = getNonCustomStartDate(value, undefined, tz);
        updatedItem.endDt = getNonCustomEndDate(value, undefined, tz);
      }
      updatedItem.isDirty = true;
      break;
    case 'timeIntervalId':
      updatedItem[field] = parseInt(value); // change to int
      updatedItem.isDirty = true;
      break;
    case 'canSave':
      updatedItem.canSave = value;
      break; // don't set dirty
    case 'startDt':
      // fix validation
      dateValidation = validation.validateDateString(value);
      //updatedItem.startDt = dateValidation ? momentTimeZone(value).startOf('day').toDate() : value;
      updatedItem.isDirty = dateValidation;

      updatedItem.startDt = momentTimeZone(value).startOf('day').toDate();
      updatedItem.isDirty = true;
      break;
    case 'endDt':
      dateValidation = validation.validateDateString(value);
      //updatedItem.endDt = dateValidation ? momentTimeZone(value).endOf('day').toDate() : value;
      updatedItem.isDirty = dateValidation;
      updatedItem.endDt = momentTimeZone(value).endOf('day').toDate();
      updatedItem.isDirty = true;
      break;
    case 'reportLineDisplay':
    case 'reportOEEDisplay':
      updatedItem[field] = parseInt(value);
      updatedItem.isDirty = true;
      break;
    case 'lineIds':
    case 'reportShifts':
      updatedItem[field] = value;
      updatedItem.isDirty = true;
      break;
    case 'downtimeElapsed':
      updatedItem[field] = value;
      updatedItem.isDirty = true;
      break;
    default:
      updatedItem[field] = value;
      updatedItem.isDirty = true;
      break;
  }
  Logger.of('App.OeeReportPage.constructor').info('Updated Filter Item', updatedItem);
  return updatedItem;
}

export const getNoContentMessage = (intl) => {
  return <h4 style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'detail_No_data_for_range' })}</h4>;
}

export const getTimeoutMessage = (intl) => {
  return <h2 style={{ textAlign: 'center' }}>
    {intl.formatMessage({ id: 'detail_Error_Unable_to_complete_operation' })}
    <br />
    {intl.formatMessage({ id: 'detail_Please_adjust_filter' })}
  </h2>;
}


export const getLocalTimeStamp = (filter, siteTz, event) => {
  if (event.dateHour) {
    return momentTimeZone.utc(event.dateHour, 'YYYY-MM-DD-HH')
      .local()
      .toDate();
  }
  if (event.date && filter.timeIntervalId === ENUMS.INTERVALS.HOUR) {
    return momentTimeZone.utc(event.date, 'YYYY-MM-DD-HH')
      .local()
      .toDate();
  }
  if (event.date && (filter.timeIntervalId === ENUMS.INTERVALS.DAY
    || filter.timeIntervalId === ENUMS.INTERVALS.WEEK
    || filter.timeIntervalId === ENUMS.INTERVALS.MONTH)) {
    return momentTimeZone.tz(event.date, 'YYYY-MM-DD', siteTz)
      .toDate();
  }
  return momentTimeZone.utc(event.timestamp)
    .local()
    .toDate(); // timestamp is UTC
}


export const getSiteTimeStamp = (filter, siteTz, i) => {
  return Utils.getLocalSiteDateObject(getLocalTimeStamp(filter, siteTz, i), siteTz);
}


const formatValue = (val) => {
  return Number(val).toFixed();
}

export const seriesByThresholds = (filter, siteTz, data, seriesFields, thresholds) => {
  const byThreshold: any = {};
  if (!data || data.length < 1) return [];

  const reportData = data || [];

  // aggregate by threshold
  const b = reportData.reduce((agg, entry) => {
    // create series for each threshold, data is an array of oee like thingies
    if (entry) {
      seriesFields.forEach((val) => {
        const key = thresholds.reduce((k, t) => (t.v <= entry[val] ? t.v : k), thresholds[0].v);


        if (entry[val] > 0) {
          (agg[key] = agg[key] || []).push(
            {
              timeStamp: getLocalTimeStamp(filter, siteTz, entry),
              siteTimeStamp: getSiteTimeStamp(filter, siteTz, entry),
              [val]: formatValue(entry[val]),
            },
          );
        }
      });
    }
    return agg;
  }, byThreshold);

  // now, they are grouped by threshold, unpack into series by threshold, by data point value
  return Object.entries(b).flatMap((e) => {
    const [threshold, val] = e; // ?
    const thresholdInfo = thresholds.find(v => Number(v.v) === Number(threshold)) || {
      n: undefined,
      c: undefined,
    }; // ?
    return seriesFields.map(sf => ({
      name: seriesFields.length > 0 ? `${thresholdInfo.n} (${sf.charAt(0)
        .toUpperCase()})` : thresholdInfo.n,
      field: sf,
      categoryField: 'siteTimeStamp',
      data: val,
      opacity: 0.7,
    }));
  });
}
