import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { object } from 'prop-types';
import { LibraryBooks as LibraryBooksIcon } from "@material-ui/icons";
import toastr from 'toastr';
import { getCurrentUser } from '../../../libs/awsLib';
import notifyApi from '../../../api/prodNotificationApi';
import usersApi from '../../../api/prodUserApi';
import Can from '../../../components/Common/Can';
import { useAppGlobalState, useAppSelectedSite } from '../../../context/AppContext/AppContext';
import { useHistory } from 'react-router';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import ReportManager from '../ReportManager/ReportManager';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { CardContent } from '@material-ui/core';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import { useReportManagementPageStyles } from './ReportManagementPage.css';

function reload(ignore,
  siteId,
  setUserId,
  setUsers,
  setReportSchedules,
  setLocal,
  setGlobal,
  setError,
  setReportSchedulesLoading,
  setLocalLoading,
  setGlobalLoading) {
  // this odd construct is that we want to fire a promise and some useEffects will return clean-up
  (async () => {
    try {
      if (ignore) return;
      setReportSchedulesLoading(true);
      setLocalLoading(true);
      setGlobalLoading(true);
      const currentUser = await getCurrentUser();
      if (currentUser) {
        setUserId(currentUser.getUsername());
        const reportSchedules = async () => {
          try {
            const uid = currentUser.getUsername();
            const r = await notifyApi.listReportSchedulesByDist(siteId, uid);
            setReportSchedules(r);
            setReportSchedulesLoading(false);
          } catch (e) {
            setError(e.toString());
          }
        };

        const userReports = async () => {
          // get local reports and set them
          try {
            const r = await notifyApi.listUserReports(siteId, currentUser.getUsername());
            setLocal(r);
            setLocalLoading(false);
          } catch (e) {
            setError(e.toString());
          }
        };

        const siteReports = async () => {
          // get site reports and set them
          try {
            const r = await notifyApi.listSiteReports(siteId);
            setGlobal(r);
            setGlobalLoading(false);
          } catch (e) {
            setError(e.toString());
          }
        };
        const getUsers = async () => {
          try {
            const u = await usersApi.getAllUsers(1);
            setUsers(u);
          } catch (e) {
            setError(e.toString());
          }
        };
        await Promise.all([getUsers(), userReports(), siteReports(), reportSchedules()]);
      }
    } catch (e) {
      setError(e);
    }
  })();
}

const ReportManagementPage = () => {
  const classes = useReportManagementPageStyles();
  const setTitle = useSetTitleAction();
  const { selectedSiteId } = useAppGlobalState();
  const selectedSite = useAppSelectedSite();
  const history = useHistory();

  const [reportSchedules, setReportSchedules] = useState();
  const [users, setUsers] = useState<Array<any>>([]);
  const [userId, setUserId] = useState<string>('');
  const [localReports, setLocal] = useState<Array<any>>([]);
  const [globalReports, setGlobal] = useState<Array<any>>([]);
  const [reloadRequest, setReloadRequest] = useState(false);
  const [isReportSchedulesLoading, setIsReportSchedulesLoading] = useState(true);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const [error, setError] = useState();
  // get report data, \/ es lint ignore on required empty array arg
  // noinspection JSCheckFunctionSignatures
  useEffect(() => {
    let ignore = false;
    // it's okay to define and call an async func, but you cannot make effect async!
    reload(ignore, selectedSiteId, setUserId, setUsers, setReportSchedules, setLocal, setGlobal, setError, setIsReportSchedulesLoading, setIsLocalLoading, setIsGlobalLoading); // call immediately
    // return the cleanup function
    return () => {
      ignore = true;
    };
  },
    [selectedSiteId]);

  useEffect(() => {
    if (reloadRequest) reload(!reloadRequest, selectedSiteId, setUserId, setUsers, setReportSchedules, setLocal, setGlobal, setError, setIsReportSchedulesLoading, setIsLocalLoading, setIsGlobalLoading);
    setReloadRequest(false);
  }, [selectedSiteId, reloadRequest]);
  useEffect(() => { if (error) toastr.error(error); }, [error]);

  // event handler for children, could use other eventing, custom hooks...
  const changeHandler = (event) => {
    if (event.type === 'redirect') {
      history.push(event.to);
    } else {
      if (event === localReports || event === globalReports) setReloadRequest(true);
    }
  };

  useEffect(() => {
    setTitle('detail_Reports', <LibraryBooksIcon />, {
      ignoreItself: true,
      parents: [{
        textOnly: true,
        id: 'INSIGHT',
      },
      {
        id: 'detail_Reports',
      },
      {
        id: 'detail_Management'
      }]
    });
  }, [setTitle]);

  const hasNoReport = globalReports.length === 0 && localReports.length === 0;

  return (
    <>
      <Can do="view" on="report">
        <ReportManager
          onChange={changeHandler}
          users={users}
          userId={userId}
          reports={localReports}
          schedules={reportSchedules}
          editAction="view"
          editContext="report"
          header={(<FormattedMessage id="detail_LocalReports" />)}
          site={selectedSite}
          isLocal={true}
        />

        <ReportManager
          onChange={changeHandler}
          users={users}
          userId={userId}
          reports={globalReports}
          schedules={reportSchedules}
          editAction="edit"
          editContext="global-report"
          header={(<FormattedMessage id="detail_GlobalReports" />)}
          site={selectedSite}
          isLocal={false}
        />
        {(isGlobalLoading || isLocalLoading || isReportSchedulesLoading) && <ProgressIndicator />}
      </Can>
      {
        !isLocalLoading && !isGlobalLoading && hasNoReport && <StyledContentCard>
          <CardContent className={classes.content}>
            <EmptyItem textId="detail_noReports" />
          </CardContent>
        </StyledContentCard>
      }
    </>
  );
};
ReportManagementPage.propTypes = {
  site: object,
  history: object,
};
// noinspection JSUnusedGlobalSymbols
export default ReportManagementPage;
