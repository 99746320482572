import { makeStyles } from "@material-ui/core";

export const useProductListRowStyles = makeStyles((theme) => ({
  productTitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  imageHolder: {
    textAlign: 'center',
    verticalAlign: 'center',
    marginRight: theme.spacing(0.625)
  },
  productImage: {
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
    marginRight: theme.spacing(0.625),
  },
}));
