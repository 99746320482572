import { makeStyles } from "@material-ui/core";
import { myGrayShade2Alpha10 } from "../../../../constants/colors";

export const useLinePerformanceOverviewStyles = makeStyles(theme => ({
  row: {
    width: '100%',
    display: 'grid',
    paddingTop: theme.spacing(0.625),
    borderBottom: `1px solid ${myGrayShade2Alpha10}`,
    gridTemplateColumns: 'repeat(5, 1fr)',
    '@media only screen and (max-width: 1200px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media only screen and (max-width: 600px)': {
      gridTemplateColumns: '1fr',
    }
  },
  smallRow: {
    minHeight: theme.spacing(4),
  },
  bigRow: {
    padding: theme.spacing(2, 0.125)
  },
  head: {
    fontWeight: 600
  },
  placeholder: {
    height: '40px'
  }
}));
