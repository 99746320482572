export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    BUCKET: 'vjt-notes-app-upload',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://37ztjwvfw9.execute-api.us-west-2.amazonaws.com/dev',
    ASSESSMENTS_URL: 'https://vyi3xglow4.execute-api.us-west-2.amazonaws.com/dev',
    COMPANIES_URL: 'https://t3ej6w26j1.execute-api.us-west-2.amazonaws.com/prod',
    DASHBOARDS_URL: 'https://yj1h83qoc5.execute-api.us-west-2.amazonaws.com/prod',
    DOWNTIMES_REASONS_URL: 'https://p51m7is822.execute-api.us-west-2.amazonaws.com/prod',
    DOWNTIMES_EVENTS_URL: 'https://uyv2f789de.execute-api.us-west-2.amazonaws.com/prod',
    INSPECTIONS_URL: 'https://vyi3xglow4.execute-api.us-west-2.amazonaws.com/dev',
    LINES_URL: 'https://k6edix8x3g.execute-api.us-west-2.amazonaws.com/prod',
    OEE_URL: 'https://q770eit1ee.execute-api.us-west-2.amazonaws.com/prod',
    PRODUCTS_URL: 'https://rcph0d7b00.execute-api.us-west-2.amazonaws.com/prod',
    ROLES_URL: 'https://7q4po6dq09.execute-api.us-west-2.amazonaws.com/prod',
    SCHEDULES_URL: 'https://1ueb62j2dj.execute-api.us-west-2.amazonaws.com/prod',
    SHIFT_URL: 'https://xunc5rypfe.execute-api.us-west-2.amazonaws.com/prod',
    SITES_URL: 'https://vd2v4a8mzb.execute-api.us-west-2.amazonaws.com/prod',
    USERS_URL: 'https://f8lvibywx5.execute-api.us-west-2.amazonaws.com/prod',
    NOTIFICATION_URL: 'https://4css48ez03.execute-api.us-west-2.amazonaws.com/prod',
    CIRRUS_URL: 'https://www.cirrusprint.net',
    VRS_URL: 'https://vrs.videojet.com',
    VCCM_HELP_URL: 'https://help.videojetcloud.com/',
    API_APP_URL: 'https://api.videojetcloud.com/'
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_4Ymwhh3Lz',
    APP_CLIENT_ID: '64nbo0qntkj4giu4vt3f18gcbv',
    IDENTITY_POOL_ID: 'us-west-2:3201550e-e47c-44cf-835f-a63786d4f28d',
    SSO_API: 'https://vjcloudservices.auth.us-west-2.amazoncognito.com/authorize?identity_provider=VJHUBADFS&response_type=token&client_id=3aarms5h44ejke940nobm4srso&redirect_uri=https://vccm.dev.videojetcloud.com/showJWT',
  },
  settings: {
    CLIENT_VERSION: '',
  },
};
