import { makeStyles } from "@material-ui/core";
import { mySecondaryColor, myWhite } from "../../constants/colors";

export const useFooterStyle = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    margin: theme.spacing(0, -1),
    padding: theme.spacing(0, 1.5),
    backgroundColor: myWhite,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, -1.125),
      padding: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
  },
  footerLinkContainer: {
    display: 'flex',
    padding: theme.spacing(1.125, 2.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.625, 0.125),
    }
  },
  footerLink: {
    marginRight: theme.spacing(2)
  },
  footerInfo: {
    color: mySecondaryColor
  }
}));
