import React from "react";
import { SimpleMessageProps } from './SimpleMessage.props';

// styles
import useStyles from "./SimpleMessage.css";

// logo
import { ReactComponent as CompanyLogo } from '../Header/videojet-logo.svg';
import { Card } from "@material-ui/core";

function SimpleMessage({ title, subtitle, extraContent }: SimpleMessageProps) {
  const classes = useStyles();

  return (
    <Card className={classes.messageBox}>
      <div className={classes.logoContainer}><CompanyLogo className={classes.logo} /></div>
      <h1>{title}</h1>
      <p  className={classes.content}>{subtitle}</p>
      {extraContent}
    </Card>
  );

}


SimpleMessage.displayName = 'SimpleMessage';

export { SimpleMessage };
