import { makeStyles } from '@material-ui/core';
import { myBlack, myGreyDimmed, myPrimaryColor, mySecondaryColor, mySecondaryColorDimmed, myWhite, myWhiteShade4 } from '../../constants/colors';

export const useSearchableMultipleSelectorStyle = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(0.5)
  },
  textRoot: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.5)
  },
  paper: {
    margin: 0,
    fontSize: theme.spacing(1.625)
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  optionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  optionLabel: {
    backgroundColor: mySecondaryColor,
    color: myWhite,
    borderRadius: '6px',
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.125, 1.125),
    marginRight: theme.spacing(1.125)
  },
  optionPart1: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteItem: {
    position: 'relative',
    left: '50px'
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(1.125),
    justifyContent: 'center',
    alignItems: 'center'
  },
  chip: {
    color: myBlack,
    backgroundColor: myGreyDimmed,
    margin: theme.spacing(0.625, 0.825)
  },
  clipClose: {
    color: myWhite,
    marginTop: theme.spacing(-0.625),
    marginRight: theme.spacing(0.9),
    '&:hover': {
      color: myWhiteShade4,
    }
  },
  clipCloseSimple: {
    color: mySecondaryColor,
    marginTop: theme.spacing(-0.625),
    marginRight: theme.spacing(0.8),
    '&:hover': {
      color: mySecondaryColorDimmed,
    }
  },
  closeIcon: {
    color: myPrimaryColor
  }
}));
