import { makeStyles } from "@material-ui/core";

export const useShiftBarStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    padding: theme.spacing(0.25, 1.25, '!important'),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2, '!important')
    }
  }
}));
