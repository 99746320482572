import momentTimeZone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import toastr from 'toastr';
import Utils from './utils';
import LineApi from '../api/prodLineApi';
import DowntimeApi from '../api/prodDowntimeApi';
import SiteApi from '../api/prodSiteApi';
import * as ENUMS from '../enums/Enums';
import {
  REPORT_DOWNLOAD_PAGE_SIZE,
  REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE,
  REPORT_DOWNLOAD_PROCESSING_PAGE_SIZE
} from '../constants/global';

const momentExtented = extendMoment(momentTimeZone as any);

// eslint-disable no-console

class ReportCsvUtils {
  static quoteData(data) {
    return `"${data}"`;
  }

  /**
   * Get a TZ from a site .. or default
   *
   * @param {Object} site - site
   * @returns {string} timezone indicator or default
   */
  static getSiteTZ(site) {
    if (site.tz && site.tz !== 'default') return site.tz;
    return 'UTC';
  }

  /**
   * Convert partial UTC from dynamodb to a site's time based on tz
   *
   * @param {Object} report - global data
   * @param {string} dtStr - date string to cvt
   * @returns {string} converted date string
   */
  static getSiteDateStr(report, dtStr) {
    let localDt;
    const dtFmt = report.interval === 'h' ? 'YYYY-MM-DD-HH' : 'YYYY-MM-DD';
    if (report.interval === 'h') {
      localDt = momentExtented.utc(dtStr, dtFmt).toDate();
    } else {
      localDt = momentTimeZone.tz(dtStr, dtFmt, report.tz).toDate();
    }
    const transformedDt = Utils.getLocalSiteDateObject(localDt, report.tz);
    return momentExtented(transformedDt).format(dtFmt);
  }

  /**
   * Recursively flatten array of objects
   *
   * @param {Array} a - input
   * @returns {Array} flattened array
   */
  static flatten(a) {
    return Array.isArray(a) ? [].concat(...a.map(ReportCsvUtils.flatten)) : a;
  }

  /**
   * Build a set of values from sliceKey objects based on type
   *
   * @param {Array} slices - array to look thru
   * @param {string} which - which type sliceKey to get
   * @returns {Set} sliceKey values array
   */
  static getSliceValues(slices, which) {
    const value = new Set();
    if (Array.isArray(slices)) {
      // eslint-disable-next-line array-callback-return
      slices.map((slice) => {
        if (slice.type === which) value.add(slice.id);
      });
    }
    return value;
  }

  /**
   * Get id and title from an item, with defaults
   *
   * @param {Object} item - item
   * @returns {Object} - id and title
   */
  static getIdAndTitle(item) {
    const data = { id: 'nodata', title: 'nodata' };
    if (item) {
      if (item.id) data.id = item.id;
      if (item.title) data.title = item.title;
    }
    return data;
  }

  static getTitle(item) {
    return ReportCsvUtils.getIdAndTitle(item).title;
  }

  static getTitles(dictionary, ids, defaultVal = 'nodata') {
    if (Array.isArray(dictionary)) {
      if (Array.isArray(ids) && ids.length) {
        const recs = dictionary.filter(i => ids.includes(i.id)).map(i => i.title);
        if (recs.length) return recs.join(', ');
      } else if (ids && ids.length) {
        const recs = dictionary.filter(i => ids.includes(i.id)).map(i => i.title);
        if (recs.length) return recs.join(', ');
      }
    }
    return defaultVal;
  }

  static localDateStr(dateStr, tz) {
    return momentTimeZone.tz(dateStr.slice(0, 23).concat('Z'), tz).format('YYYY-MM-DD HH:mm');
  }

  static getDayRangeDates(day) {
    const newDay = momentExtented.utc(day);
    return {
      startDt: newDay.toISOString(),
      endDt: newDay.clone().add(1, 'day').subtract(1, 'ms').toISOString(),
    };
  }


  static getFilterInfo(filter, site, lines, events, products, allProducts) {
    const report: any = {};
    // Config info
    report.byShift = filter && (Array.isArray(filter.reportShifts) ? filter.reportShifts.length > 0 : filter.reportShifts !== undefined && filter.reportShifts !== '' && filter.reportShifts !== 'all');
    report.byLine = filter && (Array.isArray(filter.lineIds) ? filter.lineIds.length > 0 : filter.lineIds !== undefined && filter.lineIds !== '');
    report.byProd = filter && filter.reportProduct !== undefined && filter.reportProduct !== '';

    // Time info
    report.tz = ReportCsvUtils.getSiteTZ(site);
    report.startDt = Utils.getFinalTimeForServer(filter.startDt, report.tz);
    report.rptStartDt = momentExtented(filter.startDt).local().format('YYYY-MM-DD');
    report.endDt = Utils.getFinalTimeForServer(filter.endDt, report.tz);
    report.rptEndDt = momentExtented(filter.endDt).local().format('YYYY-MM-DD');
    report.dayRange = momentExtented.range(report.startDt, report.endDt);
    report.dayRangeDates = [];
    Array.from(report.dayRange.by('day')).forEach((day) => {
      report.dayRangeDates.push(ReportCsvUtils.getDayRangeDates(day));
    });

    // Site info
    report.site = ReportCsvUtils.getIdAndTitle(site);
    report.interval = ENUMS.INTERVALS.instances[filter.timeIntervalId].api;

    // Basic tabular data, if available
    report.reasons = Array.isArray(site.downtimeCategories) ? site.downtimeCategories.filter(el => !el.isSubcategory).map(c => ReportCsvUtils.getIdAndTitle(c)) : [];
    report.secondReasons = Array.isArray(site.downtimeCategories) ? site.downtimeCategories.filter(el => el.isSubcategory).map(c => ReportCsvUtils.getIdAndTitle(c)) : [];

    // Get all the product info for lookup
    const localProducts = (products || allProducts);
    report.products = Array.isArray(localProducts) ? localProducts.map(p => ReportCsvUtils.getIdAndTitle(p)) : [];
    // If the user selected some products to filter by...
    if (filter.reportProduct && filter.reportProduct.length > 0) {
      report.productIds = report.products.filter(e => filter.reportProduct === e.id).map(s => s.id);
      report.productProducts = report.productIds;
    } else {
      report.productIds = [];
      report.productProducts = report.products.map(p => p.id);
    }

    // Get all the shift info for lookup
    report.shifts = Array.isArray(events) ? events.map(e => ({ ...ReportCsvUtils.getIdAndTitle(e), lineIds: e.lineIds })) : [];
    // If the user selected some shifts to filter by...
    if (filter.reportShifts && filter.reportShifts.length > 0) {
      report.shiftIds = report.shifts.filter(e => filter.reportShifts.includes(e.id)).map(s => s.id);
      report.productShifts = report.shiftIds;
    } else {
      report.shiftIds = [];
      report.productShifts = report.shifts.map(s => s.id);
    }

    // Get all the line info for lookup
    report.lines = Array.isArray(lines) ? lines.map(l => ReportCsvUtils.getIdAndTitle(l)) : [];
    // If the user selected some lines to filter by...
    if (filter.lineId && filter.lineId !== 'all') {
      report.lineIds = report.lines.filter(l => filter.lineId.includes(l.id)).map(l => l.id);
      report.productLines = report.lineIds;
    } else if (filter.lineIds && filter.lineIds.length > 0) {
      report.lineIds = report.lines.filter(l => filter.lineIds.includes(l.id)).map(l => l.id);
      report.productLines = report.lineIds;
    } else {
      report.lineIds = report.lines.map(l => l.id);
      report.productLines = [];
    }

    return report;
  }

  /**
   * Create a ProcessMinute CSV record
   *
   * @param {Object} report - Data accumulator object
   * @param {Object} item - Item to be processed
   * @returns {{Site: *, Line: *, Date: *, Shift: *, Product: *, Printer: *|string, Count: number, RunRate: *}}
   *          - The data for a single csv record
   */
  static createProcessCsvRecord(report, item) {
    /* eslint-disable no-param-reassign */
    report.item = {};
    // shifts
    report.item.shiftIds = ReportCsvUtils.getSliceValues(item.sliceKeys, 'schedule');
    report.item.shifts = (report.item.shiftIds.size) ? report.shifts.filter(shift => report.item.shiftIds.has(shift.id)) : [];
    report.item.shiftTitles = (report.item.shifts.length < 1) ? 'none' : report.item.shifts.map(shift => ReportCsvUtils.getTitle(shift)).join(';');
    // products
    report.item.productIds = ReportCsvUtils.getSliceValues(item.sliceKeys, 'product');
    report.item.products = (report.item.productIds.size) ? report.products.filter(product => report.item.productIds.has(product.id)) : [];
    report.item.productTitles = (report.item.products.length < 1) ? 'none' : report.item.products.map(product => ReportCsvUtils.getTitle(product)).join(';');
    // line titles
    report.item.lineTitle = ReportCsvUtils.getTitle(report.lines.find(line => line.id === item.lineId));
    // timezone
    report.item.dateStr = ReportCsvUtils.localDateStr(item.dateMinute, report.tz);
    return {
      Site: ReportCsvUtils.quoteData(report.site.title),
      Line: ReportCsvUtils.quoteData(report.item.lineTitle),
      Date: report.item.dateStr,
      Shift: ReportCsvUtils.quoteData(report.item.shiftTitles),
      Product: ReportCsvUtils.quoteData(report.item.productTitles),
      Printer: ReportCsvUtils.quoteData(item.printerSerial || 'nodata'),
      Count: item.estDiff * item.multiplier,
      RunRate: item.targetRateAccumulator,
    };
    /* eslint-enable no-param-reassign */
  }

  static async processOeeData(report, output, smallPagedOutput): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Filter by shift if needed
        if (report.lineIds.length > 0) {
          smallPagedOutput = smallPagedOutput.filter(item => report.lineIds.includes(item.lineId));
        }

        if (report.shiftIds.length > 0) {
          smallPagedOutput = smallPagedOutput.filter((item) => {
            const itemShiftIds = ReportCsvUtils.getSliceValues(item.sliceKeys, 'schedule');
            return report.shiftIds.find(s => itemShiftIds.has(s));
          });
        }

        // Create all process-minute output records using the information gathered above
        smallPagedOutput = smallPagedOutput.map(item => ReportCsvUtils.createProcessCsvRecord(report, item));
        output = output.concat(smallPagedOutput);
        resolve(output);
      });
    });
  }

  static async selectOeeReportData(filter, site, lines, events, products, allProducts, setDownloadProgressIndex, setDownloadProgressCount) {
    try {
      const filename = `VTI.Oee.${momentExtented().format('YYYYMMDD_HHmmss')}.csv`;
      const report = ReportCsvUtils.getFilterInfo(filter, site, lines, events, products, allProducts);
      const promised: Array<any> = [];

      // Query all the process-minutes we need ... but do it by day so
      // we can split up the calls into nice parallel processed segments
      report.lineIds.forEach((lineId) => {
        report.dayRangeDates.forEach((day) => {
          promised.push(LineApi.getLineProcessMinute(report.site.id, lineId, day.startDt, day.endDt));
        });
      });

      const count = Math.ceil(((promised && promised.length) || 0) / REPORT_DOWNLOAD_PAGE_SIZE);
      let output: Array<any> = [];
      setDownloadProgressCount(count);
      for (let page = 1; page <= count; page++) {
        const paged = promised.slice((page - 1) * REPORT_DOWNLOAD_PAGE_SIZE, page * REPORT_DOWNLOAD_PAGE_SIZE);
        let pagedData: Array<any> = [];
        try {
          pagedData = await Promise.all(paged);
        } catch (err) {
          toastr.error(err.message);
        }
        // display progress
        setDownloadProgressIndex(page);

        const pagedOutput: Array<any> = [];
        // Gather the minutes
        pagedData.forEach((item) => {
          if (item.length) {
            pagedOutput.push(...item);
          }
        });

        const processPageCount = Math.ceil(((pagedOutput && pagedOutput.length) || 0) / REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE);
        for (let processPage = 1; processPage <= processPageCount; processPage++) {
          const smallPagedOutput = pagedOutput.slice((processPage - 1) * REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE, processPage * REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE);
          output = await ReportCsvUtils.processOeeData(report, output, smallPagedOutput)
        }
      }

      return { filename, output };

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Create a Downtime CSV record
   *
   * @param {Object} report - Data accumulator object
   * @param {Object} item - Item to be processed
   * @returns {{Site: *, Line: *, Date: *, Shift: *, DurationMin: *, Reason: *}}
   *          - The data for a single csv record
   */
  static createDowntimeCsvRecord(report, item) {
    /* eslint-disable no-param-reassign */
    report.item = {};
    report.item.line = ReportCsvUtils.getTitle(report.lines.find(line => line.id === item.lineId));
    report.item.reason = (item.reasonId === 1 || !item.reasonId) ? 'Unknown' : ReportCsvUtils.getTitle(report.reasons.find(reason => reason.id === item.reasonId));
    report.item.secondReason = (item.secondReasonId === 1 || !item.secondReasonId) ? 'Unknown' : ReportCsvUtils.getTitle(report.secondReasons.find(reason => reason.id === item.secondReasonId));
    report.item.shift = ReportCsvUtils.getTitle(report.shifts.find(shift => shift.id === item.shiftId));
    report.item.duration = momentExtented.duration(momentExtented(item.endDt).diff(momentExtented(item.startDt))).asMinutes();
    report.item.dateStr = ReportCsvUtils.localDateStr(item.startDt, report.tz);
    return {
      Site: ReportCsvUtils.quoteData(report.site.title),
      Line: ReportCsvUtils.quoteData(report.item.line),
      Date: report.item.dateStr,
      Shift: ReportCsvUtils.quoteData(report.item.shift),
      DurationMin: report.item.duration,
      Category: ReportCsvUtils.quoteData(report.item.reason),
      Subcategory: ReportCsvUtils.quoteData(report.item.secondReason),
    };
    /* eslint-enable no-param-reassign */
  }

  static async processDowntimeData(report, output, smallPagedOutput): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Filter by line ids if needed
        if (report.lineIds.length > 0) {
          smallPagedOutput = smallPagedOutput.filter(item => report.lineIds.includes(item.lineId));
        }

        if (report.shiftIds.length > 0) {
          smallPagedOutput = smallPagedOutput.filter(item => report.shiftIds.includes(item.shiftId));
        }

        // Create all downtime output records using the information gathered above
        smallPagedOutput = smallPagedOutput.map(item => ReportCsvUtils.createDowntimeCsvRecord(report, item));
        output = output.concat(smallPagedOutput);
        resolve(output);
      });
    });
  }

  /**
   * Select data for Downtime
   *
   * @param {Object} props - Obj to pull site
   * @param {Object} state - Obj to pull filter
   * @returns {{type: string, output: Array}} - value
   */
  static async selectDowntimeReportData(filter, site, lines, events, products, allProducts, setDownloadProgressIndex, setDownloadProgressCount) {
    try {
      const filename = `VTI.Downtime.${momentExtented().format('YYYYMMDD_HHmmss')}.csv`;
      const report = ReportCsvUtils.getFilterInfo(filter, site, lines, events, products, allProducts);
      const promised: Array<any> = [];
      let output: Array<any> = [];

      // Query some needed data from the system
      report.dayRangeDates.forEach((day) => {
        promised.push(DowntimeApi.getDowntimeEventsByLineList(report.site.id, report.lineIds, day.startDt, day.endDt, null));
      });

      const count = Math.ceil(((promised && promised.length) || 0) / REPORT_DOWNLOAD_PAGE_SIZE);
      setDownloadProgressCount(count);
      for (let page = 1; page <= count; page++) {
        const paged = promised.slice((page - 1) * REPORT_DOWNLOAD_PAGE_SIZE, page * REPORT_DOWNLOAD_PAGE_SIZE);
        let pagedData: Array<any> = [];
        try {
          pagedData = await Promise.all(paged);
        } catch (err) {
          toastr.error(err.message);
        }

        // display progress
        setDownloadProgressIndex(page);

        const pagedOutput: Array<any> = [];
        // Gather the minutes
        pagedData.forEach((item) => {
          if (item.length) {
            pagedOutput.push(...item);
          }
        });

        const processPageCount = Math.ceil(((pagedOutput && pagedOutput.length) || 0) / REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE);
        for (let processPage = 1; processPage <= processPageCount; processPage++) {
          const smallPagedOutput = pagedOutput.slice((processPage - 1) * REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE, processPage * REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE);
          output = await ReportCsvUtils.processDowntimeData(report, output, smallPagedOutput)
        }
      }

      return { filename, output };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Pick one or the other id based on passed type and selections
   *
   * @param {Object} report - global data
   * @param {Array} slices - slices to look for product/shift
   * @param {string} type - which one we want
   * @returns {string} filtered ids or nodata
   */
  static pickId(report, slices, type) {
    if (Array.isArray(slices) && slices.length) {
      const ids = slices.filter(s => s && s.type === type).map(s => s.sliceKey);
      if (ids.length) return ids.join(',');
    }
    return 'nodata';
  }

  static pickIntersectionIds(slices, type, sliceKey) {
    return (slices.filter(sf => sf.type === type && sliceKey.split('_').includes(sf.sliceKey)) || []).map(s2 => s2.sliceKey).join(',');
  }

  static findOppositeId(slices, key, defaultVal = '') {
    const item = slices
      .filter(s => s.type === 'intersection' && s.sliceKey.split('_').includes(key))
      .flatMap(i => i.sliceKey.split('_').filter(k => k !== key)[0]);
    return item.length > 0 ? item[0] : defaultVal;
  }

  /**
   * Get either a set of product records or the detailed result
   *
   * @param {Object} report - global data
   * @param {Object} d - record we are currently working on
   * @param {Object} dr - detailed results record
   * @returns {Array} results
   */
  static getProductOrDetailedRecords(report, d, dr) {
    if (Array.isArray(dr.slices) && dr.slices.length) {
      const records = dr.slices.filter(s => s && s.type === 'product');
      if (records.length > 0) {
        return records.map(s => ({
          lineId: dr.lineId,
          count: s.count,
          target: s.currentTargetCount,
          productId: s.sliceKey,
          shiftId: ReportCsvUtils.pickId(report, dr.slices, 'schedule'),
          date: ReportCsvUtils.getSiteDateStr(report, (d.dateHour || d.date)),
        }));
      }
    }
    return {
      lineId: dr.lineId,
      count: dr.count,
      target: dr.currentTargetCount,
      productId: ReportCsvUtils.pickId(report, dr.slices, 'product'),
      shiftId: ReportCsvUtils.pickId(report, dr.slices, 'schedule'),
      date: ReportCsvUtils.getSiteDateStr(report, (d.dateHour || d.date)),
    };
  }

  /**
   * Process the selected product data into usable records
   *
   * @param {Object} report - global data
   * @param {Object} filter - report filter props
   * @param {Array} data - array to process
   * @returns {Array} processed records array
   */
  static sliceData(report, filter, data) {
    let items;
    let productShiftKey;

    // if there is only one line to query we dont get the entire hierarchy,
    // so instead of changing all the code below, we just make it up...
    if (report.byLine) {
      data = data.map(d => d && ({ ...d, detailedResults: [d] }));
    }

    // Selected BOTH Shifts and Products
    if (report.byShift && report.byProd) {
      const reportShiftsArray = Array.isArray(filter.reportShifts) ? filter.reportShifts : [filter.reportShifts];
      productShiftKey = reportShiftsArray.map(shift => [shift, filter.reportProduct].sort().join('_'));
      items = data.flatMap(d => d && d.detailedResults && d.detailedResults
        .flatMap(dr => dr.slices && dr.slices
          .filter(s => s.type === 'intersection' && productShiftKey.includes(s.sliceKey))
          .map(sf => sf && ({
            lineId: dr.lineId || !Array.isArray(filter.lineIds) ? filter.lineIds : undefined,
            count: sf.count,
            target: sf.currentTargetCount,
            productId: ReportCsvUtils.pickIntersectionIds(dr.slices, 'product', sf.sliceKey),
            shiftId: ReportCsvUtils.pickIntersectionIds(dr.slices, 'schedule', sf.sliceKey),
            date: ReportCsvUtils.getSiteDateStr(report, (d.dateHour || d.date)),
          }))));
    } else if (report.byProd) {
      // Selected Products
      productShiftKey = filter.reportProduct;
      items = data.flatMap(d => d && d.detailedResults && d.detailedResults
        .flatMap(dr => dr.slices && dr.slices && dr.slices
          .filter(s => s.type === 'product' && productShiftKey === s.sliceKey)
          .map(sf => sf && ({
            lineId: dr.lineId || !Array.isArray(filter.lineIds) ? filter.lineIds : undefined,
            count: sf.count,
            target: sf.currentTargetCount,
            productId: sf.sliceKey,
            shiftId: ReportCsvUtils.findOppositeId(dr.slices, sf.sliceKey, 'nodata'),
            date: ReportCsvUtils.getSiteDateStr(report, (d.dateHour || d.date)),
          }))));
    } else if (report.byShift) {
      // Selected Shifts
      productShiftKey = Array.isArray(filter.reportShifts) ? filter.reportShifts : [filter.reportShifts];
      items = data.flatMap(d => d && d.detailedResults && d.detailedResults
        .flatMap(dr => dr.slices && dr.slices && dr.slices
          .filter(s => s.type === 'schedule' && productShiftKey.includes(s.sliceKey))
          .map(sf => sf && ({
            lineId: dr.lineId || !Array.isArray(filter.lineIds) ? filter.lineIds : undefined,
            count: sf.count,
            target: sf.currentTargetCount,
            productId: ReportCsvUtils.findOppositeId(dr.slices, sf.sliceKey, 'nodata'),
            shiftId: sf.sliceKey,
            date: ReportCsvUtils.getSiteDateStr(report, (d.dateHour || d.date)),
          }))));
    } else {
      // Selected NOTHING
      items = data.flatMap(d => d.detailedResults && d.detailedResults
        .flatMap(dr => dr && ReportCsvUtils.getProductOrDetailedRecords(report, d, { ...dr, lineId: dr.lineId || !Array.isArray(filter.lineIds) ? filter.lineIds : undefined })));
    }
    return ReportCsvUtils.flatten(items)
      .filter(i => i)
      .sort((a, b) => (a.productId.localeCompare(b.productId) || a.date.localeCompare(b.date)));
  }

  /**
   * Convert an input record to an output
   *
   * @param {Object} report - global data object
   * @param {Object} detail - detail input record
   */
  static makeProductShiftRecord(report, detail) {
    const item: any = {};
    item.Product = ReportCsvUtils.quoteData(ReportCsvUtils.getTitles(report.products, detail.productId, 'No Product'));
    item.StartDate = report.rptStartDt;
    item.EndDate = report.rptEndDt;
    item.Line = ReportCsvUtils.quoteData(ReportCsvUtils.getTitles(report.lines, detail.lineId));
    item.Shift = ReportCsvUtils.quoteData(ReportCsvUtils.getTitles(report.shifts, detail.shiftId, 'No Shift'));
    if (report.interval === 'h') {
      item.Hour = detail.date;
    } else if (report.interval === 'd') {
      item.Date = detail.date;
    }
    // eslint-disable-next-line no-restricted-globals
    const actual = (isNaN(detail.count)) ? 0 : Number.parseFloat(detail.count);
    item.Actual = actual.toFixed(1);
    // eslint-disable-next-line no-restricted-globals
    const target = (isNaN(detail.target)) ? 0 : Number.parseFloat(detail.target);
    item.Target = target.toFixed(1);
    // eslint-disable-next-line no-restricted-globals
    const value = (actual === 0 || target === 0) ? 0 : ((actual / target) * 100);
    item.Score = `${value.toFixed(3)}%`;
    return item;
  }

  static reportParams(report, day) {
    return {
      siteId: report.site.id,
      startDt: day.startDt,
      endDt: day.endDt,
      interval: report.interval,
      lineIds: report.lineIds,
      shiftIds: report.productShifts,
      productIds: report.productIds,
    };
  }

  static async selectProductReportData(filter, site, lines, events, products, allProducts, setDownloadProgressIndex, setDownloadProgressCount) {
    try {
      const filename = `VTI.Product.${momentExtented().format('YYYYMMDD_HHmmss')}.csv`;
      const report = ReportCsvUtils.getFilterInfo(filter, site, lines, events, products, allProducts);
      const promised: Array<any> = [];
      let input: Array<any> = [];
      let output: Array<any> = [];

      // Split query into day ranges cuz we got a 100 item limit per query here...
      report.dayRangeDates.forEach((day) => {
        promised.push(SiteApi.getSiteOeeSummary(ReportCsvUtils.reportParams(report, day)));
      });

      const count = Math.ceil(((promised && promised.length) || 0) / REPORT_DOWNLOAD_PAGE_SIZE);
      setDownloadProgressCount(count);
      for (let page = 1; page <= count; page++) {
        const paged = promised.slice((page - 1) * REPORT_DOWNLOAD_PAGE_SIZE, page * REPORT_DOWNLOAD_PAGE_SIZE);
        let pagedData: Array<any> = [];
        try {
          pagedData = await Promise.all(paged);
        } catch (err) {
          toastr.error(err.message);
        }
        // display progress
        setDownloadProgressIndex(page);

        // Gather the minutes
        pagedData.forEach((datum) => { // eslint-disable-line no-loop-func
          if (datum.data && datum.data.length) {
            input = input.concat(datum.data);
          }
        });
      }

      if (input && input.length > 0) {
        // sort the input data
        input.sort((a, b) => (a.dateHour ? (a.dateHour.localeCompare(b.dateHour)) : (a.date.localeCompare(b.date))));

        // Preprocess input data into something usable
        const records = ReportCsvUtils.sliceData(report, filter, input);

        // Walk the raw records and make csv records...
        output = records.map(s => ReportCsvUtils.makeProductShiftRecord(report, s));
      }
      return { filename, output };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async processCSV(result, paged): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data: Array<any> = [];
        for (const obj of paged) {
          data.push(Object.values(obj).join(','))
        }

        result = result + (result ? '\n' : '') + data.join('\n');
        resolve(result);
      });
    });
  }

  /**
   * Converts a prepared array or objects into csv format.
   *
   * @example { const { filename, output } = await selectOeeReportData(this.props, this.state); const csv = toCSV(output)}
   * @param {Array} array - an array of _uniform_ objects to convert into a csv format.
   * @returns {undefined|string} - string of csv data
   */
  static async toCSV(rawDataArray): Promise<any> {
    if (!rawDataArray || rawDataArray.length === 0) {
      return undefined;
    }

    let result = '';
    let isHeadAdded = false;

    const count = Math.ceil(((rawDataArray && rawDataArray.length) || 0) / REPORT_DOWNLOAD_PROCESSING_PAGE_SIZE);
    for (let page = 1; page <= count; page++) {
      const paged = rawDataArray.slice((page - 1) * REPORT_DOWNLOAD_PROCESSING_PAGE_SIZE, page * REPORT_DOWNLOAD_PROCESSING_PAGE_SIZE);
      if (!isHeadAdded) {
        isHeadAdded = true;
        if (paged.length > 0) {
          result += Object.keys(paged[0]).join(',');
        }
      }
      result = await ReportCsvUtils.processCSV(result, paged);
    }
    return result;
  }
}

export default ReportCsvUtils;
