import { makeStyles } from "@material-ui/core";

export const useShiftListRowStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  productTitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageHolder: {
    textAlign: 'center',
    verticalAlign: 'center',
    marginRight: theme.spacing(1.25)
  },
  productImage: {
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
    marginRight: theme.spacing(0.625)
  },
}));
