import { withStyles } from '@material-ui/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export { StyledDialogContent }
