import React from 'react';
import { useIntl } from 'react-intl';
import { useUserRolesListTableStyles } from './UserRolesListTable.css';
import { Grid } from '@material-ui/core';

const roleGridBreaks = {
  name: { lg: 4, md: 4, sm: 4, xs: 4 },
  roles: { lg: 8, md: 8, sm: 8, xs: 8 },
};

interface IUserRolesListRowProps {
  role: any;
  gridBreak: any;
}

const UserRolesListRow = ({ role, gridBreak }: IUserRolesListRowProps) => {
  const classes = useUserRolesListTableStyles();
  const intl = useIntl();
  return <>
    <Grid item {...gridBreak.name} classes={{ root: classes.roleGridCell }}>
      {role.authModule && intl.formatMessage({ id: `detail_AUTH_MODULE_NAME_${role.authModule.toUpperCase()}` })}
    </Grid>
    <Grid item {...gridBreak.roles} classes={{ root: classes.roleGridCell }}>
      {role.siteRoles.sort().join(', ')}
    </Grid>
  </>
}

interface IUserRolesListTableProps {
  userRolesToDisplay: Array<any>;
}
const UserRolesListTable = ({ userRolesToDisplay }: IUserRolesListTableProps) => {
  const classes = useUserRolesListTableStyles();
  const gridBreak: any = roleGridBreaks;
  return <>
    {
      userRolesToDisplay.length > 0 && userRolesToDisplay.some(ur => ur.siteRoles.length > 0)
      && (<Grid container spacing={1} className={classes.roleGridContainer}>
        {
          userRolesToDisplay.map(ur => {
            return ur.siteRoles.length > 0 &&
              <UserRolesListRow key={ur.authModule} role={ur} gridBreak={gridBreak} />
          })}
      </Grid>)
    }
  </>
}

export default UserRolesListTable;
