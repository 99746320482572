export const getAbilityDescription = (ability) => {
  if (!ability) return null;
  switch (ability.toLowerCase()) {
    // Setup
    case 'view-schedule':
      return 'detail_ViewShiftDescription';
    case 'edit-schedule':
      return 'detail_EditShiftDescription';
    case 'view-product':
      return 'detail_ViewProductsDescription';
    case 'edit-product':
      return 'detail_EditProductDescription';
    case 'view-line':
      return 'detail_ViewLinesDescription';
    case 'edit-line':
      return 'detail_EditLineDescription';
    case 'view-downtimereason':
      return 'detail_ViewDowntimeReasonsDescription';
    case 'edit-downtimereason':
      return 'detail_EditDowntimeReasonDescription';
    case 'view-devicerole':
      return 'detail_ViewDeviceRoleDescription';
    case 'edit-devicerole':
      return 'detail_EditDeviceRoleDescription';
    // User Management
    case 'view-role':
      return 'detail_ViewRolesDescription';
    case 'edit-role':
      return 'detail_EditRoleDescription';
    case 'create-user':
      return 'detail_CanCreateUser';
    case 'delete-user':
      return 'detail_EditRemoveUserDescription';
    case 'assign-user-role':
      return 'detail_AssignUserRoleDescription';
    case 'edit-setting':
      return 'detail_EditSettingDescription';
    // Operation
    case 'assign-line-printer':
      return 'detail_AssignPrinterDescription';
    case 'assign-line-product':
      return 'detail_AssignProductDescription';
    case 'assign-downtime-reason':
      return 'detail_AssignDowntimeReasonDescription';
    case 'apply-qa':
      return 'detail_AssignQAcountDescription';
    case 'approve-assessment':
      return 'detail_ApproveAssessmentDescription';
    case 'edit-assessment':
      return 'detail_EditAssessmentDescription';
    case 'view-assessment':
      return 'detail_ViewAssessmentDescription';
    // Site Management
    case 'view-sitedef':
      return 'detail_ViewSiteSettingsDescription';
    case 'edit-sitedef':
      return 'detail_EditSiteDefDescription';
    case 'edit-siteconfig':
      return 'detail_EditSiteConfigDescription';
    // Report
    case 'view-report':
      return 'detail_ViewReports';
    case 'edit-report':
      return 'detail_EditReportDescription';
    case 'edit-global-report':
      return 'detail_EditGlobalReportDescription';
    // Design
    case 'edit-design':
      return 'detail_DesignAccessDescription';
    case 'send-printerjob':
      return 'detail_DownloadTemplateDescriptionPrinter';
    case 'download-printerjob':
      return 'detail_DownloadTemplateDescriptionUSB';
    case 'view-inspection':
      return 'detail_ViewInspectionDescription';
    case 'edit-inspection':
      return 'detail_EditInspectionDescription';
    case 'perform-inspection':
      return 'detail_PerformInspectionDescription';
    // Order Execution
    case 'edit-printerjob':
      return 'detail_EditOrderExDescription';
    case 'execute-printerjob':
      return 'detail_ExecuteOrderExDescription';
    case 'full-access':
      return 'detail_FullAccessDescription';

    default:
      return ability;
  }
}
