import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';

interface ILineListRowProps {
  line: any;
  siteId: string;
  gridBreak: any;
  onDeleteLine: (line: any) => void;
}

const LineListRow = ({ line, siteId, gridBreak, onDeleteLine }: ILineListRowProps) => {
  const intl = useIntl();
  return (
    <StyledGridRow>
      <StyledGridCell {...gridBreak.name} >
        <div><span style={{ color: line.display.color, margin: '0px 5px 0px 0px', fontSize: 'x-large' }}>
          ●
      </span><strong>{line.title}</strong></div>
        <div><span style={{ color: 'transparent', margin: '0px 5px 0px 0px', fontSize: 'x-large' }}>
          ●
      </span><em>{line.description}</em></div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.product} >
        <div className="align-bottom">
          <strong>
            {line.product
              ? line.product.title
              : ''}
          </strong>
        </div>
        <div>
          <em>
            {line.product
              ? line.product.description
              : ''}
          </em>
        </div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.targetRate} >
        <div><strong><FormattedMessage id="detail_Units_Hour" /></strong></div>
        <div>
          <em style={{ fontSize: 'Larger' }}>
            {Math.round(line.runRate)
              .toLocaleString('en')}
          </em>
        </div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.printer} >
        <div><strong>{line.printerId}</strong></div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons} >
        <StyledActionContainer breaks={{ md: true }}>
          <Link to={`/site/${siteId}/setup/line/${line.id}/edit`}
          >
            <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
              <ActionButton>
                <EditIcon />
              </ActionButton>
            </Tooltip>
          </Link>
          <Link to={`/site/${siteId}/setup/line/${line.id}/clone`}
          >
            <Tooltip title={intl.formatMessage({ id: 'detail_Clone' })}>
              <ActionButton>
                <FileCopyIcon />
              </ActionButton>
            </Tooltip>
          </Link>


          <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
            <ActionButton
              onClick={() => onDeleteLine(line)}>
              <DeleteIcon />
            </ActionButton>
          </Tooltip>
        </StyledActionContainer>
      </StyledGridCell>
    </StyledGridRow>
  )
};

export default LineListRow;
