import { makeStyles } from "@material-ui/core";
import { mySecondaryColor } from "../../constants/colors";

export const useHomePageStyles = makeStyles((theme) => ({
  cardRoot: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.spacing(100),
    padding: theme.spacing(1.5, 2.5),
    margin: theme.spacing(1.5, 1.25)
  },
  vccmImage: {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1),
    maxWidth: theme.spacing(84.375),
    maxHeight: theme.spacing(53.55),
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(55),
      maxHeight: theme.spacing(34.90),
    },
  },
  content: {
    fontSize: '20px'
  },
  dashboardBtns: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  headText: {
    fontWeight: 600,
    fontSize: theme.spacing(3.5)
  },
  text: {
    fontSize: theme.spacing(2.2)
  },
  subheadText: {
    fontWeight: 600,
    fontSize: theme.spacing(2.5)
  },
  subheadTextAccess: {
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  dividerContainer: {
    width: '100%'
  },
  divider: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(1.25),
    backgroundColor: mySecondaryColor
  },
  textRow: {
    justifyContent: 'center',
    margin: theme.spacing(1)
  },
  row: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },
  cell: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  homeButtonSubText: {
    fontSize: theme.spacing(1.5)
  }
}));
