import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { TableHeadText } from '../../../../components/TableHeadText/TableHeadText';

import DowntimeCategoryListRow from '../DowntimeCategoryListRow/DowntimeCategoryListRow';
import StyledGrid from '../../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledContentCard from '../../../../components/Basic/StyledContentCard/StyledContentCard';
import { DowntimeCategoryBar } from '../DowntimeCategoryBar/DowntimeCategoryBar';
import Can from '../../../../components/Common/Can';
import { EmptyItem } from '../../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../../constants/global';
import { useDowntimeCategoryListTableStyles } from './DowntimeCategoryListTable.css';
import { useConfigActions } from '../../../../actions/configActions';
import { useAppGlobalState } from '../../../../context/AppContext/AppContext';


interface IDowntimeCategoryListState {
  page: number;
  sortedDowntimeCategories: Array<any>;
  pageCount: number;
  pagedDowntimeCategories: Array<any>;
}

const set1Breaks = {
  name: { lg: 2, md: 2, sm: 3, xs: 12 },
  description: { lg: 2, md: 2, sm: 3, xs: 12 },
  type: { lg: 2, md: 2, sm: 3, xs: 12 },
  subcategories: { lg: 2, md: 2, sm: 3, xs: 12 },
  actionButtons: { lg: 4, md: 4, sm: 12, xs: 12 }
};
interface IDowntimeCategoryListTableProps {
  downtimeCategories: Array<any>;
  downtimeTypesFormatted: Array<any>;
  siteId: string;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const DowntimeCategoryListTable = ({
  downtimeCategories,
  downtimeTypesFormatted,
  siteId,
  setRefreshRequired }: IDowntimeCategoryListTableProps) => {
  const classes = useDowntimeCategoryListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';

  const gridBreak: any = set1Breaks;

  const configActions = useConfigActions();
  const { appFilters } = useAppGlobalState();

  const [filteredDowntimeCategories, setFilteredDowntimeCategories] = useState<Array<any>>([]);

  const [downtimeCategoryListState, setDowntimeCategoryListState] = useState<IDowntimeCategoryListState>({
    page: 1,
    sortedDowntimeCategories: [],
    pageCount: 0,
    pagedDowntimeCategories: []
  });

  const {
    page,
    sortedDowntimeCategories,
    pageCount,
    pagedDowntimeCategories
  } = downtimeCategoryListState;

  useEffect(() => {
    setDowntimeCategoryListState(s => {
      const sorted = downtimeCategories.filter(el => !el.isSubcategory).sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        sortedDowntimeCategories: sorted,
      }
    })
  }, [downtimeCategories]);

  useEffect(() => {
    const searchTerm = appFilters.categoryFilter.searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredCategories = searchTerm
      ? sortedDowntimeCategories.filter(category => category.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedDowntimeCategories;

    setFilteredDowntimeCategories(initialFilteredCategories);

  }, [appFilters, sortedDowntimeCategories]);

  useEffect(() => {
    setDowntimeCategoryListState(s => {
      const count = Math.ceil(((filteredDowntimeCategories && filteredDowntimeCategories.length) || 0) / PAGE_SIZE);
      const paged = filteredDowntimeCategories.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedDowntimeCategories: paged
      }
    })
  }, [filteredDowntimeCategories]);

  const onPageChange = useCallback(p => {
    setDowntimeCategoryListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredDowntimeCategories.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setDowntimeCategoryListState(s => ({
      ...s,
      pagedDowntimeCategories: paged
    }));
  }, [page, filteredDowntimeCategories]);

  return (
    <>
      <Can do="edit" on="downtimeReason">
        <DowntimeCategoryBar
          siteId={siteId}
          filter={appFilters.categoryFilter}
          setFilter={configActions.setBarFilter}
        />
      </Can>

      <StyledContentCard>
        {downtimeCategories && downtimeCategories.filter(el => !el.isSubcategory).length > 0 ? <StyledGrid>
          {
            appFilters.categoryFilter.searchTerm.length === 0 || pagedDowntimeCategories.length > 0 ? <StyledGridHeadRow>
              <StyledGridHeadCell {...gridBreak.name}>
                <TableHeadText
                  textId="detail_Name"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.description}>
                <TableHeadText
                  textId="detail_Description"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.subcategories}>
                <TableHeadText
                  textId="detail_Subcategories"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell {...gridBreak.type}>
                <TableHeadText
                  textId="detail_Type"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              <StyledGridHeadCell item {...gridBreak.actionButtons}>
              </StyledGridHeadCell>
            </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
          }
          <>
            {
              pagedDowntimeCategories.map(category =>
              (
                <DowntimeCategoryListRow
                  key={category.id}
                  siteId={siteId}
                  category={category}
                  categories={downtimeCategories}
                  downtimeTypesFormatted={downtimeTypesFormatted}
                  gridBreak={gridBreak}
                  setRefreshRequired={setRefreshRequired}
                />
              )
              )
            }
          </>
        </StyledGrid>
          : <EmptyItem textId="detail_noDowntimeCategory" />
        }
        {
          filteredDowntimeCategories && filteredDowntimeCategories.length > PAGE_SIZE ? <div className={classes.pageContainer}>
            <Paging
              page={page}
              onChange={onPageChange}
              count={pageCount}
              total={filteredDowntimeCategories.length} /></div> : null
        }
      </StyledContentCard>
    </>
  );
}

export default DowntimeCategoryListTable;
