import { invokeApig } from '../libs/awsLib';
import * as config from '../config';

class CirrusApi {
  // base URI for Cirrus API calls
  static baseUri = config.default.apiGateway.CIRRUS_URL;

  static authenticate(token) {
    const url = `${this.baseUri}/account/authenticate2`;
    const data = { offset: -60, token: token };

    return fetch(url, {
      body: JSON.stringify(data),
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST', // or 'PUT'
    });
  }

  static currentUser() {
    const url = `${this.baseUri}/account/currentuser`;

    return fetch(url, {
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST', // or 'PUT'
    });
  }

  /**
   * loads the jobs for a given printerId
   *
   * @function
   *
   * @param {string} printerId
   *   - gets all the valid jobs for this model.
   *
   * @param {string} token
   *   - token used for api identification and access.
   *
   * @example [{"UniqueJobCode":"James","CiffReference":"6230I#32#test.ciff","JobCode1":"","JobCode2":"","JobCode3":"","Id":"James","DisplayedTitle":"James","PrinterType":"DataFlex 6230 Intermittent","PrinterFormat":"32mm","Base64":"","Description":"James"},{"UniqueJobCode":"Rob","CiffReference":"6230I#32#test1.ciff","JobCode1":"","JobCode2":"","JobCode3":"","Id":"Rob","DisplayedTitle":"Rob","PrinterType":"DataFlex 6230 Intermittent","PrinterFormat":"32mm","Base64":"","Description":"Rob"},{"UniqueJobCode":"Jeff_Job","CiffReference":"6230I#32#Jeff_Template.ciff","JobCode1":"Videojet","JobCode2":"GenericProduct","JobCode3":"GenericSize","Id":"Jeff_Job","DisplayedTitle":"Jeff_Job","PrinterType":"DataFlex 6230 Intermittent","PrinterFormat":"32mm","Base64":"","Description":"Jeff_Job"}]
   */
  static getAllJobs(printerId) {
    const url = `${this.baseUri}/jobs/${printerId}`;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });
  }

  /**
   * Gets printers for the given siteId
   *
   * @function
   *
   * @param {string} token
   *   - SiteId to get associated jobs
   *
   * @example {"PrinterList":{"TTO":[{"Name":"DataFlex 6530 Continuous","Id":"DataFlex 6530(53mm) - Continuous","Formats":["53mm"]},{"Name":"DataFlex 6230 Intermittent","Id":"DataFlex 6230(32mm) - Intermittent","Formats":["32mm"]}],"TIJ":[{"Name":"Videojet 8510","Id":"VJ8510","Formats":["WOLKE_TEMPLATE_1_HEAD","WOLKE_TEMPLATE_2_HEAD_2_DIFFERENT","WOLKE_TEMPLATE_2_HEAD_ALL_LIKE_HEAD_1","WOLKE_TEMPLATE_3_HEAD_3_DIFFERENT","WOLKE_TEMPLATE_3_HEAD_ALL_LIKE_HEAD_1","WOLKE_TEMPLATE_4_HEAD_4_DIFFERENT","WOLKE_TEMPLATE_4_HEAD_ALL_LIKE_HEAD_1","WOLKE_TEMPLATE_2_HEAD_1_COMBINED","WOLKE_TEMPLATE_4_HEAD_3AND4_LIKE_1AND2","WOLKE_TEMPLATE_3_HEAD_1_COMBINED","WOLKE_TEMPLATE_4_HEAD_1_COMBINED"]}]},"DefaultSettings":{"DefaultPrinterName":"DataFlex 6230 Intermittent","DefaultFormat":"32mm"}}
   */
  static getAvailablePrinters() {
    const url = `${this.baseUri}/jobs/printers`;

    return fetch(url, {
      credentials: 'include',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST', // or 'PUT'
    });
  }

  static getCirrusToken(siteId) {
    const apiUrl = '/users/tdtoken/' + siteId;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'GET' });
  }

  /**
   * Gets the zippedJobFile from cirrus.
   *
   * @function
   *
   * @param {string} jobId
   *   - SiteId to get associated jobs
   *
   */
  static getJobFile(jobId) {
    const apiUrl = `${this.baseUri}/jobs/file`;
    const data = `JobIdList=${jobId}`;

    return fetch(apiUrl, {
      body: data,
      credentials: 'include',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
  }
}

export default CirrusApi;
