import Ability from '../ability';
import { upperFirstLetterOfEachWord } from '../utilities/stringutils';

const debug = false;

class UserHelper {
  /**
   * Find and return an email address for a specified userId from an array of cognito user records
   *
   * @param users - {Array} - Cognito user records
   * @param userId - {string} - Cognito user id
   * @returns {string} Email or empty string
   */
  static getUserEmailFromList(users, userId) {
    let email = '';
    const found = users && users.find(u => u.Username === userId);
    if (found) {
      email = found.Attributes.find(att => att.Name === 'email').Value;
    }
    return email;
  }

  static getEmailAddressBySession = (user) => {
    if (!user || !user.signInUserSession || !user.signInUserSession.idToken.payload) return null;
    const { email } = user.signInUserSession.idToken.payload;
    return email
      || '';
  };

  static UserInRole(user, role, siteId) {
    if (!role || !user || !user.roles || !user.roles || !user.roles[siteId]) return undefined;
    // debug && console.log(user.roles[siteId].filter(r => r.startsWith(role)));
    return user.roles[siteId].filter(r => r.startsWith(role)) ? user : undefined;
  }

  static UsersInRole(users, role, siteId) {
    debug && console.log(this.UsersInRole.name, { users, role, siteId });
    if (!role || !users) return [];
    const retVal = users.filter(user => this.UserInRole(user, role, siteId));
    debug && console.log(this.UsersInRole.name, 'returning', retVal);
    return retVal;
  }

  static UserInAuthModule(user, authModule, siteId) {
    debug && console.log(this.UserInAuthModule.name, { user, authModule, siteId });
    if (!authModule || !siteId || !user || !user.authRoles) return false;
    const authModuleRoles = user.authRoles.find(m => m.authModule === authModule);
    if (!authModuleRoles) return false;
    const retVal = (authModuleRoles[siteId] !== false);
    debug && console.log(this.UserInAuthModule.name, 'returning', retVal);
    return retVal;
  }

  static UserInAuthModules(user, authModules, siteId) {
    debug && console.log(this.UserInAuthModules.name, { user, authModules, siteId });
    if (!authModules || !user || !user.authRoles) return [];
    const retVal = authModules.filter(m => this.UserInAuthModule(user, m, siteId)).length > 0;
    debug && console.log(this.UserInAuthModules.name, 'returning', retVal);
    return retVal;
  }

  static UsersInAuthModules(users, authModules, siteId) {
    if (authModules.length > 0) return users;
    debug && console.log(this.UsersInAuthModules.name, { users, authModules, siteId });
    const retVal = users.filter(user => this.UserInAuthModules(user, authModules, siteId));
    debug && console.log(this.UsersInAuthModules.name, 'returning', retVal);
    return retVal;
  }

  /**
   *
   * @param user - User from cognito
   * @returns {{firstName: string, lastName: string, fullName: string, email: string}|null}
   */
  static getProfile = (user): any => {
    if (!user || !user.Attributes) {
      return {};
    }

    // if no firstName use empty string
    let firstName = user.Attributes.find(
      att => att.Name === 'given_name',
    );
    firstName = firstName ? firstName.Value : '';

    // if no lastName use empty string
    let lastName = user.Attributes.find(
      att => att.Name === 'family_name',
    );
    lastName = lastName ? lastName.Value : '';

    const email = user.Attributes.find(
      att => att.Name === 'email',
    ).Value;

    return { firstName, lastName, fullName: `${firstName} ${lastName}`, email };
  };

  static getEmailAddress = (user) => {
    if (!user || !user.Attributes) return undefined;
    return user.Attributes.length > 0
      ? user.Attributes.find(
        att => att.Name === 'email',
      )
        ? user.Attributes.find(
          att => att.Name === 'email',
        ).Value
        : '' : console.log('no email for user =>', user);
  };

  static getRoleById(roles, id) {
    if (!roles || !roles.length || !id) return null;
    const role = roles.find(r => r.id === id);
    if (role) return role; // since filter returns an array, have to grab the first.
    return null;
  }

  static getUserById(users, id) {
    const user = users.find(u => u.Username === id);
    if (user) return user; // since filter returns an array, have to grab the first.
    return null;
  }

  static getAllowedAuthModules(isAdmin) {
    const allowedAuthModules: Array<any> = [];
    const able = Ability.can('vccm', 'authModule');
    const isVccmTopLevel = isAdmin || able;
    if (isVccmTopLevel) {
      allowedAuthModules.push({ text: 'API', value: 'api' });
      allowedAuthModules.push({ text: 'VRS', value: 'vrs' });
      allowedAuthModules.push({ text: 'Videojet Connect', value: 'vccm' });
    } else {
      if (Ability.can('vrs', 'authModule')) allowedAuthModules.push({ text: 'VRS', value: 'vrs' });
    }
    return allowedAuthModules;
  }


  static formatAbilities(abilities, authModule = null) {
    if (!abilities) return [];
    if (authModule) {
      return abilities.map((ability) => {
        const actions = authModule;
        const subject = 'authModule';
        return { actions, subject, fields: abilities };
      });
    }
    return abilities.map((ability) => {
      const parts = ability.split('-');
      const actions = parts[0];
      const subject = ability.substr(actions.length + 1);
      return { actions, subject };
    });
  }

  /**
   * Test for ability
   *
   * @param {string} action - action to test for
   * @param {string} subject - subject to test ability on
   * @returns {RawRule} true action and ability exist for the user.
   */
  static Can(action, subject) {
    return Ability.rules.find(r => r.actions === action && (r.subject === subject || (Array.isArray(r.subject) && r.subject.indexOf(subject) > -1)));
  }

  /**
  * Test for ability
  *
  * @param {string} action - action to test for
  * @param {string} subject - subject to test ability on
  * @param {string} field - subject to test ability on
  * @returns {RawRule} true action and ability exist for the user.
  */
  static CanWithField(action, subject, field) {
    return Ability.rules.find(r => {
      return r.actions === action && r.subject === subject && r.fields && r.fields.indexOf(field) > -1;
    });
  }

  static CanAccessModule(module) {
    console.log('Ability.rules=', JSON.stringify(Ability.rules, null, 2));
    if (module === 'api') {
      // Give everybody access regardless of user permissions
      return true;
    }

    if (module === 'vrs') {
      return !!Ability.rules.find(el => el.actions === 'vrs');
    }

    if (module === 'Intelligence') {
      return !!Ability.rules.find(el => el.actions === 'vccm');
    }

    if (module === 'Inspect') {
      return !!Ability.rules.find(el => el.subject === 'qa' || el.subject === 'assessment' || el.subject === 'inspection');
    }

    if (module === 'Design') {
      return !!Ability.rules.find(el => el.subject === 'design' || el.subject === 'printerJob');
    }

    return false;
  }

  /**
   * Translates role titles if key present
   *
   * @param roles - from the API
   * @param intl - react-intl intlShape
   * @returns {any[]} - translated roles
   */
  static translateRoles(roles, intl) {
    if (!roles || !intl) {
      return roles;
    }
    return roles.map(r => {
      const title = r.title;
      return r.readonly ? ({
        ...r,
        title: intl.formatMessage({
          id: r.key || `detail_${upperFirstLetterOfEachWord(title, false)}`
        })
      }) : r;
    });
  }

  static addApiOrVrsRolesForAdmin(roles, site) {
    let extraRoles: Array<any> = [];
    const siteId = site.id.toString();
    const modules = site.modules && site.modules.values && site.modules.values;
    if (!roles.find(el => el.authModule === 'vrs' && el.siteId === siteId) && modules && modules.indexOf('vrs') > -1) {
      extraRoles = [
        ...extraRoles,
        { id: `vrs-Admin-${siteId}`, title: 'Admin', authModule: 'vrs', siteId },
        { id: `vrs-User-${siteId}`, title: 'User', authModule: 'vrs', siteId },
        { id: `vrs-Maintenance-${siteId}`, title: 'Maintenance', authModule: 'vrs', siteId }];
    }

    if (!roles.find(el => el.authModule === 'api' && el.siteId === siteId) && modules && modules.indexOf('api') > -1) {
      extraRoles = [
        ...extraRoles,
        { id: `api-full-access-${siteId}`, title: 'full-access', authModule: 'api', siteId }];
    }

    return extraRoles;
  }
}

export default UserHelper;
