import Moment from 'moment';
import Utils from '../../utilities/utils';

class ChartHelper {
  static mergeTimeLine(timeLine, slowCycles) {
    return Utils.addProcessMinuteDataToTimeInStateEvents(timeLine, slowCycles)
      .sort((a, b) => Moment(a.startTime)
        .toDate().valueOf() - Moment(b.startTime)
          .toDate().valueOf());
  }

}

export default ChartHelper
