import toastr from 'toastr';
import React, { useEffect, useState } from 'react';
import { CognitoUserPool, CognitoUser, } from 'amazon-cognito-identity-js';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import config from '../../config';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  FormControl,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps
} from '@material-ui/core';

import { usePasswordResetDialogStyle } from './PasswordResetDialog.css';
import { NewPasswordWithCodeDialog } from '../NewPasswordWithCodeDialog/NewPasswordWithCodeDialog';

const simpleEmailValidate = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface PasswordResetDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps['maxWidth'];
}

const PasswordResetDialog = ({
  open,
  maxWidth,
  onClose }: PasswordResetDialogProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = usePasswordResetDialogStyle();
  const [email, setEmail] = useState<string>('');
  const [requestError, setRequestError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  // toast on error
  useEffect(() => {
    if (requestError) {
      toastr.error(requestError);
      setRequestError('');
    }
  }, [requestError]);

  const passwordReset = (email) => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });

    const user = new CognitoUser({ Username: email, Pool: userPool });
    console.log('PasswordReset CognitoUser=>', user);
    return new Promise((resolve, reject) =>
      user.forgotPassword({
        onSuccess: function (result) {
          setIsLoading(false);
          setShowPassword(true);
          console.log('PasswordReset onSuccess=>', result);
          toastr.success('Password reset');
          resolve(true);
        },
        onFailure: function (err) {
          console.log('PasswordReset onFailure=>', err);
          toastr.error(err.message, 'Password Reset', { timeOut: 10000 });
          reject(err);
        },
        inputVerificationCode(data) {
          /*        console.log('Code sent to: ' + data);
                    let verificationCode = prompt('Please input verification code ' ,'');
                    let newPassword = prompt('Enter new password ' ,'');
                    user.confirmPassword(verificationCode, newPassword, this);*/
        },
      }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowPassword(true);
    try {
      await passwordReset(email);
    } catch (e) {
      console.log(e);
    }
  };

  const onCloseNewPasswordDialog = async (verificationCode, password) => {
    if (verificationCode && password) {
      setIsLoading(true);
      try {
        const userPool = new CognitoUserPool({
          UserPoolId: config.cognito.USER_POOL_ID,
          ClientId: config.cognito.APP_CLIENT_ID,
        });
        const user = new CognitoUser({ Username: email, Pool: userPool });

        return new Promise((resolve, reject) => {
          user.confirmPassword(verificationCode, password,
            {
              onSuccess: function () {
                toastr.success('Password reset');
              },
              onFailure: function (err) {
                toastr.error('Error reset password');
              },
            },
          );
          history.push(`/`);
        });
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    } else {
      setShowPassword(false);
    }
  };

  return <>
    <Dialog disableBackdropClick
      classes={{ paper: classes.root }}
      fullWidth={true}
      maxWidth={maxWidth || 'sm'}
      disableEscapeKeyDown
      aria-labelledby="password-reset" open={open}>
      <DialogTitle id="terms-dialog-title" >
        <div className={classes.title}>
          <div>
            <PersonAddIcon className={classes.titleIcon} />
          </div>
          <div>
            <div className={classes.titleText}>{intl.formatMessage({ id: 'detail_Password_Reset' })}</div>
          </div>
        </div>
        <IconButton aria-label="close" className={classNames(classes.closeButton, 'terms-close-btn')} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl required className={classes.formControl}>
          <TextField
            autoComplete="off"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label={intl.formatMessage({ id: 'detail_Email' })}
            onChange={(event) => setEmail(event.target.value)}
            id="email"
            value={email}
            error={false}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button autoFocus
          onClick={handleSubmit}
          className="reset-ok-btn"
          variant="contained"
          disabled={!simpleEmailValidate.test(email)}
          color="primary">
          {intl.formatMessage({ id: 'detail_Submit' })}
        </Button>
      </DialogActions>
    </Dialog>
    {
      showPassword && <NewPasswordWithCodeDialog
        loading={isLoading}
        open={showPassword}
        onClose={onCloseNewPasswordDialog}
      />
    }
  </>
};

export { PasswordResetDialog };
