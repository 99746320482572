import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';
import { useHistory } from 'react-router';
import ability from '../../../ability';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { FormControl, Grid } from '@material-ui/core';
import { useUserManagementBarStyles } from './UserManagementBar.css';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import BarStyledGrid from '../../../components/Basic/BarStyledGrid/BarStyledGrid';
import { ChipSelector } from '../../../components/Basic/ChipSelector/ChipSelector';
import { useIsSmallSelector } from '../../../context/LayoutContext/LayoutContext';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';

const gridBreak: any = {
  create: { lg: 3, md: 3, sm: 12, xs: 12 },
  filter: { lg: 9, md: 9, sm: 12, xs: 12 },
};

const gridBreakSmall: any = {
  create: { lg: 3, md: 3, sm: 4, xs: 6 },
  search: { lg: 3, md: 9, sm: 8, xs: 6 },
  filter: { lg: 6, md: 12, sm: 12, xs: 12 },
};

interface IUserManagementBarProps {
  siteId: string;
  allowedAuthModules: Array<any>;
  filter: {
    searchTerm: string;
    filteredAuthModules: Array<string>;
    showAllUsers: boolean;
  };
  setFilter: (string, any) => void;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}


const UserManagementBar = ({ siteId,
  allowedAuthModules,
  filter,
  setFilter,
  setRefreshRequired }: IUserManagementBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useUserManagementBarStyles();
  const { isAdmin } = useAppGlobalState();

  const smallMode = useIsSmallSelector();
  const activeBreak = smallMode ? gridBreakSmall : gridBreak;

  const canCreate = ability.rules.find(r => (r.actions === 'vrs' && r.subject === 'authModule' && r.fields && r.fields.includes('Administrator')) || (r.actions === 'create' && r.subject === 'user')) || isAdmin || false;

  const addUser = () => {
    history.push(`/site/${siteId}/setup/user/0/add`);
  }

  const [authModules, setAuthModules] = useState<Array<string>>(filter.filteredAuthModules);
  const debouncedFilteredAuthModules = useDebounce(authModules, DEBOUNCED_SEARCH_DELAY) as string[];

  useEffect(() => {
    setAuthModules(filter.filteredAuthModules);
  }, [filter.filteredAuthModules]);

  useEffect(() => {
    setFilter('userFilter', (f) => ({ ...f, filteredAuthModules: debouncedFilteredAuthModules }));
  }, [debouncedFilteredAuthModules, setFilter]);

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('userFilter', s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);


  const allUsersSwitch = isAdmin ? <div className={classes.barItem}>
    <div className={classes.barItemTitle}>
      {intl.formatMessage({ id: 'detail_ShowAllUsers' })}
    </div>
    <div className={classes.barItemControl}>
      <StyledSwitch checked={filter.showAllUsers || false}
        onChange={(e, checked) => {
          setFilter('userFilter', (f) => ({ ...f, showAllUsers: checked }));
          setTimeout(() => {
            setRefreshRequired(true);
          }, 100);
        }}
        name="IsShowingAllUsers" />
    </div>
  </div> : null;

  return <BarStyledContentCard>
    <BarStyledGrid>
      <StyledGridCell {...activeBreak.create}>
        <StyledActionButton
          color="primary"
          startIcon={<AddIcon />}
          disabled={!canCreate}
          onClick={addUser}
          label={intl.formatMessage({ id: 'detail_Create_a_User' })}
        />
      </StyledGridCell>

      {
        !smallMode ? <Grid item {...activeBreak.filter} className={classes.filterContainer}>
          {
            allUsersSwitch
          }
          <FormControl variant="outlined" className={classes.formControl}>
            <ChipSelector
              id='userModules'
              name="userModules"
              can={true}
              rootClass={classes.chipRoot}
              selectClass={classes.chipSelect}
              outlinedClass={classes.chipOutlined}
              label={null}
              onChange={(value) => setFilter('userFilter', (f) => ({ ...f, filteredAuthModules: value }))}
              options={allowedAuthModules}
              value={filter.filteredAuthModules || []} />
          </FormControl>
          <SearchInput
            searchTerm={searchTerm}
            rootClass={classes.searchRoot}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            onSubmitSearch={(term) => {
              setSearchTerm(term);
            }}
          />
        </Grid> : <>
          <Grid item {...activeBreak.search} className={classes.searchContainer}>
            {
              allUsersSwitch
            }
            <SearchInput
              searchTerm={searchTerm}
              rootClass={classes.searchRoot}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              onSubmitSearch={(term) => {
                setSearchTerm(term);
              }}
            />
          </Grid>
          <Grid item {...activeBreak.filter} className={classes.filterContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <ChipSelector
                id='userModules'
                name="userModules"
                can={true}
                rootClass={classes.chipRoot}
                selectClass={classes.chipSelect}
                outlinedClass={classes.chipOutlined}
                label={null}
                onChange={(value) => setFilter('userFilter', (f) => ({ ...f, filteredAuthModules: value }))}
                options={allowedAuthModules}
                value={filter.filteredAuthModules || []} />
            </FormControl>
          </Grid></>
      }
    </BarStyledGrid>

  </BarStyledContentCard>
};

export default UserManagementBar;
