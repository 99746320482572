import { withStyles } from '@material-ui/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    fontSize: theme.spacing(3)
  },
}))(MuiDialogTitle);

export { StyledDialogTitle }
