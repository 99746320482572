import { makeStyles } from "@material-ui/core";
import { myGreyDimmed, myWhiteShade0 } from "../../../constants/colors";

export const useUserRolesListTableStyles = makeStyles((theme) => ({
  roleGridContainer: {
    display: 'inline-flex',
  },
  roleGridCell: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    padding: '10px !important',
    borderTop: `1px solid ${myGreyDimmed}`,
    borderLeft: `1px solid ${myGreyDimmed}`,
    '&:nth-child(1)': {
      borderTopLeftRadius: '.55rem'
    },
    '&:nth-child(2)': {
      borderTopRightRadius: '.55rem'
    },
    '&:nth-last-child(1)': {
      borderBottomRightRadius: '.55rem'
    },
    '&:nth-last-child(2)': {
      borderBottomLeftRadius: '.55rem'
    },

    '&:nth-child(2n + 1)': {
      backgroundColor: myWhiteShade0,
      fontWeight: 700,
      fontSize: '15px'
    },

    '&:nth-child(2n)': {
      borderRight: `1px solid ${myGreyDimmed}`,
      fontSize: '14px'
    },

    '&:nth-last-child(-n + 2)': {
      borderBottom: `1px solid ${myGreyDimmed}`
    }
  }
}));
