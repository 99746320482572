import Cookies from 'universal-cookie';
import momentTimeZone from 'moment-timezone';
import { AbilityBuilder } from '@casl/ability';
import { authModules, claims } from 'vccm-common';
import ability from '../ability';
import UserHelper from '../helpers/UserHelper';
import DowntimeApi from '../api/prodDowntimeApi';
import ProductApi from '../api/prodProductApi';
import ShiftApi from '../api/prodShiftApi';
import SiteApi from '../api/prodSiteApi';
import LineApi from '../api/prodLineApi';
import { loadLinesOverviewSuccess, loadLinesSuccess } from './lineActions';
import { loadShiftsSuccess } from './shiftActions';
import { extendMoment } from 'moment-range';
import {
  CREATE_SITE_SUCCESS,
  DELETE_SITE_SUCCESS,
  LOAD_JOB_SUCCESS,
  LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS,
  LOAD_SITE_DOWNTIME_SUMMARY_FAILURE,
  LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS,
  LOAD_SITE_OEE_SUMMARY_FAILURE,
  LOAD_SITE_OEE_SUMMARY_SUCCESS,
  LOAD_SITE_PRINTERS_SUCCESS,
  LOAD_SITE_SUCCESS,
  LOAD_SITE_SUMMARY,
  MIGRATE_SITES_SUCCESS,
  RESET_SITE_DATA,
  SELECT_SITE,
  SET_SITE, SET_SITES, SET_SITE_ID, UPDATE_SITE_INITIALISATION, UPDATE_SITE_SUCCESS
} from './actionTypes';
import { Logger } from '../utilities/Logger/Logger';
import { loadProductsSuccess } from './productActions';
import JwtDecode from 'jwt-decode';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import { useMemo } from 'react';

const moment = extendMoment(momentTimeZone as any);

export function createSite(site) {
  return function (dispatch) {
    return SiteApi.createSite(site).then((site) => {
      console.log('Creating Site for self', site);
      if (site !== undefined) {
        console.log('Creating Site for self success', site);
        dispatch(createSiteSuccess(site));
        console.log('Returning site=>', site);
        return site;
      }

      throw Error(site);
    }).catch((error) => {

      console.log('siteActions.createSite', error);
      throw error;
    });
  }
}

export function createSiteSuccess(site) {
  return { type: CREATE_SITE_SUCCESS, site };
}



export function deleteSite(id) {
  return function (dispatch) {
    return SiteApi.deleteSite(id).then(
      dispatch(deleteSiteSuccess(id)),
    ).catch((error) => {
      console.log('siteActions.deleteSite error', error);
      /*      throw(error); */
    });
  }
}

export function deleteSiteSuccess(payload) {
  return { type: DELETE_SITE_SUCCESS, payload };
}

export function loadJob(jobId) {
  return (dispatch) => {
    return SiteApi.loadJob(jobId).then((jobInfo) => {
      dispatch(loadJobSuccess(jobInfo));
      return jobInfo;
    }).catch((error) => {
      console.log('siteActions.loadJob error', error);
      throw error;
    });
  }
}

export function getJob(id, request) {
  return (dispatch) => {
    return SiteApi.getJob(id, request).then((jobInfo) => {
      console.log('siteActions.loadJob jobInfo', jobInfo);
      return jobInfo;
    }).catch((error) => {
      //
      console.log('siteActions.loadJob error', error);
      throw error;
    });
  }
}

export function loadJobSuccess(jobId) {
  return { type: LOAD_JOB_SUCCESS, jobId };
}

export function loadSite(siteId, updateStore) {
  return (dispatch) => {
    return SiteApi.getSite(siteId).then((site) => {
      if (updateStore) {
        dispatch(loadSiteSuccess(site));
      }
      return site;
    }).catch((error) => {
      console.log('siteActions.loadSite error', error);
      throw (error)
    });
  }
}

export function loadSingleSite(siteId) {
  return (dispatch) => {
    return SiteApi.getSite(siteId).then((site) => {
      if (site) {
        dispatch(loadSitesSuccess([site]));
      }
      return site;
    }).catch((error) => {

      console.log('siteActions.loadSite error', error);
      /*      throw(error); */
    });
  }
}

export function migrateSites(syncType: string) {
  return (dispatch) => {
    return SiteApi.migrateSites(syncType).then((result) => {
      dispatch(migrateSitesSuccess(result));
      return result;
    }).catch((error) => {
      console.log('siteActions.migrateSites error', error);
    });
  }
}

export function loadSiteSuccess(payload) {
  return { type: LOAD_SITE_SUCCESS, payload };
}

export function migrateSitesSuccess(payload) {
  return { type: MIGRATE_SITES_SUCCESS, payload };
}

export function loadSites() {
  return (dispatch) => {
    return SiteApi.getAllSites().then((sites) => {
      dispatch(loadSitesSuccess(sites));
      return sites;
    }).catch((error) => {
      console.log('siteActions.loadSites error', error);
      throw new Error('Could not load sites');
    });
  }
}

export function loadSitesSuccess(payload) {
  return { type: SET_SITES, payload };
}

export function loadSiteDowntimeReasonsSuccess(payload) {
  return { type: LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS, payload };
}

function loadSiteDowntimeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, includeMicrostops, includePrinterDisconnected, tz = null) {
  const queryStart = moment(startDt);
  const queryEnd = moment(endDt);

  return (dispatch) => {
    const diff = queryEnd.diff(queryStart, 'd');
    if (diff > 0) // more than one day's worth of queries
    {
      const queryRanges = getRanges(queryStart, queryEnd, interval, false);
      // execute all the API calls
      return Promise.all(queryRanges.map((qr: any) => SiteApi.getSiteDowntimeSummary(qr.end, interval, lineIds, shiftIds, siteId, qr.start, includeMicrostops, includePrinterDisconnected))).then(results => {
        // concatenate all the results
        const response = results.reduce((r, agg) => {
          if (results[0] && results[0].data)
            return ({ data: (agg.data || []).concat(r.data) });
          else
            return results.flat();
        });
        dispatch(loadSiteDowntimeSummarySuccess({ siteId, shiftIds, interval, response, tz }));
      });
    }
    return SiteApi.getSiteDowntimeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, includeMicrostops, includePrinterDisconnected).then((response) => {
      dispatch(loadSiteDowntimeSummarySuccess({ siteId, shiftIds, interval, response, tz }));
    }).catch((error) => {
      console.log('siteActions.loadSiteDowntimeSummary error', error);
      dispatch(loadSiteDowntimeSummaryFailure({ siteId, error }));
    });
  }
}

export function loadSiteDowntimeSummarySuccess(payload) {
  return { type: LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS, payload };
}

export function loadSiteDowntimeSummaryFailure(payload) {
  return { type: LOAD_SITE_DOWNTIME_SUMMARY_FAILURE, payload };
}

export function loadSitePrinters(siteId) {
  return (dispatch) => {
    return SiteApi.getSitePrinters(siteId).then((printers) => {
      dispatch(loadSitePrintersSuccess({ id: siteId, printers }));
    }).catch((error) => {
      Logger.of('App.siteActions.loadSitePrinters').error('siteActions.loadSitePrinters error', error);
      /*     throw(error); */
    });
  }
}

export function loadSitePrintersSuccess(payload) {
  return { type: LOAD_SITE_PRINTERS_SUCCESS, payload };
}

export function loadSiteOeeSummarySuccess(payload) {
  return { type: LOAD_SITE_OEE_SUMMARY_SUCCESS, payload };
}

export function loadSiteOeeSummaryFailure(payload) {
  return { type: LOAD_SITE_OEE_SUMMARY_FAILURE, payload };
}

function getRanges(queryStart, queryEnd, interval, dayFormat, exclusive = false) {
  const range = moment.range(queryStart, queryEnd);
  const chunks = Array.from(range.by('day'));
  // convert the chunks into start / end pairs formatted either ISO or custom
  const isoFormat = !(interval === 'd' && (typeof dayFormat !== 'undefined'));
  const queryRanges = chunks.map(c => ({
    start: isoFormat
      ? c.utc()
        .toISOString()
      : c.clone()
        .startOf(interval)
        .format(dayFormat),
    end: isoFormat
      ? c.clone()
        .add(1, 'd')
        .subtract(1, 'ms')
        .utc()
        .toISOString()
      : c.clone()
        .add(exclusive ? 0 : 1, 'd')
        .startOf(interval)
        .format(dayFormat)
  }));
  // set last chunk to original end
  queryRanges[queryRanges.length - 1].end = isoFormat ? queryEnd.toISOString() : queryEnd.startOf(interval).format(dayFormat);
  return queryRanges;
}

export function loadSiteOeeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, intervalText = '', tz, productId, useDayFormat) {
  let raw = false;
  if (intervalText === 'Week' || intervalText === 'Month') {
    raw = true;
  }
  let queryStart: any = moment(startDt);
  let queryEnd: any = moment(endDt);
  const dayFormat = useDayFormat === false ? undefined : 'YYYY-MM-DD';

  return (dispatch) => {

    const diff = queryEnd.diff(queryStart, 'd');
    if (diff > 0) // more than one day's worth of queries
    {
      const queryRanges = getRanges(queryStart, queryEnd, interval, dayFormat, true);
      // execute all the API calls
      return Promise.all(queryRanges.map(qr => SiteApi.getSiteOeeSummary({ endDt: qr.end, interval, lineIds, shiftIds, siteId, startDt: qr.start, raw }))).then(results => {
        // concatenate all the results
        const data = results.reduce((r, agg) => ({ data: agg.data.concat(r.data) }));
        dispatch(loadSiteOeeSummarySuccess({ siteId, interval, data, shiftIds, intervalText, tz, productId }));
      });
    }

    // if it is in days, then ignore the time part (VCCM-1851)
    if (interval === 'd') {
      const dayFormat = 'YYYY-MM-DD';
      queryStart = moment(startDt).startOf(interval).format(dayFormat);
      queryEnd = moment(endDt).startOf(interval).format(dayFormat);
    }
    return SiteApi.getSiteOeeSummary({ endDt: queryEnd, interval, lineIds, shiftIds, siteId, startDt: queryStart, raw, productId }).then((data) => {
      dispatch(loadSiteOeeSummarySuccess({ siteId, interval, data, shiftIds, intervalText, tz }));
    }).catch((error) => {
      console.log('siteActions.loadSiteOeeSummary error', error);
      dispatch(loadSiteOeeSummaryFailure({ error, siteId, intervalText }));
    });
  };
}

export function loadSiteProductSummarySuccess(payload) {
  return { type: LOAD_SITE_OEE_SUMMARY_SUCCESS, payload };
}

export function loadSiteProductSummary(endDt, interval, lineIds, shiftId, siteId, startDt, intervalText = '', productId, tz) {
  const shiftIds = shiftId === '' || shiftId === 'all' ? null : [shiftId];
  return loadSiteOeeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, intervalText, tz, productId, interval === 'd');
}

export function loadSiteSummary(siteId) {
  return { type: LOAD_SITE_SUMMARY, siteId };
}

function deserializeClaimsForApiModule(authClaimsStr, authMod = authModules.VCCM_AUTH_MODULE) {
  const authClaims = JSON.parse(authClaimsStr);
  const apiAccessAbilities: any = [];
  Object.keys(authClaims).filter(siteId => authClaims[siteId].hasOwnProperty(authMod)).forEach(siteId => { // eslint-disable-line no-prototype-builtins
    const modClaims = authClaims[siteId][authMod];
    const packedString = JSON.stringify({ [siteId]: modClaims });
    const apiClaimForTheSite = claims.deserializeClaimsForModule(packedString, authMod);
    apiClaimForTheSite[siteId].forEach(apiRole => {
      apiAccessAbilities.push({ actions: apiRole, subject: `api-${siteId}` })
    });
  });

  return apiAccessAbilities;
}

export function setSiteId(siteId) {
  return (dispatch) => {
    dispatch({ type: SET_SITE_ID, payload: siteId });
  }
}
export function setSite(siteId, currentUser, siteDataOnly = false) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SITE_INITIALISATION, payload: { status: false } });
    if (!siteDataOnly) {
      dispatch({ type: SET_SITE, siteId });
    }

    const cookies = new Cookies();
    cookies.set('LastSite', siteId, { path: '/', maxAge: (365 * 24 * 60 * 60) });

    // get the abilities for the current user.
    if (currentUser) {
      return currentUser.getSession(async (err, data) => {
        if (err) {
          // Prompt the user to reauthenticate by hand...
          return null;
        } else {
          const yourAccessToken = data.idToken.jwtToken;
          const tokenDecoded: any = JwtDecode(yourAccessToken);
          Logger.of('App.siteActions.setSite').info('Token decoded during this feature', tokenDecoded);
          let userClaimObj: any;
          try {
            userClaimObj = claims.deserializeClaimsForModule(tokenDecoded.userClaims);
            Logger.of('App.siteActions.setSite').info('User claims unpacked', userClaimObj);
          } catch (e) {
            Logger.of('App.siteActions.setSite').error('Failed to parse user claims', e);
          }
          if (userClaimObj && userClaimObj[siteId] instanceof Error) {
            Logger.of('App.siteActions.setSite').error(userClaimObj[siteId]);
            userClaimObj[siteId] = [];
          }

          let apiAccessAbilities: any = [];
          try {
            apiAccessAbilities = deserializeClaimsForApiModule(tokenDecoded.authClaims, authModules.API_AUTH_MODULE);
            Logger.of('App.siteActions.setSite').info('Api claims unpacked', userClaimObj);
          } catch (e) {
            Logger.of('App.siteActions.setSite').error('Failed to parse auth claims', e);
          }

          const userVccmSites = userClaimObj && Object.entries(userClaimObj).reduce((agg: any, [key, val]) => {
            if (val !== false)
              return [...agg, key];
            return agg;
          }, []);

          const userSites = new Set(userVccmSites);
          Logger.of('App.siteActions.setSite').info('vccm auth', userSites);
          const abilitySet = new Set((userClaimObj[siteId] || []).concat(userClaimObj["*"] || []));
          const formattedAbilities = UserHelper.formatAbilities(Array.from(abilitySet));
          Logger.of('App.siteActions.setSite').info(`formatted abilities for site ${siteId}`, formattedAbilities);

          const saveSiteDetails = AbilityBuilder.define((allow, forbid) => {
            if (ability.can('edit', 'siteDef') || ability.can('edit', 'siteConfig')) {
              allow('click', 'saveSiteDetails');
            } else {
              forbid('click', 'saveSiteDetails');
            }
          });
          const siteDetails = AbilityBuilder.define((allow, forbid) => {
            if (ability.can('view', 'siteDef') || saveSiteDetails.can('click', 'saveSiteDetails')) {
              allow('click', 'siteDetails');
            } else {
              forbid('click', 'siteDetails');
            }
          });

          // generate foreign auth module abilities
          // todo verify with eric about re-structuring this bit
          const parsedAuthClaims = JSON.parse(tokenDecoded.authClaims);
          const multipleSites = userVccmSites.length > 0 || Object.entries(parsedAuthClaims).filter(([k, v]: Array<any>) => v.vrs && v.vrs !== false).length > 0;

          const sitesAvaiable = AbilityBuilder.define((allow, forbid) => {
            if (multipleSites)
              allow('view', 'sites');
            else {
              forbid('view', 'sites');
            }
          });

          Logger.of('App.siteActions.setSite').info('parsed claims', parsedAuthClaims);
          const foreignAbilities = authModules.foreign.reduce((abilities, mod) => {
            const modClaims = parsedAuthClaims && parsedAuthClaims[siteId] && parsedAuthClaims[siteId][mod];
            if (!modClaims) return abilities;
            const packedString = JSON.stringify({ [siteId]: modClaims });
            // get mod claims
            const claimsForModule = claims.deserializeClaimsForModule(packedString, claims.siteForeignModuleAuthMap[mod]);
            return [...abilities, ...(UserHelper.formatAbilities((claimsForModule && claimsForModule[siteId]) || [], mod))];
          }, []);

          Logger.of('App.siteActions.setSite').info(`foreignAbilities`, foreignAbilities);
          const abilityArray = [
            ...formattedAbilities,
            ...sitesAvaiable.rules,
            ...siteDetails.rules,
            ...saveSiteDetails.rules,
            ...apiAccessAbilities,
            ...foreignAbilities,
            ...(formattedAbilities.length > 0
              ? [{ actions: 'vccm', subject: 'authModule' }]
              : [])];
          Logger.of('App.siteActions.setSite').info(`Ability array`, abilityArray);
          ability.update(abilityArray);

          // Load data from endpoints that we have permission to access
          const promises: Array<any> = [];
          if (UserHelper.Can('view', 'schedule')) {
            promises.push(ShiftApi.getShifts(siteId).then((shifts) => {
              dispatch(loadShiftsSuccess(shifts));
            }).catch((error) => {

            }));
          }
          if (UserHelper.Can('view', 'siteDef')) {
            promises.push(SiteApi.getSitePrinters(siteId).then((printers) => {
              dispatch(loadSitePrintersSuccess({ id: siteId, printers }));
            }).catch((error) => {

            }));
          }
          if (UserHelper.Can('view', 'product')) {
            promises.push(ProductApi.getProducts(siteId).then((products) => {
              dispatch(loadProductsSuccess(products));
            }).catch((error) => {

            }));
          }
          if (UserHelper.Can('view', 'line')) {
            promises.push(LineApi.getAllLines(siteId).then((lines) => {
              dispatch(loadLinesSuccess({ id: siteId, lines }));
              if (lines && lines.length > 0) {
                return LineApi.getLinesOverview(siteId, lines.map(l => l.id), moment().toISOString()).then((response) => {
                  const results = (Array.isArray(response))
                    ? response.reduce((acc, val) => ({ ...acc, ...val }), {})
                    : response;

                  dispatch(loadLinesOverviewSuccess({ id: siteId, response: results }));
                }).catch((error) => {

                });
              }
            }).catch((error) => {

            }));
          }

          if (UserHelper.Can('view', 'downtimeReason')) {
            promises.push(DowntimeApi.getDowntimeReasons(siteId).then((downtimeCategories) => {
              dispatch(loadSiteDowntimeReasonsSuccess({ id: siteId, downtimeCategories }));
            }).catch((error) => {

            }));
          }

          return Promise.all(promises).then(results => {
            dispatch({
              type: UPDATE_SITE_INITIALISATION, payload: {
                status: true,
                selectedSiteId: siteId
              }
            });
            return results;
          });
        }
      });
    }

    return Promise.resolve(null);
  }
}

function saveSite(site, isEditMode) {
  return (dispatch) => {
    if (isEditMode) {
      return SiteApi.updateSite(site).then((site) => {
        Logger.of('Updating site').info(`site updated`, site);
        dispatch(updateSiteSuccess(site));
        return site;
      }).catch((error) => {

        Logger.of('Save site error').warn(`update site error`, error);
        throw (error);
      });
    }
    return SiteApi.saveSite(site).then((site) => {
      Logger.of('App.siteActions.saveSite').info(`site saved`, site);
      dispatch(createSiteSuccess(site));
      return site;
    }).catch((error) => {

      Logger.of('App.siteActions.saveSite').warn(`save site error`, error);
      throw (error);
    });
  }
}

function updateSiteSuccess(payload) {
  return { type: UPDATE_SITE_SUCCESS, payload };
}

function generateApiKey(site: any) {
  return (dispatch) => {
    return SiteApi.generateApiKey(site).then((updatedSite) => {
      if (updatedSite) {
        dispatch({ type: UPDATE_SITE_SUCCESS, payload: updatedSite });
        return updatedSite;
      } else {
        throw (new Error('There is error while updating the site!'));
      }

    }).catch((error) => {
      Logger.of('App.siteActions.generateApiKey').error('siteActions.generateApiKey error', error);
      throw (error);
    });
  }
}

function selectSite(siteId) {
  return (dispatch) => {
    dispatch({ type: SELECT_SITE, payload: siteId });
  }
}

function resetSiteData(siteId) {
  return (dispatch) => {
    dispatch({ type: RESET_SITE_DATA, payload: { id: siteId } });
  }
}

export const useSiteActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    resetSiteData: (siteId) => resetSiteData(siteId)(dispatch),
    createSite: (site) => createSite(site)(dispatch),
    deleteSite: (id) => deleteSite(id)(dispatch),
    loadJob: (jobId) => loadJob(jobId)(dispatch),
    getJob: (id, request) => getJob(id, request)(dispatch),
    loadSite: (siteId, updateStore = false) => loadSite(siteId, updateStore)(dispatch),
    loadSingleSite: (siteId) => loadSingleSite(siteId)(dispatch),
    migrateSites: (syncType) => migrateSites(syncType)(dispatch),
    loadSites: () => loadSites()(dispatch),
    loadSiteDowntimeSummary: (endDt, interval, lineIds, shiftIds, siteId, startDt, includeMicrostops, includePrinterDisconnected, tz = null) => loadSiteDowntimeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, includeMicrostops, includePrinterDisconnected, tz)(dispatch),
    loadSitePrinters: (siteId) => loadSitePrinters(siteId)(dispatch),
    loadSiteProductSummary: (endDt, interval, lineIds, shiftId, siteId, startDt, intervalText = '', productId, tz) => loadSiteProductSummary(endDt, interval, lineIds, shiftId, siteId, startDt, intervalText, productId, tz)(dispatch),
    loadSiteOeeSummary: (endDt, interval, lineIds, shiftIds, siteId, startDt, intervalText = '', tz, productId, useDayFormat) => loadSiteOeeSummary(endDt, interval, lineIds, shiftIds, siteId, startDt, intervalText, tz, productId, useDayFormat)(dispatch),
    saveSite: (site, isEditMode) => saveSite(site, isEditMode)(dispatch),
    setSite: (siteId, currentUser, siteDataOnly = false) => setSite(siteId, currentUser, siteDataOnly)(dispatch),
    setSiteId: (siteId) => setSiteId(siteId)(dispatch),
    selectSite: (siteId) => selectSite(siteId)(dispatch),
    generateApiKey: (site) => generateApiKey(site)(dispatch),
  }), [dispatch]);
};
