import React from "react";
import { SimpleMessage } from "../SimpleMessage/SimpleMessage";

function NotFound() {
  return (
    <div>
      <SimpleMessage title="404 - Not found" subtitle="The page you are looking for cannot be found." />
    </div>
  );
}

NotFound.displayName = 'NotFound';

export { NotFound };
