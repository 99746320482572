import React from "react";
import { Grid } from "@material-ui/core";

// components
import Widget from "../../components/Widget/Widget";
import ChartOeeByLine from "../../components/Chart/ChartOeeByLine";
import { useHistory } from "react-router";
import ChartPerformanceBarByLine from "../../components/Chart/ChartPerformanceBarByLine";
import ChartThroughputBarLines from "../../components/Chart/ChartThroughputBarLines";
import LinePerformanceOverview from "./components/LinePerformanceOverview/LinePerformanceOverview";
import LineSummary from "./components/LineSummary/LineSummary";
import { useIntl } from "react-intl";
import { SMALL_NUMBER_OF_ROWS } from '../../constants/global';
import { useDashboardStyles } from "./Dashboard.css";
import { useIsSmallSelector } from "../../context/LayoutContext/LayoutContext";

const gridBreak: any = {
  cell: { lg: 6, md: 6, sm: 12, xs: 12 },
};

const gridBreakSmall: any = {
  cell: { lg: 12, md: 12, sm: 12, xs: 12 },
};

interface IDashboardProps {
  currentLines: Array<any>;
  currentShifts: Array<any>;
  siteId: string;
}
export default function Dashboard({ currentLines, currentShifts, siteId }: IDashboardProps) {
  const history = useHistory();
  const intl = useIntl();
  const classes = useDashboardStyles();

  const smallMode = useIsSmallSelector();
  const activeBreak = smallMode ? gridBreakSmall : gridBreak;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid container className={classes.row} spacing={1}>
          <Grid item md={12} sm={12} xs={12}>
            <LineSummary
              currentLines={currentLines}
              currentShifts={currentShifts}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.row} spacing={1}>
          <Grid item {...activeBreak.cell}>
            <Widget
              title={intl.formatMessage({ id: 'detail_OEE' })}
            >
              <ChartOeeByLine
                history={history}
                lines={currentLines}
                siteId={siteId}
                showTitle={false}
                title=""
                interval="Day"
                min={0.8}
                screenHeight={500}
                screenWidth={1000}
              />
            </Widget>
          </Grid>
          <Grid item {...activeBreak.cell}>
            <Widget
              title={intl.formatMessage({ id: 'detail_Unit_Count' })}
            >
              <ChartThroughputBarLines
                history={history}
                lines={currentLines}
                siteId={siteId}
                showTitle={false}
                title=""
                interval="Day"
                min={0.8}
                screenHeight={500}
                screenWidth={1000}
              />
            </Widget>
          </Grid>
        </Grid>
        <Grid container className={classes.row} spacing={1}>
          <Grid item {...activeBreak.cell}>
            <Widget
              title={intl.formatMessage({ id: 'detail_Line_Performance' })}
            >
              <ChartPerformanceBarByLine
                history={history}
                lines={currentLines}
                siteId={siteId}
                showTitle={false}
                title=""
                interval="Day"
                min={0.8}
                screenHeight={500}
                screenWidth={1000}
              />
            </Widget>
          </Grid>

          <Grid item {...activeBreak.cell}>
            <Widget
              title={`${intl.formatMessage({ id: 'detail_Performance' })} ${intl.formatMessage({ id: 'detail_Bottom_to_Top' })}`}
              contentClassName={currentLines.length < SMALL_NUMBER_OF_ROWS ? classes.contentSmall : classes.contentLarge}
            >
              <LinePerformanceOverview
                lines={currentLines}
                showTitle
                title="OEE"
                interval="Day"
                min={0.8}
              />
            </Widget>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
