import AWS from "aws-sdk";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import sigV4Client from "./sigV4Client";
import config from "../config";
import { cognitoFields } from 'vccm-common';

import Axios, { AxiosRequestConfig } from "axios";
import { ExternalActionType } from "../enums/externalActionType";

export function callExternalApi(method: string, externalUrl: string, body: any, headers): Promise<any> {
  const axiosConfig: AxiosRequestConfig = { headers };
  const methodLower = method.toLowerCase();
  if (methodLower === ExternalActionType.get || methodLower === ExternalActionType.delete) {
    return Axios[methodLower](externalUrl, axiosConfig);
  } else {
    return Axios[methodLower](externalUrl, body, axiosConfig);
  }
}

export function invokeApig(apiParam: any): Promise<any> {
  const {
    gatewayName,
    path,
    method = "GET",
    headers = {},
    queryParams = {},
    body
  } = apiParam;

  return new Promise((resolve, reject) => {
    authUser().then(({ idToken }) => {
      if (!idToken) {
        reject(new Error("User is not logged in"));
      }

      let gatewayUrlKey = "URL";
      if (gatewayName) gatewayUrlKey = gatewayName + "_URL";

      const credentials: any = AWS.config.credentials;

      const signedRequest = sigV4Client
        .newClient({
          accessKey: credentials.accessKeyId,
          secretKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
          region: config.apiGateway.REGION,
          endpoint: config.apiGateway[gatewayUrlKey]
        })
        .signRequest({
          method,
          path,
          headers,
          queryParams,
          body
        });

      return callExternalApi(method,
        signedRequest.url,
        body !== null ? JSON.stringify(body) : null,
        { ...headers, 'Authorization': idToken }).then(results => {
          if (results.status === 204) {
            return {};
          }
          return results.data;
        });
    }).then(result => {
      resolve(result)
    }).catch(err => {
      reject(err);
    })
  });

}

export async function s3Upload(file) {
  if (!await authUser()) {
    throw new Error("User is not logged in");
  }

  const s3: any = new AWS.S3({
    params: {
      Bucket: config.s3.BUCKET
    }
  });
  const filename = `${(AWS.config.credentials as any)
    .identityId}-${Date.now()}-${file.name}`;

  return s3
    .upload({
      Key: filename,
      Body: file,
      ContentType: file.type,
      ACL: "public-read"
    })
    .promise();
}

export async function authUser(): Promise<any> {
  const currentUser = getCurrentUser();
  if (currentUser === null) {
    return false;
  }

  const idToken = await getUserToken(currentUser);

  if (
    !AWS.config.credentials ||
    Date.now() >= (AWS.config.credentials as any).expireTime - 60000
  ) {
    await getAwsCredentials(idToken);
  }

  return { currentUser, idToken };
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }

  if (AWS.config.credentials) {
    (AWS.config.credentials as any).clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({} as any);
  }
}

export function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export async function userRefreshSession(cognitoUser) {
  return new Promise((resolve, reject) => {
    if (!cognitoUser) reject(new Error('UserHelperRefresh.refreshSession: no user'));
    cognitoUser.getSession((getSessionErr, cognitoUserSession) => {
      if (getSessionErr) {
        reject(new Error(`UserHelperRefresh.refreshSession: ${JSON.stringify(getSessionErr)}`));
      }
      const refreshToken = cognitoUserSession.getRefreshToken();
      cognitoUser.refreshSession(refreshToken, (refreshSessionErr, token) => {
        if (refreshSessionErr) {
          reject(new Error(`UserHelperRefresh.refreshSession: ${JSON.stringify(getSessionErr)}`));
        }
        resolve(token);
      });
    });
  });
}

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID
  });
  return userPool.getCurrentUser();
}

export function getUserCompanyName(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) reject(err);
      else {
        const companyAttr = attributes.find(attr => attr.getName() === cognitoFields.COMPANY_NAME);
        resolve((companyAttr && companyAttr.getValue()) || 'My Site');
      }
    });
  });
}

export function getUserName(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) reject(err);
      else {
        const firstName = attributes.find(attr => attr.getName() === 'given_name');
        const lastName = attributes.find(attr => attr.getName() === 'family_name');
        resolve(`${firstName.getValue()} ${lastName.getValue()}`);
      }
    });
  });
}

export function getUserEmail(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) reject(err);
      else {
        const emailAttr = attributes.find(attr => attr.getName() === 'email');
        resolve((emailAttr && emailAttr.getValue()) || '@undefined');
      }
    });
  });
}

// after researching, I agree with the analysis here:
// https://github.com/AnomalyInnovations/serverless-stack-com/issues/113#issuecomment-346095708
// basically, multiple calls, trying to get the aws credentials can lead to overwriting the
// credentials, this will make later secrets invalid for the previous call

let loadingCredentials = null;
async function getAwsCredentials(userToken) {
  // currently have a request in progress, return that promise
  if (loadingCredentials) return loadingCredentials;
  const authenticator = `cognito-idp.${config.cognito
    .REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;

  AWS.config.update({ region: config.cognito.REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken
    }
  });

  loadingCredentials = (AWS.config.credentials as any).getPromise();
  // awaiting the promise so we can reset the loading variable after the request is completed
  await loadingCredentials;
  loadingCredentials = null;
}
