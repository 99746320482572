import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import LineListRow from '../LineListRow/LineListRow';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../constants/global';
import { useLineListTableStyles } from './LineListTable.css';
import Can from '../../../components/Common/Can';
import { LineBar } from '../LineBar/LineBar';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { useConfigActions } from '../../../actions/configActions';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

const set1Breaks = {
  name: { lg: 3, md: 4, sm: 6, xs: 12 },
  product: { lg: 2, md: 4, sm: 6, xs: 12 },
  targetRate: { lg: 2, md: 4, sm: 6, xs: 12 },
  printer: { lg: 3, md: 4, sm: 6, xs: 12 },
  actionButtons: { lg: 2, md: 4, sm: 6, xs: 12 }
};

interface ILineListState {
  page: number;
  sortedLines: Array<any>;
  pageCount: number;
  pagedLines: Array<any>;
}

interface ILineListTableProps {
  linesWithAssignedProduct: any;
  site: any;
  onDeleteLine: (line: any) => void;
}

const LineListTable = ({
  linesWithAssignedProduct,
  site,
  onDeleteLine
}: ILineListTableProps) => {
  const classes = useLineListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const gridBreak: any = set1Breaks;

  const configActions = useConfigActions();
  const { appFilters } = useAppGlobalState();

  const [filteredLines, setFilteredLines] = useState<Array<any>>([]);

  const [lineListState, setLineListState] = useState<ILineListState>({
    page: 1,
    sortedLines: [],
    pageCount: 0,
    pagedLines: []
  });

  const {
    page,
    sortedLines,
    pageCount,
    pagedLines
  } = lineListState;

  useEffect(() => {
    setLineListState(s => {
      const sorted = linesWithAssignedProduct.sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        sortedLines: sorted,
      }
    })
  }, [linesWithAssignedProduct, site]);

  useEffect(() => {
    const searchTerm = appFilters.lineFilter.searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredLines = searchTerm
      ? sortedLines.filter(line => line.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedLines;

    setFilteredLines(initialFilteredLines);

  }, [appFilters, sortedLines]);

  useEffect(() => {
    setLineListState(s => {
      const count = Math.ceil(((filteredLines && filteredLines.length) || 0) / PAGE_SIZE);
      const paged = filteredLines.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedLines: paged
      }
    })
  }, [filteredLines]);

  const onPageChange = useCallback(p => {
    setLineListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredLines.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setLineListState(s => ({
      ...s,
      pagedLines: paged
    }));
  }, [page, filteredLines]);

  return (
    <>
      <Can do="edit" on="line">
        <LineBar
          siteId={site.id}
          filter={appFilters.lineFilter}
          setFilter={configActions.setBarFilter}
        />
      </Can>
      <StyledContentCard>
        {
          site && linesWithAssignedProduct && linesWithAssignedProduct.length > 0 ? <StyledGrid>
            {
              appFilters.lineFilter.searchTerm.length === 0 || pagedLines.length > 0 ? <StyledGridHeadRow>
                <StyledGridHeadCell {...gridBreak.name} >
                  <TableHeadText
                    textId="detail_Line_Name"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell {...gridBreak.product} >
                  <TableHeadText
                    textId="detail_Product"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell {...gridBreak.targetRate} >
                  <TableHeadText
                    textId="detail_Target_Rate_s"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell {...gridBreak.printer} >
                  <TableHeadText
                    textId="detail_Printer"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
                <StyledGridHeadCell {...gridBreak.actionButtons} >
                </StyledGridHeadCell>
              </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
            }
            {
              pagedLines.map(line => (
                <LineListRow key={line.id} line={line}
                  siteId={site.id}
                  gridBreak={gridBreak}
                  onDeleteLine={onDeleteLine}
                />

              ))
            }
          </StyledGrid>
            : <EmptyItem textId="detail_noLines" />
        }
        {
          filteredLines && site && filteredLines.length > PAGE_SIZE ? <div className={classes.pageContainer}>
            <Paging
              page={page}
              onChange={onPageChange}
              count={pageCount}
              total={filteredLines.length} /></div> : null
        }
      </StyledContentCard>
    </>
  );
}

LineListTable.propTypes = {
  linesWithAssignedProduct: PropTypes.array,
  productsFormattedForDropdown: PropTypes.array,
  shownPrinters: PropTypes.object,
  site: PropTypes.object,
};

LineListTable.defaultProps = {
  shownPrinters: {},
};

export default LineListTable;
