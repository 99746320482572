import { makeStyles } from "@material-ui/core";

export const useTranslationManagementBarStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0),
    marginTop: theme.spacing(2.5),
    borderRadius: theme.spacing(1.5)
  },
  barContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  formControl: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.spacing(40),
    minWidth: theme.spacing(35),
    flexFlow: 'row',
    alignItems: 'center'
  },
  barItem: {
    margin: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  barItemTitle: {
    padding: 0
  },
  barItemControl: {
    padding: 0
  },
  textInput: {
    padding: theme.spacing(0, 0.625)
  },
  csvUploadContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
