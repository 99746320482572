import { makeStyles } from "@material-ui/styles";
import { myWhite } from "../../../../constants/colors";

export const useLineSummaryStyle =  makeStyles(() => ({
  widget: {
    backgroundColor: myWhite
  },
  title: {
    fontSize: '20px',
  },
  value: {
    fontSize: '36px',
    fontWeight: 600
  }
}));
