
export function getIdFromLink(link: string): number {
  return Number(getIdFromLinkAsString(link));
}

export function getIdFromLinkAsString(link: string): string {
  const parts = link.split('/');
  return parts.pop() || '';
}

export function truncateString(text = '', limit = 20, trail = '...'): string {
  if ((limit > 0) && (limit - trail.length > 0)) {
    return text.length > limit ? text.substring(0, limit - trail.length) + trail : text;
  } else {
    return text;
  }
}

export function upperFirstLetter(value: string): string {
  return value.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function upperFirstLetterOfEachWord(value: string, separateWitSpace = true): string {
  return value.split(' ')
    .map((word: string) => upperFirstLetter(word))
    .join(separateWitSpace ? ' ' : '');
}
