/**
 * Store time in state data and filter by shift.
 * Converted from TimeInStateShiftReport React component.
 */
class TimeInStateFilteredData {
  timeLine: any;
  occurrencesToDisplay: any;
  displayedTimeInState: any;
  constructor(timeLine, occurrencesToDisplay) {
    this.timeLine = timeLine;
    this.occurrencesToDisplay = occurrencesToDisplay;
    this.displayedTimeInState = [];
  }

  getEarliestStartingSelectedShift() {
    // find all shift occurrences on the selected date
    const occurrenceStartTimes = this.occurrencesToDisplay.map(occurrence => occurrence.start);
    // find earliest time of the group of shifts
    if (occurrenceStartTimes.length > 0) {
      const earliestStartTime = occurrenceStartTimes.reduce((earliestShift, shift) => (earliestShift < shift ? earliestShift : shift));

      return earliestStartTime.toISOString();
    }
    return null;
  }

  getLatestEndingSelectedShift() {
    // find all shift occurrences on the selected date
    const occurrencesEndTimes = this.occurrencesToDisplay.map(occurrence => occurrence.end);
    // find latest end time of the group of shifts
    if (occurrencesEndTimes.length > 0) {
      const latestEndTime = occurrencesEndTimes.reduce((latestShift, shift) => (latestShift > shift ? latestShift : shift));
      return latestEndTime.toISOString();
    }
    return null;
  }

  // if the shift event does not begin withing a displayed shift occurrences, we will not display it.
  isWithinDisplayedOccurrenceTimeFrame(shiftEvent) {
    const { occurrencesToDisplay } = this;
    const start = shiftEvent.startTime;
    const end = shiftEvent.endTime;
    for (let i = 0; i < occurrencesToDisplay.length; i++) {
      if (occurrencesToDisplay[i].shiftId === shiftEvent.id) {
        return true;
      } if (start >= occurrencesToDisplay[i].start.toISOString() && start <= occurrencesToDisplay[i].end.toISOString() && end <= occurrencesToDisplay[i].end.toISOString()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Perform filtering by shift occurrencesToDisplay from timeLine into displayedTimeInState
   */
  filterTimeInStateData() {
    // get the earliest selected shift end time
    const { timeLine, occurrencesToDisplay } = this;

    // if startTime of the event is at the the end of the shift hour, remove the shift data completely
    // and then add no scheduled shifts for any gaps that occur between shifts
    if (timeLine) {
      const filteredTimeInState = timeLine.filter((shiftEvent) => {
        return this.eventBeginsAtOrAfterLatestEndTime(shiftEvent) && this.eventEndsBeforeEarliestStartTime(shiftEvent);
      })
        .map((shiftEvent) => {
          let { endTime } = shiftEvent;
          let { startTime } = shiftEvent;
          let { type } = shiftEvent;
          let { typeId } = shiftEvent;

          // if the startTime of an event is before the earliest start time, reset it
          if (shiftEvent.startTime < this.getEarliestStartingSelectedShift()) {
            startTime = this.getEarliestStartingSelectedShift();
          }
          // if the endTime of an event is after the latest endTime, reset it
          if (shiftEvent.endTime > this.getLatestEndingSelectedShift()) {
            endTime = this.getLatestEndingSelectedShift();
          }

          // if the shift is not currently selected by the user AND it's a schedule event, change the event type to "No shift scheduled"
          // this originally relied on the shiftId but the timeframe is more accurate
          if (!this.isWithinDisplayedOccurrenceTimeFrame(shiftEvent) && shiftEvent.typeId === 1) {
            type = 'NoShiftScheduled';
            typeId = 100;
          }

          return Object.assign({}, shiftEvent, {
            endTime,
            startTime,
            type,
            typeId,
          });
        });

      this.displayedTimeInState = filteredTimeInState;

      // fill in shift gaps
      const orderedOccurrences = occurrencesToDisplay.sort((a, b) => a.end - b.end);
      for (let i = 0; i < orderedOccurrences.length - 1; i++) {
        if (orderedOccurrences[i].end < orderedOccurrences[i + 1].start) {
          const noScheduledShiftStartTime = orderedOccurrences[i].end.toISOString();
          const noScheduledShiftEndTime = orderedOccurrences[i + 1].start.toISOString();

          const gapInShifts = {
            type: 'NoShiftScheduled',
            startTime: noScheduledShiftStartTime,
            endTime: noScheduledShiftEndTime,
            typeId: 100,
            id: 1,
          };

          // ensure there are not other events within this time window
          this.displayedTimeInState = this.displayedTimeInState.map((time) => {
            let { endTime, startTime } = time;

            if (startTime > noScheduledShiftStartTime && startTime < noScheduledShiftEndTime) {
              startTime = noScheduledShiftEndTime;

              if (endTime < startTime) {
                endTime = startTime;
              }
            }

            if (endTime > noScheduledShiftStartTime && endTime < noScheduledShiftEndTime) {
              endTime = noScheduledShiftStartTime;

              // this should not happen...
              if (endTime < startTime) {
                startTime = endTime;
              }
            }

            return Object.assign({}, time, {
              endTime,
              startTime,
              // type: type,
              // typeId: typeId,
            });
          });

          // insert at the first event where the start time exceeds the new startTime
          const indexToInsertAt = this.displayedTimeInState.find(occurrence => occurrence.startTime > gapInShifts.startTime);

          if (this.displayedTimeInState.indexOf(indexToInsertAt) !== this.displayedTimeInState.length - 1) { // only need to insert the new event if there is another event displayed after it
            this.displayedTimeInState.splice(this.displayedTimeInState.indexOf(indexToInsertAt), 0, gapInShifts);
          } else {
            // if we are at the end of the events, make sure that the end times are right
            // if any items have an endTime extending the past the gap's start time (which is the end/ last thing that should be shown), reset them
            this.displayedTimeInState.forEach((timeItem) => {
              if (timeItem.endTime > gapInShifts.startTime) {
                timeItem.endTime = gapInShifts.startTime;
              }
            });
          }
        }
      }
    }
  }

  eventBeginsAtOrAfterLatestEndTime(shiftEvent) {
    return shiftEvent.startTime < this.getLatestEndingSelectedShift() || shiftEvent.startTime.split('.')[0] === this.getLatestEndingSelectedShift()
      .split('.')[0];
  }

  eventEndsBeforeEarliestStartTime(shiftEvent) {
    return shiftEvent.endTime > this.getEarliestStartingSelectedShift();
  }
}

export default TimeInStateFilteredData;
