import Moment from 'moment/moment';
import LinesApi from "../../api/prodLineApi";
import { Logger } from "../../utilities/Logger/Logger";
import Utils from '../../utilities/utils';
import * as types from '../../actions/actionTypes';
import LineHelper from '../../helpers/LineHelper/LineHelper';

const DateTimeParts = {
  YEAR: 0,
  MONTH: 1,
  DAY: 2,
  HOUR: 3,
  MINUTE: 4,
  SECOND: 5,
};


const formatString = (value: string, ...rest) => {
  let a = value;
  rest.forEach((item, index) => {
    a = a.replace(`{${index}}`, item);
  });
  return a;
}

const loadLinesOverview: (siteId, lineIds, startDateTime, dispatch: any) => Promise<any> = (siteId: string, lineIds: any, startDateTime: any, dispatch: any) => {
  return LinesApi.getLinesOverview(siteId, lineIds, startDateTime).then((response) => {
    const results = (Array.isArray(response))
      ? response.reduce((acc, val) => ({ ...acc, ...val }), {})
      : response;

    return results;
  }).catch((error) => {
    Logger.of('App.siteActions.getLinesOverview').error('siteActions.getLinesOverview error', error);
    /*     throw(error); */
  });
}

const loadTimeInState: (siteId, lineIds, threshold, dispatch) => Promise<any> = (siteId: string, lineIds: any, threshold: any, dispatch: any) => {
  return LinesApi.getLineTimeInState(siteId, lineIds, null, threshold).then((response) => {
    const payload: any = { siteId, multiline: (lineIds.length > 1), response };
    if (!payload.multiline) {
      payload.lineId = lineIds[0];
    }
    dispatch({ type: types.LOAD_LINE_TIME_IN_STATE_SUCCESS, payload });
    return payload;
  }).catch((error) => {
    throw error;
  });
}

const addTimeInStateDataToLines = (lines: Array<any>, payload: any) => {
  return lines.map((line) => {
    if (payload.response
      && ((payload.multiline && payload.response[line.id])
        || line.id === payload.lineId)) {
      const timeInState = (payload.multiline) ? payload.response[line.id] : payload.response;
      const { timeLine, slowCycles } = timeInState;

      // reverse sort and put nulls at the end

      const ts = timeLine
        .slice(0)
        .sort((a, b) => (a.endTime === null ? 0 : 1) - (b.endTime === null ? 0 : 1)
          || -(a.endTime > b.endTime) || +(a.endTime < b.endTime));
      const nowIndex = ts.findIndex(tls => tls.type === 'now');
      const nowSegmentStartTime = nowIndex >= 0 ? ts[nowIndex].startTime : null;
      const timeLineSegmentNow = nowIndex <= (ts.length - 2) ? ts[nowIndex + 1] : undefined;

      const r = Object.assign({}, line, {
        timeInState: {
          timeLine,
          nowSegmentStartTime,
          currentSegment: timeLineSegmentNow,
          slowCycles,
        },
      });
      r.status = LineHelper.getStatus(r);
      /* console.log('LOAD_LINE_TIME_IN_STATE_SUCCESS final state', r); */
      return r;
    }

    return line;
  });
}

const addOverviewDataToLines = (lines: Array<any>, linesOverview: any) => {
  const linesWithOverviewData = Object.assign([], ...lines, {
    ...lines.map(line => (line.display && line.display.color ? line : Object.assign({}, line, { display: { color: Utils.getColorFromString(line.title.split('').reverse().join('')) } }))),
  }).sort(Utils.sortByTitle);

  return linesWithOverviewData.map(line => {
    const data = linesOverview[line.id];
    if (data) {
      const thisLine = { ...line };
      const formattedHourEvents = data.oeeHours.map((item) => {
        const dateTimeParts = item.dateHour.split('-');
        return ({
          count: item.count || 0,
          plannedMinutes: item.plannedMinutes || 0,
          unplannedStoppedMinutes: item.unplannedStoppedMinutes || 0,
          runningMinutes: item.plannedMinutes + item.unplannedStoppedMinutes,
          reportedMinutes: item.reportedMinutes || 0,
          currentTargetCount: item.currentTargetCount || 0,
          badPieceCount: item.badPieceCount || 0,
          dateHour: item.dateHour,
          // change date from "2018-02-21-15" to "2018-02-21T15:00:00"
          timeStamp: Moment(formatString('{0}-{1}-{2}T{3}:00:00Z', dateTimeParts[DateTimeParts.YEAR],
            dateTimeParts[DateTimeParts.MONTH], dateTimeParts[DateTimeParts.DAY],
            dateTimeParts[DateTimeParts.HOUR])).toDate(),
          oee: item.oee || 0,
          performance: item.performance || 0,
          availability: item.availability || 0,
          quality: item.quality || 1,
          totalCounter: 0,
          ingestVersion: item.ingestVersion,
          scheduledRunMinutes: item.scheduledRunMinutes

        });
      });

      // Sort by timeStamp
      const sortedFormattedHourEvents = formattedHourEvents.sort((a, b) => a.timeStamp - b.timeStamp);
      const eventCount = sortedFormattedHourEvents.length;

      // get running minutes
      // todo: might not need total
      const total = sortedFormattedHourEvents.reduce(
        (sums, event) => {
          Object.keys(sums).forEach((key) => {
            if (event.hasOwnProperty(key) && key !== 'ingestVersion') sums[key] += event[key]; //eslint-disable-line no-prototype-builtins
          });
          sums.runningHours += event.count > 0 ? 1 : 0;
          sums.ingestVersion = sums.ingestVersion || event.ingestVersion;
          return sums;
        },
        {
          count: 0,
          runningHours: 0,
          runningMinutes: 0,
          reportedMinutes: 0,
          plannedMinutes: 0,
          currentTargetCount: 0,
          badPieceCount: 0,
        },
      );

      // Shape the Line data

      // todo: possible grab the assign product
      thisLine.assignedProductRun = data.product && data.product.run;
      thisLine.assignedProductId = data.product && data.product.id;

      // todo: rethink the next line.
      thisLine.count = total.count;
      // thisLine.count = data.oee.count;

      thisLine.currentHour = (eventCount > 1) ? sortedFormattedHourEvents.slice(-1)[0].count : total.count;
      thisLine.lastHour = (eventCount > 1) ? sortedFormattedHourEvents.slice(-2)[0].count : 0;
      thisLine.lastUpdate = eventCount > 1 ? sortedFormattedHourEvents.slice(-1)[0].timeStamp : '';
      thisLine.oeeHour = sortedFormattedHourEvents;
      // thisLine.oeeHour = data.oeeHours.map(h => Object.assign({}, h, {timeStamp: `${h.dateHour}:00:00.000Z`}));

      thisLine.printerId = data.printerId;
      thisLine.shiftAvailability = data.oee.availability * 100;
      thisLine.shiftCount = data.oee.count;
      thisLine.shiftEff = data.oee.oee;
      thisLine.shiftOee = data.oee.oee * 100;
      thisLine.shiftPerformance = data.oee.performance * 100;
      thisLine.shiftQuality = data.oee.quality * 100;

      // still using the shiftTargets endpoint to load shiftTargets graph data on LineDetailPage
      // these shiftTargets are used for the overview / general calculations
      if (!thisLine.shiftTargets) {
        thisLine.shiftTargets = {};

        thisLine.shiftTargets.produced = data.production && data.production.actual;
        thisLine.shiftTargets.planned = data.production && data.production.planned;
        thisLine.shiftTargets.remaining = data.product && data.product.currentRemaining;
        thisLine.shiftTargets.rates = {
          assignedProductRun: data.product && data.product.run,
          assignedProductId: data.product && data.product.id,
          instant: (data.production && data.production.currentRates && data.production.currentRates.instant),
          bestRate: (data.production && data.production.currentRates && data.production.currentRates.bestRate),
          runRate: (data.production && data.production.currentRates && data.production.currentRates.runRate),
        };
      }
      thisLine.targetCount = data.production && data.production.planned;

      return thisLine;
    }
    return line; // {...line, shiftEff: Math.random()};
  });
}

const loadLinesFull = async (siteId: string, threshold: any, dispatch: any) => {
  const lines = await LinesApi.getAllLines(siteId);

  if (lines && lines.length > 0) {

    const lineIds = lines.map(l => l.id);
    const linesOverview = await loadLinesOverview(siteId, lineIds, Moment().toISOString(), true);
    const timeInStateData = await loadTimeInState(siteId, lineIds, threshold, dispatch)
    const linesWithOverviewData = addOverviewDataToLines(lines, linesOverview);
    const linesWithOverviewAndTimeInStateData = addTimeInStateDataToLines(linesWithOverviewData, timeInStateData);
    return linesWithOverviewAndTimeInStateData;
  }

  return lines;
}

const loadLines = async (siteId: string, threshold: any, dispatch: any) => {
  const lines = await LinesApi.getAllLines(siteId);
  const linesWithDisplay = Object.assign([], ...lines, {
    ...lines.map(line => (line.display && line.display.color ? line : Object.assign({}, line, { display: { color: Utils.getColorFromString(line.title.split('').reverse().join('')) } }))),
  }).sort(Utils.sortByTitle);
  return linesWithDisplay;
}

export { loadLinesFull, loadLines };
