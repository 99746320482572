import { array, func, object, shape } from 'prop-types';
import React from 'react';
import toastr from 'toastr';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import ChartTimeStateBar from '../../../../components/Chart/ChartTimeStateBar';

// Modals
import RetroactiveDowntimeModal from '../../../../modals/RetroactiveDowntimeModal/RetroactiveDowntimeModal';

import LinePerformanceListRow from '../LinePerformanceListRow/LinePerformanceListRow';

import SiteHelper from '../../../../helpers/SiteHelper/SiteHelper';
import LineHelper from '../../../../helpers/LineHelper/LineHelper';
import SchedulerHelper from "../../../../helpers/SchedulerHelper/SchedulerHelper";

import Utils from '../../../../utilities/utils';
import ShiftApi from '../../../../api/prodShiftApi';
import { Logger } from '../../../../utilities/Logger/Logger';
import { Grid } from '@material-ui/core';

import { useLinePerformanceListTableStyles } from './LinePerformanceListTable.css';
import { TableHeadText } from '../../../../components/TableHeadText/TableHeadText';
import { disabledBackgroundColor, disabledColor } from '../../../../constants/colors';
import StyledContentCard from '../../../../components/Basic/StyledContentCard/StyledContentCard';
import { useIsSmallSelector } from '../../../../context/LayoutContext/LayoutContext';

const statusDisconnected = 'Disconnected';

const gridBreak: any = {
  lineNumber: { lg: 3, md: 3, sm: 12, xs: 12 },
  product: { lg: 2, md: 2, sm: 12, xs: 12 },
  printer: { lg: 2, md: 2, sm: 12, xs: 12 },
  count: { lg: 1, md: 1, sm: 12, xs: 12 },
  lastHour: { lg: 1, md: 1, sm: 12, xs: 12 },
  lastCurrentHour: { lg: 1, md: 1, sm: 12, xs: 12 },
  dashboardLink: { lg: 2, md: 2, sm: 12, xs: 12 },
};

const gridBreakSmall: any = {
  lineNumber: { lg: 3, md: 12, sm: 12, xs: 12 },
  product: { lg: 2, md: 12, sm: 12, xs: 12 },
  printer: { lg: 2, md: 12, sm: 12, xs: 12 },
  count: { lg: 1, md: 12, sm: 12, xs: 12 },
  lastHour: { lg: 1, md: 12, sm: 12, xs: 12 },
  lastCurrentHour: { lg: 1, md: 12, sm: 12, xs: 12 },
  dashboardLink: { lg: 2, md: 12, sm: 12, xs: 12 },
};

const LinePerformanceListTable = (props) => {
  const {
    linesWithAssignedProduct,
    site
  } = props;

  const classes = useLinePerformanceListTableStyles();
  const intl = useIntl();
  const smallMode = useIsSmallSelector();
  const activeBreak = smallMode ? gridBreakSmall : gridBreak;

  const [showRetroactiveDowntimeModal, setShowRetroactiveDowntimeModal] = React.useState(false);
  const [selectedShift, setSelectedShift] = React.useState<any>(null);
  const [eventToEdit, setEventToEdit] = React.useState<any>({});

  const handleClose = () => {
    setShowRetroactiveDowntimeModal(false);
    setSelectedShift(null);
    setEventToEdit(undefined);
  }

  const formatShiftEvent = (shift) => {
    return {
      title: shift.title,
      start: shift.startTime,
      end: shift.endTime,
    };
  }

  const showRetroactiveDowntimeModalFnc = async (timeInStateEvent) => {
    // must have a line ID
    if (!timeInStateEvent && !timeInStateEvent.lineId) return;

    //must be a downtime or scheduled break
    if (timeInStateEvent.type !== 'downtime' && timeInStateEvent.typeId !== 3) return;

    const shifts = await ShiftApi.getShifts(site.id);

    //attach details of the current shift
    const currentShift = formatShiftEvent(SchedulerHelper.getShiftById(shifts, timeInStateEvent.shiftId))

    // don't allow change of a disconnected printer.
    if (timeInStateEvent.status && timeInStateEvent.status === statusDisconnected) return;
    const selectedShift: any = {
      lineId: timeInStateEvent.lineId,
      instance: timeInStateEvent,
      title: currentShift.title,
      start: currentShift.start,
      end: currentShift.end,
    };

    Logger.of('App.LinePerformanceListTable.showRetroactiveDowntimeModal')
      .info('here it is', {
        showRetroactiveDowntimeModal: true,
        selectedLineId: timeInStateEvent.lineId,
        selectedShift,
      });

    setSelectedShift(selectedShift);
    setEventToEdit(timeInStateEvent);
    setShowRetroactiveDowntimeModal(true);
  }

  const updateRetroactiveDowntime = (selectedDowntimeReason, downtimeReasonNote, eventToUpdate, secondReasonId, subDowntimeReasonNote) => {
    props.downtimeActions.saveDowntimeEvent(props.site.id, {
      reasonId: selectedDowntimeReason.id,
      reasonNote: downtimeReasonNote,
      secondReasonId: secondReasonId,
      subReasonNote: subDowntimeReasonNote
    }, eventToUpdate)
      .then(() => {
        toastr.success(intl.formatMessage({ id: 'detail_DowntimeUpdated' }));
        props.refreshData();
        handleClose();
      })
      .catch((error) => {
        toastr.error(error.message);
        Logger.of('App.LinePerformanceListTable.updateRetroactiveDowntime')
          .info('saveDowntimeEvent error', error);
        handleClose();
      });
  }

  return <div className={classes.root}><StyledContentCard>
    {
      (site && selectedShift && showRetroactiveDowntimeModal) ? (
        <RetroactiveDowntimeModal
          cancel={handleClose}
          selectedShift={selectedShift.instance}
          title={selectedShift.title}
          start={selectedShift.start}
          end={selectedShift.end}
          lineId={selectedShift.lineId}
          open={showRetroactiveDowntimeModal}
          save={updateRetroactiveDowntime}
          selectedEvent={eventToEdit}
          site={site}
        />
      ) : <div />
    }
    <Grid container className={classes.container}>
      {
        site && linesWithAssignedProduct.length > 0 && <Grid container className={classes.titleContainer}>
          <Grid item {...activeBreak.lineNumber}><div className={classes.lineName}><TableHeadText textAlign={smallMode ? 'center' : 'left'} textId="detail_Line_Name" fontSize="h6.fontSize" /> </div></Grid>
          <Grid item {...activeBreak.product}><TableHeadText textId="detail_Product" fontSize="h6.fontSize" /></Grid>
          <Grid item {...activeBreak.printer}><TableHeadText textId="detail_Printer" fontSize="h6.fontSize" /></Grid>
          <Grid item {...activeBreak.count}><TableHeadText textId="detail_Count" fontSize="h6.fontSize" /></Grid>
          <Grid item {...activeBreak.lastHour}><TableHeadText textId="detail_Last_Hour" fontSize="h6.fontSize" /></Grid>
          <Grid item {...activeBreak.lastCurrentHour}><TableHeadText textId="detail_Current_Hour" fontSize="h6.fontSize" /></Grid>
          <Grid item {...activeBreak.dashboardLink}></Grid>
        </Grid>
      }
      {
        (site)
          ? (linesWithAssignedProduct.length > 0) ? linesWithAssignedProduct.sort(Utils.sortByTitle)
            // .filter((el, index) => index === 0)
            .map((line) => {
              line.status = LineHelper.getStatus(line);
              let style: any = {};
              const slowCyclesData = (line.timeInState && line.timeInState.slowCycles) || [];
              const printer = site.printers ? SiteHelper.getPrinterById(site.printers, line.printerId) : undefined;
              if (site && site.printers && site.printers.length) {
                // Todo: Rob will give me better guidance then this.
                style = printer && printer.status !== statusDisconnected ? {} : {
                  opacity: '90%',
                  backgroundColor: disabledBackgroundColor,
                  color: disabledColor
                };
              }

              return (
                <React.Fragment key={line.id}>
                  {
                    line
                    && (
                      <LinePerformanceListRow
                        line={line}
                        key={line.id}
                        printer={printer}
                        siteId={site.id}
                        siteTimezone={site.tz}
                        style={style}
                        gridBreak={activeBreak}
                      />
                    )
                  }

                  {line.timeInState
                    && (
                      <Grid container className={classes.chartContainer} style={style}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <ChartTimeStateBar
                            lineId={line.id}
                            allowComponentUpdates={!showRetroactiveDowntimeModal}
                            style={{ margin: '10px 5px' }}
                            downtimeCategories={site.downtimeCategories || []}
                            timeLine={line && line.timeInState && line.timeInState.timeLine ? line.timeInState.timeLine : []}
                            slowCycles={line.timeInState ? slowCyclesData : []}
                            timezone={site.tz}
                            eventSelected={showRetroactiveDowntimeModalFnc}
                          />
                        </Grid>
                      </Grid>
                    )
                  }

                  <Grid container className={classes.separatorContainer} style={style}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })
            : (
              <Grid container>
                <Grid item lg={12}>
                  <div className={classes.noLineRunning} style={{ textAlign: 'center' }}>
                    <b><FormattedMessage id='detail_NoLinesRunning' /></b>
                  </div>
                </Grid>
              </Grid>
            )
          : null
      }
    </Grid>
  </StyledContentCard>
  </div>
}

LinePerformanceListTable.propTypes = {
  downtimeActions: shape({
    saveDowntimeEvent: func,
  }),
  lines: array,
  linesWithAssignedProduct: array,
  lineActions: shape({
    saveLine: func,
  }),
  refreshData: func,
  productsFormattedForDropdown: array,
  shownPrinters: object,
  site: object,
  updateDowntimeEvent: func
};

LinePerformanceListTable.defaultProps = {
  lines: [],
  shownPrinters: {},
};

export default injectIntl(LinePerformanceListTable);
