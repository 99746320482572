import { makeStyles } from "@material-ui/core";

export const useSiteManagementBarStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0),
    marginTop: theme.spacing(2.5),
    borderRadius: theme.spacing(1.5)
  },
  barContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  barItem: {
    margin: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column'
  },
  barItemTitle: {
    padding: 0
  },
  barItemControl: {
    padding: 0
  },
  textInput: {
    padding: theme.spacing(0, 0.625)
  }
}));
