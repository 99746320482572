import React from 'react'
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';

interface ISitePrinterListRowProps {
  printer: any;
  gridBreak: any;
}

const SitePrinterListRow = ({ printer, gridBreak }: ISitePrinterListRowProps) => {
  return (
    <StyledGridRow>
      <StyledGridCell {...gridBreak.serial}>
        <div>{printer.printerId}</div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.name}>
        <div>{printer.title}</div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.model}>
        <div>{printer.description}</div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.status}>
        <div>{printer.status}</div>
      </StyledGridCell>
    </StyledGridRow>
  )
};

export default SitePrinterListRow;
