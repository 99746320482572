import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { claims } from 'vccm-common';
import { useProductImportDialogStyle } from './ProductImportDialog.css';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import Utils from '../../../utilities/utils';
import { useProductActions } from '../../../actions/productActions';
import ProductImportListRow from '../ProductImportListRow/ProductImportListRow';
import { LinearProgressWithLabel } from '../../../components/LinearProgressWithLabel/LinearProgressWithLabel';

const set1Breaks = {
  selected: { lg: 2, md: 2, sm: 12, xs: 12 },
  title: { lg: 2, md: 2, sm: 12, xs: 12 },
  description: { lg: 2, md: 2, sm: 12, xs: 12 },
  productId: { lg: 1, md: 1, sm: 12, xs: 12 },
  runRate: { lg: 2, md: 2, sm: 12, xs: 12 },
  bestRate: { lg: 2, md: 2, sm: 12, xs: 12 },
  multiplier: { lg: 1, md: 1, sm: 12, xs: 12 }
};

const set2Breaks = {
  selected: { lg: 1, md: 1, sm: 12, xs: 12 },
  title: { lg: 2, md: 2, sm: 12, xs: 12 },
  description: { lg: 2, md: 12, sm: 12, xs: 12 },
  runRate: { lg: 3, md: 2, sm: 12, xs: 12 },
  bestRate: { lg: 3, md: 2, sm: 12, xs: 12 },
  multiplier: { lg: 1, md: 1, sm: 12, xs: 12 }
};

const set3Breaks = {
  selected: { lg: 2, md: 2, sm: 12, xs: 12 },
  title: { lg: 5, md: 5, sm: 12, xs: 12 },
  description: { lg: 5, md: 5, sm: 12, xs: 12 },
};

const tableHeadFontSize = 'h6.fontSize';
const tableHeadAlign = 'left';

interface IProductImportDialogProps {
  title: any;
  open: boolean;
  setImportedProducts: Dispatch<SetStateAction<Array<any>>>;
  importedProducts: Array<any>;
  products: Array<any>;
  site: any;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps['maxWidth'];
}

const ProductImportDialog = ({ title,
  open,
  maxWidth,
  onClose,
  site,
  products,
  setImportedProducts,
  importedProducts }: IProductImportDialogProps) => {

  const intl = useIntl();
  const classes = useProductImportDialogStyle();
  const productActions = useProductActions();

  const [selectedAll, setSelectedAll] = useState<boolean>(true);
  const [insertCount, setInsertCount] = React.useState(0);
  const [insertTotalCount, setInsertTotalCount] = React.useState(0);
  const [saving, setSaving] = useState(false);
  const [processed, setProceessed] = useState(false);

  const toggleAllSelect = () => {
    const toggledValue = !selectedAll;
    const tempImportedProducts = Array.from(importedProducts);
    tempImportedProducts.forEach((product) => {
      product.selected = toggledValue;
    });
    setImportedProducts(tempImportedProducts);
    setSelectedAll(toggledValue);
  }

  const handleClose = () => {
    onClose(insertCount > 0);
    setInsertCount(0);
    setInsertTotalCount(0);
  };

  useEffect(() => {
    const isAllSelected = importedProducts.reduce((agg, el) => agg && el.selected, true);
    if (isAllSelected) {
      setSelectedAll(!isAllSelected);
    }
    setSelectedAll(isAllSelected);
  }, [importedProducts]);

  const calculateGridBreak = (insightUser: boolean) => {
    const isDev = Utils.isDev();
    if (isDev && insightUser) {
      return set1Breaks;
    }

    if (!isDev && insightUser) {
      return set2Breaks;
    }

    return set3Breaks;
  }

  const sleep = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  const createProducts = () => {
    let timeToExecute = 0;
    const siteId = site.id;
    setInsertTotalCount(importedProducts.filter(p => p.selected === true).length);

    //Use a temp variable here as if we use importedProducts directly, the status is not reported correctly
    let tempImportedProducts = Array.from(importedProducts);

    let itemCount = 0;
    importedProducts.filter(p => p.selected === true).forEach((product) => {
      // create 10 products per second
      timeToExecute += 100;
      sleep(timeToExecute).then(() => {
        const bestRate = product['Best Rate units/hour'];
        const runRate = product['Run Rate units/hour'];
        const productToAdd: any = {
          bestRate,
          description: product['Product Description'],
          lineIds: [],
          productIdentifier: ((product['Product Id'] && product['Product Id'].toString()) || null),
          multiplier: product.Multiplier && parseFloat(product.Multiplier),
          runRate,
          siteId,
          title: product['Product Title'] && product['Product Title'].toString(),
        };

        // if product exists set the id
        // look through existing products for a title that matches.
        const productExists = products.find(p => p.title === productToAdd.title);
        if (productExists) {
          productToAdd.id = productExists.id;
          productToAdd.createdAt = productExists.createdAt;
          productToAdd.updatedAt = productExists.updatedAt;
        }

        setSaving(true);
        productActions.saveProduct(siteId, productToAdd)
          .then(() => {
            // update imported product success
            tempImportedProducts = tempImportedProducts.map(ip =>
              (ip['Product Title'] === product['Product Title'] ? Object.assign({}, ip, { insertStatus: true }) : ip));
            setImportedProducts(tempImportedProducts);
          }).catch((error) => {
            // update imported product failure and error
            tempImportedProducts = tempImportedProducts.map(ip =>
              (ip['Product Title'] === product['Product Title'] ? Object.assign({}, ip, { insertStatus: false, insertError: `${error}` }) : ip));
            setImportedProducts(tempImportedProducts);
          }).finally(() => {
            setSaving(false);
          });
      });
      itemCount += 1;
    });

    setInsertCount(itemCount);
    setProceessed(true);
  }

  const insightUser = claims.moduleLicensed(site, claims.licensedModuleInfo.intelligence);
  const isDev = Utils.isDev();
  const gridBreak: any = calculateGridBreak(insightUser);

  const onSelected = (title, selected) => {
    setImportedProducts(products => products.map(el => el['Product Title'] === title ? ({ ...el, selected }) : el));
    const isAllSelected = importedProducts.reduce((agg, el) => agg && el['Product Title'] === title ? selected : el.selected, true);
    if (isAllSelected) {
      setSelectedAll(!isAllSelected);
    }
  }

  const extraTitleProps = {
    fontSize: tableHeadFontSize,
    textAlign: tableHeadAlign,
    extraClass: classes.title
  };

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'lg'}
    disableEscapeKeyDown
    aria-labelledby="upload-product" open={open}>
    <DialogTitle id="upload-product-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent className={classes.content} dividers>
      <StyledGrid>
        <StyledGridHeadRow>
          <StyledGridHeadCell {...gridBreak.selected}>
            <TableHeadText
              textId="detail_SelectedReplace"
              {...extraTitleProps}
            />
          </StyledGridHeadCell>
          <StyledGridHeadCell {...gridBreak.title}>
            <TableHeadText
              textId="detail_Title"
              {...extraTitleProps}
            />
          </StyledGridHeadCell>
          <StyledGridHeadCell {...gridBreak.description}>
            <TableHeadText
              textId="detail_Description"
              {...extraTitleProps}
            />
          </StyledGridHeadCell>
          {
            isDev && <StyledGridHeadCell {...gridBreak.productId}>
              <TableHeadText
                textId="detail_ProductId"
                {...extraTitleProps}
              />
            </StyledGridHeadCell>
          }
          {insightUser && <>
            <StyledGridHeadCell {...gridBreak.runRate}>
              <TableHeadText
                textId="detail_Run_Rate"
                {...extraTitleProps}
              />
            </StyledGridHeadCell>
            <StyledGridHeadCell {...gridBreak.bestRate}>
              <TableHeadText
                textId="detail_Best_Rate"
                {...extraTitleProps}
              />
            </StyledGridHeadCell>
            <StyledGridHeadCell {...gridBreak.multiplier}>
              <TableHeadText
                textId="detail_Multiplier"
                {...extraTitleProps}
              />
            </StyledGridHeadCell>
          </>
          }
        </StyledGridHeadRow>
        {
          (importedProducts && site)
            ? importedProducts.map(product => (
              <ProductImportListRow key={product.id || product['Product Title']}
                product={product}
                gridBreak={gridBreak}
                isDev={isDev}
                insightUser={insightUser}
                setSelected={onSelected}
                processed={processed}
              />
            )) : null
        }
      </StyledGrid>
      {

        importedProducts && importedProducts.filter(p => p.insertStatus !== undefined && p.selected === true).length > 0
        && (
          <div className="summary">
            <LinearProgressWithLabel
              label={`${intl.formatMessage({ id: 'detail_Completed' })} ${insertCount} / ${insertTotalCount}`}
              progressValue={insertCount}
              maxValue={insertTotalCount}
              hideProgressLabel={true} />
            <h4>
              {`${intl.formatMessage({ id: 'detail_Success' })} ${importedProducts.filter(p => p.selected === true && p.insertStatus === true).length}`}
            </h4>
            <h4>
              {`${intl.formatMessage({ id: 'detail_Failures' })} ${importedProducts.filter(p => p.selected === true && p.insertStatus === false).length}`}
            </h4>
          </div>
        )
      }
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <div className={classes.selectBtns}>
        <Button autoFocus
          onClick={toggleAllSelect}
          className="select-product-btn"
          variant="contained"
          disabled={saving || processed}
          color="primary">
          <FormattedMessage id={selectedAll ? 'detail_Unselect_All' : 'detail_Select_All'} />
        </Button>
      </div>
      <div className={classes.saveCloseBtns}>
        <Button
          onClick={createProducts}
          className={classNames(classes.createProductBtn, 'create-product-btn')}
          variant="contained"
          disabled={saving || processed}
          color="primary">
          <FormattedMessage id="detail_CreateProducts" />
        </Button>
        <Button onClick={handleClose}
          color="default"
          className="close-import-product-btn"
          disabled={saving}
          variant="contained">
          <FormattedMessage id="detail_Close" />
        </Button>
      </div>
    </DialogActions>
  </Dialog>;
}

export { ProductImportDialog };
