import { useMemo } from 'react';
import UsersApi from '../api/prodUserApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import {
  ASSIGN_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  LOAD_USERS_SUCCESS,
  SET_COGNITO_USER_LOADED,
  SET_DEFAULT_PAGE_REQUIRED,
  UPDATE_USER_SUCCESS
} from './actionTypes';

export function loadUsersSuccess(users, version = null) {
  return { type: LOAD_USERS_SUCCESS, users, version };
}

function loadUsers(companies) {
  return async (dispatch) => {
    let paginationToken = null;
    const allUsers: Array<any> = [];
    do {
      try {
        const response = await UsersApi.getAllUsers(1, paginationToken, companies);
        paginationToken = response.paginationToken; //eslint-disable-line prefer-destructuring
        if (Array.isArray(response)) {
          allUsers.push(...response);
        }
      } catch (error) {
        console.log('userActions.allUsers error', error);
        throw error;
      }
    } while (paginationToken);
    dispatch(loadUsersSuccess(allUsers));
    return allUsers;
  };
}


export function createUserSuccess(user) {
  return { type: CREATE_USER_SUCCESS, user };
}

export function updateUserSuccess(user) {
  return { type: UPDATE_USER_SUCCESS, user };
}


function saveUser(user) {
  return (dispatch) => {
    console.log('userActions.saveUser saving =>', user);
    return UsersApi.saveUser(user).then((user) => {
      dispatch(createUserSuccess(user));
      console.log('userActions.saveUser success =>', user);
      return user;
    }).catch((error) => {
      console.log('userActions.saveUser error', error);
      throw error;
    });
  };
}

export function assignUserSuccess(userId) {
  return { type: ASSIGN_USER_SUCCESS, userId };
}
function assignUser(userId, siteId, roleLabel, siteRoles) {
  return (dispatch) => {
    return UsersApi.assignUser(userId, siteId, roleLabel, siteRoles).then(
      dispatch(assignUserSuccess(userId)),
    ).catch((error) => {

      console.log('userActions.assignUser error', error);
      throw error;
    });
  };
}

export function deleteUserSuccess(id) {
  return { type: DELETE_USER_SUCCESS, id };
}

function deleteUser(siteId, id) {
  return (dispatch) => {
    return UsersApi.deleteUser(siteId, id).then(
      dispatch(deleteUserSuccess(id)),
    ).catch((error) => {

      console.log('userActions.deleteUser error', error);
      throw error;
    });
  };
}

function setPageRequired(payload) {
  return (dispatch) => {
    dispatch({ type: SET_DEFAULT_PAGE_REQUIRED, payload });
  }
}

function setCognitoUserLoaded(payload) {
  return (dispatch) => {
    dispatch({ type: SET_COGNITO_USER_LOADED, payload });
  }
}

export const useUserActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    loadUsers: (companies) => loadUsers(companies)(dispatch),
    saveUser: (user) => saveUser(user)(dispatch),
    assignUser: (userId, siteId, roleLabel, siteRoles) => assignUser(userId, siteId, roleLabel, siteRoles)(dispatch),
    deleteUser: (siteId, id) => deleteUser(siteId, id)(dispatch),
    setPageRequired: (value) => setPageRequired(value)(dispatch),
    setCognitoUserLoaded: (value) => setCognitoUserLoaded(value)(dispatch),
  }), [dispatch]);
};
