import { invokeApig } from '../libs/awsLib';

const apiUrl = '/deviceroles';

class DeviceRoleApi {
  static list(siteId) {
    return invokeApig({ gatewayName: 'ROLES', path: apiUrl, queryParams: (siteId ? { siteId } : {}) });
  }

  static create(deviceRole) {
    return invokeApig({ gatewayName: 'ROLES', method: 'POST', path: apiUrl, body: deviceRole });
  }

  static get(deviceRoleId, siteId) {
    return invokeApig({ gatewayName: 'ROLES', path: `${apiUrl}/${deviceRoleId}`, queryParams: { siteId } });
  }

  static update(deviceRole) {
    return invokeApig({ gatewayName: 'ROLES', method: 'PUT', path: `${apiUrl}/${(deviceRole && deviceRole.id)}`, body: deviceRole });
  }

  static delete(deviceRole, siteId) {
    return invokeApig({ gatewayName: 'ROLES', method: 'DELETE', path: `${apiUrl}/${(deviceRole && deviceRole.id)}`, queryParams: { siteId } });
  }
}

export default DeviceRoleApi;
