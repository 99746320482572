import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledGridHeadRow = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    padding: theme.spacing(1.25),
    margin: theme.spacing(0.625, 1.25, '!important'),
  }
}))((props) => <Grid container spacing={4} {...props}/>);

export default StyledGridHeadRow;
