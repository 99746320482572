import { makeStyles } from "@material-ui/core";
import { myWhiteShade4, myGreyDimmed2 } from "../../../constants/colors";

export const useRoleAbilityListTableStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.625, 0),
    padding: theme.spacing(1.25, 0.25),
    border: `1px solid ${myWhiteShade4}`,
    borderRadius: theme.spacing(0.625),
    backgroundColor: myGreyDimmed2
  },
  headerContainer: {
    margin: theme.spacing(1.25),
  },
  activeHeader: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
