import React from 'react';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';

import RoleAbilityListRow from '../RoleAbilityListRow/RoleAbilityListRow';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import { useRoleAbilityListTableStyles } from './RoleAbilityListTable.css';

const set1Breaks = {
  name: { lg: 5, md: 5, sm: 5, xs: 12 },
  description: { lg: 5, md: 5, sm: 5, xs: 12 },
  actionButtons: { lg: 2, md: 2, sm: 5, xs: 12 }
};

const getCategoryTitle = (category) => {
  if (!category) return null;
  switch (category.toLowerCase()) {
    // Setup
    case 'setup':
      return 'detail_Setup';
    case 'user management':
      return 'detail_UserManagement';
    case 'operation':
      return 'detail_Operation';
    case 'site management':
      return 'detail_SiteManagement';
    case 'design':
      return 'detail_Design';
    case 'report':
      return 'detail_Report';
    case 'order execution':
      return 'detail_OrderExecution';
    default:
      return category;
  }
}

interface IRoleAbilityListTableProps {
  abilityCategory: Array<any>;
  categoryName: string;
  onChange: any;
}

const RoleAbilityListTable = ({
  abilityCategory,
  categoryName,
  onChange }: IRoleAbilityListTableProps) => {

  const classes = useRoleAbilityListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';

  const gridBreak: any = set1Breaks;

  return (<div className={classes.root}>
    <div className={classes.headerContainer}>
      <TableHeadText
        textId={getCategoryTitle(categoryName)}
        fontSize='h4.fontSize'
        textAlign={tableHeadAlign}
      />
    </div>
    <StyledGrid>
      <StyledGridHeadRow>
        <StyledGridHeadCell {...gridBreak.name}>
          <TableHeadText
            textId="detail_FeatureName"
            fontSize={tableHeadFontSize}
            textAlign={tableHeadAlign}
          />
        </StyledGridHeadCell>
        <StyledGridHeadCell {...gridBreak.description}>
          <TableHeadText
            textId="detail_FeatureOverviewDescription"
            fontSize={tableHeadFontSize}
            textAlign={tableHeadAlign}
          />
        </StyledGridHeadCell>
        <StyledGridHeadCell {...gridBreak.actionButtons}>
          <div className={classes.activeHeader}>
            <TableHeadText
              textId="detail_Active"
              fontSize={tableHeadFontSize}
              textAlign="right"
            />
          </div>
        </StyledGridHeadCell>
      </StyledGridHeadRow>
      <>
        {
          abilityCategory ? abilityCategory.map(ability => (
            <RoleAbilityListRow
              key={ability.id}
              ability={ability}
              onChange={onChange}
              gridBreak={gridBreak}
            />

          )) : null
        }
      </>
    </StyledGrid>
  </div>
  );
}

export default RoleAbilityListTable;
