import { makeStyles } from "@material-ui/core";
import { requiredColor } from "./colors";

export const useGlobalStyles = makeStyles((theme) => ({
    required: {
        color: requiredColor,
        fontSize: theme.spacing(1.5)
    },
    formControl: {
      display: 'flex',
      flexFlow: 'row'
    },
}));
