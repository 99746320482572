import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { useLineOEEOverviewStyle } from './LineOEEOverview.css';
import { Card, Grid } from '@material-ui/core';
import {
  getOee,
  getOeeHoursWithinShifts,
  reduceHours
} from '../../../../../utilities/oeeCalculations';

const LineOEEOverview = ({ currentShifts, lines }) => {
  const intl = useIntl();
  const classes = useLineOEEOverviewStyle();
  const oeeHoursToDisplay = getOeeHoursWithinShifts(currentShifts, lines);

  let reducedOeeHoursToDisplay;
  if (oeeHoursToDisplay.length > 0) {
    reducedOeeHoursToDisplay = reduceHours(oeeHoursToDisplay);
  }

  const OEEValue = reducedOeeHoursToDisplay ? Math.round(getOee(reducedOeeHoursToDisplay) * 100) : 0;

  return (
    <Card className={classes.widget}>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          {intl.formatMessage({ id: 'detail_OEE' })}
        </Grid>
        <Grid item xs={4}>
          {OEEValue} %
        </Grid>
      </Grid>
    </Card>
  );
};

LineOEEOverview.propTypes = {
  lines: PropTypes.array,
  currentShifts: PropTypes.array,
};

LineOEEOverview.defaultProps = {
  lines: [],
  showTitle: false,
};

export default LineOEEOverview;
