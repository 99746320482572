import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toastr from 'toastr';
import downloadJs from 'downloadjs';
import { validation } from 'vccm-common';
import moment from 'moment-timezone';

import { useAppLineState, useAppProductState, useAppShiftState, useAppSiteState } from '../../../context/AppContext/AppContext';
import { useDowntimeReportPageStyles } from './DowntimeReportPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { CardContent, Grid } from '@material-ui/core';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Logger } from '../../../utilities/Logger/Logger';
import ReportCsvUtils from '../../../utilities/ReportCsvUtils';
import { useIntl } from 'react-intl';
import Utils from '../../../utilities/utils';
import * as ENUMS from '../../../enums/Enums';
import { useHistory } from 'react-router';
import { defaultReportFilter, getCommonFilterFromQueryString, getShiftsOccurringInRange, getTimeoutMessage, performUpdateReportFilterData } from '../../../utilities/ReportUtility/ReportUtility';
import { PDFExport } from '@progress/kendo-react-pdf';
import ChartDowntimeDonut from '../../../components/Chart/ChartDowntimeDonut';
import { DowntimeDataHelper } from '../../../helpers/downtimeDataHelper';
import ChartDowntime from '../../../components/Chart/ChartDowntime';
import { useSiteActions } from '../../../actions/siteActions';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { ReportDownload } from '../ReportDownload/ReportDownload';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import { LinearProgressWithLabel } from '../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import { ReportTypes } from '../../../enums/ReportTypes';

const DowntimeReportPage = () => {
  const classes = useDowntimeReportPageStyles();

  const lines = useAppLineState();
  const shifts = useAppShiftState();
  const products = useAppProductState();

  const siteActions = useSiteActions();

  const intl = useIntl();
  const { sites, selectedSiteId } = useAppSiteState();
  const history = useHistory();
  const site = sites.find(el => el.id === selectedSiteId);

  const [downloadProgressIndex, setDownloadProgressIndex] = useState(0);
  const [downloadProgressCount, setDownloadProgressCount] = useState(1);

  const initialiseFilter = () => {
    if (history.location.search) {
      // if there are query params in the route when it's loaded, set the filters to match before fetching data
      return {
        ...getCommonFilterFromQueryString({
          startTimeId: '',
          endTimeId: '',
        }, history.location.search, site.tz, true)
      };
    } else {
      return {
        ...defaultReportFilter,
        lineIds: [],
        reportLineDisplay: 1,
        reportShifts: [],
        downtimeElapsed: true,
      }
    }
  }

  const [filter, setFilter] = useState<any>(initialiseFilter());

  const [isFilterInitialisedFirstTime, setIsFilterInitialisedFirstTime] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [filterIndex, setFilterIndex] = useState(0);
  const [activeShifts, setActiveShifts] = useState<Array<any> | null>(null);
  const shiftsFormatted = useMemo(() => activeShifts ? activeShifts.map(shift => ({
    value: shift.id,
    text: shift.title,
  })) : [], [activeShifts]);

  const [pdfExportComponent, setPdfExportComponent] = useState<any>();

  const getNewData = async (lines) => {
    const site = sites.find(el => el.id === selectedSiteId);
    setIsLoading(true);

    if (!isInitialized) {
      setIsInitialized(true);
    }

    Logger.of('App.DowntimeReportPage.getNewData')
      .info('getNewData =>', lines);

    const lineIds = filter.lineIds && filter.lineIds.length > 0
      ? filter.lineIds
      : (lines || []).map(l => l.id);

    const shiftIds = filter.reportShifts && filter.reportShifts.length > 0
      ? filter.reportShifts
      : [];

    const newFilter = Object.assign({}, filter, {
      lineIds,
      reportShifts: shiftIds,
    });
    const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
    Logger.of('App.DowntimeReportPage.getNewData')
      .info('new Filter', newFilter);
    if (interval && validation.validateDateString(newFilter.startDt) && validation.validateDateString(newFilter.endDt)) {

      try {
        await siteActions.loadSiteDowntimeSummary(
          Utils.getFinalTimeForServer(newFilter.endDt, site.tz),
          interval.downtimeRptApi,
          newFilter.lineIds,
          newFilter.reportShifts || [],
          selectedSiteId,
          Utils.getFinalTimeForServer(newFilter.startDt, site.tz),
          false,
          false,
          site.tz,
        );
      } catch (err) {
        toastr.error(err.message);
      }

      setIsLoading(false);
      setFilter((state) => ({ ...state, isDirty: false }));
      if (!isInitialized) {
        setIsInitialized(true);
      }
    }
  }

  const downloadToCsv = async () => {
    setIsDownloading(true);
    setDownloadProgressIndex(0);
    setDownloadProgressCount(1);
    const site = sites.find(el => el.id === selectedSiteId);
    Logger.of('App.DowntimeReportPage.downloadToCsv').info('start', Date.now());
    const { filename, output } = await ReportCsvUtils.selectDowntimeReportData(filter, site, lines, shifts, products, [], setDownloadProgressIndex, setDownloadProgressCount);
    if (Array.isArray(output) && output.length > 0) {
      Logger.of('App.DowntimeReportPage.downloadToCsv').info(`data selected (${output.length})`, Date.now());
      const csv = await ReportCsvUtils.toCSV(output);
      Logger.of('App.DowntimeReportPage.downloadToCsv').info('toCSV', Date.now());
      downloadJs(csv, filename, 'text/csv');
      Logger.of('App.DowntimeReportPage.downloadToCsv').info('downloadJs', Date.now());
    } else {
      toastr.info('No data returned...');
    }
    setIsDownloading(false);
    Logger.of('App.DowntimeReportPage.downloadToCsv').info('end', Date.now());
  };

  const routeReportQueryParams = useCallback(() => {
    let dates = '';
    if (filter.timeFrameId === 'customDateRange'
      && validation.validateDateString(filter.startDt)
      && validation.validateDateString(filter.endDt)) {
      dates = `&startDt=${filter.startDt.toISOString()}&endDt=${filter.endDt.toISOString()}`;
    }
    history.push({
      search: `${`?timeFrameId=${filter.timeFrameId}&timeIntervalId=${filter.timeIntervalId}`
        + `&reportLineDisplay=${filter.reportLineDisplay}&lineIds=${filter.lineIds && filter.lineIds.length > 0 ? filter.lineIds : 'all'}`
        + `&reportShifts=${filter.reportShifts && filter.reportShifts.length > 0 ? filter.reportShifts : 'all'}`}${dates}`
        + `&downtimeElapsed=${filter.downtimeElapsed ? 'true' : 'false'}`
    });
  }, [filter, history]);

  const updateReportFilterData = (data) => {
    const { name, value } = data;
    setFilter((state) => performUpdateReportFilterData(state, name, value, site.tz, 'DowntimeReportPage'));

    if (name !== 'reportShifts') {
      setFilterIndex(s => s + 1);
    }
  }

  useEffect(() => {
    const [shiftsWithOccurrencesInRange] = getShiftsOccurringInRange(shifts, filter, site.tz)
    setActiveShifts(shiftsWithOccurrencesInRange);
  }, [filterIndex, shifts, site.tz]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    routeReportQueryParams();
  }, [filter, routeReportQueryParams]);

  const exportPDGWithComponent = async () => {
    setExporting(true);
  }

  useEffect(() => {
    if (exporting) {
      pdfExportComponent.save();
      setExporting(false);
    }
  }, [exporting, pdfExportComponent]);

  const getDownTimeChart = (downtimeData) => {
    if (!downtimeData) {
      return <div />;
    }

    const summary = filter.reportLineDisplay === 1;
    const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
    const title = intl.formatMessage({ id: 'detail_DowntimeChart' })

    return (
      <ChartDowntime
        customSeries={downtimeDataHelper(downtimeData).series(filter.downtimeElapsed)}
        requestedOneDay={getRequestedOneDay()}
        interval={interval}
        summary={summary}
        end={filter.endDt}
        start={filter.startDt}
        printing={exporting}
        downtimeElapsed={filter.downtimeElapsed}
        title={title}
        forceLabelRotation={filter.timeFrameId === 'lastWeek'} // DE-650 force rotate only the last week time frame (for now...)
      />
    );
  }

  const getRequestedOneDay = () => {
    const stDt = moment(filter.startDt);
    const enDt = moment(filter.endDt);
    // const for the number of ms in a day - 1
    const MS_PER_DAY_MINUS_ONE = 86399999;
    return enDt.diff(stDt) === MS_PER_DAY_MINUS_ONE;
  }

  const downtimeDataHelper = (data) => {
    const requestedOneDay = getRequestedOneDay();
    const summary = filter.reportLineDisplay === 1;
    const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
    const summaryTitle = intl.formatMessage({ id: 'detail_Downtime_Summary' });
    const downtimeDataArgs = {
      downtimeData: JSON.parse(JSON.stringify(data)),
      interval,
      lines,
      opacity: summary ? 0.7 : 1,
      shifts: filter.reportShifts,
      site,
      summary,
      requestedOneDay,
      summaryTitle,
    };
    Logger.of('App.DowntimeReportPage.downtimeDataHelper').info('.downtimeDataArgs =>', downtimeDataArgs);
    Logger.of('App.DowntimeReportPage.downtimeDataHelper').info('.downtimeDataArgs new filter =>', filter);
    return new DowntimeDataHelper(downtimeDataArgs);
  }

  const getDownTimeDonut = (downtimeData, intl) => {
    if (!downtimeData) {
      return <div />;
    }

    return (
      <ChartDowntimeDonut
        data={filter.isDirty ? [] : downtimeDataHelper(downtimeData).slicedDonut(false,
          filter.downtimeElapsed,
          intl)}
        showTitle
        printing={exporting}
        downtimeElapsed={filter.downtimeElapsed}
      />
    );
  }

  const chart = (
    <Grid container spacing={4} style={{ minWidth: '350px' }}>
      <Grid item md={9} sm={12} xs={12} lg={9}>
        {(() => {
          if (site && site.downtimeReport && site.downtimeReport.error) {
            return getTimeoutMessage(intl);
          }
          const data = (site && site.downtimeReport && site.downtimeReport.data) || [];
          return getDownTimeChart(filter.isDirty ? [] : data);
        }
        )()}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <div className="custom-border">
          {(() => {
            if (site && site.downtimeReport && site.downtimeReport.data) {
              const { data } = site.downtimeReport;
              return getDownTimeDonut(filter.isDirty ? [] : data, intl);
            }
            return <React.Fragment />;
          })()}
        </div>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (history.location.search && isFilterInitialisedFirstTime) {
      getNewData(lines);
    }
    setIsFilterInitialisedFirstTime(false);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const subHeader = intl.formatMessage({ id: 'detail_Downtime_Report' });
  return <>
    { sites.length > 0 && <>
      <BarStyledContentCard>
        <CardContent className={classes.content}>
          <ReportHeader
            enableShiftFilter
            filter={filter}
            getNewData={() => getNewData(lines)}
            lines={lines}
            reportType={ReportTypes.Downtime}
            saved={filter.saved}
            shifts={shiftsFormatted}
            siteId={selectedSiteId}
            title={filter.title || ''}
            isLoading={isLoading}
            updateReportFilterData={updateReportFilterData}
            subHeader={subHeader}
            enableTimeInterval={true}
          />
        </CardContent>
      </BarStyledContentCard>
      <StyledContentCard>
        <CardContent className={classes.content}>
          {
            isDownloading && <>
              <LinearProgressWithLabel
                label={intl.formatMessage({ id: 'detail_CsvData' })}
                progressValue={downloadProgressIndex}
                maxValue={downloadProgressCount} />
              <div className={classes.overlay}></div>
            </>
          }
          <ReportDownload
            downloadToCsv={() => downloadToCsv()}
            exportToPdf={() => exportPDGWithComponent()}
            isDownloading={false}
            isLoading={isLoading}
          />
          <div className={classes.pdfContainer}>
            <PDFExport
              ref={component => {
                setPdfExportComponent(component);
              }}
              margin="5mm"
              paperSize="A4"
              landscape
              scale={0.8}
            >
              {exporting ? <>
                <div>
                  <div>{filter.title || subHeader}</div>
                  <div>{(filter.title && filter.title !== subHeader) ? subHeader : undefined}</div>
                </div>
                {chart}
              </>
                : chart}

            </PDFExport>
          </div>
        </CardContent>
      </StyledContentCard>
    </>
    }
    {
      isLoading && <ProgressIndicator />
    }
  </>
};

export default DowntimeReportPage;
