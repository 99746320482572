import React from 'react';
import {
  Gradient as GradientIcon,
  FormatShapes as FormatShapesIcon,
  Toc as TocIcon,
  Tune as TuneIcon,
  ViewComfy as ViewComfyIcon,
  HomeWork as HomeWorkIcon,
  LibraryBooks as LibraryBooksIcon,
  CloudDownload as CloudDownloadIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Print as PrintIcon,
  CardGiftcard as CardGiftcardIcon,
  Build as BuildIcon,
  WatchLater as WatchLaterIcon,
  Dashboard as DashboardIcon,
  Devices as DevicesIcon,
  SupervisorAccount as SupervisorAccountIcon,
  SyncAlt as SyncAltIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Extension as ExtensionIcon
} from '@material-ui/icons';

import { getLinkAccessInfo } from './RouteAccess';
import UserHelper from './helpers/UserHelper';
import { ILinkStructure } from './interfaces/ILinkStructure';

import {
  VideojetConnectDesign,
  VideojetConnectExchange,
  VideojetConnectInsight,
  VideojetConnectInspect,
  VideojetConnectRemoteService
} from './constants/global';

export const getStructure = (site, internalCallArray, isAdmin: boolean, selectedSiteId = ''): Array<ILinkStructure> => {
  const accessInfo = getLinkAccessInfo(site, isAdmin, selectedSiteId);

  return [
    {
      id: 31,
      label: VideojetConnectRemoteService,
      internalCall: () => internalCallArray['menuAccessVrs'](),
      link: '',
      type: 'titleText',
      module: 'vrs',
      capitalise: false,
    },
    {
      id: 33,
      type: 'divider',
      single: true,
    },
    {
      id: 32,
      label: VideojetConnectExchange,
      internalCall: () => internalCallArray['menuExchange'](),
      capitalise: false,
      type: 'titleText',
      link: '',
      module: 'api'
    },
    {
      id: 34,
      type: 'divider',
      single: true,
    },
    {
      id: 0,
      type: 'title',
      label: VideojetConnectInsight,
      module: 'Intelligence',
      capitalise: false,
    },
    {
      id: 1,
      languageKey: 'detail_Performance',
      label: 'Performance',
      ...accessInfo(`/site/${selectedSiteId}/lines/overview`),
      icon: <GradientIcon />,
      module: 'Intelligence',
      activeLinkIndicators: ['/lines/overview', 'lines/performance']
    },
    {
      id: 2,
      languageKey: 'detail_Reports',
      label: 'Reports',
      ...accessInfo(`/site/${selectedSiteId}/report`),
      icon: <LibraryBooksIcon />,
      module: 'Intelligence',
      activeLinkIndicators: [`/site/${selectedSiteId}/report`]
    },

    {
      id: 5,
      type: 'divider',
      module: 'Inspect',
      hideIfModuleNotEnabled: true
    },
    {
      id: 12,
      type: 'title',
      label: VideojetConnectInspect,
      module: 'Inspect',
      hideIfModuleNotEnabled: true,
      capitalise: false,
    },
    {
      id: 14,
      label: 'Inspection',
      languageKey: 'detail_Inspection',
      ...accessInfo(`/site/${selectedSiteId}/inspections`),
      icon: <PlaylistAddCheckIcon />,
      module: 'Inspect',
      hideIfModuleNotEnabled: true,
      activeLinkIndicators: [`/site/${selectedSiteId}/inspections`]
    },
    {
      id: 16,
      label: 'Reports',
      languageKey: 'detail_Reports',
      ...accessInfo(`/site/${selectedSiteId}/inspection/report`),
      icon: <TocIcon />,
      module: 'Inspect',
      hideIfModuleNotEnabled: true,
      activeLinkIndicators: ['/inspection/report']
    },

    { id: 17, type: 'divider' },
    {
      id: 18,
      type: 'title',
      label: VideojetConnectDesign,
      capitalise: false,
      module: 'Design'
    },
    {
      id: 19,
      label: 'Design',
      disabled: !(isAdmin || !!UserHelper.Can('edit', 'design')),
      internalCall: () => internalCallArray['menuShowCirrus'](),
      languageKey: 'detail_Design',
      link: '',
      icon: <FormatShapesIcon />,
      module: 'Design'
    },
    {
      id: 20, label: 'Download',
      languageKey: 'detail_Download',
      ...accessInfo(`/site/${selectedSiteId}/Download`),
      icon: <CloudDownloadIcon />,
      module: 'Design'
    },
    { id: 29, type: 'divider' },

    {
      id: 41,
      type: 'title',
      languageKey: 'detail_Configuration',
      label: 'Configuration',
      children: [
        { id: 409, languageKey: 'detail_Sites', label: 'Sites', ...accessInfo('/sites'), icon: <HomeWorkIcon />, activeLinkIndicators: ['/sites', 'setup/site'], nonActiveLinkIndicators: ['/setup/site/0/add'] },
        { id: 411, label: 'Lines', languageKey: 'detail_Lines', ...accessInfo(`/site/${selectedSiteId}/setup/lines`), icon: <PrintIcon />, activeLinkIndicators: ['/setup/line'] },
        { id: 412, label: 'Products', languageKey: 'detail_Products', ...accessInfo(`/site/${selectedSiteId}/setup/products`), icon: <CardGiftcardIcon />, activeLinkIndicators: ['/setup/product'] },
        { id: 413, label: 'Shifts', languageKey: 'detail_Shifts', ...accessInfo(`/site/${selectedSiteId}/shifts`), icon: <BuildIcon />, activeLinkIndicators: ['/shifts', '/setup/shift/'] },
        { id: 414, label: 'Downtime', languageKey: 'detail_Downtimes', ...accessInfo(`/site/${selectedSiteId}/setup/downtimes`), icon: <WatchLaterIcon />, activeLinkIndicators: ['/setup/downtime', 'setup/subcategory'] },
        { id: 415, label: 'Display Mode', languageKey: 'detail_DisplayMode', ...accessInfo(`/site/${selectedSiteId}/setup/displayMode`), icon: <DashboardIcon />, activeLinkIndicators: ['/setup/displayMode'] },
        { id: 416, label: 'Device Role', languageKey: 'detail_DeviceRole_Setup', ...accessInfo(`/site/${selectedSiteId}/setup/deviceroles`), icon: <DevicesIcon />, activeLinkIndicators: ['/setup/deviceroles'] },
        {
          id: 420,
          label: 'Inspection Setup',
          languageKey: 'detail_InspectionSetup',
          ...accessInfo(`/site/${selectedSiteId}/setup/inspections`),
          icon: <ViewComfyIcon />,
          module: 'Inspect',
          hideIfModuleNotEnabled: true,
          activeLinkIndicators: ['/setup/inspections']
        },
        {
          id: 421,
          label: 'Inspection Settings',
          languageKey: 'detail_InspectionSettings',
          ...accessInfo(`/site/${selectedSiteId}/setup/compliance`),
          icon: <TuneIcon />,
          module: 'Inspect',
          hideIfModuleNotEnabled: true,
          activeLinkIndicators: ['/setup/compliance']
        },
        { id: 430, label: 'Users', languageKey: 'detail_Users', ...accessInfo(`/site/${selectedSiteId}/setup/users`), icon: <SupervisorAccountIcon />, activeLinkIndicators: ['/setup/users', '/setup/user/'] },
      ],
    },
    {
      id: 50,
      requireAdmin: true,
      type: 'divider'
    },
    {
      id: 51,
      type: 'title',
      requireAdmin: true,
      languageKey: 'detail_Administrator',

      label: 'Admin',
      children: [
        {
          id: 52,
          languageKey: 'detail_Create_a_Site',
          requireAdmin: true,
          label: 'Create Site',
          ...accessInfo('/setup/site/0/add'),
          icon: <HomeWorkIcon />,
          activeLinkIndicators: ['/setup/site/0/add']
        },
        {
          id: 53,
          languageKey: 'detail_AdminSiteConfiguration',
          requireAdmin: true,
          label: 'Site Configuration',
          ...accessInfo(`/site/${selectedSiteId}/admin`),
          icon: <SupervisedUserCircleIcon />,
          activeLinkIndicators: [`/site/${selectedSiteId}/admin`]
        },
        {
          id: 54,
          languageKey: 'detail_Manage_Translations',
          requireAdmin: true,
          label: 'Manage_Translations',
          ...accessInfo('/setup/translations'),
          icon: <SyncAltIcon />,
          activeLinkIndicators: ['/setup/translations']
        },
        {
          id: 55,
          languageKey: 'detail_Operations',
          requireAdmin: true,
          label: 'Operations',
          ...accessInfo('/sites/operations'),
          icon: <ExtensionIcon />,
          activeLinkIndicators: ['/sites/operations']
        },
      ],
    },
  ]
};
