
import React, { useEffect, useState } from 'react';
import jsFileDownload from 'js-file-download';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import LensIcon from '@material-ui/icons/Lens';
import { useLanguageActions } from '../../../actions/languageActions';
import { useAppGlobalState, useAppLanguageState } from '../../../context/AppContext/AppContext';
import { useTranslationManagementPageStyles } from './TranslationManagementPage.css';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import TranslationManagementBar from '../TranslationManagementBar/TranslationManagementBar';
import { convertToLanguageFile, messagesCategory, getLanguageLongStr } from '../../../utilities/loadTranslations';
import allLanguages from '../../../utilities/languages';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { smallDelay } from '../../../utilities/utils';

const languageKeys = Object.keys(messagesCategory);
const languages = languageKeys.map(key => ({ value: key, text: allLanguages[key] ? allLanguages[key] : key }))

const csvItem = (item) => item.indexOf(',') > -1 ? `"${item}"` : item;
const calculatePercent = (items: Array<any>, language: string) => {
  const emptyCount = items.filter(el => el[language] !== '').length;
  return items.length > 0 ? Math.round(emptyCount * 10000.0 / items.length)/100.0 : 0.0;
}

const columns: Array<any> = [
  { id: 'key', label: 'key', minWidth: '100px' },
  ...languageKeys.map(el => ({ id: el, label: getLanguageLongStr(el), minWidth: '100px', percent: 0 }))
];

const TranslationManagementPage = () => {
  const classes = useTranslationManagementPageStyles();
  const { isAdmin } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const { translationDataObj, translationItems, filter } = useAppLanguageState();
  const languageActions = useLanguageActions();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [filteredData, setFilteredData] = useState<{ filteredRows: Array<any>; filteredColumns: Array<any> }>({ filteredRows: translationItems, filteredColumns: columns });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchTerm = filter.searchTerm;
    let filteredRows;

    let activeTranslationItems = translationItems;

    if (filter.selectedLanguages.length !== languageKeys.length) {
      activeTranslationItems = Object.keys(translationDataObj).map(key => {
        const itemData = translationDataObj[key];
        return languageKeys.reduce((acc: any, lanKey) => filter.selectedLanguages.indexOf(lanKey) > -1 ? {
          ...acc,
          all: `${acc.all} ${itemData[lanKey]}`,
          [lanKey]: itemData[lanKey] || ''
        } : acc, { key, all: key });
      });
    }

    const filteredColumns = (filter.selectedLanguages.length !== languageKeys.length
      ? columns.filter(column => column.id === 'key' || filter.selectedLanguages.indexOf(column.id) > -1)
      : columns).map(el => ({ ...el, percent: calculatePercent(translationItems, el.id) }));

    const filterRowsbyBlankValue = filter.blankValueOnly
      ? activeTranslationItems.filter(row => filteredColumns.find(filteredColumns => row[filteredColumns.id] === ''))
      : activeTranslationItems;

    if (filter.searchingKeyOnly || !searchTerm) {
      filteredRows = searchTerm
        ? filterRowsbyBlankValue.filter(s => (s.key && s.key.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))
        : filterRowsbyBlankValue;
    } else {
      filteredRows = filterRowsbyBlankValue.filter(s => (s.all && s.all.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))
    }

    setFilteredData(s => ({ ...s, filteredRows, filteredColumns }));

  }, [filter, translationItems, translationDataObj]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    setTitle('detail_Manage_Translations', <SyncAltIcon />, {
      parents: [{
        id: 'detail_Administrator',
      }]
    });
  }, [setTitle]);

  const refreshData = (language: string, importedItems: Array<any>) => {
    if (importedItems && importedItems.length > 0) {
      languageActions.updateLanguageData(language, importedItems);
    }
  }

  const onCloseExportToCsvDialog = async (languages: Array<string>, fileName: string, emptySelected?: boolean) => {
    if (languages.length > 0) {
      for (const language of languages) {
        const filterRowsbyBlankValue = emptySelected
          ? translationItems.filter(row => row[language] === '')
          : translationItems;

        // calculate  csv file
        const csvTitle = language !== 'en' ? 'Key,English,Value\n' : 'Key,Value\n';
        const csv = csvTitle + filterRowsbyBlankValue.map(data => `${data.key}${language !== 'en' ? ',' + csvItem(data.en) : ''},${csvItem(data[language])}`).join('\n');
        const blob = new Blob(["\uFEFF" + csv], {
          type: 'text/csv; charset=utf-8'
        });
        jsFileDownload(blob, `${fileName}-${language}.csv`);
        await smallDelay(10);
      }
    }
  }

  const onCloseExportToJsonDialog = async (languages: Array<string>, fileName: string, emptySelected?: boolean) => {
    if (languages.length > 0) {
      for (const language of languages) {
        const languageJSON = convertToLanguageFile(language, translationItems.map(row => ({ key: row.key, value: row[language] })))
        const json = JSON.stringify(languageJSON, null, 2);
        const blob = new Blob(["\uFEFF" + json], {
          type: 'application/json; charset=utf-8'
        });
        jsFileDownload(blob, `${fileName}-${language}.json`);
        await smallDelay(10);
      }
    }
  }

  return <>
    { isAdmin && <TranslationManagementBar
      languages={languages}
      refreshData={refreshData}
      setLoading={setLoading}
      onCloseExportToCsvDialog={onCloseExportToCsvDialog}
      onCloseExportToJsonDialog={onCloseExportToJsonDialog}
    />
    }
    <StyledContentCard>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {filteredData.filteredColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.cell}
                >
                  {column.label} {column.id !== 'key' ? `(${column.percent}%)` : ''}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.key}>
                  {filteredData.filteredColumns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} className={classes.cell}>
                        {value ? value : <LensIcon className={classes.warning} />}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 200, 1000]}
        component="div"
        count={filteredData.filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{ root: classes.paginationRoot, toolbar: classes.paginationToolbar }}
      />
    </StyledContentCard>
    {
      loading && <ProgressIndicator />
    }
  </>
}

TranslationManagementPage.DisplayName = 'TranslationManagementPage';

export { TranslationManagementPage };
