import React from 'react';

import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import { useIntl } from 'react-intl';
import { wholeRow } from '../../../constants/global';

interface ITranslationImportListRow {
  item: any;
  gridBreak: any;
}

const TranslationImportListRow = ({ item,
  gridBreak }: ITranslationImportListRow) => {
  const intl = useIntl();

  const getProductStyle = () => {
    if (item.insertStatus !== undefined) {
      return item.insertStatus === true
        ? { backgroundColor: 'Honeydew' }
        : { backgroundColor: 'MistyRose' };
    }

    return {};
  }

  const productStyle = getProductStyle();

  return <>
    <StyledGridRow style={productStyle}>
      <StyledGridCell {...gridBreak.key} >
        {item['Key']}
      </StyledGridCell>
      <StyledGridCell {...gridBreak.value} >
        {item['Value']}
      </StyledGridCell>

    </StyledGridRow>
    {item.insertError
      && (
        <StyledGridRow style={productStyle}>
          <StyledGridCell {...wholeRow} >
            {intl.formatMessage({ id: 'detail_Error_Unable_to_complete_operation' })}
          </StyledGridCell>
        </StyledGridRow>
      )
    }
  </>
};

export default TranslationImportListRow;
