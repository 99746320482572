import React, { useState } from 'react';

import ShiftListTable from '../ShiftListTable/ShiftListTable';
import { ShiftDelete } from '../ShiftDelete/ShiftDelete';

interface IShiftSetupPageProps {
  shifts: Array<any>;
  lines: Array<any>;
  timezone: string;
  siteId: string;
  isShift: boolean;
}

const ShiftSetupPage = ({ shifts,
  lines,
  siteId,
  timezone,
  isShift }: IShiftSetupPageProps) => {

  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [toBeDeletedShift, setToBeDeletedShift] = useState<any>({ title: '' });

  const onDeleteShift = (shift) => {
    setDeleteConfirmVisible(true);
    setToBeDeletedShift(shift)
  }

  return (
    <>
      <ShiftListTable
        shifts={shifts}
        lines={lines}
        siteId={siteId}
        timezone={timezone}
        isShift={isShift}
        actionButtonHidden={false}
        onDeleteShift={onDeleteShift} />
      <ShiftDelete
        shift={toBeDeletedShift}
        isDeleteConfirmVisible={isDeleteConfirmVisible}
        setDeleteConfirmVisible={setDeleteConfirmVisible} />
    </>
  );
}

export default ShiftSetupPage;
