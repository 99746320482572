import React from "react";
import { useIntl } from "react-intl";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSearchableTimezoneSelectorStyle } from "./SearchableTimezoneSelector.css";
import { useGlobalStyles } from "../../constants/globalstyles";
interface SearchableTimezoneSelectorProps {
  fieldName: string;
  label: string;
  placeholder: string;
  onChange: (event) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  value: string;
  options: Array<any>;
  errors: any;
}

function SearchableTimezoneSelector({ fieldName,
  label,
  onChange,
  value,
  isDisabled,
  isRequired,
  placeholder,
  errors,
  options }: SearchableTimezoneSelectorProps) {
  const classes = useSearchableTimezoneSelectorStyle();
  const globalClasses = useGlobalStyles();
  const intl = useIntl();

  const removeAll = () => {
    onChange('');
  };

  const handleMouseDownDelete = (event) => {
    event.preventDefault();
  };

  return <div className={classes.mainBox}>

    <Autocomplete
      value={value}
      options={options}
      classes={{
        paper: classes.paper,
        option: classes.option,
        popperDisablePortal: classes.popperDisablePortal,
      }}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.text) {
          return option.text;
        }
        return option.title;
      }}
      getOptionSelected={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        return option.value;
      }}

      onChange={(event, option, reason) => {
        if (option) {
          onChange(option.value)
        }
      }}

      renderOption={(el) => <div key={el.value} className={classes.timeZoneItem}>
        <div className={classes.timeZoneItemPart1}>
          <div className={classes.timeZoneItemLabel}>{el.label}</div>
          <div>{el.text}</div>
        </div>
        <div>{el.description}</div>
      </div>}
      renderInput={(params) => <>
        <div className={classes.label}>
          <span>{label}</span>
          {
            isRequired && <>&nbsp; <span className={globalClasses.required}>*</span></>
          }
        </div>
        <TextField {...params}
          placeholder={placeholder}
          margin="normal"
          variant="outlined"
          classes={{
            root: classes.textRoot
          }}
          required={isRequired}
          disabled={isDisabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: <InputAdornment position="end">
              <IconButton
                onClick={removeAll}
                onMouseDown={handleMouseDownDelete}
                edge="end"
                className={classes.deleteItem}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }}
          error={!!errors[fieldName]}
          helperText={errors[fieldName] && intl.formatMessage({ id: errors[fieldName] })}
        />
      </>}
    />
  </div>
}

SearchableTimezoneSelector.displayName = 'SearchableTimezoneSelector';

export { SearchableTimezoneSelector };
