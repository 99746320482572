import React, { Context } from 'react';
import { IStorage } from '../../interfaces/Storage/IStorage';

const simpleSessionStorage: IStorage = sessionStorage as IStorage;
export const SessionStorageContext: Context<IStorage> = React.createContext(simpleSessionStorage)

export function useSessionStorage(): IStorage {
  try {
    const context = React.useContext(SessionStorageContext);
    return context;
  } catch (err) {
    throw new Error("useSessionStorage must be used within a functional component");
  }
}