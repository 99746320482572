import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import BarStyledContentCard from '../../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import { useHistory } from 'react-router';
import Can from '../../../../components/Common/Can';
import { useInspectionSetupBarStyles } from './InspectionSetupBar.css';
import { StyledActionButton } from '../../../../components/Basic/StyledActionButton/StyledActionButton';

import { DEBOUNCED_SEARCH_DELAY } from '../../../../constants/global';
import { useDebounce } from '../../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { FormControl } from '@material-ui/core';
import { SearchableMultipleSelector } from '../../../../components/SearchableMultipleSelector/SearchableMultipleSelector';
interface IInspectionSetupBarProps {
  siteId: string;
  isSmall: boolean;
  products: Array<any>;
  lines: Array<any>;
  refreshSiteData: () => void;
  filter: {
    searchTerm: string;
    lines: Array<any>;
    products: Array<any>;
  };
  setFilter: (string, any) => void;
}

const InspectionSetupBar = ({ siteId,
  isSmall,
  products,
  lines,
  filter,
  setFilter }: IInspectionSetupBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useInspectionSetupBarStyles();

  const addInspection = () => {
    history.push(`/site/${siteId}/setup/inspection/0/add`);
  }

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('inspectionSetupFilter', s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);

  return <>
    <BarStyledContentCard>
      <div className={classes.barContainer}>
        <Can do="edit" on="product">
          <div className={classes.buttonContainer}>
            <StyledActionButton
              color="primary"
              startIcon={<AddIcon />}
              onClick={addInspection}
              label={intl.formatMessage({ id: 'detail_Create_an_Inspection' })}
            />
          </div>
        </Can>

        <div className={ isSmall ? classes.filterContainerSmall : classes.filterContainer}>
          <div className={classes.barItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <SearchableMultipleSelector
                fieldName="lines"
                id="lines"
                label={intl.formatMessage({ id: "detail_Lines" })}
                placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
                options={lines}
                value={lines.filter(el => (filter.lines|| []).includes(el.value))}
                onChange={(value) => {
                  setFilter('inspectionSetupFilter', s => ({ ...s, lines: value.map(el => el.value) }));
                }}
                onReset={(value) => {
                  setFilter('inspectionSetupFilter', s => ({ ...s, lines: [] }));
                }}
              />
            </FormControl>
          </div>
          <div className={classes.barItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <SearchableMultipleSelector
                fieldName="products"
                id="products"
                label={intl.formatMessage({ id: "detail_Products" })}
                placeholder={intl.formatMessage({ id: 'detail_Select_Ellipsis' })}
                options={products}
                value={products.filter(el => (filter.products|| []).includes(el.value))}
                onChange={(value) => {
                  setFilter('inspectionSetupFilter', s => ({ ...s, products: value.map(el => el.value) }));
                }}
                onReset={(value) => {
                  setFilter('inspectionSetupFilter', s => ({ ...s, products: [] }));
                }}
              />
            </FormControl>
          </div>
          <div className={classes.barItem}>
            <SearchInput
              searchTerm={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              onSubmitSearch={(term) => {
                setSearchTerm(term);
              }}
              rootClass={classes.search}
            />
          </div>
        </div>
      </div>
    </BarStyledContentCard>
  </>
};

export { InspectionSetupBar };
