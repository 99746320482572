import { makeStyles } from "@material-ui/core";
import { mySecondaryColorDimmed, myWhiteShade3, requiredColor } from "../../constants/colors";

export const useRetroactiveDowntimeModalStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  control: {
    width: '100%',
  },
  textArea: {
    width: '100%',
    fontSize: theme.spacing(2),
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    padding: theme.spacing(1.25)
  },
  controlItem: {
    minWidth: theme.spacing(10),
    padding: theme.spacing(1.25)
  },
  divider: {
    margin: theme.spacing(2.5, 0)
  },
  label: {
    fontWeight: 600
  },
  shift: {
    fontWeight: 600
  },
  shiftContainer: {
    borderRadius: theme.spacing(1.25),
    border: `1px solid ${mySecondaryColorDimmed}`,
    padding: theme.spacing(2.5),
    backgroundColor: myWhiteShade3
  },
  shiftTitle: {
    fontSize: 'small'
  },
  shiftDate: {
    marginTop: theme.spacing(2.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  row: {
    alignItems: 'center'
  },
  required: {
    color: requiredColor,
    fontSize: theme.spacing(1.5)
  },
}));
