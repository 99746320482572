import { useMemo } from 'react';
import DeviceRoleApi from '../api/prodDeviceRoleApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import { Logger } from '../utilities/Logger/Logger';
import * as types from './actionTypes';

function loadDeviceRolesSuccess(payload) {
  return { type: types.LOAD_DEVICE_ROLE_SUCCESS, payload };
}

const loadDeviceRoles = (siteId) => {
  return (dispatch) => {
    return DeviceRoleApi.list(siteId).then((deviceRoles) => {
      dispatch(loadDeviceRolesSuccess(deviceRoles));
      return deviceRoles;
    }).catch((error) => {
      Logger.of('App.siteActions.loadDeviceRoles').error('siteActions.loadDeviceRoles error', error);
      /*     throw(error); */
    });
  }
}

export const useDeviceRoleActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    loadDeviceRoles: (siteId) => loadDeviceRoles(siteId)(dispatch)
  }), [dispatch]);
};
