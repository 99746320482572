import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useLayoutState } from '../../context/LayoutContext/LayoutContext';
import { useProgressIndicatorStyles } from './ProgressIndicator.css';

const ProgressIndicator = ({ full }: { full?: boolean }) => {
  const classes = useProgressIndicatorStyles();
  const { isSidebarOpened } = useLayoutState();
  return <>
    <div className={full ? classes.progress : isSidebarOpened ? classes.plzWaitWithDrawer : classes.plzWait}>
      <div className={classes.spinner}>
        <div className={classes.placeholder}>
          <CircularProgress />
        </div>
      </div>
    </div>
    {
      !full && <div className={classes.overlay}></div>
    }
  </>
}

export { ProgressIndicator }
