import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';

import { Logger } from '../../../utilities/Logger/Logger';
import { loadLines } from '../../../services/LineService/LineService';
import { useAppGlobalDispatch, useAppGlobalState } from '../../../context/AppContext/AppContext';
import LineListTable from '../LineListTable/LineListTable';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { Print as PrintIcon } from "@material-ui/icons";
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useProductActions } from '../../../actions/productActions';
import { LineDelete } from '../LineDelete/LineDelete';

const LineSetupPage = (props) => {
  const dispatch = useAppGlobalDispatch();
  const setTitle = useSetTitleAction();
  const { sites } = useAppGlobalState();
  const productActions = useProductActions();

  const [isLoading, setIsLoading] = useState(true);

  const [linesWithAssignedProduct, setLinesWithAssignedProduct] = useState<Array<any>>([]);
  const [productsFormattedForDropdown, setProductsFormattedForDropdown] = useState<any>([]);

  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [toBeDeletedLine, setToBeDeletedLine] = useState<any>({ title: '' });

  const generateLocalLines = (lines: any, products: any) => {
    const localLines = [...lines];
    products.forEach((product) => {
      for (let i = 0; localLines && i < localLines.length; i++) {
        if (localLines[i].assignedProductId === product.id) {
          localLines[i] = Object.assign({}, localLines[i], { product });
        }
      }
    });
    return localLines;
  }

  const generateProductsFormattedForDropdown = (products: any) => {
    return products.map(product => ({
      value: product.id,
      text: product.title,
    }));
  }

  const refreshSiteData = async () => {
    try {
      Logger.of('App.LinesPage.refreshSiteData').info('Refreshing site data');
      const siteId: string = props.match.params.siteId;
      const currentSite = sites.find(el => el.id === siteId);
      if (currentSite && currentSite.printers) {
        setIsLoading(() => true);
        const [lines, products] = await Promise.all(
          [loadLines(siteId, sites.find(el => el.id === siteId).slowCycleThreshold, dispatch),
          productActions.loadProducts(siteId)
          ]);

        const localLines = generateLocalLines(lines, products);
        setLinesWithAssignedProduct(localLines);
        setProductsFormattedForDropdown(generateProductsFormattedForDropdown(products));
      }
      setIsLoading(() => false);
    } catch (error) {
      Logger.of('App.LinesPage.refreshSiteData').info('Error Refreshing site data', error);
    }
  }

  const onDeleteLine = (line) => {
    setDeleteConfirmVisible(true);
    setToBeDeletedLine(line)
  }

  const onLineDeleted = () => {
    setDeleteConfirmVisible(false);
    refreshSiteData();
  }

  useEffect(() => {
    if (sites.length > 0) {
      refreshSiteData();
    }
  }, [sites]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_Lines', <PrintIcon />, {
      parents: [{
        id: 'detail_Configuration',
      }]
    });
  }, [setTitle]);

  const siteId: string = props.match.params.siteId;
  const currentSite = sites.find(el => el.id === siteId);

  return !isLoading ? <>
      <LineListTable
        site={currentSite}
        linesWithAssignedProduct={linesWithAssignedProduct}
        productsFormattedForDropdown={productsFormattedForDropdown}
        onDeleteLine={onDeleteLine}
      />
    <LineDelete
      line={toBeDeletedLine}
      isDeleteConfirmVisible={isDeleteConfirmVisible}
      setDeleteConfirmVisible={setDeleteConfirmVisible}
      onDeleted={onLineDeleted} />
  </> :
    <ProgressIndicator />
}

LineSetupPage.propTypes = {
  history: object,
  match: object,
};

export default LineSetupPage;
