import React, { useCallback, useEffect, useState } from 'react';
import ReportManagerListRow from '../ReportManagerListRow/ReportManagerListRow';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import { Typography } from '@material-ui/core';
import { useReportManagerListTableStyles } from './ReportManagerListTable.css';
import { Paging } from '../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../constants/global';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import { useConfigActions } from '../../../actions/configActions';

interface IReportListState {
  page: number;
  sortedReports: Array<any>;
  pageCount: number;
  pagedReports: Array<any>;
}
interface IReportManagerListTableProps {
  header: string;
  editContext: string;
  editAction: string;
  displayedReports: any;
  onReportChange: (newReports: any) => void;
  schedules: Array<any>;
  userId: string;
  isLocal: boolean;
}

const set1Breaks = {
  title: { lg: 3, md: 3, sm: 12, xs: 12 },
  type: { lg: 2, md: 2, sm: 12, xs: 12 },
  timeFrame: { lg: 2, md: 2, sm: 12, xs: 12 },
  distributedBy: { lg: 3, md: 3, sm: 12, xs: 12 },
  actionButtons: { lg: 2, md: 2, sm: 12, xs: 12 }
};

const ReportManagerListTable = ({
  header,
  editContext,
  editAction,
  displayedReports,
  schedules,
  onReportChange,
  userId,
  isLocal
}: IReportManagerListTableProps) => {

  const classes = useReportManagerListTableStyles();
  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const { sites, selectedSiteId, appFilters } = useAppGlobalState();
  const filterName = isLocal ? 'localReportFilter': 'globalReportFilter';
  const gridBreak: any = set1Breaks;

  const configActions = useConfigActions();

  const [filteredDisplayedReports, setFilteredDisplayedReports] = useState<Array<any>>([]);

  const [searchTerm, setSearchTerm] = useState<string>(appFilters[filterName].searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    configActions.setBarFilter(filterName, s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, configActions, filterName]);


  const [reportListState, setReportListState] = useState<IReportListState>({
    page: 1,
    sortedReports: [],
    pageCount: 0,
    pagedReports: []
  });

  const {
    page,
    sortedReports,
    pageCount,
    pagedReports
  } = reportListState;


  useEffect(() => {
    setReportListState(s => {
      const sorted = displayedReports.sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        sortedReports: sorted,
      }
    })
  }, [displayedReports]);

  useEffect(() => {
    const searchTerm = appFilters[filterName].searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredReports = searchTerm
      ? sortedReports.filter(report => report.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedReports;

    setFilteredDisplayedReports(initialFilteredReports);

  }, [appFilters, sortedReports, filterName]);

  useEffect(() => {
    setReportListState(s => {
      const count = Math.ceil(((filteredDisplayedReports && filteredDisplayedReports.length) || 0) / PAGE_SIZE);
      const paged = filteredDisplayedReports.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedReports: paged
      }
    })
  }, [filteredDisplayedReports]);

  useEffect(() => {
    const paged = filteredDisplayedReports.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setReportListState(s => ({
      ...s,
      pagedReports: paged
    }));
  }, [page, filteredDisplayedReports]);

  const onPageChange = useCallback(p => {
    setReportListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  return (<>
    <StyledContentCard>
      <div className={classes.bar}>
        <div className={classes.title}>
          <Typography variant="h4">{header}</Typography>
        </div>
        <div className={classes.searchContainer}>
          <SearchInput
            searchTerm={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            onSubmitSearch={(term) => {
              setSearchTerm(term);
            }}
          />
        </div>
      </div>
      <StyledGrid>
        {
          appFilters[filterName].searchTerm.length === 0 || pagedReports.length > 0 ? <StyledGridHeadRow>
            <StyledGridHeadCell {...gridBreak.title}>
              <TableHeadText
                textId="detail_Title"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>
            <StyledGridHeadCell {...gridBreak.type}>
              <TableHeadText
                textId="detail_Type"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>

            <StyledGridHeadCell {...gridBreak.timeFrame}>
              <TableHeadText
                textId="detail_TimeFrame"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>

            <StyledGridHeadCell {...gridBreak.distributedBy}>
              {editContext === 'global-report' && <TableHeadText
                textId="detail_DistributedBy"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
              }
            </StyledGridHeadCell>

            <StyledGridHeadCell {...gridBreak.actionButtons}>
              <TableHeadText
                textId="detail_Actions"
                fontSize={tableHeadFontSize}
                textAlign={tableHeadAlign}
              />
            </StyledGridHeadCell>
          </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
        }
        <>
          {
            pagedReports && pagedReports.length > 0 && pagedReports.map((report, index) => {
              return <ReportManagerListRow
                key={report.title + index}
                userId={userId}
                onReportChange={onReportChange}
                report={report}
                editContext={editContext}
                editAction={editAction}
                schedules={schedules}
                site={sites.find(el => el.id === selectedSiteId)}
                gridBreak={gridBreak}
              />
            })
          }
        </>
      </StyledGrid>
      {
        filteredDisplayedReports && filteredDisplayedReports.length > PAGE_SIZE ? <div className={classes.pageContainer}>
          <Paging
            page={page}
            onChange={onPageChange}
            count={pageCount}
            total={filteredDisplayedReports.length} /></div> : null
      }
    </StyledContentCard>
  </>
  );
}

export default ReportManagerListTable;
