import * as PropTypes from 'prop-types';
import React from 'react';

const AddressLabel = (props) => {
  const { address } = props;

  if (address) {
    return (
      <React.Fragment>
        {address}
      </React.Fragment>
    )
  }
  return <React.Fragment />;
};

AddressLabel.propTypes = {
  address: PropTypes.string
};

export default AddressLabel;
