import arLan from "./translations/ar.json";
import bgLan from "./translations/bg.json";
import csLan from "./translations/cs.json";
import daLan from "./translations/da.json";
import deLan from "./translations/de.json";
import elLan from "./translations/el.json";
import enLan from "./translations/en.json";
import esLan from "./translations/es.json";
import fiLan from "./translations/fi.json";
import frLan from "./translations/fr.json";
import itLan from "./translations/it.json";
import jaLan from "./translations/ja.json";
import koLan from "./translations/ko.json";
import huLan from "./translations/hu.json";
import nlLan from "./translations/nl.json";
import noLan from "./translations/no.json";
import plLan from "./translations/pl.json";
import ptLan from "./translations/pt.json";
import roLan from "./translations/ro.json";
import ruLan from "./translations/ru.json";
import srLan from "./translations/sr.json";
import svLan from "./translations/sv.json";
import thLan from "./translations/th.json";
import trLan from "./translations/tr.json";
import viLan from "./translations/vi.json";
import zhLan from "./translations/zh.json";

export const convertLanguageFile = (languageData: any) => {
  const newLanguageData: any = {};
  const newLanguageDataCategory: any = {};
  Object.keys(languageData).forEach(keyCategory => {
    const category = languageData[keyCategory];
    Object.keys(category).forEach(key => {
      if (typeof category[key] === 'string') {
        newLanguageData[`${keyCategory}_${key}`] = category[key];
        newLanguageDataCategory[`${keyCategory}/${key}`] = category[key];
      } else {
        Object.keys(category[key]).forEach(innerKey => {
          newLanguageData[`${keyCategory}_${key}_${innerKey}`] = category[key][innerKey];
          newLanguageDataCategory[`${keyCategory}/${key}/${innerKey}`] = category[key][innerKey];
        });
      }
    });
  });

  return [newLanguageData, newLanguageDataCategory];
}


export const getLanguageLongStr = (lan) => {
  if (lan === 'en') {
    return 'en-US';
  }

  return `${lan}-${lan.toUpperCase()}`;
}

export const convertToLanguageFile = (language: string, languageFlatData: any) => {
  const hierarchicalData: any = {};
  languageFlatData.forEach(row => {
    const hierarchyKeys = row.key.split('/');
    let currentHierarchy;
    hierarchyKeys.forEach((key, index) => {
      if (index === 0) {
        currentHierarchy = hierarchicalData;
      }

      if (index < hierarchyKeys.length - 1) {
        currentHierarchy[key] = currentHierarchy[key] || {};
        currentHierarchy = currentHierarchy[key];
      } else {
        const key = hierarchyKeys[index];
        currentHierarchy[key] = row.value;
      }
    });
  })

  return { [getLanguageLongStr(language)]: hierarchicalData };
}

const [en, enCategory] = convertLanguageFile(enLan["en-US"]);
const [fr, frCategory] = convertLanguageFile(frLan["fr-FR"]);
const [ar, arCategory] = convertLanguageFile(arLan["ar-AR"]);
const [bg, bgCategory] = convertLanguageFile(bgLan["bg-BG"]);
const [cs, csCategory] = convertLanguageFile(csLan["cs-CS"]);
const [da, daCategory] = convertLanguageFile(daLan["da-DA"]);
const [de, deCategory] = convertLanguageFile(deLan["de-DE"]);
const [es, esCategory] = convertLanguageFile(esLan["es-ES"]);
const [el, elCategory] = convertLanguageFile(elLan["el-EL"]);
const [fi, fiCategory] = convertLanguageFile(fiLan["fi-FI"]);
const [it, itCategory] = convertLanguageFile(itLan["it-IT"]);
const [ja, jaCategory] = convertLanguageFile(jaLan["ja-JA"]);
const [ko, koCategory] = convertLanguageFile(koLan["ko-KO"]);
const [hu, huCategory] = convertLanguageFile(huLan["hu-HU"]);
const [nl, nlCategory] = convertLanguageFile(nlLan["nl-NL"]);
const [no, noCategory] = convertLanguageFile(noLan["no-NO"]);
const [pl, plCategory] = convertLanguageFile(plLan["pl-PL"]);
const [pt, ptCategory] = convertLanguageFile(ptLan["pt-PT"]);
const [ro, roCategory] = convertLanguageFile(roLan["ro-RO"]);
const [ru, ruCategory] = convertLanguageFile(ruLan["ru-RU"]);
const [sr, srCategory] = convertLanguageFile(srLan["sr-SR"]);
const [sv, svCategory] = convertLanguageFile(svLan["sv-SV"]);
const [th, thCategory] = convertLanguageFile(thLan["th-TH"]);
const [tr, trCategory] = convertLanguageFile(trLan["tr-TR"]);
const [vi, viCategory] = convertLanguageFile(viLan["vi-VI"]);
const [zh, zhCategory] = convertLanguageFile(zhLan["zh-ZH"]);

export const messages = { en, fr, ar, bg, cs, da, de, es, el, fi, it, ja, ko, hu, nl, no, pl, pt, ro, ru, sr, sv, th, tr, vi, zh };
export const messagesCategory = {
  en: enCategory,
  fr: frCategory,
  ar: arCategory,
  bg: bgCategory,
  cs: csCategory,
  da: daCategory,
  de: deCategory,
  es: esCategory,
  el: elCategory,
  fi: fiCategory,
  it: itCategory,
  ja: jaCategory,
  ko: koCategory,
  hu: huCategory,
  nl: nlCategory,
  no: noCategory,
  pl: plCategory,
  pt: ptCategory,
  ro: roCategory,
  ru: ruCategory,
  sr: srCategory,
  sv: svCategory,
  th: thCategory,
  tr: trCategory,
  vi: viCategory,
  zh: zhCategory
};


