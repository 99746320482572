import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    borderRadius: '.75rem',
    borderWidth: '1px',
    borderColor: 'rgba(7,9,25,.125)',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxShadow: '0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03)'
  },
  header: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(7,9,25,.125)',
    borderBottomStyle: 'solid',
    flexGrow: 0,
  },
  headerTitle: {
    fontSize: theme.spacing(2.75),
    fontWeight: 400
  }
}));
