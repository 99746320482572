export const getAbilityTitle = (ability) => {
  if (!ability) return null;
  switch (ability.toLowerCase()) {
    // Setup
    case 'view-schedule':
      return 'detail_ViewShift';
    case 'edit-schedule':
      return 'detail_EditShift';
    case 'view-product':
      return 'detail_ViewProducts';
    case 'edit-product':
      return 'detail_EditProduct';
    case 'view-line':
      return 'detail_ViewLines';
    case 'edit-line':
      return 'detail_EditLine';
    case 'view-downtimereason':
      return 'detail_ViewDowntimeReasons';
    case 'edit-downtimereason':
      return 'detail_EditDowntimeReason';
    case 'view-devicerole':
      return 'detail_ViewDeviceRole';
    case 'edit-devicerole':
      return 'detail_EditDeviceRole';
    // User Management
    case 'view-role':
      return 'detail_ViewRoles';
    case 'edit-role':
      return 'detail_EditRole';
    case 'create-user':
      return 'detail_createUser';
    case 'delete-user':
      return 'detail_EditRemoveUser';
    case 'assign-user-role':
      return 'detail_AssignUserRole';
    // Operation
    case 'assign-line-printer':
      return 'detail_AssignPrinter';
    case 'assign-line-product':
      return 'detail_AssignProduct';
    case 'assign-downtime-reason':
      return 'detail_AssignDowntimeReason';
    case 'apply-qa':
      return 'detail_AssignQAcount';
    case 'approve-assessment':
      return 'detail_ApproveAssessment';
    case 'edit-assessment':
      return 'detail_EditAssessment';
    case 'view-assessment':
      return 'detail_ViewAssessment';
    // Site Management
    case 'view-sitedef':
      return 'detail_ViewSiteSettings';
    case 'edit-sitedef':
      return 'detail_EditSiteDef';
    case 'edit-siteconfig':
      return 'detail_EditSiteConfig';
    // Report
    case 'view-report':
      return 'detail_ViewReport';
    case 'edit-report':
      return 'detail_EditReport';
    case 'edit-global-report':
      return 'detail_EditGlobalReport';
    // Design
    case 'edit-design':
      return 'detail_DesignAccess';
    case 'send-printerjob':
      return 'detail_DownloadTemplatePrinter';
    case 'download-printerjob':
      return 'detail_DownloadTemplateUSB';
    case 'view-inspection':
      return 'detail_ViewInspection';
    case 'edit-inspection':
      return 'detail_EditInspection';
    case 'perform-inspection':
      return 'detail_PerformInspection';
    // Order Execution
    case 'edit-printerjob':
      return 'detail_EditOrderEx';
    case 'execute-printerjob':
      return 'detail_ExecuteOrderEx';
    case 'full-access':
      return 'detail_FullAccess';
    default:
      return ability;
  }
}
