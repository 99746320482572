import { makeStyles } from "@material-ui/styles";
import { mySecondaryColor, mySecondaryColorDimmed } from "../../constants/colors";

export default makeStyles((theme: any) => ({
  link: {
    padding: 0,
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: mySecondaryColorDimmed,
    },
  },

  linkIcon: {
    color: mySecondaryColor,
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },

  linkText: {
    padding: 0,
    color: mySecondaryColor,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: '1.06rem'
  }
}));
