import React from 'react';
import { makeStyles, Button, Tooltip } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  actionButtonContrainer: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.125),
    '&:nth-last-child(n + 2)': {
      marginRight: theme.spacing(1.25)
    }
  }

}));

interface IStyledActionButtonProps {
  label: string;
  startIcon: any;
  tooltip?: string;
  color: any;
  disabled?: boolean;
  onClick?: any;
}

export const StyledActionButton = ({ label, startIcon, tooltip, color, disabled, onClick }: IStyledActionButtonProps) => {
  const classes = useStyles();
  return <Tooltip title={tooltip || ''} placement="top">
    <div className={classes.actionButtonContrainer}>
      <Button
        variant="contained"
        color={color}
        startIcon={startIcon}
        disabled={disabled}
        onClick={onClick}
      >{label}</Button>
    </div>
  </Tooltip>;
}
