import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableSortLabel
} from '@material-ui/core';

import { FormattedMessage, useIntl } from 'react-intl';
import { useMigrateSitesResultDialogStyle } from './MigrateSitesResultDialog.css';
import { ResultItem } from '../interface/ResultItem';


const columns: Array<any> = [
  {
    id: 'siteId',
    label: 'Site Id',
    minWidth: '100px'
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: '100px'
  },
  {
    id: 'companyId',
    label: 'Company Id',
    minWidth: '100px'
  },
  {
    id: 'actionType',
    label: 'Action Type',
    minWidth: '100px'
  },
  {
    id: 'siteUpdated',
    label: 'Site Updated',
    minWidth: '100px'
  }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableHeadProps {
  classes: any;
  order: any;
  orderBy: any;
  onRequestSort: any;
}

function EnhancedTableHead({
  classes,
  order,
  orderBy,
  onRequestSort }: EnhancedTableHeadProps) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IMigrateSitesResultDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: DialogProps['maxWidth'];
  items: Array<ResultItem>;
}

const MigrateSitesResultDialog = ({
  items,
  open,
  maxWidth,
  onClose }: IMigrateSitesResultDialogProps) => {
  const intl = useIntl();
  const classes = useMigrateSitesResultDialogStyle();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('siteId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'lg'}
    disableEscapeKeyDown
    aria-labelledby="upload-item" open={open}>
    <DialogTitle id="upload-item-dialog-title">
      {intl.formatMessage({ id: 'detail_MigrateSitesResults' })}
    </DialogTitle>
    <DialogContent className={classes.content} dividers>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(items, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.siteId}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className={classes.cell}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 200, 1000]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{
          root: classes.paginationRoot,
          toolbar: classes.paginationToolbar
        }}
      />
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <Button onClick={handleClose}
        color="default"
        className="close-item-btn"
        variant="contained">
        <FormattedMessage id="detail_Close" />
      </Button>
    </DialogActions>
  </Dialog>;
}

export { MigrateSitesResultDialog };
