import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import { siteReducer, ISiteState } from '../siteReducer/siteReducer';
import { configReducer, IConfigState } from '../configReducer/configReducer';
import { languageReducer, ILanguageState } from '../languageReducer/languageReducer';
import { userReducer, IUserState } from '../userReducer/userReducer';
import { lineReducer } from '../lineReducer/lineReducer';
import { shiftReducer } from '../shiftReducer/shiftReducer';
import { productReducer } from '../productReducer/productReducer';

function combineReducers(reducers) {
  return (state: any, action: IAppGlobalAction) => {
    const newState: any = {};
    Object.keys(reducers).forEach(key => {
      const dataKey = key.replace('Reducer', 'State');
      newState[dataKey] = reducers[key](state[dataKey], action);
    })
    return newState;
  }
}
export interface IRootState {
  configState: IConfigState;
  userState: IUserState;
  siteState: ISiteState;
  lineState: Array<any>;
  shiftState: Array<any>;
  productState: Array<any>;
  languageState: ILanguageState;
}

function createRootReducer() {
  return combineReducers({
    configReducer,
    userReducer,
    siteReducer,
    lineReducer,
    shiftReducer,
    productReducer,
    languageReducer
  })
}

export const rootReducer = createRootReducer();
