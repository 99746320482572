import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";

import { AppGlobalProvider } from "./context/AppContext/AppContext";
import { LayoutProvider } from './context/LayoutContext/LayoutContext';

import { IntlProvider } from 'react-intl';
import { Logger } from './utilities/Logger/Logger';
import { messages } from './utilities/loadTranslations';

Logger.of('App.index').info('Environment', process.env.NODE_ENV); // eslint-disable-line no-undef
let hostName = '';
if (typeof window !== 'undefined') {
  hostName = window.location.host;
  switch (hostName) {
    case 'localhost:3000':
    case 'localhost:9000':
    case 'vccm.dev.videojetcloud.com':
      Logger.of('App.index').info('setting stageName dev.');
      break;

    case 'vccm.qa.videojetcloud.com':
      Logger.of('App.index').info('setting stageName qa.');
      break;

    case 'vccm.stage.videojetcloud.com':
      Logger.of('App.index').info('setting stageName stage.');
      break;
    default:
      Logger.of('App.index').info(`couldn't find case for ${hostName}`);
  }
}

const locale = navigator.language.split(/[-_]/)[0];

ReactDOM.render(
  <IntlProvider
    locale={locale}
    messages={messages[locale]}
  >
    <LayoutProvider>
      <AppGlobalProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <BrowserRouter>
            <Switch>
              <Route path="/site/:siteId" component={App} />
              <Route path="*" component={App} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </AppGlobalProvider>
    </LayoutProvider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
