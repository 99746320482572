import { makeStyles } from "@material-ui/styles";
import { imageBackgroundColor, mySecondaryColorDimmed } from "../../../../constants/colors";

export const useLinePerformanceListRowStyle = makeStyles((theme: any) => ({
  root: {
    borderLeft: `1px solid ${mySecondaryColorDimmed}`,
    borderTop: `1px solid ${mySecondaryColorDimmed}`,
    borderRight: `1px solid ${mySecondaryColorDimmed}`,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    padding: '5px'
  },
  dashboardLink: {
    justifySelf: 'flex-end'
  },
  lineDashboardLink: {
    textDecoration: 'none'
  },
  lineDashboardBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(1.125)
  },
  lineDashboardBtn: {
    padding: theme.spacing(0.625),
    minWidth: theme.spacing(16.25)
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  productImage: {
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
    backgroundColor: imageBackgroundColor
  },
  imageHolder: {
    textAlign: 'center',
    verticalAlign: 'center'
  },
  status: {
    minWidth: theme.spacing(6.25),
  },
  printer: {
    minWidth: theme.spacing(10),
    wordBreak: 'normal'
  },
  item: {
    fontSize: 'Larger',
    textAlign: 'center',
    margin: 'auto'
  }
}));
