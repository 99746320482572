import { makeStyles } from "@material-ui/core";
import { myGhostWhite } from "../../../constants/colors";

export const useReportManagerListRowStyles = makeStyles((theme) => ({
  scheduleContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
   // border: `1px solid ${myGreyDimmed}`,
    borderRadius: theme.spacing(1),
    backgroundColor: myGhostWhite
  }
}));
