import { makeStyles } from "@material-ui/core";
import { mySecondaryColor } from "../../constants/colors";

export const useTermsDialogStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.125),
  },
  title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
  },
  titleText: {
    fontSize: theme.spacing(3),
    fontWeight: 600
},
  titleIcon: {
    fontSize: theme.spacing(6),
    color: mySecondaryColor,
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  backBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  nextBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  documentContainer: {
    padding: theme.spacing(0.125)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rightButton: {
    marginRight: theme.spacing(1.5),
  }
}));
