import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesDefaults,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import 'hammerjs';

import PropTypes from 'prop-types';
import React from 'react';
import { sharedConfig } from './ChartSharedConfig';

function chartConfig(configProps: any): any {
  return {
    title: {
      text: configProps.title,
      visible: configProps.showTitle,
      color: 'Black',
    },
    legend: {
      position: 'bottom',
      visible: false,
    },
    series: [
      {
        name: 'Totals',
        data: configProps.lines,
        field: 'shiftEff',
        categoryField: 'title',
        color: '#0192c7',
        noteTextField: 'id',
        notes: { visible: false },
      }],
    transitions: false,
    panes: [
      {
        clip: false,
      }],
    valueAxis: {
      line: {
        visible: false,
      },
      min: 0,
      max: 1.1,
      labels: {
        visible: true,
        format: '{0:p0}',
      },
    },
    categoryAxis: {
      labels: {
        rotation: 'auto',
      },
      majorGridLines: {
        visible: false,
      },
      line: {
        visible: false,
      },
    },
    seriesColors: ['#ff832d', '#3aba23', '#ffff00'],
    tooltip: {
      visible: true,
      format: '{0:p0}',
    },

  };
}

const ChartOeeByLine = (props: any) => {
  const config = chartConfig(props);
  return (
    <React.Fragment>
      {props.children}
      <Chart {...sharedConfig}
        onSeriesClick={e => props.history.push(`/site/${props.siteId}/line/${e.dataItem.id}`)}
      >
        <ChartTooltip />
        <ChartLegend {...config.legend} />
        <ChartSeriesDefaults {...config.seriesDefaults} />
        <ChartTitle {...config.title} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem {...config.categoryAxis} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem {...config.valueAxis} />
        </ChartValueAxis>
        <ChartSeries>
          {config.series ? config.series.map((item, i) => (
            <ChartSeriesItem {...item} key={`series-${i}`}>
              <ChartSeriesItemTooltip format="{0:p0}" />
            </ChartSeriesItem>
          )) : null}
        </ChartSeries>
      </Chart>
    </React.Fragment>
  );
};
/* eslint-enable react/prop-types */

ChartOeeByLine.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  history: PropTypes.object,
  lines: PropTypes.array,
  showTitle: PropTypes.bool,
  siteId: PropTypes.string,
  interval: PropTypes.string,
  min: PropTypes.number,
  screenHeight: PropTypes.number,
  screenWidth: PropTypes.number,
};

ChartOeeByLine.defaultProps = {
  showTitle: true,
};


export default ChartOeeByLine;
