import { makeStyles } from "@material-ui/core";
import { myWhite } from "../../../constants/colors";

export const useSitePageStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: myWhite,
  },
  headerSelected: {
    backgroundColor: '#eeeff8',
  },
  siteTitles: {
    margin: '12px',
    display: 'flex',
    flexGrow: 1,
    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  headingTitle: {
    fontWeight: 600
  },
  heading: {
    fontSize: '1.06rem',
    flexBasis: '25%',
    flexShrink: 0,
  },
  siteId: {
    userSelect: 'text'
  },
  secondaryHeading: {
    fontSize: '1.06rem',
    flexBasis: '25%',
    color: theme.palette.text.secondary,
  },

  thirdHeading: {
    fontSize: '1.06rem',
    flexBasis: '25%',
    color: theme.palette.text.secondary,
  },
  controlHeading: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.06rem',
    flexBasis: '25%',
    color: theme.palette.text.secondary,
  },
  accordionTitle: {
    marginBottom: '10px'
  },
  placeholder: {
    height: '40px'
  },
  pageContainer: {
    marginLeft: theme.spacing(0)
  }
}));
