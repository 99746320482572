import React, { useCallback, useEffect, useState } from 'react';
import { claims } from 'vccm-common';
import ProductListRow from '../ProductListRow/ProductListRow';
import { TableHeadText } from '../../../components/TableHeadText/TableHeadText';
import StyledGrid from '../../../components/Basic/StyledGrid/StyledGrid';
import StyledGridHeadRow from '../../../components/Basic/StyledGridHeadRow/StyledGridHeadRow';
import StyledGridHeadCell from '../../../components/Basic/StyledGridHeadCell/StyledGridHeadCell';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import Utils from '../../../utilities/utils';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import { Paging } from '../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../constants/global';
import { useProductListTableStyles } from './ProductListTable.css';
import Can from '../../../components/Common/Can';
import { ProductBar } from '../ProductBar/ProductBar';
import { useConfigActions } from '../../../actions/configActions';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';

const set1Breaks = {
  product: { lg: 3, md: 4, sm: 4, xs: 12 },
  productId: { lg: 1, md: 3, sm: 4, xs: 12 },
  lineTitles: { lg: 2, md: 3, sm: 4, xs: 12 },
  runRate: { lg: 1, md: 2, sm: 4, xs: 12 },
  bestRate: { lg: 1, md: 4, sm: 4, xs: 12 },
  quality: { lg: 1, md: 4, sm: 4, xs: 12 },
  actionButtons: { lg: 3, md: 4, sm: 12, xs: 12 }
};

const set2Breaks = {
  product: { lg: 3, md: 4, sm: 12, xs: 12 },
  lineTitles: { lg: 2, md: 4, sm: 12, xs: 12 },
  runRate: { lg: 2, md: 4, sm: 12, xs: 12 },
  bestRate: { lg: 1, md: 4, sm: 12, xs: 12 },
  quality: { lg: 1, md: 4, sm: 12, xs: 12 },
  actionButtons: { lg: 3, md: 4, sm: 12, xs: 12 }
};

const set3Breaks = {
  product: { lg: 6, md: 6, sm: 12, xs: 12 },
  actionButtons: { lg: 6, md: 6, sm: 12, xs: 12 }
};

const calculateGridBreak = (insightUser: boolean) => {
  const isDev = Utils.isDev();
  if (isDev && insightUser) {
    return set1Breaks;
  }

  if (!isDev && insightUser) {
    return set2Breaks;
  }

  return set3Breaks;
}

interface IProductListState {
  page: number;
  sortedProducts: Array<any>;
  pageCount: number;
  pagedProducts: Array<any>;
}
interface IProductListTableProps {
  products: any;
  site: any;
  refreshSiteData: () => void;
  onDeleteProduct: (product: any) => void;
}

const ProductListTable = ({
  products,
  site,
  refreshSiteData,
  onDeleteProduct }: IProductListTableProps) => {
  const classes = useProductListTableStyles();
  const [, setshowProductAssignModal] = useState(false);

  const configActions = useConfigActions();
  const { appFilters } = useAppGlobalState();

  const [filteredProducts, setFilteredProducts] = useState<Array<any>>([]);

  const [productListState, setProductListState] = useState<IProductListState>({
    page: 1,
    sortedProducts: [],
    pageCount: 0,
    pagedProducts: []
  });

  const {
    page,
    sortedProducts,
    pageCount,
    pagedProducts
  } = productListState;

  const showAssignModal = () => {
    setshowProductAssignModal(true);
  }

  function makeLinesListForDisplay(lineTitles) {
    // TODO: make this wrap on each title
    let output = '';
    for (const key in lineTitles) {
      output += `${lineTitles[key]}; `;
    }
    return output;
  }

  const tableHeadFontSize = 'h6.fontSize';
  const tableHeadAlign = 'left';
  const insightUser = claims.moduleLicensed(site, claims.licensedModuleInfo.intelligence);

  const gridBreak: any = calculateGridBreak(insightUser);
  const isDev = Utils.isDev();

  useEffect(() => {
    setProductListState(s => {
      const sorted = products.sort((a, b) => a.title.localeCompare(b.title));
      return {
        ...s,
        sortedProducts: sorted
      }
    })
  }, [products, site]);

  useEffect(() => {
    const searchTerm = appFilters.productFilter.searchTerm;
    const searchTermLowercase = searchTerm.toLowerCase();
    const initialFilteredProducts = searchTerm
      ? sortedProducts.filter(product => product.title.toLowerCase().indexOf(searchTermLowercase) > -1)
      : sortedProducts;

    setFilteredProducts(initialFilteredProducts);

  }, [appFilters, sortedProducts]);


  useEffect(() => {
    setProductListState(s => {
      const count = Math.ceil(((filteredProducts && site && filteredProducts.length) || 0) / PAGE_SIZE);
      const paged = filteredProducts.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        pageCount: count,
        pagedProducts: paged
      }
    })
  }, [filteredProducts, site]);

  const onPageChange = useCallback(p => {
    setProductListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = filteredProducts.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setProductListState(s => ({
      ...s,
      pagedProducts: paged,
    }));
  }, [page, filteredProducts]);

  return (<>
    <Can do="edit" on="product">
      <ProductBar
        siteId={site.id}
        site={site}
        products={products}
        filter={appFilters.productFilter}
        setFilter={configActions.setBarFilter}
        refreshSiteData={refreshSiteData} />
    </Can>
    <StyledContentCard>
      {
        products && site && products.length > 0 ? <StyledGrid>
          {
            appFilters.productFilter.searchTerm.length === 0 || pagedProducts.length > 0 ? <StyledGridHeadRow>
              <StyledGridHeadCell {...gridBreak.product}>
                <TableHeadText
                  textId="detail_Product"
                  fontSize={tableHeadFontSize}
                  textAlign={tableHeadAlign}
                />
              </StyledGridHeadCell>
              {
                isDev && <StyledGridHeadCell {...gridBreak.productId} >
                  <TableHeadText
                    textId="detail_ProductId"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
              }
              {
                insightUser && <StyledGridHeadCell {...gridBreak.lineTitles} >
                  <TableHeadText
                    textId="detail_Line_s"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
              }
              {
                insightUser && <StyledGridHeadCell {...gridBreak.runRate} >
                  <TableHeadText
                    textId="detail_Run_Rate"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
              }
              {
                insightUser && <StyledGridHeadCell {...gridBreak.bestRate} >
                  <TableHeadText
                    textId="detail_Best_Rate"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
              }
              {
                insightUser && <StyledGridHeadCell {...gridBreak.quantity} >
                  <TableHeadText
                    textId="detail_Quantity"
                    fontSize={tableHeadFontSize}
                    textAlign={tableHeadAlign}
                  />
                </StyledGridHeadCell>
              }
              <StyledGridHeadCell {...gridBreak.actionButtons} >
              </StyledGridHeadCell>
            </StyledGridHeadRow> : <EmptyItem textId="detail_noItemsByFilter" />
          }
          {
            pagedProducts.map(product => (
              <ProductListRow key={product.id} product={product}
                siteId={site.id}
                gridBreak={gridBreak}
                onAssign={showAssignModal}
                isDev={isDev}
                insightUser={insightUser}
                makeLinesListForDisplay={makeLinesListForDisplay}
                onDeleteProduct={onDeleteProduct}
              />
            ))
          }
        </StyledGrid>
          : <EmptyItem textId="detail_noProducts" />
      }
      {
        filteredProducts && site && filteredProducts.length > PAGE_SIZE ? <div className={classes.pageContainer}>
          <Paging
            page={page}
            onChange={onPageChange}
            count={pageCount}
            total={filteredProducts.length} /></div> : null
      }
    </StyledContentCard>
  </>)
}

export default ProductListTable;
