import { FormControl } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGlobalStyles } from "../../constants/globalstyles";
import { useNotificationsDelayStyle } from "./NotificationsDelay.css";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { InfoTooltip } from "../Basic/InfoTooltip/InfoTooltip";

interface NotificationsDelayProps {
  onChange: (event) => void;
  isDisabled?: boolean;
  value: number;
  errors: any;
}

function NotificationsDelay({ onChange,
  value,
  isDisabled,
  errors }: NotificationsDelayProps) {
  const classes = useNotificationsDelayStyle();
  const intl = useIntl();
  const globalClasses = useGlobalStyles();
  return <div className={classes.inputBox}>
    <div>
      <FormattedMessage id='detail_MinutesofDowntime' />
      <InfoTooltip textId='detail_MinutesofDowntimeTip'/>
    </div>

    <FormControl variant="outlined" className={globalClasses.formControl}>
      <NumericTextBox
        name="NotificationDelay"
        onChange={onChange}
        value={value}
        format="n0"
        min={1}
        disabled={isDisabled}
        validationMessage={errors.notificationsDelay && intl.formatMessage({ id: errors.notificationsDelay })}
        valid={errors.notificationsDelay == null}
      />
    </FormControl>
  </div>
}

NotificationsDelay.displayName = 'NotificationsDelay';

export { NotificationsDelay };
