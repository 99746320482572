import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ActionButton } from '../../../../components/ActionButton/ActionButton';
import StyledGridRow from '../../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledActionContainer from '../../../../components/Basic/StyledActionContainer/StyledActionContainer';
import StyledGridCell from '../../../../components/Basic/StyledGridCell/StyledGridCell';
import Can from '../../../../components/Common/Can';
import { useConfigState } from '../../../../context/AppContext/AppContext';
import { getValues } from '../../../../helpers/ArrayHelper';

function getArrays(a, b = null) {
  let out: Array<any> = [];
  if (a && Array.isArray(getValues(a))) {
    out = getValues(a);
  }
  if (b && Array.isArray(getValues(b))) {
    out = out.concat(getValues(b));
  }
  return out;
}
function getTitlesByIds(data, ids) {
  if (Array.isArray(ids)) {
    return ids.reduce((acc, id) => {
      const found = data.find(e => (e.value === id));
      if (found) {
        acc.push(found.text);
      }
      return acc;
    }, []);
  }
  return [];
}

interface InspectionSetupListRowProps {
  inspection: any;
  onAssign: any;
  makeLinesListForDisplay: any;
  siteId: string;
  isDev: boolean;
  insightUser: boolean;
  gridBreak: any;
  roles: Array<any>;
  formattedProducts: Array<any>;
  formattedLines: Array<any>;
  onDeleteInspectionSetup: (inspection: any) => void;
}

const InspectionSetupListRow = ({
  inspection,
  roles,
  formattedProducts,
  formattedLines,
  siteId,
  gridBreak,
  onDeleteInspectionSetup }: InspectionSetupListRowProps) => {
  const intl = useIntl();

  const configState = useConfigState();
  const [inspectionFreqTypes, setInspectionFreqTypes] = useState<Array<any>>([]);
  const [allProductNames, setAllProductNames] = useState<Array<any>>([]);
  const [allLineNames, setAllLineNames] = useState<Array<any>>([]);
  const [roleName, setRoleName] = useState<any>(null);

  useEffect(() => {
    const values = configState.inspectionFreqTypes.map(i => ({
      value: i.value.toString(),
      text: intl.formatMessage({ id: i.transId })
    }));
    setInspectionFreqTypes(values);
  }, [intl, configState.inspectionFreqTypes]);

  useEffect(() => {
    setAllLineNames(getTitlesByIds(formattedLines, getArrays(inspection.lineIds)).join(','));
  }, [formattedLines, inspection.lineIds]);

  useEffect(() => {
    setAllProductNames(getTitlesByIds(formattedProducts, getArrays(inspection.productIds)).join(','));
  }, [formattedProducts, inspection.productIds]);

  useEffect(() => {
    setRoleName(getTitlesByIds(roles, [inspection.roleId]).join(','));
  }, [roles, inspection.roleId]);

  // useEffect(() => {
  //   inspection.roleName = roleName;
  // }, [roleName]);

  return (
    <StyledGridRow>
      <StyledGridCell {...gridBreak.name} >
        <strong>{inspection.title}</strong>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.description} >
        <strong>{inspection.description}</strong>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.frequency} >
        <strong>{inspectionFreqTypes.length > 0 ? inspectionFreqTypes[inspection.freqType || 0].text : null}</strong>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.active} >
        <strong>{inspection.active ? intl.formatMessage({ id: 'detail_Yes' }) : intl.formatMessage({ id: 'detail_No' })}</strong>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.role} >
        <strong>{roleName}</strong>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.lines} >
        <strong>{(allLineNames.length) ? allLineNames : null}</strong>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.products} >
        <strong>{(allProductNames.length) ? allProductNames : null}</strong>
      </StyledGridCell>

      <StyledGridCell {...gridBreak.actionButtons} >
        <StyledActionContainer breaks={{ sm: true }}>
          <Can do="edit" on="inspection">
            <Link to={`/site/${siteId}/setup/inspection/${inspection.id}/edit`}
            >
              <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
                <ActionButton>
                  <EditIcon />
                </ActionButton>
              </Tooltip>
            </Link>
          </Can>
          <Can do="edit" on="inspection">
            <Link to={`/site/${siteId}/setup/inspection/${inspection.id}/clone`}
            >
              <Tooltip title={intl.formatMessage({ id: 'detail_Clone' })}>
                <ActionButton>
                  <FileCopyIcon />
                </ActionButton>
              </Tooltip>
            </Link>
          </Can>
          <Can do="edit" on="inspection">
            <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
              <ActionButton
                onClick={() => onDeleteInspectionSetup(inspection)}>
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </Can>
        </StyledActionContainer>
      </StyledGridCell>
    </StyledGridRow>
  )
};

export default InspectionSetupListRow;
