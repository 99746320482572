import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import Can from '../../../components/Common/Can';
import { useRoleActions } from '../../../actions/roleActions';

interface IRoleListRow {
  role: any;
  siteId: string;
  gridBreak: any;
  setRoleRefreshIndex: Dispatch<SetStateAction<number>>;
}

const RoleListRow = ({ role,
  siteId,
  gridBreak,
  setRoleRefreshIndex }: IRoleListRow) => {
  const intl = useIntl();
  const roleActions = useRoleActions();

  const [saving, setSaving] = useState(false);
  const { readonly } = role;

  const [isRoleDeleteConfirmVisible, setRoleDeleteConfirmVisible] = useState(false);

  const onCloseRoleDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);
        roleActions.deleteRole(role, siteId)
          .then(() => {
            toastr.success(intl.formatMessage({ id: 'detail_RoleDeleted' }));
            setSaving(false);
            setRoleRefreshIndex(state => state + 1);
          })
          .catch((error) => {
            toastr.error(error);
            setSaving(false);
          });
      } catch (e) {
        toastr.error(e.toString());
      }
    }
    setRoleDeleteConfirmVisible(false);
  }

  return (<>
    <StyledGridRow readonly={readonly}>
      <StyledGridCell {...gridBreak.name}>
        <div>
          <strong>{role.title}</strong>
        </div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.description}>
        <div><em>{role.description}</em></div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons} >
        <Can
          do="edit"
          on="role"
          passThrough>
          {can => can && !readonly &&
            <StyledActionContainer>
              <Link to={`/site/${siteId}/setup/role/${role.id}/edit`}
              >
                <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
                  <ActionButton>
                    <EditIcon />
                  </ActionButton>
                </Tooltip>
              </Link>
              <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
                <ActionButton onClick={() => setRoleDeleteConfirmVisible(true)}>
                  <DeleteIcon />
                </ActionButton>
              </Tooltip>
            </StyledActionContainer>
          }
        </Can>
      </StyledGridCell>
    </StyledGridRow>
    <DeleteDialog
      saving={saving}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={isRoleDeleteConfirmVisible}
      onClose={onCloseRoleDeleteDialog}
    />
    { saving && <ProgressIndicator />}
  </>
  )
};

export default RoleListRow;
