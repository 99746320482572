import humanizeDuration from 'humanize-duration';
import * as ENUMS from '../../enums/Enums';
import momentEN from 'moment';

const defaultSelector = data => data;

class DateHelper {
  _intl: any;
  _timeFrames: any;

  constructor(intl, timeFrames = DateHelper.timeFrames) {
    this._intl = intl;
    this._timeFrames = timeFrames;
  }

  static shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: function () {
          return 'y';
        },
        mo: function () {
          return 'mo';
        },
        w: function () {
          return 'w';
        },
        d: function () {
          return 'd';
        },
        h: function () {
          return 'h';
        },
        m: function () {
          return 'm';
        },
        s: function () {
          return 's';
        },
        ms: function () {
          return 'ms';
        },
      },
    },
  });

  static timeIntervals = [
    ENUMS.INTERVALS.instances[ENUMS.INTERVALS.HOUR],
    ENUMS.INTERVALS.instances[ENUMS.INTERVALS.DAY],
    // VCCM-1682 commented out for release
    ENUMS.INTERVALS.instances[ENUMS.INTERVALS.WEEK],
    /* ENUMS.INTERVALS.instances[ENUMS.INTERVALS.MONTH],*/
  ];

  static timeFrames = [
    { translationKey: 'detail_CustomDateRange', value: 'customDateRange' },
    { translationKey: 'detail_Yesterday', value: 'yesterday' },
    { translationKey: 'detail_LastWeek', value: 'lastWeek' },
    { translationKey: 'detail_Last30Days', value: 'last30Days' },
    { translationKey: 'detail_ThisMonth', value: 'thisMonth' },
    { translationKey: 'detail_LastMonth', value: 'lastMonth' },
  ];

  static humanizeDuration(duration) {
    return this.shortEnglishHumanizer(duration, { largest: 2, round: true, units: ['d', 'h', 'm'] });
  }

  static getFormattedTimeFrames(intl, selector = defaultSelector) {
    return this.formatData(this.timeFrames, intl, selector);
  }

  static translateWeekday(momentLocale, enWeekdayName) {
    const idx = momentEN.weekdays().indexOf(enWeekdayName);
    return (idx >= 0) ? momentLocale.weekdays(idx) : enWeekdayName;
  }

  getTimeFrameTextByValue(tfVal) {
    return DateHelper.getTextByValue(this._timeFrames, tfVal, this._intl);
  }

  static getFormattedTimeIntervals(intl, selector = defaultSelector) {
    return this.formatData(this.timeIntervals, intl, selector);
  }

  static formatData(data, intl, selector = defaultSelector) {
    const selected = selector(data);
    return intl == null
      ? selected
      : selected.map(d => ({ ...d, text: intl.formatMessage({ id: d.translationKey }) }));
  }

  static getTextByValue(data, val, intl) {
    const entry = data.find(d => d.value === val);
    return entry ? intl.formatMessage({ id: entry.translationKey }) : val;
  }
}

export default DateHelper;
