import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveAs } from '@progress/kendo-file-saver';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import csvtojsonV2 from 'csvtojson/v2';
import { Button } from '@material-ui/core';
import { claims } from 'vccm-common';
import Utils from '../../../utilities/utils';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import { useHistory } from 'react-router';
import Can from '../../../components/Common/Can';
import { useProductBarStyles } from './ProductBar.css';
import { StyledActionButton } from '../../../components/Basic/StyledActionButton/StyledActionButton';

import { DEBOUNCED_SEARCH_DELAY } from '../../../constants/global';
import { ProductImportDialog } from '../ProductImportDialog/ProductImportDialog';
import { useDebounce } from '../../../components/Debouncer/Debouncer';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import BarStyledGrid from '../../../components/Basic/BarStyledGrid/BarStyledGrid';

const gridBreak: any = {
  create: { lg: 6, md: 6, sm: 6, xs: 8 },
  uploadCsv: { lg: 3, md: 3, sm: 3, xs: 4 },
  filter: { lg: 3, md: 3, sm: 3, xs: 12 }
};

interface IProductBarProps {
  siteId: string;
  site: any;
  products: Array<any>;
  refreshSiteData: () => void;
  filter: { searchTerm: string };
  setFilter: (string, any) => void;
}

const ProductBar = ({ siteId,
  site,
  products,
  filter,
  setFilter,
  refreshSiteData }: IProductBarProps) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useProductBarStyles();

  const [fileInfo, setFileInfo] = React.useState<any>({});
  const [importedProducts, setImportedProducts] = useState<Array<any>>([]);

  const csvExportData = [
    ['Product Title', 'Product Description', ...(Utils.isDev() ? ['Product Id'] : []), 'Best Rate units/hour', 'Run Rate units/hour', 'Multiplier'],
    ['Sample Title', 'Sample Description', ...(Utils.isDev() ? [''] : []), '1800', '1188', '1'],
  ];
  const minCsvExportData = [
    ['Product Title', 'Product Description', ...(Utils.isDev() ? ['Product Id'] : [])],
    ['Sample Title', 'Sample Description', ...(Utils.isDev() ? [''] : [])],
  ];

  const addProduct = () => {
    history.push(`/site/${siteId}/setup/product/0/add`);
  }

  const [searchTerm, setSearchTerm] = useState<string>(filter.searchTerm);
  const debouncedFilter = useDebounce(searchTerm, DEBOUNCED_SEARCH_DELAY);

  useEffect(() => {
    setFilter('productFilter', s => ({ ...s, searchTerm: debouncedFilter as string }));
  }, [debouncedFilter, setFilter]);


  const convertArrayOfObjectsToCSV = (productArray) => {
    let result;
    let ctr;

    const data = productArray || null;
    if (!data || !data.length) {
      return null;
    }

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);

    result = '';
    data.forEach((item) => {
      ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const downloadTemplate = () => {
    const nonInsightUser = !claims.moduleLicensed(site, claims.licensedModuleInfo.intelligence);
    const exportData = convertArrayOfObjectsToCSV(
      nonInsightUser
        ? minCsvExportData
        : csvExportData,
    );
    const dataURI = `data:text/plain;charset=utf-8,${exportData}`;
    saveAs(dataURI, 'template.csv');
  }

  const createProductPreview = (fileInfo, newlyImportedProducts) => {
    newlyImportedProducts.forEach((product) => {
      product.selected = !products.find(p => p.title === product['Product Title']);
    });
    setFileInfo(fileInfo);
    setImportedProducts(newlyImportedProducts);
  }

  const csvUploadChange = () => {
    const cvsUpload = document.getElementById('__csvUploadId');
    if (cvsUpload) {
      const [fileInfo] = cvsUpload['files'];
      const reader = new FileReader();
      if (fileInfo) {
        reader.onloadend = () => {
          csvtojsonV2({ flatKeys: true, checkType: true }).fromString(reader.result as string).then((jsonObj) => {
            createProductPreview(fileInfo, jsonObj);
          });
        };
        reader.readAsText(fileInfo);
      }
      cvsUpload['value'] = null;
    }
  }

  const handleImportClose = (anyInserted) => {
    setImportedProducts([]);
    setFileInfo({});
    if (anyInserted) {
      refreshSiteData();
    }
  }

  return <>
    <BarStyledContentCard>
      <BarStyledGrid>
        <Can do="edit" on="product">
          <StyledGridCell {...gridBreak.create}>
            <div className={classes.buttonContainer}>
              <StyledActionButton
                color="primary"
                startIcon={<AddIcon />}
                onClick={addProduct}
                label={intl.formatMessage({ id: 'detail_Create_a_Product' })}
              />
              <StyledActionButton
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={downloadTemplate}
                label={intl.formatMessage({ id: 'detail_DownloadTemplate' })}
              />
            </div>
          </StyledGridCell>
        </Can>
        <StyledGridCell {...gridBreak.uploadCsv}>
          <div className={classes.csvUploadContainer}>
            <div>
              <label htmlFor="__csvUploadId">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                >
                  <FormattedMessage id='detail_ImportCSV' />
                </Button>
              </label>
              <input
                type="file"
                name="btnUpload"
                className="btn btn-primary"
                style={{ display: 'none' }}
                id="__csvUploadId"
                onChange={csvUploadChange}
              />
            </div>
          </div>
        </StyledGridCell>
        <StyledGridCell {...gridBreak.filter} className={classes.filterContainer}>
          <SearchInput
            searchTerm={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            onSubmitSearch={(term) => {
              setSearchTerm(term);
            }}
          />
        </StyledGridCell>
      </BarStyledGrid>

    </BarStyledContentCard>
    <ProductImportDialog
      title={fileInfo && fileInfo.name}
      site={site}
      open={importedProducts && importedProducts.length > 0}
      setImportedProducts={setImportedProducts}
      importedProducts={importedProducts}
      products={products}
      onClose={handleImportClose} />
  </>
};

export { ProductBar };
