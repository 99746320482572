import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDowntimeSetupPageStyles } from './DowntimeSetupPage.css';

import { useIntl } from 'react-intl';

import {
  TimerOff as TimerOffIcon,
  AvTimer as AvTimerIcon,
  WatchLater as WatchLaterIcon,
} from "@material-ui/icons";
import PageTabs from '../../../components/PageTabs/PageTabs';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';

import { useHistory } from 'react-router';
import DowntimeCategoryListTable from '../category/DowntimeCategoryListTable/DowntimeCategoryListTable';
import Utils from '../../../utilities/utils';
import DowntimeSubCategoryListTable from '../subcategory/DowntimeSubCategoryListTable/DowntimeSubCategoryListTable';
import StyledTab from '../../../components/Basic/StyledTab/StyledTab';
import StyledTabs from '../../../components/Basic/StyledTabs/StyledTabs';

interface IDowntimeSetupPageProps {
  match: any;
  downtimeCategories: Array<any>;
  downtimeTypesFormatted: Array<any>;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const DowntimeSetupPage = ({ match,
  downtimeCategories,
  downtimeTypesFormatted,
  setRefreshRequired }: IDowntimeSetupPageProps) => {

  const classes = useDowntimeSetupPageStyles();
  const intl = useIntl();
  const history = useHistory();

  const setTitle = useSetTitleAction();
  const siteId: string = match.params.siteId;

  const [activeTabIndex, setActiveTabIndex] = useState(history.location.pathname.indexOf('setup/subcategory') > -1 ? 1 : 0);

  useEffect(() => {
    if (activeTabIndex === 0) {
      setTitle('detail_Downtimes', <WatchLaterIcon />, {
        ignoreItself: true,
        parents: [{
          id: 'detail_Configuration',
        },
        {
          id: 'detail_Downtimes',
        },
        {
          id: 'detail_DowntimeCategory'
        }]
      });
      history.replace(`/site/${siteId}/setup/downtimes`);
    } else {
      setTitle('detail_Downtimes', <WatchLaterIcon />, {
        ignoreItself: true,
        parents: [{
          id: 'detail_Configuration',
        },
        {
          id: 'detail_Downtimes',
        },
        {
          id: 'detail_DowntimeSubcategory'
        }]
      });
      history.replace(`/site/${siteId}/setup/subcategory`);
    }

  }, [setTitle, activeTabIndex, history, siteId]);



  return <><PageTabs>
    <StyledTabs
      value={activeTabIndex}
      onChange={(e, index) => setActiveTabIndex(index)}
      indicatorColor="secondary"
      textColor="primary"
    >
      <StyledTab icon={<TimerOffIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_DowntimeCategory' })} />
      <StyledTab icon={<AvTimerIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_DowntimeSubcategory' })} />
    </StyledTabs>
  </PageTabs>

    {
      activeTabIndex === 0 &&
      <DowntimeCategoryListTable
        downtimeCategories={downtimeCategories ? downtimeCategories
          .sort(Utils.sortByTitle)
          .filter(d => d.id !== '1') : []}
        downtimeTypesFormatted={downtimeTypesFormatted}
        siteId={siteId}
        setRefreshRequired={setRefreshRequired}
      />
    }
    {
      activeTabIndex === 1 &&
      <DowntimeSubCategoryListTable
        downtimeCategories={downtimeCategories ? downtimeCategories
          .sort(Utils.sortByTitle)
          .filter(d => d.id !== '1') : []}
        siteId={siteId}
        setRefreshRequired={setRefreshRequired}
      />
    }
  </>
}

export { DowntimeSetupPage }
