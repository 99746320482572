import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import momentTimeZone from 'moment-timezone';
import { useAppLineState } from '../../../../context/AppContext/AppContext';

import { Logger } from '../../../../utilities/Logger/Logger';
import { useIntl } from 'react-intl';
import Utils from '../../../../utilities/utils';

import { LINE_FETCH_DELAY, LINE_IN_ONCE } from '../../../../constants/global';
import SiteReducerHelpers from '../../../../helpers/siteReducerHelpers';
import SiteApi from '../../../../api/prodSiteApi';
import LinesApi from '../../../../api/prodLineApi';
import ChartHelper from '../../../../components/Chart/ChartHelper';
import TimeInStateFilteredData from '../../../../utilities/TimeInStateFilteredData';
import { DowntimeDataHelper } from '../../../../helpers/downtimeDataHelper';
import { LinearProgressWithLabel } from '../../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import { Typography } from '@material-ui/core';
import { useDayLineMapLoaderStyles } from './DayLineMapLoader.css';
import {
  getDayText,
  getEndShiftHour,
  getStartShiftHour,
  formatDayLineMap,
  staggeredCall,
  getAndFormatLineProductionHours
} from '../ShiftHistoryUtility';

interface DayLineMapProps {
  initialReportState: any;
  timeRange: any;
  site: any;
  refreshDataIndex: number;
  filter: any;
  dayLineMapUpdated: (value: any) => void;
}

const DayLineMapLoader = ({
  timeRange,
  initialReportState,
  site,
  refreshDataIndex,
  filter,
  dayLineMapUpdated
}: DayLineMapProps) => {
  const classes = useDayLineMapLoaderStyles();
  const lines = useAppLineState();
  const intl = useIntl();
  const [reportState, setReportState] = useState<any>(initialReportState);

  const [dayLineMapInitialised, setDayLineMapInitialised] = useState<any>(null);
  const [dataContainer, setDataContainer] = useState<any>({
    dayLineMap: null
  });
  const [isDaylineMapLoading, setIsDaylineMapLoading] = useState<any>(false);
  const [noShiftOccurrences, setNoShiftOccurrences] = useState<any>(false);
  const [isDataInitialized, setIsDataInitialized] = useState(false);

  const incrementProgress = (stateName, caller) => {
    setReportState(state => ({
      ...state,
      [stateName]: state[stateName] + 1
    }));
  }

  const setLineDateGroupings = () => {
    const newLineDateGroupings = Object.values(dataContainer.dayLineMap).sort((a: any, b: any) => a.dayDt - b.dayDt)
      .map((dayGroup: any) => ({
        ...dayGroup.dayData,
        data: Object.values(dayGroup.lineData)
          .filter((grouping: any) => grouping && grouping.hourData && grouping.hourData.length > 0)
          .sort((a: any, b: any) => a.title - b.title),
      }));

    dayLineMapUpdated({
      dayLineMap: dataContainer.dayLineMap,
      noShiftOccurrences,
      lineDateGroupings: newLineDateGroupings,
      totalData: {
        oeeHours: getOeeTotalHours(newLineDateGroupings),
        downtime: getDowntimeTotalHours(newLineDateGroupings),
        microstops: getMicroStopsTotalHours(newLineDateGroupings),
      }
    })
  }

  const getOeeTotalHours = (days) => {
    // sum of hour collections for each line.
    return days.flatMap(day => day.data.flatMap(line => line.hourData.flat()))
      .filter(hour => hour && !hour.noData);
  }

  const getDowntimeTotalHours = (days) => {
    return days.flatMap(day => day.data.flatMap(line => line.downtimeData));
  }

  const getMicroStopsTotalHours = (days) => {
    return days.flatMap(day => day.data.flatMap(line => line.microStopData));
  }

  const loadShiftReportData = async () => {
    const selectedDays = Array.from(timeRange.by('day')).map((d, i) => ({
      text: getDayText(d, site.tz),
      value: i,
    })); // todo refactor, this format is a consequence of legacy code

    const dayText = (selectedDays.length === 1) ? selectedDays[0].text : undefined;
    const firstShiftHour = getStartShiftHour(reportState, site.tz, dayText); // start of the week
    const lastShiftHour = getEndShiftHour(reportState, site.tz, dayText); // end of the week

    if (firstShiftHour && lastShiftHour) {
      const lineMap = formatDayLineMap(filter, reportState, selectedDays, lines, site.tz);
      setDataContainer({
        dayLineMap: lineMap
      });
      setDayLineMapInitialised(true);
      setNoShiftOccurrences(false);
    } else {
      setNoShiftOccurrences(true);
    }
  }

  useEffect(() => {
    (async () => {
      if (dayLineMapInitialised && !isDaylineMapLoading) {
        setIsDaylineMapLoading(true);
        const dataLoads: Array<any> = [];
        const selectedDays = Array.from(timeRange.by('day')).map((d, i) => ({
          text: getDayText(d, site.tz),
          value: i,
        })); // todo refactor, this format is a consequence of legacy code

        let callIndex = 0;
        selectedDays.forEach((day) => {
          for (let lineIdx = 0; lineIdx < lines.length; lineIdx += LINE_IN_ONCE) {

            const lineIds = lines.slice(lineIdx, lineIdx + LINE_IN_ONCE).map(line => line.id);
            // PRODUCT COUNT DATA RETRIEVAL
            // values as follows: endDt, interval, lineIds, shiftIds, siteId, startDt. retrieving for all lines in the shift (filtering out selected lines after)
            // find the min and max times, from the associated occurrences on the lineData by selecting the min / max from each line, then reduce across all eligible lines
            // PRODUCT COUNT DATA RETRIEVAL
            // values as follows: endDt, interval, lineIds, shiftIds, siteId, startDt. retrieving for all lines in the shift (filtering out selected lines after)
            // find the min and max times, from the associated occurrences on the lineData by selecting the min / max from each line, then reduce across all eligible lines
            const lineEntries = Object.values(dataContainer.dayLineMap[day.text].lineData)
              .map((ld: any) => ({ start: new Date(Math.min(...ld.shiftOccurrences.map(so => so.start))), end: new Date(Math.max(...ld.shiftOccurrences.map(so => so.end))) }))
              //DE-1483 filter invalid times before reduce
              .filter((e: any) => isFinite(e.start) && isFinite(e.end));
            // early out on no entries
            if (!(lineEntries.length > 0)) {
              continue;
            }
            const { start, end } = lineEntries.reduce((s, agg) => ({ start: (agg.start < s.start ? agg.start : s.start), end: (agg.end > s.end ? agg.end : s.end) }));
            const startShift = start.toISOString();
            const endShift = end.toISOString();

            callIndex++;
            dataLoads.push(staggeredCall(loadOEEData, [site.id,
              startShift,
              endShift,
              lineIds,
              day], callIndex * LINE_FETCH_DELAY));

            callIndex++;
            dataLoads.push(staggeredCall(loadDowntimeData, [site.id,
              startShift,
              endShift,
              lineIds,
              day], callIndex * LINE_FETCH_DELAY));

            callIndex++;
            dataLoads.push(staggeredCall(loadTimeInStateData, [
              startShift,
              endShift,
              day.text,
              lineIds], callIndex * LINE_FETCH_DELAY));
          }
        });

        const progressApiTotal = dataLoads.length; // OEE,DT,TiS per day/line-batch
        const progressIngestTotal = dataLoads.length * LINE_IN_ONCE; // OEE,DT,TiS per day/line

        setReportState(state => ({
          ...state,
          progressApiTotal, // OEE,DT,TiS per day/line-batch
          progressIngestTotal, // OEE,DT,TiS per day/line
          progressApi: 0,
          progressIngest: 0,
        }));

        try {
          await Promise.all(dataLoads);
        } catch (err) {
          toastr.error(err.message);
        }

        setIsDataInitialized(true);
      }
    })();
  }, [dayLineMapInitialised]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataInitialized) {
      setLineDateGroupings();
    }
  }, [isDataInitialized]);// eslint-disable-line react-hooks/exhaustive-deps

  const mergeDayLineMapState = (dayText, lineId, lineData) => {
    const lineMap = dataContainer.dayLineMap;
    const dayGroup = lineMap[dayText] || {};
    const oldLineData = dayGroup.lineData[lineId] || {};
    dataContainer.dayLineMap = {
      ...lineMap,
      [dayText]: {
        ...dayGroup,
        lineData: {
          ...dayGroup.lineData,
          [lineId]: { ...oldLineData, ...lineData },
        },
      },
    };
  }

  const loadOEEData = (siteId, startDt, endDt, lineIds, day) => {
    if (startDt == null || endDt == null) {
      // no selected shifts, so no data request required
      incrementProgress('progressApi', 'oee');
      incrementProgress('progressIngest', 'oee');
      return Promise.resolve();
    }

    return SiteApi.getSiteOeeSummary({
      siteId,
      startDt,
      endDt,
      interval: 'h',
      lineIds,
      shiftIds: null,
      raw: false,
    }).then((results) => {
      incrementProgress('progressApi', 'oee');
      ingestOEEData(day, lineIds, results.data);
    }).catch((err) => {
      console.warn('loadOEEData error', err, { day, lineIds });
    });
  }

  const ingestOEEData = (day, lineIds, unformattedEvents) => {
    const formattedEvents = SiteReducerHelpers.formatHourOrDayOEE(
      unformattedEvents,
      'dateHour',
      'YYYY-MM-DD-HH',
      'h',
      site.tz,
    );
    const flattenedOeeData = formattedEvents.flatMap(hour => hour.details);
    const now = momentTimeZone.utc();

    const dayGroup = dataContainer.dayLineMap[day.text];
    if (!dayGroup) return;

    lineIds.forEach((lineId) => {
      const lineData = dayGroup.lineData[lineId];
      if (lineData) {
        const hourData = getAndFormatLineProductionHours(lineId, flattenedOeeData, day, lineData.shiftOccurrences, lines, site.tz);
        const hourDataFromOee = hourData.filter(hour => hour && !hour.noData);
        const noPrinterAssignedToLineForEntireTimeFrame = hourData.length > 0 && hourDataFromOee.length === 0 && !(timeRange.contains(now) && lineData.line.printerId);
        const noPrinterAssignedToLineForPartOfTimeFrame = hourData.length > 0 && hourDataFromOee.length > 0 && hourDataFromOee.length !== hourData.length;

        mergeDayLineMapState(day.text, lineId, {
          hourData,
          hourDataFromOee,
          // if noPrinter flags set elsewhere, a false value takes precedence
          noPrinterAssignedToLineForEntireTimeFrame: lineData.noPrinterAssignedToLineForEntireTimeFrame !== false && noPrinterAssignedToLineForEntireTimeFrame,
          noPrinterAssignedToLineForPartOfTimeFrame: lineData.noPrinterAssignedToLineForPartOfTimeFrame !== false && noPrinterAssignedToLineForPartOfTimeFrame,
        });
      } else {
        console.warn(`Received OEE data which was not prepared for, ${day.text} ${lineId}`);
      }
      incrementProgress('progressIngest', 'ingestOEEData');
    });
  }

  const downtimeHourFallsBetweenSelectedShiftTimes = (downtime, earliestStartShiftHour, latestEndShiftHour) => {
    // check that hour of the downtime is correct since downtimes are returned in hour increments. It's filtered by shift slices afterward
    return (downtime.timeStamp >= earliestStartShiftHour || (downtime.timeStamp.getDay() === earliestStartShiftHour.getDay() && downtime.timeStamp.getHours() === earliestStartShiftHour.getHours()))
      && downtime.timeStamp < latestEndShiftHour;
  }

  const getTotalDowntimeHoursWithPrinterDisconnects = (lineId, flattenedDowntimeData, earliestStartShiftHour, latestEndShiftHour, selectedShiftOccurrencesForLineAndDay) => {
    const filteredByLine = flattenedDowntimeData.filter(downtime => downtime.lineId === lineId && downtimeHourFallsBetweenSelectedShiftTimes(downtime, earliestStartShiftHour, latestEndShiftHour));

    return filteredByLine.flatMap(downtimeEvent => (downtimeEvent.slices)
      .filter((slicedEvent) => {
        // transfer the reason id so we can map it to the category correctly
        slicedEvent.sliceDtReasonId = downtimeEvent.reasonId;
        // filter the slices to only display downtimes with the id of a selected shift
        return selectedShiftOccurrencesForLineAndDay.find(occurrence => occurrence.shiftId === slicedEvent.sliceKey);
      }));
  }

  const getAndFormatLineDowntimeHours = (lineId, flattenedDowntimeData, selectedShiftOccurrencesForLineAndDay) => {
    const downtimeDataArgs = {
      downtimeData: flattenedDowntimeData,
      shifts: selectedShiftOccurrencesForLineAndDay.map(shift => shift.shiftId),
      site,
    };
    return new DowntimeDataHelper(downtimeDataArgs).slicedDonut(lineId, true, intl);
  }


  const getLineMicroStopsHours = (lineId, flattenedMicroStopData, earliestStartShiftHour, latestEndShiftHour, selectedShiftOccurrencesForLineAndDay) => {
    const filteredSectionsToLine = flattenedMicroStopData.filter(downtime => downtime.lineId === lineId
      && downtime.timeStamp >= earliestStartShiftHour && downtime.timeStamp < latestEndShiftHour);
    const notAsFilteredSectionsToLine = flattenedMicroStopData.filter(downtime => downtime.lineId === lineId);
    Logger.of('App.getLineMicroStopsHours').info('filtered Sections To Line', filteredSectionsToLine);
    Logger.of('App.getLineMicroStopsHours').info('filtered Sections To Line', notAsFilteredSectionsToLine);
    return notAsFilteredSectionsToLine.flatMap(microstop => (microstop.slices)
      .filter(slicedEvent => selectedShiftOccurrencesForLineAndDay.find(occurrence => occurrence.shiftId === slicedEvent.sliceKey)));
  }

  const loadDowntimeData = (siteId, startDt, endDt, lineIds, day) => {
    if (startDt == null || endDt == null) {
      // no selected shifts, so no data request required
      incrementProgress('progressApi', 'downtime');
      incrementProgress('progressIngest', 'downtime');
      return Promise.resolve();
    }

    return SiteApi.getSiteDowntimeSummary(
      endDt,
      'h',
      lineIds,
      null,
      siteId,
      startDt,
      true,
      true,
    ).then((results) => {
      incrementProgress('progressApi', 'downtime');
      ingestDowntimeData(day, lineIds, results);
    }).catch((err) => {
      console.warn('loadDowntimeData error', err, { day, lineIds });
    });
  }

  const ingestDowntimeData = (day, lineIds, unformattedEvents) => {
    const flattenedMicroStopData = (unformattedEvents.microStops)
      ? unformattedEvents.microStops.flatMap((hour) => {
        hour.details.forEach((section) => {
          section.timeStamp = new Date(hour.startDt);
        });
        return hour.details;
      })
      : [];


    const flattenedDowntimeData = (unformattedEvents.data)
      ? unformattedEvents.data.flatMap((hour) => {
        hour.details.flatMap((section) => {
          section.timeStamp = new Date(hour.startDt);
          section.siteTimeStamp = Utils.getLocalSiteDateObject(new Date(hour.startDt), site.tz);
        });
        return hour.details;
      })
      : [];

    const dayGroup = dataContainer.dayLineMap[day.text];
    if (!dayGroup) return;

    lineIds.forEach((lineId) => {
      const lineData = dayGroup.lineData[lineId];
      if (lineData && lineData.shiftOccurrences.length > 0) {
        const earliestStartShiftHour = lineData.shiftOccurrences.sort((a, b) => a.start - b.start)[0].start;
        const latestEndShiftHour = lineData.shiftOccurrences.sort((a, b) => b.end - a.end)[0].end;
        const downtimeData = getAndFormatLineDowntimeHours(lineId, unformattedEvents.data, lineData.shiftOccurrences);
        const downtimeWithPrinterDisconnects = getTotalDowntimeHoursWithPrinterDisconnects(lineId, flattenedDowntimeData, earliestStartShiftHour, latestEndShiftHour, lineData.shiftOccurrences);
        const microStopData = getLineMicroStopsHours(lineId, flattenedMicroStopData, earliestStartShiftHour, latestEndShiftHour, lineData.shiftOccurrences);
        const noPrinterAssignedToLineForEntireTimeFrame = downtimeWithPrinterDisconnects.length === 0;
        const noPrinterAssignedToLineForPartOfTimeFrame = downtimeWithPrinterDisconnects.length === 0;

        mergeDayLineMapState(day.text, lineId, {
          downtimeData,
          microStopData,
          // if noPrinter flags set elsewhere, a false value takes precedence
          noPrinterAssignedToLineForEntireTimeFrame: lineData.noPrinterAssignedToLineForEntireTimeFrame !== false && noPrinterAssignedToLineForEntireTimeFrame,
          noPrinterAssignedToLineForPartOfTimeFrame: lineData.noPrinterAssignedToLineForPartOfTimeFrame !== false && noPrinterAssignedToLineForPartOfTimeFrame,
        });
      } else {
        console.warn(`Received Downtime data which was not prepared for, ${day.text} ${lineId}`);
      }
      incrementProgress('progressIngest', 'ingestDowntimeData');
    });
  }

  const loadTimeInStateData = (startTime, endTime, dayText, lineIds) => {
    return LinesApi.getLineTimeInStateForAllShifts(
      site.id,
      lineIds,
      startTime,
      endTime,
      site.slowCycleThreshold,
    )
      .then((tis) => {
        incrementProgress('progressApi', 'timeInState');
        ingestTimeInStateData(dayText, lineIds, tis);
      }).catch((err) => {
        console.warn('loadTimeInStateData error', err, { dayText, lineIds });
      });
  }

  const ingestTimeInStateData = (dayText, lineIds, result) => {
    lineIds.forEach((lineId) => {
      const timeInState = (lineIds.length === 1 && !result[lineId]) ? result : result[lineId];
      const { shiftOccurrences } = dataContainer.dayLineMap[dayText].lineData[lineId];
      if (timeInState && shiftOccurrences.length > 0) {
        const filter = new TimeInStateFilteredData(timeInState.timeLine, shiftOccurrences);
        filter.filterTimeInStateData();
        const mergedTimeLine = ChartHelper.mergeTimeLine(filter.displayedTimeInState, timeInState.slowCycles);
        mergeDayLineMapState(dayText, lineId, {
          filteredTimeline: filter.displayedTimeInState,
          slowCycles: timeInState.slowCycles,
          mergedTimeLine,
        });
      } else if (shiftOccurrences.length > 0) {
        console.warn(`Expected but did not find time in state data for ${dayText} ${lineId}`);
      }
      incrementProgress('progressIngest', 'ingestTimeInStateData');
    });
  }

  useEffect(() => {
    loadShiftReportData();
  }, [refreshDataIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  return reportState.progressApiTotal ? <div>
    <div className={classes.overlay}></div>
    <LinearProgressWithLabel
      label={(reportState.progressApi / reportState.progressApiTotal >= 1)
        ? intl.formatMessage({ id: 'detail_RetrievedData' }) : intl.formatMessage({ id: 'detail_RetrievingDataEllipsis' })}
      progressValue={reportState.progressApi}
      maxValue={reportState.progressApiTotal} />

    <LinearProgressWithLabel
      label={(reportState.progressIngest / reportState.progressIngestTotal >= 1)
        ? intl.formatMessage({ id: 'detail_Processed' }) : intl.formatMessage({ id: 'detail_ProcessingDataEllipsis' })}
      progressValue={reportState.progressIngest}
      maxValue={reportState.progressIngestTotal}
      hideProgressLabel={true} />

  </div> : <div className={classes.noShiftFound}>
    <Typography variant="h6">{noShiftOccurrences ? intl.formatMessage({ id: 'detail_No_shifts' }) : ''}</Typography>
  </div>;
};

export { DayLineMapLoader }
