import { makeStyles } from "@material-ui/styles";
import { mySecondaryColor, myWhite } from "../../constants/colors";

const drawerWidth = 320;

export default makeStyles((theme: any) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 20
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    border: 0,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listContainer: {
    backgroundColor: mySecondaryColor,
    paddingTop: theme.spacing(1)
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      position: 'absolute',
      top: theme.spacing(0.5)
    },
  },
  mobileBackButtonIcon: {
    color: myWhite,
  },
  logoIcon: {
    width: theme.spacing(5),
    margin: 0,
    padding: 0,
    background: 'transparent'
  }
}));
