import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useProductListRowStyles } from './ProductListRow.css';
import { Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import Can from '../../../components/Common/Can';

interface IProductListRow {
  product: any;
  onAssign: any;
  makeLinesListForDisplay: any;
  siteId: string;
  isDev: boolean;
  insightUser: boolean;
  gridBreak: any;
  onDeleteProduct: (product: any) => void;
}

const ProductListRow = ({ product,
  makeLinesListForDisplay,
  isDev,
  insightUser,
  siteId,
  gridBreak,
  onDeleteProduct }: IProductListRow) => {
  const classes = useProductListRowStyles();
  const intl = useIntl();

  return (
    <StyledGridRow>
      <StyledGridCell {...gridBreak.product} >
        <div className={classes.productTitleContainer}>
          <div className={classes.imageHolder}>
            {
              product.imageBase64
                ? <img src={`data:image/jpeg;${product.imageBase64}`} className={classes.productImage} alt="" />
                : <ImageIcon className={classes.productImage} />
            }
          </div>
          <div className="productTitle">
            <div><strong>{product.title}</strong></div>
            <div><em>{product.description}</em></div>
          </div>
        </div>
      </StyledGridCell>
      {
        isDev && <StyledGridCell {...gridBreak.productId} >
          <div>
            {product.productIdentifier}
          </div>
        </StyledGridCell>
      }
      {
        insightUser && <StyledGridCell {...gridBreak.lineTitles} >
          <div>
            <em>{makeLinesListForDisplay(product.lineTitles)}</em>
          </div>
        </StyledGridCell>
      }
      { insightUser && <StyledGridCell {...gridBreak.runRate} >
        <div>
          <strong>
            {product.runRate}
            {' '}
            u/h
          </strong>
        </div>
      </StyledGridCell>
      }
      {
        insightUser &&
        <StyledGridCell {...gridBreak.bestRate} >
          <div>
            <strong>
              {product.bestRate}
              {' '}
            u/h
          </strong>
          </div>
        </StyledGridCell>
      }
      {
        insightUser && <StyledGridCell {...gridBreak.quality} >
          <div>
            <strong>{product.quantity}</strong>
          </div>
        </StyledGridCell>
      }
      <StyledGridCell {...gridBreak.actionButtons} >
        <StyledActionContainer breaks={{ sm: true }}>
          <Can do="edit" on="product">
            <Link to={`/site/${siteId}/setup/product/${product.id}/edit`}
            >
              <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
                <ActionButton>
                  <EditIcon />
                </ActionButton>
              </Tooltip>
            </Link>
          </Can>
          <Can do="edit" on="product">
            <Link to={`/site/${siteId}/setup/product/${product.id}/clone`}
            >
              <Tooltip title={intl.formatMessage({ id: 'detail_Clone' })}>
                <ActionButton>
                  <FileCopyIcon />
                </ActionButton>
              </Tooltip>
            </Link>
          </Can>
          <Can do="edit" on="product">
          <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
            <ActionButton
              onClick={() => onDeleteProduct(product)}>
              <DeleteIcon />
            </ActionButton>
          </Tooltip>
          </Can>
        </StyledActionContainer>
      </StyledGridCell>
    </StyledGridRow>
  )
};

export default ProductListRow;
