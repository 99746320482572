import React, { useEffect, useState } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { Tune as TuneIcon } from '@material-ui/icons';
import { validation } from 'vccm-common';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useInspectionSettingsPageStyles } from './InspectionSettingsPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  Button,
  CardActions,
  CardContent,
  FormControl
} from '@material-ui/core';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { useSiteActions } from '../../../actions/siteActions';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import Can from '../../../components/Common/Can';

interface InspectionSettingsPageProps {
  match: any;
}

const InspectionSettingsPage = ({ match }: InspectionSettingsPageProps) => {
  const classes = useInspectionSettingsPageStyles();

  const intl = useIntl();
  const { sites } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const siteActions = useSiteActions();

  const siteId: string = match.params.siteId;

  const [assessmentExpiration, setAssessmentExpiration] = useState<number | undefined>(undefined);
  const [data, setData] = useState<any>();
  const [error, setError] = useState();
  const [saving, setSaving] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);

  // get the data for the current site, \/ es lint ignore on required empty array arg
  // noinspection JSCheckFunctionSignatures
  useEffect(() => {
    let ignore = false;
    // it's okay to define and call an async func, but you cannot make effect async!
    (async () => {
      try {
        if (!ignore) {
          setIsLoading(true);
          const loadedSite = await siteActions.loadSite(siteId, true);
          if (loadedSite) {
            if (loadedSite.complianceSettings) {
              setAssessmentExpiration(loadedSite.complianceSettings.assessmentExpirationMonths);
            }
            setDirty(false);
            setData(loadedSite || {});
          }
        }
      } catch (e) {
        setError(e);
      } finally {
        if (!ignore) {
          setIsLoading(false);
        }
      }
    })(); // call immediately
    // return the cleanup function
    return () => { ignore = true; };
  }, [siteActions, siteId]);

  async function saveSite() {
    if (data) {
      setSaving(true);
      try {
        if (!data.complianceSettings) {
          data.complianceSettings = {};
        }
        data.complianceSettings.assessmentExpirationMonths = assessmentExpiration;
        await siteActions.saveSite(data, true);
        toastr.success(intl.formatMessage(({ id: 'detail_AssessmentExpirationSaved' })));
      } catch (e) {
        toastr.error(e.message ? e.message : e);
      } finally {
        setSaving(false);
      }
    }
  }

  function cancelEdit() {
    setAssessmentExpiration(data && data.complianceSettings && data.complianceSettings.assessmentExpirationMonths);
  }

  // if state we care changes, reevaluate dirty
  useEffect(() => {
    // abort early if dirty state changed
    const settings = data && data.complianceSettings;
    setDirty(!settings || settings.assessmentExpirationMonths !== assessmentExpiration);

  }, [data && data.complianceSettings && data.complianceSettings.assessmentExpirationMonths, assessmentExpiration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTitle('detail_InspectionSettings', <TuneIcon />, {
      parents: [{
        id: 'detail_Configuration',
      }]
    });
  }, [setTitle]);

  return !isLoading && sites.length > 0 ? <Can do="view" on="inspection" passThrough>
    {can => can ? <>
      <StyledContentCard>
        <CardContent className={classes.content}>
          <FormControl variant="outlined" className={classes.formControl}>
            <div>
              <div className={classes.textLabel}>{intl.formatMessage({ id: "detail_AssessmentExpiration" })}</div>
              <NumericTextBox
                name="AssessmentExpiration"
                defaultValue={validation.dashConfigLimits.default}
                onChange={(e) => setAssessmentExpiration(Number(e.target.value))}
                value={assessmentExpiration}
                format="n0"
                min={validation.dashConfigLimits.min}
                max={validation.dashConfigLimits.max}
              />
            </div>
          </FormControl>
          {
            error && <h4 className={classes.error}>{error}</h4>
          }
        </CardContent>
        <CardActions className={classes.actionButtons}>
          <Button
            variant="contained"
            disabled={saving || !dirty}
            onClick={cancelEdit}>
            <FormattedMessage id="detail_Cancel" />
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={saving || !dirty}
            onClick={saveSite}
          >
            <FormattedMessage id="detail_Save" />
          </Button>
        </CardActions>
      </StyledContentCard>
    </> : <div> <FormattedMessage id="detail_NotAuthorizedToDoAnything" /></div>
    }
  </Can> : <ProgressIndicator full={true} />
};

export default InspectionSettingsPage;
