import { makeStyles } from '@material-ui/core';
import { mySecondaryColor, myWhite } from '../../constants/colors';

export const useSearchableTimezoneSelectorStyle = makeStyles((theme) => ({
  mainBox: {
    marginBottom: theme.spacing(2.75)
  },
  label: {
    marginBottom: theme.spacing(0.5)
  },
  textRoot: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.5)
  },
  paper: {
    margin: 0,
    fontSize: theme.spacing(1.625)
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  timeZoneItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  timeZoneItemLabel: {
    backgroundColor: mySecondaryColor,
    color: myWhite,
    borderRadius: '6px',
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.125, 1.125),
    marginRight: theme.spacing(1.125)
  },
  timeZoneItemPart1: {
    display: 'flex',
  },
  deleteItem: {
    position: 'relative',
    left: '50px'
  }
}));
