import Moment from "moment";
import momentTimeZone from 'moment-timezone';
import SchedulerHelper from '../SchedulerHelper/SchedulerHelper';
import DateHelper from '../DateHelper/DateHelper';

const formatString = (value: string, ...rest) => {
  let a = value;
  rest.forEach((item, index) => {
    a = a.replace(`{${index}}`, item);
  });
  return a;
}

const DateTimeParts = {
  YEAR: 0,
  MONTH: 1,
  DAY: 2,
  HOUR: 3,
  MINUTE: 4,
  SECOND: 5,
};

class LineHelper {

  static getColors = (rates) => {
    const { instant } = rates;
    const bestRate = Number(rates.bestRate);
    const runRate = Number(rates.runRate);
    // Red: 0-50% of target
    const red = runRate * 0.5;
    // Orange: 50%-99% of target
    const orange = runRate * 0.99;
    // Black: rate < target + (best-target)/2
    const black = runRate + (bestRate - runRate) / 2;
    // Green: rate > = target + (best-target)/2
    // Violet: > best rate

    // normalize on bestRate to get a percentage
    const score = (instant / bestRate) * 100;
    // percent thresholds
    const orangeScoreThreshold = red / bestRate * 100;
    const blackScoreThreshold = orange / bestRate * 100;
    let greenScoreThreshold = black / bestRate * 100;
    if (greenScoreThreshold > 99.99) { greenScoreThreshold = 99.99; }
    const violetScoreThreshold = 99.99;
    // color bands
    const colors = [
      {
        to: orangeScoreThreshold,
        color: 'red',
      }, {
        from: orangeScoreThreshold,
        to: blackScoreThreshold,
        color: 'orange',
      }, {
        from: blackScoreThreshold,
        to: greenScoreThreshold,
        color: 'black',
      }, {
        from: greenScoreThreshold,
        to: violetScoreThreshold, // = bestRate / bestRate * 100
        color: 'green',
      }, {
        from: violetScoreThreshold, // = bestRate / bestRate * 100
        color: 'violet',
      },
    ];

    // classify this rate
    const rateColor = (s) => {
      if (s > 99.99) return 'violet';
      if (s > greenScoreThreshold) return 'green';
      if (s > blackScoreThreshold) return 'black';
      if (s > orangeScoreThreshold) return 'orange';
      return 'red';
    };

    const rVal = {
      bestRate,
      instant,
      colors,
      rateColor: rateColor(score),
      score,
    };
    return rVal;
  }

  static formatHourEventsFromOverviewResponse(response) {
    const formattedHourEvents = response.oeeHours.map((item) => {
      const dateTimeParts = item.dateHour.split('-');
      return ({
        count: item.count || 0,
        plannedMinutes: item.plannedMinutes || 0,
        unplannedStoppedMinutes: item.unplannedStoppedMinutes || 0,
        runningMinutes: item.plannedMinutes + item.unplannedStoppedMinutes,
        reportedMinutes: item.reportedMinutes || 0,
        currentTargetCount: item.currentTargetCount || 0,
        badPieceCount: item.badPieceCount || 0,
        dateHour: item.dateHour,
        // change date from "2018-02-21-15" to "2018-02-21T15:00:00"
        timeStamp: Moment(formatString('{0}-{1}-{2}T{3}:00:00Z', dateTimeParts[DateTimeParts.YEAR],
          dateTimeParts[DateTimeParts.MONTH], dateTimeParts[DateTimeParts.DAY],
          dateTimeParts[DateTimeParts.HOUR])).toDate(),
        oee: item.oee || 0,
        performance: item.performance || 0,
        availability: item.availability || 0,
        quality: item.quality || 1,
        totalCounter: 0,
        ingestVersion: item.ingestVersion,
        scheduledRunMinutes: item.scheduledRunMinutes

      });
    })

    // Sort by timeStamp
    const sortedFormattedHourEvents = formattedHourEvents.sort((a, b) => a.timeStamp - b.timeStamp);
    const eventCount = sortedFormattedHourEvents.length;

    // get running minutes
    // todo: might not need total
    const total = sortedFormattedHourEvents.reduce(
      (sums, event) => {
        Object.keys(sums).forEach((key) => {
          if (event.hasOwnProperty(key) && key !== 'ingestVersion') sums[key] += event[key]; // eslint-disable-line no-prototype-builtins
        });
        sums.runningHours += event.count > 0 ? 1 : 0;
        sums.ingestVersion = sums.ingestVersion || event.ingestVersion;
        return sums;
      },
      {
        count: 0,
        runningHours: 0,
        runningMinutes: 0,
        reportedMinutes: 0,
        plannedMinutes: 0,
        currentTargetCount: 0,
        badPieceCount: 0,
      },
    );

    const currentHour = sortedFormattedHourEvents.slice(-1)[0];
    return {
      assignedProductRun: response.product && response.product.run,
      assignedProductId: response.product && response.product.id,
      count: total.count,
      currentHour: (eventCount > 1) ? currentHour.count : total.count,
      lastHour: sortedFormattedHourEvents.length > 1 ? (sortedFormattedHourEvents.slice(-2)[0].count || 0) : 0,
      lastUpdate: eventCount > 1 ? currentHour.timeStamp : '',
      oeeHour: sortedFormattedHourEvents,
      printerId: response.printerId,
      shiftAvailability: response.oee.availability * 100,
      shiftCount: response.oee.count,
      shiftEff: response.oee.oee,
      shiftOee: response.oee.oee * 100,
      shiftPerformance: response.oee.performance * 100,
      shiftQuality: response.oee.quality * 100,
      shiftTargets: {
        produced: response.production && response.production.actual,
        planned: response.production && response.production.planned,
        remaining: response.product && response.product.currentRemaining,
        rates: {
          assignedProductRun: response.product && response.product.run,
          assignedProductId: response.product && response.product.id,
          instant: (response.production && response.production.currentRates && response.production.currentRates.instant),
          bestRate: (response.production && response.production.currentRates && response.production.currentRates.bestRate),
          runRate: (response.production && response.production.currentRates && response.production.currentRates.runRate),
        }
      },
      targetCount: response.production && response.production.planned
    };
  }

  static formatTimeInStateFromResponse(response) {
    const { timeLine, slowCycles } = response;

    // reverse sort and put nulls at the end
    const ts = timeLine
      .slice(0)
      .sort((a, b) => (a.endTime === null ? 0 : 1) - (b.endTime === null ? 0 : 1)
        || -(a.endTime > b.endTime) || +(a.endTime < b.endTime));
    const nowIndex = ts.findIndex(tls => tls.type === 'now');
    const nowSegmentStartTime = nowIndex >= 0 ? ts[nowIndex].startTime : null;
    const timeLineSegmentNow = nowIndex <= (ts.length - 2) ? ts[nowIndex + 1] : undefined;

    return {
      timeLine,
      nowSegmentStartTime,
      currentSegment: timeLineSegmentNow,
      slowCycles,
    }
  }

  static getRemaining(assignedProductRun, shiftTargets) {
    if (assignedProductRun) {
      if (assignedProductRun.endDt) return 0;
      if (shiftTargets.remaining >= 0) return shiftTargets.remaining;
      return assignedProductRun.targetCount;
    }
    return shiftTargets.remaining;
  }

  static getLineById(lines, id) {
    const line = lines.find(l => l.id === id);
    return line || null;
  }

  /**
   *
   * @param {Object} line - line to determine state for
   * @returns {{description: string, diff: string, state: string, color:string}} - status of the line
   */
  static getStatus(line): any {
    let retVal = {};
    let retColor;
    const nowSegmentStartTime = line && line.timeInState && line.timeInState.nowSegmentStartTime;
    const currentState = line && line.timeInState && line.timeInState.currentSegment;
    const timeLine = line && line.timeInState && line.timeInState.timeLine;
    if (!currentState || !timeLine) return undefined;
    const stateStart = momentTimeZone(currentState.startTime).utc();
    const nowLocal = momentTimeZone.utc();
    const now = nowSegmentStartTime ? momentTimeZone(nowSegmentStartTime).utc() : nowLocal;
    const endOfTimeLine = momentTimeZone(timeLine[timeLine.length - 1].endTime || now).utc();
    const diff = now.diff(stateStart);
    if (now.isAfter(endOfTimeLine)) {
      const diffString = `${DateHelper.humanizeDuration(momentTimeZone.duration(diff))}`
      retVal = {
        description: `After shift ${diffString}`,
        state: 'after',
        diff: diffString,
        color: 'green'
      };
      return retVal;
    }
    let statusType;
    switch (currentState.type) {
      case 'schedule':
        statusType = SchedulerHelper.getEventTypeById(currentState.typeId);
        retColor = currentState.typeId === 1 ? 'green' : 'yellow';
        break;
      case 'downtime':
        statusType = 'stopped';
        retColor = 'red'
        break;
      case 'microStop':
        statusType = 'stopped';
        retColor = 'red'
        break;
      case 'micro':
        statusType = 'stopped';
        retColor = 'red'
        break;
      default:
        statusType = 'unknown';
        retColor = 'black'
        break;
    }
    const statusText = `${statusType && statusType[0].toUpperCase() + statusType.slice(1)} ${DateHelper.humanizeDuration(momentTimeZone.duration(diff))}`;
    retVal = {
      description: statusText,
      diff: `${DateHelper.humanizeDuration(momentTimeZone.duration(diff))}`,
      state: statusType,
      color: retColor
    };
    return retVal;
  }

  static getLineIdByTitle(lines, title) {
    if (!title) return undefined;
    const line = lines.find(l => l.title === title.trim());
    if (line) {
      return line.id;
    }
    return undefined;
  }

  static getIsDeviceInUse(deviceAssignments, deviceId) {
    if (deviceAssignments) {
      const inUse = deviceAssignments.some(p => p.deviceId === deviceId);
      return inUse;
    }
    return false;
  }

  static getRolePrinters(lines, roleId) {
    const printerIds: Array<any> = [];
    if (lines !== undefined) {
      lines.forEach((line) => {
        if (line.deviceAssignments !== undefined) {
          const filteredPrinterIds = line.deviceAssignments.map((deviceAssignment) => {
            if (deviceAssignment.roleId === roleId) {
              return deviceAssignment.deviceId;
            }

            return null;
          });

          filteredPrinterIds.forEach((printerId) => {
            if (printerIds.indexOf(printerId) === -1) {
              if (printerId !== undefined) {
                printerIds.push(printerId);
              }
            }
          });
        }
      });
    }
    return printerIds;
  }
}

export default LineHelper;
