import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useReportNotificationListRowStyles } from './ReportNotificationListRow.css';
import { Accordion, AccordionDetails, AccordionSummary, Card, Tooltip, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { freqTypes } from '../Commons/FreqTypes';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import StyledGridRow from '../../../components/Basic/StyledGridRow/StyledGridRow';
import Can from '../../../components/Common/Can';

const days = moment.weekdays().reduce((obj, day, i, weeks) => ({
  ...obj,
  [day]: {
    short: moment.weekdaysShort(weeks.indexOf(day)),
    min: moment.weekdaysMin(weeks.indexOf(day))
  },
}), {});

interface IReportNotificationListRow {
  sr: any;
  localReports: any;
  siteReports: string;
  title: string;
  siteId: string;
  userId: string;
  gridBreak: any;
  onDeleteSchedule: (sr: any) => void;
}

const ReportNotificationListRow = ({
  sr,
  localReports,
  siteReports,
  title,
  siteId,
  userId,
  gridBreak,
  onDeleteSchedule }: IReportNotificationListRow) => {
  const classes = useReportNotificationListRowStyles();
  const intl = useIntl();
  const history = useHistory();

  const [expanded, setExpanded] = useState('');

  const canDelete = true;
  const active = sr.schedule && sr.schedule.active === true;
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onEditSchedule = () => {
    history.push(`/site/${siteId}/scheduledReport/${userId}/${title}/edit`);
  }
  // sr.distributedBySort = getUserEmailFromList(users, sr.distributedBy);
  const panels = [
    {
      key: 'localReports',
      title: localReports && localReports.length > 0 && `${localReports.length} ${intl.formatMessage({ id: 'detail_LocalReports' })}`,
      content: localReports && localReports.length > 0 && Array.from(localReports)
        .map((lr: any) => (<Card key={lr} className={classes.reportOptions}><div className={classes.reportOptionInner}>{lr}</div></Card>)),
    },
    {
      key: 'siteReports',
      title: siteReports && siteReports.length > 0 && `${siteReports.length} ${intl.formatMessage({ id: 'detail_GlobalReports' })}`,
      content: siteReports && siteReports.length > 0 && Array.from(siteReports)
        .map((lr: any) => (<Card key={lr} className={classes.reportOptions}><div className={classes.reportOptionInner}>{lr}</div></Card>)),
    },
  ].map((el, index) => el.content && <Accordion
    square
    key={`${el.key}-${index}`}
    className={classes.reportAccordion}
    expanded={expanded === `${el.key}-${index}`}
    onChange={handleChange(`${el.key}-${index}`)}>
    <AccordionSummary
      aria-controls={`${el.key}-content`}
      id={`${el.key}-header`}
      expandIcon={<ExpandMoreIcon />}
      classes={{ root: classes.summary, content: classes.summaryContent, expandIcon: classes.summaryIcon }}
    >
      <Typography component="div">{el.title}</Typography>
    </AccordionSummary>
    <AccordionDetails className={classes.reportOptionsContainer}>
      <div className={classes.reportOptionsContainer}>{el.content}</div>
    </AccordionDetails>
  </Accordion>);

  return (
    <StyledGridRow>
      <StyledGridCell {...gridBreak.active}>
        <FormattedMessage id={active ? 'detail_Active' : 'detail_Deactivated'} />
      </StyledGridCell>
      <StyledGridCell {...gridBreak.title}>
        {title}
      </StyledGridCell>

      <StyledGridCell {...gridBreak.reports}>
        {panels}
      </StyledGridCell>

      <StyledGridCell {...gridBreak.schedule}>
        <div>
          <div><FormattedMessage id={freqTypes[sr.schedule.freq].transId} /></div>
          <div className={classes.scheduleOptionsContainer}>
            {(
              !Array.isArray(sr.schedule.options)
                ? [sr.schedule.options]
                : sr.schedule.options.map(m => days[m].min)
            )
              .map(o => (<Card key={o} className={classes.scheduleOptions}><div className={classes.scheduleOptionInner}>{o}</div></Card>))
            }
          </div>
        </div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons}>
        <Can do="view" on="report">
          <StyledActionContainer breaks={{ md: true }}>
              <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}
              onClick={() => onEditSchedule()}>
                <ActionButton>
                  <EditIcon />
                </ActionButton>
              </Tooltip>

            <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
              <ActionButton
              disabled={!canDelete}
              onClick={() => onDeleteSchedule(sr)}>
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </StyledActionContainer>
        </Can>
      </StyledGridCell>
    </StyledGridRow>
  )
};

export default ReportNotificationListRow;
