import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { useShiftActions } from '../../../../actions/shiftActions';
import { DeleteDialog } from '../../../../components/DeleteDialog/DeleteDialog';

interface IShiftDeleteProps {
  isDeleteConfirmVisible: boolean;
  shift: any;
  setDeleteConfirmVisible: Dispatch<SetStateAction<boolean>>;
}

const ShiftDelete = ({
  isDeleteConfirmVisible,
  setDeleteConfirmVisible,
  shift }: IShiftDeleteProps) => {
  const intl = useIntl();

  const shiftActions = useShiftActions();
  const [saving, setSaving] = useState(false);

  const onCloseDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);
        shiftActions.deleteShiftEvent(shift.siteId, shift.id)
          .then(() => {
            toastr.success(`${shift.title} ${intl.formatMessage({ id: 'detail_Deleted' })}`);
            setSaving(false);
          })
          .catch((error) => {
            toastr.error(error);
            setSaving(false);
          });

      } catch (e) {
        toastr.error(e.toString());
      }
    }
    setDeleteConfirmVisible(false);
  }

  return <DeleteDialog
    saving={saving}
    title={<FormattedMessage id="detail_PleaseConfirm" />}
    open={isDeleteConfirmVisible}
    onClose={onCloseDeleteDialog}
    extraContent={<div>{shift.title} {shift.description ? `-${shift.description}` : ''}</div>}
  />;

};

export { ShiftDelete };
