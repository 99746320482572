import { makeStyles } from "@material-ui/core";

export const useLinearProgressWithLabelStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(2),
    margin: theme.spacing(2, 0,0,0),
    borderRadius: '10px'
  },
  label: {
    textAlign: 'center',
    margin: '0px'
  },
  bar: {
    margin: '0px'
  }
}));
