import React, { Dispatch, SetStateAction } from 'react';
import toastr from 'toastr';
import csvtojsonV2 from 'csvtojson/v2';
import {
  Button,
} from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import { useCsvImportSelectorStyle } from './CsvImportSelector.css';

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
}

function arrayBufferToString(arrayBuffer, decoderType = 'utf-8') {
  const decoder = new TextDecoder(decoderType);
  return decoder.decode(arrayBuffer);
}
interface ICsvImportSelectorProps {
  csvUploadId: string;
  disabled: boolean;
  buttonIcon: any;
  buttonTextId: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onCsvFileImported: (fileInfo, newlyImportedItems) => void;
}

const CsvImportSelector = ({
  csvUploadId,
  disabled,
  buttonIcon,
  buttonTextId,
  setLoading,
  onCsvFileImported }: ICsvImportSelectorProps) => {
  const classes = useCsvImportSelectorStyle();

  const createItemPreview = (fileInfo, items) => {
    onCsvFileImported(fileInfo, items);
  }

  const csvUploadChange = () => {
    const cvsUpload = document.getElementById(csvUploadId);
    if (cvsUpload) {
      const [fileInfo] = cvsUpload['files'];
      setLoading(true);
      if (fileInfo) {
        (async () => {
          try {
            const arrayBuffer: any = await readFileAsync(fileInfo);
            csvtojsonV2({ flatKeys: true, checkType: false }).fromString(arrayBufferToString(arrayBuffer)).then((jsonObj) => {
              createItemPreview(fileInfo, jsonObj);
            });
          } catch (err) {
            toastr.error(err);
          }
        })();
      }
      cvsUpload['value'] = null;
    }
  }

  return  <div className={classes.csvUploadContainer}>
  <div>
    <label htmlFor={csvUploadId}>
      <Button
        variant="contained"
        component="span"
        startIcon={buttonIcon}
        disabled={disabled}
        color="primary"
      >
        <FormattedMessage id={buttonTextId} />
      </Button>
    </label>
    <input
      type="file"
      name="btnUpload"
      className="btn btn-primary"
      style={{ display: 'none' }}
      id={csvUploadId}
      onChange={csvUploadChange}
    />
  </div>
</div>
}

export { CsvImportSelector };
