import { makeStyles } from "@material-ui/core";

export const useUserManagementBarStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.spacing(50),
    flexFlow: 'column',
    alignItems: 'flex-end',
    padding: 0
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    padding: theme.spacing(0.25, 1.25, '!important'),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2, '!important')
    }
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    padding: theme.spacing(0.25, 1.25, '!important'),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2, '!important')
    }
  },
  chipRoot: {
    margin: 0
  },
  chipSelect: {
    padding: theme.spacing(0.125),
  },
  chipOutlined: {
    paddingRight: theme.spacing(0, '!important'),
  },
  searchRoot:  {
    padding: theme.spacing(0.375, 0.25),
    display: 'flex',
    alignItems: 'center'
  },
  barItem: {
    margin: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column'
  },
  barItemTitle: {
    padding: 0
  },
  barItemControl: {
    padding: 0
  },
}));
