// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import Loadable from 'react-loadable';
import Loading from './loading-component';

export default function AsyncComponent(opts) {
  return Loadable({
    loading: Loading,
    delay: 200,
    timeout: 10000,
    ...opts
  });
}
