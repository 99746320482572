import momentTimeZone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Utils from '../../../utilities/utils';

const momentExtended = extendMoment(momentTimeZone as any);

export const getDayText = (aMoment, siteTz) => {
  return aMoment.tz(siteTz).format('MM/DD/YYYY');
}

const isDateAfterSiteDayBegin = (aDate, dayText, siteTz) => {
  const siteDay = momentTimeZone.tz(dayText, 'MM/DD/YYYY', siteTz);
  return aDate >= siteDay.toDate();
}

export const getStartShiftHour = (reportState, siteTz, dayText = null) => {
  // find all shift occurrences on the selected date
  let selectedStartTimes;
  if (dayText) {
    // occurrence.start and -.end are Date objects in local zone
    selectedStartTimes = reportState.occurrencesInRange.filter(occurrence => (isDateAfterSiteDayBegin(occurrence.start, dayText, siteTz) && occurrence.end <= new Date()))
      .map(occurrence => occurrence.start);
  } else {
    selectedStartTimes = reportState.occurrencesInRange.filter(occurrence => occurrence.end <= new Date())
      .map(occurrence => occurrence.start);
  }

  if (selectedStartTimes && selectedStartTimes.length > 0) {
    // find shift with earliest start time
    const earliestStartTime = new Date(selectedStartTimes.reduce((earliestShift, shift) => (earliestShift < shift ? earliestShift : shift)));
    return earliestStartTime.toISOString();
  }
  return null;
}


export const getEndShiftHour = (reportState, siteTz, dayText = null) => {
  // find all shift occurrences on the selected date
  let selectedEndTimes;
  if (dayText) {
    // occurrence.start and -.end are Date objects in local zone

    /* const selectedDate = new Date(dayText).getDate();
         selectedEndTimes = reportState.occurrencesInRange.filter(occurrence => (occurrence.start.getDate() === selectedDate && occurrence.end <= new Date()))*/
    selectedEndTimes = reportState.occurrencesInRange.filter(occurrence => (isDateAfterSiteDayBegin(occurrence.start, dayText, siteTz) && occurrence.end <= new Date()))
      .map(occurrence => occurrence.end);
  } else {
    selectedEndTimes = reportState.occurrencesInRange.filter(occurrence => (occurrence.end <= new Date()))
      .map(occurrence => occurrence.end);
  }

  // find shift with latest end time
  if (selectedEndTimes && selectedEndTimes.length > 0) {
    const latestEndTime = selectedEndTimes.reduce((latestShift, shift) => (latestShift > shift ? latestShift : shift));
    return latestEndTime.toISOString();
  }
  return null;
}

const getSelectedShiftOccurrencesForLineAndDay = (filter, reportState, lineId, dayInQuestion) => {
  const selectedDate = new Date(dayInQuestion.text).getDate();

  const selectedShifts = (filter.reportShifts.length > 0)
    ? filter.reportShifts
    : reportState.shiftsWithOccurrencesInRange.map(shift => shift.id);
  const now = new Date();

  return reportState.occurrencesInRange.filter((occurrence) => {
    const onDay = occurrence.start.getDate() === selectedDate;
    const ended = occurrence.end <= now;
    const selected = selectedShifts.includes(occurrence.shiftId);
    const typed = occurrence.typeId === 1;
    const onLine = occurrence.lineIds && occurrence.lineIds.indexOf(lineId) >= 0;
    const onLineAndDay = onDay && ended && selected && typed && onLine;
    return onLineAndDay;
  });
}

export const formatDayLineMap = (filter, reportState, days, lines, siteTz) => {

  // console.log('lines=', JSON.stringify(lines, null, 2));
  // console.log('reportState=', JSON.stringify(reportState, null, 2));
  // console.log('filter=', JSON.stringify(filter, null, 2));
  // console.log('days', JSON.stringify(days, null, 2))
  return days.reduce((dayLineMap, day) => {
    const dayGroup: any = {};

    dayGroup.dayDt = momentTimeZone.tz(day.text, 'MM/DD/YYYY', siteTz);
    dayGroup.dayData = {
      day: day.text,
      dayNumber: day.value,
    };
    const startShiftHour =  getStartShiftHour(reportState, siteTz, day.text);
    const endShiftHour =  getEndShiftHour(reportState, siteTz, day.text);

    dayGroup.lineData = lines.reduce((lineData, line) => {
      lineData[line.id] = {
        line,
        day: day.text,
        shiftOccurrences: getSelectedShiftOccurrencesForLineAndDay(filter, reportState, line.id, day),
        startShiftHour,
        endShiftHour,
        hourData: [],
        hourDataFromOee: [],
        downtimeData: [],
        microStopData: [],
      };
      return lineData;
    }, {});
    dayLineMap[day.text] = dayGroup;
    return dayLineMap;
  }, {});

}

export const staggeredCall = (method, args, delay) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      method(...args).then(res => resolve(res)).catch(err => reject(err));
    }, delay);
  });
}

export const getAndFormatLineProductionHours = (lineId, flattenedOeeData, dayInQuestion, selectedShiftOccurrencesForLineAndDay, lines, siteTz) => {
  const reducedLineProdHours = flattenedOeeData.reduce((filtered, option) => {
    if (option !== undefined && option.lineId && option.lineId === lineId) {
      const newObj = Object.create(option);

      if (newObj.currentRunCount === 0 && newObj.currentTargetCount !== 0) { // additional check to set a run count in case printer lines hasn't been updated
        newObj.currentRunCount = Math.round(lines.find(line => line.id === lineId).runRate);
      }

      const localTimeStamp = momentTimeZone.utc(newObj.dateHour, 'YYYY-MM-DD-HH')
        .local()
        .toDate();
      newObj.siteTimeStamp = Utils.getLocalSiteDateObject(localTimeStamp, siteTz);
      newObj.timeStamp = localTimeStamp;

      filtered.push(newObj);
    }
    return filtered;
  }, []);

  return filterLineHoursToSelectedShiftOccurrences(reducedLineProdHours, lineId, dayInQuestion, selectedShiftOccurrencesForLineAndDay, siteTz);
}

export const filterLineHoursToSelectedShiftOccurrences = (lineHours, lineId, dayInQuestion, selectedShiftOccurrencesForLineAndDay, siteTz) => {
  if (selectedShiftOccurrencesForLineAndDay.length > 0) {
    const shiftHourSlices: Array<any> = [];

    for (let i = 0; i < selectedShiftOccurrencesForLineAndDay.length; i++) {
      // rounding starts to earlier hour to include that entire hour if the instance started at a different time.
      const startMoment = momentTimeZone.utc(selectedShiftOccurrencesForLineAndDay[i].start);
      const endMoment: any = momentTimeZone.utc(selectedShiftOccurrencesForLineAndDay[i].end);
      const { shiftId } = selectedShiftOccurrencesForLineAndDay[i];

      const remainderStart = startMoment.minute() % 60;
      const startTimeRounded: any = momentTimeZone.utc(startMoment)
        .subtract(remainderStart, 'minutes');

      // for each occurrence, create an array of hours. The range is all the hours in the specific occurrence
      const range = momentExtended.range(startTimeRounded, endMoment);

      let excludeEndHour = true;
      const remainderEnd = endMoment.minute() % 60;
      if (remainderEnd !== 0) excludeEndHour = false;

      const hours = Array.from(range.by('hour', { excludeEnd: excludeEndHour }));

      // Go through each hour, if a matching hour isn't found in the oee data set, assume all values are zero
      shiftHourSlices.push(hours.map((displayHour: any) => {
        const dateHour = displayHour.format('YYYY-MM-DD-HH');

        const noDataHour = {
          badPieceCount: 0,
          count: 0,
          currentRunCount: 0,
          currentTargetCount: 0,
          dateHour,
          noData: true,
          reportedMinutes: 0,
          runningMinutes: 0,
          plannedMinutes: 0,
          plannedStoppedMinutes: 0,
          siteTimeStamp: Utils.getLocalSiteDateObject(displayHour, siteTz),
          timeStamp: new Date(displayHour),
          unplannedStoppedMinutes: 0,
        };

        const hour = lineHours.find(h => h.dateHour === dateHour);
        if (hour) {
          const slicedHour = hour.slices ? hour.slices.find(scheduleSlice => scheduleSlice.sliceKey === shiftId) : null;
          if (slicedHour) {
            slicedHour.dateHour = dateHour;
            slicedHour.timeStamp = new Date(displayHour);
            slicedHour.siteTimeStamp = Utils.getLocalSiteDateObject(displayHour, siteTz);
            if (!slicedHour.runningMinutes || isNaN(slicedHour.runningMinutes)) {
              slicedHour.runningMinutes = slicedHour.reportedMinutes - slicedHour.plannedStoppedMinutes;
            }
            // current run count = the targetRate for the hour (from product or line)
            if (!slicedHour.currentRunCount || isNaN(slicedHour.currentRunCount)) {
              if (hour.currentRunCount && hour.currentRunCount > 0) {
                // calculate the est current run count from the runRate on the line
                slicedHour.currentRunCount = Math.round(hour.currentRunCount * (slicedHour.runningMinutes / 60));
              } else {
                slicedHour.currentRunCount = 0;
              }
            }

            // this is temp fix for oee hour entries that were processed missing the first min of the shift
            if (slicedHour.reportedMinutes < 60 && slicedHour.reportedMinutes > 56) {
              slicedHour.currentRunCount = hour.currentRunCount;
            }
            return slicedHour;
          }
          return noDataHour; // no shift slice is found for the hour. this could happen if the shift time was changed recently.
        }
        return noDataHour;
      }));
    }

    const flattenedSlices = shiftHourSlices.flat();

    const finalResult: Array<any> = [];

    // go through each slice to find any duplicates, and combine them into one slice to draw on chart
    // there can be two shift slices for one hour on a line when a shift change occurs mid hour

    for (let i = 0; i < flattenedSlices.length; i++) {
      const finalResultIndex = finalResult.find(item => item.dateHour === flattenedSlices[i].dateHour);
      if (!finalResultIndex) {
        finalResult.push(flattenedSlices[i]);
      } else {
        const slicesToAgg = [finalResultIndex, flattenedSlices[i]];
        const aggedSlice = slicesToAgg.reduce((prev, current) => ({
          badPieceCount: prev.badPieceCount + current.badPieceCount,
          count: prev.count + current.count,
          currentRunCount: prev.currentRunCount + current.currentRunCount,
          currentTargetCount: prev.currentTargetCount + current.currentTargetCount,
          dateHour: prev.dateHour,
          reportedMinutes: prev.reportedMinutes + current.reportedMinutes,
          runningMinutes: prev.runningMinutes + current.runningMinutes,
          plannedMinutes: prev.plannedMinutes + current.plannedMinutes,
          plannedStoppedMinutes: prev.plannedStoppedMinutes + current.plannedStoppedMinutes,
          siteTimeStamp: prev.siteTimeStamp,
          timeStamp: prev.timeStamp,
          scheduledRunMinutes: prev.scheduledRunMinutes + current.scheduledRunMinutes,
          ingestVersion: prev.ingestVersion || current.ingestVersion,
          unplannedStoppedMinutes: prev.unplannedStoppedMinutes + current.unplannedStoppedMinutes,
        }));

        finalResult[finalResult.indexOf(finalResultIndex)] = aggedSlice;
      }
    }
    return finalResult;
  }
  return [];
}


export const calculateAvailableData = (filter, reportState, timeRange, lines, linesAtOnce, siteTz, siteId, pageSize) => {
  const selectedDays = Array.from(timeRange.by('day')).map((d, i) => ({
    text: getDayText(d, siteTz),
    value: i,
  })); // todo refactor, this format is a consequence of legacy code

  const dayText = (selectedDays.length === 1) ? selectedDays[0].text : undefined;
  const firstShiftHour = getStartShiftHour(reportState, siteTz, dayText); // start of the week
  const lastShiftHour = getEndShiftHour(reportState, siteTz, dayText); // end of the week

  if (firstShiftHour && lastShiftHour) {
    const dayLineMap = formatDayLineMap(filter, reportState, selectedDays, lines, siteTz);
    const totalLineRequestData: Array<any> = [];
    selectedDays.forEach((day) => {
      for (let lineIdx = 0; lineIdx < lines.length; lineIdx += linesAtOnce) {
        const lineIds = lines.slice(lineIdx, lineIdx + linesAtOnce).map(line => line.id);
        const lineEntries = Object.values(dayLineMap[day.text].lineData)
          .map((ld: any) => ({ start: new Date(Math.min(...ld.shiftOccurrences.map(so => so.start))), end: new Date(Math.max(...ld.shiftOccurrences.map(so => so.end))) }))
          //DE-1483 filter invalid times before reduce
          .filter((e: any) => isFinite(e.start) && isFinite(e.end));
        // early out on no entries
        if (!(lineEntries.length > 0)) {
          continue;
        }
        const { start, end } = lineEntries.reduce((s, agg) => ({ start: (agg.start < s.start ? agg.start : s.start), end: (agg.end > s.end ? agg.end : s.end) }));
        const startShift = start.toISOString();
        const endShift = end.toISOString();
        totalLineRequestData.push({
          day,
          loadOEEDataParams: [siteId,
            startShift,
            endShift,
            lineIds,
            day],
          loadDowntimeDataParams: [siteId,
            startShift,
            endShift,
            lineIds,
            day],
          loadTimeInStateDataParams: [
            startShift,
            endShift,
            day.text,
            lineIds]
        });
      }
    });

    const lineRequestPages: Array<any> = [];

    const count = Math.ceil(((totalLineRequestData && totalLineRequestData.length) || 0) / pageSize);
    for (let page = 1; page <= count; page++) {
      const paged = totalLineRequestData.slice((page - 1) * pageSize, page * pageSize);
      const progressApiTotal = 3 * paged.length; // OEE,DT,TiS per day/line-batch
      const progressIngestTotal = 3 * paged.length * linesAtOnce; // OEE,DT,TiS per day/line
      lineRequestPages.push({
        request: paged,
        progressApiTotal,
        progressIngestTotal,
        dayLineMap
      })
    }

    return lineRequestPages;
  }

  return [];
}
