import { object, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, useIntl } from "react-intl";
import Moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import BallotIcon from '@material-ui/icons/Ballot';
import { LineStatusWidget } from '../LineStatusWidget/LineStatusWidget';
import LineHelper from '../../../../helpers/LineHelper/LineHelper';
import { Button, Grid } from '@material-ui/core';
import { useLinePerformanceListRowStyle } from './LinePerformanceListRow.css';

function getRemaining(assignedProductRun, shiftTargets) {
  if (assignedProductRun) {
    if (assignedProductRun.endDt) return 0;
    if (shiftTargets.remaining >= 0) return shiftTargets.remaining;
    return assignedProductRun.targetCount;
  }
  return shiftTargets.remaining;
}

const LinePerformanceListRow = (props) => {
  const classes = useLinePerformanceListRowStyle();
  const intl = useIntl();
  const { line, printer, siteTimezone, siteId, gridBreak } = props;
  const { rateColor } = (line.shiftTargets && line.shiftTargets.rates && line.shiftTargets.rates && LineHelper.getColors(line.shiftTargets.rates)) || {};

  return line && (
    <Grid container style={props.style} className={classes.root}>
      <Grid item {...gridBreak.lineNumber} className={classes.item}>
        <div className={classes.statusContainer}>
          <div className={classes.status}>
            {line && printer
              && <LineStatusWidget line={line} printer={printer} />
            }

          </div>
          <div>
            <div><strong>{line.title}</strong></div>
            <div>{line.description}</div>
          </div>
        </div>
      </Grid>

      <Grid item {...gridBreak.product} className={classes.item}>
        <div className={classes.productContainer}>
          <div className={classes.imageHolder}>
            {
              line.product && line.product.imageBase64
                ? <img src={`data:image/jpeg;${line.product.imageBase64}`}
                  className={classes.productImage} alt="" />
                : line.product && line.product.title ? (
                  <div>
                    <div><strong>{line.product.title}</strong></div>
                    <div>{line.product.description}</div>
                  </div>
                ) : null
            }
          </div>
          {(printer && printer.status !== 'Disconnected')
            && (
              <div style={{
                fontSize: 'Larger',
                textAlign: 'center',
                color: rateColor,
              }}
              >
                <strong>
                  <FormattedMessage id="detail_Rate" />
                  {`: ${((line.shiftTargets && line.shiftTargets.rates && line.shiftTargets.rates.instant != null
                    ? Math.round(line.shiftTargets.rates.instant)
                      .toLocaleString('en')
                    : '--'))
                    }`}
                </strong>
              </div>
            )
          }

          <div>
            {line.shiftTargets && line.shiftTargets.rates && line.shiftTargets.rates.assignedProductRun && line.shiftTargets.rates.assignedProductRun.targetCount
              && (
                <div>
                  {(line.shiftTargets.rates.assignedProductRun.targetCount
                    - getRemaining(line.shiftTargets.rates.assignedProductRun, line.shiftTargets)).toLocaleString(intl.locale)
                  }
                  {' / '}
                  {Number(line.shiftTargets.rates.assignedProductRun.targetCount).toLocaleString(intl.locale)}
                </div>
              )
            }
          </div>
          <div>
            {line.shiftTargets && line.shiftTargets.rates && line.shiftTargets.rates.assignedProductRun && line.shiftTargets.rates.assignedProductRun.targetCount
              && (
                <progress id="file"
                  max={line.shiftTargets.rates.assignedProductRun.targetCount}
                  value={line.shiftTargets.rates.assignedProductRun.targetCount
                    - getRemaining(line.shiftTargets.rates.assignedProductRun, line.shiftTargets)}
                />
              )
            }
          </div>
          {
            line.shiftTargets && line.shiftTargets.rates.assignedProductRun && (
              <div>
                {!line.shiftTargets.rates.assignedProductRun.endDt
                  ? Moment.tz(line.shiftTargets.etc, siteTimezone)
                    .format('L HH:mm A')
                  : Moment.tz(line.shiftTargets.rates.assignedProductRun.endDt, siteTimezone)
                    .format('L HH:mm A')}
              </div>
            )
          }
        </div>
      </Grid>

      <Grid item {...gridBreak.printer} className={classes.item}>
        <div className={classes.printer}>
          <strong>
            {printer ? (printer.title || '').replace(/,/g, ', ').replace(/\//g, '/ ') : line.printerId}
          </strong>
        </div>
      </Grid>
      <Grid item {...gridBreak.count} className={classes.item}>
        <div>
          <strong>
            {Math.round(line.count)
              .toLocaleString('en')}
          </strong>
        </div>
      </Grid>
      <Grid item {...gridBreak.lastHour} className={classes.item}>
        <div>
          <strong>
            {Math.round(line.lastHour)
              .toLocaleString('en')}
          </strong>
        </div>
      </Grid>
      <Grid item {...gridBreak.lastCurrentHour} className={classes.item}>
        <div>
          <strong>
            {Math.round(line.currentHour)
              .toLocaleString('en')}
          </strong>
        </div>
      </Grid>

      <Grid item {...gridBreak.dashboardLink} className={classNames(classes.item, classes.dashboardLink)}>
        <div className={classes.lineDashboardBtnContainer}>
          <Link data-tip="Detail..."
            to={`/site/${siteId}/line/${line.id}`}
            className={classes.lineDashboardLink}
          >
            <Button className={classes.lineDashboardBtn}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<BallotIcon />}
            >
              {intl.formatMessage({ id: 'detail_Line_Dashboard' })}
            </Button>
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}

LinePerformanceListRow.propTypes = {
  line: object.isRequired,
  printer: object,
  siteId: string,
  siteTimezone: string,
  gridBreak: object,
  style: object
};

export default injectIntl(LinePerformanceListRow);
