
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import toastr from 'toastr';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useSitePageStyles } from './SitePage.css';
import SitePrinters from '../SitePrinters/SitePrinters';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormattedMessage, useIntl } from 'react-intl';
import { HomeWork as HomeWorkIcon } from '@material-ui/icons';
import Can from '../../../components/Common/Can';
import { useSetTitleAction } from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { ActionButton } from '../../../components/ActionButton/ActionButton';
import { useSiteActions } from '../../../actions/siteActions';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { DeleteDialog } from '../../../components/DeleteDialog/DeleteDialog';
import SiteManagementBar from '../SiteManagementBar/SiteManagementBar';
import Utils from '../../../utilities/utils';
import { EmptyItem } from '../../../components/Basic/EmptyItem/EmptyItem';
import UserHelper from '../../../helpers/UserHelper';
import { Paging } from '../../../components/Paging/Paging';
import { PAGE_SIZE } from '../../../constants/global';
import { useConfigActions } from '../../../actions/configActions';
interface ISiteListState {
  page: number;
  sortedSites: Array<any>;
  pageCount: number;
  pagedSites: Array<any>;
}

const canAccessVrsWithField = (field) => !!UserHelper.CanWithField('vrs', 'authModule', field);

const SitePage = () => {
  const classes = useSitePageStyles();
  const { sites, selectedSiteId, siteInitialised, appFilters } = useAppGlobalState();

  const setTitle = useSetTitleAction();
  const intl = useIntl();
  const siteActions = useSiteActions();
  const configActions = useConfigActions();
  const history = useHistory();

  const [expanded, setExpanded] = useState<string>(selectedSiteId ? `panel_${selectedSiteId}` : '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allSiteLoaded, setAllSiteLoaded] = useState<boolean>(false);

  const [siteListState, setSiteListState] = useState<ISiteListState>({
    page: 1,
    sortedSites: [],
    pageCount: 0,
    pagedSites: []
  });

  const {
    page,
    sortedSites,
    pageCount,
    pagedSites
  } = siteListState;

  const [siteIdForDeletion, setSiteIdForDeletion] = useState<string>('');
  const [saving, setSaving] = useState(false);
  const [siteChanged, setSiteChanged] = useState(false);

  const [filteredSites, setFilteredSites] = useState<Array<any>>([]);

  const onCloseUserDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);
        const site = getSiteForDeletion();
        siteActions.deleteSite(site.id)
          .then(() => {
            toastr.success(intl.formatMessage({ id: 'detail_SiteDeleted' }));
            setSaving(false);
          })
          .catch((error) => {
            toastr.error(error);
            setSaving(false);
          });
      } catch (e) {
        toastr.error(e.toString());
      }

    }
    setSiteIdForDeletion('');
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (panel) {
      const siteId = panel.substr(6);
      if (siteId !== selectedSiteId) {
        siteActions.resetSiteData(siteId);
        siteActions.selectSite(siteId);
        setSiteChanged(true);
      }
    }
  };

  useEffect(() => {
    if (sites && sites.length === 1 && sites[0].id === selectedSiteId) {
      siteActions.loadSites().then(() => {
        setAllSiteLoaded(true);
      });
    } else if (!allSiteLoaded) {
      setAllSiteLoaded(true);
    }
  }, [siteActions, sites, selectedSiteId, allSiteLoaded]);

  useEffect(() => {
    setTitle('detail_Sites', <HomeWorkIcon />, {
      parents: [{
        id: 'detail_Configuration',
      }]
    });
  }, [setTitle]);

  useEffect(() => {
    const searchTerm = appFilters.siteFilter.searchTerm;
    const initialFilteredSites = searchTerm
      ? sites.filter(s => (s.title && s.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
        || s.id.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
      : sites;

    setFilteredSites(appFilters.siteFilter.isShowingDesignUsers ? initialFilteredSites : initialFilteredSites.filter(s => !Utils.isGuid(s.id)));

  }, [appFilters, sites]);

  const getSiteForDeletion = () => {
    return sites.find(el => el.id === siteIdForDeletion);
  }

  useEffect(() => {
    setSiteListState(s => {
      const sorted = filteredSites.sort((a, b) => a.title ? a.title.localeCompare(b.title) : 0);
      const count = Math.ceil(((sorted && sorted.length) || 0) / PAGE_SIZE);
      const paged = sorted.slice((s.page - 1) * PAGE_SIZE, s.page * PAGE_SIZE);
      return {
        ...s,
        page: 1,
        sortedSites: sorted,
        pageCount: count,
        pagedSites: paged
      }
    })
  }, [filteredSites]);

  const onPageChange = useCallback(p => {
    setSiteListState(s => ({
      ...s,
      page: p
    }));
  }, []);

  useEffect(() => {
    const paged = sortedSites.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    setSiteListState(s => ({
      ...s,
      pagedSites: paged
    }));
  }, [page, sortedSites]);

  const gotoEditPage = (siteId) => (event) => {
    event.stopPropagation();
    history.push(`/setup/site/${siteId}/edit`);
  }

  return <>
    { allSiteLoaded && <SiteManagementBar
      filter={appFilters.siteFilter}
      setFilter={configActions.setBarFilter}
    />
    }
    { allSiteLoaded && <StyledContentCard>
      {filteredSites.length > 0 ? <>
        <div className={classes.siteTitles}>
          <Typography className={classNames(classes.heading, classes.headingTitle)}><FormattedMessage id="detail_Site_Id" /></Typography>
          <Typography className={classNames(classes.secondaryHeading, classes.headingTitle)}><FormattedMessage id="detail_Name" /></Typography>
          <Typography className={classNames(classes.thirdHeading, classes.headingTitle)}><FormattedMessage id="detail_Address" /></Typography>
        </div>
        {pagedSites.map(site => <Accordion key={site.id} expanded={expanded === `panel_${site.id}`}
          onChange={handleChange(`panel_${site.id}`)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id={`${site.id}-header`}
            className={site.id === selectedSiteId ? classes.headerSelected : classes.header}
          >
            <Typography className={classNames(classes.heading, classes.siteId)}>{site.id}</Typography>
            <Typography className={classes.secondaryHeading}>{site.title}</Typography>
            <Typography className={classes.thirdHeading}>{site.address}</Typography>
            <div className={classes.controlHeading}>
              <Can do="edit" on="siteConfig" passThrough>
                {
                  can => (can || canAccessVrsWithField('Administrator')) ? <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
                    <ActionButton
                      onClick={gotoEditPage(site.id)}
                      onFocus={(event) => event.stopPropagation()}>
                      <EditIcon />
                    </ActionButton>
                  </Tooltip> : null
                }

              </Can>

              <Can do="edit" on="siteConfig">
                {site.id !== selectedSiteId ? <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
                  <ActionButton onClick={(event) => {
                    event.stopPropagation();
                    setSiteIdForDeletion(site.id);
                  }}
                    onFocus={(event) => event.stopPropagation()}>
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip> : <ActionButton disabled={true}
                  onFocus={(event) => event.stopPropagation()}>
                  <DeleteIcon />
                </ActionButton>
                }
              </Can>
            </div>

          </AccordionSummary>
          <AccordionDetails>
            {siteInitialised && selectedSiteId === site.id && <SitePrinters siteId={site.id}
              expanded={expanded === `panel_${site.id}`}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              siteChanged={siteChanged}
              setSiteChanged={setSiteChanged}
            />
            }
          </AccordionDetails>

        </Accordion>
        )}
      </> : <EmptyItem textId={sites.length === 0 ? 'detail_noSites' : 'detail_noSitesByFilter'} />
      }
      {
        filteredSites && filteredSites.length > PAGE_SIZE ? <div className={classes.pageContainer}>
          <Paging
            page={page}
            onChange={onPageChange}
            count={pageCount}
            total={filteredSites.length} /></div> : null
      }
    </StyledContentCard>
    }
    <DeleteDialog
      saving={saving}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={!!siteIdForDeletion}
      onClose={onCloseUserDeleteDialog}
      extraContent={getSiteForDeletion() && <div>Site: {getSiteForDeletion().title}</div>}
    />
    { (saving || !allSiteLoaded) && <ProgressIndicator />}
  </>

}

SitePage.DisplayName = 'SitePage';

export { SitePage };
