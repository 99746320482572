import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const BarStyledGrid = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0.125, 1.25),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.125, 0.125),
    },
    alignItems: 'center'
  }
}))((props) => <Grid container spacing={1} {...props} />);

export default BarStyledGrid;
