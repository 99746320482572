import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { useAppGlobalState, useAppSelectedSite } from '../../../../context/AppContext/AppContext';
import { useDowntimeSubCategoryManagementPageStyles } from './DowntimeSubCategoryManagementPage.css'
import { ProgressIndicator } from '../../../../components/ProgressIndicator/ProgressIndicator';
import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { useSetTitleAction } from '../../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { ACTION_EDIT } from '../../../../actions/actionTypes';
import { validation } from 'vccm-common';
import { useHistory } from 'react-router';
import { useDowntimeActions } from '../../../../actions/downtimeActions';
import {
  WatchLater as WatchLaterIcon
} from "@material-ui/icons";
import { ChromePicker } from 'react-color';
import Utils from '../../../../utilities/utils';
import { DEFAULT_DOWNTIME_COLOR } from '../../../../constants/colors';
import SiteHelper from '../../../../helpers/SiteHelper/SiteHelper';
import StyledContentCard from '../../../../components/Basic/StyledContentCard/StyledContentCard';

interface IDowntimeSubCategoryManagementPage {
  match: any;
}

const getEmptyCategory = (siteId) => ({
  title: '',
  description: '',
  typeId: '1',
  display: { color: DEFAULT_DOWNTIME_COLOR },
  siteId,
  isSubcategory: true
});

const DowntimeSubCategoryManagementPage = ({
  match }: IDowntimeSubCategoryManagementPage) => {
  const classes = useDowntimeSubCategoryManagementPageStyles();
  const history = useHistory();

  const downtimeActions = useDowntimeActions();

  const intl = useIntl();
  const { sites } = useAppGlobalState();
  const selectedSite = useAppSelectedSite();
  const setTitle = useSetTitleAction();
  const siteId: string = match.params.siteId;
  const categoryId: string = match.params.setupParam;

  const { downtimeCategories } = selectedSite ? selectedSite : { downtimeCategories: [] };
  const currentDowntimeCategory = (categoryId !== '0' && selectedSite) ? SiteHelper.getCategoryById(selectedSite.downtimeCategories, categoryId) : getEmptyCategory(siteId);
  const [errors, setErrors] = useState<any>({});

  const [localDowntimeCategory, setLocalDowntimeCategory] = React.useState<any>({ ...currentDowntimeCategory });

  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(false);

  const isEditMode = useMemo(() => {
    return match.params.action && match.params.action.toUpperCase() === ACTION_EDIT;
  }, [match.params.action]);

  const cancelForm = () => {
    setLocalDowntimeCategory({ ...currentDowntimeCategory });
  };

  useEffect(() => {
    setLocalDowntimeCategory((categoryId !== '0' && downtimeCategories && selectedSite) ? SiteHelper.getCategoryById(selectedSite.downtimeCategories, categoryId) : getEmptyCategory(siteId));
  }, [downtimeCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await downtimeActions.loadSiteDowntimeReasons(siteId);
        setIsLoading(false);
      } catch (err) {
        toastr(err && err.message ? err.message : 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const parentLink = useMemo(() => {
    return `/site/${siteId}/setup/subcategory`;
  }, [siteId]);

  useEffect(() => {
    setTitle('detail_Downtimes', <WatchLaterIcon />, {
      ignoreItself: true,
      parents: [{
        id: 'detail_Configuration',
      }, {
        id: 'detail_Downtimes',
        link: parentLink
      },
      {
        id: 'detail_DowntimeSubcategory'
      },
      {
        id: isEditMode ? 'detail_EditSubDowntimeCategory' : 'detail_createSubDowntimeCategory',
      }]
    });
  }, [setTitle, parentLink, isEditMode]);

  const closeForm = () => {
    history.push(parentLink);
  }

  useEffect(() => {
    if (sites.length > 0) {
      setDirty(JSON.stringify(currentDowntimeCategory) !== JSON.stringify(localDowntimeCategory));
    }
  }, [localDowntimeCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const downtimeCategoryFormIsValid = () => {
    const { createdAt, updatedAt, ...item } = localDowntimeCategory;
    let errors = {};
    // remove empty strings;
    if (item.description === '') {
      delete item.description;
    }

    const validationProps = ['title', 'description', 'display', 'siteId'];
    const itemToValidate = Object.entries(item)
      .map(k => (validationProps.includes(k[0]) ? ({ [k[0]]: k[1] }) : undefined))
      .reduce((agg, i) => ({ ...agg, ...i }), { siteId });
    const results = validation.schemas.downtimeReason.validate(itemToValidate, { abortEarly: false });

    if (!results.error) {
      setErrors(errors);
      return ({ ...itemToValidate, id: item.id });
    }

    errors = results.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, '_') : '';
      return agg;
    }, {});
    setErrors(errors);
    return false;
  }

  const saveDowntimeCategory = async (event) => {
    event.preventDefault();
    const validItem = downtimeCategoryFormIsValid();
    if (!validItem) {
      return;
    }
    if (!downtimeCategoryFormIsValid()) {
      return;
    }
    const tobeSavedDowntime = Object.assign({}, localDowntimeCategory);
    if (localDowntimeCategory.id === '') {
      delete localDowntimeCategory.id;
    }
    setSaving(true);
    downtimeActions.saveDowntime(tobeSavedDowntime)
      .then(() => {
        toastr.success(intl.formatMessage({ id: 'detail_Downtime_Saved' }));
        setSaving(false);
        closeForm();
      })
      .catch((error) => {
        toastr.error(error);
        setSaving(false);
      });
  }

  const onTitleChange = (event) => {
    const value = event.target.value;
    if (value) {
      setErrors({});
    }
    setLocalDowntimeCategory(s => ({ ...s, title: value }));
  }

  return <>
    {!isLoading && sites.length > 0 && <StyledContentCard>
      <CardContent className={classes.content}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <Typography variant="h5">{intl.formatMessage({ id: isEditMode ? 'detail_EditSubDowntimeCategory' : 'detail_createSubDowntimeCategory' })}</Typography>
          </div>
        </div>
        <FormControl className={classes.formControl}>
          <TextField
            autoComplete="off"
            name="title"
            required
            variant="outlined"
            label={intl.formatMessage({ id: "detail_Name" })}
            value={localDowntimeCategory.title}
            onChange={onTitleChange}
            fullWidth={true}
            error={!!errors.title}
            helperText={errors.title && intl.formatMessage({ id: 'validation_downtimeReason_title' })}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            autoComplete="off"
            name="description"
            variant="outlined"
            label={intl.formatMessage({ id: "detail_Description" })}
            value={localDowntimeCategory.description}
            onChange={(event) => setLocalDowntimeCategory(s => ({ ...s, description: event.target.value }))}
            fullWidth={true}
          />
        </FormControl>

        <div className={classes.colorPicker}>
          <ChromePicker
            name="display"
            color={localDowntimeCategory.display ? localDowntimeCategory.display.color : Utils.getColorFromString(localDowntimeCategory.title || 'color')}
            onChangeComplete={(event) => setLocalDowntimeCategory(s => ({ ...s, display: { color: event.hex } }))}
          />
        </div>
        {
          errors.form && <div className={classes.warning}>{errors.form}</div>
        }
      </CardContent>
      <CardActions className={classes.actionButtons}>
        <Button
          variant="contained"
          disabled={saving || !dirty}
          onClick={cancelForm}>
          <FormattedMessage id="detail_Cancel" />
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={saving || !dirty}
          onClick={async (event) => {
            try {
              saveDowntimeCategory(event);
            } catch (e) {
              toastr.error(e);
            }
          }}
        >
          <FormattedMessage id="detail_Save" />
        </Button>
        <div className={classes.grow} />
        <Button
          variant="contained"
          onClick={closeForm}>
          <FormattedMessage id="detail_Close" />
        </Button>
      </CardActions>
    </StyledContentCard>
    }
    {
      (isLoading || saving) && <ProgressIndicator />
    }
  </>

};

export { DowntimeSubCategoryManagementPage };
