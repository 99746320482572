import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledGridHeadCell = withStyles((theme) => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    padding: theme.spacing(0.25, 1.25, '!important'),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2, '!important')
    }
  }
}))((props) => <Grid item {...props}/>);

export default StyledGridHeadCell;
