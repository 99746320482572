import React from 'react';
import {
  Dialog,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { StyledDialogTitle } from '../../components/StyledDialogTitle/StyledDialogTitle';
import { StyledDialogContent } from '../../components/StyledDialogContent/StyledDialogContent';
import CountdownProgress from "../../pages/dashboard/components/CountdownProgress/CountdownProgress";
import { useCountdownModalStyles } from './CountdownModal.css';

interface CountdownModalProps {
  open: boolean;
  totalDuration: number;
  titleTranslationId: string;
  bodyTranslationId: string;
  onComplete: (status) => void;
}

const CountdownModal = ({
  open,
  totalDuration,
  titleTranslationId,
  bodyTranslationId,
  onComplete
}: CountdownModalProps) => {

  const classes = useCountdownModalStyles();

  return <Dialog
    open={open}
    disableBackdropClick
    maxWidth={'xs'}
    fullWidth={true}
  >
    <StyledDialogTitle>
      <FormattedMessage id={titleTranslationId} />
    </StyledDialogTitle>
    <StyledDialogContent>
      <Grid container spacing={3}>
        <Grid item className={classes.modalBody}>
          <Typography variant="h6"><FormattedMessage id={bodyTranslationId} /></Typography>
        </Grid>
        <Grid container className={classes.divider}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.progressContainer}>
            <CountdownProgress
              totalDuration={totalDuration}
              restartIndex={1}
              onComplete={() => onComplete && onComplete("auto")} />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialogContent>
  </Dialog>
};

export default CountdownModal;
