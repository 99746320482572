import React from 'react';
import classNames from 'classnames';
import { useLineStatusWidgetStyles } from './LineStatusWidget.css';

interface LineStatusWidgetProps {
  line: any;
  printer?: any;
}
function LineStatusWidget({ line, printer }: LineStatusWidgetProps) {
  const classes = useLineStatusWidgetStyles();
  let lineStatus = 'bad';
  if (line && line.status && line.status.state) {
    lineStatus = line.status.state;
  }

  const dotColor = classes[`${lineStatus}Marker`];

  return (
    printer && printer.status === 'Disconnected'
      ? <div className={classes.status}>●</div>
      : <div className={classNames(classes.status, dotColor)}>●</div>
  );
}

export { LineStatusWidget };
