import { makeStyles } from "@material-ui/core";
import { mySecondaryColor } from "../../../constants/colors";

export const useMigrateSitesResultDialogStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.625)
  },
  content: {
    margin: 0,
    padding: 0
  },
  title: {
    wordBreak: 'normal'
  },
  cell: {
    borderBottom: `0.125px solid ${mySecondaryColor}`,
    '&:nth-last-child(n + 2)': {
      borderRight: `0.125px solid ${mySecondaryColor}`,
    },
    margin: theme.spacing(0.125)
  },
  dialogActions: {
    justifyContent: 'flex-end'
  },
  createProductBtn: {
    marginRight: theme.spacing(1.125)
  },
  language: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  control: {
    width: theme.spacing(80),
  },
  value: {
    display: 'flex',
    alignItems: 'center'
  },
  percent: {
    marginLeft: theme.spacing(1)
  },
  paginationRoot: {
    display: 'flex'
  },
  paginationToolbar: {
    display: 'flex',
    alignItems: 'center'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
