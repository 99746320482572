import React from 'react';
import { useIntl } from 'react-intl';

import NarrowStyledGridRow from '../../../components/Basic/NarrowStyledGridRow/NarrowStyledGridRow';
import StyledGridCell from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledActionContainer from '../../../components/Basic/StyledActionContainer/StyledActionContainer';
import { getAbilityDescription } from '../RoleManagementPage/AbilityDescription';
import { getAbilityTitle } from '../RoleManagementPage/AbilityTitle';
import { StyledSwitch } from '../../../components/StyledSwitch/StyledSwitch';

interface IRoleAbilityListRow {
  ability: any;
  onChange: any;
  gridBreak: any;
}

const RoleAbilityListRow = ({ ability,
  onChange,
  gridBreak }: IRoleAbilityListRow) => {
  const intl = useIntl();

  return (
    <NarrowStyledGridRow>
      <StyledGridCell {...gridBreak.name}>
        <div>
          <strong>{intl.formatMessage({ id: getAbilityTitle(ability.title) })}</strong>
        </div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.description}>
        <div>{intl.formatMessage({ id: getAbilityDescription(ability.title) })}</div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons}>
        <StyledActionContainer>
          <StyledSwitch checked={!!ability.active}
            onChange={() => onChange({
              target: {
                name: ability.id,
                value: !ability.active,
              },
            })}
            name="checkedAutoRotate" />
        </StyledActionContainer>
      </StyledGridCell>
    </NarrowStyledGridRow>
  )
};

export default RoleAbilityListRow;
