import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useAppGlobalState } from '../../../context/AppContext/AppContext';
import { getPrinterStatus } from 'vccm-common/src/printerHelper';
import SitePrinterListTable from '../SitePrinterListTable/SitePrinterListTable';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useSiteActions } from '../../../actions/siteActions';
import { getCurrentUser } from '../../../libs/awsLib';

interface SitePrintersProps {
  siteId: string;
  expanded: boolean;
  siteChanged: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSiteChanged: Dispatch<SetStateAction<boolean>>;
}

export default function SitePrinters({
  siteId,
  expanded,
  siteChanged,
  isLoading,
  setIsLoading,
  setSiteChanged }: SitePrintersProps) {
  const siteActions = useSiteActions();
  const { sites } = useAppGlobalState();

  const [sitePrinters, setSitePrinters] = React.useState<Array<any>>([]);

  useEffect(() => {
    if (expanded && siteChanged) {
      try {
        if (!isLoading) {
          setIsLoading(() => true);
          const currentUser = getCurrentUser();
          siteActions.setSite(siteId, currentUser, true).then(() => {
            setIsLoading(() => false);
            setSiteChanged(false);
          });
        }

      } catch (err) {
        setIsLoading(() => false);
      }
    }
  }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const site = sites.find(el => el.id === siteId);
    if (!isLoading && expanded && site && site.printers) {
      const printers = site.printers;
      const currentPrinters = printers.filter(printer => printer.siteId === siteId)
        .map(printer => ({
          ...printer,
          status: getPrinterStatus(printer).Name,
          description: (printer.modelName != null) ? printer.modelName : (printer.model != null) ? printer.model : '',
          title: (printer.friendlyName != null) ? printer.friendlyName : (printer.title != null) ? printer.title : '',
          printerId: (printer.serial != null) ? printer.serial : (printer.printerId != null) ? printer.printerId : '',
        }));
      setSitePrinters(() => currentPrinters);
    }
  }, [sites, isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return expanded ? (!isLoading ? <SitePrinterListTable
    sitePrinters={sitePrinters} />
    : <ProgressIndicator />) : null;
}
