import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  actionBackgroundColor,
  myPrimaryColor,
  mySecondaryColor,
  myWhite
} from '../../constants/colors';

export const ActionButton = withStyles(() => ({
  root: {
    color: myPrimaryColor,
    backgroundColor: actionBackgroundColor,
    '&:hover': {
      color: myWhite,
      backgroundColor: mySecondaryColor,
    },
    borderRadius: '5px',
    padding: '8px',
    marginRight: '4px',
    maxHeight: '40px'
  },
}))(IconButton);
