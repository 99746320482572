import { useMemo } from 'react';
import DowntimeApi from '../api/prodDowntimeApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import { Logger } from '../utilities/Logger/Logger';
import * as types from './actionTypes';

export function loadSiteDowntimeReasonsSuccess(payload) {
  return { type: types.LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS, payload };
}

function loadSiteDowntimeReasons(siteId) {
  return (dispatch) => {
    return DowntimeApi.getDowntimeReasons(siteId).then((downtimeCategories) => {
      dispatch(loadSiteDowntimeReasonsSuccess({ id: siteId, downtimeCategories }));
    }).catch((error) => {

      Logger.of('App.siteActions.setSite').error('siteActions.loadSiteDowntimeReasons error', error);
      /*      throw(error); */
    });
  }
}

function createDowntimeSuccess(downtimeCategory) {
  return { type: types.CREATE_DOWNTIME_CATEGORY_SUCCESS, downtimeCategory };
}

function updateDowntimeSuccess(downtimeCategory) {
  return { type: types.UPDATE_DOWNTIME_CATEGORY_SUCCESS, downtimeCategory };
}

function saveDowntime(downtimeCategory) {
  return function (dispatch) {
    const edited = !!downtimeCategory.id;
    if (!edited) {
      return DowntimeApi.saveDowntimeReason(downtimeCategory).then((downtimeCategory) => {
        dispatch(createDowntimeSuccess(downtimeCategory));
      }).catch((error) => {
        console.log('downtimeActions.saveDowntime error', error);
        throw error;
      });
    }

    return DowntimeApi.updateDowntimeReason(downtimeCategory).then((downtimeCategory) => {
      dispatch(updateDowntimeSuccess(downtimeCategory));
    }).catch((error) => {
      console.log('downtimeActions.updateRetroactiveDowntime error', error);
      throw error;
    });
  };
}

function deleteDowntimeSuccess(id) {
  return { type: types.DELETE_DOWNTIME_CATEGORY_SUCCESS, id };
}

function deleteDowntime(siteId, id) {
  return function (dispatch) {
    return DowntimeApi.deleteDowntimeReason(siteId, id).then(
      dispatch(deleteDowntimeSuccess(id)),
    ).catch((error) => {
      console.log('downtimeActions.deleteDowntime error', error);
      /*      throw(error); */
    });
  };
}

function loadDowntimeEventsSuccess(downtimeEvents) {
  return { type: types.LOAD_DOWNTIME_EVENTS_SUCCESS, downtimeEvents };
}

function loadAllDowntimeEvents() {
  return (dispatch) => {
    return DowntimeApi.getAllDowntimeEvents().then((downtimeEvents) => {
      dispatch(loadDowntimeEventsSuccess(downtimeEvents));
    }).catch((error) => {
      console.log('downtimeActions.loadAllDowntimeEvents error', error);
      /*          throw(error) */
    });
  };
}

function loadDowntimeEventsBySuccess(downtimeEvents) {
  return { type: types.LOAD_DOWNTIME_EVENTS_BY_SUCCESS, downtimeEvents };
}

function loadDowntimeEventsBy(siteId, lineId, startTime, endTime, downtimeQueryType) {
  return (dispatch) => {
    return DowntimeApi.getDowntimeEventsBy(siteId, lineId, startTime, endTime, downtimeQueryType)
      .then((downtimeEvents) => {
        dispatch(loadDowntimeEventsBySuccess(downtimeEvents));
      })
      .catch((error) => {
        console.log('downtimeActions.loadDowntimeEventsBy error', error);
        /*     throw(error) */
      });
  };
}

function createDowntimeEventSuccess(downtimeEvent) {
  return { type: types.CREATE_DOWNTIME_EVENT_SUCCESS, downtimeEvent };
}

function updateDowntimeEventSuccess(downtimeEvent) {
  return { type: types.UPDATE_DOWNTIME_EVENT_SUCCESS, downtimeEvent };
}

function saveDowntimeEvent(siteId, updates, originalEvent) {
  return function (dispatch) {
    const edited = !!originalEvent;
    return DowntimeApi.saveDowntimeEvent(siteId, updates, originalEvent).then((downtimeEvent) => {
      edited
        ? dispatch(updateDowntimeEventSuccess(downtimeEvent))
        : dispatch(createDowntimeEventSuccess(downtimeEvent));
    }).catch((error) => {
      console.log('downtimeActions.saveDowntimeEvent error', error);
      throw error;
    });
  };
}

function deleteDowntimeEventSuccess(id) {
  return { type: types.DELETE_DOWNTIME_EVENT_SUCCESS, id };
}

function deleteDowntimeEvent(siteId, id) {
  return function (dispatch) {
    return DowntimeApi.deleteDowntimeEvent(siteId, id).then(
      dispatch(deleteDowntimeEventSuccess(id)),
    ).catch((error) => {
      console.log('downtimeActions.deleteDowntimeEvent error', error);
      /*           throw(error); */
    });
  };
}

export const useDowntimeActions = () => {
  const dispatch: Function = useAppGlobalDispatch();
  return useMemo(() => ({
    loadSiteDowntimeReasons: (siteId) => loadSiteDowntimeReasons(siteId)(dispatch),
    saveDowntime: (downtimeCategory) => saveDowntime(downtimeCategory)(dispatch),
    deleteDowntime: (siteId, id) => deleteDowntime(siteId, id)(dispatch),
    loadAllDowntimeEvents: () => loadAllDowntimeEvents()(dispatch),
    loadDowntimeEventsBy: (siteId, lineId, startTime, endTime, downtimeQueryType) => loadDowntimeEventsBy(siteId, lineId, startTime, endTime, downtimeQueryType)(dispatch),
    saveDowntimeEvent: (siteId, updates, originalEvent) => saveDowntimeEvent(siteId, updates, originalEvent)(dispatch),
    deleteDowntimeEvent: (siteId, id) => deleteDowntimeEvent(siteId, id)(dispatch)
  }), [dispatch]);
};
