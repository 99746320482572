import { makeStyles } from "@material-ui/core";

export const useShiftHistoryReportPageStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'column',
    '&:nth-last-child(1)': {
      paddingBottom: theme.spacing(2, '!important')
    },
    [theme.breakpoints.down('sm')]: {
      '&:nth-last-child(1)': {
        paddingBottom: theme.spacing(1, '!important')
      },
    }
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    '&:nth-last-child(n + 2)': {
      marginBottom: theme.spacing(1.875)
    }
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(3.75)
  },
  actionButtons: {
    justifyContent: 'flex-start'
  },
  widgetContainer: {
    marginTop: theme.spacing(2)
  },
  noShiftFound: {
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  pageContainer: {
    marginLeft: theme.spacing(1.5),
  }
}));
