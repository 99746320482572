import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toastr from 'toastr';
import downloadJs from 'downloadjs';
import { validation } from 'vccm-common';

import { useAppLineState, useAppProductState, useAppShiftState, useAppSiteState } from '../../../context/AppContext/AppContext';
import { useOeeReportPageStyles } from './OeeReportPage.css'
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import ReportHeader from '../ReportHeader/ReportHeader';
import { Logger } from '../../../utilities/Logger/Logger';
import ReportCsvUtils from '../../../utilities/ReportCsvUtils';
import { useIntl } from 'react-intl';
import Utils from '../../../utilities/utils';
import * as ENUMS from '../../../enums/Enums';
import { useHistory } from 'react-router';
import { PDFExport } from '@progress/kendo-react-pdf';

import {
  defaultReportFilter,
  getNoContentMessage,
  getTimeoutMessage,
  getSiteTimeStamp,
  performUpdateReportFilterData,
  seriesByThresholds,
  getLocalTimeStamp,
  getCommonFilterFromQueryString,
  getShiftsOccurringInRange
} from '../../../utilities/ReportUtility/ReportUtility';
import ChartOee from '../../../components/Chart/ChartOee';
import { reduceHours, getOee } from '../../../utilities/oeeCalculations';
import oeeThresholds from '../../../utilities/oeeThresholds';
import LineHelper from '../../../helpers/LineHelper/LineHelper';
import { useSiteActions } from '../../../actions/siteActions';
import StyledContentCard from '../../../components/Basic/StyledContentCard/StyledContentCard';
import { CardContent } from '@material-ui/core';
import { ReportDownload } from '../ReportDownload/ReportDownload';
import BarStyledContentCard from '../../../components/Basic/BarStyledContentCard/BarStyledContentCard';
import { LinearProgressWithLabel } from '../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import { ReportTypes } from '../../../enums/ReportTypes';

const defaultOeeThresholds = [
  {
    v: oeeThresholds.thresholds.min.value,
    c: oeeThresholds.thresholds.min.colorName,
    n: oeeThresholds.thresholds.min.n,
  },
  {
    v: oeeThresholds.thresholds.med.value,
    c: oeeThresholds.thresholds.med.colorName,
    n: oeeThresholds.thresholds.med.n,
  },
  {
    v: oeeThresholds.thresholds.max.value,
    c: oeeThresholds.thresholds.max.colorName,
    n: oeeThresholds.thresholds.max.n,
  },
];

const paqOeeThresholds = [
  {
    v: oeeThresholds.thresholds.min.value,
    c: 'red',
    n: oeeThresholds.thresholds.min.n,
  },
  {
    v: oeeThresholds.thresholds.med.value,
    c: 'grey',
    n: oeeThresholds.thresholds.med.n,
  },
  {
    v: oeeThresholds.thresholds.max.value,
    c: 'yellow',
    n: oeeThresholds.thresholds.max.n,
  },
];

interface IOeeReportPage {
  match: any;
}

const OeeReportPage = ({ match }: IOeeReportPage) => {
  const classes = useOeeReportPageStyles();

  const lines = useAppLineState();
  const shifts = useAppShiftState();
  const products = useAppProductState();

  const intl = useIntl();
  const { sites, selectedSiteId } = useAppSiteState();
  const siteActions = useSiteActions();
  const history = useHistory();

  const siteId: string = match.params.siteId;
  const site = sites.find(el => el.id === siteId);

  const initialiseFilter = () => {
    if (history.location.search) {
      // if there are query params in the route when it's loaded, set the filters to match before fetching data
      return getCommonFilterFromQueryString({
        startTimeId: '',
        endTimeId: '',
        reportOEEDisplay: 1,
      }, history.location.search, site.tz, true);
    } else {
      return {
        ...defaultReportFilter,
        lineIds: [],
        reportLineDisplay: 1,
        reportOEEDisplay: 1,
        reportShifts: [],
      }
    }
  }
  const [isFilterInitialisedFirstTime, setIsFilterInitialisedFirstTime] = useState(true);
  const [filter, setFilter] = useState<any>(initialiseFilter());

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pdfExportComponent, setPdfExportComponent] = useState<any>();

  const [downloadProgressIndex, setDownloadProgressIndex] = useState(0);
  const [downloadProgressCount, setDownloadProgressCount] = useState(1);

  const [filterIndex, setFilterIndex] = useState(0);
  const [activeShifts, setActiveShifts] = useState<Array<any> | null>(null);
  const shiftsFormatted = useMemo(() => activeShifts ? activeShifts.map(shift => ({
    value: shift.id,
    text: shift.title,
  })) : [], [activeShifts]);

  const getNewData = async (lines) => {
    const site = sites.find(el => el.id === selectedSiteId);

    Logger.of('App.OeeReportPage.getNewData')
      .info('getNewData =>', lines);

    const lineIds = filter.lineIds && filter.lineIds.length > 0 && filter.lineIds.indexOf('all') === -1
      ? filter.lineIds
      : (lines || []).map(l => l.id);

    const shiftIds = filter.reportShifts && filter.reportShifts.length > 0
      ? filter.reportShifts.indexOf('all') > -1 ? (shiftsFormatted || []).map(l => l.value) : filter.reportShifts
      : shiftsFormatted.map(sf => sf.value);

    const newFilter = Object.assign({}, filter, {
      lineIds,
      reportShifts: shiftIds,
    });
    const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
    Logger.of('App.OeeReportPage.getNewData')
      .info('new Filter', newFilter);
    if (interval && site.tz && validation.validateDateString(newFilter.startDt) && validation.validateDateString(newFilter.endDt)) {
      setIsLoading(true);
      try {
        await siteActions.loadSiteOeeSummary(
          Utils.getISOTimeForReport(newFilter.endDt, site.tz, interval.value),
          interval.api,
          newFilter.lineIds,
          newFilter.reportShifts || [],
          selectedSiteId,
          Utils.getISOTimeForReport(newFilter.startDt, site.tz, interval.value),
          interval.text,
          site.tz,
          '',
          interval.api === 'd'
        );
      } catch (err) {
        toastr.error(err.message);
      }

      setIsLoading(false);
      if (!isInitialized) {
        setIsInitialized(true);
      }
      setFilter((state) => ({ ...state, isDirty: false }))
    }
  }

  const downloadToCsv = async () => {
    setIsDownloading(true);
    setDownloadProgressIndex(0);
    setDownloadProgressCount(1);
    const site = sites.find(el => el.id === selectedSiteId);
    Logger.of('App.OeeReportPage.downloadToCsv').info('start', Date.now());
    const { filename, output } = await ReportCsvUtils.selectOeeReportData(filter, site, lines, shifts, products, [], setDownloadProgressIndex, setDownloadProgressCount);
    if (Array.isArray(output) && output.length > 0) {
      Logger.of('App.OeeReportPage.downloadToCsv').info(`data selected (${output.length})`, Date.now());
      const csv = await ReportCsvUtils.toCSV(output);
      Logger.of('App.OeeReportPage.downloadToCsv').info('toCSV', Date.now());
      downloadJs(csv, filename, 'text/csv');
      Logger.of('App.OeeReportPage.downloadToCsv').info('downloadJs', Date.now());
    } else {
      toastr.info('No data returned...');
    }
    setIsDownloading(false);
    Logger.of('App.OeeReportPage.downloadToCsv').info('end', Date.now());
  };

  const routeReportQueryParams = useCallback(() => {
    let dates = '';
    if (filter.timeFrameId === 'customDateRange'
      && validation.validateDateString(filter.startDt)
      && validation.validateDateString(filter.endDt)) {
      dates = `&startDt=${filter.startDt.toISOString()}&endDt=${filter.endDt.toISOString()}`;
    }
    history.push({
      search: `${`?timeFrameId=${filter.timeFrameId}&timeIntervalId=${filter.timeIntervalId}`
        + `&reportLineDisplay=${filter.reportLineDisplay}&lineIds=${filter.lineIds && filter.lineIds.length > 0 ? filter.lineIds : 'all'}`
        + `&reportShifts=${filter.reportShifts && filter.reportShifts.length > 0 ? filter.reportShifts : 'all'}`}${dates}`,
    });
  }, [filter, history]);

  const updateReportFilterData = (data) => {
    const { name, value } = data;
    setFilter((state) => performUpdateReportFilterData(state, name, value, site.tz, 'OeeReportPage'));
    if (name !== 'reportShifts') {
      setFilterIndex(s => s + 1);
    }
  }

  useEffect(() => {
    const [shiftsWithOccurrencesInRange] = getShiftsOccurringInRange(shifts, filter, site.tz);
    setActiveShifts(shiftsWithOccurrencesInRange);
  }, [filterIndex, shifts, site.tz]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    routeReportQueryParams();
  }, [filter, routeReportQueryParams]);

  const exportPDGWithComponent = async () => {
    setExporting(true);
  }

  useEffect(() => {
    if (exporting) {
      pdfExportComponent.save();
      setExporting(false);
    }
  }, [exporting, pdfExportComponent]);

  const slicesForValidShifts = (detail) => {
    const { reportShifts } = filter;
    const validShiftIds = reportShifts.length > 0 ? reportShifts : shiftsFormatted.concat({ value: 'defaultShift', text: '' }).map(sf => sf.value);
    return (detail && detail.slices
      && detail.slices.filter(slice => validShiftIds.includes(slice.sliceKey)))
      || [detail];
  }

  const seriesSummary = (data, seriesName, fieldName) => {
    const reportData = JSON.parse(JSON.stringify(data)) || [];
    if (reportData.length > 0) {
      reportData.forEach((segment) => {
        const slices = segment.details.reduce((agg, detail) => {
          detail.slices = slicesForValidShifts(detail);
          return agg.concat(detail.slices);
        }, []);
        const reducedHours = reduceHours(slices);
        segment.oee = getOee(reducedHours);
      });
    }

    const barColor = !filter.reportOEEDisplay ? () => {
      Logger.of('App.OeeReportPage.constructor')
        .info('looking for v=====>', fieldName);
      switch (fieldName) {
        case 'availability':
          return 'grey';
        case 'performance':
          return 'red';
        case 'quality':
          return 'yellow';
        default:
          return '#0192c7';
      }
    } : '#0192c7';

    const site = sites.find(el => el.id === siteId);

    return [{
      name: `${seriesName}`,
      field: 'oee',
      categoryField: 'siteTimeStamp',
      data: reportData.map(i => ({
        ...i,
        timeStamp: getLocalTimeStamp(filter, site.tz, i),
        siteTimeStamp: getSiteTimeStamp(filter, site.tz, i),
      })),
      opacity: 0.7,
      color: barColor,
    }];
  }

  const seriesDetails = (data, seriesFields) => {
    const detailsByLine: any = {};
    const series: Array<any> = [];
    let hasDetails = false;
    const reportData = data || [];
    // use the detail to instead of the aggregate
    const site = sites.find(el => el.id === siteId);
    reportData.map(lineData => lineData.details)
      .reduce((agg, details) => {
        if (details) {
          details.forEach((detail) => {
            const slices = slicesForValidShifts(detail);
            const reducedHours = reduceHours(slices);
            detail.oee = getOee(reducedHours);
          });

          details.forEach(d => {
            hasDetails = true;

            seriesFields.forEach((val) => {
              if (d[val] > 0) {
                (agg[d.lineId] = agg[d.lineId] || [])
                  .push({
                    timeStamp: getLocalTimeStamp(filter, site.tz, d),
                    siteTimeStamp: getSiteTimeStamp(filter, site.tz, d),
                    lineId: d.lineId,
                    color: LineHelper.getLineById(lines, d.lineId).display.color,
                    [val]: d[val],
                  });
              }
            });
          });
        }
        return agg;
      }, detailsByLine);

    if (!hasDetails) return series;
    // create series for each line, by data point, data is an array of oee like thingies
    for (const key in detailsByLine) {
      if (!detailsByLine.hasOwnProperty(key)) continue; // eslint-disable-line no-prototype-builtins
      // a series per value type (oee or w/ paq)
      seriesFields.forEach((val) => {
        const line = LineHelper.getLineById(lines, key);
        series.push({
          name: seriesFields.length > 0 && line
            ? `${line.title}`
            : 'Unnamed',
          field: val,
          categoryField: 'siteTimeStamp',
          colorField: 'color',
          color: line.display.color,
          data: detailsByLine[key],
        });
      });
    }

    return series.sort(Utils.sortByName);
  }

  const getOeeChartData = (data) => {
    let series: Array<any> = [];
    let newSeries: Array<any> = [];
    const stacked = false;
    let separateSeriesByThreshold = true;
    const reportData = data || [];
    const oeeType = filter.reportOEEDisplay === 1
      ? ['oee']
      : ['performance', 'availability', 'quality'];
    let custom = false;
    if (reportData) {
      // Logger.of('App.OeeReportPage.getOeeChartData')
      //   .trace('Here is the reportData', reportData);
      const site = sites.find(el => el.id === siteId);
      switch (filter.reportLineDisplay) {
        case 1: // line summary
          series = seriesByThresholds(
            filter, site.tz,
            reportData,
            oeeType,
            filter.reportOEEDisplay ? defaultOeeThresholds : paqOeeThresholds,
          );
          separateSeriesByThreshold = false;
          newSeries = seriesSummary(reportData, intl.formatMessage({ id: 'detail_OEE' }), 'oee');
          custom = true;
          break;

        case 2: // line details
          // todo grab 'oee' from this.state
          // By threshold way
          series = [];
          newSeries = seriesDetails(reportData, oeeType);
          if (series.length <= 0) {
            series = seriesByThresholds(
              filter, site.tz, reportData, oeeType, defaultOeeThresholds,
            );
          }
          custom = ((series && series.length) || 0) > 0;
          separateSeriesByThreshold = filter.reportOEEDisplay !== 1;
          break;
        default:
          break;
      }
    }

    return {
      series,
      custom,
      reportData,
      newSeries,
      stacked,
      separateSeriesByThreshold,
      summary: filter.reportLineDisplay === 1,
    };
  }

  const getOeeChart = (data, interval) => {
    const { series, custom, reportData, newSeries, stacked, separateSeriesByThreshold, summary } = getOeeChartData(data);
    const title = filter.reportOEEDisplay === 1 ? intl.formatMessage({ id: 'detail_OEE' }) : intl.formatMessage({ id: 'detail_OEE_w_PAQ' });

    return (reportData.length > 0 && series.length < 1 ? getNoContentMessage(intl) // the server returned data,= but we've filtered it out
      : (
        <>
          {separateSeriesByThreshold
            ? (
              <ChartOee
                data={custom ? [] : reportData}
                interval={interval}
                customSeries={custom ? series : undefined}
                start={filter.startDt}
                end={filter.endDt}
                stacked={stacked}
                dynamicBarColor={false}
                title={title}
                showBarLabels={false}
                summary={summary}
                printing={exporting}
              />
            )
            : (
              <ChartOee
                data={custom ? [] : reportData}
                interval={interval}
                customSeries={custom ? newSeries : undefined}
                start={filter.startDt}
                end={filter.endDt}
                stacked={stacked}
                dynamicBarColor={false}
                title={title}
                showBarLabels={false}
                summary={summary}
                printing={exporting}
              />
            )
          }
        </>
      )
    );
  }

  const chart = (
    <>
      {(() => {
        let dataPropName = 'data';
        const interval = ENUMS.INTERVALS.instances[filter.timeIntervalId];
        switch (Number(filter.timeIntervalId)) {
          case ENUMS.INTERVALS.HOUR:
            dataPropName = 'oeeHour';
            break;
          case ENUMS.INTERVALS.DAY:
            dataPropName = 'oeeDay';
            break;
          case ENUMS.INTERVALS.WEEK:
            dataPropName = 'oeeWeek';
            break;
          case ENUMS.INTERVALS.MONTH:
            dataPropName = 'oeeMonth';
            break;
          default:
            return getNoContentMessage(intl);
        }

        const site = sites.find(el => el.id === selectedSiteId);

        if (site && site[dataPropName] && site[dataPropName].error) {
          return getTimeoutMessage(intl);
        }
        if (site && site[dataPropName] && site[dataPropName].data) {
          const data = site && site[dataPropName] && site[dataPropName].data;
          const oeeChart = isDownloading ? '' : getOeeChart(filter.isDirty ? [] : data, interval);
          return data.length > 0
            ? oeeChart
            : filter.isDirty ? oeeChart : getNoContentMessage(intl);
        }
        return '';
      }
      )()}
    </>
  );

  useEffect(() => {
    if (history.location.search && isFilterInitialisedFirstTime) {
      getNewData(lines);
    }
    setIsFilterInitialisedFirstTime(false);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps


  const subHeader = intl.formatMessage({ id: 'detail_OEE_Report' });
  return <>
    { sites.length > 0 && <>
      <BarStyledContentCard>
        <CardContent className={classes.content}>
          <ReportHeader
            enableShiftFilter
            filter={filter}
            getNewData={() => getNewData(lines)}
            lines={lines}
            reportType={ReportTypes.OEE}
            saved={filter.saved}
            shifts={shiftsFormatted}
            siteId={selectedSiteId}
            title={filter.title || ''}
            isLoading={isLoading}
            updateReportFilterData={updateReportFilterData}
            subHeader={subHeader}
            enableTimeInterval={true}
          />
        </CardContent>
      </BarStyledContentCard>
      <StyledContentCard>
        <CardContent className={classes.content}>
          {
            isDownloading && <>
              <LinearProgressWithLabel
                label={intl.formatMessage({ id: 'detail_CsvData' })}
                progressValue={downloadProgressIndex}
                maxValue={downloadProgressCount} />
              <div className={classes.overlay}></div>
            </>
          }
          <ReportDownload
            downloadToCsv={() => downloadToCsv()}
            exportToPdf={() => exportPDGWithComponent()}
            isDownloading={false}
            isLoading={isLoading}
          />
          <div className={classes.pdfContainer}>
            <PDFExport
              ref={component => {
                setPdfExportComponent(component);
              }}
              margin="5mm"
              paperSize="A4"
              landscape
              scale={0.8}
            >
              {exporting ? (
                <div>
                  <div>
                    <div>{filter.title || subHeader}</div>
                    <div>{(filter.title && filter.title !== subHeader) ? subHeader : undefined}</div>
                  </div>
                  {chart}
                </div>
              )
                : chart}

            </PDFExport>
          </div>
        </CardContent>
      </StyledContentCard>
    </>
    }
    {
      isLoading && <ProgressIndicator />
    }
  </>

};

export default OeeReportPage;
