import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import toastr from 'toastr';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ActionButton } from '../../../../components/ActionButton/ActionButton';
import StyledGridRow from '../../../../components/Basic/StyledGridRow/StyledGridRow';
import StyledGridCell from '../../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledActionContainer from '../../../../components/Basic/StyledActionContainer/StyledActionContainer';
import Can from '../../../../components/Common/Can';
import { DeleteDialog } from '../../../../components/DeleteDialog/DeleteDialog';
import { useDowntimeActions } from '../../../../actions/downtimeActions';

interface IDowntimeSubCategoryListRow {
  category: any;
  siteId: string;
  gridBreak?: any;
  setRefreshRequired: Dispatch<SetStateAction<boolean>>;
}

const DowntimeSubCategoryListRow = ({ category,
  siteId,
  gridBreak,
  setRefreshRequired }: IDowntimeSubCategoryListRow) => {

  const intl = useIntl();
  const downtimeActions = useDowntimeActions();

  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const onCloseUserDeleteDialog = async (confirm: boolean) => {
    if (confirm) {
      try {
        setSaving(true);

        downtimeActions.deleteDowntime(category.siteId, category.id)
          .then(() => {
            toastr.success(intl.formatMessage({ id: 'detail_Deleted' }));
            setSaving(false);
            setRefreshRequired(true);
          })
          .catch((error) => {
            toastr.error(error);
            setSaving(false);
          });

      } catch (e) {
        toastr.error(e.toString());
      }
    }
    setDeleteConfirmVisible(false);
  }

  return <>
    <StyledGridRow>
      <StyledGridCell {...gridBreak.name}>
        <div>
          <span style={{ color: category.display.color, fontSize: 'x-large' }}>
            ●
        </span>&nbsp;
          <span><strong>{category.title}</strong></span>
        </div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.description}>
        <div><em>{category.description}</em></div>
      </StyledGridCell>
      <StyledGridCell {...gridBreak.actionButtons}>
        <Can do="edit" on="downtimeReason">
          <StyledActionContainer>
            <Link to={`/site/${siteId}/setup/subcategory/${category.id}/edit`}
            >
              <Tooltip title={intl.formatMessage({ id: 'detail_Edit' })}>
                <ActionButton>
                  <EditIcon />
                </ActionButton>
              </Tooltip>
            </Link>

            <Tooltip title={intl.formatMessage({ id: 'detail_Delete' })}>
              <ActionButton onClick={() => setDeleteConfirmVisible(true)}>
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </StyledActionContainer>
        </Can>
      </StyledGridCell>
    </StyledGridRow>
    <DeleteDialog
      saving={saving}
      title={<FormattedMessage id="detail_PleaseConfirm" />}
      open={isDeleteConfirmVisible}
      onClose={onCloseUserDeleteDialog}
      extraContent={<div>{category.title} {category.description ? `-${category.description}` : ''}</div>}
    />
  </>
};

export default DowntimeSubCategoryListRow;
