
class SiteHelper {
  static getCategoryById(categories: Array<any> = [], id) {
    if (!id) return undefined;
    const category = categories.find(c => c.id === id);
    return category || undefined;
  }

  static getListitemById(itemList: Array<any> = [], id) {
    if (!id) return undefined;
    return itemList.find(i => i.id === id);
  }

  static getCategoryNameById(categories: Array<any> = [], id) {
    if (!id) return undefined;
    if (id === 'Disconnected') {
      return 'Printer Disconnected';
    }
    if (id === '1' || id === 1) {
      return 'Unknown';
    }
    const category = categories.find(c => c.id === id);
    if (category && category.title) {
      return category.title;
    }
    return undefined;
  }

  static getPrinterById(printers: Array<any> = [], id) {
    if (!id) return undefined;
    const printer = printers.find(p => p.printerId === id);
    return printer || undefined;
  }

  static getDowntimeById(downtimes: Array<any> = [], id) {
    if (!id) return undefined;
    const downtime = downtimes.find(d => d.id === id);
    return downtime || undefined;
  }

  static getDowntimeColorById(downtimes: Array<any> = [], id) {
    if (!id) return undefined;
    const downtime = downtimes.find(d => d.id === id);
    if (downtime && downtime.display && downtime.display.color) {
      return downtime.display.color;
    }
    return undefined;
  }

  static getSiteById(sites: Array<any> = [], id) {
    if (!id) return undefined;
    const site = sites.find(d => d.id === id);
    if (site) return site;
    return undefined;
  }
}

export default SiteHelper;
