import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import classNames from 'classnames';

// styles
import useStyles from "./Widget.css";

interface IWidgetProps {
  children?: any;
  title?: string;
  subTitle?: string;
  className?: string;
  contentClassName?: string;
}
export default function Widget({
  children,
  title,
  subTitle,
  className,
  contentClassName
}: IWidgetProps) {
  const classes = useStyles();

  return (
    <Card className={classNames(className, classes.root)}>
      <CardHeader
        className={classes.header}
        title={title}
        subheader={subTitle}
        classes={{ title: classes.headerTitle }}
      />
      <CardContent className={contentClassName ? contentClassName : ''}>
        {children}
      </CardContent>
    </Card>
  );
}
