import { makeStyles } from "@material-ui/core";

export const useDisplayModeSettingsSetupPageStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: theme.spacing(2.5),
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    '&:nth-last-child(n + 2)': {
      marginBottom: theme.spacing(1.875)
    }
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(3.75)
  },
  actionButtons: {
    justifyContent: 'flex-start'
  },
  rotate: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  textLabel: {
    fontSize: theme.spacing(1.5)
  }
}));
