import React from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useConfirmDialogStyle } from './ConfirmDialog.css';

interface IConfirmDialogProps {
  title: any;
  open: boolean;
  onClose: (value: boolean) => void;
  content: any;
  saving?: boolean;
  maxWidth?: DialogProps['maxWidth'];
}

const ConfirmDialog = ({ title, open, maxWidth, onClose, content, saving }: IConfirmDialogProps) => {
  const classes = useConfirmDialogStyle();
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return <Dialog disableBackdropClick
    classes={{ paper: classes.root }}
    fullWidth={true}
    maxWidth={maxWidth || 'xs'}
    disableEscapeKeyDown
    aria-labelledby="confirm" open={open}>
    <DialogTitle id="confirm-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent dividers>
      {content}
    </DialogContent>
    <DialogActions>
      <Button autoFocus
        onClick={handleOk}
        className="confirm-ok-btn"
        variant="contained"
        disabled={saving}
        color="primary">
        <FormattedMessage id="detail_Yes" />
      </Button>
      <Button onClick={handleCancel}
        color="default"
        className="confirm-cancel-btn"
        disabled={saving}
        variant="contained">
        <FormattedMessage id="detail_No" />
      </Button>
    </DialogActions>
  </Dialog>;
}

export { ConfirmDialog };
