import { Select } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledSelect = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25),
    width: '100%',
  },
  outlined: {
    width: '100%'
  },
  select: {
    width: '100%'
  }
}))(Select);

export default StyledSelect;
