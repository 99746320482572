import Moment from 'moment-timezone';
import * as ENUMS from '../enums/Enums';

export class DowntimeDataHelper {
  static get AGGREGATE() { return 'sum'; }

  static get CATEGORY_FIELD() { return 'siteTimeStamp'; }

  static get DEFAULT_COLOR() { return '#0192c7'; }

  static get DEFAULT_OPACITY() { return 1; }

  static get FIELD() { return 'duration'; }

  static get COUNT() { return 'count'; }

  static get SUMMARY_TITLE() { return 'Downtime Summary'; }

  requestedOneDay: any;
  interval: any;
  responseType: any;
  isSummary: boolean;
  lines: any;
  opacity: any;
  site: any;
  shifts: Array<any>;
  reasons: any;
  summaryTitle: string;
  downtimeData: any;

  constructor(props) {
    this.requestedOneDay = props.requestedOneDay;
    this.interval = props.interval || ENUMS.INTERVALS.instances[ENUMS.INTERVALS.HOUR];
    this.responseType = props.responseType;
    this.isSummary = props.summary;
    this.lines = props.lines;
    this.opacity = props.opacity || DowntimeDataHelper.DEFAULT_OPACITY;
    this.site = props.site;
    this.shifts = props.shifts || [];
    this.reasons = this.downtimeReasons();
    this.summaryTitle = props.summaryTitle || DowntimeDataHelper.SUMMARY_TITLE;
    const data = props.downtimeData.sort((a, b) => Moment(a.startDt).diff(Moment(b.startDt)));

    this.downtimeData = this.rollupForVccm2196(data, this.requestedOneDay, this.interval);
  }

  // These functions are throw-away when we fix downtimeEvents.js to properly return a single
  // day's worth of data (and all the rest correctly too) and display it appropriately.
  rollupForVccm2196(downtimeData, requestedOneDay, interval) {
    let data = downtimeData;
    if (requestedOneDay && downtimeData && downtimeData.length > 1) {
      if (interval.downtimeRptApi === 'd') {
        data = this.rollupDayForVccm2196(downtimeData);
      } else if (interval.downtimeRptApi === 'w') {
        data = this.rollupWeekForVccm2196(downtimeData);
      }
    } else if (interval.downtimeRptApi === 'w' && downtimeData && downtimeData.length > 1) {
      data = this.rollupWeekForVccm2196(downtimeData);
    }
    return data;
  }
  rollupDayForVccm2196(downtimeData) {
    return downtimeData.reduce((acc, curr, idx) => {
      if (idx === 0) {
        acc.push(curr);
      } else {
        const used: Array<any> = [];
        acc[0].details.push(...curr.details);
        acc[0].slices.forEach(s => {
          const [currSlice] = curr.slices.filter(cs => cs.sliceKey === s.sliceKey);
          if (currSlice) {
            used.push(currSlice.sliceKey);
            s.duration += currSlice.duration;
          }
        });
        acc[0].slices.push(...curr.slices.filter(s => !used.includes(s.sliceKey)));
        acc[0].duration = curr.details.reduce((amt, curr) => { amt += curr.duration; return amt; }, 0);
        acc[0].count = curr.details.length;
      }
      return acc;
    }, []);
  }
  rollupWeekForVccm2196(downtimeData) {
    return downtimeData.map((d) => { if (!d.count) d.count = 1; return d; });
  }

  detailHasValidLineId(detail, lineId) {
    return !lineId || detail.lineId === lineId;
  }

  detailSliceHasValidReason(detailSlice) {
    const reasonId = detailSlice.reasonId || 1;
    return this.reasons[reasonId] && this.reasons[reasonId].typeId !== ENUMS.DOWNTIME_TYPE_IDS.PLANNED;
  }

  detailSliceHasValidShift(detailSlice) {
    return this.shifts.length === 0 || this.shifts.includes(detailSlice.sliceKey);
  }

  downtimeByReason(lineId) {
    let result = {};
    const updateSubSliceData = (slice, result) => {
      const { reasonId } = slice;
      const { secondReasonId } = slice;
      if (result[reasonId].subSlice.map(slice => slice.sliceKey).includes(secondReasonId)) {
        const indexToUpdate = result[reasonId].subSlice.findIndex(x => x['sliceKey'] === secondReasonId)
        const currentDuration = result[reasonId].subSlice[indexToUpdate].duration
        result[reasonId].subSlice[indexToUpdate] = { sliceKey: secondReasonId, duration: currentDuration + slice.duration }
      } else {
        result[reasonId].subSlice.push({
          sliceKey: secondReasonId,
          duration: slice.duration
        })
      }
      return result
    }

    this.downtimeData.forEach((segment) => {
      const data = segment.details || [segment];

      data.forEach((detail) => {
        if (!this.detailHasValidLineId(detail, lineId) || detail.status === 'Disconnected')
          return;

        if (detail.slices) {
          detail.slices.forEach((slice) => {
            if (slice.type === 'schedule' && this.detailSliceHasValidReason(slice) && this.detailSliceHasValidShift(slice)) {
              const reasonId = slice.reasonId || 1;
              if (!result[reasonId]) {
                result[reasonId] = { duration: 0, count: 0, subSlice: [] };
              }
              result[reasonId].duration += slice.duration;
              result[reasonId].count += slice.count;
              if (slice.secondReasonId && slice.secondReasonId !== '') {
                result = updateSubSliceData(slice, result);
              }
            }
          });
        } else {
          data.forEach((slice) => {
            const reasonId = slice.reasonId || 1;
            if (!result[reasonId]) {
              result[reasonId] = { duration: 0, count: 0 };
            }
            result[reasonId].duration += slice.duration;
            result[reasonId].count += slice.count;
            if (slice.secondReasonId && slice.secondReasonId !== '') {
              result = updateSubSliceData(slice, result)
            }
          });
        }
      });
    });
    return result;
  }

  downtimeReasons() {
    const reasons = {};
    if (this.site.downtimeCategories != null && this.site.downtimeCategories.length > 0)
      this.site.downtimeCategories.forEach((reason) => { reasons[reason.id] = reason; });
    return reasons;
  }

  getDisplayTime(inputTimeStamp, reportData) {
    switch (this.interval.value) {
      case ENUMS.INTERVALS.HOUR:
        if (!this.isFirstHourSameDayAsLast() || reportData[0].startDt === inputTimeStamp) {
          return Moment.tz(inputTimeStamp, this.site.tz).format('MMM DD HH:mm');
        }
        return Moment.tz(inputTimeStamp, this.site.tz).format('HH:mm');

      default: // days come in site days already
        return Moment(inputTimeStamp.split('T')[0]).toDate();
    }
  }

  isFirstHourSameDayAsLast() {
    const firstItem = this.downtimeData[0];
    const lastItem = this.downtimeData[this.downtimeData.length - 1];
    return Moment.tz(firstItem.startDt, this.site.tz).format('MMM DD') === Moment.tz(lastItem.startDt, this.site.tz).format('MMM DD');
  }

  makeTheDonut(lineId, intl) {
    const downtimeByReason = this.downtimeByReason(lineId);
    return Object.keys(downtimeByReason)
      .map(reasonId => {
        return ({
          sliceKey: intl && this.reasons[reasonId]?.translationKey ? intl.formatMessage({id: this.reasons[reasonId].translationKey.replace(/\./g, '_')}) : this.reasons[reasonId]?.title,
          subSlice: downtimeByReason[reasonId].subSlice ? downtimeByReason[reasonId].subSlice.map(subReason => {
            return ({
              sliceKey: intl && this.reasons[subReason.sliceKey]?.translationKey ? intl.formatMessage({id: this.reasons[subReason.sliceKey].translationKey.replace(/\./g, '_')}) : this.reasons[subReason.sliceKey]?.title,
              duration: subReason.duration,
            })
          }) : [],
          color: this.reasons[reasonId]?.display?.color,
          duration: Math.round(downtimeByReason[reasonId]?.duration),
          count: downtimeByReason[reasonId]?.count,
        })})
  }

  series(elapsed = true) {
    return this.isSummary ? this.seriesForSummary(elapsed) : this.seriesForDetails(elapsed);
  }

  seriesForSummary(elapsed = true) {
    const data = this.unplannedDowntimeByInterval();
    return [{
      aggregate: DowntimeDataHelper.AGGREGATE,
      categoryField: DowntimeDataHelper.CATEGORY_FIELD,
      color: DowntimeDataHelper.DEFAULT_COLOR,
      data,
      field: elapsed ? DowntimeDataHelper.FIELD : DowntimeDataHelper.COUNT,
      name: this.summaryTitle,
      opacity: this.opacity,
    }];
  }

  seriesForDetails(elapsed = true) {
    const series: Array<any> = [];
    this.lines.forEach((line) => {
      const data = this.unplannedDowntimeByInterval(line.id);
      const totalDuration = data.reduce((acc, segment) => acc + segment.duration, 0);
      if (totalDuration > 0) {
        series.push({
          aggregate: DowntimeDataHelper.AGGREGATE,
          categoryField: DowntimeDataHelper.CATEGORY_FIELD,
          color: line.display.color,
          data,
          field: elapsed ? DowntimeDataHelper.FIELD : DowntimeDataHelper.COUNT,
          name: line.title,
          opacity: this.opacity,
        });
      }
    });
    return series;
  }

  siteTimeStampForSegment(segment) {
    return this.getDisplayTime(segment.startDt, this.downtimeData);
  }

  slicedDonut(lineId = false, elapsed = true, intl) {
    const donut = this.makeTheDonut(lineId, intl);
    this.sortTheDonut(donut, elapsed);
    return this.sliceTheDonut(donut, 4, intl);
  }

  sliceTheDonut(donut, numberOfSlices, intl) {
    if (donut.length > numberOfSlices) {
      const slicedDonut = donut.slice(0, numberOfSlices);
      const remainder = donut.slice(numberOfSlices);
      slicedDonut.push({
        sliceKey: intl ? intl.formatMessage({ id: 'detail_Other' }) : 'Other',
        duration: remainder.reduce((acc, slice) => acc + slice.duration, 0),
        count: remainder.reduce((acc, slice) => acc + slice.count, 0),
      });
      return slicedDonut;
    }
    return donut;
  }

  sortTheDonut(donut, elapsed) {
    const propName = elapsed ? 'duration' : 'count';
    donut.sort((a, b) => ((a[propName] < b[propName]) ? 1 : -1));
  }

  unplannedDowntimeDurationForSegment(segment, lineId = false, prop = 'duration') {
    return this.segmentReducer(segment, lineId, prop);
  }

  unplannedDowntimeByInterval(lineId = false) {
    return this.downtimeData.map(segment => this.unplannedDowntimeByIntervalForSegment(segment, lineId));
  }

  unplannedDowntimeByIntervalForSegment(segment, lineId = false) {
    return Object.assign({},
      { duration: this.segmentReducer(segment, lineId, 'duration') },
      { siteTimeStamp: this.siteTimeStampForSegment(segment) },
      { count: this.segmentReducer(segment, lineId, 'count') || 0 });
  }

  unplannedDowntimeCountForSegment(segment, lineId, prop = 'count') {
    if (segment.details) return this.segmentReducer(segment, lineId, prop);
  }

  segmentReducer(segment, lineId, prop) {
    const data = segment.details || [segment];
    return data.reduce((total, detail) => {
      if (!this.detailHasValidLineId(detail, lineId)) return total;

      if (detail.slices) {
        detail.slices.forEach((slice) => {
          if (slice.type === 'schedule' && this.detailSliceHasValidReason(slice) && this.detailSliceHasValidShift(slice)) {
            total += slice[prop];
          }
        });
        return total;
      }

      data.forEach((slice) => {
        total += slice[prop];
      });
      return total;
    }, 0);
  }
}
