import {
  APP_INITIALISED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESTORE_USER_FROM_SESSION_SUCCESS,
  SET_COGNITO_USER_LOADED,
  SET_DEFAULT_PAGE_REQUIRED,
} from "../../actions/actionTypes";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";

export interface IUserState {
  isAdmin: boolean;
  initialised: boolean;
  isAuthenticated: boolean;
  cognitoUserLoaded: boolean;
  userProfile: any;
  defaultPageDisplayRequired: boolean;
  currentUser: any;
}
export const initialUserState: IUserState = {
  isAdmin: false,
  initialised: false,
  isAuthenticated: false,
  cognitoUserLoaded: false,
  userProfile: {
    email: ''
  },
  defaultPageDisplayRequired: false,
  currentUser: {}
};

export function userReducer(state: IUserState = initialUserState, action: IAppGlobalAction): IUserState {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userProfile: action.payload,
        defaultPageDisplayRequired: true
      };
    case RESTORE_USER_FROM_SESSION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userProfile: action.payload,
        defaultPageDisplayRequired: false
      };
    case SET_DEFAULT_PAGE_REQUIRED:
      return {
        ...state,
        defaultPageDisplayRequired: action.payload
      };
    case APP_INITIALISED:
      return {
        ...state,
        initialised: true,
        currentUser: action.payload.currentUser,
        isAdmin: action.payload.isAdmin
      };
    case LOGOUT_SUCCESS:
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        initialised: false,
        userProfile: null
      };
    case SET_COGNITO_USER_LOADED:
      return {
        ...state,
        cognitoUserLoaded: action.payload
      };
    default: {
      return state;
    }
  }
}

