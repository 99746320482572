import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'moment/moment';
import { useHistory, withRouter } from 'react-router';
import BusinessIcon from '@material-ui/icons/Business';
import LinePerformanceListTable from './components/LinePerformanceListTable/LinePerformanceListTable';
import { Logger } from '../../utilities/Logger/Logger';
import DowntimeApi from '../../api/prodDowntimeApi';
import {
  useAppGlobalState,
  useAppLineState,
  useAppProductState,
  useAppSelectedSite,
  useAppShiftState
} from '../../context/AppContext/AppContext';
import { useLinePagesStyles } from './LinesPage.css';
import Utils from '../../utilities/utils';
import { useDowntimeActions } from '../../actions/downtimeActions';

import Dashboard from '../dashboard/Dashboard';
import { useIntl } from 'react-intl';
import {
  Apps as AppsIcon,
  Gradient as GradientIcon,
  Subject as SubjectIcon,
} from "@material-ui/icons";
import PageTabs from '../../components/PageTabs/PageTabs';
import { useSetTitleAction } from '../../actions/useActions/useSetTitleAction/useSetTitleAction';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSiteActions } from '../../actions/siteActions';
import { useLineActions } from '../../actions/lineActions';
import { useShiftActions } from '../../actions/shiftActions';
import StyledTab from '../../components/Basic/StyledTab/StyledTab';
import StyledTabs from '../../components/Basic/StyledTabs/StyledTabs';
import { useConfigActions } from '../../actions/configActions';
import { useIsSmallSelector } from '../../context/LayoutContext/LayoutContext';

interface LineState {
  activeTabIndex: number;
  isLoading: boolean;
  currentLines: Array<any>;
  currentShifts: Array<any>;
  linesWithAssignedProduct: Array<any>;
  productsFormattedForDropdown: Array<any>;
  mounted: boolean;
  refreshTimer: any;
}

const calculateTargetLineAndShifts = (lines, shifts) => {
  const schedulerFormattedShifts = shifts.map((shift => new window['kendo'].data.SchedulerEvent({
    id: shift.id,
    xid: shift.id,
    companyId: shift.companyId,
    siteId: shift.siteId,
    title: shift.title,
    description: shift.description,
    lineIds: shift.lineIds,
    typeId: shift.typeId,
    start: shift.startTime,
    end: shift.endTime,
    recurrenceRule: shift.recurrenceRule,
    recurrenceException: shift.recurrenceException,
    isAllDay: shift.isAllDay,
    isDeleted: shift.isDeleted,
  } as any)));

  const schedulerDataSource: any = new window['kendo'].data.SchedulerDataSource({
    batch: true,
    data: schedulerFormattedShifts,
    schema: {
      model: {
        shiftId: { from: 'shiftId', type: 'string' },
        fields: {
          id: { from: 'id', type: 'string' },
          title: { from: 'title', defaultValue: 'No title' },
          description: { from: 'description', defaultValue: 'No description' },
          lineIds: { from: 'lineIds' },
          typeId: { from: 'typeId', defaultValue: 1 },
          start: { type: 'date', from: 'start' },
          end: { type: 'date', from: 'end' },
          recurrenceId: { from: 'recurrenceID' },
        },
      },
    },
  });

  schedulerDataSource.fetch();
  const targetShifts = schedulerDataSource.expand(new (Moment as any)(new Date()).subtract(1, 'minute').toDate(), new Date());
  const targetLines = lines.filter(l => Utils.OnlyLinesInShift(l, targetShifts) && l.printerId);
  return [targetLines, targetShifts];
}

const LinePages = (props: any) => {
  const intl = useIntl();
  const history = useHistory();
  const downtimeActions = useDowntimeActions();
  const siteActions = useSiteActions();
  const lineActions = useLineActions();
  const shiftActions = useShiftActions();

  const classes = useLinePagesStyles();
  const { sites, selectedSiteId } = useAppGlobalState();
  const selectedSite = useAppSelectedSite();
  const setTitle = useSetTitleAction();
  const siteId: string = props.match.params.siteId;

  const shifts = useAppShiftState();
  const lines = useAppLineState();
  const products = useAppProductState();
  const site = useAppSelectedSite();
  const configs = useConfigActions();

  const [lineState, setLineState] = useState<LineState>({
    activeTabIndex: props.match.params.lineMode === 'performance' ? 1 : 0,
    isLoading: true,
    currentLines: [],
    currentShifts: [],
    linesWithAssignedProduct: [],
    productsFormattedForDropdown: [],
    mounted: false,
    refreshTimer: 0
  });

  const { activeTabIndex,
    currentLines,
    currentShifts,
    linesWithAssignedProduct,
    productsFormattedForDropdown,
    mounted, refreshTimer } = lineState;

  const generateLocalLines = (targetLines: any, products: any) => {
    const localLines = [...targetLines];
    products.forEach((product) => {
      for (let i = 0; localLines && i < localLines.length; i++) {
        if (localLines[i].assignedProductId === product.id) {
          localLines[i] = Object.assign({}, localLines[i], { product });
        }
      }
    });
    return localLines;
  }

  const generateProductsFormattedForDropdown = (products: any) => {
    return products.map(product => ({
      value: product.id,
      text: product.title,
    }));
  }

  const loadShifts = () => {
    shiftActions.loadShifts(siteId)
      .catch((error) => {
        Logger.of('App.LinesPage.loadShifts').info('Error', error);
      });
  }

  const loadSiteData = () => {
    const [targetLines] = calculateTargetLineAndShifts(lines, shifts)
    downtimeActions.loadSiteDowntimeReasons(siteId)
      .catch(error => Logger.of('App.LinesPage.loadSiteDowntimeReasons')
        .info('Error ', error));
    siteActions.loadSitePrinters(siteId)
      .then(async () => {
        if (targetLines && site) {
          const lineIds = targetLines.map(l => l.id);
          await Promise.all([
            lineActions.loadLinesOverview(siteId, lineIds, Moment().toISOString(), true),
            lineActions.loadTimeInState(siteId, lineIds, site.slowCycleThreshold),
          ]);

          setLineState(s => ({
            ...s,
            isLoading: false,
          }));

          configs.setIsLoading(false);
        }
      })
      .catch((error) => {
        Logger.of('App.LinesPage.loadSitePrinters').info('Error ', error);
        setLineState(s => ({
          ...s,
          isLoading: false,
        }));
        configs.setIsLoading(false);
      });
  }

  const refreshSiteData = async () => {
    try {
      if (!window.location.pathname.includes(`/site/${selectedSiteId}/lines`)) {
        clearInterval(refreshTimer);
        return;
      }

      Logger.of('App.LinesPage.refreshSiteData').info('Refreshing site data');
      const siteId = selectedSiteId;
      const currentSite = sites.find(el => el.id === siteId);
      if (currentSite && currentSite.printers) {
        configs.setIsLoading(true);
        setLineState(s => ({ ...s, isLoading: true }));
        loadShifts();
        loadSiteData();
      } else {
        setLineState(s => ({
          ...s,
          isLoading: false,
        }));
        configs.setIsLoading(false);
      }
    } catch (error) {
      Logger.of('App.LinesPage.refreshSiteData').info('Error Refreshing site data', error);
    }
  }

  useEffect(() => {
    const [targetLines, targetShifts] = calculateTargetLineAndShifts(lines, shifts)
    setLineState(s => ({
      ...s,
      currentLines: targetLines,
      currentShifts: targetShifts,
      linesWithAssignedProduct: generateLocalLines(targetLines, products),
      productsFormattedForDropdown: generateProductsFormattedForDropdown(products)
    }));
  }, [site, shifts, lines, products]);

  const setIntervalForRefresh = () => {
    refreshSiteData();
    const timeInterval = setInterval(refreshSiteData, 1000 * 65);
    setLineState(s => ({ ...s, refreshTimer: timeInterval }));
  };

  useEffect(() => {
    if (!mounted) {
      setLineState(s => ({ ...s, mounted: true }));
      setIntervalForRefresh();
    }

    return () => {
      if (mounted) {
        clearInterval(refreshTimer);
      }
    }
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearIntervalAndRefresh = useCallback(() => {
    clearInterval(refreshTimer);
    setIntervalForRefresh();
  }, [refreshTimer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeTabIndex === 0) {
      history.replace(`/site/${siteId}/lines/overview`);
      setTitle('detail_Performance', <GradientIcon />, {
        ignoreItself: true,
        parents: [
          {
            textOnly: true,
            id: 'INSIGHT',
          },
          { id: 'detail_Performance' },
          { id: 'detail_Overview' }]
      });
    } else {
      history.replace(`/site/${siteId}/lines/performance`);
      setTitle('detail_Performance', <GradientIcon />, {
        ignoreItself: true,
        parents: [
          {
            textOnly: true,
            id: 'INSIGHT',
          },
          { id: 'detail_Performance' },
          { id: 'detail_Lines' }]
      });
    }
  }, [setTitle, activeTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const smallMode = useIsSmallSelector();

  return <>
    <PageTabs displayButton={
      activeTabIndex === 1 && !smallMode ? <Link
        to={`/site/${siteId}/dashboard/lines`}
        className={classes.displayModeLink}
      > <div className={classes.displayModeBtnContainer}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<BusinessIcon />}
          >
            {intl.formatMessage({ id: 'detail_EnterDisplayMode' })}
          </Button>
        </div>
      </Link> : ''
    }>
      <StyledTabs
        value={activeTabIndex}
        onChange={(e, index) => setLineState(s => ({ ...s, activeTabIndex: index }))}
        indicatorColor="secondary"
        textColor="primary"
      >
        <StyledTab icon={<AppsIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_Overview' })} />
        <StyledTab icon={<SubjectIcon className={classes.tabIcon} />} label={intl.formatMessage({ id: 'detail_Lines' })} />
      </StyledTabs>
    </PageTabs>
    {
      activeTabIndex === 0 && <Dashboard
        siteId={props.match.params.siteId}
        currentLines={currentLines}
        currentShifts={currentShifts}
      />
    }
    {
      activeTabIndex === 1 && <LinePerformanceListTable
        linesWithAssignedProduct={linesWithAssignedProduct}
        site={selectedSite}
        shownPrinters={{}}
        productsFormattedForDropdown={productsFormattedForDropdown}
        refreshData={clearIntervalAndRefresh}
        siteId={selectedSiteId}
        updateDowntimeEvent={DowntimeApi.saveDowntimeEvent}
        downtimeActions={downtimeActions}
      />
    }
  </>
}

export default withRouter(LinePages);
