import React from "react";
import { SimpleMessage } from "../SimpleMessage/SimpleMessage";

function NotAuthorised() {
  return (
    <div>
      <SimpleMessage title="Not Authorised" subtitle="The page you are looking for is not authorised." />
    </div>
  );
}

NotAuthorised.displayName = 'NotAuthorised';

export { NotAuthorised };
