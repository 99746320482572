import { invokeApig, callExternalApi  } from '../libs/awsLib';

class InspectionApi {
  static getInspection(id, siteId) {
    const apiUrl = `/inspections/${id}`;
    return invokeApig({ gatewayName: 'INSPECTIONS', path: apiUrl, queryParams: { siteId } });
  }

  static getInspections(siteId) {
    const apiUrl = '/inspections';
    return invokeApig({ gatewayName: 'INSPECTIONS', path: apiUrl, queryParams: { siteId } });
  }

  static saveInspection(inspection) {
    const apiUrl = '/inspections';
    if (inspection.id === '') delete inspection.id;
    return invokeApig({ gatewayName: 'INSPECTIONS', path: apiUrl, method: 'POST', body: inspection });
  }

  static updateInspection(inspection) {
    const apiUrl = `/inspections/${inspection.id}`;
    return invokeApig({ gatewayName: 'INSPECTIONS', path: apiUrl, method: 'PUT', queryParams: { siteId: inspection.siteId }, body: inspection });
  }

  static deleteInspection(siteId, id) {
    const apiUrl = `/inspections/${id}`;
    return invokeApig({ gatewayName: 'INSPECTIONS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }

  static getFileUploadUrl(siteId, fileType = 'image/jpeg') {
    const path = '/inspectionFiles/getUploadUrl';
    return invokeApig({ gatewayName: 'INSPECTIONS', path, queryParams: { siteId, fileType } });
  }

  static getFileUrl(siteId, fileName) {
    const path = '/inspectionFiles/getDownloadUrl';
    return invokeApig({ gatewayName: 'INSPECTIONS', path, queryParams: { siteId, fileName } });
  }

  static async uploadFile(url, file) {
    return await callExternalApi('PUT', url, file, { 'Content-Type': file.type });
  }
}

export default InspectionApi;
