import { makeStyles } from "@material-ui/core";
import { myGrayShade4, standardBoxShadow } from "../../../constants/colors";

export const useReportHeaderStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.25, 2.875),
    margin: theme.spacing(1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow
  },
  content: {
    display: 'flex',
    flexFlow: 'column'
  },
  timeIntervalRadio: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-1.5)
  },
  timeInterval: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    width: theme.spacing(30),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(27),
    }
  },
  datePicker: {
    width: '100%',
    outline: 'none'
  },
  select: {
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(30)
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(3.75)
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  applyContainer: {
    marginRight: theme.spacing(4)
  },
  actionButton: {
    '&:nth-last-child(n + 2)': {
      marginRight: theme.spacing(1.25)
    }
  },
  title: {
    marginBottom: theme.spacing(2.5)
  },
  cell: {
    padding: theme.spacing(1.25)
  },
  optionText: {
    display: 'block',
    fontSize: theme.spacing(1.625)
  },
  dimmedLabel: {
    color: myGrayShade4
  }
}));
