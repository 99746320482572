import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesDefaults,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartSeriesItemTooltip,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import Moment from 'moment/moment';

import PropTypes from 'prop-types';
import React from 'react';
import { IntlService } from '@progress/kendo-react-intl';
import { sharedConfig } from './ChartSharedConfig';
import * as ENUMS from '../../enums/Enums';
import { injectIntl, useIntl } from 'react-intl';

function getNote(d, name, intl) {
  const targetCount = d.currentTargetCount || d.target || 0;
  return `${(name) ? `${intl.formatMessage({ id: 'Line' })}: ${name}\n` : ''}${intl.formatMessage({ id: 'detail_Count' })}: ${d.count.toFixed()}\n${intl.formatMessage({ id: 'detail_Target' })}: ${targetCount.toFixed()}\n${intl.formatMessage({ id: 'detail_Score' })}: ${((d.count / targetCount) * 100).toFixed(2)} %`;
}

export function createSeries({ data, intl, name = undefined, color = undefined }) {
  return [
    {
      name: name ? `${name}` : intl.formatMessage({ id: 'detail_PieceCount' }),
      tooltipDisplayName: name ? `${name}` : '',
      field: 'count',
      visibleInLegend: true,
      categoryField: 'siteTimeStamp',
      data: data.map(d => ({
        ...d,
        note: getNote(d, name, intl),
      })),

      color: color || '#0192c7',
      noteTextField: 'note',
      notes: { visible: false },
      stacked: true,
      stack: { group: name || 'agg' },
    }, {
      name: name ? `${name}` : intl.formatMessage({ id: 'detail_Target_Count' }),
      tooltipDisplayName: name ? `${name}` : '',
      data: data.map((d) => {
        const targetCount = d.currentTargetCount || d.target;
        return ({
          ...d,
          chartTarget: targetCount && Math.max(0, targetCount - (d.count || 0)),
          note: getNote(d, name, intl),
        });
      }),
      field: 'chartTarget',
      noteTextField: 'note',
      categoryField: 'siteTimeStamp',
      color: 'white',
      visibleInLegend: false,
      stacked: true,
      stack: { group: name || 'agg' },
      notes: { visible: false },
    }];
}

const ChartProductBar = (props) => {
  const intl = useIntl();
  function chartConfig(chartProps) {
    function defaultConfig(p): any {
      return {
        title: {
          text: p.title,
          visible: p.showTitle,
          margin: {
            bottom: 18,
          },
          color: 'Black',
        },
        legend: {
          position: 'bottom',
          visible: true,
        },
        seriesDefaults: {
          type: 'column',
          stack: p.stacked === undefined ? true : p.stacked,
          labels: {
            visible: false,
            background: 'transparent',
            format: '{0:n0}',
          },
        },
        series: (p.customSeries && p.customSeries.length > 0)
          ? p.customSeries.flatMap(series => createSeries({
            data: series.data,
            interval: props.interval,
            name: series.name,
            color: series.color,
            intl,
          } as any))
          : createSeries({
            data: p.data,
            intl
          }),

        transitions: false,
        valueAxis: {
          min: 0,

          labels: {
            visible: true,
            format: '{0:n0}',
          },

        },
        categoryAxis: {
          baseUnit: props.interval.chartBaseUnit,
          labels: {
            rotation: 'auto',
          },
          majorGridLines: {
            visible: false,
          },
          line: {
            visible: false,
          },
        },
        tooltip: {
          visible: true,

        },
      };
    }

    // start with a default configuration for the chart
    const config = defaultConfig(chartProps);

    // update the chart configuration based on the interval
    switch (chartProps.interval.value) {
      case ENUMS.INTERVALS.HOUR:
        config.categoryAxis.labels.content = (e) => {
          const { dataItem, value } = e;
          const is = new IntlService(props.locale);
          return is.format(((dataItem && dataItem.siteTimeStamp) || value).getHours() === 0 ? '{0:MM-dd HH:mm}' : '{0:HH:mm}', (dataItem && dataItem.siteTimeStamp) || value);
        };
        break;
      case ENUMS.INTERVALS.DAY:
        config.categoryAxis.labels.format = '{0:MMMM dd}';
        config.categoryAxis.labels.rotation = -45;
        break;
      case ENUMS.INTERVALS.WEEK:
        config.categoryAxis.labels.content = (e) => {
          const dataItemWeek = Moment(e.value).week();
          const chartStartWeek = Moment(chartProps.start).week();
          const chartEndWeek = Moment(chartProps.end).week();

          const dataItemYear = Moment(e.value).year();
          const chartStartYear = Moment(chartProps.start).year();
          const chartEndYear = Moment(chartProps.end).year();

          let startLabel = Moment(e.value).startOf('week').format('MMM DD');
          let endLabel = Moment(e.value).endOf('week').format('MMM DD');

          if ((chartStartWeek === dataItemWeek && chartStartYear === dataItemYear)
            // year changeover
            || (chartStartWeek === 1 && dataItemWeek === 1 && dataItemYear === chartStartYear - 1)) {
            startLabel = Moment(chartProps.start).format('MMM DD');
          }
          if ((chartEndWeek === dataItemWeek && chartEndYear === dataItemYear)
            // year changeover
            || (chartStartWeek === 1 && dataItemWeek === 1 && dataItemYear === chartStartYear - 1)) {
            endLabel = Moment(chartProps.end).format('MMM DD');
          }

          return `${startLabel} to ${endLabel}`;
        };
        break;
      case ENUMS.INTERVALS.MONTH:
        config.categoryAxis.labels.content = (e) => {
          const dataItemMonthNumber = Moment(e.value).month();
          const chartStartMonthNumber = Moment(chartProps.start).month();
          const chartEndMonthNumber = Moment(chartProps.end).month();

          const dataItemYear = Moment(e.value).year();
          const chartStartYear = Moment(chartProps.start).year();
          const chartEndYear = Moment(chartProps.end).year();

          const inStartMonth = chartStartMonthNumber === dataItemMonthNumber && chartStartYear === dataItemYear;
          const inEndMonth = chartEndMonthNumber === dataItemMonthNumber && chartEndYear === dataItemYear;

          if (inStartMonth && inEndMonth) {
            return `${Moment(chartProps.start).format('MMM DD')} to ${Moment(chartProps.end).format('MMM DD')}`;
          } if (inStartMonth) {
            return `${Moment(chartProps.start).format('MMM DD')} to ${Moment(chartProps.start).endOf('month').format('MMM DD')}`;
          } if (inEndMonth) {
            return `${Moment(chartProps.end).format('MMM')} 01 to ${Moment(chartProps.end).format('MMM DD')}`;
          }
          return Moment(e.value).format('MMM');
        };
        break;
      default:
    }
    return config;
  }

  const config = chartConfig(props);
  return (

    <div className="chart-product">
      {props.children}

      <Chart renderAs={props.printing ? 'canvas' : undefined} {...sharedConfig}>
        <ChartTooltip />
        <ChartLegend {...config.legend} />
        <ChartSeriesDefaults {...config.seriesDefaults} />
        <ChartTitle {...config.title} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem {...config.categoryAxis} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem {...config.valueAxis} />
        </ChartValueAxis>
        <ChartSeries>
          {config.series.map((item, i) => (
            <ChartSeriesItem {...item} key={`series-${i}`}>
              <ChartSeriesItemTooltip render={({ point }) => (
                <div>
                  { point.dataItem.note.split('\n').map(i => (<div key={i}>{i}</div>))}
                </div>
              )}
              />
            </ChartSeriesItem>
          ))
          }
        </ChartSeries>
      </Chart>
    </div>
  );
};


ChartProductBar.propTypes = {
  children: PropTypes.element,
  customSeries: PropTypes.array,
  data: PropTypes.array,
  dynamicBarColor: PropTypes.bool,
  interval: PropTypes.object,
  printing: PropTypes.bool,
  showTitle: PropTypes.bool,
  showBarLabels: PropTypes.bool,
  stacked: PropTypes.bool,
  title: PropTypes.string,
  locale: PropTypes.string,
  /*  start: PropTypes.Date,
    end: PropTypes.date, */
};

ChartProductBar.defaultProps = {
  data: [],
  interval: '',
  showTitle: true,
  customSeries: undefined,
};

export default injectIntl(ChartProductBar);
