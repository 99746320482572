import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';

import { Route, Switch } from 'react-router';
import { NotFound } from '../../../components/NotFound/NotFound';
import { DowntimeSetupPage } from '../DowntimeSetupPage/DowntimeSetupPage';
import { CheckAuthorisedRoute } from '../../../components/CheckAuthorisedRoute/CheckAuthorisedRoute';
import { getLinkAccessInfo } from '../../../RouteAccess';
import { useAppSelectedSite, useAppGlobalState } from '../../../context/AppContext/AppContext';
import { useDowntimeActions } from '../../../actions/downtimeActions';

const DowntimeSetupMainPage = ({ match }: any) => {
  const intl = useIntl();
  const downtimeActions = useDowntimeActions();

  const { selectedSiteId, isAdmin, downtimeTypes } = useAppGlobalState();
  const site = useAppSelectedSite();
  const { downtimeCategories } = site ? site : { downtimeCategories: [] };
  const [refreshRequired, setRefreshRequired] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState(false);

  const downtimeTypesFormatted = downtimeTypes.map(type => ({
    value: type.id,
    text: intl.formatMessage({ id: `detail_Downtime_${type.name}` })
  }));

  const setupParam = match.params.setupParam;
  const action = match.params.action || 'none';
  const setupMode = match.params.setupMode;

  const accessInfo = getLinkAccessInfo(site, isAdmin, selectedSiteId);
  const access = {
    accessInfo,
    params: {
      siteId: selectedSiteId,
      setupMode,
      action,
      setupParam
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (refreshRequired) {
          setIsLoading(true);
          await downtimeActions.loadSiteDowntimeReasons(selectedSiteId);
          setIsLoading(false);
          setRefreshRequired(false);
        }
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }

    })();
  }, [refreshRequired]); // eslint-disable-line react-hooks/exhaustive-deps


  return !isLoading ? <>
    <Switch>
      <CheckAuthorisedRoute {...access} path={`/site/${selectedSiteId}/setup/downtimes`}>
        <DowntimeSetupPage
          downtimeCategories={downtimeCategories}
          downtimeTypesFormatted={downtimeTypesFormatted}
          match={match}
          setRefreshRequired={setRefreshRequired} />
      </CheckAuthorisedRoute>
      <CheckAuthorisedRoute {...access} path={`/site/${selectedSiteId}/setup/subcategory`}>
        <DowntimeSetupPage
          downtimeCategories={downtimeCategories}
          downtimeTypesFormatted={downtimeTypesFormatted}
          match={match}
          setRefreshRequired={setRefreshRequired} />
      </CheckAuthorisedRoute>
      <Route component={NotFound} />
    </Switch>
  </> : <ProgressIndicator />
}

export { DowntimeSetupMainPage }
