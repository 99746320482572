import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";


export default makeStyles((theme: Theme)=> ({
  root: {
    display: "flex"
  },
  contentContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(9),
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      overflowY: 'hidden',
      minHeight: 'calc(100vh - 81px)',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 91px)',
    },
    zIndex: 30,
  },
  content: {
    flex: '1 0 auto',
    margin: theme.spacing(1, 1.5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 1.2),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 1.125),
    }
  },
  footer: {
    flexShrink: 0,
    padding: theme.spacing(0, 1.5)
  },
  displayModeContainer: {
    margin: theme.spacing(1),
    width: '100%',
    height: '100%',
  },
  displayModeContent: {
    padding: theme.spacing(1, 1.5),
    width: '100%',
    height: '100%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'hidden'
    }
  }
}));
