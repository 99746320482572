import { FormControl } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useGlobalStyles } from "../../constants/globalstyles";
import { useDowntimeCheckboxStyle } from "./DowntimeCheckbox.css";
import { StyledSwitch } from "../StyledSwitch/StyledSwitch";
import { InfoTooltip } from "../Basic/InfoTooltip/InfoTooltip";
interface DowntimeCheckboxProps {
  onChange: (event) => void;
  isDisabled?: boolean;
  value: boolean;
}

function DowntimeCheckbox({ onChange,
  value,
  isDisabled }: DowntimeCheckboxProps) {
  const classes = useDowntimeCheckboxStyle();
  const globalClasses = useGlobalStyles();
  return <div className={classes.inputBox}>
    <div>
      <FormattedMessage id='detail_Downtime' />
      <InfoTooltip textId='detail_DowntimeNotification' />
    </div>

    <FormControl variant="outlined" className={globalClasses.formControl}>
      <StyledSwitch checked={value}
        onChange={(e, checked) => onChange(checked)}
        name="checkedAutoRotate"
        disabled={isDisabled}
      />
    </FormControl>
  </div>
}

DowntimeCheckbox.displayName = 'DowntimeCheckbox';

export { DowntimeCheckbox };
